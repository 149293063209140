import React from 'react';

import {DSL_Box, DSL_BoxProps} from '../../Layout/Box';

export interface DSL_DrawerSidebarProps extends DSL_BoxProps {
  orientation?: 'vertical' | 'horizontal';
}

export const DSL_DrawerSidebar = ({
  display = 'flex',
  orientation = 'vertical',
  ...props
}: DSL_DrawerSidebarProps) => {
  return orientation === 'vertical' ? (
    <DSL_Box
      display={display}
      height={'100vh'}
      flexDirection={'column'}
      {...props}
    />
  ) : (
    <DSL_Box
      display={display}
      width={'100vw'}
      flexDirection={'row'}
      justifyContent={'center'}
      {...props}
    />
  );
};

DSL_DrawerSidebar.displayName = 'DSL_DrawerSidebar';

export {
  //
  /** @deprecated use DSL_DrawerSidebarProps instead*/
  DSL_DrawerSidebarProps as DrawerSidebarProps, //
  /** @deprecated use DSL_DrawerSidebar instead*/
  DSL_DrawerSidebar as DrawerSidebar,
};
