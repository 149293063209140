import React from 'react';

import {DSL_IconWrapper} from '../IconWrapper';
import {DSL_IconProps} from '../types';

const DSL_CorrectiveActions = (props: DSL_IconProps) => {
  return (
    <DSL_IconWrapper
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          fill="none"
          viewBox="0 0 24 24"
          {...props}
        >
          <path
            fill="#000"
            fillOpacity={0.54}
            d="M6 22h7v-2H6V4h7v5h5v2h2V8l-6-6H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2"
          />
          <path
            fill="#000"
            fillOpacity={0.54}
            d="M14.261 17.094c-.348-.302-.348-.836 0-1.171a.944.944 0 0 1 1.254.033l1.114 1.07 2.89-2.775c.314-.335.871-.335 1.22 0 .348.301.348.836 0 1.17l-3.483 3.345a.86.86 0 0 1-.627.234.86.86 0 0 1-.626-.234z"
          />
        </svg>
      }
      {...props}
    />
  );
};

export default React.memo(DSL_CorrectiveActions);
