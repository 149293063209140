import EditRoadIcon from '@mui/icons-material/EditRoad';
import Networks from 'containers-spatial/Networks';
import NetworkSegments from 'containers-spatial/Networks/NetworkSegments';
import NetworkUpdateRequestSegments from 'containers-spatial/NetworkManagement/NetworkUpdateRequests/NetworkUpdateRequestSegments';
import AccessInstrument from 'containers-access/AccessInstrument';
import CreateAccessInstrument from 'containers-access/AccessInstrument/CreateAccessInstrument';
import AccessInstrumentDetails from 'containers-access/AccessInstrument/AccessInstrumentDetails';
import CreateAccessProductApplicationAI from 'containers-access/AccessInstrument/AccessInstrumentDetails/ReferenceVehicles/CreateAccessProduct';
import AccessProductAI from 'containers-access/AccessInstrument/AccessInstrumentDetails/ReferenceVehicles/AccessProduct';
import AccessProductApplicationAPAI from 'containers-access/AccessInstrument/AccessInstrumentDetails/ReferenceVehicles/AccessProduct/AccessProductApplication';
import AccessProductApplicationAI from 'containers-access/AccessInstrument/AccessInstrumentDetails/AccessProductApplications/AccessProductApplication';
import AccessProductApplicationNetworkDocumentAPAI from 'containers-access/AccessInstrument/AccessInstrumentDetails/ReferenceVehicles/AccessProduct/AccessProductApplication/NetworkDocument';
import AccessProductApplicationNetworkNURDocumentAPAI from 'containers-access/AccessInstrument/AccessInstrumentDetails/ReferenceVehicles/AccessProduct/AccessProductApplication/NetworkDocument/NetworkUpdateRequestSegments';
import AccessProductApplicationNetworkAPAI from 'containers-access/AccessInstrument/AccessInstrumentDetails/ReferenceVehicles/AccessProduct/AccessProductApplication/Network';
import AccessProductApplicationNetworkNURAPAI from 'containers-access/AccessInstrument/AccessInstrumentDetails/ReferenceVehicles/AccessProduct/AccessProductApplication/Network/NetworkUpdateRequestSegments';
import AccessProductApplicationVehicleAPAI from 'containers-access/AccessInstrument/AccessInstrumentDetails/ReferenceVehicles/AccessProduct/AccessProductApplication/AccessVehicleExemptionSet';
import AccessProductApplicationNetworkDocumentAI from 'containers-access/AccessInstrument/AccessInstrumentDetails/AccessProductApplications/AccessProductApplication/NetworkDocument';
import AccessProductApplicationNetworkNURDocumentAI from 'containers-access/AccessInstrument/AccessInstrumentDetails/AccessProductApplications/AccessProductApplication/NetworkDocument/NetworkUpdateRequestSegments';
import AccessProductApplicationNetworkAI from 'containers-access/AccessInstrument/AccessInstrumentDetails/AccessProductApplications/AccessProductApplication/Network';
import AccessProductApplicationNetworkNURAI from 'containers-access/AccessInstrument/AccessInstrumentDetails/AccessProductApplications/AccessProductApplication/Network/NetworkUpdateRequestSegments';
import AccessProductApplicationVehicleAI from 'containers-access/AccessInstrument/AccessInstrumentDetails/AccessProductApplications/AccessProductApplication/AccessVehicleExemptionSet';
import AccessProductDocumentAPAI from 'containers-access/AccessInstrument/AccessInstrumentDetails/ReferenceVehicles/AccessProduct/AccessProductDocument';
import AccessProductVehicleAPAI from 'containers-access/AccessInstrument/AccessInstrumentDetails/ReferenceVehicles/AccessProduct/AccessVehicleExemptionSet';
import AccessProductDocumentAI from 'containers-access/AccessInstrument/AccessInstrumentDetails/AccessProductDocuments/AccessProductDocument';
import AccessProductDocumentNetworkAPAI from 'containers-access/AccessInstrument/AccessInstrumentDetails/ReferenceVehicles/AccessProduct/AccessProductDocument/Network';
import AccessProductDocumentNetworkNURAPAI from 'containers-access/AccessInstrument/AccessInstrumentDetails/ReferenceVehicles/AccessProduct/AccessProductDocument/Network/NetworkUpdateRequestSegments';
import AccessProductDocumentVehicleAPAI from 'containers-access/AccessInstrument/AccessInstrumentDetails/ReferenceVehicles/AccessProduct/AccessProductDocument/AccessVehicleExemptionSet';

import AccessProductDocumentAPA from 'containers-access/AccessProductDocument/AccessProductDocumentDetail/AccessProductApplication';
import AccessProductDocumentVehicleAPA from 'containers-access/AccessProductDocument/AccessProductDocumentDetail/AccessProductApplication/AccessVehicleExemptionSet';
import AccessProductDocumentNetworkAPA from 'containers-access/AccessProductDocument/AccessProductDocumentDetail/AccessProductApplication/Network';
import AccessProductDocumentNetworkNURAPA from 'containers-access/AccessProductDocument/AccessProductDocumentDetail/AccessProductApplication/Network/NetworkUpdateRequestSegments';

import AccessProductDocumentAPDAI from 'containers-access/AccessInstrument/AccessInstrumentDetails/AccessProductDocuments/AccessProductDocument/AccessProductApplication';
import AccessProductDocumentNetworkAPDAI from 'containers-access/AccessInstrument/AccessInstrumentDetails/AccessProductDocuments/AccessProductDocument/AccessProductApplication/Network';
import AccessProductDocumentNetworkNURAPDAI from 'containers-access/AccessInstrument/AccessInstrumentDetails/AccessProductDocuments/AccessProductDocument/AccessProductApplication/Network/NetworkUpdateRequestSegments';
import AccessProductDocumentVehicleAPDAI from 'containers-access/AccessInstrument/AccessInstrumentDetails/AccessProductDocuments/AccessProductDocument/AccessProductApplication/AccessVehicleExemptionSet';

import AccessProductDocumentNetworkAI from 'containers-access/AccessInstrument/AccessInstrumentDetails/AccessProductDocuments/AccessProductDocument/Network';
import AccessProductDocumentNetworkNURAI from 'containers-access/AccessInstrument/AccessInstrumentDetails/AccessProductDocuments/AccessProductDocument/Network/NetworkUpdateRequestSegments';
import CreateAccessProductChangeSetAI from 'containers-access/AccessInstrument/AccessInstrumentDetails/AccessProductDocuments/AccessProductDocument/CreateAccessProductChangeSet';
import AccessProductDocumentVehicleAI from 'containers-access/AccessInstrument/AccessInstrumentDetails/AccessProductDocuments/AccessProductDocument/AccessVehicleExemptionSet';
import CreateAccessProductApplicationAPAI from 'containers-access/AccessInstrument/AccessInstrumentDetails/ReferenceVehicles/AccessProduct/CreateAccessProductApplication';
import AccessProduct from 'containers-access/AccessProduct';
import CreateAccessProduct from 'containers-access/AccessProduct/CreateAccessProduct';
import AccessProductDetails from 'containers-access/AccessProduct/AccessProductDetails';
import AccessProductDetailsVehicle from 'containers-access/AccessProduct/AccessProductDetails/AccessVehicleExemptionSet';
import AccessProductDocumentAP from 'containers-access/AccessProduct/AccessProductDetails/AccessProductDocuments/AccessProductDocument';
import AccessProductDocument from 'containers-access/AccessProductDocument';
import AccessProductDocumentDetail from 'containers-access/AccessProductDocument/AccessProductDocumentDetail';
import CreateAccessProductChangeSetAPD from 'containers-access/AccessProductDocument/AccessProductDocumentDetail/AccessProductChangeSets/CreateAccessProductChangeSet';
import VehicleAPD from 'containers-access/AccessProductDocument/AccessProductDocumentDetail/AccessVehicleExemptionSet';
import NetworkAPD from 'containers-access/AccessProductDocument/AccessProductDocumentDetail/Network';
import NetworkNURAPD from 'containers-access/AccessProductDocument/AccessProductDocumentDetail/Network/NetworkUpdateRequestSegments';

import Preapprovals from 'containers-access/AccessProductDocument/index-preapproval';
import PreapprovalDetail from 'containers-access/AccessProductDocument/AccessProductDocumentDetail/index-preapproval';
import NetworkPreapproval from 'containers-access/AccessProductDocument/AccessProductDocumentDetail/Network/index-preapproval';
import NetworkNURPreapproval from 'containers-access/AccessProductDocument/AccessProductDocumentDetail/Network/NetworkUpdateRequestSegments/index-preapproval';
import CreatePreApproval2 from 'containers-access/AccessProductDocument/CreatePreApproval/index-preapproval';
import CreatePreApproval from 'containers-access/AccessProductDocument/CreatePreApproval';
import CreatePreApprovalNetwork from 'containers-access/AccessProductDocument/AccessProductDocumentDetail/AccessProductChangeSets/CreatePreApprovalNetwork';
import CreateAccessProductChangeSetAPDPreapproval from 'containers-access/AccessProductDocument/AccessProductDocumentDetail/AccessProductChangeSets/CreateAccessProductChangeSet/index-preapproval';

import PreApprovalAPA from 'containers-access/AccessProductDocument/AccessProductDocumentDetail/AccessProductApplicationPreapproval';
import PreApprovalVehicleAPA from 'containers-access/AccessProductDocument/AccessProductDocumentDetail/AccessProductApplicationPreapproval/AccessVehicleExemptionSet';
import PreApprovalNetworkAPA from 'containers-access/AccessProductDocument/AccessProductDocumentDetail/AccessProductApplicationPreapproval/Network';
import PreApprovalNetworkNURAPA from 'containers-access/AccessProductDocument/AccessProductDocumentDetail/AccessProductApplicationPreapproval/Network/NetworkUpdateRequestSegments';

import JoinAccessProductDocument from 'containers-access/AccessProductDocument/JoinAccessProductDocument';
import JoinRelatedAccessProductDocument from 'containers-access/AccessProductDocument/JoinRelatedAccessProductDocument';
import AccessProductApplication from 'containers-access/AccessProductApplication';
import CreateAccessProductApplication from 'containers-access/AccessProductApplication/CreateAccessProductApplication';
import AccessProductApplicationDetails from 'containers-access/AccessProductApplication/AccessProductApplicationDetails';
import AccessProductApplicationDetailsNetwork from 'containers-access/AccessProductApplication/AccessProductApplicationDetails/Network';
import AccessProductApplicationDetailsNetworkNUR from 'containers-access/AccessProductApplication/AccessProductApplicationDetails/Network/NetworkUpdateRequestSegments';
import AccessProductApplicationDetailsNetworkDocument from 'containers-access/AccessProductApplication/AccessProductApplicationDetails/NetworkDocument';
import AccessProductApplicationDetailsNetworkNURDocument from 'containers-access/AccessProductApplication/AccessProductApplicationDetails/NetworkDocument/NetworkUpdateRequestSegments';
import AccessProductApplicationDetailsVehicle from 'containers-access/AccessProductApplication/AccessProductApplicationDetails/AccessVehicleExemptionSet';
import AccessProductChangeSet from 'containers-access/AccessProductChangeSet';
import NetworkAPCS from 'containers-access/AccessProductChangeSet/Network';
import NetworkNURAPCS from 'containers-access/AccessProductChangeSet/Network/NetworkUpdateRequestSegments';

import AccessProductChangeSetAPCS from 'containers-access/AccessProductChangeSet/AccessProductApplication';
import AccessProductChangeSetNetworkAPCS from 'containers-access/AccessProductChangeSet/AccessProductApplication/Network';
import AccessProductChangeSetNetworkNURAPCS from 'containers-access/AccessProductChangeSet/AccessProductApplication/Network/NetworkUpdateRequestSegments';
import AccessProductChangeSetVehicleAPCS from 'containers-access/AccessProductChangeSet/AccessProductApplication/AccessVehicleExemptionSet';

import AccessPublicNoticeOfAmendment from 'containers-access/AccessPublicNoticeOfAmendment';
import ViewAccessPublicNoticeOfAmendment from 'containers-access/AccessPublicNoticeOfAmendment/ViewAccessPublicNoticeOfAmendment';
import VehicleNetworkMapIncDraft from 'containers-spatial/VehicleNetworkMapIncDraft';

export default {
  routes: [
    AccessInstrument,
    CreateAccessInstrument,
    AccessInstrumentDetails,
    CreateAccessProductApplicationAI,
    AccessProductAI,
    AccessProduct,
    CreateAccessProduct,
    AccessProductDetails,
    AccessProductDetailsVehicle,
    AccessProductDocumentAP,
    AccessProductDocumentNetworkAPAI,
    AccessProductDocumentNetworkNURAPAI,
    AccessProductDocumentVehicleAPAI,
    AccessProductDocumentNetworkAI,
    AccessProductDocumentNetworkNURAI,
    CreateAccessProductChangeSetAI,
    AccessProductDocumentVehicleAI,
    AccessProductApplicationAPAI,
    AccessProductApplicationAI,
    CreateAccessProductApplicationAPAI,
    AccessProductApplicationNetworkDocumentAPAI,
    AccessProductApplicationNetworkNURDocumentAPAI,
    AccessProductApplicationNetworkAPAI,
    AccessProductApplicationNetworkNURAPAI,
    AccessProductApplicationVehicleAPAI,
    AccessProductApplicationNetworkDocumentAI,
    AccessProductApplicationNetworkNURDocumentAI,
    AccessProductApplicationNetworkAI,
    AccessProductApplicationNetworkNURAI,
    AccessProductApplicationVehicleAI,
    AccessProductDocumentAPAI,
    AccessProductVehicleAPAI,
    AccessProductDocumentAI,
    AccessProductDocument,
    AccessProductDocumentDetail,
    CreateAccessProductChangeSetAPD,
    NetworkAPD,
    NetworkNURAPD,
    VehicleAPD,
    AccessProductDocumentAPA,
    AccessProductDocumentNetworkAPA,
    AccessProductDocumentNetworkNURAPA,
    AccessProductDocumentVehicleAPA,
    AccessProductDocumentAPDAI,
    AccessProductDocumentVehicleAPDAI,
    AccessProductDocumentNetworkAPDAI,
    AccessProductDocumentNetworkNURAPDAI,
    AccessProductChangeSetAPCS,
    AccessProductChangeSetVehicleAPCS,
    AccessProductChangeSetNetworkAPCS,
    AccessProductChangeSetNetworkNURAPCS,
    CreatePreApproval2,
    CreateAccessProductChangeSetAPDPreapproval,
    CreatePreApproval,
    CreatePreApprovalNetwork,
    JoinAccessProductDocument,
    JoinRelatedAccessProductDocument,
    NetworkAPCS,
    NetworkNURAPCS,
    Networks,
    NetworkSegments,
    NetworkUpdateRequestSegments,
    Preapprovals,
    PreapprovalDetail,
    NetworkPreapproval,
    NetworkNURPreapproval,
    PreApprovalAPA,
    PreApprovalVehicleAPA,
    PreApprovalNetworkAPA,
    PreApprovalNetworkNURAPA,
    AccessProductApplication,
    CreateAccessProductApplication,
    AccessProductApplicationDetailsNetwork,
    AccessProductApplicationDetailsNetworkNUR,
    AccessProductApplicationDetailsNetworkDocument,
    AccessProductApplicationDetailsNetworkNURDocument,
    AccessProductApplicationDetailsVehicle,
    AccessProductApplicationDetails,
    AccessProductChangeSet,
    ViewAccessPublicNoticeOfAmendment,
    AccessPublicNoticeOfAmendment,
    VehicleNetworkMapIncDraft,
  ],
  path: '/networks',
  menuTitle: 'Networks',
  menuDescription:
    'Manage heavy vehicle network access on public road infrastructure',
  showChildRoutesInMenu: true,
  menuIcon: EditRoadIcon,
};
