import React from 'react';

import {
  ListSubheader as MuiListSubheader,
  ListSubheaderProps as MuiListSubheaderProps,
  Theme,
} from '@mui/material';
import {useTheme} from '@mui/styles';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

import {DSL_Overline} from '../../../Typography/Overline';
import {DSL_Divider} from '../../Divider';

export type DSL_PopperListSubheaderProps = Omit<
  MuiListSubheaderProps,
  DSL_RestrictedVisualProps
>;

export const DSL_PopperListSubheader = ({
  children,
  ...props
}: DSL_PopperListSubheaderProps) => {
  const {zIndex} = useTheme<Theme>();
  return (
    <MuiListSubheader
      component={'span'}
      disableGutters
      sx={{
        display: 'flex',
        flexDirection: 'column',
        // This ensures subheaders appear above things like CardActionAreas correctly
        // This component should always be used in a relative zIndex context, it shouldn't impact other contexts
        zIndex: zIndex.overlay + 1,
      }}
      {...props}
    >
      <DSL_Overline color={'text.secondary'} py={1} px={3}>
        {children}
      </DSL_Overline>
      <DSL_Divider />
    </MuiListSubheader>
  );
};

DSL_PopperListSubheader.displayName = 'DSL_PopperListSubheader';

export {
  //
  /** @deprecated use DSL_PopperListSubheaderProps instead*/
  DSL_PopperListSubheaderProps as PopperListSubheaderProps, //
  /** @deprecated use DSL_PopperListSubheader instead*/
  DSL_PopperListSubheader as PopperListSubheader,
};
