import React, {ReactNode} from 'react';

import {
  DSL_PopperProps,
  DSL_FlexRow,
  DSL_ExternalLinkCardActionArea,
  DSL_Body1,
  DSL_ExternalLinkOutlined,
} from 'components/DesignSystem/Library';

export interface ExternalLinkOptionProps {
  label: ReactNode;
  popperProps?: DSL_PopperProps;
  closePopper?: () => void;
  onClick?: () => void;
  href?: string;
  to?: string;
}

export const ExternalLinkOption = ({
  label,
  popperProps,
  closePopper,
  href = '',
  to,
}: ExternalLinkOptionProps) => {
  return (
    <DSL_FlexRow
      width={'100%'}
      onClick={() => {
        popperProps?.closePopper();
        closePopper?.();
      }}
    >
      <DSL_ExternalLinkCardActionArea to={to} href={href} width={'100%'}>
        <DSL_FlexRow
          px={2}
          py={1}
          justifyContent={'space-between'}
          width={'100%'}
        >
          <DSL_Body1>{label}</DSL_Body1>
          <DSL_ExternalLinkOutlined />
        </DSL_FlexRow>
      </DSL_ExternalLinkCardActionArea>
    </DSL_FlexRow>
  );
};
