import React, {CSSProperties} from 'react';

import {Theme} from '@mui/material';

// You can find the complete list of variables here: https://github.com/ckeditor/ckeditor5/tree/master/packages/ckeditor5-theme-lark/theme/ckeditor5-ui/globals

export default function (theme: Theme): CSSProperties {
  const styles: {[record: string]: string} = {
    // '/* Overrides the border radius setting in the theme. */
    ['--ck-border-radius']: `${theme.shape.borderRadius}px`,

    ['--ck-z-default']: '1210',

    // '/* Overrides the default font size in the theme. */
    ['--ck-font-size-base']: theme.typography.body1.fontSize as string,

    /* -- Overrides base colors. ------------------------------------------------------------- */
    ['--ck-color-base-background']: theme.palette.background.default,
    ['--ck-color-base-border']: theme.palette.divider,
    ['--ck-color-base-foreground']: theme.palette.text.primary,
    ['--ck-color-base-text']: theme.palette.text.primary,
    ['--ck-color-base-action']: theme.palette.action.active,
    ['--ck-color-base-active']: theme.palette.action.active,
    ['--ck-color-base-active-focus']: theme.palette.action.focus,
    ['--ck-color-base-focus']: theme.palette.action.focus,
    ['--ck-color-base-error']: theme.palette.error.main,

    /* -- Overrides foundation colors. ------------------------------------------------------------- */

    ['--ck-custom-background']: theme.palette.background.default,
    ['--ck-custom-foreground']: theme.palette.text.primary,
    ['--ck-custom-border']: theme.palette.divider,
    ['--ck-inner-shadow']: 'none',
    ['--ck-color-text']: theme.palette.text.primary,
    ['--ck-color-focus-border']: theme.palette.divider,
    ['--ck-color-input-text']: theme.palette.text.primary,
    ['--ck-color-shadow-drop']: 'none',
    ['--ck-color-shadow-drop-active']: 'none',
    ['--ck-color-shadow-inner']: 'none',
    ['--ck-focus-ring']: `1px solid ${theme.palette.divider}`,
    ['--ck-color-input-error-border']: theme.palette.error.main,
    ['--ck-color-focus-outer-shadow']: 'none',
    ['--ck-color-focus-disabled-shadow']: 'none',
    ['--ck-color-focus-error-shadow:']: 'none',

    /* -- Overrides the default .ck-button class colors. ---------------------------------------- */

    ['--ck-color-button-default-background']: theme.palette.background.default,
    ['--ck-color-button-default-hover-background']: theme.palette.action.hover,
    ['--ck-color-button-default-active-background']:
      theme.palette.action.active,
    ['--ck-color-button-default-active-shadow']: 'none',
    ['--ck-color-button-default-disabled-background']:
      theme.palette.action.disabledBackground,
    ['--ck-color-button-on-color']: theme.palette.primary.contrastText,
    ['--ck-color-button-on-background']: theme.palette.primary.main,
    ['--ck-color-button-on-hover-background']: theme.palette.primary.dark,
    ['--ck-color-button-on-active-background']: theme.palette.primary.light,
    ['--ck-color-button-on-active-shadow']: 'none',
    ['--ck-color-button-on-disabled-background']:
      theme.palette.action.disabledBackground,

    ['--ck-color-button-action-background']: theme.palette.action.hover,
    ['--ck-color-button-action-hover-background']: theme.palette.action.hover,
    ['--ck-color-button-action-active-background']: theme.palette.action.active,
    ['--ck-color-button-action-active-shadow']: 'none',
    ['--ck-color-button-action-disabled-background']:
      theme.palette.action.disabledBackground,
    ['--ck-color-button-action-text']: theme.palette.text.primary,
    ['--ck-color-button-save']: theme.palette.success.main,
    ['--ck-color-button-cancel']: theme.palette.error.main,

    ['--ck-color-switch-button-off-background']: theme.palette.secondary.main,
    ['--ck-color-switch-button-off-hover-background']:
      theme.palette.secondary.dark,
    ['--ck-color-switch-button-on-background']: theme.palette.primary.main,
    ['--ck-color-switch-button-on-hover-background']:
      theme.palette.primary.dark,
    ['--ck-color-switch-button-inner-background']:
      theme.palette.background.default,
    ['--ck-color-switch-button-inner-shadow']: 'none',

    // '/* -- Overrides the default .ck-dropdown class colors. -------------------------------------- */

    ['--ck-color-dropdown-panel-background']: theme.palette.background.default,
    ['--ck-color-dropdown-panel-border']: theme.palette.divider,

    // '/* -- Overrides the default .ck-splitbutton class colors. ----------------------------------- */

    ['--ck-color-split-button-hover-background']: theme.palette.action.hover,
    ['--ck-color-split-button-hover-border']: theme.palette.divider,

    // '/* -- Overrides the default .ck-input class colors. ----------------------------------------- */

    ['--ck-color-input-background']: theme.palette.background.default,
    ['--ck-color-input-border']: theme.palette.divider,
    ['--ck-color-input-disabled-background']:
      theme.palette.action.disabledBackground,
    ['--ck-color-input-disabled-border']: theme.palette.divider,
    ['--ck-color-input-disabled-text']: theme.palette.text.disabled,

    // '/* -- Overrides the default .ck-labeled-field-view class colors. ---------------------------- */

    ['--ck-color-labeled-field-label-background']:
      theme.palette.background.default,

    // '/* -- Overrides the default .ck-list class colors. ------------------------------------------ */

    ['--ck-color-list-background']: theme.palette.background.default,
    ['--ck-color-list-button-hover-background']: theme.palette.action.hover,
    ['--ck-color-list-button-on-background']: theme.palette.primary.main,
    ['--ck-color-list-button-on-background-hover']: theme.palette.primary.dark,
    ['--ck-color-list-button-on-background-focus']: theme.palette.primary.dark,
    ['--ck-color-list-button-on-text']: theme.palette.primary.contrastText,

    // '/* -- Overrides the default .ck-balloon-panel class colors. --------------------------------- */
    ['--ck-color-panel-background']: theme.palette.background.default,
    ['--ck-color-panel-border']: theme.palette.divider,

    // '/* -- Overrides the default .ck-toolbar class colors. --------------------------------------- */

    ['--ck-color-toolbar-background']: theme.palette.background.default,
    ['--ck-color-toolbar-border']: theme.palette.divider,

    // '/* -- Overrides the default .ck-tooltip class colors. --------------------------------------- */

    ['--ck-color-tooltip-background']: theme.palette.action.active,
    ['--ck-color-tooltip-text']: theme.palette.common.white,

    // '/* -- Overrides the default colors used by the ckeditor5-image package. --------------------- */

    ['--ck-color-image-caption-background']: theme.palette.primary.main,
    ['--ck-color-image-caption-text']: theme.palette.primary.contrastText,

    // '/* -- Overrides the default colors used by the ckeditor5-widget package. -------------------- */

    ['--ck-color-widget-blurred-border']: theme.palette.divider,
    ['--ck-color-widget-hover-border']: theme.palette.divider,
    ['--ck-color-widget-editable-focus-background']: theme.palette.action.focus,

    /* -- Upload -------------------------------------------------------------------------------- */

    ['--ck-color-upload-bar-background']: theme.palette.primary.main,

    /* -- Link -------------------------------------------------------------------------------- */

    ['--ck-color-link-default']: theme.palette.primary.main,
    ['--ck-color-link-selected-background']: theme.palette.primary.shades?.[
      '4p'
    ] as string,
    ['--ck-color-link-fake-selection']: theme.palette.primary.shades?.[
      '8p'
    ] as string,
    /* -- Search result highlight ---------------------------------------------------------------- */

    ['--ck-color-highlight-background']: theme.palette.secondary.main,

    ['--ck-color-table-caption-background']: theme.palette.background.default,
    ['--ck-color-table-caption-text']: theme.palette.text.primary,
    ['--ck-color-table-caption-highlighted-background']:
      theme.palette.background.paper,

    ['--ck-powered-by-text-color']: theme.palette.text.primary,
    ['--ck-powered-by-background']: theme.palette.background.default,

    ['--ck-color-color-grid-check-icon']: theme.palette.success.main,
  };

  // TODO: Figure out a way that the app doesn't need this override: https://nhvr.atlassian.net/browse/NHVRREP-30503

  Object.keys(styles).forEach(key => {
    styles[key] += ' !important';
  });

  return styles as React.CSSProperties;
}
