import React from 'react';

import {
  FormControl as MuiFormControl,
  FormControlProps as MuiFormControlProps,
} from '@mui/material';

import {DSL_GridItemProps} from '../../../Layout/Grid';
import {useGridItemStyles} from '../../../Layout/hooks';

export type DSL_FormControlProps = MuiFormControlProps & DSL_GridItemProps;

export const DSL_FormControl = ({
  span,
  xs,
  sm,
  md,
  lg,
  xl,
  ...props
}: DSL_FormControlProps) => {
  const gridStyles = useGridItemStyles({span, xs, sm, md, lg, xl});
  return (
    <MuiFormControl sx={gridStyles} variant="outlined" fullWidth {...props} />
  );
};

DSL_FormControl.displayName = 'DSL_FormControl';

export {
  //
  /** @deprecated use DSL_FormControlProps instead*/
  DSL_FormControlProps as FormControlProps, //
  /** @deprecated use DSL_FormControl instead*/
  DSL_FormControl as FormControl,
};
