import {useCallback, useRef} from 'react';

import {useSizeObserver} from '../hooks/useSizeObserver';

import {useDrawerContext} from './DrawerProvider';

export function useDrawerOffsetObserver() {
  const {setOffsetTop} = useDrawerContext();
  const offsettingContainerRef = useRef<HTMLDivElement | null>(null);

  const onResize = useCallback(
    (entries: ResizeObserverEntry[]) =>
      setOffsetTop(Math.round(entries[0].borderBoxSize[0].blockSize)),
    [setOffsetTop],
  );

  useSizeObserver({ref: offsettingContainerRef, onResize});

  return {
    offsettingContainerRef,
  };
}
