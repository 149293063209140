import React from 'react';

import {
  CardHeader as MuiCardHeader,
  CardHeaderProps as MuiCardHeaderProps,
} from '@mui/material';

import {DSL_RestrictedVisualProps} from '../../../types';

export interface DSL_CardHeaderProps
  extends Omit<MuiCardHeaderProps, 'variant' | DSL_RestrictedVisualProps> {
  removePaddingBottom?: boolean;
  removePaddingTop?: boolean;
  fullWidth?: boolean;
}

export const DSL_CardHeader = ({
  removePaddingBottom,
  removePaddingTop,
  fullWidth,
  ...props
}: DSL_CardHeaderProps) => {
  return (
    <MuiCardHeader
      sx={{
        paddingBottom: removePaddingBottom ? 0 : undefined,
        paddingTop: removePaddingTop ? 0 : undefined,
        width: fullWidth ? '100%' : undefined,
      }}
      {...props}
    />
  );
};

DSL_CardHeader.displayName = 'DSL_CardHeader';

export {
  //
  /** @deprecated use DSL_CardHeaderProps instead*/
  DSL_CardHeaderProps as CardHeaderProps, //
  /** @deprecated use DSL_CardHeader instead*/
  DSL_CardHeader as CardHeader,
};
