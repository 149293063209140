import * as Types from '../../../../typeDefs/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPbsApplicationPbsApprovalVehicleMappingsQueryVariables = Types.Exact<{
  id: Types.Scalars['Float']['input'];
  positionIndex: Types.Scalars['Float']['input'];
}>;


export type GetPbsApplicationPbsApprovalVehicleMappingsQuery = { __typename?: 'Query', pbsApplicationPbsApprovalVehicleMappings?: Array<{ __typename?: 'PbsApprovalVehicleMappingPublic', id?: number | null, pbsApprovalId?: number | null, pbsVehicleUnitSetId?: number | null, status?: string | null, unitPosition?: Array<number | null> | null, vehicleRegistrationExtendedId?: string | null, vehicleVin?: string | null } | null> | null };


export const GetPbsApplicationPbsApprovalVehicleMappingsDocument = gql`
    query getPbsApplicationPbsApprovalVehicleMappings($id: Float!, $positionIndex: Float!) {
  pbsApplicationPbsApprovalVehicleMappings(id: $id, positionIndex: $positionIndex) {
    id
    pbsApprovalId
    pbsVehicleUnitSetId
    status
    unitPosition
    vehicleRegistrationExtendedId
    vehicleVin
  }
}
    `;

/**
 * __useGetPbsApplicationPbsApprovalVehicleMappingsQuery__
 *
 * To run a query within a React component, call `useGetPbsApplicationPbsApprovalVehicleMappingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPbsApplicationPbsApprovalVehicleMappingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPbsApplicationPbsApprovalVehicleMappingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      positionIndex: // value for 'positionIndex'
 *   },
 * });
 */
export function useGetPbsApplicationPbsApprovalVehicleMappingsQuery(baseOptions: Apollo.QueryHookOptions<GetPbsApplicationPbsApprovalVehicleMappingsQuery, GetPbsApplicationPbsApprovalVehicleMappingsQueryVariables> & ({ variables: GetPbsApplicationPbsApprovalVehicleMappingsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPbsApplicationPbsApprovalVehicleMappingsQuery, GetPbsApplicationPbsApprovalVehicleMappingsQueryVariables>(GetPbsApplicationPbsApprovalVehicleMappingsDocument, options);
      }
export function useGetPbsApplicationPbsApprovalVehicleMappingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPbsApplicationPbsApprovalVehicleMappingsQuery, GetPbsApplicationPbsApprovalVehicleMappingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPbsApplicationPbsApprovalVehicleMappingsQuery, GetPbsApplicationPbsApprovalVehicleMappingsQueryVariables>(GetPbsApplicationPbsApprovalVehicleMappingsDocument, options);
        }
export function useGetPbsApplicationPbsApprovalVehicleMappingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPbsApplicationPbsApprovalVehicleMappingsQuery, GetPbsApplicationPbsApprovalVehicleMappingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPbsApplicationPbsApprovalVehicleMappingsQuery, GetPbsApplicationPbsApprovalVehicleMappingsQueryVariables>(GetPbsApplicationPbsApprovalVehicleMappingsDocument, options);
        }
export type GetPbsApplicationPbsApprovalVehicleMappingsQueryHookResult = ReturnType<typeof useGetPbsApplicationPbsApprovalVehicleMappingsQuery>;
export type GetPbsApplicationPbsApprovalVehicleMappingsLazyQueryHookResult = ReturnType<typeof useGetPbsApplicationPbsApprovalVehicleMappingsLazyQuery>;
export type GetPbsApplicationPbsApprovalVehicleMappingsSuspenseQueryHookResult = ReturnType<typeof useGetPbsApplicationPbsApprovalVehicleMappingsSuspenseQuery>;
export type GetPbsApplicationPbsApprovalVehicleMappingsQueryResult = Apollo.QueryResult<GetPbsApplicationPbsApprovalVehicleMappingsQuery, GetPbsApplicationPbsApprovalVehicleMappingsQueryVariables>;