import React, {ForwardedRef, forwardRef, ReactNode} from 'react';

import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormControlLabel,
} from '@mui/material';

import {DSL_UseGridItemStyles} from '../../../';
import {DSL_RestrictedVisualProps} from '../../../types';
import {DSL_FormControl} from '../FormControl';

export type DSL_CheckboxInputProps = Omit<
  MuiCheckboxProps,
  'variant' | 'color' | 'ref' | DSL_RestrictedVisualProps
> &
  DSL_UseGridItemStyles & {
    label?: ReactNode;
    error?: boolean;
    textAlign?: React.CSSProperties['textAlign'];
    justifyContent?: React.CSSProperties['justifyContent'];
    formControlLabelMarginRightOverride?: number;
  };

export const DSL_CheckboxInput = forwardRef(
  (
    {
      label = '',
      textAlign = 'inherit',
      span,
      xs,
      sm,
      md,
      lg,
      xl,
      justifyContent,
      error,
      formControlLabelMarginRightOverride,
      ...props
    }: DSL_CheckboxInputProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const checkbox = (
      <MuiCheckbox
        inputRef={ref}
        color={error ? 'error' : 'primary'}
        sx={{
          color: error ? 'error.main' : undefined,
        }}
        {...props}
      />
    );

    return label ? (
      <DSL_FormControl
        error={error}
        span={span}
        xs={xs}
        sm={sm}
        md={md}
        lg={lg}
        xl={xl}
      >
        <FormControlLabel
          control={checkbox}
          label={label}
          sx={{
            textAlign,
            color: 'text.primary',
            justifyContent: justifyContent ?? 'normal',
            ...(formControlLabelMarginRightOverride && {
              mr: formControlLabelMarginRightOverride,
            }),
          }}
        />
      </DSL_FormControl>
    ) : (
      checkbox
    );
  },
);

DSL_CheckboxInput.displayName = 'DSL_CheckboxInput';

export {
  //
  /** @deprecated use DSL_CheckboxInputProps instead*/
  DSL_CheckboxInputProps as CheckboxInputProps, //
  /** @deprecated use DSL_CheckboxInput instead*/
  DSL_CheckboxInput as CheckboxInput,
};
