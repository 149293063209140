import React from 'react';

import {
  DSL_BaseIconInfo,
  DSL_BaseIconInfoProps,
} from 'components/DesignSystem/Library/IconInfo/BaseIconInfo';
import {DSL_RemoveRedEyeIcon} from 'components/DesignSystem/Library/Icons';

export type DSL_RemoveRedEyeIconInfoProps = Omit<DSL_BaseIconInfoProps, 'icon'>;

export const DSL_RemoveRedEyeIconInfo = (
  props: DSL_RemoveRedEyeIconInfoProps,
) => {
  return <DSL_BaseIconInfo icon={<DSL_RemoveRedEyeIcon />} {...props} />;
};

DSL_RemoveRedEyeIconInfo.displayName = 'DSL_RemoveRedEyeIconInfo';

export {
  //
  /** @deprecated use DSL_RemoveRedEyeIconInfoProps instead*/
  DSL_RemoveRedEyeIconInfoProps as RemoveRedEyeIconInfoProps, //
  /** @deprecated use DSL_RemoveRedEyeIconInfo instead*/
  DSL_RemoveRedEyeIconInfo as RemoveRedEyeIconInfo,
};
