import React from 'react';
import {
  Content,
  ContentMap,
  DomainContentMap,
  ContentKeys,
} from 'containers-content';
import {AuthorisationDomains} from '@regulatory-platform/common-utils/dist';

export type ContentKeysContainerHome = 'HOME_APP_NAME';

const nhvr: ContentMap<ContentKeysContainerHome> = {
  HOME_APP_NAME: (
    <>
      the <Content cKey={'GLOBAL_APP_NAME_LONG'} />
    </>
  ),
};

const wa: ContentMap<ContentKeysContainerHome> = {
  HOME_APP_NAME: <Content cKey={'GLOBAL_APP_NAME_SHORT'} />,
};

const adf: ContentMap<ContentKeysContainerHome> = {};

export default {
  nhvr,
  wa,
  adf,
} as DomainContentMap<AuthorisationDomains, ContentKeys>;
