import * as React from 'react';

import {createFilterOptions} from '@mui/material/Autocomplete';
import {AutocompleteValue} from '@mui/material/useAutocomplete';
import * as R from 'ramda';

import {MultiSelectAutocompleteInputProps} from 'components/DesignSystem/Library';

import {useFormService} from '../FormProvider';
import {useFormFieldPropsSelector} from '../hooks';
import {getArrayFieldProps} from '../utils/getArrayFieldProps';

const filter = createFilterOptions<string>();
export function useMultiSelectAutocomplete(
  fieldRef: string,
  placeholder?: string,
  items?: string[],
  recordsLoaderRef?: string,
): MultiSelectAutocompleteInputProps<string> {
  const service = useFormService();

  return useFormFieldPropsSelector(
    fieldRef,
    service,
    state => {
      const fieldProps = getArrayFieldProps<true, false, false>({
        fieldRef,
        state,
        items,
        multiple: true,
      });

      return {
        ...fieldProps,
        onChange: (
          e: React.SyntheticEvent,
          values: AutocompleteValue<string, true, false, false>,
        ): void => {
          service.send({
            type: 'CHANGEBLUR',
            fieldRef,
            value: R.isNil(values) ? [] : values,
          });
        },
        onBlur: (): void => {
          service.send({type: 'BLUR', fieldRef});
        },
        onOpen: (): void => {
          if (recordsLoaderRef) {
            service.send('TRIGGER_ACTOR_LOAD', {
              fieldRef: recordsLoaderRef,
            });
          }
        },
        filterOptions: (fOptions, params) => {
          return filter(fOptions, params);
        },
        placeholder,
      } as MultiSelectAutocompleteInputProps<string>;
    },
    ['filterOptions', 'onChange', 'onBlur', 'onOpen'],
  );
}
