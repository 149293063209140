import React from 'react';

import {
  DSP_UserEntityLoaderProvider,
  DSP_UserEntityAvatarWithName,
} from 'components/DesignSystem/Portal/Entity/UserEntity';

import {
  DSP_BaseAccreditationActionNotificationCard,
  DSP_BaseAccreditationActionNotificationCardProps,
} from '../Base/BaseAccreditationActionNotificationCard.Connector';

export interface DSP_AccreditationActionCustomerNotificationCardViewProps
  extends Omit<DSP_BaseAccreditationActionNotificationCardProps, 'avatar'> {
  userId: number | undefined;
}

export const DSP_AccreditationActionCustomerNotificationCardView = ({
  userId,
  notification,
}: DSP_AccreditationActionCustomerNotificationCardViewProps) => {
  return (
    <DSP_BaseAccreditationActionNotificationCard
      notification={notification}
      avatar={
        userId && (
          <DSP_UserEntityLoaderProvider entityId={userId}>
            <DSP_UserEntityAvatarWithName />
          </DSP_UserEntityLoaderProvider>
        )
      }
    />
  );
};

DSP_AccreditationActionCustomerNotificationCardView.displayName =
  'DSP_AccreditationActionCustomerNotificationCardView';
