import React from 'react';

import {DSL_SearchIcon} from 'components/DesignSystem/Library/Icons';

import {
  DSL_BaseCardIconButton,
  DSL_BaseCardIconButtonProps,
} from '../BaseCardIconButton';

type DSL_SearchCardIconButtonProps = Omit<
  DSL_BaseCardIconButtonProps,
  'children'
>;

export const DSL_SearchCardIconButton = (
  props: DSL_SearchCardIconButtonProps,
) => {
  return (
    <DSL_BaseCardIconButton {...props}>
      <DSL_SearchIcon color={props.disabled ? 'disabled' : 'action'} />
    </DSL_BaseCardIconButton>
  );
};

DSL_SearchCardIconButton.displayName = 'DSL_SearchCardIconButton';
