import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/pbsDocumentTemplates/:id',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "PbsDocumentTemplateDetails" */ './PbsDocumentTemplateDetails'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'PBS Document Template Details',
  menuIcon: LocalShippingOutlinedIcon,
  authorisation: {
    apiKey: null,
    policies: [{role: 'operationsPBSAdmin'}] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'PBS Document Templates',
      to: '..',
    },
  ],
};
