import React, {FC, ReactNode} from 'react';

import {Breakpoint} from '@mui/system';

import {DSL_FlexRow, DSL_FlexRowProps} from '../FlexRow';
import {useOverflowManager} from '../hooks/useOverflowManager';

export interface DSL_OverflowManagerProps
  extends Omit<DSL_FlexRowProps, 'children'> {
  maxItems: number | {[BreakpointName in Breakpoint]?: number};
  renderOverflowItem: (maxItems: number) => JSX.Element;
  children: ReactNode[];
  onOverflowChange?: () => void;
  showAll?: boolean;
}

export const DSL_OverflowManager: FC<DSL_OverflowManagerProps> = ({
  showAll = false,
  renderOverflowItem,
  onOverflowChange,
  children,
  maxItems,
  ...props
}: DSL_OverflowManagerProps): JSX.Element => {
  const {effectiveMaxItems} = useOverflowManager({
    children,
    maxItems,
    onOverflowChange,
  });

  return (
    <DSL_FlexRow {...props}>
      {showAll ? children : children.slice(0, effectiveMaxItems)}
      {!showAll &&
        effectiveMaxItems < children.length &&
        renderOverflowItem(effectiveMaxItems)}
    </DSL_FlexRow>
  );
};

export {
  //
  /** @deprecated use DSL_OverflowManagerProps instead*/
  DSL_OverflowManagerProps as OverflowManagerProps, //
  /** @deprecated use DSL_OverflowManager instead*/
  DSL_OverflowManager as OverflowManager,
};
