import Layers from '@mui/icons-material/Layers';
import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/roads',
  component: lazy(() => import(/* webpackChunkName: "Roads" */ './Roads')),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'Road details',
  menuDescription:
    'Tools to define the heavy vehicle access details of road segments',
  menuIcon: Layers,
  parent: true,
  authorisation: {
    apiKey: 'RoadDetailWithRelations.findById',
    policies: [
      {
        role: 'partnerSpatialRead',
      },
      {
        role: 'providerSpatialRead',
      },
      {
        role: 'operationsSpatialRead',
      },
    ] as AuthorisationPolicy[],
  },
  sections: [],
};
