import React from 'react';

import {DSL_IconWrapper} from '../IconWrapper';
import {DSL_IconProps} from '../types';

const DSL_KeepLeft = (props: DSL_IconProps) => {
  return (
    <DSL_IconWrapper
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          fill="none"
          viewBox="0 0 60 60"
          {...props}
        >
          <g clipPath="url(#a)">
            <path fill="#fff" d="M0 0h60v60H0z" />
            <path
              fill="#000"
              fillRule="evenodd"
              d="m28.732 21.768 3.536-3.535L23 8.965l-9.268 9.268 3.536 3.535 3.232-3.232V48h5V18.536z"
              clipRule="evenodd"
            />
            <path
              fill="#000"
              fillOpacity={0.3}
              fillRule="evenodd"
              d="M36 48V13h5v35z"
              clipRule="evenodd"
            />
          </g>
          <defs>
            <clipPath id="a">
              <path fill="#fff" d="M0 0h60v60H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      {...props}
    />
  );
};

export default React.memo(DSL_KeepLeft);
