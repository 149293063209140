import {schemaJsonPointerResolver} from '@regulatory-platform/common-utils';
import {FormEventObject, FormMachineContext} from './types';

export default function isFieldTouched(
  context: FormMachineContext,
  event: FormEventObject,
): boolean {
  const {fieldRef} = event;
  return (
    schemaJsonPointerResolver<boolean | undefined>(
      fieldRef,
      'x-valid/touched',
    )(context.schema) === true
  );
}
