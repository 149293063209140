import React from 'react';

import {TabStepComponentProps} from 'components/navigation/Tabs/FormTabs';
import {TabStep} from 'components/navigation/types';

import {
  Tab,
  TabContext,
  TabList,
  TabPanel,
} from 'components/DesignSystem/Library';

export interface DSP_ITabStep extends TabStep {
  error?: boolean;
}

export interface DSP_FormTabsViewProps {
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
  ) => void;
  label: string;
  disabled?: boolean;
  onStepChange: (label: string) => void;
  tabSteps: DSP_ITabStep[];
  variant?: 'fullWidth' | 'scrollable' | 'standard';
  activeHasErrors?: boolean;
  value: string;
}

export const DSP_FormTabsView = ({
  onChange,
  label,
  disabled,
  onStepChange,
  tabSteps,
  variant,
  activeHasErrors,
  value,
}: DSP_FormTabsViewProps) => {
  return (
    <TabContext value={value} data-id={'form-tabs-view-tab-context'}>
      <TabList
        data-id={'form-tabs-view-tab-list'}
        variant={variant}
        aria-label={label}
        onChange={onChange}
        error={activeHasErrors}
      >
        {tabSteps.map((step, index) => {
          return (
            <Tab
              aria-invalid={step.error}
              error={step.error}
              key={index}
              label={step.label}
              value={step.label}
            />
          );
        })}
      </TabList>
      {tabSteps.map((step, index) => {
        const DSP_ActiveStepComponent = tabSteps[index]
          .component as new () => React.Component<
          TabStepComponentProps,
          unknown
        >;

        return (
          <TabPanel key={index} value={step.label}>
            <DSP_ActiveStepComponent
              tabIndex={index}
              onStepChange={onStepChange}
              label={step.label}
              validateFields={step.validateFields}
              disabled={disabled}
            />
          </TabPanel>
        );
      })}
    </TabContext>
  );
};

DSP_FormTabsView.displayName = 'DSP_FormTabsView';

export {
  //
  /** @deprecated use DSP_FormTabsViewProps instead*/
  DSP_FormTabsViewProps as FormTabsViewProps, //
  /** @deprecated use DSP_FormTabsView instead*/
  DSP_FormTabsView as FormTabsView,
};
