import * as R from 'ramda';
import {TabStepConfig} from '../types';

export default function getTabConfigSteps(
  numberSteps: number,
): TabStepConfig[] {
  return R.times(idx => {
    return {
      index: idx,
      active: idx === 0,
      skipped: false,
      completed: false,
      touched: false,
    };
  }, numberSteps) as TabStepConfig[];
}
