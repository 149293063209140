import {lazy} from 'react';
import HomeIcon from '@mui/icons-material/Home';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';

export default {
  path: '/registrations/requestCertifiedCopy',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "RequestCertifiedCopy" */ './RequestCertifiedCopy'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Request certified copy process',
  menuIcon: HomeIcon,
  authorisation: {
    apiKey: 'VehicleRegistrationExtendedWithRelations.findById',
    policies: [
      {
        role: 'partnerRegistrationEnabled',
      },
      {
        role: 'customerRegistrationEnabled',
      },
    ] as AuthorisationPolicy[],
  },
  sections: [],
};
