import React, {ForwardedRef, useMemo} from 'react';

import {DSL_BaseTab, DSL_BaseTabProps} from '../BaseTab/BaseTab';

export interface DSL_VerticalTabProps
  extends Omit<DSL_BaseTabProps, 'icon' | 'iconPosition'> {
  icon?: DSL_BaseTabProps['icon'];
}

export const DSL_VerticalTab = React.forwardRef(
  (props: DSL_VerticalTabProps, ref: ForwardedRef<HTMLDivElement>) => {
    const sx = useMemo(() => ({minWidth: 0, minHeight: 0, p: 2}), []);

    return <DSL_BaseTab sx={sx} {...props} ref={ref} />;
  },
);

DSL_VerticalTab.displayName = 'DSL_VerticalTab';

export {
  //
  /** @deprecated use DSL_VerticalTabProps instead*/
  DSL_VerticalTabProps as VerticalTabProps, //
  /** @deprecated use DSL_VerticalTab instead*/
  DSL_VerticalTab as VerticalTab,
};
