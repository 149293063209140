import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import Applications from 'containers-pbs/Applications';
import ApplicationConfirmation from 'containers-pbs/Applications/ApplicationConfirmation';
import ApplicationSummary from 'containers-pbs/Applications/ApplicationSummary';
import DesignApprovalsGateway from 'containers-pbs/Applications/DesignApprovalGateway';
import NewApplication from 'containers-pbs/Applications/NewApplication';
import VehicleApprovalsGateway from 'containers-pbs/Applications/VehicleApprovalGateway';
import ApprovalLibrary from 'containers-pbs/ApprovalLibrary';
import ManageApprovalLibrary from 'containers-pbs/ApprovalLibrary/ManageApprovals';
import PbsApplicationVehicleApprovalGateway from 'containers-pbs/ApprovalLibrary/ManageApprovals/PbsApplicationsVA/PbsApplicationVehicleApprovalGateway';
import PbsApprovalVehicleMappingGateway from 'containers-pbs/ApprovalLibrary/ManageApprovals/VehicleInspections/PbsApprovalVehicleMappingGateway';
import VehicleSpecificationDetails from 'containers-pbs/ApprovalLibrary/ManageApprovals/VehicleInspections/VehicleSpecificationDetails';
import CertificationConfirmation from 'containers-pbs/ApprovalLibrary/ManageApprovals/VehicleInspections/CertificationConfirmation';
import ManageApprovalsNewApplication from 'containers-pbs/ApprovalLibrary/ManageApprovals/PbsApplications/NewApplication';
import ManageApprovalsApplicationConfirmation from 'containers-pbs/ApprovalLibrary/ManageApprovals/PbsApplications/ApplicationConfirmation';
import VehicleUnitSetDetails from 'containers-pbs/ApprovalLibrary/ManageApprovals/VehicleUnitSets/VehicleUnitSetDetails';
import VehicleUnitSetLayoutDetails from 'containers-pbs/ApprovalLibrary/ManageApprovals/VehicleUnitSetLayouts/VehicleUnitSetLayoutDetails';
import VehicleUnitSetTrailerDetails from 'containers-pbs/ApprovalLibrary/ManageApprovals/VehicleUnitSetTrailers/VehicleUnitSetTrailerDetails';
import NewVehicleApprovalGateway from 'containers-pbs/ApprovalLibrary/NewVehicleApprovalGateway';
import Assessors from 'containers-pbs/Assessors';
import CreateAssessor from 'containers-pbs/Assessors/CreateAssessor';
import AssessorDetails from 'containers-pbs/Assessors/AssessorDetails';
import Certifiers from 'containers-pbs/Certifiers';
import CreateCertifier from 'containers-pbs/Certifiers/CreateCertifier';
import CertifierDetails from 'containers-pbs/Certifiers/CertifierDetails';

export default {
  routes: [
    Applications,
    VehicleApprovalsGateway,
    DesignApprovalsGateway,
    NewApplication,
    ApplicationSummary,
    ApplicationConfirmation,
    ApprovalLibrary,
    ManageApprovalLibrary,
    PbsApplicationVehicleApprovalGateway,
    PbsApprovalVehicleMappingGateway,
    VehicleSpecificationDetails,
    CertificationConfirmation,
    ManageApprovalsNewApplication,
    ManageApprovalsApplicationConfirmation,
    VehicleUnitSetDetails,
    VehicleUnitSetLayoutDetails,
    VehicleUnitSetTrailerDetails,
    NewVehicleApprovalGateway,
    Assessors,
    CreateCertifier,
    AssessorDetails,
    Certifiers,
    CreateAssessor,
    CertifierDetails,
  ],
  menuLabel: 'PBS',
  path: '/pbs',
  menuTitle: 'Performance Based Standards',
  menuDescription:
    'Submit and track the status of your Design Approval and Vehicle Approval applications',
  showChildRoutesInMenu: true,
  menuIcon: LocalShippingOutlinedIcon,
};
