import React from 'react';

import {DSL_BaseGrid, DSL_BaseGridProps} from './BaseGrid';

export type DSL_FieldGroupProps = Omit<DSL_BaseGridProps, 'spacingUnit'>;
export const DSL_FieldGroup = (props: DSL_FieldGroupProps) => (
  <DSL_BaseGrid spacingUnit={2} {...props} />
);

export {
  //
  /** @deprecated use DSL_FieldGroupProps instead*/
  DSL_FieldGroupProps as FieldGroupProps, //
  /** @deprecated use DSL_FieldGroup instead*/
  DSL_FieldGroup as FieldGroup,
};
