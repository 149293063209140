import * as Types from '../../../../../typeDefs/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NotificationUserViewCreateAllMutationVariables = Types.Exact<{
  notificationUserViewsCreateAllInput: Array<Types.InputMaybe<Types.NotificationUserViewsInput>> | Types.InputMaybe<Types.NotificationUserViewsInput>;
}>;


export type NotificationUserViewCreateAllMutation = { __typename?: 'Mutation', notificationUserViewsControllerCreateAll?: Array<{ __typename?: 'NotificationUserViews', notificationUserViewId?: number | null, notificationId: number, userId?: number | null, viewedDate?: string | null } | null> | null };


export const NotificationUserViewCreateAllDocument = gql`
    mutation notificationUserViewCreateAll($notificationUserViewsCreateAllInput: [NotificationUserViewsInput]!) {
  notificationUserViewsControllerCreateAll(
    notificationUserViewsCreateAllInput: $notificationUserViewsCreateAllInput
  ) {
    notificationUserViewId
    notificationId
    userId
    viewedDate
  }
}
    `;
export type NotificationUserViewCreateAllMutationFn = Apollo.MutationFunction<NotificationUserViewCreateAllMutation, NotificationUserViewCreateAllMutationVariables>;

/**
 * __useNotificationUserViewCreateAllMutation__
 *
 * To run a mutation, you first call `useNotificationUserViewCreateAllMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationUserViewCreateAllMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationUserViewCreateAllMutation, { data, loading, error }] = useNotificationUserViewCreateAllMutation({
 *   variables: {
 *      notificationUserViewsCreateAllInput: // value for 'notificationUserViewsCreateAllInput'
 *   },
 * });
 */
export function useNotificationUserViewCreateAllMutation(baseOptions?: Apollo.MutationHookOptions<NotificationUserViewCreateAllMutation, NotificationUserViewCreateAllMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NotificationUserViewCreateAllMutation, NotificationUserViewCreateAllMutationVariables>(NotificationUserViewCreateAllDocument, options);
      }
export type NotificationUserViewCreateAllMutationHookResult = ReturnType<typeof useNotificationUserViewCreateAllMutation>;
export type NotificationUserViewCreateAllMutationResult = Apollo.MutationResult<NotificationUserViewCreateAllMutation>;
export type NotificationUserViewCreateAllMutationOptions = Apollo.BaseMutationOptions<NotificationUserViewCreateAllMutation, NotificationUserViewCreateAllMutationVariables>;