import React from 'react';

import {
  DSL_BackIconButton,
  DSL_BaseDrawerHeader,
  DSL_Flex,
  DSL_FlexColumn,
  DSL_FlexRow,
  DSL_FullscreenExitIconButton,
  DSL_Heading5,
  DSL_RemoveCircleIconButton,
  DSL_Tooltip,
} from 'components/DesignSystem/Library';

import {useDspSelectedSegmentsDrawerHeader} from './useSelectedSegmentsDrawerHeader';

export interface DSP_SelectedSegmentsDrawerHeaderProps {
  onClose: () => void;
  toggleMinimizeDrawer?: () => void;
  isMinimized?: boolean;
}

export const DSP_SelectedSegmentsDrawerHeader = ({
  onClose,
  toggleMinimizeDrawer,
  isMinimized,
}: DSP_SelectedSegmentsDrawerHeaderProps) => {
  const {onBackClick, title} = useDspSelectedSegmentsDrawerHeader();
  return (
    <DSL_BaseDrawerHeader>
      <DSL_FlexRow
        flexShrink={0}
        width={'100%'}
        boxSizing={'border-box'}
        flexWrap={'nowrap'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <DSL_FlexColumn gap={1}>
          <DSL_FlexRow gap={1} alignItems={'center'} flexWrap={'nowrap'}>
            {onBackClick && <DSL_BackIconButton onClick={onBackClick} />}
            <DSL_Heading5 ellipsisLines={1}>{title}</DSL_Heading5>
          </DSL_FlexRow>
        </DSL_FlexColumn>
        <DSL_FlexRow alignItems={'center'} gap={1}>
          {toggleMinimizeDrawer && (
            <DSL_Tooltip
              isClickable
              title={'Hide selection summary'}
              placement={'bottom'}
            >
              <DSL_Flex>
                <DSL_FullscreenExitIconButton
                  size={'large'}
                  aria-label={'Hide selection summary'}
                  aria-controls={'base-drawer'}
                  aria-expanded={!isMinimized}
                  key={'toggle-minimize-drawer'}
                  onClick={toggleMinimizeDrawer}
                />
              </DSL_Flex>
            </DSL_Tooltip>
          )}
          {onClose && (
            <DSL_Tooltip title={'Deselect all segments'} placement={'top'}>
              <DSL_Flex justifyContent={'flex-end'} alignItems={'flex-start'}>
                <DSL_RemoveCircleIconButton
                  aria-label={'Deselect all segments'}
                  size={'large'}
                  onClick={onClose}
                />
              </DSL_Flex>
            </DSL_Tooltip>
          )}
        </DSL_FlexRow>
      </DSL_FlexRow>
    </DSL_BaseDrawerHeader>
  );
};
