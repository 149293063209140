export function downloadBlobUrl(
  blob: Blob | undefined,
  fileName: string,
): void {
  if (!blob) {
    return;
  }

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');

  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
}
