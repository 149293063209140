import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/pnas',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "AccessPublicNoticeOfAmendment" */ './AccessPublicNoticeOfAmendment.Connector'
      ),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'Public Notices',
  menuIcon: LibraryAddCheckIcon,
  authorisation: {
    policies: [{role: 'operationsSpatialRead'}] as AuthorisationPolicy[],
  },
  sections: [],
};
