import React from 'react';

import {AccreditationApplicationWithRelations} from 'typeDefs/types';
import {NotificationItem} from 'utils/machines/notificationsMachine';

import {DSP_AccreditationApplicationOperationsNotificationCardView} from './AccreditationApplicationOperationsNotificationCard.View';
import {useAccreditationApplicationOperationsNotificationCard} from './useAccreditationApplicationOperationsNotificationCard';

export interface DSP_AccreditationApplicationOperationsNotificationCardProps {
  notification: NotificationItem<AccreditationApplicationWithRelations>;
}

export const DSP_AccreditationApplicationOperationsNotificationCard = (
  props: DSP_AccreditationApplicationOperationsNotificationCardProps,
) => {
  const viewProps =
    useAccreditationApplicationOperationsNotificationCard(props);

  return (
    <DSP_AccreditationApplicationOperationsNotificationCardView
      {...viewProps}
    />
  );
};

DSP_AccreditationApplicationOperationsNotificationCard.displayName =
  'DSP_AccreditationApplicationOperationsNotificationCard';
