import {useDspSelectedSegmentsDrawerContext} from '../SelectedSegmentsDrawer.Provider';

export const useDspSelectedSegmentsRoadList = () => {
  const {
    hasSelectedRoadId,
    isViewByRoads,
    hasFilterByAccessType,
    filteredRoads,
    currentBreakpoint,
    setSelectedRoadId,
    onSegmentHover,
    onSegmentHoverOut,
  } = useDspSelectedSegmentsDrawerContext();

  const handleRoadClick = (id: number) => {
    setSelectedRoadId(id);
  };

  const showFilteredRoads =
    !hasSelectedRoadId && (isViewByRoads || hasFilterByAccessType);

  return {
    roads: showFilteredRoads ? filteredRoads : [],
    currentBreakpoint,
    onClick: handleRoadClick,
    onSegmentHover,
    onSegmentHoverOut,
  };
};
