import * as Types from '../../../typeDefs/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserModelControllerVerifyTokenAccessMutationVariables = Types.Exact<{
  verifyTokenAccessInput: Types.VerifyTokenAccessInput;
}>;


export type UserModelControllerVerifyTokenAccessMutation = { __typename?: 'Mutation', userModelControllerVerifyTokenAccess?: { __typename?: 'JWTToken', refreshToken?: string | null, token?: string | null } | null };


export const UserModelControllerVerifyTokenAccessDocument = gql`
    mutation userModelControllerVerifyTokenAccess($verifyTokenAccessInput: VerifyTokenAccessInput!) {
  userModelControllerVerifyTokenAccess(
    verifyTokenAccessInput: $verifyTokenAccessInput
  ) {
    refreshToken
    token
  }
}
    `;
export type UserModelControllerVerifyTokenAccessMutationFn = Apollo.MutationFunction<UserModelControllerVerifyTokenAccessMutation, UserModelControllerVerifyTokenAccessMutationVariables>;

/**
 * __useUserModelControllerVerifyTokenAccessMutation__
 *
 * To run a mutation, you first call `useUserModelControllerVerifyTokenAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserModelControllerVerifyTokenAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userModelControllerVerifyTokenAccessMutation, { data, loading, error }] = useUserModelControllerVerifyTokenAccessMutation({
 *   variables: {
 *      verifyTokenAccessInput: // value for 'verifyTokenAccessInput'
 *   },
 * });
 */
export function useUserModelControllerVerifyTokenAccessMutation(baseOptions?: Apollo.MutationHookOptions<UserModelControllerVerifyTokenAccessMutation, UserModelControllerVerifyTokenAccessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserModelControllerVerifyTokenAccessMutation, UserModelControllerVerifyTokenAccessMutationVariables>(UserModelControllerVerifyTokenAccessDocument, options);
      }
export type UserModelControllerVerifyTokenAccessMutationHookResult = ReturnType<typeof useUserModelControllerVerifyTokenAccessMutation>;
export type UserModelControllerVerifyTokenAccessMutationResult = Apollo.MutationResult<UserModelControllerVerifyTokenAccessMutation>;
export type UserModelControllerVerifyTokenAccessMutationOptions = Apollo.BaseMutationOptions<UserModelControllerVerifyTokenAccessMutation, UserModelControllerVerifyTokenAccessMutationVariables>;