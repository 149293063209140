import React from 'react';

import {
  DSL_BaseAutocompleteInput,
  DSL_BaseAutocompleteInputProps,
} from '../BaseAutocompleteInput';

export type DSL_SingleSelectAutocompleteInputProps<T> = Omit<
  DSL_BaseAutocompleteInputProps<T, false, boolean, false>,
  'multiple' | 'disableClearable'
>;

export const DSL_SingleSelectAutocompleteInput = <T,>(
  props: DSL_SingleSelectAutocompleteInputProps<T>,
) => {
  // UX tweak so that the clear button doesn't show if the input is empty
  const disableClearable = !props.value || !props.inputValue;
  return (
    <DSL_BaseAutocompleteInput<T, false, boolean, false>
      multiple={false}
      disableClearable={disableClearable}
      {...props}
    />
  );
};

export {
  //
  /** @deprecated use DSL_SingleSelectAutocompleteInputProps instead*/
  DSL_SingleSelectAutocompleteInputProps as SingleSelectAutocompleteInputProps, //
  /** @deprecated use DSL_SingleSelectAutocompleteInput instead*/
  DSL_SingleSelectAutocompleteInput as SingleSelectAutocompleteInput,
};
