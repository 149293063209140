import {isValidByJsonPointer} from '@regulatory-platform/common-utils';
import * as R from 'ramda';
import {FormMachineContext} from 'utils/stores/types';

export default function isValidStep(
  context: FormMachineContext,
  fields: string[],
  onlyValidIfTouched = true,
  onlyInvalidIfTouched = false,
): boolean {
  return !R.any(
    fieldRef =>
      isValidByJsonPointer(
        fieldRef,
        onlyValidIfTouched,
        onlyInvalidIfTouched,
      )(context.schema) === false,
    fields,
  );
}
