import React from 'react';
import {useStyles} from '../image.styles';
import clsx from 'clsx';

type NoVehicleComponentProps = {
  mode?:
    | 'icon'
    | 'single'
    | 'card'
    | 'configuration'
    | 'iconConfiguration'
    | 'cardConfiguration';
};

/**
 * NoVehicleComponent
 */
const NoVehicleComponent: React.FC<NoVehicleComponentProps> = ({
  mode = 'configuration',
}: NoVehicleComponentProps) => {
  const classes = useStyles();

  const className = clsx(
    ['icon', 'iconConfiguration'].includes(mode)
      ? classes.iconImage
      : undefined,
    mode === 'icon' ? classes.iconImage : undefined,
    mode === 'single' ? classes.singleImage : undefined,
    mode === 'card' ? classes.cardImage : undefined,
    mode === 'cardConfiguration' ? classes.cardConfigurationImage : undefined,
    classes.defaultImage,
  );
  return (
    <img
      className={className}
      alt="No vehicle component"
      src={
        'https://nhvrappsprod.blob.core.windows.net/vehicle-components/default.png'
      }
    />
  );
};

export default NoVehicleComponent;
