import React from 'react';

import {ApolloQueryResult} from '@apollo/client';
import * as R from 'ramda';
import type {AccessProductDocumentViewPartnerPartial} from 'typeDefs/types';

import {
  DSP_SearchMultiSelectRelationFieldProps,
  DSP_SearchMultiSelectRelationField,
} from '../SearchMultiSelectRelationField';

import {
  AccessProductDocumentsPartnerSearchDocument,
  AccessProductDocumentsPartnerSearchQuery,
} from './query/AccessProductDocumentsPartnerSearch.generated';

export interface DSP_MultiSelectNetworkSearchRelationFieldProps
  extends Omit<
    DSP_SearchMultiSelectRelationFieldProps,
    'query' | 'queryVariables'
  > {
  excludedNetworks?: AccessProductDocumentViewPartnerPartial[];
}

export const DSP_MultiSelectNetworkSearchRelationField = ({
  fieldRef,
  fieldRelationRef,
  span,
  excludedNetworks,
  label,
}: Omit<
  DSP_MultiSelectNetworkSearchRelationFieldProps,
  'query'
>): JSX.Element => {
  return (
    <DSP_SearchMultiSelectRelationField
      label={label}
      fieldRef={fieldRef}
      fieldRelationRef={fieldRelationRef}
      query={AccessProductDocumentsPartnerSearchDocument}
      queryVariables={{showHidden: true}}
      span={span}
      getOptionLabel={(searchResult): string => searchResult?.name}
      clientSideFilter={(
        result: ApolloQueryResult<AccessProductDocumentsPartnerSearchQuery>,
      ) => {
        const networks = result.data?.accessProductDocumentsPartnerSearch ?? [];
        const excludedNetworkIds = R.map(
          n => n?.networkId,
          excludedNetworks ?? [],
        ) as number[];
        return {
          ...result,
          data: {
            accessProductDocumentsPartnerSearch:
              excludedNetworkIds.length > 0
                ? networks.filter(
                    n => !excludedNetworkIds.includes(n?.networkId as number),
                  )
                : networks,
          },
        };
      }}
    />
  );
};
