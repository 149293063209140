import React from 'react';

import {DSL_BaseGrid, DSL_BaseGridProps} from './BaseGrid';

export type DSL_LayoutGridContainerProps = DSL_BaseGridProps;
export const DSL_LayoutGridContainer = ({
  spacingUnit = 0,
  children,
  ...props
}: DSL_LayoutGridContainerProps) => {
  return (
    <DSL_BaseGrid
      data-name={'layout-grid-container'}
      spacingUnit={spacingUnit}
      {...props}
    >
      {children}
    </DSL_BaseGrid>
  );
};

DSL_LayoutGridContainer.displayName = 'DSL_LayoutGridContainer';

export {
  //
  /** @deprecated use DSL_LayoutGridContainerProps instead*/
  DSL_LayoutGridContainerProps as LayoutGridContainerProps, //
  /** @deprecated use DSL_LayoutGridContainer instead*/
  DSL_LayoutGridContainer as LayoutGridContainer,
};
