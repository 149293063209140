import React from 'react';

import {DSL_RemoveIcon} from 'components/DesignSystem/Library/Icons';

import {
  DSL_BaseCardIconButton,
  DSL_BaseCardIconButtonProps,
} from '../BaseCardIconButton';

type DSL_ZoomOutCardIconButtonProps = Omit<
  DSL_BaseCardIconButtonProps,
  'children'
>;

export const DSL_ZoomOutCardIconButton = (
  props: DSL_ZoomOutCardIconButtonProps,
) => {
  return (
    <DSL_BaseCardIconButton {...props}>
      <DSL_RemoveIcon color={props.disabled ? 'disabled' : 'action'} />
    </DSL_BaseCardIconButton>
  );
};

DSL_ZoomOutCardIconButton.displayName = 'DSL_ZoomOutCardIconButton';
