import {lazy} from 'react';
import WebAsset from '@mui/icons-material/WebAsset';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/accessVehicleExemptionSets/create',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "NewAccessVehicleExemptionSetGateway" */ './NewAccessVehicleExemptionSetGateway'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Add reference vehicle',
  menuIcon: WebAsset,
  authorisation: {
    apiKey: 'AccessVehicleExemptionSetWithRelations.create',
    policies: [
      {
        role: 'operationsSpatialAdmin',
      },
      {
        role: 'operationsAccessAdmin',
      },
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Reference vehicles',
      to: '..',
    },
  ],
};
