import React from 'react';

import {
  BoxProps as MuiBoxProps,
  Card as MuiCard,
  CardProps as MuiCardProps,
  Palette,
  PaletteColor,
} from '@mui/material';

import {DSL_RestrictedVisualProps} from '../../types';

type BorderColor = `${keyof Palette}.${keyof PaletteColor}`;

export interface DSL_BaseCardProps
  extends Omit<MuiCardProps, DSL_RestrictedVisualProps | 'onClick'> {
  bgcolor?: MuiBoxProps['bgcolor'];
  width?: React.CSSProperties['width'];
  height?: React.CSSProperties['height'];
  overflow?: React.CSSProperties['overflow'];
  borderColor?: BorderColor;
  borderWidth?: React.CSSProperties['borderWidth'];
  internal_sx?: MuiCardProps['sx'];
}

export const DSL_BaseCard = ({
  variant = 'outlined',
  bgcolor,
  width,
  overflow,
  borderColor,
  borderWidth,
  internal_sx,
  ...props
}: DSL_BaseCardProps) => {
  return (
    <MuiCard
      variant={variant}
      sx={{
        boxSizing: 'border-box',
        borderWidth,
        bgcolor,
        width,
        overflow,
        borderColor,
        ...internal_sx,
      }}
      {...props}
    />
  );
};

DSL_BaseCard.displayName = 'DSL_BaseCard';

export {
  //
  /** @deprecated use DSL_BaseCardProps instead*/
  DSL_BaseCardProps as BaseCardProps, //
  /** @deprecated use DSL_BaseCard instead*/
  DSL_BaseCard as BaseCard,
};
