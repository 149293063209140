import React from 'react';

import {DSL_BaseDrawer, DSL_BaseDrawerProps} from '../BaseDrawer';

import {useModalDrawer, DSL_UseModalDrawerArgs} from './useModalDrawer';

export interface DSL_ModalDrawerProps
  extends Pick<DSL_BaseDrawerProps, 'onClose' | 'children' | 'id' | 'isHidden'>,
    DSL_UseModalDrawerArgs {}

export const DSL_ModalDrawer = ({
  children,
  onClose,
  id,
  isHidden,
  ...args
}: DSL_ModalDrawerProps) => {
  const viewProps = useModalDrawer(args);

  return (
    <DSL_BaseDrawer
      id={id}
      data-name={'non-modal-drawer'}
      onClose={onClose}
      isHidden={isHidden}
      {...viewProps}
    >
      {children}
    </DSL_BaseDrawer>
  );
};

DSL_ModalDrawer.displayName = 'DSL_ModalDrawer';

export {
  //
  /** @deprecated use DSL_ModalDrawerProps instead*/
  DSL_ModalDrawerProps as ModalDrawerProps, //
  /** @deprecated use DSL_ModalDrawer instead*/
  DSL_ModalDrawer as ModalDrawer,
};
