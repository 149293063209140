import React, {Fragment} from 'react';

import {DSL_Divider} from 'components/DesignSystem/Library';

import {DSP_SelectedSegmentsSegmentCard} from '../SelectedSegmentsSegmentCard';

import {useDspSelectedSegmentsSegmentList} from './useSelectedSegmentsSegmentList';

export interface DSP_SelectedSegmentsSegmentListProps {
  onRemoveSegment?: (segmentId: number) => void;
  showChangeOptions?: boolean;
}

export const DSP_SelectedSegmentsSegmentList = ({
  onRemoveSegment,
  showChangeOptions,
}: DSP_SelectedSegmentsSegmentListProps) => {
  const {segments, activeSegmentId, ...props} =
    useDspSelectedSegmentsSegmentList();

  return (
    <>
      {segments.map((segment, index) => (
        <Fragment key={index}>
          <DSP_SelectedSegmentsSegmentCard
            segment={segment}
            active={activeSegmentId === segment.id}
            onRemoveSegment={onRemoveSegment}
            showChangeOptions={showChangeOptions}
            {...props}
          />
          <DSL_Divider />
        </Fragment>
      ))}
    </>
  );
};

DSP_SelectedSegmentsSegmentList.displayName = 'DSP_SelectedSegmentsSegmentList';
