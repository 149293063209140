import React from 'react';

import {DSL_IconWrapper} from '../IconWrapper';
import {DSL_IconProps} from '../types';

const DSL_MergeRight = (props: DSL_IconProps) => {
  return (
    <DSL_IconWrapper
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          fill="none"
          viewBox="0 0 60 60"
          {...props}
        >
          <g clipPath="url(#a)">
            <path fill="#fff" d="M0 0h60v60H0z" />
            <path
              fill="#000"
              fillOpacity={0.3}
              fillRule="evenodd"
              d="m44.268 46.232-6.142-6.142A17.5 17.5 0 0 1 33 27.716V16h-5v11.716a22.5 22.5 0 0 0 6.59 15.91l6.142 6.142z"
              clipRule="evenodd"
            />
            <path
              fill="#000"
              fillRule="evenodd"
              d="m36.232 22.267 3.536-3.535L30.5 9.464l-9.268 9.268 3.536 3.535L28 19.035v8.68a17.5 17.5 0 0 1-5.126 12.375l-6.142 6.142 3.536 3.536 6.142-6.143A22.5 22.5 0 0 0 33 27.715v-8.68z"
              clipRule="evenodd"
            />
          </g>
          <defs>
            <clipPath id="a">
              <path fill="#fff" d="M0 0h60v60H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      {...props}
    />
  );
};

export default React.memo(DSL_MergeRight);
