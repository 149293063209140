import React from 'react';

import {DSL_AddCircleIcon} from '../../../Icons';
import {DSL_BaseIconButton, DSL_BaseIconButtonProps} from '../BaseIconButton';

export type DSL_AddCircleIconButtonProps = Omit<
  DSL_BaseIconButtonProps,
  'color' | 'children'
>;

export const DSL_AddCircleIconButton = (
  props: DSL_AddCircleIconButtonProps,
): JSX.Element => {
  return (
    <DSL_BaseIconButton aria-label="more" {...props}>
      <DSL_AddCircleIcon />
    </DSL_BaseIconButton>
  );
};

DSL_AddCircleIconButton.displayName = 'DSL_AddCircleIconButton';

export {
  //
  /** @deprecated use DSL_AddCircleIconButtonProps instead*/
  DSL_AddCircleIconButtonProps as AddCircleIconButtonProps, //
  /** @deprecated use DSL_AddCircleIconButton instead*/
  DSL_AddCircleIconButton as AddCircleIconButton,
};
