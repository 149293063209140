import React, {useCallback} from 'react';

import {format} from 'date-fns';

import {
  BaseDateTimeRenderer,
  BaseDateTimeRendererProps,
} from 'components/DesignSystem/Library';

export type DSP_LongDateRendererProps = Omit<
  BaseDateTimeRendererProps,
  'formatter'
>;

export const DSP_LongDateRenderer = ({
  date,
  ...props
}: DSP_LongDateRendererProps) => {
  const formatter = useCallback(() => format(date, 'dd MMM yyyy'), [date]);

  return <BaseDateTimeRenderer date={date} formatter={formatter} {...props} />;
};

DSP_LongDateRenderer.displayName = 'DSP_LongDateRenderer';

export {
  //
  /** @deprecated use DSP_LongDateRendererProps instead*/
  DSP_LongDateRendererProps as LongDateRendererProps, //
  /** @deprecated use DSP_LongDateRenderer instead*/
  DSP_LongDateRenderer as LongDateRenderer,
};
