import React from 'react';

import {
  DialogContent as MuiDialogContent,
  DialogContentProps as MuiDialogContentProps,
} from '@mui/material';
import {StandardCSSProperties} from '@mui/system';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

import {useDialogContent} from './useDialogContent';

export interface DSL_DialogContentProps
  extends Omit<MuiDialogContentProps, DSL_RestrictedVisualProps> {
  height?: StandardCSSProperties['width'];
  width?: StandardCSSProperties['width'];
}

export const DSL_DialogContent = ({
  height,
  width,
  ...props
}: DSL_DialogContentProps) => {
  const {ref, borderStyle} = useDialogContent();

  return (
    <MuiDialogContent
      ref={ref}
      dividers
      sx={{height, width, ...borderStyle}}
      {...props}
    />
  );
};

DSL_DialogContent.displayName = 'DSL_DialogContent';

export {
  //
  /** @deprecated use DSL_DialogContentProps instead*/
  DSL_DialogContentProps as DialogContentProps, //
  /** @deprecated use DSL_DialogContent instead*/
  DSL_DialogContent as DialogContent,
};
