import {useState} from 'react';

import * as ApolloReactCommon from '@apollo/client';
// eslint-disable-next-line no-restricted-imports
import {AccountsSearchDocument} from 'containers-accounts/Accounts/api/query/AccountSearch.generated';
import * as R from 'ramda';
import * as Types from 'typeDefs/types';
import useBaseSearchMachine from 'utils/machines/useBaseSearchMachine';
// TODO: migrate these components to the new design system
// eslint-disable-next-line no-restricted-imports

import {
  SearchAutocompleteInputProps,
  RelatedRecord,
} from 'components/DesignSystem/Library';

export type DSP_UseSearchAutocompleteArgs = {
  currentRecord: RelatedRecord;
  onOptionSelect: (option: RelatedRecord) => void;
  mapOptions?: (options: RelatedRecord[]) => RelatedRecord[];
  query?: ApolloReactCommon.DocumentNode;
  queryVariables?: {qs: Types.Scalars['String']['input']};
  emptyRecord?: RelatedRecord;
  getOptionLabel: (searchResult?: RelatedRecord) => string;
  validateSearchTerm?: (searchTerm?: string) => string;
  fixedRecords?: RelatedRecord[];
};

export function useSearchAutocomplete({
  currentRecord,
  onOptionSelect,
  mapOptions = options => options,
  query = AccountsSearchDocument,
  queryVariables = undefined,
  emptyRecord = {
    id: null,
  },
  getOptionLabel,
  validateSearchTerm = (searchTerm?: string): string => {
    return R.defaultTo('', searchTerm);
  },
  fixedRecords = [],
}: DSP_UseSearchAutocompleteArgs): SearchAutocompleteInputProps {
  const [inputValue, setInputValue] = useState<string>(
    getOptionLabel(currentRecord),
  );

  const onSelect = (option: RelatedRecord) => {
    onOptionSelect?.(option);
  };

  const searchResultToString = getOptionLabel;

  const [searchState, searchSend] = useBaseSearchMachine(
    currentRecord,
    onSelect,
    query,
    queryVariables,
    emptyRecord,
    searchResultToString,
    fixedRecords,
  );

  const fieldValue = searchState?.context?.storeFieldValue as
    | RelatedRecord
    | undefined;

  const isLoading =
    searchState.matches('userInput.debouncing') ||
    searchState.matches('userInput.searching');

  const isError = searchState.context.props?.overrideTouched === true;

  const options = mapOptions(searchState.context.data);

  return {
    getOptionLabel,
    inputValue,
    loading: isLoading,
    error: isError,
    options,
    onChange: (_event, searchResult: RelatedRecord | null): void => {
      searchSend('SELECT', {
        fieldRef: '',
        value: searchResult,
      });
    },
    onInputChange: (_event, searchTerm, reason): void => {
      setInputValue(searchTerm);
      if (reason === 'reset') return; //programmatic change
      searchTerm = validateSearchTerm(searchTerm);
      if (searchResultToString(fieldValue) !== searchTerm) {
        searchSend('CHANGE', {fieldRef: '#/search', value: searchTerm});
      }
    },
    isOptionEqualToValue: (
      searchResult: RelatedRecord,
      value: RelatedRecord,
    ): boolean => {
      if (!searchResult || !value) return false;
      if (searchResult?.id) {
        return searchResult?.id === value?.id;
      }
      return searchResult === value;
    },
  };
}
