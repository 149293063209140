import React, {CSSProperties} from 'react';

import {Table as MuiTable, TableProps as MuiTableProps} from '@mui/material';

import {DSL_RestrictedVisualProps} from '../types';

export interface DSL_TableProps
  extends Omit<MuiTableProps, DSL_RestrictedVisualProps> {
  tableLayout?: CSSProperties['tableLayout'];
  display?: CSSProperties['display'];
  overflowX?: CSSProperties['overflowX'];
  whiteSpace?: CSSProperties['whiteSpace'];
}

export const DSL_Table = ({
  display,
  overflowX,
  whiteSpace,
  tableLayout,
  ...props
}: DSL_TableProps) => {
  return (
    <MuiTable sx={{display, tableLayout, overflowX, whiteSpace}} {...props} />
  );
};

DSL_Table.displayName = 'DSL_Table';

export {
  //
  /** @deprecated use DSL_TableProps instead*/
  DSL_TableProps as TableProps, //
  /** @deprecated use DSL_Table instead*/
  DSL_Table as Table,
};
