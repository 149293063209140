import React from 'react';

import {UserProfile} from '@regulatory-platform/common-utils/dist';

import {DSP_AccountHeaderView} from './AccountHeader.View';
import {useAccountHeader} from './useAccountHeader';

export interface DSP_AccountHeaderProps {
  userProfile: UserProfile;
}

export const DSP_AccountHeader = ({userProfile}: DSP_AccountHeaderProps) => {
  const props = useAccountHeader(userProfile);
  return <DSP_AccountHeaderView {...props} />;
};

DSP_AccountHeader.displayName = 'DSP_AccountHeader';

export {
  //
  /** @deprecated use DSP_AccountHeaderProps instead*/
  DSP_AccountHeaderProps as AccountHeaderProps, //
  /** @deprecated use DSP_AccountHeader instead*/
  DSP_AccountHeader as AccountHeader,
};
