import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: white;
  position: unset;
  align-items: center;
  flex: 1;
  display: flex;
  height: 50px;
`;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconContainer: {
      transform: 'scale(1.25)',
      fontSize: theme.typography.body1.fontSize,
    },
    stepCompleted: {
      color: theme.palette.success.main,
    },
    noPrint: {
      '@media print': {
        display: 'none',
      },
    },
  }),
);
