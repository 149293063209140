import React from 'react';

import {DSP_WorkflowActivityNotificationItem} from '../types';

import {useWorkflowActivityNotificationCard} from './useWorkflowActivityNotificationCard';
import {DSP_WorkflowActivityNotificationCardView} from './WorkflowActivityNotificationCard.View';

export interface DSP_WorkflowActivityNotificationCardProps {
  notification: DSP_WorkflowActivityNotificationItem;
}

export const DSP_WorkflowActivityNotificationCard = (
  props: DSP_WorkflowActivityNotificationCardProps,
) => {
  const viewProps = useWorkflowActivityNotificationCard(props);

  return <DSP_WorkflowActivityNotificationCardView {...viewProps} />;
};

DSP_WorkflowActivityNotificationCard.displayName =
  'DSP_WorkflowActivityNotificationCard';

export {
  //
  /** @deprecated use DSP_WorkflowActivityNotificationCardProps instead*/
  DSP_WorkflowActivityNotificationCardProps as WorkflowActivityNotificationCardProps, //
  /** @deprecated use DSP_WorkflowActivityNotificationCard instead*/
  DSP_WorkflowActivityNotificationCard as WorkflowActivityNotificationCard,
};
