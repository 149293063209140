import React from 'react';

import {DSL_DismissibleGlobalAlertView} from './DismissibleGlobalAlert.View';
import {
  useDismissibleGlobalAlert,
  UseDismissibleGlobalAlertProps,
} from './useDismissibleGlobalAlert';

export type DSL_DismissibleGlobalAlertProps = UseDismissibleGlobalAlertProps;

export const DSL_DismissibleGlobalAlert = (
  props: DSL_DismissibleGlobalAlertProps,
) => {
  const viewProps = useDismissibleGlobalAlert(props);

  return <DSL_DismissibleGlobalAlertView {...viewProps} />;
};

DSL_DismissibleGlobalAlert.displayName = 'DSL_DismissibleGlobalAlert';
