import DataUsage from '@mui/icons-material/Dashboard';
import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/featureTokens',
  component: lazy(
    () => import(/* webpackChunkName: "FeatureTokens" */ './FeatureTokens'),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'Feature tokens',
  menuIcon: DataUsage,
  authorisation: {
    apiKey: 'FeatureTokenWithRelations.findById',
    policies: [{role: 'operationsAccountAdmin'}] as AuthorisationPolicy[],
  },
  category: 'Admin',
  sections: [],
};
