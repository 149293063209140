import React from 'react';

import {useThemeSelection} from 'app/theming/ThemeSelectionProvider';
import {
  DSL_FlexRow,
  DSL_IconWrapper,
  DSL_PaletteIcon,
} from 'components/DesignSystem/Library';

const ThemeToggle: React.FC = () => {
  const {toggleSelectedTheme, selectedTheme} = useThemeSelection();
  return (
    <DSL_FlexRow
      role="button"
      onClick={toggleSelectedTheme}
      alignItems={'center'}
      justifyContent={'flex-end'}
      cursor={'pointer'}
      color={'text.primary'}
      hiddenDownTo={'lg'}
    >
      <DSL_IconWrapper icon={<DSL_PaletteIcon />} />
      <DSL_FlexRow>{`${selectedTheme} theme`}</DSL_FlexRow>
    </DSL_FlexRow>
  );
};

export default ThemeToggle;
