import React from 'react';

import {DSL_DeleteIcon} from '../../../Icons';
import {DSL_BaseIconButton, DSL_BaseIconButtonProps} from '../BaseIconButton';

export type DSL_DeleteIconButtonProps = Omit<
  DSL_BaseIconButtonProps,
  'color' | 'children'
>;

export const DSL_DeleteIconButton = (
  props: DSL_DeleteIconButtonProps,
): JSX.Element => {
  return (
    <DSL_BaseIconButton aria-label={'delete'} {...props}>
      <DSL_DeleteIcon />
    </DSL_BaseIconButton>
  );
};

DSL_DeleteIconButton.displayName = 'DSL_DeleteIconButton';

export {
  //
  /** @deprecated use DSL_DeleteIconButtonProps instead*/
  DSL_DeleteIconButtonProps as DeleteIconButtonProps, //
  /** @deprecated use DSL_DeleteIconButton instead*/
  DSL_DeleteIconButton as DeleteIconButton,
};
