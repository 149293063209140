/* eslint-disable @typescript-eslint/explicit-function-return-type */
import * as R from 'ramda';
import {FormEventObject, SearchStoreMachineContext} from 'utils/stores/types';
import {MachineOptions} from 'xstate';
import formMachineGuards from './formMachineGuards';

export type MachineGuards = NonNullable<
  MachineOptions<SearchStoreMachineContext, FormEventObject>['guards']
>;

export default function getGuards(): MachineGuards {
  return R.mergeRight(
    formMachineGuards() as unknown as MachineGuards,
    {
      isSearchable: (context): boolean => {
        return (
          !R.isNil(context.record.search) && !R.isEmpty(context.record.search)
        );
      },
      isNotSearchable: (context): boolean => {
        return (
          R.isNil(context.record.search) || R.isEmpty(context.record.search)
        );
      },
      hasFindRelatedEntity: (context): boolean => {
        return !R.isNil(context.findRelatedEntity);
      },
      notHasFindRelatedEntity: (context): boolean => {
        return R.isNil(context.findRelatedEntity);
      },
      isNullSelection: (_context, event) => {
        return R.isNil(event.value);
      },
      isNotNullSelection: (_context, event) => {
        return !R.isNil(event.value);
      },
      isFormValueChanged: (context, event) => {
        if (
          !R.isNil(event.schema2) &&
          !R.isNil(context.storeFieldSchema2) &&
          !R.isNil(event.schema2?.['x-valid']) &&
          !R.isNil(context.storeFieldSchema2?.['x-valid']) &&
          !R.equals(
            event.schema2['x-valid'],
            context.storeFieldSchema2['x-valid'],
          )
        ) {
          return true;
        }
        if (
          !R.isNil(event.schema) &&
          !R.isNil(context.storeFieldSchema) &&
          !R.isNil(event.schema?.['x-valid']) &&
          !R.isNil(context.storeFieldSchema?.['x-valid']) &&
          !R.equals(
            event.schema['x-valid'],
            context.storeFieldSchema['x-valid'],
          )
        ) {
          return true;
        }
        return (
          (Array.isArray(context.storeFieldValue) && Array.isArray(event.value)
            ? context.searchResultToString(
                context.storeFieldValue[context.storeFieldValue.length - 1],
              ) !==
              context.searchResultToString(event.value[event.value.length - 1])
            : context.searchResultToString(context.storeFieldValue) !==
              context.searchResultToString(event.value as object)) ||
          context.props.overrideTouched !== event.flag
        );
      },
    } as MachineGuards,
  );
}
