import React from 'react';

import {DSP_PartnerAccountEntityAvatarView} from './PartnerAccountEntityAvatar.View';
import {usePartnerAccountEntityAvatar} from './usePartnerAccountEntityAvatar';

export const DSP_PartnerAccountEntityAvatar = () => {
  const viewProps = usePartnerAccountEntityAvatar();

  return <DSP_PartnerAccountEntityAvatarView {...viewProps} />;
};

DSP_PartnerAccountEntityAvatar.displayName = 'DSP_PartnerAccountEntityAvatar';

export {
  //
  /** @deprecated use DSP_PartnerAccountEntityAvatar instead*/
  DSP_PartnerAccountEntityAvatar as PartnerAccountEntityAvatar,
};
