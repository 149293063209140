import {useCallback, useRef} from 'react';

export const useInfiniteLoopProtection = ({
  callCountThreshold = 20,
  rapidCallThresholdMs = 50,
  errorToThrow = 'Infinite loop detected',
}: {
  callCountThreshold?: number;
  rapidCallThresholdMs?: number;
  errorToThrow?: string;
}) => {
  const timeSinceLastCall = useRef(0);
  const rapidCallCount = useRef(0);

  const checkForInfiniteLoop = useCallback(() => {
    if (
      timeSinceLastCall.current > 0 &&
      performance.now() - timeSinceLastCall.current < rapidCallThresholdMs
    ) {
      rapidCallCount.current += 1;
    } else {
      rapidCallCount.current = 0;
    }

    if (rapidCallCount.current > callCountThreshold) {
      throw new Error(errorToThrow);
    }
    timeSinceLastCall.current = performance.now();
  }, [timeSinceLastCall, rapidCallCount, callCountThreshold, errorToThrow]);

  return checkForInfiniteLoop;
};
