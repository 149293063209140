import React, {createContext, ReactNode} from 'react';

import {WizardActions} from '../../../../navigation/LegacyWizard/types';
import {useRequiredContext} from '../../../utils/useRequiredContext';

export type DSP_WizardContext = Pick<WizardActions, 'onStepChange'>;

const WizardContextInstance = createContext<DSP_WizardContext | undefined>(
  undefined,
);

export const useWizardContext = () =>
  useRequiredContext(WizardContextInstance, DSP_WizardProvider.displayName);

export interface DSL_DrawerProviderProps extends DSP_WizardContext {
  children?: ReactNode;
}

export const DSP_WizardProvider = ({
  children,
  ...value
}: DSL_DrawerProviderProps) => (
  <WizardContextInstance.Provider value={value}>
    {children}
  </WizardContextInstance.Provider>
);

DSP_WizardProvider.displayName = 'DSP_WizardProvider';
