import {SettingsOutlined} from '@mui/icons-material';
import AccessVehicleCategory from 'containers-admin/AccessVehicleCategory';
import ManageAccessVehicleCategory from 'containers-admin/AccessVehicleCategory/ManageAccessVehicleCategory';
import NewAccessVehicleCategoryGateway from 'containers-admin/AccessVehicleCategory/NewAccessVehicleCategoryGateway';
import AccessVehicleExemptionSet from 'containers-admin/AccessVehicleExemptionSet';
import ManageAccessVehicleExemptionSet from 'containers-admin/AccessVehicleExemptionSet/ManageAccessVehicleExemptionSet';
import NewAccessVehicleExemptionSetGateway from 'containers-admin/AccessVehicleExemptionSet/NewAccessVehicleExemptionSetGateway';
import VehicleBuilder from 'containers-admin/VehicleBuilder';
import PermitStandardConditions from 'containers-admin/PermitStandardConditions';
import CreatePermitStandardCondition from 'containers-admin/PermitStandardConditions/CreatePermitStandardCondition';
import ManagePermitStandardCondition from 'containers-admin/PermitStandardConditions/ManagePermitStandardCondition';
import PermitCustomConditions from 'containers-admin/PermitCustomConditions';
import CreatePermitPartnerCondition from 'containers-admin/PermitCustomConditions/CreatePermitPartnerCondition';
import ManagePermitPartnerCondition from 'containers-admin/PermitCustomConditions/ManagePermitPartnerCondition';
import ProductDocumentMedia from 'containers-admin/ProductDocumentMedia';
import CreateProductDocumentMedia from 'containers-admin/ProductDocumentMedia/CreateProductDocumentMedia';
import ProductDocumentMediaDetails from 'containers-admin/ProductDocumentMedia/ProductDocumentMediaDetails';
import {DocumentTemplateRoutes} from 'containers-admin/DocumentTemplate';
import ReferenceData from 'containers-admin/ReferenceData';
import ManageDesignRule from 'containers-admin/ReferenceData/DesignRule/manage';
import CreateFeatureToken from 'containers-admin/FeatureToken/CreateFeatureToken';
import FeatureTokenDetails from 'containers-admin/FeatureToken/FeatureTokenDetails';
import FeatureToken from 'containers-admin/FeatureToken';
import CreateProviderContact from 'containers-admin/ProviderContact/CreateProviderContact';
import ProviderContactDetails from 'containers-admin/ProviderContact/ProviderContactDetails';
import ProviderContact from 'containers-admin/ProviderContact';
import AccessProductDocumentGenerateds from 'containers-admin/AccessProductDocumentGenerated';
import AratRefVehicles from 'containers-spatial/Assets/AratRefVehicle';
import ManageAratRefVehicle from 'containers-spatial/Assets/AratRefVehicle/ManageAratRefVehicle';
import NewAratRefVehicleGateway from 'containers-spatial/Assets/AratRefVehicle/NewAratRefVehicleGateway';

export default {
  routes: [
    AratRefVehicles,
    ManageAratRefVehicle,
    NewAratRefVehicleGateway,
    ...DocumentTemplateRoutes,
    AccessVehicleExemptionSet,
    ManageAccessVehicleExemptionSet,
    NewAccessVehicleExemptionSetGateway,
    AccessVehicleCategory,
    ManageAccessVehicleCategory,
    NewAccessVehicleCategoryGateway,
    CreateProviderContact,
    ProviderContactDetails,
    ProviderContact,
    CreateFeatureToken,
    FeatureTokenDetails,
    FeatureToken,
    PermitStandardConditions,
    ManagePermitStandardCondition,
    CreatePermitStandardCondition,
    PermitCustomConditions,
    ManagePermitPartnerCondition,
    CreatePermitPartnerCondition,
    ProductDocumentMedia,
    ProductDocumentMediaDetails,
    CreateProductDocumentMedia,
    ReferenceData,
    ManageDesignRule,
    VehicleBuilder,
    AccessProductDocumentGenerateds,
  ],
  menuTitle: 'Administration',
  menuDescription: 'Administration of vehicle components and configurations',
  path: '/administration',
  showChildRoutesInMenu: true,
  menuIcon: SettingsOutlined,
};
