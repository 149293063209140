import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import HomeIcon from '@mui/icons-material/Home';
import {lazy} from 'react';
//pbs/reference-data/designRule/manage
export default {
  path: '/reference-data/designRules/:id',
  component: lazy(
    () =>
      import(/* webpackChunkName: "ManageDesignRule" */ './ManageDesignRule'),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuIcon: HomeIcon,
  menuTitle: 'Manage design rule',
  authorisation: {
    apiKey: null,
    policies: [
      {
        role: 'operationsPBSAdmin',
      },
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Reference data',
      to: '../..',
      state: {'Reference data': 'Design rules'},
    },
  ],
};
