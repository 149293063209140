import React from 'react';

import {DSL_BaseCard, DSL_BaseCardProps} from '../BaseCard';

export type DSL_ElevatedCardProps = Omit<DSL_BaseCardProps, 'variant'>;

export const DSL_ElevatedCard = ({
  elevation = 3,
  ...props
}: DSL_ElevatedCardProps) => {
  return (
    <DSL_BaseCard variant={'elevation'} elevation={elevation} {...props} />
  );
};

DSL_ElevatedCard.displayName = 'DSL_ElevatedCard';

export {
  //
  /** @deprecated use DSL_ElevatedCardProps instead*/
  DSL_ElevatedCardProps as ElevatedCardProps, //
  /** @deprecated use DSL_ElevatedCard instead*/
  DSL_ElevatedCard as ElevatedCard,
};
