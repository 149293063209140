import React, {forwardRef, ReactNode} from 'react';

import {DropzoneRootProps} from 'react-dropzone';

import {DSL_FlexColumn} from '../../Layout/FlexColumn';

export interface DSL_DropTargetProps {
  rootProps: DropzoneRootProps;
  isDragActive?: boolean;
  error?: boolean;
  children?: ReactNode;
}

export const DSL_DropTarget = forwardRef(
  ({isDragActive, error, rootProps, ...props}: DSL_DropTargetProps, ref) => {
    return (
      <DSL_FlexColumn
        ref={ref}
        alignItems={'center'}
        p={3}
        internal_sx={theme => ({
          m: 2,
          borderStyle: 'dashed',
          borderWidth: 1,
          borderRadius: `${theme.shape.borderRadius}px`,
          ...(isDragActive && {
            backgroundColor: theme.palette.primary.shades?.['8p'],
            borderColor: theme.palette.primary.main,
          }),
          ...(error && {
            backgroundColor: theme.palette.error.shades?.['4p'],
            borderColor: theme.palette.error.main,
          }),
        })}
        {...props}
        {...rootProps}
      />
    );
  },
);

DSL_DropTarget.displayName = 'DSL_DropTarget';
