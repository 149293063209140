import {SegmentAccessType} from '../../Layout/Segments/constants';

import {DSP_Road, DSP_Segment} from './types';

export const dsp_calculateRoadsByAccessType = (
  roads: DSP_Road[],
  getSegmentsByIds: (segmentIds?: number[]) => DSP_Segment[],
) => {
  const segmentsByAccessType: Record<SegmentAccessType, Set<number>> = {
    [SegmentAccessType.APPROVED]: new Set(),
    [SegmentAccessType.APPROVED_WITH_CONDITION]: new Set(),
    [SegmentAccessType.RESTRICTED]: new Set(),
    [SegmentAccessType.REMOVED]: new Set(),
    [SegmentAccessType.NO_ACCESS]: new Set(),
  };

  const roadsByAccessType: Record<SegmentAccessType, Set<DSP_Road>> = {
    [SegmentAccessType.APPROVED]: new Set(),
    [SegmentAccessType.APPROVED_WITH_CONDITION]: new Set(),
    [SegmentAccessType.RESTRICTED]: new Set(),
    [SegmentAccessType.REMOVED]: new Set(),
    [SegmentAccessType.NO_ACCESS]: new Set(),
  };

  for (const segment of getSegmentsByIds()) {
    segmentsByAccessType[segment.accessType].add(segment.id);
  }

  for (const road of roads) {
    for (const accessType of Object.values(SegmentAccessType)) {
      if (
        road.segmentIds.some(segmentId =>
          segmentsByAccessType[accessType].has(segmentId),
        )
      ) {
        roadsByAccessType[accessType].add(road);
      }
    }
  }

  return {
    segmentsByAccessType,
    roadsByAccessType: [
      {
        segmentAccessType: SegmentAccessType.APPROVED,
        withIcon: true,
        totalRoads: roadsByAccessType[SegmentAccessType.APPROVED].size,
      },
      {
        segmentAccessType: SegmentAccessType.APPROVED_WITH_CONDITION,
        withIcon: true,
        totalRoads:
          roadsByAccessType[SegmentAccessType.APPROVED_WITH_CONDITION].size,
      },
      {
        segmentAccessType: SegmentAccessType.RESTRICTED,
        withIcon: true,
        totalRoads: roadsByAccessType[SegmentAccessType.RESTRICTED].size,
      },
      {
        segmentAccessType: SegmentAccessType.REMOVED,
        withIcon: true,
        totalRoads: roadsByAccessType[SegmentAccessType.REMOVED].size,
      },
      {
        segmentAccessType: SegmentAccessType.NO_ACCESS,
        withIcon: true,
        totalRoads: roadsByAccessType[SegmentAccessType.NO_ACCESS].size,
      },
    ].filter(({totalRoads}) => totalRoads !== 0),
  };
};
