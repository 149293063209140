import React from 'react';

import {DSL_Box, DSL_TableCell} from 'components/DesignSystem/Library';

import {DSP_TableFormField} from '../FormField';

import {
  DSP_FormTableCellRenderer,
  DSP_FormTableCellRendererProps,
} from './types';

export const DSP_FormTableCell: DSP_FormTableCellRenderer = ({
  fieldRef,
  width,
  children,
  size,
  borderTop,
  borderBottom,
}: DSP_FormTableCellRendererProps) => {
  return (
    <DSL_TableCell
      verticalAlign={'center'}
      width={width}
      borderTop={borderTop}
      borderBottom={borderBottom}
    >
      <DSL_Box width={width} whiteSpace={'break-spaces'}>
        {children ? (
          children
        ) : (
          <DSP_TableFormField hideLabel fieldRef={fieldRef} size={size} />
        )}
      </DSL_Box>
    </DSL_TableCell>
  );
};

DSP_FormTableCell.displayName = 'DSP_FormTableCell';
