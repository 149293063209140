import React from 'react';

import {DSL_TableRowsIcon} from '../../../Icons';
import {DSL_BaseIconButton, DSL_BaseIconButtonProps} from '../BaseIconButton';

export type DSL_TableRowsIconButtonProps = Omit<
  DSL_BaseIconButtonProps,
  'color' | 'children'
>;

export const DSL_TableRowsIconButton = (
  props: DSL_TableRowsIconButtonProps,
): JSX.Element => {
  return (
    <DSL_BaseIconButton aria-label="more" {...props}>
      <DSL_TableRowsIcon />
    </DSL_BaseIconButton>
  );
};

DSL_TableRowsIconButton.displayName = 'DSL_TableRowsIconButton';
