import {
  AuthorisationSchema,
  AuthorizationDecision,
  CommonInvocationContext,
  schemaVoter,
} from '@regulatory-platform/common-utils';
import * as R from 'ramda';

/**
 * An authorisation voter for an action
 * @param context - the invocation context maybe containing userProfile and instance record
 * @param schema - the static authorisation schema containing policies for each action
 */
export function actionVoter(
  context: CommonInvocationContext,
  schema: AuthorisationSchema,
): (action: string) => AuthorizationDecision {
  //schemaVoter will return a decision based on the static schema policies
  //you can override the decision by returning a different voter function
  return schemaVoter(context, schema);
}

/**
 * A helper method to return an authorisation schema with each an authorisation decision recorded for each action
 * @param context - the invocation context maybe containing userProfile and instance record
 * @param schema - the static authorisation schema containing policies for each action
 */
export function authorizeAllActions(
  context: CommonInvocationContext,
  schema: AuthorisationSchema,
): AuthorisationSchema {
  const authorizer = actionVoter(context, schema);
  return R.mapObjIndexed(
    (resultSchema, action) =>
      R.assoc('decision', authorizer(action), resultSchema),
    schema,
  );
}
