import React, {CSSProperties} from 'react';

import {DSP_TableFormField} from 'components/DesignSystem/Portal/Forms/FormField';

import {DSL_Box, DSL_TableCell} from 'components/DesignSystem/Library';

import {CsvColumn} from '../machines/types';

export interface ImportFromCsvColumnProps extends Omit<CsvColumn, 'width'> {
  width: CSSProperties['width'];
}

export const ImportFromCsvColumn = ({
  fieldName,
  fieldRef,
  width,
}: ImportFromCsvColumnProps) => {
  return (
    <DSL_TableCell verticalAlign={'center'} width={width}>
      <DSL_Box width={width} whiteSpace={'break-spaces'}>
        <DSP_TableFormField
          hideLabel
          fieldRef={fieldRef ? `${fieldRef}/0/${fieldName}` : `#/${fieldName}`}
        />
      </DSL_Box>
    </DSL_TableCell>
  );
};

ImportFromCsvColumn.displayName = 'ImportFromCsvColumn';
