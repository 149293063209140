import {NetworkSegmentManagementControllerCalculateLengthByIdsDocument} from 'containers-spatial/NetworkManagement/api/mutations/CalculateRoadSegmentsLength.generated';
import {DataLoaderProvider} from 'containers-spatial/Shared/DataLoaderProvider/DataLoaderProvider';
import React, {ReactNode, useMemo} from 'react';
import {NetworkSegmentManagementSegmentLengthsCalculationResponse} from 'typeDefs/types';
import {compressData} from 'utils/helper/compressData';

interface RoadSegmentsLengthLoaderProviderPops {
  segmentIds: number[];
  children?: ReactNode;
}

export const RoadSegmentsLengthLoaderProvider = ({
  segmentIds,
  ...props
}: RoadSegmentsLengthLoaderProviderPops) => {
  const query = useMemo(
    () => ({
      findByCompressedSegmentIdsRequestInput: {
        compressedData: compressData({segmentIds}),
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(segmentIds)],
  );

  return (
    <DataLoaderProvider<NetworkSegmentManagementSegmentLengthsCalculationResponse>
      document={NetworkSegmentManagementControllerCalculateLengthByIdsDocument}
      query={query}
      {...props}
    />
  );
};

RoadSegmentsLengthLoaderProvider.displayName =
  'RoadSegmentsLengthLoaderProvider';
