import {useDialog} from '../../..';

import {DSL_ConfirmDialogProps} from './ConfirmDialog';

export interface DSL_UseConfirmDialogProps
  extends Omit<DSL_ConfirmDialogProps, 'open' | 'onCancel' | 'onConfirm'> {
  bypassCondition?: boolean;
  onCancel?: () => void;
  onConfirm: () => void;
}

export function useConfirmDialog({
  onConfirm,
  bypassCondition,
  onCancel,
  ...props
}: DSL_UseConfirmDialogProps) {
  const [isOpen, openDialog, closeDialog] = useDialog();

  return {
    openDialog: bypassCondition ? onConfirm : openDialog,
    dialogProps: {
      open: isOpen,
      onCancel: () => {
        onCancel?.();
        closeDialog();
      },
      onConfirm: () => {
        onConfirm?.();
        closeDialog();
      },
      ...props,
    },
  };
}
