import React from 'react';

import {AccreditationWithRelations} from 'typeDefs/types';
import {NotificationItem} from 'utils/machines/notificationsMachine';

import {DSP_AccreditationActionCustomerNotificationCardView} from './AccreditationActionCustomerNotificationCard.View';
import {useAccreditationActionCustomerNotificationCard} from './useAccreditationActionCustomerNotificationCard';

export interface DSP_AccreditationActionCustomerNotificationCardProps {
  notification: NotificationItem<AccreditationWithRelations>;
}

export const DSP_AccreditationActionCustomerNotificationCard = (
  props: DSP_AccreditationActionCustomerNotificationCardProps,
) => {
  const viewProps = useAccreditationActionCustomerNotificationCard(props);

  return <DSP_AccreditationActionCustomerNotificationCardView {...viewProps} />;
};

DSP_AccreditationActionCustomerNotificationCard.displayName =
  'DSP_AccreditationActionCustomerNotificationCard';
