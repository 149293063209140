/* eslint-disable @typescript-eslint/explicit-function-return-type */
import * as R from 'ramda';
import {FormEventObject, SearchStoreMachineContext} from 'utils/stores/types';
import {createMachine, InterpreterFrom, MachineConfig, State} from 'xstate';
import machineDefinition from './searchMachine.json';
import {RecursivePartial} from '../../typeDefs/utils';

export interface SearchMachineStateSchema {
  states: {
    userInput: {
      states: {
        idle: {};
        searching: {};
        error: {};
      };
    };
    finished: {};
  };
}

export type SearchMachineState = State<
  SearchStoreMachineContext,
  FormEventObject,
  SearchMachineStateSchema
>;

export type SearchMachineConfig = MachineConfig<
  SearchStoreMachineContext,
  SearchMachineStateSchema,
  FormEventObject
>;

export default function getMachine(
  config = {} as RecursivePartial<SearchMachineConfig>,
) {
  return createMachine<SearchStoreMachineContext, FormEventObject>(
    R.mergeDeepRight(
      machineDefinition as SearchMachineConfig,
      config,
    ) as SearchMachineConfig,
  );
}

export type SearchMachineService = InterpreterFrom<
  ReturnType<typeof getMachine>
>;
