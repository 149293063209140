import React from 'react';

import {DSL_ClearIcon} from '../../../Icons';
import {DSL_BaseIconButton, DSL_BaseIconButtonProps} from '../BaseIconButton';

export type DSL_ClearIconButtonProps = Omit<
  DSL_BaseIconButtonProps,
  'color' | 'children'
>;

export const DSL_ClearIconButton = (
  props: DSL_ClearIconButtonProps,
): JSX.Element => {
  return (
    <DSL_BaseIconButton aria-label="clear" {...props}>
      <DSL_ClearIcon />
    </DSL_BaseIconButton>
  );
};

DSL_ClearIconButton.displayName = 'DSL_ClearIconButton';
