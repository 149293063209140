import React, {createContext, ReactNode, useContext} from 'react';

import {IdentifiablePopper, usePopperProvider} from './usePopperProvider';

export interface DSL_PopperContext {
  createPopper: (popper: IdentifiablePopper) => void;
  removePopper: (id: string) => void;
  closeOtherPoppers: (id: string) => void;
}

const PopperContextInstance = createContext<DSL_PopperContext | undefined>(
  undefined,
);

export const usePopperContext = () => useContext(PopperContextInstance);

export interface DSL_PopperProviderProps {
  children?: ReactNode;
}

export const DSL_PopperProvider = ({children}: DSL_PopperProviderProps) => {
  const value = usePopperProvider();

  return (
    <PopperContextInstance.Provider value={value}>
      {children}
    </PopperContextInstance.Provider>
  );
};

DSL_PopperProvider.displayName = 'DSL_PopperProvider';
