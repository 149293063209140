import Layers from '@mui/icons-material/Layers';
import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/accessVehicleExemptionSets',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "AccessVehicleExemptionSets" */ './AccessVehicleExemptionSets'
      ),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'Access reference vehicles',
  menuDescription: 'Access network reference vehicles',
  menuIcon: Layers,
  parent: true,
  authorisation: {
    apiKey: 'AccessVehicleExemptionSetWithRelations.findById',
    policies: [
      {
        role: 'operationsSpatialRead',
      },
    ] as AuthorisationPolicy[],
  },
  sections: [],
};
