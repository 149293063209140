import {useMemo} from 'react';

import {useTheme} from '@mui/material';

import {ScrollableLayoutAttributes} from './useScrollableLayout';

export interface UseKeyLineBorderProps
  extends Pick<
    ScrollableLayoutAttributes,
    'hasOverflowY' | 'isAtBottomOfDiv' | 'isAtTopOfDiv'
  > {
  alwaysShow?: boolean;
}

export function useKeyLineBorder({
  alwaysShow,
  hasOverflowY,
  isAtBottomOfDiv,
  isAtTopOfDiv,
}: UseKeyLineBorderProps) {
  const {palette} = useTheme();
  const border = `1px solid ${palette.divider}`;
  const borderOff = `1px solid transparent`;

  return useMemo(
    () => ({
      borderTop: !isAtTopOfDiv || alwaysShow ? border : borderOff,
      borderBottom:
        (hasOverflowY && !isAtBottomOfDiv) || alwaysShow ? border : borderOff,
    }),
    [alwaysShow, border, hasOverflowY, isAtBottomOfDiv, isAtTopOfDiv],
  );
}
