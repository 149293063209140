import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/preapprovals/:id',
  component: lazy(
    () =>
      import(/* webpackChunkName: "ManagePreapproval" */ './ManagePreapproval'),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Manage pre approved route',
  menuIcon: LibraryAddCheckIcon,
  authorisation: {
    apiKey: 'AccessProductDocumentWithRelations.findById',
    policies: [
      {role: 'operationsAccessRead'},
      {role: 'operationsSpatialRead'},
      {role: 'partnerSpatialRead'},
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Pre approved routes',
      to: '..',
    },
  ],
};
