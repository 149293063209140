import React from 'react';

import {
  UseGridItemStyles,
  GridItem,
  RelatedRecord,
} from 'components/DesignSystem/Library';

import {
  DSP_SelectRelationBoxView,
  DSP_SelectRelationBoxViewProps,
} from './SelectRelationBox.View';
import {
  useSelectRelationBox,
  DSP_UseSelectRelationBoxProps,
} from './useSelectRelationBox';
export interface DSP_SelectRelationBoxProps
  extends DSP_UseSelectRelationBoxProps,
    DSP_SelectRelationBoxViewProps,
    UseGridItemStyles {
  renderOption?: (item: RelatedRecord) => React.ReactNode;
}

export const DSP_SelectRelationBox = ({
  renderOption = record => record.name,
  span,
  ...restProps
}: DSP_SelectRelationBoxProps) => {
  const {hideComponent, ...props} = useSelectRelationBox(restProps);
  if (hideComponent) return null;
  return (
    <GridItem span={span}>
      <DSP_SelectRelationBoxView renderOption={renderOption} {...props} />
    </GridItem>
  );
};

export {
  //
  /** @deprecated use DSP_SelectRelationBoxProps instead*/
  DSP_SelectRelationBoxProps as SelectRelationBoxProps, //
  /** @deprecated use DSP_SelectRelationBox instead*/
  DSP_SelectRelationBox as SelectRelationBox,
};
