import React from 'react';

import {UserProfile} from '@regulatory-platform/common-utils/dist';

import {DSP_NotificationsPopoutView} from './NotificationsPopout.View';
import {useNotificationsPopout} from './useNotificationsPopout';

export interface DSP_NotificationsPopoutProps {
  userProfile: UserProfile;
}

export const DSP_NotificationsPopout = ({
  userProfile,
}: DSP_NotificationsPopoutProps) => {
  const props = useNotificationsPopout(userProfile);

  return <DSP_NotificationsPopoutView {...props} />;
};

DSP_NotificationsPopout.displayName = 'DSP_NotificationsPopout';

export {
  //
  /** @deprecated use DSP_NotificationsPopoutProps instead*/
  DSP_NotificationsPopoutProps as NotificationsPopoutProps, //
  /** @deprecated use DSP_NotificationsPopout instead*/
  DSP_NotificationsPopout as NotificationsPopout,
};
