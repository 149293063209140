import {useCallback} from 'react';

import {usePubSubContext} from './PubSubContext';

export function usePubSubPublish<TData = unknown>(event: string) {
  const pubSub = usePubSubContext<TData>();

  return useCallback(
    (data: TData) => {
      pubSub.publish(event, data);
    },
    [pubSub, event],
  );
}
