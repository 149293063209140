import React, {ReactNode} from 'react';

import {useMediaQuery} from '@mui/material';

export interface PrintHideProps {
  children?: ReactNode;
}

export const PrintHide = ({children}: PrintHideProps) => {
  const isPrint = useMediaQuery('print');

  return isPrint ? null : <>{children}</>;
};
