import React from 'react';

import {
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
} from '@mui/material';
import {Breakpoint} from '@mui/system';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

import {
  DSL_BreakpointValues,
  useCascadingBreakpointValue,
} from '../../hooks/useCascadingBreakpointValue';

export interface DSL_DialogProps
  extends Omit<MuiDialogProps, DSL_RestrictedVisualProps> {
  size?: DSL_BreakpointValues<Breakpoint>;
}

export const DSL_Dialog = ({
  size = 'sm',
  fullWidth = true,
  ...props
}: DSL_DialogProps) => {
  const maxWidth = useCascadingBreakpointValue(size);

  return <MuiDialog maxWidth={maxWidth} fullWidth={fullWidth} {...props} />;
};

DSL_Dialog.displayName = 'DSL_Dialog';

export {
  //
  /** @deprecated use DSL_DialogProps instead*/
  DSL_DialogProps as DialogProps, //
  /** @deprecated use DSL_Dialog instead*/
  DSL_Dialog as Dialog,
};
