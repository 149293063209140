import {useCallback, useEffect, useMemo, useState} from 'react';

import {SelectChangeEvent} from '@mui/material';
import {UserProfile} from '@regulatory-platform/common-utils/dist';
import {WebSocketMessage} from '@regulatory-platform/common-utils-notification';
import {useSelector} from '@xstate/react';
import {useWebsocketService} from 'app/services/AppServicesContext';
import useNotificationsMachine from 'components/navigation/Notifications/api/useNotificationsMachine';
import {isToday} from 'date-fns';
import * as R from 'ramda';
import {useDebouncedCallback} from 'use-debounce';
import {
  NotificationFilter,
  NotificationItem,
} from 'utils/machines/notificationsMachine';

import {usePopper} from 'components/DesignSystem/Library';

import {DSP_NotificationsPopoutViewProps} from './NotificationsPopout.View';

// You can use this code if you need to test notifications using mock notifications
// replace the notificationsList in the return statement with the following:
// import {mockNotifications} from 'utils/test/fileMock';
// const notificationsList = mockNotifications;

const websocketSubscriptionKey = 'notificationsPopout';

const NOTIFICATIONS_PROGRESSIVE_PAGE_SIZE = 20;

export const useNotificationsPopout = (
  userProfile: UserProfile,
): DSP_NotificationsPopoutViewProps => {
  const websocketService = useWebsocketService();
  const service = useNotificationsMachine();

  const [currentPage, setCurrentPage] = useState<number>(0);

  const clearFilter = useCallback(
    () =>
      service.send('FILTER', {filterBy: NotificationFilter.AllNotifications}),
    [service],
  );

  const {openerProps, popperProps} = usePopper<HTMLDivElement>({
    onClose: clearFilter,
  });

  const {filteredNotifications, ...stateDerivedProps} = useSelector(
    service,
    state => {
      const {
        notifications,
        filteredNotifications: _filteredNotifications,
        filterBy,
      } = state.context;
      const filterByUnread = filterBy === NotificationFilter.UnreadOnly;

      const unreadCount = notifications.filter(
        ({readByLoggedInUser}) => !readByLoggedInUser,
      ).length;

      return {
        filterBy,
        unreadCount,
        filteredNotifications: _filteredNotifications,
        isLoading: state.matches('loading') && notifications.length === 0,
        showFilterBar: notifications.length > 0,
        showEmptyState:
          notifications.length === 0 || (filterByUnread && unreadCount === 0),
        emptyStateText: filterByUnread
          ? 'You have no unread notifications.'
          : 'You have no notifications.',
        showMarkAllAsReadButton: unreadCount > 0,
        options: [
          NotificationFilter.AllNotifications,
          NotificationFilter.UnreadOnly,
        ],
      };
    },
    R.equals,
  );

  const [todayNotifications, olderNotifications] = useMemo(() => {
    const paginatedNotifications = filteredNotifications.slice(
      0,
      currentPage * NOTIFICATIONS_PROGRESSIVE_PAGE_SIZE,
    );
    return R.partition(
      ({created}) => !!(created && isToday(created)),
      paginatedNotifications,
    );
  }, [currentPage, filteredNotifications]);

  const hasMore =
    currentPage * NOTIFICATIONS_PROGRESSIVE_PAGE_SIZE <
    filteredNotifications.length;

  const loadMore = useCallback(
    (newPage: number) => setCurrentPage(newPage),
    [setCurrentPage],
  );

  // refresh the notifications list (via the machine) when the user profile changes
  useEffect(() => {
    service.send('REFRESH');
  }, [service, userProfile]);

  const messageListener = useDebouncedCallback((message: WebSocketMessage) => {
    // if the message relates to a persisted notification, then simply refresh (refetch) the list
    if (message.notificationId) {
      service.send('REFRESH');
    }
  }, 1000);

  // subscribe to websocket notifications when the user profile changes
  useEffect(() => {
    // unsubscribe from any earlier subscriptions of this component
    websocketService.unsubscribe({
      subscriptionKey: websocketSubscriptionKey,
    });

    if (!userProfile) {
      return;
    }

    // subscribe to account level notifications
    websocketService.subscribe({
      subscriptionKey: websocketSubscriptionKey,
      group: {
        subscriptionType: 'account',
      },
      messageListener,
    });

    // subscribe to user level notifications
    websocketService.subscribe({
      // we can use the same subscription key - as they are differentiated in the notification service first by the group etc
      subscriptionKey: websocketSubscriptionKey,
      group: {
        subscriptionType: 'user',
      },
      messageListener,
    });
  }, [service, websocketService, userProfile, messageListener]);

  const markAllAsRead = () => {
    service.send({
      type: 'MARK_ALL_AS_READ',
      markAllAsReadEvent: {},
    });
  };

  const markAsRead = (notification: NotificationItem) => {
    if (notification?.notificationId && !notification.readByLoggedInUser) {
      service.send({
        type: 'MARK_AS_READ',
        markAsReadEvent: {
          notificationId: notification.notificationId,
        },
      });
    }
  };

  const onNotificationClicked = (notification: NotificationItem) => {
    markAsRead(notification);
    popperProps.closePopper();
  };

  const onSelectChange = (event: SelectChangeEvent<NotificationFilter>) => {
    service.send('FILTER', {filterBy: event.target.value});
  };

  return {
    ...stateDerivedProps,
    todayNotifications,
    olderNotifications,
    hasMore,
    loadMore,
    openerProps,
    popperProps,
    onNotificationClicked,
    markAllAsRead,
    onSelectChange,
  };
};
