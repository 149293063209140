import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/permitCustomConditions/create',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "ProductDocumentMediaDetails" */ './CreatePermitPartnerCondition'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Add custom condition',
  menuIcon: LibraryAddCheckIcon,
  authorisation: {
    apiKey: 'PermitPartnerConditionWithRelations.create',
    policies: [{role: 'operationsAccessAdmin'}] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Custom conditions',
      to: '..',
    },
  ],
};
