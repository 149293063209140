import React, {ReactNode} from 'react';

import {DSL_Divider} from '../../Divider';
import {DSL_Tab} from '../Tab';
import {DSL_TabContext} from '../TabContext';
import {DSL_TabList} from '../TabList';
import {DSL_TabPanel} from '../TabPanel';

export interface DSL_HorizontalTabsProps {
  anchor?: 'top' | 'bottom';
  tabs: {label: string; component: ReactNode}[];
}

export const DSL_HorizontalTabs = ({
  anchor = 'bottom',
  tabs,
}: DSL_HorizontalTabsProps) => {
  const [value, setValue] = React.useState<string>(tabs[0].label);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    event.preventDefault();
    setValue(newValue);
  };

  return (
    <DSL_TabContext value={value}>
      <DSL_TabList indicatorAnchor={anchor} onChange={handleChange}>
        {tabs.map((tab, index) => (
          <DSL_Tab key={index} label={tab.label} value={tab.label} />
        ))}
      </DSL_TabList>
      <DSL_Divider />

      {tabs.map((tab, index) => (
        <DSL_TabPanel key={index} value={tab.label}>
          {tab.component}
        </DSL_TabPanel>
      ))}
    </DSL_TabContext>
  );
};
