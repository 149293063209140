import React from 'react';

import {DSL_InsertDriveFileIcon} from 'components/DesignSystem/Library/Icons';

import {DSL_BaseIconInfo, DSL_BaseIconInfoProps} from '../BaseIconInfo';

export type DSL_InsertFileIconInfoProps = Omit<DSL_BaseIconInfoProps, 'icon'>;

export const DSL_InsertFileIconInfo = (props: DSL_InsertFileIconInfoProps) => {
  return <DSL_BaseIconInfo icon={<DSL_InsertDriveFileIcon />} {...props} />;
};

DSL_InsertFileIconInfo.displayName = 'DSL_InsertFileIconInfo';
