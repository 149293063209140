import {lazy} from 'react';
import {RouteConfig} from 'app/router/types';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';
import Assignment from '@mui/icons-material/Assignment';

export const ManageCustomer = {
  path: '/manage/customers/:id',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "ManageAccreditation" */ './ManageAccreditation'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: true,
  menuTitle: 'Accreditation',
  menuIcon: Assignment,
  authorisation: {
    apiKey: 'AccreditationWithRelations.findById',
    policies: [{role: 'operationsAccreditationRead'}] as AuthorisationPolicy[],
  },
  sections: [],
} as RouteConfig;
