import React from 'react';

import {
  TimelineItemProps as TimelineItemMuiProps,
  TimelineItem as TimelineItemMui,
  timelineItemClasses,
} from '@mui/lab';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

export type DSL_TimelineItemProps = Omit<
  TimelineItemMuiProps,
  DSL_RestrictedVisualProps
>;

export const DSL_TimelineItem = (props: DSL_TimelineItemProps) => {
  return (
    <TimelineItemMui
      {...props}
      sx={{
        [`&.${timelineItemClasses.missingOppositeContent}`]: {
          '&:before': {
            display: 'none', // Required styling fix for TimelineItem
          },
        },
      }}
    />
  );
};

DSL_TimelineItem.displayName = 'DSL_TimelineItem';

export {
  //
  /** @deprecated use DSL_TimelineItemProps instead*/
  DSL_TimelineItemProps as TimelineItemProps, //
  /** @deprecated use DSL_TimelineItem instead*/
  DSL_TimelineItem as TimelineItem,
};
