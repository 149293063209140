import React from 'react';

import {DSL_FullscreenExitIcon} from '../../../Icons';
import {DSL_BaseIconButton, DSL_BaseIconButtonProps} from '../BaseIconButton';

export type DSL_FullscreenExitIconButtonProps = Omit<
  DSL_BaseIconButtonProps,
  'color' | 'children'
>;

export const DSL_FullscreenExitIconButton = (
  props: DSL_FullscreenExitIconButtonProps,
): JSX.Element => {
  return (
    <DSL_BaseIconButton aria-label={'fullscreen-exit'} {...props}>
      <DSL_FullscreenExitIcon fontSize={props.size} />
    </DSL_BaseIconButton>
  );
};

DSL_FullscreenExitIconButton.displayName = 'DSL_FullscreenExitIconButton';

export {
  //
  /** @deprecated use DSL_FullscreenExitIconButtonProps instead*/
  DSL_FullscreenExitIconButtonProps as FullscreenExitIconButtonProps, //
  /** @deprecated use DSL_FullscreenExitIconButton instead*/
  DSL_FullscreenExitIconButton as FullscreenExitIconButton,
};
