import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/accessProductApplications/:id',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "AccessProductApplicationDetails" */ './AccessProductApplicationDetails'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Manage network consultation case',
  menuIcon: LibraryAddCheckIcon,
  authorisation: {
    apiKey: 'AccessProductApplicationWithRelations.findById',
    policies: [
      {role: 'operationsSpatialRead'},
      {role: '$impersonator'},
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Network consultation cases',
      to: '..',
    },
  ],
};
