import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import LegacyTypography from 'components/typography/LegacyTypography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {useUserModelsMyAccountMappingsQuery} from 'app/api/mutations/UserMappings.generated';
import * as R from 'ramda';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {getThemeColor} from 'utils/global';
import {UserProfile} from '@regulatory-platform/common-utils';
import {DSL_Flex, DSL_Body1} from 'components/DesignSystem/Library';

type SelectAccountsProps = {
  userProfile: UserProfile;
  isAdmin: boolean;
};

const SelectAccounts: React.FC<SelectAccountsProps> = ({
  userProfile,
  isAdmin = false,
}: SelectAccountsProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  const {data: accountMappingData} = useUserModelsMyAccountMappingsQuery();
  const userMappings = R.defaultTo(
    [],
    accountMappingData?.userModelsMyAccountMappings,
  );

  const updatedMappingsWithValue = R.map(userMapping => {
    return {
      accountId: userMapping?.accountId,
      accountType: userMapping?.accountType,
      accountName: userMapping?.accountName,
      route: `${userMapping?.accountId}-${userMapping?.accountType}`,
    };
  }, userMappings);

  const hasOtherAccount = userProfile?.accountCount
    ? userProfile?.accountCount > 1
    : false;

  const navigateToRoute = (route: string, options: object = {}): void => {
    handleClose();
    navigate(route, options);
  };

  const themeColor = getThemeColor(userProfile.accountType as string);

  return (
    <>
      {hasOtherAccount && updatedMappingsWithValue.length > 0 ? (
        <>
          <Button
            id="accountSelector"
            variant="text"
            disableRipple={true}
            aria-controls={open ? 'accountSelector-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            endIcon={
              (isAdmin || hasOtherAccount) && (
                <KeyboardArrowDownIcon sx={{color: themeColor}} />
              )
            }
          >
            <LegacyTypography
              variant="body1"
              sx={{
                color: themeColor,
                fontWeight: 400,
              }}
            >
              {userProfile.accountName}
            </LegacyTypography>
          </Button>

          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'accountSelector',
            }}
            PaperProps={{
              style: {
                width: `20.25rem`,
                maxHeight: '25rem',
              },
            }}
          >
            {hasOtherAccount &&
              updatedMappingsWithValue.map((option, idx) => {
                const optionColor = getThemeColor(option.accountType as string);
                return (
                  <MenuItem
                    key={`account-${idx}`}
                    sx={{
                      display: 'flex',
                      paddingLeft: '35px',
                      position: 'relative',
                    }}
                    onClick={(): void =>
                      navigateToRoute('/changeAccount', {
                        state: {changeToAccount: option.route},
                        replace: true,
                      })
                    }
                  >
                    <Box
                      component="span"
                      sx={{
                        bgcolor: optionColor,
                        width: '10px',
                        height: '10px',
                        borderRadius: '50%',
                        position: 'absolute',
                        left: '10px',
                      }}
                    />
                    <Box component="span" sx={{whiteSpace: 'normal'}}>
                      {option.accountName}
                    </Box>
                  </MenuItem>
                );
              })}
          </Menu>
        </>
      ) : (
        <DSL_Flex maxWidth={'30rem'} overflowX={'hidden'}>
          <DSL_Body1 textOverflow={'ellipsis'}>
            {userProfile?.accountName}
          </DSL_Body1>
        </DSL_Flex>
      )}
    </>
  );
};

export default SelectAccounts;
