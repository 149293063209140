import React from 'react';

import {DSL_LockIcon} from '../../../Icons';
import {DSL_BaseIconButton, DSL_BaseIconButtonProps} from '../BaseIconButton';

export type DSL_LockIconButtonProps = Omit<
  DSL_BaseIconButtonProps,
  'color' | 'children'
>;

export const DSL_LockIconButton = ({
  size = 'large',
  ...props
}: DSL_LockIconButtonProps): JSX.Element => {
  return (
    <DSL_BaseIconButton aria-label="close" size={size} {...props}>
      <DSL_LockIcon fontSize={size} />
    </DSL_BaseIconButton>
  );
};

DSL_LockIconButton.displayName = 'DSL_LockIconButton';

export {
  //
  /** @deprecated use DSL_LockIconButtonProps instead*/
  DSL_LockIconButtonProps as LockIconButtonProps, //
  /** @deprecated use DSL_LockIconButton instead*/
  DSL_LockIconButton as LockIconButton,
};
