import {formControlSize} from '../../../utils/stores/types';
import React from 'react';
import {useStyles} from '../form.styles';
import FormControl from '@mui/material/FormControl';
import clsx from 'clsx';
import {DSL_Box} from 'components/DesignSystem/Library';

interface LegacyFieldWrapperInternalProps {
  /** @deprecated You should migrate to the new DS form layouts */
  size: formControlSize;
  multiline?: boolean;
  children: React.ReactNode;
  /** @deprecated most times you should be using helperText here */
  addOnElement?: React.ReactNode;
  includeOptionalSuffix?: boolean;
}

export interface LegacyFieldWrapperProps
  extends Omit<LegacyFieldWrapperInternalProps, 'children'> {
  inputComponent: React.ElementType;
}

const LegacyFieldWrapperInternal = ({
  size,
  multiline,
  children,
  addOnElement,
}: LegacyFieldWrapperInternalProps) => {
  const formClasses = useStyles();

  return (
    <FormControl
      data-name={'legacy-field-wrapper'}
      className={clsx(
        multiline && size !== 100 ? formClasses[12] : formClasses[size],
        multiline && size === 100
          ? formClasses.marginTopBottom
          : formClasses.margin,
      )}
    >
      <>
        {children}
        {addOnElement && <DSL_Box mt={0.5}>{addOnElement}</DSL_Box>}
      </>
    </FormControl>
  );
};

/**
 * Use this component to wrap our new components in styles that make them able
 * to be dropped in to old forms.
 *
 * Old form fields have the following differences which this wrapper accommodates:
 *
 * - They're wrapped in a FormControl with specific margins
 * - They accept a "size" prop which defines the width
 * - They use the small variant of input fields rather than the new medium
 *
 */
export const LegacyFieldWrapper = ({
  size,
  multiline,
  addOnElement,
  inputComponent: InputComponent,
  ...otherProps
}: LegacyFieldWrapperProps) => {
  return (
    <LegacyFieldWrapperInternal
      size={size}
      multiline={multiline}
      addOnElement={addOnElement}
    >
      <InputComponent size={'small'} {...otherProps} />
    </LegacyFieldWrapperInternal>
  );
};

LegacyFieldWrapper.displayName = 'LegacyFieldWrapper';
