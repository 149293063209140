import React from 'react';

import {
  ErrorBoundary,
  ErrorBoundaryPropsWithComponent,
} from 'react-error-boundary';

import {DSP_ErrorBoundaryFallback} from './ErrorBoundaryFallback';

export interface DSP_ErrorBoundaryProps
  extends Omit<ErrorBoundaryPropsWithComponent, 'FallbackComponent'> {
  FallbackComponent?: ErrorBoundaryPropsWithComponent['FallbackComponent'];
}

export const DSP_ErrorBoundary = ({
  FallbackComponent = DSP_ErrorBoundaryFallback,
  ...props
}: DSP_ErrorBoundaryProps) => {
  return <ErrorBoundary FallbackComponent={FallbackComponent} {...props} />;
};
