import React from 'react';

import {DSL_BaseGrid, DSL_BaseGridProps} from './BaseGrid';

export type DSL_ButtonLayoutProps = Omit<DSL_BaseGridProps, 'spacingUnit'>;
export const DSL_ButtonLayout = (props: DSL_ButtonLayoutProps) => (
  <DSL_BaseGrid columns={1} spacingUnit={2} {...props} />
);

export {
  //
  /** @deprecated use DSL_ButtonLayoutProps instead*/
  DSL_ButtonLayoutProps as ButtonLayoutProps, //
  /** @deprecated use DSL_ButtonLayout instead*/
  DSL_ButtonLayout as ButtonLayout,
};
