import React from 'react';

import {files as FilesLib} from '@regulatory-platform/common-utils';
import * as R from 'ramda';

import {
  Dialog,
  DialogContent,
  DialogActions,
  TextButton,
  SubmitButton,
  SelectInput,
  TextInput,
  FieldGroup,
} from 'components/DesignSystem/Library';

import {FilesConfig} from '../Attachments.types';

import {DSP_UseEditFileDialogResults} from './useEditFileDialog';
export interface DSP_EditFileDialogProps
  extends Omit<DSP_UseEditFileDialogResults, 'handleEditDialogOpen'> {
  attachmentConfig: FilesConfig;
}
export const DSP_EditFileDialog = ({
  attachmentConfig,
  editDialogOpen,
  editDialogState,
  setEditDialogField,
  handleEditDialogClose,
  onSubmit,
}: DSP_EditFileDialogProps) => {
  if (R.isNil(editDialogState.file)) {
    return null;
  }
  const selectedFile = editDialogState.file;
  const availablePrefixes = FilesLib.getAvailablePrefixes(
    selectedFile.name as string,
    attachmentConfig,
  );
  return (
    <Dialog
      open={editDialogOpen}
      onClose={handleEditDialogClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent width={'50ch'}>
        <FieldGroup>
          <TextInput
            id="name"
            label="File name"
            value={selectedFile?.name}
            disabled={true}
            span={12}
          />
          <TextInput
            autoFocus
            id="notes"
            label="Description (optional)"
            value={selectedFile?.note}
            onChange={(e): void => {
              setEditDialogField('note', e.target.value);
            }}
            span={12}
          />
          <SelectInput
            options={availablePrefixes.map(
              availablePrefix =>
                attachmentConfig.prefixes[availablePrefix].name,
            )}
            id="file-folder"
            value={
              attachmentConfig.prefixes[
                R.defaultTo('', selectedFile.prefix) as string
              ]?.name
            }
            fullWidth
            label="Move to"
            onChange={(e): void => {
              //find prefix
              const selectedPrefix = R.find(
                _prefix =>
                  attachmentConfig.prefixes[_prefix].name === e.target.value,
                R.keys(attachmentConfig.prefixes),
              ) as string;
              setEditDialogField('prefix', selectedPrefix);
            }}
            disabled={availablePrefixes.length === 1}
            span={12}
          ></SelectInput>
        </FieldGroup>
      </DialogContent>
      <DialogActions>
        <SubmitButton onClick={onSubmit}>Done</SubmitButton>
        <TextButton onClick={handleEditDialogClose}>Cancel</TextButton>
      </DialogActions>
    </Dialog>
  );
};

export {
  //
  /** @deprecated use DSP_EditFileDialogProps instead*/
  DSP_EditFileDialogProps as EditFileDialogProps, //
  /** @deprecated use DSP_EditFileDialog instead*/
  DSP_EditFileDialog as EditFileDialog,
};
