import React, {createContext, ReactNode} from 'react';

import {useRequiredContext} from '../../../utils/useRequiredContext';

import {DSL_DrawerContext} from './types';
import {useDrawerProvider} from './useDrawerProvider';

const DSL_DrawerContextInstance = createContext<DSL_DrawerContext | undefined>(
  undefined,
);

export const useDrawerContext = () =>
  useRequiredContext(DSL_DrawerContextInstance, DSL_DrawerProvider.displayName);

export interface DSL_DrawerProviderProps {
  children?: ReactNode;
}

export const DSL_DrawerProvider = ({children}: DSL_DrawerProviderProps) => {
  const value = useDrawerProvider();

  return (
    <DSL_DrawerContextInstance.Provider value={value}>
      {children}
    </DSL_DrawerContextInstance.Provider>
  );
};

DSL_DrawerProvider.displayName = 'DSL_DrawerProvider';

export {
  //
  /** @deprecated use DSL_DrawerProviderProps instead*/
  DSL_DrawerProviderProps as DrawerProviderProps, //
  /** @deprecated use DSL_DrawerProvider instead*/
  DSL_DrawerProvider as DrawerProvider,
};
