import React from 'react';

import {DSL_FilterCenterFocusIcon} from '../../../Icons';
import {DSL_BaseIconButton, DSL_BaseIconButtonProps} from '../BaseIconButton';

export type DSL_FilterCenterFocusButtonProps = Omit<
  DSL_BaseIconButtonProps,
  'color' | 'children'
>;

export const DSL_FilterCenterFocusIconButton = ({
  size = 'medium',
  ...props
}: DSL_FilterCenterFocusButtonProps): JSX.Element => {
  return (
    <DSL_BaseIconButton aria-label="back" size={size} {...props}>
      <DSL_FilterCenterFocusIcon fontSize={size} />
    </DSL_BaseIconButton>
  );
};

DSL_FilterCenterFocusIconButton.displayName = 'DSL_FilterCenterFocusIconButton';

export {
  //
  /** @deprecated use DSL_FilterCenterFocusButtonProps instead*/
  DSL_FilterCenterFocusButtonProps as FilterCenterFocusButtonProps, //
  /** @deprecated use DSL_FilterCenterFocusIconButton instead*/
  DSL_FilterCenterFocusIconButton as FilterCenterFocusIconButton,
};
