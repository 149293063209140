import React, {Fragment} from 'react';

import {FormLabel} from '@mui/material';
import {DSL_Body1} from 'components/DesignSystem/Library/Typography/Body1';

import {DSL_RestrictedVisualProps} from '../../../types';
import {DSL_Option} from '../../../types';
import {DSL_Body2} from '../../../Typography/Body2';
import {optionToLabel} from '../../utils/optionToLabel';
import {optionToValue} from '../../utils/optionToValue';
import {DSL_FormControl} from '../FormControl';
import {DSL_FormHelperText} from '../FormHelperText';

import {DSL_RadioGroup, DSL_RadioGroupProps} from './RadioGroup';
import {DSL_RadioOption} from './RadioOption';

export interface DSL_RadioGroupInputProps
  extends Omit<DSL_RadioGroupProps, DSL_RestrictedVisualProps | 'onClick'> {
  label?: React.ReactNode;
  hideLabel?: boolean;
  options?: readonly DSL_Option[];
  disabled?: boolean;
  error?: boolean;
  helperText?: React.ReactNode;
  boldSelectedOption?: boolean;
  onClick?: React.MouseEventHandler<HTMLLabelElement>;
}

export const DSL_RadioGroupInput = ({
  id,
  label,
  options = [],
  helperText = '',
  disabled = false,
  error = false,
  hideLabel = false,
  value,
  boldSelectedOption,
  onClick,
  ...radioGroupProps
}: DSL_RadioGroupInputProps) => {
  return (
    <DSL_FormControl error={error}>
      <FormLabel hidden={hideLabel} id={id}>
        {label}
      </FormLabel>
      <DSL_RadioGroup
        aria-labelledby={id}
        name={`radio-buttons-group-${id}`}
        value={value}
        {...radioGroupProps}
      >
        {options
          ?.filter(opt => !!opt)
          .map((option, index) => (
            <Fragment key={index}>
              <DSL_RadioOption
                radioColor={
                  typeof option === 'object' ? option?.radioColor : undefined
                }
                disabled={disabled}
                value={optionToValue(option)}
                label={
                  boldSelectedOption ? (
                    <DSL_Body1 bold={optionToValue(option) === value}>
                      {optionToLabel(option)}
                    </DSL_Body1>
                  ) : (
                    optionToLabel(option)
                  )
                }
                onClick={
                  typeof option === 'object' && onClick ? onClick : undefined
                }
              />
              {typeof option === 'object' && option?.description && (
                <DSL_Body2 pl={4}>{option.description}</DSL_Body2>
              )}
            </Fragment>
          ))}
      </DSL_RadioGroup>
      {helperText && <DSL_FormHelperText>{helperText}</DSL_FormHelperText>}
    </DSL_FormControl>
  );
};

DSL_RadioGroupInput.displayName = 'DSL_RadioGroupInput';

export {
  //
  /** @deprecated use DSL_RadioGroupInputProps instead*/
  DSL_RadioGroupInputProps as RadioGroupInputProps, //
  /** @deprecated use DSL_RadioGroupInput instead*/
  DSL_RadioGroupInput as RadioGroupInput,
};
