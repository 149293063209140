import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/accessProductDocuments/join/:accessProductId',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "JoinAccessProductDocument" */ './JoinAccessProductDocument'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Join vehicle network',
  menuIcon: LibraryAddCheckIcon,
  authorisation: {
    policies: [{role: 'partnerSpatialRead'}] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Vehicle networks',
      to: '..',
    },
  ],
};
