import React from 'react';

import {DSP_RoadManagementNotificationCardViewCard} from './RoadManagementNotificationCard.View';
import {
  useRoadManagementCard,
  DSP_UseRoadManagementCardProps,
} from './useRoadManagementCard';

export type DSP_RoadManagementNotificationCardProps =
  DSP_UseRoadManagementCardProps;

export const DSP_RoadManagementNotificationCard = (
  props: DSP_RoadManagementNotificationCardProps,
) => {
  const viewProps = useRoadManagementCard(props);

  return <DSP_RoadManagementNotificationCardViewCard {...viewProps} />;
};

DSP_RoadManagementNotificationCard.displayName =
  'DSP_RoadManagementNotificationCard';

export {
  //
  /** @deprecated use DSP_RoadManagementNotificationCardProps instead*/
  DSP_RoadManagementNotificationCardProps as RoadManagementNotificationCardProps, //
  /** @deprecated use DSP_RoadManagementNotificationCard instead*/
  DSP_RoadManagementNotificationCard as RoadManagementNotificationCard,
};
