import React, {createContext, ReactNode} from 'react';

import {useRequiredContext} from 'components/DesignSystem/utils/useRequiredContext';

import {DSP_SelectedSegmentsDrawerContext} from './types';
import {
  useDspSelectedSegmentsDrawer,
  UseDspSelectedSegmentsDrawerArgs,
} from './useSelectedSegmentsDrawer';

const DSP_SelectedSegmentsDrawerContextInstance = createContext<
  DSP_SelectedSegmentsDrawerContext | undefined
>(undefined);

export const useDspSelectedSegmentsDrawerContext = () =>
  useRequiredContext(
    DSP_SelectedSegmentsDrawerContextInstance,
    DSP_SelectedSegmentsDrawerProvider.displayName,
  );

export interface DSP_SelectedSegmentsDrawerProviderProps
  extends UseDspSelectedSegmentsDrawerArgs {
  children?: ReactNode;
}

export const DSP_SelectedSegmentsDrawerProvider = ({
  children,
  roads,
  selectSegment,
  activeSegmentId,
  getSegmentsByIds,
  RenderRoadSegmentsLengthComponent,
  onSegmentHover,
  onSegmentHoverOut,
  onBack,
  onChange,
  options,
}: DSP_SelectedSegmentsDrawerProviderProps) => {
  const value = useDspSelectedSegmentsDrawer({
    roads,
    selectSegment,
    activeSegmentId,
    getSegmentsByIds,
    RenderRoadSegmentsLengthComponent,
    onSegmentHover,
    onSegmentHoverOut,
    onBack,
    onChange,
    options,
  });

  return (
    <DSP_SelectedSegmentsDrawerContextInstance.Provider value={value}>
      {children}
    </DSP_SelectedSegmentsDrawerContextInstance.Provider>
  );
};

DSP_SelectedSegmentsDrawerProvider.displayName =
  'DSP_SelectedSegmentsDrawerProvider';
