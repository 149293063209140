import React from 'react';

import {
  DSL_UseGridItemStyles,
  DSL_GridItem,
  DSL_RadioCardGroupInput,
  DSL_RadioCardGroupInputProps,
} from 'components/DesignSystem/Library';

import {DSP_FieldProps} from '../../types';
import {useRadioGroup} from '../useRadioGroup';

export type DSP_RadioCardGroupFieldProps<Options extends string> =
  DSL_RadioCardGroupInputProps<Options> &
    DSP_FieldProps &
    DSL_UseGridItemStyles;

export function DSP_RadioCardGroupField<Options extends string>({
  fieldRef,
  span,
  ...props
}: DSP_RadioCardGroupFieldProps<Options>) {
  const viewProps = useRadioGroup({fieldRef});

  return (
    <DSL_GridItem span={span}>
      {/* TODO: https://nhvr.atlassian.net/browse/NSSP-8084 Fix this*/}
      {/* eslint-disable-next-line */}
      {/* @ts-ignore */}
      <DSL_RadioCardGroupInput {...viewProps} {...props} />
    </DSL_GridItem>
  );
}

DSP_RadioCardGroupField.displayName = 'DSP_RadioCardGroupField';
