import React, {useCallback} from 'react';

import {format} from 'date-fns';

import {
  BaseDateTimeRenderer,
  BaseDateTimeRendererProps,
} from 'components/DesignSystem/Library';

export type DSP_TimeRendererProps = Omit<
  BaseDateTimeRendererProps,
  'formatter'
>;

export const DSP_TimeRenderer = ({date, ...props}: DSP_TimeRendererProps) => {
  const formatter = useCallback(() => format(date, 'hh:mm aaa'), [date]);

  return <BaseDateTimeRenderer date={date} formatter={formatter} {...props} />;
};

DSP_TimeRenderer.displayName = 'DSP_TimeRenderer';

export {
  //
  /** @deprecated use DSP_TimeRendererProps instead*/
  DSP_TimeRendererProps as TimeRendererProps, //
  /** @deprecated use DSP_TimeRenderer instead*/
  DSP_TimeRenderer as TimeRenderer,
};
