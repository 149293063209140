import React from 'react';

import {DSL_IconWrapper} from '../IconWrapper';
import {DSL_IconProps} from '../types';

const DSL_Vehicle = (props: DSL_IconProps) => {
  return (
    <DSL_IconWrapper
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          fill="none"
          viewBox="0 0 24 24"
          {...props}
        >
          <path
            fill="#000"
            fillOpacity={0.54}
            fillRule="evenodd"
            d="M14 5a2 2 0 0 1 2 2h1.281a1 1 0 0 1 .904.572l1.041 2.197H21a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1h-2.05q.05.243.05.5a2.5 2.5 0 1 1-4.95-.5h-5.1q.05.243.05.5a2.5 2.5 0 1 1-4.95-.5H2V7a2 2 0 0 1 2-2zM6.5 15a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1m6.5-2V7H3.714v6zm3.5 2a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1m4-2v-2h-2.315L17 8.5h-2V13z"
            clipRule="evenodd"
          />
        </svg>
      }
      {...props}
    />
  );
};

export default React.memo(DSL_Vehicle);
