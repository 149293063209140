import React, {ReactNode} from 'react';

import {
  usePopper,
  DSL_Popper,
  DSL_PopperBody,
  DSL_FlexColumn,
} from 'components/DesignSystem/Library';

import {ContextualOption} from './ContextualOption';
import {ExternalLinkOption} from './ExternalLinkOption';

export interface NestedOptionProps {
  closePopper?: () => void;
  options?: {label: ReactNode; onClick?: () => void; href?: string}[];
}

export const NestedOption = ({closePopper, options}: NestedOptionProps) => {
  const {openerProps, popperProps} = usePopper<HTMLButtonElement>();

  return (
    <>
      {options && (
        <DSL_Popper
          maxWidth={{xs: 300}}
          placement={'right-start'}
          {...popperProps}
        >
          <DSL_PopperBody padding={'NONE'}>
            <DSL_FlexColumn gap={0} py={1}>
              {options.map((contextualOption, index) =>
                contextualOption.href ? (
                  <ExternalLinkOption
                    label={contextualOption.label}
                    key={index}
                    closePopper={popperProps.closePopper}
                    href={contextualOption.href as string}
                    onClick={() => {
                      popperProps.closePopper();
                      closePopper?.();
                      contextualOption.onClick?.();
                    }}
                  />
                ) : (
                  <ContextualOption
                    label={contextualOption.label}
                    key={index}
                    closePopper={popperProps.closePopper}
                    openerProps={openerProps}
                    onClick={() => {
                      popperProps.closePopper();
                      closePopper?.();
                      contextualOption.onClick?.();
                    }}
                  />
                ),
              )}
            </DSL_FlexColumn>
          </DSL_PopperBody>
        </DSL_Popper>
      )}
      <ContextualOption
        label={'Some nestable options'}
        openerProps={openerProps}
        popperProps={popperProps}
        nestedContextualOptions={options}
      />
    </>
  );
};
