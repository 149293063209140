import React from 'react';

import {Chip as MuiChip, ChipProps as MuiChipProps} from '@mui/material';

import {DSL_RestrictedVisualProps} from '../types';

export type DSL_ChipProps = Omit<MuiChipProps, DSL_RestrictedVisualProps>;

export const DSL_Chip = ({
  size = 'medium',
  variant = 'filled',
  ...props
}: DSL_ChipProps) => {
  return <MuiChip size={size} variant={variant} {...props} />;
};

DSL_Chip.displayName = 'DSL_Chip';

export {
  //
  /** @deprecated use DSL_ChipProps instead*/
  DSL_ChipProps as ChipProps, //
  /** @deprecated use DSL_Chip instead*/
  DSL_Chip as Chip,
};
