import React from 'react';

import {
  DSL_EntityTitle,
  DSL_EntityTitleProps,
  usePopover,
} from 'components/DesignSystem/Library';

import {
  DSP_CustomerAccountEntityPopover,
  DSP_CustomerAccountEntityPopoverProps,
} from '../CustomerAccountEntityPopover';

export interface DSP_CustomerAccountEntityNameViewProps
  extends Omit<DSP_CustomerAccountEntityPopoverProps, 'open'>,
    ReturnType<typeof usePopover<HTMLElement>>,
    Pick<DSL_EntityTitleProps, 'skeletonWidth'> {}

export const DSP_CustomerAccountEntityNameView = ({
  isLoading,
  title,
  subTitle,
  anchorRef,
  skeletonWidth,
  openPopover,
  closePopover,
  isPopoverOpen,
  ...props
}: DSP_CustomerAccountEntityNameViewProps) => {
  return (
    <>
      <DSP_CustomerAccountEntityPopover
        anchorEl={anchorRef.current}
        onMouseEnter={openPopover}
        onMouseLeave={closePopover}
        isLoading={isLoading}
        open={isPopoverOpen}
        title={title}
        subTitle={subTitle}
        {...props}
      />
      <DSL_EntityTitle
        ref={anchorRef}
        isLoading={isLoading}
        onMouseEnter={openPopover}
        onMouseLeave={closePopover}
        skeletonWidth={skeletonWidth}
        color={'primary.main'}
      >
        {title}
      </DSL_EntityTitle>
    </>
  );
};

DSP_CustomerAccountEntityNameView.displayName =
  'DSP_CustomerAccountEntityNameView';
