import React from 'react';

import {
  TabContext as MuiTabContext,
  TabContextProps as MuiTabContextProps,
} from '@mui/lab';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

export type DSL_TabContextProps = Omit<
  MuiTabContextProps,
  DSL_RestrictedVisualProps
>;

export const DSL_TabContext = (props: DSL_TabContextProps) => {
  return <MuiTabContext {...props} />;
};

DSL_TabContext.displayName = 'DSL_TabContext';

export {
  //
  /** @deprecated use DSL_TabContextProps instead*/
  DSL_TabContextProps as TabContextProps, //
  /** @deprecated use DSL_TabContext instead*/
  DSL_TabContext as TabContext,
};
