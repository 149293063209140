import {FormEventObject, FormMachineContext} from 'utils/stores/types';
import {createMachine, StateMachine} from 'xstate';
import {FormMachineStateSchema} from '../../../utils/machines/formMachine';

const machine = createMachine<FormMachineContext, FormEventObject>({
  id: 'verify',
  initial: 'awaitRecaptcha',
  invoke: {
    src: 'authStoreStream',
    onDone: 'finished',
  },
  states: {
    awaitRecaptcha: {
      on: {
        RECAPTCHA: {target: 'entry', actions: 'onRecaptcha'},
      },
    },
    entry: {
      always: [
        {cond: 'invalidUrl', target: 'invalidUrl'},
        {cond: 'verifyEmail', target: 'verifyingEmail'},
        {
          cond: 'verifyToken',
          target: 'verifyingToken',
          actions: 'onVerifyToken',
        },
      ],
    },
    verifyingEmail: {
      invoke: {
        src: 'apiVerifyEmail',
        onError: {target: 'error', actions: 'onError'},
        onDone: {target: 'success', actions: 'onVerifyEmailSuccess'},
      },
    },
    verifyingToken: {
      on: {
        ERROR: {target: 'error'},
        SUCCESS: {target: 'success'},
      },
    },
    invalidUrl: {
      type: 'final',
    },
    error: {
      type: 'final',
    },
    success: {
      type: 'final',
    },
    finished: {
      type: 'final',
    },
  },
});
export type VerifyMachine = StateMachine<
  FormMachineContext,
  FormMachineStateSchema,
  FormEventObject
>;

export default machine;
