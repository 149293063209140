import * as React from 'react';

import {
  DSL_RadioGroupInputProps,
  RadioGroupInputProps,
} from 'components/DesignSystem/Library';

import {useFormService} from '../FormProvider';
import {useFormFieldPropsSelector} from '../hooks';
import {getArrayFieldProps} from '../utils/getArrayFieldProps';

export interface DSP_UseRadioGroupProps {
  fieldRef: string;
  placeholder?: string;
  items?: string[];
}

export function useRadioGroup({
  fieldRef,
  placeholder,
  items,
}: DSP_UseRadioGroupProps): DSL_RadioGroupInputProps {
  const service = useFormService();

  return useFormFieldPropsSelector(fieldRef, service, state => {
    const fieldProps = getArrayFieldProps<false, false, false>({
      fieldRef,
      state,
      items,
    });

    return {
      ...fieldProps,
      onChange: (
        _: React.ChangeEvent<HTMLInputElement>,
        value?: string,
      ): void => {
        service.send({
          type: 'CHANGEBLUR',
          fieldRef,
          value,
        });
      },
      onBlur: (): void => {
        service.send({type: 'BLUR', fieldRef});
      },
      placeholder,
    } as RadioGroupInputProps;
  });
}
