import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/permitStandardConditions/create',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "CreatePermitStandardCondition" */ './CreatePermitStandardCondition'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Add standard condition',
  menuIcon: LibraryAddCheckIcon,
  authorisation: {
    apiKey: 'PermitStandardConditionWithRelations.create',
    policies: [
      {
        role: 'operationsPBSSupervisor',
      },
      {
        role: 'operationsAccessSupervisor',
      },
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Standard conditions',
      to: '..',
    },
  ],
};
