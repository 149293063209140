import {EmojiTransportationOutlined} from '@mui/icons-material';
import Registrations from 'containers-vehicle/Registrations';
import RegistrationsDetails from 'containers-vehicle/Registrations/RegistrationDetails';
import JurisdictionalContacts from 'containers-vehicle/Registrations/JurisdictionalContacts';
import RequestCertifiedCopy from 'containers-vehicle/Registrations/RequestCertifiedCopy';
import VehicleSpecifications from './VehicleSpecifications';
import CertificationConfirmation from './VehicleSpecifications/CertificationConfirmation';
import VehicleSpecificationGateway from './VehicleSpecifications/VehicleGateway';
import VehicleSpecificationDetails from 'containers-vehicle/VehicleSpecifications/VehicleSpecificationDetails';

export default {
  routes: [
    VehicleSpecifications,
    Registrations,
    JurisdictionalContacts,
    RequestCertifiedCopy,
    CertificationConfirmation,
    RegistrationsDetails,
    VehicleSpecificationDetails,
    VehicleSpecificationGateway,
  ],
  path: '/vehicle',
  menuTitle: 'Vehicle database',
  menuDescription:
    'Heavy vehicle database including vehicle specifications and registration information',
  showChildRoutesInMenu: true,
  menuIcon: EmojiTransportationOutlined,
};
