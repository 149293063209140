import {isValidByJsonPointer} from '@regulatory-platform/common-utils';
import * as R from 'ramda';
import React, {useMemo} from 'react';
import {FormRecord, FormStoreContainer} from 'utils/stores/types';
import {TabStep} from '../types';
import getTabConfigSteps from '../utils/getTabConfigSteps';
import isValidStep from '../utils/isValidStep';
import {useWizardResults} from './types';
import {useSelector} from '@xstate/react';
import {scrollMainLayoutToTop} from '../../layout/AuthenticatedLayout/AuthenticatedLayout';

type PartialStoreContainer = Partial<FormStoreContainer> &
  Pick<FormStoreContainer, 'service'>;
export default function useLegacyWizards(
  tabSteps: TabStep[],
  storeContainer: PartialStoreContainer,
  initialActiveStep?:
    | number
    | ((state: FormStoreContainer['state']) => number | undefined),
): useWizardResults {
  const {service} = storeContainer;
  const getValidatedState = (state: FormStoreContainer['state']) => {
    return {
      stepsValidated: R.map((tabStep: TabStep) => {
        const stepFields = R.defaultTo([], tabStep?.validateFields);
        const validatedFields = R.filter(fieldRef => {
          return isValidByJsonPointer(fieldRef, false)(state.context.schema);
        }, stepFields);
        return {
          valid: stepFields.length === validatedFields.length,
          validTouched: isValidStep(
            state.context,
            R.defaultTo([], tabStep.validateFields),
          ),
        };
      }, R.defaultTo([], tabSteps)),
      id: (state.context.record as FormRecord | undefined)?.id,
      overrideTouched: state.context.props.overrideTouched,
    };
  };

  const {id, overrideTouched, stepsValidated} = useSelector(
    service,
    getValidatedState,
    R.equals,
  );

  const [tabConfig, setTabConfig] = React.useState({
    steps: getTabConfigSteps(R.defaultTo([], tabSteps).length),
    activeStep: -1,
  });

  //TODO: temporary fix to useMemo implementation, replace this when replacing legacy functionality
  let activeStepValid = true;
  if (
    tabConfig.activeStep !== -1 &&
    !R.isNil(tabSteps[tabConfig.activeStep]) &&
    !R.isNil(tabSteps[tabConfig.activeStep].validateFields)
  ) {
    activeStepValid = stepsValidated[tabConfig.activeStep].validTouched;
  }

  //initiate tabConfig
  React.useEffect(() => {
    if (tabSteps.length === 0) {
      return;
    }
    let activeStep = 0;
    const state = service.getSnapshot();
    const overrideActionStep =
      typeof initialActiveStep === 'number'
        ? initialActiveStep
        : initialActiveStep?.(state);
    const steps = getTabConfigSteps(R.defaultTo([], tabSteps).length);
    const validatedState = getValidatedState(state);
    validatedState.stepsValidated.reduce(
      (
        accStepValid: boolean,
        stepValid: {valid: boolean},
        idx: number,
      ): boolean => {
        if (accStepValid) {
          accStepValid = stepValid.valid;
          steps[idx].active = true;
          activeStep = idx;
          if (!accStepValid || overrideActionStep !== undefined) {
            return false;
          }
          steps[idx].completed = true;
        }
        return accStepValid;
      },
      true,
    );
    setTabConfig({
      steps,
      activeStep: overrideActionStep ?? activeStep,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabSteps, id]);

  return useMemo(() => {
    const _stepOnChange = (nextStep: number, skippedStep?: number): void => {
      //get current active tab
      const activeStep = tabConfig.activeStep;
      //touch all tabs up to but not including next step unless the next step = active step
      let touchUptoStep = R.clamp(0, nextStep, nextStep - 1);
      if (nextStep === activeStep) {
        touchUptoStep = nextStep;
      }
      setTabConfig(prevTabConfig => {
        prevTabConfig.steps = R.map(step => {
          const validateFields = R.defaultTo(
            [],
            tabSteps[step.index]?.validateFields,
          );
          step.touched = step.touched || touchUptoStep >= step.index;
          step.active = step.index === nextStep;
          if (step.active && step.touched) {
            service.send({
              type: 'TAB_TOUCHED',
              fieldRef: tabSteps[step.index]?.label,
              fieldRefs: validateFields,
            });
          }
          if (skippedStep === step.index) {
            step.skipped = true;
          }
          if (step.active && tabSteps[step.index].onClickBlurField) {
            service.send({
              type: 'BLUR',
              fieldRef: tabSteps[step.index].onClickBlurField as string,
            });
          }
          if (step.active && tabSteps[step.index].onClickValidateField) {
            service.send({
              type: 'VALIDATE',
              fieldRef: tabSteps[step.index].onClickValidateField as string,
            });
          }
          return step;
        }, prevTabConfig.steps);
        prevTabConfig = R.mergeRight(prevTabConfig, {activeStep: nextStep});
        return prevTabConfig;
      });
      scrollMainLayoutToTop();
    };

    const _totalSteps = (): number => {
      return R.length(tabConfig.steps);
    };

    const _isStepOptional = (stepIndex: number): boolean => {
      return R.defaultTo(false, tabSteps[stepIndex].optional);
    };

    const _skippedSteps = (): number => {
      return R.filter(step => step.skipped === true, tabConfig.steps).length;
    };

    const _completedSteps = (): number => {
      return R.filter(step => step?.completed === true, tabConfig.steps).length;
    };

    const _allStepsCompleted = (): boolean => {
      return _completedSteps() === _totalSteps() - _skippedSteps();
    };

    const _isLastStep = (): boolean => {
      return tabConfig.activeStep === _totalSteps() - 1;
    };

    const _handleSkip = (): void => {
      if (!_isStepOptional(tabConfig.activeStep)) {
        // You probably want to guard against something like this
        // it should never occur unless someone's actively trying to break something.
        throw new Error("You can't skip a step that isn't optional.");
      }

      setTabConfig(prevTabConfig => {
        prevTabConfig.steps[prevTabConfig.activeStep].skipped = true;
        prevTabConfig = R.mergeRight(prevTabConfig, {
          activeStep: prevTabConfig.activeStep + 1,
        });
        return prevTabConfig;
      });
    };

    const _handleComplete = (idx: number): void => {
      setTabConfig(prevTabConfig => {
        prevTabConfig.steps[idx].completed = true;
        prevTabConfig = R.mergeRight(prevTabConfig, {
          activeStep: idx >= prevTabConfig.steps.length - 1 ? idx : idx + 1,
        });
        return prevTabConfig;
      });
    };

    const _handleNext = (): void => {
      const newActiveStep =
        _isLastStep() && !_allStepsCompleted()
          ? // It's the last step, but not all steps have been completed
            // find the first step that has not been completed
            R.findIndex(step => step.completed !== true, tabConfig.steps)
          : tabConfig.activeStep + 1;
      if (activeStepValid) {
        _handleComplete(tabConfig.activeStep);
      }
      _stepOnChange(newActiveStep);
    };

    const _handleBack = (): void => {
      _stepOnChange(tabConfig.activeStep - 1);
    };

    const _onStepChange = (
      label: string,
      skipValidation?: boolean | undefined,
    ): void => {
      if (stepsValidated[tabConfig.activeStep].validTouched) {
        _handleComplete(tabConfig.activeStep);
      }
      if (R.isNil(skipValidation)) {
        //find tab step index
        const stepIndex = R.findIndex(R.propEq('label', label), tabSteps);
        if (stepIndex > -1) {
          _stepOnChange(stepIndex);
        }
      }
    };

    const _handleReset = (): void => {
      setTabConfig({
        steps: getTabConfigSteps(R.defaultTo([], tabSteps).length),
        activeStep: 0,
      });
    };

    const _isStepSkipped = (stepIndex: number): boolean => {
      return (
        !R.isNil(tabConfig.steps[stepIndex]) &&
        tabConfig.steps[stepIndex].skipped === true
      );
    };

    const _isStepComplete = (stepIndex: number): boolean => {
      return (
        !R.isNil(tabConfig.steps[stepIndex]) &&
        tabConfig.steps[stepIndex].completed === true
      );
    };

    const _isStepFailed = (stepIndex: number): boolean => {
      return (
        (overrideTouched ||
          (!R.isNil(tabConfig.steps[stepIndex]) &&
            tabConfig.steps[stepIndex]?.touched)) &&
        !stepsValidated[stepIndex].validTouched
      );
    };

    return [
      tabConfig,
      {
        handleSkip: _handleSkip,
        handleNext: _handleNext,
        handleBack: _handleBack,
        handleReset: _handleReset,
        onStepChange: _onStepChange,
      },
      {
        isStepOptional: _isStepOptional,
        isStepSkipped: _isStepSkipped,
        isStepComplete: _isStepComplete,
        isStepFailed: _isStepFailed,
        isLastStep: _isLastStep,
        allStepsCompleted: _allStepsCompleted,
        completedSteps: _completedSteps,
        skippedSteps: _skippedSteps,
        totalSteps: _totalSteps,
      },
    ];
  }, [tabSteps, tabConfig, activeStepValid]);
}
