import React, {useMemo} from 'react';

import {Properties} from 'csstype';

import {DSL_Box, DSL_BoxProps} from '../../';
import {DSL_RestrictedStyleProps} from '../../types';
import {
  DSL_BreakpointValues,
  useCascadingBreakpointValue,
} from '../hooks/useCascadingBreakpointValue';

export interface DSL_BaseGridProps
  extends Omit<DSL_BoxProps, DSL_RestrictedStyleProps> {
  spacingUnit?: number;
  columns?: DSL_BreakpointValues<number>;
  gap?: DSL_BreakpointValues<number>;
  children: React.ReactNode;
  overflowX?: Properties['overflowX'];
  overflowY?: Properties['overflowY'];
}

export const DSL_BaseGrid = ({
  spacingUnit = 4,
  columns = 12,
  gap,
  overflowX,
  overflowY,
  children,
  ...props
}: DSL_BaseGridProps) => {
  const effectiveGap = useCascadingBreakpointValue(gap);
  const effectiveColumns = useCascadingBreakpointValue(columns);

  const styles = useMemo(
    () => ({
      overflowX,
      overflowY,
      display: 'grid',
      gap: effectiveGap ?? {xs: spacingUnit / 2, sm: spacingUnit}, // xs: 2 is needed to prevent breaking the layout on mobile
      gridColumn: 'span 12',
      gridTemplateColumns: `repeat(${effectiveColumns}, 1fr)`,
    }),
    [effectiveGap, spacingUnit, effectiveColumns, overflowX, overflowY],
  );

  return (
    <DSL_Box internal_sx={styles} data-name={'base-grid'} {...props}>
      {children}
    </DSL_Box>
  );
};

export {
  //
  /** @deprecated use DSL_BaseGridProps instead*/
  DSL_BaseGridProps as BaseGridProps, //
  /** @deprecated use DSL_BaseGrid instead*/
  DSL_BaseGrid as BaseGrid,
};
