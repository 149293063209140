import React from 'react';

import {DSP_HelpMenuView} from './HelpMenu.View';
import {useHelpMenu} from './useHelpMenu';

export const DSP_HelpMenu = () => {
  const props = useHelpMenu();
  return <DSP_HelpMenuView {...props} />;
};

DSP_HelpMenu.displayName = 'DSP_HelpMenu';

export {
  //
  /** @deprecated use DSP_HelpMenu instead*/
  DSP_HelpMenu as HelpMenu,
};
