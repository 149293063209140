import React from 'react';

import {
  GridItem,
  UseGridItemStyles,
  SearchAutocompleteInput,
  SearchAutocompleteInputProps,
} from 'components/DesignSystem/Library';

import {DSP_FieldProps} from '../types';

import {
  DSP_UseSearchRelationFieldArgs,
  useSearchRelationField,
} from './useSearchRelationField';

export interface DSP_SearchRelationFieldProps
  extends DSP_UseSearchRelationFieldArgs,
    Omit<DSP_FieldProps, 'fieldRef'>,
    UseGridItemStyles,
    Omit<SearchAutocompleteInputProps, 'value' | 'options' | 'fieldRef'>,
    Partial<Pick<SearchAutocompleteInputProps, 'options'>> {}

export const DSP_SearchRelationField = ({
  span,
  ...props
}: DSP_SearchRelationFieldProps) => {
  const fieldProps = useSearchRelationField(props);

  return (
    <GridItem span={span}>
      <SearchAutocompleteInput {...fieldProps} />
    </GridItem>
  );
};

export {
  //
  /** @deprecated use DSP_SearchRelationFieldProps instead*/
  DSP_SearchRelationFieldProps as SearchRelationFieldProps, //
  /** @deprecated use DSP_SearchRelationField instead*/
  DSP_SearchRelationField as SearchRelationField,
};
