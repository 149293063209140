import {useMemo} from 'react';
import {ContentKeys, DomainContentMap, i18n} from 'containers-content';
import {AuthorisationDomains} from '@regulatory-platform/common-utils/dist';

export function useRegisterContent(
  content: DomainContentMap<AuthorisationDomains, ContentKeys> | undefined,
) {
  useMemo(() => {
    if (!content) {
      return;
    }

    return i18n.registerContent(content);
  }, [content]);
}
