import {
  JSONSchemaRecord,
  schemaJsonPointerResolver,
  FormattingConfig,
} from '@regulatory-platform/common-utils';
import * as R from 'ramda';

import {FormattedNumberInputProps} from 'components/DesignSystem/Library';

import {useFormContext} from '../FormProvider';
import {useFormFieldPropsSelector} from '../hooks/useFormFieldPropsSelector';
import {DSP_FieldProps} from '../types';
import {getGenericFieldProps} from '../utils/getGenericFieldProps';

export interface DSP_UseNumberField
  extends DSP_FieldProps,
    Partial<FormattedNumberInputProps> {
  stepper?: boolean;
  hideUom?: boolean;
  includeOptionalSuffix?: boolean;
}

export const useNumberField = ({
  fieldRef,
  stepper,
  hideUom,
  label,
  hideLabel,
  onBlurEvent,
  onChangeEvent,
  includeOptionalSuffix,
  useLabelForValidationErrors,
  ...propsOverrides
}: DSP_UseNumberField): FormattedNumberInputProps => {
  const {service} = useFormContext();
  const send = service.send;

  return useFormFieldPropsSelector(fieldRef, service, state => {
    const {schema} = state.context;
    const fieldConfig = schemaJsonPointerResolver(fieldRef)(
      schema,
    ) as JSONSchemaRecord;

    const fieldFormatting = schemaJsonPointerResolver<
      FormattingConfig | undefined
    >(
      fieldRef,
      'x-formatting',
    )(schema);
    const genericFieldProps = getGenericFieldProps({
      fieldRef,
      state,
      label,
      hideLabel,
      includeOptionalSuffix,
      useLabelForValidationErrors,
    });

    const inputProps: Partial<FormattedNumberInputProps> = {};
    //add field formatting
    inputProps.uom = hideUom ? undefined : fieldFormatting?.uom;
    if (!R.isNil(fieldFormatting) && !R.isNil(fieldFormatting?.decimalScale)) {
      let decimalScale = fieldFormatting.decimalScale;
      const uomConversion = fieldFormatting.uomConversion;
      if (uomConversion === 10) {
        decimalScale = fieldFormatting.decimalScale - 1;
      }
      if (uomConversion === 100) {
        decimalScale = fieldFormatting.decimalScale - 2;
      }
      if (uomConversion === 1000) {
        decimalScale = fieldFormatting.decimalScale - 3;
      }
      inputProps.decimalScale = decimalScale;
    }

    if (stepper) {
      const uomConversion = R.defaultTo(1, fieldFormatting?.uomConversion);
      inputProps.step =
        !R.isNil(fieldFormatting) && !R.isNil(fieldFormatting?.step)
          ? fieldFormatting.step * uomConversion
          : undefined;
      inputProps.min = !R.isNil(fieldConfig.minimum)
        ? fieldConfig.minimum * uomConversion
        : undefined;
      inputProps.max = !R.isNil(fieldConfig.maximum)
        ? fieldConfig.maximum * uomConversion
        : undefined;
    }

    inputProps.value = !R.isNil(genericFieldProps.value)
      ? R.defaultTo(1, fieldFormatting?.uomConversion) *
        (genericFieldProps.value as number)
      : null;

    return {
      ...genericFieldProps,
      ...inputProps,
      ...propsOverrides,
      stepper,
      onChange: (e, numericValue): void => {
        const value =
          fieldFormatting?.uomConversion && R.is(Number, numericValue)
            ? numericValue / fieldFormatting.uomConversion
            : numericValue;

        send({type: 'CHANGE', fieldRef, value});
        onChangeEvent?.();
      },
      onBlur: (): void => {
        send({type: 'BLUR', fieldRef});
        onBlurEvent?.();
      },
    };
  }) as FormattedNumberInputProps;
};
