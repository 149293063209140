import React from 'react';

import {DSL_BaseSummaryItem, DSL_BaseSummaryItemProps} from './BaseSummaryItem';

export type DSL_SummaryPageItemProps = Omit<
  DSL_BaseSummaryItemProps,
  'variant'
>;
export const DSL_SummaryPageItem = (props: DSL_SummaryPageItemProps) => (
  <DSL_BaseSummaryItem variant={'page'} {...props} />
);

DSL_SummaryPageItem.displayName = 'DSL_SummaryPageItem';

export {
  //
  /** @deprecated use DSL_SummaryPageItemProps instead*/
  DSL_SummaryPageItemProps as SummaryPageItemProps, //
  /** @deprecated use DSL_SummaryPageItem instead*/
  DSL_SummaryPageItem as SummaryPageItem,
};
