import {FetchResult} from '@apollo/client';
import * as R from 'ramda';

export default function getFetchDataResult<T>(
  result?: FetchResult,
): T | undefined {
  const data = result?.data;
  if (R.isNil(data)) {
    return undefined;
  }
  return data[R.keys(data)[0]];
}
