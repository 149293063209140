import {jsonPointerResolver} from '@regulatory-platform/common-utils';
import * as R from 'ramda';
import {FormEventObject, FormMachineContext} from './types';

export default function isChangingField(
  context: FormMachineContext,
  event: FormEventObject,
): boolean {
  const {fieldRef, value, fieldRefRelation, valueRelation} = event;
  const {record: origRecord, methodName} = context;
  const isNewRecord = fieldRef.indexOf('/-1/') > -1 || methodName === 'create';
  if (!isNewRecord && !R.isNil(fieldRefRelation)) {
    const origRelationValue = jsonPointerResolver(fieldRefRelation)(origRecord);
    if (R.equals(origRelationValue, valueRelation)) {
      return false;
    }
  }

  const origFieldValue = jsonPointerResolver(fieldRef)(origRecord);
  return !R.equals(origFieldValue, value);
}
