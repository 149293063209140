import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';
export default {
  path: '/accessInstruments',
  component: lazy(
    () =>
      import(/* webpackChunkName: "AccessInstruments" */ './AccessInstruments'),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'Access instruments',
  menuIcon: LibraryAddCheckIcon,
  authorisation: {
    apiKey: 'AccessInstrumentWithRelations.findById',
    policies: [
      {role: 'operationsSpatialRead'},
      {role: 'partnerSpatialRead'},
    ] as AuthorisationPolicy[],
  },
  sections: [],
};
