import React, {ReactNode} from 'react';

import {DSL_Box, DSL_BoxProps} from '../../Layout/Box';
import {DSL_SkeletonLoader} from '../../Skeleton/SkeletonLoader';

export interface DSL_EntityInfoRowProps extends DSL_BoxProps {
  isLoading: boolean | undefined;
  children: ReactNode;
  skeletonWidth?: number;
  skeletonHeight?: number;
}

export const DSL_EntityInfoRow = ({
  skeletonWidth = 250,
  skeletonHeight = 25,
  isLoading,
  children,
}: DSL_EntityInfoRowProps) => {
  return (
    <DSL_Box>
      <DSL_SkeletonLoader
        isLoading={isLoading}
        width={skeletonWidth}
        height={skeletonHeight}
      >
        {children}
      </DSL_SkeletonLoader>
    </DSL_Box>
  );
};

DSL_EntityInfoRow.displayName = 'DSL_EntityInfoRow';

export {
  //
  /** @deprecated use DSL_EntityInfoRowProps instead*/
  DSL_EntityInfoRowProps as EntityInfoRowProps, //
  /** @deprecated use DSL_EntityInfoRow instead*/
  DSL_EntityInfoRow as EntityInfoRow,
};
