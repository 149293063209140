import {ApolloError} from '@apollo/client';
import * as R from 'ramda';
import {ErrorDetails} from '../stores/types';

export default function getError(err?: ApolloError): ErrorDetails | null {
  if (R.isNil(err)) {
    return null;
  }
  return (
    err?.graphQLErrors[0]?.extensions?.responseBody?.error || {
      message: 'Could not connect to server',
    }
  );
}
