import React from 'react';

import {
  DSP_CustomerAccountEntityAvatarWithName,
  DSP_CustomerAccountEntityLoaderProvider,
} from 'components/DesignSystem/Portal/Entity/AccountEntity/CustomerEntity';

import {
  DSP_BaseAccreditationApplicationNotificationCard,
  DSP_BaseAccreditationApplicationNotificationCardProps,
} from '../Base/BaseAccreditationApplicationNotificationCard.Connector';

export interface DSP_AccreditationApplicationOperationsNotificationCardViewProps
  extends Omit<
    DSP_BaseAccreditationApplicationNotificationCardProps,
    'avatar'
  > {
  customerAccountId: number | undefined | null;
}

export const DSP_AccreditationApplicationOperationsNotificationCardView = ({
  notification,
  customerAccountId,
}: DSP_AccreditationApplicationOperationsNotificationCardViewProps) => {
  return (
    <DSP_BaseAccreditationApplicationNotificationCard
      notification={notification}
      avatar={
        customerAccountId && (
          <DSP_CustomerAccountEntityLoaderProvider entityId={customerAccountId}>
            <DSP_CustomerAccountEntityAvatarWithName />
          </DSP_CustomerAccountEntityLoaderProvider>
        )
      }
    />
  );
};

DSP_AccreditationApplicationOperationsNotificationCardView.displayName =
  'DSP_AccreditationApplicationOperationsNotificationCardView';
