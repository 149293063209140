import {SelectChangeEvent} from '@mui/material';

import {useDspSelectedSegmentsDrawerContext} from '../SelectedSegmentsDrawer.Provider';
import {DSP_FilterByRoadsOrAccessType} from '../types';

export const useDspViewByRoadsOrAccessTypeSelect = () => {
  const {
    filterByRoadsOrAccessTypeValue,
    setFilterByAccessTypeValue,
    setFilterByRoadsOrAccessTypeValue,
  } = useDspSelectedSegmentsDrawerContext();

  const handleViewByFilterChange = (
    event: SelectChangeEvent<DSP_FilterByRoadsOrAccessType>,
  ): void => {
    setFilterByRoadsOrAccessTypeValue(
      event.target.value as DSP_FilterByRoadsOrAccessType,
    );
    setFilterByAccessTypeValue(undefined);
  };

  return {
    value: filterByRoadsOrAccessTypeValue,
    onChange: handleViewByFilterChange,
  };
};
