import {useTheme} from '@mui/material';

import {DSL_UseDrawerArgs, useDrawer} from '../useDrawer';

export interface DSL_UseNonModalDrawerArgs
  extends Omit<DSL_UseDrawerArgs, 'variant'> {
  isTopOfStack?: boolean;
}

export function useNonModalDrawer({
  isTopOfStack,
  ...args
}: DSL_UseNonModalDrawerArgs) {
  const theme = useTheme();
  const {zIndexModifier, ...props} = useDrawer({
    ...args,
    variant: 'nonModal',
  });

  return {
    ...props,
    muiVariant: 'persistent' as const,
    zIndex: isTopOfStack
      ? theme.zIndex.drawerNonModalTopOfStack
      : theme.zIndex.drawerNonModal + zIndexModifier,
  };
}
