import {FaceOutlined} from '@mui/icons-material';
import SSORequest from './AuthoriseRedirect/SSORequest';

export default {
  routes: [SSORequest],
  menuTitle: 'SSO',
  menuDescription: 'SSO',
  path: '/sso',
  showChildRoutesInMenu: false,
  menuIcon: FaceOutlined,
};
