/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {useContext} from 'react';

import {DocumentNode, useApolloClient} from '@apollo/client';
import {
  jsonPointerResolver,
  ObjectType,
  schemaJsonPointerResolver,
} from '@regulatory-platform/common-utils';
import {JSONSchemaRecord} from '@regulatory-platform/common-utils/dist';
import {useMachine} from '@xstate/react';
import AppServicesContext from 'app/services/AppServicesContext';
import {
  actionVoter,
  baseSchema,
  validator,
} from 'common/global/entities/search/search';
import * as R from 'ramda';
import createSyntheticFetchResult from 'utils/apollo/createSyntheticFetchResult';
import getFetchDataResult from 'utils/apollo/getFetchDataResult';
import searchMachineActions, {
  MachineActions,
} from 'utils/machines/searchMachineActions';
import searchMachineGuards from 'utils/machines/searchMachineGuards';
import getFormStore from 'utils/stores/getFormStore';
import {
  FormStoreContainer,
  SearchStoreMachineContext,
  RelatedRecord,
  FormRecord,
} from 'utils/stores/types';

import fieldRelationsSearchMachine from '../AccountSearchRelationField/api/fieldRelationsSearchMachine';

export default function useFieldRelationsMultiSelectSearchMachine(
  service: FormStoreContainer['service'],
  fieldRef: string,
  fieldRefRelation: string,
  query: DocumentNode,
  queryVariables = {},
  emptyRecord: RelatedRecord,
  searchResultToString: (searchResult?: RelatedRecord) => string,
  clientSideFilter: SearchStoreMachineContext['clientSideFilter'] = R.identity,
  findRelatedEntity: SearchStoreMachineContext['findRelatedEntity'] = v =>
    Promise.resolve(createSyntheticFetchResult(v)),
) {
  const appServices = useContext(AppServicesContext);
  const client = useApolloClient();

  const container = useMachine(fieldRelationsSearchMachine, {
    context: R.mergeRight(getFormStore<FormRecord>(baseSchema, validator), {
      props: {},
      data: [],
      fieldRef,
      fieldRefRelation,
      clientSideFilter,
      findRelatedEntity,
      searchResultToString: searchResultToString as (
        searchResult?: ObjectType,
      ) => string,
    }),
    guards: searchMachineGuards(),
    actions: R.mergeRight(
      searchMachineActions(baseSchema, validator, actionVoter, appServices),
      {
        onFindRelatedEntitySuccess: (_context, event) => {
          if (event.data) {
            const relatedRecord = getFetchDataResult(
              event.data,
            ) as RelatedRecord;
            service.send({
              type: 'CHANGEBLUR',
              fieldRef,
              value: relatedRecord.map((record: RelatedRecord) => record.id),
              fieldRefRelation,
              valueRelation: relatedRecord,
            });
          }
        },
        onSelect: (_context, event) => {
          let relatedRecord = event.value as RelatedRecord;
          if (R.isNil(relatedRecord)) {
            relatedRecord = R.defaultTo({id: null}, emptyRecord);
          }

          service.send({
            type: 'CHANGEBLUR',
            fieldRef,
            value: relatedRecord.id,
            fieldRefRelation,
            valueRelation: relatedRecord,
          });
        },
      } as MachineActions,
    ),
    services: {
      apiFindRelatedEntity: async (_context, event) => {
        const relatedRecord = event.value as RelatedRecord;

        if (R.isNil(relatedRecord)) {
          return Promise.resolve(null);
        }
        return findRelatedEntity(relatedRecord as JSONSchemaRecord);
      },
      apiSearch: context => {
        return client
          .query({
            query,
            variables: R.mergeRight(
              {qs: context.record.search},
              queryVariables,
            ),
            fetchPolicy: 'no-cache',
            errorPolicy: 'none',
          })
          .then(context.clientSideFilter);
      },
      formStoreStream: context => callback => {
        const subscription = service.subscribe({
          next: emitted => {
            //NB: context is not updated - only original value - context.fieldRef set on initialisation
            const ref = R.defaultTo(context.fieldRef, context.fieldRefRelation);
            let fieldValue = jsonPointerResolver(ref)(
              emitted.context.record,
            ) as RelatedRecord | RelatedRecord[];

            // Check if fieldValue is an array, if not make it an array
            if (!Array.isArray(fieldValue)) {
              fieldValue = fieldValue ? [fieldValue] : [];
            }

            const fieldSchema = schemaJsonPointerResolver(context.fieldRef)(
              emitted.context.schema,
            );

            callback({
              type: 'FORM_VALUE_CHANGE',
              fieldRef: ref,
              value: fieldValue,
              schema: fieldSchema,
              flag: emitted.context.props.overrideTouched,
            });
          },
          error: () => {
            /* ... */
          },
          complete: () => {
            /* ... */
          },
        });
        return () => subscription.unsubscribe();
      },
    },
    delays: {
      DEBOUNCE_INPUT_DELAY: () => 500,
    },
  });
  return container;
}
