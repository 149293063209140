import React from 'react';

import {DSL_FlexColumn, DSL_FlexColumnProps} from '../../Layout/FlexColumn';

export type DSL_EntityInfoContainerProps = Omit<
  DSL_FlexColumnProps,
  'justifyContent'
>;

export const DSL_EntityInfoContainer = (
  props: DSL_EntityInfoContainerProps,
) => {
  return <DSL_FlexColumn justifyContent={'center'} {...props} />;
};

DSL_EntityInfoContainer.displayName = 'DSL_EntityInfoContainer';

export {
  //
  /** @deprecated use DSL_EntityInfoContainerProps instead*/
  DSL_EntityInfoContainerProps as EntityInfoContainerProps, //
  /** @deprecated use DSL_EntityInfoContainer instead*/
  DSL_EntityInfoContainer as EntityInfoContainer,
};
