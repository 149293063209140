import React from 'react';

import {DSL_BaseAlert, DSL_BaseAlertProps} from '../BaseAlert';

export type DSL_GlobalInfoAlertProps = Omit<
  DSL_BaseAlertProps,
  'variant' | 'severity'
>;

export const DSL_GlobalInfoAlert = (props: DSL_GlobalInfoAlertProps) => {
  return <DSL_BaseAlert severity="info" {...props} />;
};

DSL_GlobalInfoAlert.displayName = 'DSL_GlobalInfoAlert';

export {
  //
  /** @deprecated use DSL_GlobalInfoAlertProps instead*/
  DSL_GlobalInfoAlertProps as GlobalInfoAlertProps, //
  /** @deprecated use DSL_GlobalInfoAlert instead*/
  DSL_GlobalInfoAlert as GlobalInfoAlert,
};
