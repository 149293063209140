import React from 'react';

import {DSL_BaseInput, DSL_BaseInputProps} from '../BaseInput';

export type DSL_EmailInputProps = Omit<DSL_BaseInputProps, 'type'>;
export const DSL_EmailInput = (props: DSL_BaseInputProps) => {
  return <DSL_BaseInput type={'email'} {...props} />;
};

DSL_EmailInput.displayName = 'DSL_EmailInput';

export {
  //
  /** @deprecated use DSL_EmailInputProps instead*/
  DSL_EmailInputProps as EmailInputProps, //
  /** @deprecated use DSL_EmailInput instead*/
  DSL_EmailInput as EmailInput,
};
