import mime from 'mime';
import * as R from 'ramda';
import {DropEvent, FileRejection, useDropzone} from 'react-dropzone';

import {DSL_SelectFileDropTargetViewProps} from './SelectFileDropTarget.View';

export interface UseSelectFileDropTargetProps
  extends Omit<
    DSL_SelectFileDropTargetViewProps,
    'rootProps' | 'inputProps' | 'isDragActive' | 'extensions'
  > {
  acceptedMimeTypes: string[];
  maxSize?: number;
  disabled?: boolean;
  accept?: string | string[];
  minSize?: number;
  maxFiles?: number;
  onDrop?: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent,
  ) => void;
  onDropAccepted?: <T extends File>(files: T[], event: DropEvent) => void;
  onDropRejected?: (fileRejections: FileRejection[], event: DropEvent) => void;
}

export function useSelectFileDropTarget({
  acceptedMimeTypes,
  maxFiles,
  maxSize,
  disabled,
  onDrop,
  onDropAccepted,
  onDropRejected,
  ...props
}: UseSelectFileDropTargetProps) {
  const accept = R.map(
    mt => R.defaultTo(mt, mime.getType(mt)),
    acceptedMimeTypes,
  );
  const extensions = R.map(
    mt => R.defaultTo(mt, mime.getExtension(mt)),
    acceptedMimeTypes,
  );

  const {getRootProps, getInputProps, isDragActive, isDragReject} = useDropzone(
    {
      accept,
      maxFiles,
      maxSize,
      disabled,
      onDrop,
      onDropRejected,
      onDropAccepted,
      preventDropOnDocument: true,
      noClick: false,
    },
  );

  return {
    ...props,
    maxSize,
    accept,
    extensions,
    isDragActive,
    isDragReject,
    inputProps: getInputProps(),
    rootProps: getRootProps(),
  };
}
