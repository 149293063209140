import React, {ReactNode} from 'react';

import {
  DSL_FlexColumn,
  DSL_InlineErrorAlert,
  DSL_InlineInfoAlert,
  DSL_InlineWarningAlert,
  DSL_TableCell,
  DSL_TableRow,
} from 'components/DesignSystem/Library';

import {DSP_FormTableRow} from './FormTableRow';
import {DSP_FormTableRowRendererProps} from './types';

export interface DSP_FormTableRowWithInlineAlertProps
  extends DSP_FormTableRowRendererProps {
  error?: false | string | ReactNode;
  info?: false | string | ReactNode;
  warning?: false | string | ReactNode;
}

export const DSP_FormTableRowWithInlineAlert = ({
  error,
  info,
  warning,
  columns,
  ...props
}: DSP_FormTableRowWithInlineAlertProps) => {
  const showInlineAlert = error || info || warning;

  return (
    <>
      <DSP_FormTableRow
        border={error ? 'top' : undefined}
        columns={columns}
        {...props}
      />
      {showInlineAlert && (
        <DSL_TableRow>
          <DSL_TableCell
            paddingTop={0}
            borderTop={'none'}
            verticalAlign={'middle'}
            colSpan={columns.length + 1}
          >
            <DSL_FlexColumn>
              {info && <DSL_InlineInfoAlert>{info}</DSL_InlineInfoAlert>}
              {warning && (
                <DSL_InlineWarningAlert>{warning}</DSL_InlineWarningAlert>
              )}
              {error && <DSL_InlineErrorAlert>{error}</DSL_InlineErrorAlert>}
            </DSL_FlexColumn>
          </DSL_TableCell>
        </DSL_TableRow>
      )}
    </>
  );
};

DSP_FormTableRowWithInlineAlert.displayName = 'DSP_FormTableRowWithInlineAlert';
