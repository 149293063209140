import {InMemoryCache} from '@apollo/client';

const inMemoryCache = new InMemoryCache({
  addTypename: true,
  typePolicies: {
    AccessProductDocumentControllerFindOperationsQuery: {
      keyFields: [],
    },
    AccessProductDocumentMediaControllerFindOperationsQuery: {
      keyFields: [],
    },
    // ConditionTemplates are included in the templateConfig response when fetching permit standard conditions.
    // Each conditionTemplate includes an `id` field, however the id is only a sort index and not a unique identifier.
    // As such, 2 different conditions will include a conditionTemplate with id 1.  By default, apollo cache will try
    // to normalise these records and when it sees a second id of 1, will use the value from the cache.
    // So here we have to prevent apollo from normalising ConditionTemplate records, meaning the values are only stored
    // within the context of the parent record, not individually cached.
    // More info: https://www.apollographql.com/docs/react/caching/cache-configuration#disabling-normalization
    ConditionTemplate: {
      keyFields: false,
    },
    VehicleConfigSpec: {
      keyFields: false,
    },
    //@TODO using this causes issues when partial specs downloaded
    // VehicleComponentSpec: {
    //   keyFields: ['vehicleComponentId'],
    // },
  },
});

export default inMemoryCache;
