import React from 'react';

import {DSL_BaseCard, DSL_BaseCardProps} from '../BaseCard';

export type DSL_BorderlessCardProps = Omit<
  DSL_BaseCardProps,
  'variant' | 'elevation' | 'raised'
>;

export const DSL_BorderlessCard = (props: DSL_BorderlessCardProps) => {
  return <DSL_BaseCard variant={'elevation'} elevation={0} {...props} />;
};

DSL_BorderlessCard.displayName = 'DSL_BorderlessCard';

export {
  //
  /** @deprecated use DSL_BorderlessCardProps instead*/
  DSL_BorderlessCardProps as BorderlessCardProps, //
  /** @deprecated use DSL_BorderlessCard instead*/
  DSL_BorderlessCard as BorderlessCard,
};
