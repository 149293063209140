import React, {FC} from 'react';

import {TabStep} from 'components/navigation/types';
import * as R from 'ramda';
import {StepProps} from 'utils/stores/types';

export const DSP_ActiveWizardStep = ({
  tabSteps,
  activeStep,
  onStepChange,
}: {
  tabSteps: TabStep[];
  activeStep: number;
  onStepChange: (label: string) => void;
}) => {
  if (activeStep === -1) {
    return null;
  }
  const DSP_ActiveWizardStepComponent = tabSteps[activeStep]
    .component as FC<StepProps>;

  return R.isNil(DSP_ActiveWizardStepComponent) ? null : (
    <DSP_ActiveWizardStepComponent
      onStepChange={onStepChange}
      label={tabSteps[activeStep].label}
      validateFields={tabSteps[activeStep].validateFields}
    />
  );
};
