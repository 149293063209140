import React from 'react';

import {useBaseCardInput, UseBaseCardInputProps} from '../../BaseCardInput';

import {
  DSL_RadioCardOptionView,
  DSL_RadioCardOptionViewProps,
} from './RadioCardOption.View';

export interface DSL_RadioCardOptionProps
  extends Omit<DSL_RadioCardOptionViewProps, 'onClickCard'>,
    UseBaseCardInputProps {}

export const DSL_RadioCardOption = ({
  onClickCard,
  ...props
}: DSL_RadioCardOptionProps) => {
  const viewProps = useBaseCardInput({onClickCard});

  return <DSL_RadioCardOptionView {...props} {...viewProps} />;
};

DSL_RadioCardOption.displayName = 'DSL_RadioCardOption';
