import {useRef} from 'react';

import {schemaJsonPointerResolver as resolveSchema} from '@regulatory-platform/common-utils/dist';
import {DateTime} from 'luxon';

import {DSL_DatePickerProps} from 'components/DesignSystem/Library';

import {useFormContext} from '../FormProvider';
import {useFormFieldPropsSelector} from '../hooks';
import {DSP_FieldProps} from '../types';
import {
  DSP_GenericFieldProps,
  DSP_GetGenericFieldProps,
  getGenericFieldProps,
} from '../utils/getGenericFieldProps';

export interface DSP_UseDateFieldProps
  extends DSP_FieldProps,
    Omit<DSP_GetGenericFieldProps, 'state'> {}

interface DSP_DateFieldSchema {
  format: 'date' | 'date-time' | 'time' | undefined;
  ['x-date-maximum']?: Date;
  ['x-date-minimum']?: Date;
}

const INVALID_DATE = 'Invalid Date';

type DSP_NonNullDateType = Exclude<DSP_DateFieldSchema['format'], undefined>;

/*
 Some values are hard-coded for legacy compatibility, when introducing a DateTimePicker,
 this hook can be refactored to allow flexibility between display formats and schema type defaults.
*/
export function useDateField({
  fieldRef,
  label,
  hideLabel,
  onBlurEvent,
  onChangeEvent,
  includeOptionalSuffix,
  useLabelForValidationErrors,
  ...propsOverrides
}: DSP_UseDateFieldProps): DSL_DatePickerProps<DateTime> {
  const {service} = useFormContext();
  const send = service.send;

  // we use the internal value to smooth over issues where the date is invalid
  const internalValue = useRef<string | null>(null);

  return useFormFieldPropsSelector(
    fieldRef,
    service,
    state => {
      const {schema} = state.context;
      const fieldSchema =
        resolveSchema<DSP_DateFieldSchema>(fieldRef)(schema) ?? {};
      const dateType = fieldSchema?.format ?? 'date';
      const maximum = fieldSchema?.['x-date-maximum'];
      const minimum = fieldSchema?.['x-date-minimum'];

      const {value: dateString, ...genericFieldProps} = getGenericFieldProps({
        fieldRef,
        state,
        includeOptionalSuffix,
        label,
        hideLabel,
        useLabelForValidationErrors,
      });

      if (dateString !== INVALID_DATE) {
        internalValue.current = dateString as string | null;
      }

      const onBlur = () => {
        send({fieldRef, type: 'BLUR'});
      };

      const onChange = (date: DateTime | null) => {
        send({
          fieldRef,
          type: 'CHANGE',
          value: date ? convertDateToString(date, dateType) : null,
        });
      };

      return {
        ...genericFieldProps,
        onBlur,
        onChange,
        id: genericFieldProps.id,
        name: genericFieldProps.name,
        label: label ?? genericFieldProps.label,
        value: parseDateTimeString(internalValue.current),
        maxDate: maximum ? DateTime.fromJSDate(maximum) : undefined,
        minDate: minimum ? DateTime.fromJSDate(minimum) : undefined,
        ...propsOverrides,
      };
    },
    ['onChange', 'onBlur', 'value'],
    ({value: oldDate}, {value: newDate}) => isDatesEqual(oldDate, newDate),
  );
}

function convertDateToString(
  date: DateTime,
  type: DSP_NonNullDateType,
): string {
  const isValidDate = validateDate(date, type);

  if (!isValidDate) {
    return INVALID_DATE;
  }

  if (type === 'date') {
    return date.toISODate() ?? INVALID_DATE;
  }

  return date.toISO() ?? INVALID_DATE;
}

function validateDate(date: DateTime, type: DSP_NonNullDateType): boolean {
  // At the moment, 'time' formatted strings aren't supported
  if (type !== 'date' && type !== 'date-time') {
    return false;
  }

  const year = date?.year ?? 0;
  const isWithinValidYearRange = year >= 1900 && year <= 2099;

  return isWithinValidYearRange && date.isValid;
}

function parseDateTimeString(
  value: DSP_GenericFieldProps['value'],
): DateTime | null {
  return !value ? null : DateTime.fromISO(value + '');
}

function isDatesEqual(
  first: ReturnType<typeof parseDateTimeString>,
  second: ReturnType<typeof parseDateTimeString>,
): boolean {
  return first?.toISODate() === second?.toISODate();
}
