import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/accessProductDocuments/:accessProductDocumentId/joinRelated/:accessProductId',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "JoinRelatedAccessProductDocument" */ './JoinRelatedAccessProductDocument'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Join pre-approved vehicle network',
  menuIcon: LibraryAddCheckIcon,
  authorisation: {
    policies: [{role: 'partnerSpatialUser'}] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Vehicle networks',
      to: '..',
    },
  ],
};
