import {InputProps} from '@mui/material';
import React from 'react';
import {LegacyFormControlProps} from 'utils/stores/types';
import {LegacyFieldWrapper} from '../LegacyFieldWrapper';

import {useTextField} from './useTextField';
import {
  DSP_TextField,
  DSP_MultiLineTextField,
  DSP_NumberField,
  DSP_PasswordField,
  DSP_EmailField,
} from 'components/DesignSystem/Portal';

type OmitTypesWithPrefix<T extends {}, Prefix extends string> = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [K in keyof T as K extends `${Prefix}${infer Rest}` ? never : K]: T[K];
};

interface TextFieldProps extends LegacyFormControlProps {
  enableAutoComplete?: boolean;
  hideUom?: boolean;
  onCopy?: (e: React.ClipboardEvent) => void;
  onCut?: (e: React.ClipboardEvent) => void;
  onPaste?: (e: React.ClipboardEvent) => void;
  hideHelperText?: boolean;
  showOptionalPlaceholder?: boolean;
  optional?: boolean;
  inputProps?: Omit<OmitTypesWithPrefix<InputProps, 'on'>, 'defaultValue'>;
}

const componentMapping = {
  text: DSP_TextField,
  multiline: DSP_MultiLineTextField,
  string: DSP_TextField,
  number: DSP_NumberField,
  password: DSP_PasswordField,
  email: DSP_EmailField,
} as const;

/**
 * @deprecated use DSP_TextField, MultiLineTextField, NumberField, PasswordField or EmailField instead
 */
const TextField: React.FC<TextFieldProps> = ({
  storeContainer,
  type,
  size = 4,
  multiline,
  useSize,
  addOnElement,
  showOptionalPlaceholder = false,
  enableAutoComplete,
  ...props
}: TextFieldProps) => {
  const {type: defaultType, optional} = useTextField({
    fieldRef: props.fieldRef,
    multiline,
  });

  const fieldType = type || (defaultType as typeof type) || 'text';

  const inputComponent = componentMapping[fieldType] ?? DSP_TextField;

  // @TODO: decide if this functionality should be incorporated into the design system.
  //   Currently this only used in TableField so we could hoist this functionality to there.
  if (!props.disabled && showOptionalPlaceholder && optional) {
    props.placeholder = 'Optional';
  }

  // Bit of a hack.
  // By default, we want to disable autocomplete on input fieldsP_ There's a select few that we enable on the user profile page for instance.
  // Most auto complete implementations are designed to not autocomplete a 'new-password' field
  // TODO: decide if this functionality should be pushed down into the design system
  if (enableAutoComplete === false) {
    props.inputProps ??= {};
    props.inputProps.autoComplete = 'new-password';
  }

  return (
    <LegacyFieldWrapper
      size={size}
      multiline={multiline}
      addOnElement={addOnElement}
      inputComponent={inputComponent}
      {...props}
    />
  );
};

export default TextField;
