import {lazy} from 'react';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/specifications',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "VehicleSpecifications" */ './VehicleSpecifications'
      ),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'Vehicle specifications',
  menuIcon: LocalShippingOutlinedIcon,
  authorisation: {
    apiKey: 'VehicleRegistrationExtendedWithRelations.findById',
    policies: [
      {
        role: 'operationsRegistrationEnabled',
      },
      {
        role: 'providerRegistrationEnabled',
        accountSubType: 'Manufacturer',
      },
      {
        role: 'providerPBSRead',
        accountSubType: 'PBS Certifier',
      },
    ] as AuthorisationPolicy[],
  },
  sections: [],
};
