import {DSP_PageContainer} from 'components/DesignSystem/Portal';
import React from 'react';

type LoadingProps = {
  title?: string;
  unauthenticated?: boolean;
  variant?: 'h1' | 'h3';
};

/**
 * Loading page
 */
const Loading: React.FC<LoadingProps> = (props: LoadingProps) => {
  return <DSP_PageContainer loading {...props} />;
};

export default Loading;
