import React from 'react';

import {Toolbar, ToolbarProps} from '@mui/material';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

export interface DSL_SidebarMenuToolbarProps
  extends Omit<ToolbarProps, DSL_RestrictedVisualProps> {
  imageSrc?: string;
  imageDescription?: string;
}

export const DSL_SidebarMenuToolbar = ({
  imageSrc,
  imageDescription = '',
  ...toolbarProps
}: DSL_SidebarMenuToolbarProps) => {
  return (
    <Toolbar {...toolbarProps}>
      {imageSrc && (
        <img
          alt={imageDescription}
          src={imageSrc}
          width={'100%'}
          height={'auto'}
        />
      )}
    </Toolbar>
  );
};

DSL_SidebarMenuToolbar.displayName = 'DSL_SidebarMenuToolbar';

export {
  //
  /** @deprecated use DSL_SidebarMenuToolbarProps instead*/
  DSL_SidebarMenuToolbarProps as SidebarMenuToolbarProps, //
  /** @deprecated use DSL_SidebarMenuToolbar instead*/
  DSL_SidebarMenuToolbar as SidebarMenuToolbar,
};
