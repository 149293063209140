import React, {useState} from 'react';

import {Visibility} from '@mui/icons-material';
import {VisibilityOff} from '@mui/icons-material';
import {IconButton, InputAdornment, Tooltip} from '@mui/material';

import {DSL_BaseInput, DSL_BaseInputProps} from '../BaseInput';

export interface DSL_PasswordInputProps extends DSL_BaseInputProps {
  enableAutoComplete?: boolean;
}

export const DSL_PasswordInput = ({
  enableAutoComplete = false,
  InputProps = {},
  ...props
}: DSL_PasswordInputProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  InputProps.endAdornment = (
    <InputAdornment position="end">
      <Tooltip title={'Toggle password visibility'}>
        <IconButton
          edge="end"
          size="small"
          aria-label="toggle password visibility"
          onClick={(): void => {
            setShowPassword(!showPassword);
          }}
          onMouseDown={(event: React.MouseEvent<HTMLButtonElement>): void => {
            event.preventDefault();
          }}
        >
          {showPassword ? (
            <Visibility fontSize="small" />
          ) : (
            <VisibilityOff fontSize="small" />
          )}
        </IconButton>
      </Tooltip>
    </InputAdornment>
  );

  // TODO: check how we want to support this
  const inputProps = {
    autoComplete: enableAutoComplete ? 'new-password' : null,
  };

  return (
    <DSL_BaseInput
      {...props}
      type={showPassword ? 'text' : 'password'}
      InputProps={InputProps}
      inputProps={inputProps}
    />
  );
};

DSL_PasswordInput.displayName = 'DSL_PasswordInput';

export {
  //
  /** @deprecated use DSL_PasswordInputProps instead*/
  DSL_PasswordInputProps as PasswordInputProps, //
  /** @deprecated use DSL_PasswordInput instead*/
  DSL_PasswordInput as PasswordInput,
};
