import React, {ReactNode} from 'react';

import {
  DSL_Subtitle1,
  DSL_Body2,
  DSL_Box,
  DSL_HighlightedText,
} from 'components/DesignSystem/Library';

import {DSP_BaseVehicleCard} from '../BaseVehicleCard/BaseVehicleCard';

export type DSP_VehicleCategoryCardProps = {
  title: string;
  highlightText?: string;
  subheader: ReactNode;
  vehicleComponentIds: number[];
  onClick: () => void;
  imageUrl?: string | null;
};

export const DSP_VehicleCategoryCard = ({
  title,
  highlightText,
  subheader,
  vehicleComponentIds,
  onClick,
  imageUrl,
}: DSP_VehicleCategoryCardProps) => {
  return (
    <DSP_BaseVehicleCard
      vehicleComponentIds={vehicleComponentIds}
      imageUrl={imageUrl}
      onClick={onClick}
    >
      <DSL_Box maxWidth={'100%'}>
        <DSL_Subtitle1 color={'text.primary'} bold>
          <DSL_HighlightedText value={title} highlightText={highlightText} />
        </DSL_Subtitle1>
        <DSL_Body2 color={'text.secondary'}>{subheader}</DSL_Body2>
      </DSL_Box>
    </DSP_BaseVehicleCard>
  );
};

DSP_VehicleCategoryCard.displayName = 'DSP_VehicleCategoryCard';

export {
  //
  /** @deprecated use DSP_VehicleCategoryCardProps instead*/
  DSP_VehicleCategoryCardProps as VehicleCategoryCardProps, //
  /** @deprecated use DSP_VehicleCategoryCard instead*/
  DSP_VehicleCategoryCard as VehicleCategoryCard,
};
