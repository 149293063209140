import React from 'react';

import {
  CheckboxInput,
  CheckboxInputProps,
} from 'components/DesignSystem/Library';

import useBooleanFieldProps from '../hooks/useBooleanFieldProps';
import {DSP_FieldProps} from '../types';

export type DSP_CheckboxFieldProps = CheckboxInputProps & DSP_FieldProps;
export const DSP_CheckboxField = (props: DSP_CheckboxFieldProps) => {
  const fieldProps = useBooleanFieldProps(props);

  return <CheckboxInput {...fieldProps} />;
};

export {
  //
  /** @deprecated use DSP_CheckboxFieldProps instead*/
  DSP_CheckboxFieldProps as CheckboxFieldProps, //
  /** @deprecated use DSP_CheckboxField instead*/
  DSP_CheckboxField as CheckboxField,
};
