import {useFormContext} from 'components/DesignSystem/Portal/Forms/FormProvider';

export const useFormSaveConfirmationDialog = (
  onClose: () => void,
  isCreate = false,
) => {
  const {service} = useFormContext();
  return {
    onConfirm: () => {
      if (isCreate) {
        service.send('CREATE');
      } else {
        service.send('SUBMIT');
      }
      onClose();
    },
  };
};
