import {InputProps} from '@mui/material';
import {
  FormattingConfig,
  schemaJsonPointerResolver,
} from '@regulatory-platform/common-utils';
import * as R from 'ramda';
import {ChangeEvent} from 'react';
import getFieldProps from 'utils/stores/getFieldProps';
import {FieldProps, FormStoreContainer} from 'utils/stores/types';

/** @deprecated use TextField instead */
export interface TextFieldProps extends FieldProps {
  placeholder?: string;
  onChange: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    numericValue?: number,
  ) => void;
  onBlur: () => void;
  rows?: number;
  multiline?: boolean;
  type?: string;
  step?: number;
  InputProps?: InputProps;
  key?: string;
  autoComplete?: 'off';
  optional?: boolean;
}

export default function getTextFieldProps(
  storeContainer: FormStoreContainer,
  fieldRef: string,
  placeholder?: string,
  multiline?: boolean,
): TextFieldProps {
  const {state, service} = storeContainer;
  const send = service.send;
  const {schema} = state.context;
  const fieldSchema = schemaJsonPointerResolver(fieldRef)(schema);
  const fieldFormatting = fieldSchema['x-formatting'] as
    | FormattingConfig
    | undefined;

  const fieldProps = getFieldProps(storeContainer, fieldRef);

  const type =
    fieldSchema.type === 'number'
      ? 'number'
      : multiline
      ? 'multiline'
      : undefined;

  return R.mergeRight(fieldProps, {
    onChange: (
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      numericValue?: number | string,
    ): void => {
      let value: string | number = !R.isNil(numericValue)
        ? numericValue
        : e.currentTarget.value;
      if (
        !R.isNil(numericValue) &&
        !R.isNil(fieldFormatting) &&
        !R.isNil(fieldFormatting.uomConversion)
      ) {
        value = Number(numericValue) / fieldFormatting.uomConversion;
      }
      send({type: 'CHANGE', fieldRef, value});
    },
    onBlur: (): void => {
      send({type: 'BLUR', fieldRef});
    },
    placeholder,
    type: type,
    step: fieldFormatting ? fieldFormatting.step : undefined,
    value:
      fieldFormatting &&
      fieldFormatting.uomConversion &&
      !R.isNil(fieldProps.value)
        ? (fieldProps.value as number) * fieldFormatting.uomConversion
        : fieldProps.value,
    key: '',
    optional: fieldSchema['x-required'] !== true,
  });
}
