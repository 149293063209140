import {usePopover} from 'components/DesignSystem/Library';

import {usePartnerAccountEntityLoaderContext} from '../PartnerAccountEntityLoaderProvider';
import {usePartnerAccountEntityMapper} from '../usePartnerAccountEntityMapper';

import {DSP_PartnerAccountEntityAvatarWithNameViewProps} from './PartnerAccountEntityAvatarWithName.View';

export function usePartnerAccountEntityAvatarWithName(): DSP_PartnerAccountEntityAvatarWithNameViewProps {
  const popoverProps = usePopover<HTMLDivElement>();
  const {entity: partnerAccount, isLoading} =
    usePartnerAccountEntityLoaderContext();
  const partnerAccountProps = usePartnerAccountEntityMapper({partnerAccount});

  return {
    ...popoverProps,
    ...partnerAccountProps,
    isLoading,
  };
}
