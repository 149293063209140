import {usePopper} from 'components/DesignSystem/Library';

import {DSP_HelpMenuViewProps} from './HelpMenu.View';

export const useHelpMenu = (): DSP_HelpMenuViewProps => {
  const {popperProps, openerProps} = usePopper();

  const onListItemClick = (href: string) => {
    popperProps.closePopper();
    if (href) {
      window?.open(href, '_blank')?.focus();
      return;
    }
  };

  return {
    onListItemClick,
    popperProps,
    openerProps,
  };
};
