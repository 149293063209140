import React from 'react';

import {DSP_RegulatorAccountEntityAvatarWithNameView} from './RegulatorAccountEntityAvatarWithName.View';
import {useRegulatorAccountEntityAvatarWithName} from './useRegulatorAccountEntityAvatarWithName';

export const DSP_RegulatorAccountEntityAvatarWithName = () => {
  const viewProps = useRegulatorAccountEntityAvatarWithName();

  return <DSP_RegulatorAccountEntityAvatarWithNameView {...viewProps} />;
};

DSP_RegulatorAccountEntityAvatarWithName.displayName =
  'DSP_RegulatorAccountEntityAvatarWithName';
