import {FaceOutlined} from '@mui/icons-material';
import NewAssetDetailAratTestVehicleGateway from './Applications/Application/NewApplication/NewAssetDetailAratTestVehicleGateway';
import ManageAssetDetailAratTestVehicle from './Applications/Application/NewApplication/ManageAssetDetailAratTestVehicles/ManageAssetDetailAratTestVehicle';
import ManageAssetDetailAratTestVehicles from './Applications/Application/NewApplication/ManageAssetDetailAratTestVehicles';

export default {
  routes: [
    NewAssetDetailAratTestVehicleGateway,
    ManageAssetDetailAratTestVehicle,
    ManageAssetDetailAratTestVehicles,
  ],
  menuTitle: 'Permits',
  menuDescription: 'Access Permits',
  path: '/permits',
  showChildRoutesInMenu: false,
  menuIcon: FaceOutlined,
};
