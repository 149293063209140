import React from 'react';

import {DSL_MyLocation} from 'components/DesignSystem/Library/Icons';

import {
  DSL_BaseCardIconButton,
  DSL_BaseCardIconButtonProps,
} from '../BaseCardIconButton';

type DSL_GeolocationCardIconButtonProps = Omit<
  DSL_BaseCardIconButtonProps,
  'children'
>;

export const DSL_GeolocationCardIconButton = (
  props: DSL_GeolocationCardIconButtonProps,
) => {
  return (
    <DSL_BaseCardIconButton {...props}>
      <DSL_MyLocation color={props.disabled ? 'disabled' : 'action'} />
    </DSL_BaseCardIconButton>
  );
};

DSL_GeolocationCardIconButton.displayName = 'DSL_GeolocationCardIconButton';
