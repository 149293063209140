import React from 'react';

import {DSP_TimeDistanceRenderer} from 'components/DesignSystem/Portal/ValueRenderers/TimeDistanceRenderer/TimeDistanceRenderer';

import {
  DSL_Avatar,
  DSL_Badge,
  DSL_Body1,
  DSL_Caption,
  DSL_FlexColumn,
  DSL_PopperListItemButton,
  DSL_FlexRow,
} from 'components/DesignSystem/Library';

export interface DSP_RoadManagementNotificationCardViewProps {
  created: Date | null | undefined;
  readByLoggedInUser: boolean;
  roadManagerName: string | undefined;
  roadName: string | undefined;
  suburb: string | undefined;
  message: string[] | undefined;
  onClick: () => void;
}

export const DSP_RoadManagementNotificationCardViewCard = ({
  created,
  readByLoggedInUser,
  roadManagerName,
  suburb,
  roadName,
  message,
  onClick,
}: DSP_RoadManagementNotificationCardViewProps) => {
  return (
    <DSL_PopperListItemButton onClick={onClick}>
      <DSL_FlexRow alignItems={'center'}>
        <DSL_Badge
          variant={readByLoggedInUser ? 'standard' : 'dot'}
          aria-label={readByLoggedInUser ? 'Message' : 'Unread message'}
        >
          <DSL_Avatar>{roadManagerName?.[0] ?? ''}</DSL_Avatar>
        </DSL_Badge>
        <DSL_FlexColumn flex={1}>
          <DSL_Body1>
            <strong>{roadManagerName} </strong>
            {message?.[0]}
            <strong>
              {' '}
              {roadName ? roadName : 'Unnamed'}, {suburb}.{' '}
            </strong>
            {message?.[1] || ''}
          </DSL_Body1>
          <DSL_Caption color="text.secondary">
            {created && <DSP_TimeDistanceRenderer date={created} />}
          </DSL_Caption>
        </DSL_FlexColumn>
      </DSL_FlexRow>
    </DSL_PopperListItemButton>
  );
};

DSP_RoadManagementNotificationCardViewCard.displayName =
  'DSP_RoadManagementNotificationCardViewCard';

export {
  //
  /** @deprecated use DSP_RoadManagementNotificationCardViewProps instead*/
  DSP_RoadManagementNotificationCardViewProps as RoadManagementNotificationCardViewProps, //
  /** @deprecated use DSP_RoadManagementNotificationCardViewCard instead*/
  DSP_RoadManagementNotificationCardViewCard as RoadManagementNotificationCardViewCard,
};
