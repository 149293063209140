import React from 'react';

import {useTheme} from '@mui/material';
import tokens from 'app/theming/themes/generated/lightTheme.json';

import {DSL_Box} from '../Box';

/**
 * Vertical Spacer
 */

export interface DSL_VerticalSpacerProps {
  size?: keyof (typeof tokens)['sizes'];
}

export const DSL_VerticalSpacer: React.FC<DSL_VerticalSpacerProps> = ({
  size = 'XS',
}) => {
  const theme = useTheme();
  return (
    <DSL_Box
      data-name={'vertical-spacer'}
      sx={{
        height: theme.sizes[size],
      }}
    />
  );
};

export {
  //
  /** @deprecated use DSL_VerticalSpacerProps instead*/
  DSL_VerticalSpacerProps as VerticalSpacerProps, //
  /** @deprecated use DSL_VerticalSpacer instead*/
  DSL_VerticalSpacer as VerticalSpacer,
};
