import React, {useContext} from 'react';
import {FormContextProps} from 'utils/stores/types';

const FormContext = React.createContext({} as FormContextProps);

export function useFormContext() {
  return useContext(FormContext);
}

export function useFormService() {
  const {service} = useFormContext();
  return service;
}

export default FormContext;
