import React from 'react';

import {
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
} from '@mui/material';

import {DSL_RestrictedVisualProps} from '../../../types';

export type DSL_SelectProps<T> = Omit<
  MuiSelectProps<T>,
  DSL_RestrictedVisualProps | 'variant'
>;

export const DSL_Select = function <T = string>(props: DSL_SelectProps<T>) {
  return <MuiSelect<T> variant="outlined" fullWidth {...props} />;
};

DSL_Select.displayName = 'DSL_Select';

export {
  //
  /** @deprecated use DSL_SelectProps instead*/
  DSL_SelectProps as SelectProps, //
  /** @deprecated use DSL_Select instead*/
  DSL_Select as Select,
};
