import React, {Fragment, useState} from 'react';

import {Theme, useTheme} from '@mui/material';

import {
  DSL_CardActionAreaExclusion,
  DSL_Divider,
  DSL_Fade,
  DSL_FlexColumn,
  DSL_FlexRow,
  DSL_RemoveCircleIconButton,
  DSL_Subtitle1,
  DSL_Subtitle2,
  DSL_Tooltip,
} from 'components/DesignSystem/Library';

import {DSP_SelectedSegmentsSegmentCard} from '../SelectedSegmentsSegmentCard';

import {useDspSelectedSegmentsSingleList} from './useSelectedSegmentsSingleList';

export interface DSP_SelectedSegmentsSingleListProps {
  onRemoveSegment?: (segmentId: number) => void;
  onRemoveSegments?: (segmentIds: number[]) => void;
  showChangeOptions?: boolean;
}

export const DSP_SelectedSegmentsSingleList = ({
  onRemoveSegment,
  onRemoveSegments,
  showChangeOptions,
}: DSP_SelectedSegmentsSingleListProps) => {
  const {sortedRoads, activeSegmentId, ...props} =
    useDspSelectedSegmentsSingleList();
  const theme: Theme = useTheme();
  const [isHovering, setIsHovering] = useState<number | false>(false);

  return (
    <DSL_FlexColumn gap={0} data-name={'selected-segments-single-list'}>
      {sortedRoads.map((road, i) =>
        road.segments.length > 0 ? (
          <Fragment key={i}>
            <DSL_FlexRow
              position={'sticky'}
              zIndex={theme.zIndex.sidebarMenu}
              bgcolor={'background.paper'}
              top={0}
              internal_sx={{
                borderTop: `1px solid ${theme.palette.divider}`,
                borderBottom: `1px solid ${theme.palette.divider}`,
              }}
              width={'100%'}
            >
              <DSL_FlexRow
                p={2}
                alignItems={'center'}
                bgcolor={theme.palette.action.hover}
                width={'100%'}
                position={'relative'}
                onMouseOver={() => setIsHovering(i)}
                onMouseLeave={() => setIsHovering(false)}
              >
                <DSL_FlexRow flexWrap={'nowrap'} alignItems={'center'} gap={1}>
                  {onRemoveSegments && (
                    <DSL_Fade in={isHovering === i}>
                      <DSL_Tooltip title={'Remove segments from the selection'}>
                        <DSL_FlexRow>
                          <DSL_CardActionAreaExclusion>
                            <DSL_RemoveCircleIconButton
                              onClick={() =>
                                onRemoveSegments(
                                  road.segments.map(segment => segment.id),
                                )
                              }
                            />
                          </DSL_CardActionAreaExclusion>
                        </DSL_FlexRow>
                      </DSL_Tooltip>
                    </DSL_Fade>
                  )}
                  <DSL_Subtitle1 color={'text.primary'} bold>
                    {road.name}
                  </DSL_Subtitle1>
                  <DSL_Subtitle2 color={'text.secondary'}>
                    {road.suburb}
                  </DSL_Subtitle2>
                </DSL_FlexRow>
              </DSL_FlexRow>
            </DSL_FlexRow>
            <>
              {road.segments.map((segment, index) => (
                <Fragment key={segment.id}>
                  <DSP_SelectedSegmentsSegmentCard
                    showChangeOptions={showChangeOptions}
                    roadName={road.name}
                    segment={segment}
                    active={activeSegmentId === segment.id}
                    onRemoveSegment={onRemoveSegment}
                    {...props}
                  />
                  {index !== road.segments.length - 1 && <DSL_Divider />}
                </Fragment>
              ))}
            </>
          </Fragment>
        ) : null,
      )}
    </DSL_FlexColumn>
  );
};

DSP_SelectedSegmentsSingleList.displayName = 'DSP_SelectedSegmentsSingleList';
