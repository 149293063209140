import React from 'react';

import {AccordionDetails, AccordionDetailsProps} from '@mui/material';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

export type DSL_BaseAccordionDetailsProps = Omit<
  AccordionDetailsProps,
  DSL_RestrictedVisualProps
>;

export const DSL_BaseAccordionDetails = (
  props: DSL_BaseAccordionDetailsProps,
) => {
  return <AccordionDetails {...props} />;
};

DSL_BaseAccordionDetails.displayName = 'DSL_BaseAccordionDetails';

export {
  //
  /** @deprecated use DSL_BaseAccordionDetailsProps instead*/
  DSL_BaseAccordionDetailsProps as BaseAccordionDetailsProps, //
  /** @deprecated use DSL_BaseAccordionDetails instead*/
  DSL_BaseAccordionDetails as BaseAccordionDetails,
};
