import {useState, useCallback, MouseEventHandler} from 'react';

import {
  FilesConfig,
  RecordWithFileList,
  files,
} from '@regulatory-platform/common-utils';
import {useSnackbarService} from 'app/services/AppServicesContext';
import {SasToken} from 'typeDefs/types';
import {onGenerateSASTokenFunc} from 'utils/stores/types';

import {useDialog} from 'components/DesignSystem/Library';

import {useFormService} from '../../FormProvider';
import {DSP_FileListWithIndex, FileListFile} from '../Attachments.types';
import useStorage from '../useStorage';
import {
  getFileListWithIndexByPrefix,
  getVersionedFileIfRequired,
} from '../utils';

const {getAccountId, getAccountType, getRecordId} = files;

export interface DSP_DialogState {
  file: DSP_FileListWithIndex | null;
  originalFile: DSP_FileListWithIndex | null;
}

type DSP_Setter<T extends Object> = <DSP_K extends keyof T>(
  key: DSP_K,
  value: T[DSP_K],
) => void;

export type DSP_UseEditFileDialogResults = {
  editDialogOpen: boolean;
  editDialogState: DSP_DialogState;
  handleEditDialogOpen: (file: DSP_FileListWithIndex) => void;
  handleEditDialogClose: () => void;
  setEditDialogField: DSP_Setter<DSP_FileListWithIndex>;
  onSubmit: MouseEventHandler<HTMLButtonElement>;
};

interface DSP_UseEditFileDialog {
  attachmentConfig: FilesConfig;
  onGenerateSASToken: onGenerateSASTokenFunc;
  activeRecord: RecordWithFileList;
  fileList: FileListFile[];
  fieldRef: string;
  fileGroupId: string | undefined;
}

export function useEditFileDialog({
  fileList,
  fieldRef,
  fileGroupId,
  activeRecord,
  attachmentConfig,
  onGenerateSASToken,
}: DSP_UseEditFileDialog): DSP_UseEditFileDialogResults {
  const snackbarService = useSnackbarService();
  const [dialogOpen, openDialog, closeDialog] = useDialog();

  const [{copyBlobToDestination, deleteBlobItem}] = useStorage();
  const {send} = useFormService();

  const [editDialogState, setDialogState] = useState<DSP_DialogState>({
    originalFile: null,
    file: null,
  });

  const handleEditDialogOpen = useCallback(
    (file: DSP_FileListWithIndex): void => {
      setDialogState(prevState => ({
        ...prevState,
        originalFile: file,
        file,
      }));
      openDialog();
    },
    [],
  );

  const handleEditDialogClose = useCallback((): void => {
    setDialogState(prevState => ({
      ...prevState,
      file: null,
    }));
    closeDialog();
  }, []);

  const setEditDialogField = useCallback(
    <DSP_K extends keyof DSP_FileListWithIndex>(
      key: DSP_K,
      value: DSP_FileListWithIndex[DSP_K],
    ) => {
      setDialogState(prevState => {
        return {
          ...prevState,
          file: {...(prevState.file || {}), [key]: value},
        } as DSP_DialogState;
      });
    },
    [],
  );

  const onSubmit = useCallback(async () => {
    if (editDialogState.file) {
      let showSnackBar = false;
      const updatedFile = editDialogState.file;
      const originalFile = editDialogState.originalFile;
      const moveToPrefix = editDialogState.file?.prefix as string;
      if (
        originalFile?.prefix &&
        originalFile?.prefix !== updatedFile?.prefix
      ) {
        const fromFileSASToken = (await onGenerateSASToken({
          blobName: originalFile.name,
          prefix: originalFile.prefix,
          accountType: getAccountType(originalFile.prefix, attachmentConfig),
          accountId: getAccountId(
            originalFile.prefix,
            attachmentConfig,
            activeRecord,
          ),
          recordId: getRecordId(
            originalFile.prefix,
            attachmentConfig,
            activeRecord,
          ),
          action: 'copy',
        })) as SasToken;
        const moveToFileListByPrefix = getFileListWithIndexByPrefix(
          moveToPrefix,
          fileGroupId,
          fileList,
        );
        const validFileDetails = getVersionedFileIfRequired(
          updatedFile.name,
          moveToFileListByPrefix,
        );
        const validFileName = validFileDetails?.name;
        const toFileSASToken = (await onGenerateSASToken({
          blobName: validFileName,
          prefix: moveToPrefix,
          accountType: getAccountType(moveToPrefix, attachmentConfig),
          accountId: getAccountId(moveToPrefix, attachmentConfig, activeRecord),
          recordId: getRecordId(moveToPrefix, attachmentConfig, activeRecord),
          action: 'copy',
        })) as SasToken;
        const moveFile = await copyBlobToDestination(
          fromFileSASToken,
          toFileSASToken,
        );
        if (moveFile?.copyStatus === 'success') {
          await deleteBlobItem(fromFileSASToken).then(() => {
            updatedFile.name = validFileName;
            updatedFile.prefix = moveToPrefix;
            updatedFile.version = validFileDetails?.version;
            showSnackBar = true;
          });
        }
      }
      send({
        type: 'CHANGE',
        fieldRef: fieldRef + '/' + updatedFile._idx,
        value: updatedFile,
      });
      if (showSnackBar) {
        snackbarService.send('TRIGGER', {
          message: `File successfully moved to '${attachmentConfig.prefixes[moveToPrefix].name}' folder`,
          variant: 'success',
        });
      }
      handleEditDialogClose();
    }
  }, [editDialogState, onGenerateSASToken]);

  return {
    editDialogOpen: dialogOpen,
    editDialogState,
    handleEditDialogOpen,
    handleEditDialogClose,
    setEditDialogField,
    onSubmit,
  };
}
