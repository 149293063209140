import React from 'react';

import {AuthorisationAccountTypes} from '@regulatory-platform/common-utils/dist';

import {accountTypeEntityComponentMap} from '../constants';

export interface DSP_AccountEntityAvatarWithNameProps {
  entityId: number | undefined;
  accountType: AuthorisationAccountTypes | undefined;
}

export const DSP_AccountEntityAvatarWithName = ({
  entityId,
  accountType,
}: DSP_AccountEntityAvatarWithNameProps) => {
  if (!entityId || !accountType) {
    return null;
  }

  const Components = accountTypeEntityComponentMap[accountType];

  if (!Components) {
    return null;
  }

  return (
    <Components.LoaderProvider entityId={entityId}>
      <Components.AvatarWithName />
    </Components.LoaderProvider>
  );
};

DSP_AccountEntityAvatarWithName.displayName = 'DSP_AccountEntityAvatarWithName';
