import * as Types from '../../../../typeDefs/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NetworkSegmentManagementControllerCalculateLengthByIdsMutationVariables = Types.Exact<{
  findByCompressedSegmentIdsRequestInput: Types.FindByCompressedSegmentIdsRequestInput;
}>;


export type NetworkSegmentManagementControllerCalculateLengthByIdsMutation = { __typename?: 'Mutation', networkSegmentManagementControllerCalculateLengthByIds?: { __typename?: 'NetworkSegmentManagementSegmentLengthsCalculationResponse', totalLength: number } | null };


export const NetworkSegmentManagementControllerCalculateLengthByIdsDocument = gql`
    mutation networkSegmentManagementControllerCalculateLengthByIds($findByCompressedSegmentIdsRequestInput: FindByCompressedSegmentIdsRequestInput!) {
  networkSegmentManagementControllerCalculateLengthByIds(
    findByCompressedSegmentIdsRequestInput: $findByCompressedSegmentIdsRequestInput
  ) {
    totalLength
  }
}
    `;
export type NetworkSegmentManagementControllerCalculateLengthByIdsMutationFn = Apollo.MutationFunction<NetworkSegmentManagementControllerCalculateLengthByIdsMutation, NetworkSegmentManagementControllerCalculateLengthByIdsMutationVariables>;

/**
 * __useNetworkSegmentManagementControllerCalculateLengthByIdsMutation__
 *
 * To run a mutation, you first call `useNetworkSegmentManagementControllerCalculateLengthByIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNetworkSegmentManagementControllerCalculateLengthByIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [networkSegmentManagementControllerCalculateLengthByIdsMutation, { data, loading, error }] = useNetworkSegmentManagementControllerCalculateLengthByIdsMutation({
 *   variables: {
 *      findByCompressedSegmentIdsRequestInput: // value for 'findByCompressedSegmentIdsRequestInput'
 *   },
 * });
 */
export function useNetworkSegmentManagementControllerCalculateLengthByIdsMutation(baseOptions?: Apollo.MutationHookOptions<NetworkSegmentManagementControllerCalculateLengthByIdsMutation, NetworkSegmentManagementControllerCalculateLengthByIdsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NetworkSegmentManagementControllerCalculateLengthByIdsMutation, NetworkSegmentManagementControllerCalculateLengthByIdsMutationVariables>(NetworkSegmentManagementControllerCalculateLengthByIdsDocument, options);
      }
export type NetworkSegmentManagementControllerCalculateLengthByIdsMutationHookResult = ReturnType<typeof useNetworkSegmentManagementControllerCalculateLengthByIdsMutation>;
export type NetworkSegmentManagementControllerCalculateLengthByIdsMutationResult = Apollo.MutationResult<NetworkSegmentManagementControllerCalculateLengthByIdsMutation>;
export type NetworkSegmentManagementControllerCalculateLengthByIdsMutationOptions = Apollo.BaseMutationOptions<NetworkSegmentManagementControllerCalculateLengthByIdsMutation, NetworkSegmentManagementControllerCalculateLengthByIdsMutationVariables>;