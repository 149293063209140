import React, {createContext, useMemo, ReactNode} from 'react';

import type {
  DraggableSyntheticListeners,
  UniqueIdentifier,
} from '@dnd-kit/core';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

import {DSL_BaseListItem} from './BaseListItem';

interface DSL_SortableListItemProps {
  id: UniqueIdentifier;
  children: ReactNode;
}

interface DSL_SortableListItemContext {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attributes: Record<string, any>;
  listeners: DraggableSyntheticListeners;
  ref(node: HTMLElement | null): void;
}

export const DSL_SortableListItemContext =
  createContext<DSL_SortableListItemContext>({
    attributes: {},
    listeners: undefined,
    ref() {},
  });

export const DSL_SortableListItem = ({
  children,
  id,
}: DSL_SortableListItemProps) => {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({id});

  const context = useMemo(
    () => ({
      attributes,
      listeners,
      ref: setActivatorNodeRef,
    }),
    [attributes, listeners, setActivatorNodeRef],
  );

  return (
    <DSL_SortableListItemContext.Provider value={context}>
      <DSL_BaseListItem
        data-name={`sortable-item-${id}`}
        ref={setNodeRef}
        internal_sx={{
          opacity: isDragging ? 0.4 : undefined,
          transform: CSS.Translate.toString(transform),
          transition,
          listStyleType: 'none',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        {children}
      </DSL_BaseListItem>
    </DSL_SortableListItemContext.Provider>
  );
};
