import * as Types from '../../../typeDefs/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserModelControllerLoginMutationVariables = Types.Exact<{
  loginInput: Types.LoginInput;
}>;


export type UserModelControllerLoginMutation = { __typename?: 'Mutation', userModelControllerLogin?: { __typename?: 'JWTToken', refreshToken?: string | null, token?: string | null } | null };


export const UserModelControllerLoginDocument = gql`
    mutation userModelControllerLogin($loginInput: LoginInput!) {
  userModelControllerLogin(loginInput: $loginInput) {
    refreshToken
    token
  }
}
    `;
export type UserModelControllerLoginMutationFn = Apollo.MutationFunction<UserModelControllerLoginMutation, UserModelControllerLoginMutationVariables>;

/**
 * __useUserModelControllerLoginMutation__
 *
 * To run a mutation, you first call `useUserModelControllerLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserModelControllerLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userModelControllerLoginMutation, { data, loading, error }] = useUserModelControllerLoginMutation({
 *   variables: {
 *      loginInput: // value for 'loginInput'
 *   },
 * });
 */
export function useUserModelControllerLoginMutation(baseOptions?: Apollo.MutationHookOptions<UserModelControllerLoginMutation, UserModelControllerLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserModelControllerLoginMutation, UserModelControllerLoginMutationVariables>(UserModelControllerLoginDocument, options);
      }
export type UserModelControllerLoginMutationHookResult = ReturnType<typeof useUserModelControllerLoginMutation>;
export type UserModelControllerLoginMutationResult = Apollo.MutationResult<UserModelControllerLoginMutation>;
export type UserModelControllerLoginMutationOptions = Apollo.BaseMutationOptions<UserModelControllerLoginMutation, UserModelControllerLoginMutationVariables>;