import {useSelector} from '@xstate/react';
import {useFormContext} from 'components/DesignSystem/Portal/Forms/FormProvider';
import * as R from 'ramda';
import {useNavigate} from 'react-router-dom';
import getStateMetaHead from 'utils/machines/utils/getStateMetaHead';

import {DSP_CreateOrCancelButtonStackViewProps} from './CreateOrCancelButtonStack.View';

export const useCreateOrCancelButtonStack =
  (): DSP_CreateOrCancelButtonStackViewProps => {
    const {service} = useFormContext();
    const navigate = useNavigate();

    return useSelector(
      service,
      state => {
        const metaHead = getStateMetaHead(state);
        const navigateTo = metaHead.navigateTo;
        const navigateState = metaHead.navigateState;

        return {
          onCreateClick: event => {
            event.preventDefault();
            service?.send('CREATE');
          },
          onCancelClick: () => {
            service?.send('CANCEL');
            if (!R.isNil(navigateTo) && navigateTo !== '') {
              navigate(
                navigateTo,
                navigateState ? {state: navigateState} : undefined,
              );
            }
          },
        };
      },
      (prevFieldProps, nextFieldProps) => {
        const ignoreProps = R.omit(['onCreateClick', 'onCancelClick']);
        return R.equals(
          ignoreProps(prevFieldProps),
          ignoreProps(nextFieldProps),
        );
      },
    );
  };
