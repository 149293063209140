import React from 'react';

import {DSL_BaseEmptyState, DSL_BaseEmptyStateProps} from '../BaseEmptyState';

import {DSL_NoResultsIcon} from './NoResultsIcon';

export type DSL_NoResultsEmptyStateProps = Omit<
  DSL_BaseEmptyStateProps,
  'icon'
>;

export const DSL_NoResultsEmptyState = (
  props: DSL_NoResultsEmptyStateProps,
) => {
  return <DSL_BaseEmptyState icon={<DSL_NoResultsIcon />} {...props} />;
};

DSL_NoResultsEmptyState.displayName = 'DSL_NoResultsEmptyState';

export {
  //
  /** @deprecated use DSL_NoResultsEmptyStateProps instead*/
  DSL_NoResultsEmptyStateProps as NoResultsEmptyStateProps, //
  /** @deprecated use DSL_NoResultsEmptyState instead*/
  DSL_NoResultsEmptyState as NoResultsEmptyState,
};
