import React from 'react';

import {useSelector} from '@xstate/react';
import * as R from 'ramda';

import {DSL_GridItem, DSL_GridItemProps} from 'components/DesignSystem/Library';

import {useFormService} from '../FormProvider';

interface DSP_ShowIfLoadingProps extends DSL_GridItemProps {
  /**
   * which state machine states should display the loading spinner.
   * These are usually consistent but can vary
   */
  loadingStates?: string[];
}

export const DSP_ShowIfLoading = ({
  loadingStates = [],
  ...props
}: DSP_ShowIfLoadingProps) => {
  const service = useFormService();
  const loading = useSelector(service, state =>
    R.any(loadingState => state.matches(loadingState), loadingStates),
  );

  return loading ? <DSL_GridItem {...props} /> : null;
};
