import React from 'react';

import {
  DSP_SearchRelationField,
  DSP_SearchRelationFieldProps,
} from '../SearchRelationField';

import {AfmsSearchDocument} from './api/AfmSearch.generated';

export type DSP_AfmSearchRelationFieldProps = Omit<
  DSP_SearchRelationFieldProps,
  'query' | 'queryVariables'
>;

const emptyRecord = {
  id: null,
  name: '',
};

export const DSP_AfmSearchRelationField = ({
  ...props
}: DSP_AfmSearchRelationFieldProps) => {
  return (
    <DSP_SearchRelationField
      id={'afm-relation-search'}
      query={AfmsSearchDocument}
      label={''}
      getOptionLabel={searchResult => searchResult?.name || ''}
      emptyRecord={emptyRecord}
      {...props}
    />
  );
};

export {
  //
  /** @deprecated use DSP_AfmSearchRelationFieldProps instead*/
  DSP_AfmSearchRelationFieldProps as AfmSearchRelationFieldProps, //
  /** @deprecated use DSP_AfmSearchRelationField instead*/
  DSP_AfmSearchRelationField as AfmSearchRelationField,
};
