import {lazy} from 'react';
import WebAsset from '@mui/icons-material/WebAsset';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/applications/application/:permitApplicationId/vcbTestVehicles',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "ManageAssetDetailAratTestVehicles" */ './TestVehicles/TestVehicles'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Manage test vehicles',
  menuIcon: WebAsset,
  authorisation: {
    apiKey: 'AssetDetailAratTestVehicleWithRelations.find',
    policies: [
      {
        role: 'operationsSpatialRead',
      },
      {
        role: 'partnerSpatialRead',
      },
    ] as AuthorisationPolicy[],
  },
};
