import {ApolloClient, ApolloLink} from '@apollo/client';
import {getEnvironmentStatus} from 'utils/global';
import cache from './cache';
import {authLink} from './links/authLink';
import {errorLink} from './links/errorLink';
import {httpLink} from './links/httpLink';
import {retryLink} from './links/retryLink';

const link = ApolloLink.from([errorLink, retryLink, authLink, httpLink]);

export const client = new ApolloClient({
  cache: cache,
  link: link,
  name: 'nhvr-portal-web-client',
  version: getEnvironmentStatus().version,
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'all',
      partialRefetch: true,
    },
    query: {
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
});

export const authClient = new ApolloClient({
  cache: cache,
  link: httpLink,
  name: 'nhvr-portal-web-client',
  version: getEnvironmentStatus().version,
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'all',
      partialRefetch: true,
    },
    query: {
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
});
