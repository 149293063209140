import {InputBaseComponentProps, InputProps} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MuiTextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  FormattingConfig,
  jsonPointerResolver,
  JSONSchemaRecord,
  schemaJsonPointerResolver,
} from '@regulatory-platform/common-utils';
import clsx from 'clsx';
import getTextFieldProps from 'components/forms/TextField/getTextFieldProps';
import * as R from 'ramda';
import React from 'react';
import {FormControlProps} from 'utils/stores/types';
import {useStyles} from '../form.styles';
import NumberFormatCustom from './NumberFormatCustom';

export interface TextFieldLegacyProps extends FormControlProps {
  enableAutoComplete?: boolean;
  hideUom?: boolean;
  onCopy?: (e: React.ClipboardEvent) => void;
  onCut?: (e: React.ClipboardEvent) => void;
  onPaste?: (e: React.ClipboardEvent) => void;
  hideHelperText?: boolean;
  showOptionalPlaceholder?: boolean;
  inputProps?: InputProps;
}

/** @deprecated Do not use! Use components/forms/TextField for old forms and DS.TextField for new forms */
export const TextFieldLegacy: React.FC<TextFieldLegacyProps> = ({
  storeContainer,
  fieldRef,
  disabled,
  readOnly,
  hideLabel,
  addOnElement,
  type,
  variant,
  rows,
  multiline,
  size = 4,
  label,
  value,
  stepper,
  hideUom,
  onCopy,
  onCut,
  onPaste,
  enableAutoComplete,
  hideHelperText = false,
  showOptionalPlaceholder = false,
  classes,
  onBlurEvent,
  placeholder,
  inputProps = {},
  tabIndex,
}: TextFieldLegacyProps) => {
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const formClasses = useStyles();
  const props = getTextFieldProps(storeContainer, fieldRef, '');

  if (!R.isNil(label)) {
    props.label = label;
  }
  if (!R.isNil(placeholder)) {
    props.placeholder = placeholder;
  }
  if (!R.isNil(value)) {
    props.value = value;
  }
  if (hideLabel === true) {
    props.label = undefined;
  }
  if (!R.isNil(variant)) {
    props.variant = variant;
  }
  if (!R.isNil(type)) {
    props.type = type;
  }
  if (!disabled && showOptionalPlaceholder && props.optional) {
    props.placeholder = 'Optional';
  }
  delete props.optional;
  if (hideHelperText) {
    props.helperText = '';
  }
  let multilineFieldMaxLength = 400;
  let multilineFieldValueLength = 0;
  if (props.type !== 'number') {
    props.value = R.defaultTo('', props.value);
  }
  if (props.type === 'password') {
    inputProps.endAdornment = (
      <InputAdornment position="end">
        <Tooltip title={'Toggle password visibility'}>
          <IconButton
            edge="end"
            size="small"
            aria-label="toggle password visibility"
            onClick={(): void => {
              setShowPassword(!showPassword);
            }}
            onMouseDown={(event: React.MouseEvent<HTMLButtonElement>): void => {
              event.preventDefault();
            }}
          >
            {showPassword ? (
              <Visibility fontSize="small" />
            ) : (
              <VisibilityOff fontSize="small" />
            )}
          </IconButton>
        </Tooltip>
      </InputAdornment>
    );
    if (showPassword) {
      props.type = 'text';
    }
  } else if (props.type === 'number') {
    const {state} = storeContainer;
    const {schema} = state.context;
    const fieldConfig = schemaJsonPointerResolver(fieldRef)(
      schema,
    ) as JSONSchemaRecord;
    const fieldFormatting = schemaJsonPointerResolver<
      FormattingConfig | undefined
    >(
      fieldRef,
      'x-formatting',
    )(schema);
    inputProps.inputComponent = NumberFormatCustom as never;
    inputProps.inputProps = R.defaultTo({}, inputProps.inputProps);
    //add field formatting
    if (!R.isNil(fieldFormatting)) {
      if (!R.isNil(fieldFormatting.uom) && hideUom !== true) {
        inputProps.endAdornment = (
          <InputAdornment position="end">{fieldFormatting.uom}</InputAdornment>
        );
      }
      if (!R.isNil(fieldFormatting.decimalScale)) {
        let decimalScale = fieldFormatting.decimalScale;
        const uomConversion = fieldFormatting.uomConversion;
        if (uomConversion === 10) {
          decimalScale = fieldFormatting.decimalScale - 1;
        }
        if (uomConversion === 100) {
          decimalScale = fieldFormatting.decimalScale - 2;
        }
        if (uomConversion === 1000) {
          decimalScale = fieldFormatting.decimalScale - 3;
        }
        inputProps.inputProps.decimalScale = decimalScale;
      }
    }

    //must opt into html5 numberbox rendering
    if (stepper !== true) {
      props.type = 'string'; //override html5 numberbox rendering
    } else {
      props.type = 'number';
      const uomConversion = R.defaultTo(1, fieldFormatting?.uomConversion);
      inputProps.inputProps.step =
        !R.isNil(fieldFormatting) && !R.isNil(fieldFormatting?.step)
          ? fieldFormatting.step * uomConversion
          : undefined;
      inputProps.inputProps.min = !R.isNil(fieldConfig.minimum)
        ? fieldConfig.minimum * uomConversion
        : undefined;
      inputProps.inputProps.max = !R.isNil(fieldConfig.maximum)
        ? fieldConfig.maximum * uomConversion
        : undefined;
    }
    props.value = R.defaultTo('', props.value);
  } else if (multiline) {
    const {state} = storeContainer;
    const {schema, record} = state.context;
    const fieldValue = jsonPointerResolver(fieldRef)(record) as
      | string
      | undefined;
    const fieldMaxLength = schemaJsonPointerResolver<number | undefined>(
      fieldRef,
      'maxLength',
    )(schema);
    multilineFieldValueLength = R.defaultTo('', fieldValue).length;
    multilineFieldMaxLength = R.defaultTo(400, fieldMaxLength);
    props.multiline = true;
    props.rows = rows;
  }
  if (!R.isNil(readOnly)) {
    inputProps = R.defaultTo({}, inputProps);
    inputProps.readOnly = readOnly;
  }

  const smallInputProps = {} as InputBaseComponentProps;
  if (enableAutoComplete !== true) {
    smallInputProps.autoComplete = 'new-password';
  }

  return (
    <FormControl
      className={clsx(
        multiline && size !== 100 ? formClasses[12] : formClasses[size],
        multiline && size === 100
          ? formClasses.marginTopBottom
          : formClasses.margin,
      )}
      margin={'dense'}
      size={'small'}
    >
      <MuiTextField
        margin={'dense'}
        size={'small'}
        {...props}
        tabIndex={tabIndex}
        classes={classes}
        disabled={disabled}
        InputProps={inputProps}
        inputProps={smallInputProps}
        onCopy={onCopy}
        onCut={onCut}
        onPaste={onPaste}
        onBlur={(): void => {
          props.onBlur();
          if (onBlurEvent) onBlurEvent();
        }}
        placeholder={props.placeholder}
      />
      {!multiline ? null : (
        <FormHelperText
          className={formClasses.textCounter}
        >{`${multilineFieldValueLength}/${multilineFieldMaxLength}`}</FormHelperText>
      )}
      {R.isNil(addOnElement) ? null : addOnElement}
    </FormControl>
  );
};
