import {AuthorisationAccountTypes} from '@regulatory-platform/common-utils/dist';
import {AccreditationWorkflowState} from 'containers-accreditation/Shared/types';

import {DSP_NotificationRendererComponent} from '../../types';

import {DSP_AccreditationActionCustomerNotificationCard} from './Customer/AccreditationActionCustomerNotificationCard.Connector';
import {DSP_AccreditationActionOperationsNotificationCard} from './Operations/AccreditationActionOperationsNotificationCard.Connector';
import {DSP_AccreditationActionNotificationItem} from './types';

export const applicationWorkflowStateDescriptionMap: {
  [DSP_ApplicationState in AccreditationWorkflowState]?: string | undefined;
} = {
  [AccreditationWorkflowState.ISSUED_STATE]:
    'approved accreditation application',
  [AccreditationWorkflowState.SUSPENDED_STATE]: 'suspended the accreditation',
  [AccreditationWorkflowState.SUBMITTED_STATE]: 'created a new accreditation',
  [AccreditationWorkflowState.PROVISIONAL_STATE]:
    'provisionally approved accreditation application',
};

export const accreditationApplicationAccountTypeNotificationRendererMap: {
  [DSP_Type in AuthorisationAccountTypes]?:
    | DSP_NotificationRendererComponent<DSP_AccreditationActionNotificationItem>
    | undefined;
} = {
  [AuthorisationAccountTypes.CUSTOMER]:
    DSP_AccreditationActionCustomerNotificationCard,
  [AuthorisationAccountTypes.OPERATIONS]:
    DSP_AccreditationActionOperationsNotificationCard,
};
