import React from 'react';

import {DSL_IconWrapper} from '../IconWrapper';
import {DSL_IconProps} from '../types';

const DSL_AccessPermits = (props: DSL_IconProps) => {
  return (
    <DSL_IconWrapper
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          fill="none"
          viewBox="0 0 24 24"
          {...props}
        >
          <path
            fill="#000"
            fillOpacity={0.54}
            d="M9.261 12.923c-.348.335-.348.87 0 1.17l1.742 1.673a.86.86 0 0 0 .626.234.86.86 0 0 0 .627-.234l3.483-3.344c.348-.335.348-.87 0-1.171-.349-.335-.906-.335-1.22 0l-2.89 2.776-1.114-1.07a.944.944 0 0 0-1.254-.034"
          />
          <path
            fill="#000"
            fillOpacity={0.54}
            fillRule="evenodd"
            d="M20 7h-4V5l-2-2h-4L8 5v2H4c-1.1 0-2 .9-2 2v5c0 .75.4 1.38 1 1.73V19c0 1.11.89 2 2 2h14c1.11 0 2-.89 2-2v-3.28c.59-.35 1-.99 1-1.72V9c0-1.1-.9-2-2-2M10 5h4v2h-4zM4 9h16v5h-1v5H5v-5H4z"
            clipRule="evenodd"
          />
        </svg>
      }
      {...props}
    />
  );
};

export default React.memo(DSL_AccessPermits);
