import React from 'react';

import {DSL_DownloadIcon} from '../../../Icons';
import {DSL_BaseIconButton, DSL_BaseIconButtonProps} from '../BaseIconButton';

export type DSL_DownloadIconButtonProps = Omit<
  DSL_BaseIconButtonProps,
  'color' | 'children'
>;

export const DSL_DownloadIconButton = (
  props: DSL_DownloadIconButtonProps,
): JSX.Element => {
  return (
    <DSL_BaseIconButton aria-label={'download'} {...props}>
      <DSL_DownloadIcon />
    </DSL_BaseIconButton>
  );
};

DSL_DownloadIconButton.displayName = 'DSL_DownloadIconButton';

export {
  //
  /** @deprecated use DSL_DownloadIconButtonProps instead*/
  DSL_DownloadIconButtonProps as DownloadIconButtonProps, //
  /** @deprecated use DSL_DownloadIconButton instead*/
  DSL_DownloadIconButton as DownloadIconButton,
};
