import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/roads/:id',
  component: lazy(
    () => import(/* webpackChunkName: "RoadDetail" */ './RoadDetail'),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Manage road detail',
  menuIcon: LibraryAddCheckIcon,
  authorisation: {
    apiKey: 'RoadDetailWithRelations.findById',
    policies: [
      {
        role: 'partnerSpatialRead',
      },
      {
        role: 'providerSpatialRead',
      },
      {
        role: 'operationsSpatialRead',
      },
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Road details',
      to: '..',
    },
  ],
};
