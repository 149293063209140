import React from 'react';

import {
  DSP_UserEntityLoaderProvider,
  DSP_UserEntityAvatarWithName,
} from 'components/DesignSystem/Portal/Entity/UserEntity';

import {DSP_AccessPublicNoticeOfAmendmentNotificationCardView} from './AccessPublicNoticeOfAmendmentNotificationCard.View';
import {DSP_AccessPublicNoticeOfAmendmentNotificationItem} from './types';
import {useAccessPublicNoticeOfAmendmentNotificationCard} from './useAccessPublicNoticeOfAmendmentNotificationCard';
export interface DSP_AccessPublicNoticeOfAmendmentNotificationCardProps {
  notification: DSP_AccessPublicNoticeOfAmendmentNotificationItem;
}

export const DSP_AccessPublicNoticeOfAmendmentNotificationCard = ({
  notification,
}: DSP_AccessPublicNoticeOfAmendmentNotificationCardProps) => {
  const viewProps = useAccessPublicNoticeOfAmendmentNotificationCard({
    notification,
  });

  return (
    <DSP_AccessPublicNoticeOfAmendmentNotificationCardView
      avatar={
        notification.fromUserId && (
          <DSP_UserEntityLoaderProvider entityId={notification.fromUserId}>
            <DSP_UserEntityAvatarWithName />
          </DSP_UserEntityLoaderProvider>
        )
      }
      {...viewProps}
    />
  );
};

DSP_AccessPublicNoticeOfAmendmentNotificationCard.displayName =
  'DSP_AccessPublicNoticeOfAmendmentNotificationCard';
