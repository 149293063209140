import React from 'react';

import {Link} from '@mui/material';
import * as R from 'ramda';
import {DropzoneInputProps, DropzoneRootProps} from 'react-dropzone';

import {DSL_FileAvatar} from '../../../Avatar/FileAvatar';
import {DSL_FileSizeRenderer} from '../../../Renderers';
import {DSL_Body2} from '../../../Typography/Body2';
import {DSL_Subtitle1} from '../../../Typography/Subtitle1';
import {DSL_DropTarget, DSL_DropTargetProps} from '../DropTarget';

export interface DSL_SelectFileDropTargetViewProps
  extends Omit<DSL_DropTargetProps, 'error'> {
  inputProps: DropzoneInputProps;
  rootProps: DropzoneRootProps;
  isDragActive: boolean;
  extensions: string[];
  maxSize?: number;
  errorMessage?: string;
}

export const DSL_SelectFileDropTargetView = ({
  extensions = [],
  errorMessage,
  isDragActive,
  maxSize,
  rootProps,
  inputProps,
  children,
}: DSL_SelectFileDropTargetViewProps) => {
  return (
    <DSL_DropTarget
      isDragActive={isDragActive}
      error={!!errorMessage}
      rootProps={rootProps}
    >
      <input {...inputProps} />
      <DSL_FileAvatar error={!!errorMessage} />
      <DSL_Subtitle1 color={'text.primary'}>
        <Link
          sx={{cursor: 'pointer'}}
          color={errorMessage ? 'error' : 'primary'}
        >
          Click to select
        </Link>
        , or drag and drop a file here
      </DSL_Subtitle1>
      <DSL_Body2>
        Accepted file types: {R.uniq(extensions).join(', ')}{' '}
        {maxSize && <>(max. {<DSL_FileSizeRenderer size={maxSize} />})</>}
        {children}
      </DSL_Body2>
      {errorMessage && <DSL_Body2 color={'error'}>{errorMessage}</DSL_Body2>}
    </DSL_DropTarget>
  );
};

DSL_SelectFileDropTargetView.displayName = 'DSL_SelectFileDropTargetView';
