import React from 'react';

import {FallbackProps} from 'react-error-boundary/dist/declarations/src/types';
import {useBoolean} from 'usehooks-ts';
import {isUATOrProduction} from 'utils/global';

import {
  DSL_Body1,
  DSL_Box,
  DSL_FlexColumn,
  DSL_FlexRow,
  DSL_Home,
  DSL_InlineErrorAlert,
  DSL_OutlinedButton,
  DSL_RefreshIcon,
} from 'components/DesignSystem/Library';

import {DSP_PageContainer} from '../PageContainer';

export type DSP_ErrorBoundaryFallbackProps = FallbackProps;
export const DSP_ErrorBoundaryFallback = ({
  error,
  resetErrorBoundary,
}: DSP_ErrorBoundaryFallbackProps) => {
  const {value: isLoading, setTrue: startLoading} = useBoolean(false);
  const isDev = !isUATOrProduction();
  return (
    <DSP_PageContainer title={'Oops!'} hideBreadcrumbs>
      <DSL_FlexColumn gap={2}>
        Something went wrong! We are looking into this but in the mean time you
        can try reloading the page, or going back to home.
        <DSL_FlexRow>
          <DSL_OutlinedButton
            loading={isLoading}
            startIcon={<DSL_RefreshIcon />}
            onClick={() => {
              if (isDev) {
                startLoading();
                setTimeout(() => resetErrorBoundary(), 100);
              } else {
                /**
                 * Unlikely to recover in production
                 * This treats issues related to a new release
                 * @TODO implement service worker to automate reloading packages on release
                 */
                window.location.reload();
              }
            }}
          >
            Reload Page
          </DSL_OutlinedButton>
          <DSL_OutlinedButton startIcon={<DSL_Home />} href={'/'}>
            Back to Home Page
          </DSL_OutlinedButton>
        </DSL_FlexRow>
        {isDev && (
          <DSL_Box maxWidth={'100%'}>
            <DSL_InlineErrorAlert title={'Error Details'}>
              <DSL_Body1 color={'warning'} bold>
                Error Details
              </DSL_Body1>
              <DSL_Box maxWidth={'100%'} overflow={'auto'}>
                <pre
                  style={{
                    maxWidth: '100%',
                    overflow: 'auto',
                    wordBreak: 'break-word',
                  }}
                >
                  {error.stack}
                </pre>
              </DSL_Box>
            </DSL_InlineErrorAlert>
          </DSL_Box>
        )}
      </DSL_FlexColumn>
    </DSP_PageContainer>
  );
};
