import React from 'react';

import {
  DSL_EmailIconInfo,
  DSL_LocationOnIconInfo,
  DSL_PhoneIconInfo,
} from '../../IconInfo';
import {DSL_EntityInfoContainer, DSL_EntityInfoRow} from '../EntityInfo';

export interface DSL_EntityContactDetailsProps {
  isLoading?: boolean;
  phone?: string | null;
  email?: string | null;
  location?: string | null;
}

export const DSL_EntityContactDetails = ({
  phone,
  email,
  location,
  isLoading,
}: DSL_EntityContactDetailsProps) => {
  return (
    <DSL_EntityInfoContainer>
      {phone && (
        <DSL_EntityInfoRow isLoading={isLoading}>
          <DSL_PhoneIconInfo label={phone} />
        </DSL_EntityInfoRow>
      )}
      {email && (
        <DSL_EntityInfoRow isLoading={isLoading}>
          <DSL_EmailIconInfo label={email} />
        </DSL_EntityInfoRow>
      )}
      {location && (
        <DSL_EntityInfoRow isLoading={isLoading}>
          <DSL_LocationOnIconInfo label={location} />
        </DSL_EntityInfoRow>
      )}
    </DSL_EntityInfoContainer>
  );
};

DSL_EntityContactDetails.displayName = 'DSL_EntityContactDetails';
