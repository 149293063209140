import React from 'react';

import {DSL_BaseAlert, DSL_BaseAlertProps} from '../BaseAlert';

export type DSL_GlobalSuccessAlertProps = Omit<
  DSL_BaseAlertProps,
  'variant' | 'severity'
>;

export const DSL_GlobalSuccessAlert = (props: DSL_GlobalSuccessAlertProps) => {
  return <DSL_BaseAlert severity="success" {...props} />;
};

DSL_GlobalSuccessAlert.displayName = 'DSL_GlobalSuccessAlert';

export {
  //
  /** @deprecated use DSL_GlobalSuccessAlertProps instead*/
  DSL_GlobalSuccessAlertProps as GlobalSuccessAlertProps, //
  /** @deprecated use DSL_GlobalSuccessAlert instead*/
  DSL_GlobalSuccessAlert as GlobalSuccessAlert,
};
