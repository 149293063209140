import React from 'react';

import {
  DSL_CardContent,
  DSL_GlobalErrorAlert,
  DSL_List,
  DSL_ListItem,
  DSL_OutlinedCard,
  DSL_DismissibleGlobalAlert,
  DSL_AlertTitle,
} from 'components/DesignSystem/Library';

import {pluralizeLabel} from '../../../../utils/pluralizeLabel';
import {ImportFromCsvRowMachine} from '../machines/types';

import {
  ImportFromCsvTable,
  ImportFromCsvTableProps,
} from './ImportFromCsvTable';

export interface ImportFromCsvEditStepProps extends ImportFromCsvTableProps {
  omittedRowCount: number;
  rowMachines: ImportFromCsvRowMachine[];
  formError: string | undefined;
  maxRows: number;
}

export const ImportFromCsvEditStep = ({
  tableTitle,
  csvService,
  formError,
  maxRows,
  columns,
  rowMachines,
  omittedRowCount,
  invalidRowCount,
  selectedRowCount,
}: ImportFromCsvEditStepProps) => {
  return (
    <>
      {omittedRowCount > 0 && (
        <DSL_DismissibleGlobalAlert severity={'warning'}>
          <DSL_AlertTitle>Maximum data rows exceeded</DSL_AlertTitle>
          {omittedRowCount} {pluralizeLabel('row', omittedRowCount)} of data
          have been excluded in this upload. Please split your file into smaller
          chunks of {maxRows} {pluralizeLabel('row', maxRows)} or less to
          proceed.
        </DSL_DismissibleGlobalAlert>
      )}
      {(formError || invalidRowCount > 0) && (
        <DSL_GlobalErrorAlert>
          <DSL_List disablePadding>
            {formError && (
              <DSL_ListItem disablePadding>{formError}</DSL_ListItem>
            )}
            {invalidRowCount > 0 && (
              <DSL_ListItem disablePadding>
                Some rows will not be imported because they contain validation
                errors. To include them in the import data, correct the
                validation errors and mark the row as selected.
              </DSL_ListItem>
            )}
          </DSL_List>
        </DSL_GlobalErrorAlert>
      )}
      <DSL_OutlinedCard>
        <DSL_CardContent padding={'NONE'}>
          <ImportFromCsvTable
            tableTitle={tableTitle}
            csvService={csvService}
            columns={columns}
            rowMachines={rowMachines}
            selectedRowCount={selectedRowCount}
            invalidRowCount={invalidRowCount}
          />
        </DSL_CardContent>
      </DSL_OutlinedCard>
    </>
  );
};

ImportFromCsvEditStep.displayName = 'ImportFromCsvEditStep';
