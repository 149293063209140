import React from 'react';

import {
  DialogActions as MuiDialogActions,
  DialogActionsProps as MuiDialogActionsProps,
} from '@mui/material';
import {DSL_ButtonBar} from 'components/DesignSystem/Library/Buttons/ButtonBar';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

export type DSL_DialogActionsProps = Omit<
  MuiDialogActionsProps,
  DSL_RestrictedVisualProps
>;

export const DSL_DialogActions = ({
  children,
  ...props
}: DSL_DialogActionsProps) => {
  return (
    <MuiDialogActions sx={{py: 2, px: 3}} {...props}>
      <DSL_ButtonBar>{children}</DSL_ButtonBar>
    </MuiDialogActions>
  );
};

DSL_DialogActions.displayName = 'DSL_DialogActions';

export {
  //
  /** @deprecated use DSL_DialogActionsProps instead*/
  DSL_DialogActionsProps as DialogActionsProps, //
  /** @deprecated use DSL_DialogActions instead*/
  DSL_DialogActions as DialogActions,
};
