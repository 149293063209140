import React from 'react';

import {AccessProductChangeSetWithRelations} from 'typeDefs/types';
import {NotificationItem} from 'utils/machines/notificationsMachine';

import {DSP_AccessProductChangeSetPartnerNotificationCardView} from './AccessProductChangeSetPartnerNotificationCard.View';
import {useAccessProductChangeSetPartnerNotificationCard} from './useAccessProductChangeSetPartnerNotificationCard';

export interface DSP_AccessProductChangeSetPartnerNotificationCardProps {
  notification: NotificationItem<AccessProductChangeSetWithRelations>;
}

export const DSP_AccessProductChangeSetPartnerNotificationCard = (
  props: DSP_AccessProductChangeSetPartnerNotificationCardProps,
) => {
  const viewProps = useAccessProductChangeSetPartnerNotificationCard(props);

  return (
    <DSP_AccessProductChangeSetPartnerNotificationCardView {...viewProps} />
  );
};

DSP_AccessProductChangeSetPartnerNotificationCard.displayName =
  'DSP_AccessProductChangeSetPartnerNotificationCard';

export {
  //
  /** @deprecated use DSP_AccessProductChangeSetPartnerNotificationCardProps instead*/
  DSP_AccessProductChangeSetPartnerNotificationCardProps as AccessProductChangeSetPartnerNotificationCardProps, //
  /** @deprecated use DSP_AccessProductChangeSetPartnerNotificationCard instead*/
  DSP_AccessProductChangeSetPartnerNotificationCard as AccessProductChangeSetPartnerNotificationCard,
};
