import Assignment from '@mui/icons-material/Assignment';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/operationManuals',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "OperationalManuals" */ './OperationalManuals'
      ),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'Operation manuals',
  menuIcon: Assignment,
  authorisation: {
    apiKey: 'OperationManualWithRelations.findById',
    policies: [
      {role: 'operationsAccreditationRead'},
      {role: 'customerAccreditationRead'},
    ] as AuthorisationPolicy[],
  },
  sections: [],
};
