import React from 'react';

import {DSL_ShareIcon} from '../../../Icons';
import {DSL_BaseIconButton, DSL_BaseIconButtonProps} from '../BaseIconButton';

export type DSL_ShareIconButtonProps = Omit<
  DSL_BaseIconButtonProps,
  'color' | 'children'
>;

export const DSL_ShareIconButton = ({
  size = 'large',
  ...props
}: DSL_ShareIconButtonProps): JSX.Element => {
  return (
    <DSL_BaseIconButton aria-label="share" size={size} {...props}>
      <DSL_ShareIcon fontSize={size} />
    </DSL_BaseIconButton>
  );
};

DSL_ShareIconButton.displayName = 'DSL_ShareIconButton';

export {
  //
  /** @deprecated use DSL_ShareIconButtonProps instead*/
  DSL_ShareIconButtonProps as ShareIconButtonProps, //
  /** @deprecated use DSL_ShareIconButton instead*/
  DSL_ShareIconButton as ShareIconButton,
};
