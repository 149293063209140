import React from 'react';

import {DSL_Heading3} from '../../Typography/Heading3';
import {DSL_Subtitle1} from '../../Typography/Subtitle1';
import {DSL_Divider} from '../Divider';
import {DSL_FlexColumn} from '../FlexColumn';
import {DSL_FlexRow, DSL_FlexRowProps} from '../FlexRow';

import {DSL_Breadcrumbs} from './Breadcrumbs';

export interface DSL_PageHeaderProps
  extends Omit<DSL_FlexRowProps, 'justifyContent' | 'gap' | 'title'> {
  title: React.ReactNode;
  breadcrumbs?: React.ReactElement[] | React.ReactNode[];
  subTitle?: React.ReactNode;
  actionItems?: React.ReactNode;
  pageInfo?: React.ReactNode;
  showDivider?: boolean;
}

export const DSL_PageHeader = ({
  title,
  subTitle = '',
  actionItems,
  pageInfo,
  breadcrumbs = [],
  showDivider = false,
  ...flexRowProps
}: DSL_PageHeaderProps) => {
  return (
    <DSL_FlexColumn gap={3}>
      <DSL_FlexRow
        {...flexRowProps}
        justifyContent={'space-between'}
        data-name={'page-header-flex-row'}
      >
        <DSL_FlexColumn
          data-name={'page-header-flex-column'}
          gap={breadcrumbs.length > 0 ? 1 : 0}
        >
          {breadcrumbs?.length > 0 && (
            <DSL_Breadcrumbs>{breadcrumbs}</DSL_Breadcrumbs>
          )}
          <DSL_Heading3>{title}</DSL_Heading3>
          {subTitle && <DSL_Subtitle1>{subTitle}</DSL_Subtitle1>}
          {pageInfo && <DSL_FlexRow>{pageInfo}</DSL_FlexRow>}
        </DSL_FlexColumn>
        <DSL_FlexRow alignItems={'flex-start'}>{actionItems}</DSL_FlexRow>
      </DSL_FlexRow>
      {showDivider && <DSL_Divider />}
    </DSL_FlexColumn>
  );
};

DSL_PageHeader.displayName = 'DSL_PageHeader';

export {
  //
  /** @deprecated use DSL_PageHeaderProps instead*/
  DSL_PageHeaderProps as PageHeaderProps, //
  /** @deprecated use DSL_PageHeader instead*/
  DSL_PageHeader as PageHeader,
};
