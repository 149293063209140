import {useDrawerContext} from '../DrawerProvider';
import {useDrawerTransitions} from '../useDrawerTransitions';

export function useDrawerResponsiveContainer() {
  const {drawerDimensions} = useDrawerContext();
  const {transition} = useDrawerTransitions(drawerDimensions);

  /*
   1px added to the left and right margin to prevent overlap between last
   pixel of the drawer and the first pixel of the content.
  */
  const margin = {
    marginLeft: drawerDimensions.fixed.left.width + 1,
    marginRight: drawerDimensions.fixed.right.width + 1,
  };

  return {
    transition,
    margin,
  };
}
