import React from 'react';

import {
  Body1,
  ButtonBarGroup,
  ContainedButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  OutlinedButton,
} from 'components/DesignSystem/Library';

import {DSP_FormSaveDialogProps} from '../FormSaveDialog.Connector';

export interface DSP_FormSaveConfirmationDialogViewProps
  extends DSP_FormSaveDialogProps {
  submitConfirmMessage?: string;
  onConfirm: () => void;
}

export const DSP_FormSaveConfirmationDialogView = ({
  submitConfirmMessage,
  onConfirm,
  onClose,
  ...props
}: DSP_FormSaveConfirmationDialogViewProps) => (
  <Dialog
    aria-labelledby={'form-save-dialog-title'}
    onClose={onClose}
    {...props}
  >
    <DialogTitle
      id={'form-save-dialog-title'}
      title={'Confirm'}
      onClose={onClose}
    />
    <DialogContent>
      <Body1>{submitConfirmMessage}</Body1>
    </DialogContent>
    <DialogActions>
      <ButtonBarGroup>
        <ContainedButton onClick={onConfirm} autoFocus>
          Yes
        </ContainedButton>
        <OutlinedButton onClick={onClose}>No</OutlinedButton>
      </ButtonBarGroup>
    </DialogActions>
  </Dialog>
);

DSP_FormSaveConfirmationDialogView.displayName =
  'DSP_FormSaveConfirmationDialogView';

export {
  //
  /** @deprecated use DSP_FormSaveConfirmationDialogViewProps instead*/
  DSP_FormSaveConfirmationDialogViewProps as FormSaveConfirmationDialogViewProps, //
  /** @deprecated use DSP_FormSaveConfirmationDialogView instead*/
  DSP_FormSaveConfirmationDialogView as FormSaveConfirmationDialogView,
};
