// Ideally this should come from the backend, while the type is available at compile time,
// the actual javascript enum instance doesn't seem to be available at runtime
export enum AccessProductApplicationActivityEventType {
  INFO_REQ__DRAFT = 'INFO_REQ.DRAFT',
  INFO_REQ__SUBMIT = 'INFO_REQ.SUBMIT',
  INFO_REQ__COMPLETE = 'INFO_REQ.COMPLETE',
  INFO_REQ__CLOSE = 'INFO_REQ.CLOSE',
  INFO_REQ__ARCHIVE = 'INFO_REQ.ARCHIVE',
  INFO_REQ__REOPEN = 'INFO_REQ.REOPEN',
  INFO_REQ_REPLY__CREATE = 'INFO_REQ_REPLY.CREATE',
  INFO_REQ_REPLY__SUBMIT = 'INFO_REQ_REPLY.SUBMIT',
  INFO_REQ_REPLY__CREATE_AND_SUBMIT = 'INFO_REQ_REPLY.CREATE_AND_SUBMIT',
  INFO_REQ_REPLY__DRAFT = 'INFO_REQ_REPLY.DRAFT',
  INFO_REQ_REPLY__ARCHIVE = 'INFO_REQ_REPLY.ARCHIVE',
  INFO_REQ__CREATE_AND_SUBMIT = 'INFO_REQ.CREATE_AND_SUBMIT',
  INFO_REQ__CREATE = 'INFO_REQ.CREATE',
  INFO_REQ__WITHDRAW = 'INFO_REQ.WITHDRAW',
  NOTE__CREATE = 'NOTE.CREATE',
  NOTE__UPDATE = 'NOTE.UPDATE',
  NOTE__REPLY = 'NOTE.REPLY',
  NOTE__ARCHIVE = 'NOTE.ARCHIVE',
  NOTE__UNARCHIVE = 'NOTE.UNARCHIVE',
  COMMENT__CREATE = 'COMMENT.CREATE',
  COMMENT__UPDATE = 'COMMENT.UPDATE',
  COMMENT__ARCHIVE = 'COMMENT.ARCHIVE',
  COMMENT__UNARCHIVE = 'COMMENT.UNARCHIVE',
  TASK__DRAFT = 'TASK.DRAFT',
  TASK__SUBMIT = 'TASK.SUBMIT',
  TASK__COMPLETE = 'TASK.COMPLETE',
  TASK__CLOSE = 'TASK.CLOSE',
  TASK__ARCHIVE = 'TASK.ARCHIVE',
  TASK__REOPEN = 'TASK.REOPEN',
  TASK__CREATE_AND_SUBMIT = 'TASK.CREATE_AND_SUBMIT',
  TASK__CREATE = 'TASK.CREATE',
  TASK__WITHDRAW = 'TASK.WITHDRAW',
  TASK_REPLY__CREATE = 'TASK_REPLY.CREATE',
  TASK_REPLY__SUBMIT = 'TASK_REPLY.SUBMIT',
  TASK_REPLY__CREATE_AND_SUBMIT = 'TASK_REPLY.CREATE_AND_SUBMIT',
  TASK_REPLY__DRAFT = 'TASK_REPLY.DRAFT',
  TASK_REPLY__ARCHIVE = 'TASK_REPLY.ARCHIVE',
  DECISION_TASK__DRAFT = 'DECISION_TASK.DRAFT',
  DECISION_TASK__SUBMIT = 'DECISION_TASK.SUBMIT',
  DECISION_TASK__COMPLETE = 'DECISION_TASK.COMPLETE',
  DECISION_TASK__CLOSE = 'DECISION_TASK.CLOSE',
  DECISION_TASK__ARCHIVE = 'DECISION_TASK.ARCHIVE',
  DECISION_TASK__REOPEN = 'DECISION_TASK.REOPEN',
  DECISION_TASK__CREATE_AND_SUBMIT = 'DECISION_TASK.CREATE_AND_SUBMIT',
  DECISION_TASK__CREATE = 'DECISION_TASK.CREATE',
  DECISION_TASK__WITHDRAW = 'DECISION_TASK.WITHDRAW',
  DECISION_TASK_REPLY__CREATE = 'DECISION_TASK_REPLY.CREATE',
  DECISION_TASK_REPLY__SUBMIT = 'DECISION_TASK_REPLY.SUBMIT',
  DECISION_TASK_REPLY__CREATE_AND_SUBMIT = 'DECISION_TASK_REPLY.CREATE_AND_SUBMIT',
  DECISION_TASK_REPLY__DRAFT = 'DECISION_TASK_REPLY.DRAFT',
  DECISION_TASK_REPLY__ARCHIVE = 'DECISION_TASK_REPLY.ARCHIVE',
  START_REVIEW = 'START_REVIEW',
  CLOSE = 'CLOSE',
}

export enum ConsentWorkflowState {
  DRAFT_NUR_STATE = 1,
  CONSENT_STATE = 9,
  SUBMITTED_STATE = 2,
  REVIEWING_STATE = 3,
  AWAITING_PUBLISH_STATE = 4,
  PUBLISHING_STATE = 5,
  PUBLISHED_STATE = 6,
  REFERRED_STATE = 7,
  CLOSED_STATE = 8,
}
