import DataUsage from '@mui/icons-material/Dashboard';
import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/approval-library/:id',
  component: lazy(
    () => import(/* webpackChunkName: "ManageApproval" */ './ManageApproval'),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Manage Approval',
  menuIcon: DataUsage,
  authorisation: {
    apiKey: 'PbsApprovalWithRelations.findById',
    policies: [
      {role: 'operationsPBSRead'},
      {role: 'providerPBSRead'},
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'PBS Approval Library',
      to: '..',
    },
  ],
};
