import DataUsage from '@mui/icons-material/Dashboard';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/certifiers',
  component: lazy(
    () => import(/* webpackChunkName: "Certifiers" */ './Certifiers'),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'Certifiers',
  menuIcon: DataUsage,
  authorisation: {
    apiKey: 'PbsCertifierWithRelations.findById',
    policies: [{role: 'operationsPBSRead'}] as AuthorisationPolicy[],
  },
  category: 'Certifiers',
  sections: [],
};
