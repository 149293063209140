import {
  FilesConfig,
  files,
  RecordWithFileList,
} from '@regulatory-platform/common-utils';
import {useSelector} from '@xstate/react';
import {getDownloadURL} from 'components/DesignSystem/Portal/Forms/AttachmentsField/utils';

import {useFormService} from '../../FormProvider';

interface DSP_UseSummaryAttachmentsRenderer {
  blobUrl: (name: string, filePrefix: string) => void;
}

export const useSummaryAttachmentsRenderer =
  (): DSP_UseSummaryAttachmentsRenderer => {
    const service = useFormService();

    const activeRecord = useSelector(service, state => state.context.record);
    const onGenerateSASToken = useSelector(
      service,
      state => state.context.onGenerateSASTokenFunc,
    );
    const schema = useSelector(service, state => state.context.schema);

    const {getAttachmentsConfig} = files;
    const attachmentConfig = getAttachmentsConfig(schema) as FilesConfig;

    const blobUrl = (
      name: string,
      filePrefix: string,
      fileGroupId?: string,
    ): void => {
      getDownloadURL(
        name,
        filePrefix,
        fileGroupId,
        attachmentConfig,
        activeRecord as RecordWithFileList,
        onGenerateSASToken,
      ).then(newPageUrl => {
        window.open(newPageUrl, '_blank');
      });
    };

    return {
      blobUrl,
    };
  };
