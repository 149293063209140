export interface DSL_UseHighlightSubstringArgs {
  value: string;
  highlightText: string;
}

export const useHighlightedText = ({
  value,
  highlightText,
}: DSL_UseHighlightSubstringArgs) => {
  const escapedHighlightText = highlightText.replace(/[^A-Za-z0-9_]/g, '\\$&');

  const regex = new RegExp(`(${escapedHighlightText})`, 'gi');

  const parts = value.split(regex);

  return {
    parts,
    regex,
  };
};
