import React, {ReactNode} from 'react';

import {DSP_BaseAccreditationApplicationNotificationCardView} from './BaseAccreditationApplicationNotificationCard.View';
import {
  useBaseAccreditationApplicationNotificationCard,
  DSP_UseBaseAccreditationApplicationNotificationCardProps,
} from './useBaseAccreditationApplicationNotificationCard';

export interface DSP_BaseAccreditationApplicationNotificationCardProps
  extends DSP_UseBaseAccreditationApplicationNotificationCardProps {
  avatar: ReactNode | undefined;
}

export const DSP_BaseAccreditationApplicationNotificationCard = ({
  avatar,
  ...props
}: DSP_BaseAccreditationApplicationNotificationCardProps) => {
  const viewProps = useBaseAccreditationApplicationNotificationCard(props);

  return (
    <DSP_BaseAccreditationApplicationNotificationCardView
      avatar={avatar}
      {...viewProps}
    />
  );
};

DSP_BaseAccreditationApplicationNotificationCard.displayName =
  'DSP_BaseAccreditationApplicationNotificationCard';
