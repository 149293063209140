import React from 'react';

import {DSP_DateTimeRenderer} from 'components/DesignSystem/Portal/ValueRenderers/DateTimeRenderer/DateTimeRenderer';
import {DSP_TimeDistanceRenderer} from 'components/DesignSystem/Portal/ValueRenderers/TimeDistanceRenderer/TimeDistanceRenderer';

import {Body2, Body2Props, Tooltip} from 'components/DesignSystem/Library';

import {useClickableTimeDistance} from './useClickableTimeDIstance';

export interface DSP_ClickableTimeDistanceProps extends Body2Props {
  date: Date;
  prefix?: string;
}

export const DSP_ClickableTimeDistance = ({
  color = 'text.secondary',
  date,
  prefix,
  ...props
}: DSP_ClickableTimeDistanceProps) => {
  const {showFullDate, onDateClicked} = useClickableTimeDistance();

  return (
    <Body2 color={color} {...props}>
      {prefix && `${prefix} `}
      {showFullDate ? (
        <DSP_DateTimeRenderer date={date} onClick={onDateClicked} />
      ) : (
        <Tooltip title={<DSP_DateTimeRenderer date={date} />}>
          <DSP_TimeDistanceRenderer date={date} onClick={onDateClicked} />
        </Tooltip>
      )}
    </Body2>
  );
};

DSP_ClickableTimeDistance.displayName = 'DSP_ClickableTimeDistance';

export {
  //
  /** @deprecated use DSP_ClickableTimeDistanceProps instead*/
  DSP_ClickableTimeDistanceProps as ClickableTimeDistanceProps, //
  /** @deprecated use DSP_ClickableTimeDistance instead*/
  DSP_ClickableTimeDistance as ClickableTimeDistance,
};
