import * as Types from '../../../../typeDefs/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountsSearchQueryVariables = Types.Exact<{
  qs: Types.Scalars['String']['input'];
}>;


export type AccountsSearchQuery = { __typename?: 'Query', accountsSearch?: Array<{ __typename?: 'AccountUserMapping', accountName?: string | null, accountType?: string | null, accountId?: number | null, accountImageUrl?: string | null } | null> | null };


export const AccountsSearchDocument = gql`
    query accountsSearch($qs: String!) {
  accountsSearch(qs: $qs) {
    accountName
    accountType
    accountId
    accountImageUrl
  }
}
    `;

/**
 * __useAccountsSearchQuery__
 *
 * To run a query within a React component, call `useAccountsSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsSearchQuery({
 *   variables: {
 *      qs: // value for 'qs'
 *   },
 * });
 */
export function useAccountsSearchQuery(baseOptions: Apollo.QueryHookOptions<AccountsSearchQuery, AccountsSearchQueryVariables> & ({ variables: AccountsSearchQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountsSearchQuery, AccountsSearchQueryVariables>(AccountsSearchDocument, options);
      }
export function useAccountsSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountsSearchQuery, AccountsSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountsSearchQuery, AccountsSearchQueryVariables>(AccountsSearchDocument, options);
        }
export function useAccountsSearchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AccountsSearchQuery, AccountsSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountsSearchQuery, AccountsSearchQueryVariables>(AccountsSearchDocument, options);
        }
export type AccountsSearchQueryHookResult = ReturnType<typeof useAccountsSearchQuery>;
export type AccountsSearchLazyQueryHookResult = ReturnType<typeof useAccountsSearchLazyQuery>;
export type AccountsSearchSuspenseQueryHookResult = ReturnType<typeof useAccountsSearchSuspenseQuery>;
export type AccountsSearchQueryResult = Apollo.QueryResult<AccountsSearchQuery, AccountsSearchQueryVariables>;