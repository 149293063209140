import Assignment from '@mui/icons-material/Assignment';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

/** @deprecated this page is used to iFrame into the legacy V1 accreditation portal, use the /vehicleDefects page instead */
export default {
  path: '/vehicleDefectsAccreditation/operations/:id',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "VehicleDefectsAccreditationOperations" */ './VehicleDefectsAccreditationOperations'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Vehicle Defects Accreditation',
  menuIcon: Assignment,
  authorisation: {
    apiKey: 'VehicleDefectAccreditationWithRelations.findById',
    policies: [{role: 'operationsAccreditationRead'}] as AuthorisationPolicy[],
  },
  sections: [],
};
