import React, {useCallback, useEffect, useRef} from 'react';

import {useSizeObserver} from '../../hooks/useSizeObserver';

import {DSL_BoxProps, DSL_Box} from './Box';

export interface DSL_WidthObservingBoxProps extends DSL_BoxProps {
  onWidthChange: (width: number) => void;
}

export const DSL_WidthObservingBox = ({
  onWidthChange,
  ...props
}: DSL_WidthObservingBoxProps) => {
  const boxRef = useRef<HTMLElement>(null);

  useSizeObserver({
    ref: boxRef,
    onResize: useCallback(
      entries => {
        const resizeWidth = Math.round(entries[0].contentRect.width);
        onWidthChange(resizeWidth);
      },
      [onWidthChange],
    ),
  });

  useEffect(() => {
    return () => {
      onWidthChange(0);
    };
  }, [onWidthChange]);

  return <DSL_Box data-name={'width-observing-box'} ref={boxRef} {...props} />;
};
