import React from 'react';

import {
  DSL_BaseDrawerHeader,
  DSL_Body2,
  DSL_Flex,
  DSL_FlexColumn,
  DSL_FlexRow,
  DSL_FullscreenIconButton,
  DSL_Heading5,
  DSL_RemoveCircleIconButton,
  DSL_Tooltip,
} from 'components/DesignSystem/Library';

import {useDspSelectedSegmentsSummary} from '../SelectedSegmentsSummary/useSelectedSegmentsSummary';

export interface DSP_SelectedSegmentsMinimizedDrawerHeaderProps {
  onClose: () => void;
  toggleMinimizeDrawer?: () => void;
  isMinimized?: boolean;
}

export const DSP_SelectedSegmentsMinimizedDrawerHeader = ({
  onClose,
  toggleMinimizeDrawer,
  isMinimized,
}: DSP_SelectedSegmentsMinimizedDrawerHeaderProps) => {
  const {summary} = useDspSelectedSegmentsSummary();
  return (
    <DSL_BaseDrawerHeader aria-expanded={!isMinimized}>
      <DSL_FlexRow
        flexShrink={0}
        width={'100%'}
        boxSizing={'border-box'}
        flexWrap={'nowrap'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <DSL_FlexColumn gap={1}>
          <DSL_FlexRow gap={1} alignItems={'flex-end'} flexWrap={'nowrap'}>
            <DSL_Heading5 ellipsisLines={1}>Selection</DSL_Heading5>
            <DSL_Body2 color={'text.secondary'}>{summary}</DSL_Body2>
          </DSL_FlexRow>
        </DSL_FlexColumn>
        <DSL_FlexRow alignItems={'center'} gap={1}>
          {!!toggleMinimizeDrawer && (
            <DSL_Tooltip
              isClickable
              title={'Show selection summary'}
              placement={'bottom'}
            >
              <DSL_Flex>
                <DSL_FullscreenIconButton
                  size={'large'}
                  aria-label={'Show selection summary'}
                  aria-controls={'base-drawer'}
                  aria-expanded={!isMinimized}
                  key={'toggle-minimize-drawer'}
                  onClick={toggleMinimizeDrawer}
                />
              </DSL_Flex>
            </DSL_Tooltip>
          )}
          {onClose && (
            <DSL_Tooltip title={'Deselect all segments'} placement={'top'}>
              <DSL_Flex justifyContent={'flex-end'} alignItems={'flex-start'}>
                <DSL_RemoveCircleIconButton
                  aria-label={'Deselect all segments'}
                  size={'large'}
                  onClick={() => {
                    onClose();
                    toggleMinimizeDrawer?.();
                  }}
                />
              </DSL_Flex>
            </DSL_Tooltip>
          )}
        </DSL_FlexRow>
      </DSL_FlexRow>
    </DSL_BaseDrawerHeader>
  );
};
