import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/preapprovals',
  component: lazy(
    () => import(/* webpackChunkName: "Preapprovals" */ './Preapprovals'),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'Pre approved routes',
  menuIcon: LibraryAddCheckIcon,
  authorisation: {
    apiKey: 'AccessProductDocumentWithRelations.findById',
    policies: [
      {role: 'operationsAccessRead'},
      {role: 'operationsSpatialRead'},
      {role: 'partnerSpatialRead'},
    ] as AuthorisationPolicy[],
  },
  sections: [],
};
