import * as Types from '../../../../../../typeDefs/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccessProductDocumentsPartnerSearchQueryVariables = Types.Exact<{
  qs: Types.Scalars['String']['input'];
}>;


export type AccessProductDocumentsPartnerSearchQuery = { __typename?: 'Query', accessProductDocumentsPartnerSearch?: Array<{ __typename?: 'AccessProductDocumentViewPartnerPartial', id?: number | null, name?: string | null, networkId?: number | null, generalAccess?: boolean | null } | null> | null };


export const AccessProductDocumentsPartnerSearchDocument = gql`
    query accessProductDocumentsPartnerSearch($qs: String!) {
  accessProductDocumentsPartnerSearch(qs: $qs) {
    id
    name
    networkId
    generalAccess
  }
}
    `;

/**
 * __useAccessProductDocumentsPartnerSearchQuery__
 *
 * To run a query within a React component, call `useAccessProductDocumentsPartnerSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessProductDocumentsPartnerSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessProductDocumentsPartnerSearchQuery({
 *   variables: {
 *      qs: // value for 'qs'
 *   },
 * });
 */
export function useAccessProductDocumentsPartnerSearchQuery(baseOptions: Apollo.QueryHookOptions<AccessProductDocumentsPartnerSearchQuery, AccessProductDocumentsPartnerSearchQueryVariables> & ({ variables: AccessProductDocumentsPartnerSearchQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccessProductDocumentsPartnerSearchQuery, AccessProductDocumentsPartnerSearchQueryVariables>(AccessProductDocumentsPartnerSearchDocument, options);
      }
export function useAccessProductDocumentsPartnerSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccessProductDocumentsPartnerSearchQuery, AccessProductDocumentsPartnerSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccessProductDocumentsPartnerSearchQuery, AccessProductDocumentsPartnerSearchQueryVariables>(AccessProductDocumentsPartnerSearchDocument, options);
        }
export function useAccessProductDocumentsPartnerSearchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AccessProductDocumentsPartnerSearchQuery, AccessProductDocumentsPartnerSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccessProductDocumentsPartnerSearchQuery, AccessProductDocumentsPartnerSearchQueryVariables>(AccessProductDocumentsPartnerSearchDocument, options);
        }
export type AccessProductDocumentsPartnerSearchQueryHookResult = ReturnType<typeof useAccessProductDocumentsPartnerSearchQuery>;
export type AccessProductDocumentsPartnerSearchLazyQueryHookResult = ReturnType<typeof useAccessProductDocumentsPartnerSearchLazyQuery>;
export type AccessProductDocumentsPartnerSearchSuspenseQueryHookResult = ReturnType<typeof useAccessProductDocumentsPartnerSearchSuspenseQuery>;
export type AccessProductDocumentsPartnerSearchQueryResult = Apollo.QueryResult<AccessProductDocumentsPartnerSearchQuery, AccessProductDocumentsPartnerSearchQueryVariables>;