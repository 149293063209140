import React from 'react';

import {DSL_IconWrapper} from '../IconWrapper';
import {DSL_IconProps} from '../types';

const DSL_ForkRight = (props: DSL_IconProps) => {
  return (
    <DSL_IconWrapper
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          fill="none"
          viewBox="0 0 60 60"
          {...props}
        >
          <g clipPath="url(#a)">
            <path fill="#fff" d="M0 0h60v60H0z" />
            <path
              fill="#000"
              fillOpacity={0.3}
              fillRule="evenodd"
              d="m15.326 17.768 6.142 6.142a17.5 17.5 0 0 1 5.126 12.374V48h5V36.284a22.5 22.5 0 0 0-6.59-15.91l-6.143-6.142z"
              clipRule="evenodd"
            />
            <path
              fill="#000"
              fillRule="evenodd"
              d="M32.59 16.598v-5h13.107v13.107h-5v-4.772l-3.978 3.977a17.5 17.5 0 0 0-5.125 12.374V48h-5V36.284a22.5 22.5 0 0 1 6.59-15.91l3.776-3.776z"
              clipRule="evenodd"
            />
          </g>
          <defs>
            <clipPath id="a">
              <path fill="#fff" d="M0 0h60v60H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      {...props}
    />
  );
};

export default React.memo(DSL_ForkRight);
