import {
  DSP_FormTableColumn,
  DSP_FormTableColumnConfig,
  DSP_FormTableColumnConfigList,
  DSP_FormTableColumnGroup,
} from './types';

export function isFormTableColumnGroup<ItemType>(
  column: DSP_FormTableColumnConfig<ItemType>,
): column is DSP_FormTableColumnGroup<ItemType> {
  return !!(column as DSP_FormTableColumnGroup<ItemType>)?.columns;
}

export function flattenFormTableColumnConfig<ItemType>(
  columns: DSP_FormTableColumnConfigList<ItemType>,
): DSP_FormTableColumn<ItemType>[] {
  return columns.flatMap(column =>
    isFormTableColumnGroup(column) ? column.columns : column,
  );
}
