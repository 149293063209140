import React from 'react';

import {DSP_SummaryTableRendererProps} from '../SummaryTableRenderer';

import {DSP_SummaryAttachmentsRendererView} from './SummaryAttachmentsRenderer.View';
import {useSummaryAttachmentsRenderer} from './useSummaryAttachmentsRenderer';

export type DSP_SummaryAttachmentsRendererProps = Omit<
  DSP_SummaryTableRendererProps,
  'columns' | 'caption'
>;

export const DSP_SummaryAttachmentsRenderer = (
  props: DSP_SummaryAttachmentsRendererProps,
) => {
  const {blobUrl} = useSummaryAttachmentsRenderer();

  return <DSP_SummaryAttachmentsRendererView {...props} blobUrl={blobUrl} />;
};

export {
  //
  /** @deprecated use DSP_SummaryAttachmentsRendererProps instead*/
  DSP_SummaryAttachmentsRendererProps as SummaryAttachmentsRendererProps, //
  /** @deprecated use DSP_SummaryAttachmentsRenderer instead*/
  DSP_SummaryAttachmentsRenderer as SummaryAttachmentsRenderer,
};
