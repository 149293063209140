import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';

export default {
  path: '/regulators/:id',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "ManageRegulatorAccount" */ './ManageRegulatorAccount'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Manage regulator account',
  menuIcon: LocalShippingOutlinedIcon,
  authorisation: {
    apiKey: null,
    policies: [{role: 'operationsAccountRead'}] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Regulator accounts',
      to: '..',
    },
  ],
};
