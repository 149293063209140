import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/accessInstruments/:accessInstrumentId/accessProduct/:productId/accessProductApplication/:id',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "AccessProductApplicationDetails" */ 'containers-access/AccessProductApplication/AccessProductApplicationDetails/AccessProductApplicationDetails'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Manage consultation case',
  menuIcon: LocalShippingOutlinedIcon,
  authorisation: {
    apiKey: 'AccessProductApplicationWithRelations.create',
    policies: [
      {
        role: 'operationsSpatialRead',
      },
      {role: '$impersonator'},
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Access instruments',
      to: '../../../../..',
    },
    {
      content: 'Manage access instrument',
      to: '../../../..',
      state: {'Access instrument tabs': 'Reference vehicles'},
    },
    {
      content: 'Manage reference vehicle',
      to: '../..',
      state: {'Access Product Tabs': 'Consultation cases'},
    },
  ],
};
