import React from 'react';

import {DateTime} from 'luxon';

import {
  DSL_DatePicker,
  DSL_DatePickerProps,
  DSL_GridItem,
  DSL_GridItemProps,
} from 'components/DesignSystem/Library';

import {DSP_FieldProps} from '../types';

import {useDateField} from './useDateField';

export interface DSP_DateFieldProps
  extends Omit<
      DSL_DatePickerProps<DateTime>,
      'inputFormat' | 'views' | 'value' | 'onChange' | 'renderInput'
    >,
    Pick<DSL_GridItemProps, 'span'> {
  fieldRef: DSP_FieldProps['fieldRef'];
}

export const DSP_DateField = ({span, ...props}: DSP_DateFieldProps) => {
  const fieldProps = useDateField(props);

  return (
    <DSL_GridItem span={span}>
      <DSL_DatePicker<DateTime> {...fieldProps} />
    </DSL_GridItem>
  );
};

export {
  /** @deprecated use DSP_DateField instead*/
  DSP_DateField as DateField,
};
