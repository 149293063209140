import React, {ForwardedRef, forwardRef} from 'react';

import {FormControlLabel, FormControlLabelProps, Radio} from '@mui/material';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

export interface DSL_RadioOptionProps
  extends Omit<
    FormControlLabelProps,
    DSL_RestrictedVisualProps | 'control' | 'size'
  > {
  radioColor?: React.CSSProperties['color'];
  disabled?: boolean;
  checked?: boolean;
}

export const DSL_RadioOption = forwardRef(
  (
    {radioColor, ...props}: DSL_RadioOptionProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    return (
      <FormControlLabel
        control={
          <Radio
            ref={ref}
            sx={{
              color: radioColor,
              '&.Mui-checked': {
                color: radioColor,
              },
            }}
          />
        }
        {...props}
      />
    );
  },
);

DSL_RadioOption.displayName = 'DSL_RadioOption';
