import React from 'react';

import {DSL_IconWrapper} from '../IconWrapper';
import {DSL_IconProps} from '../types';

const DSL_Overview = (props: DSL_IconProps) => {
  return (
    <DSL_IconWrapper
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          fill="none"
          viewBox="0 0 24 24"
          {...props}
        >
          <path
            fill="#000"
            fillOpacity={0.54}
            fillRule="evenodd"
            d="M8.155 5.875a.48.48 0 0 0-.3.267c-.19.38-1.104 2.51-1.991 4.598l-.355.834a4.64 4.64 0 0 1 3.929.815V6.562c0-.3-.056-.458-.095-.531a.3.3 0 0 0-.118-.126c-.128-.073-.312-.087-.53-.087-.214 0-.387.006-.54.057m3.075 10.687h1.54a4.629 4.629 0 1 0 8.629-2.779l-.027-.062c-.09-.216-.222-.526-.38-.901l-1.182-2.791c-.869-2.044-1.815-4.254-2.038-4.7C17.105 3.995 15.752 4 15.339 4h-.055c-.217 0-.836-.002-1.41.327-.693.395-1.13 1.14-1.13 2.235v.744h-1.488v-.744c0-1.094-.437-1.84-1.13-2.235C9.553 3.998 8.934 4 8.717 4h-.055C8.25 4 6.895 3.995 6.228 5.33c-.223.445-1.17 2.655-2.038 4.699-.44 1.034-.866 2.042-1.182 2.791-.158.375-.29.685-.38.901l-.027.062a4.628 4.628 0 1 0 8.629 2.779m-7.09-1.804a2.81 2.81 0 1 0 .088-.156l-.068.164zm7.116-5.634v5.62h1.488v-5.62zm3.306 3.265V6.562c0-.3.056-.458.095-.531a.3.3 0 0 1 .118-.126c.128-.073.312-.087.53-.087.215 0 .387.006.54.057.118.04.218.102.3.267.19.38 1.104 2.51 1.992 4.598l.354.834a4.64 4.64 0 0 0-3.929.815m5.209 2.213a2.81 2.81 0 1 0 .088.156l-.02.008z"
            clipRule="evenodd"
          />
        </svg>
      }
      {...props}
    />
  );
};

export default React.memo(DSL_Overview);
