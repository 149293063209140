import React from 'react';

import {useSelector} from '@xstate/react';
import getError from 'utils/apollo/getError';

import {
  GlobalErrorAlert,
  GridItem,
  UseGridItemStyles,
} from 'components/DesignSystem/Library';

import {useFormService} from '../FormProvider';

export const DSP_FormServerError = ({span}: UseGridItemStyles) => {
  const service = useFormService();
  const submitError = useSelector(service, state =>
    state.matches('userInput.error'),
  );
  const apiError = useSelector(service, state => state.context.props.apiError);

  return submitError ? (
    <GridItem span={span}>
      <GlobalErrorAlert>{getError(apiError)?.message}</GlobalErrorAlert>
    </GridItem>
  ) : null;
};

export {
  //
  /** @deprecated use DSP_FormServerError instead*/
  DSP_FormServerError as FormServerError,
};
