import {
  schemaJsonPointerResolver,
  JSONSchemaRecord,
} from '@regulatory-platform/common-utils';
import {useSelector} from '@xstate/react';
import * as R from 'ramda';

import {useFormContext} from './index';

export type DSP_UseFieldSchema = {
  fieldRef: string;
};

export const useFieldSchema = ({
  fieldRef,
}: DSP_UseFieldSchema): JSONSchemaRecord => {
  const {service} = useFormContext();
  return useSelector(
    service,
    state => {
      const {schema} = state.context;
      return schemaJsonPointerResolver(fieldRef)(schema);
    },
    R.equals,
  );
};
