import React from 'react';

import {
  OutlinedButton,
  SubmitButton,
  ButtonBar,
  ButtonBarGroup,
  NextButton,
  PreviousButton,
  TextButton,
} from 'components/DesignSystem/Library';

export interface DSP_WizardFooterViewProps {
  loading?: boolean;
  isSubmitButtonVisible?: boolean;
  isPrintButtonVisible?: boolean;
  isSubmitButtonDisabled?: boolean;
  isPreviousButtonDisabled?: boolean;
  isSaveButtonDisabled?: boolean;
  isPrintDisabled?: boolean;
  onSubmitClick?: () => void;
  onNextClick?: () => void;
  onPreviousClick?: () => void;
  onCancelClick?: () => void;
  onSaveClick?: () => void;
  onPrintClick?: () => void;
  isCancelButtonVisible?: boolean;
  isCreate?: boolean;
}

export const DSP_WizardFooterView = ({
  loading,
  isSubmitButtonVisible,
  isPrintButtonVisible,
  isSubmitButtonDisabled,
  isPrintDisabled,
  isPreviousButtonDisabled,
  isSaveButtonDisabled,
  onNextClick,
  onSubmitClick,
  onPreviousClick,
  onCancelClick,
  onSaveClick,
  onPrintClick,
  isCancelButtonVisible,
  isCreate,
}: DSP_WizardFooterViewProps) => (
  <ButtonBar>
    <ButtonBarGroup>
      <PreviousButton
        disabled={isPreviousButtonDisabled}
        onClick={onPreviousClick}
      />
    </ButtonBarGroup>
    <ButtonBarGroup>
      {isCancelButtonVisible && (
        <TextButton onClick={onCancelClick}>Cancel</TextButton>
      )}
      {isPrintButtonVisible && (
        <OutlinedButton onClick={onPrintClick} disabled={isPrintDisabled}>
          Print
        </OutlinedButton>
      )}
      {!isCreate && (
        <OutlinedButton
          onClick={onSaveClick}
          disabled={isSaveButtonDisabled}
          loading={loading}
        >
          Save
        </OutlinedButton>
      )}
      {isSubmitButtonVisible ? (
        <SubmitButton onClick={onSubmitClick} disabled={isSubmitButtonDisabled}>
          Submit
        </SubmitButton>
      ) : (
        <NextButton onClick={onNextClick} />
      )}
    </ButtonBarGroup>
  </ButtonBar>
);

DSP_WizardFooterView.displayName = 'DSP_WizardFooterView';

export {
  //
  /** @deprecated use DSP_WizardFooterViewProps instead*/
  DSP_WizardFooterViewProps as WizardFooterViewProps, //
  /** @deprecated use DSP_WizardFooterView instead*/
  DSP_WizardFooterView as WizardFooterView,
};
