import React from 'react';

import {usePopover, DSL_AvatarProps} from 'components/DesignSystem/Library';

import {DSP_EntityAvatarWithName} from '../../../EntityAvatarWithName';
import {
  DSP_PartnerAccountEntityPopover,
  DSP_PartnerAccountEntityPopoverProps,
} from '../PartnerAccountEntityPopover';

export interface DSP_PartnerAccountEntityAvatarWithNameViewProps
  extends Omit<DSP_PartnerAccountEntityPopoverProps, 'open' | 'anchorEl'>,
    ReturnType<typeof usePopover<HTMLDivElement>>,
    Pick<DSL_AvatarProps, 'src'> {
  initials: string | undefined;
}

export const DSP_PartnerAccountEntityAvatarWithNameView = ({
  src,
  initials,
  title,
  isLoading,
  isPopoverOpen,
  anchorRef,
  openPopover,
  closePopover,
  subTitle,
  website,
}: DSP_PartnerAccountEntityAvatarWithNameViewProps) => {
  return (
    <DSP_EntityAvatarWithName
      isLoading={isLoading}
      name={title}
      anchorRef={anchorRef}
      openPopover={openPopover}
      closePopover={closePopover}
      avatarUrl={src}
      avatarTitle={title}
      avatarInitials={initials}
      popover={
        <DSP_PartnerAccountEntityPopover
          anchorEl={anchorRef.current}
          onMouseEnter={openPopover}
          onMouseLeave={closePopover}
          isLoading={isLoading}
          title={title}
          subTitle={subTitle}
          open={isPopoverOpen}
          website={website}
        />
      }
    />
  );
};

DSP_PartnerAccountEntityAvatarWithNameView.displayName =
  'DSP_PartnerAccountEntityAvatarWithNameView';
