import * as R from 'ramda';
import getFetchDataResult from 'utils/apollo/getFetchDataResult';
import {
  FormEventObject,
  FormMachineContext,
  FormRecord,
  StoreShared,
} from './types';

export default function getSharedDataFromEvent<T extends FormRecord>(
  context: FormMachineContext<T>,
  event: FormEventObject,
): FormMachineContext<T> {
  const sharedRef = event.fieldRef;
  const sharedData = getFetchDataResult(event.data);

  const {shared} = context;

  //add shared data
  let nextShared = R.defaultTo({}, shared) as StoreShared;
  if (event.flag === true) {
    const origShareData = R.defaultTo([], shared?.[sharedRef]);
    const nextSharedData = R.uniqBy(
      (rec: FormRecord) => rec.id,
      R.concat(origShareData as FormRecord[], sharedData as FormRecord[]),
    );
    nextShared = R.assoc(sharedRef, nextSharedData, nextShared) as StoreShared;
  } else {
    nextShared = R.assoc(sharedRef, sharedData, nextShared) as StoreShared;
  }
  const nextContext = context;
  nextContext.shared = nextShared;
  return nextContext;
}
