import {Palette} from '@mui/material';
import {OutcomeType} from 'containers-workflow/Shared/types';
import {WorkflowActivitySystemType} from 'containers-workflow/Shared/types';

export enum DSP_WorkflowActivityActionType {
  DRAFT = 'DRAFT',
  SUBMIT = 'SUBMIT',
  COMPLETE = 'COMPLETE',
  CLOSE = 'CLOSE',
  ARCHIVE = 'ARCHIVE',
  REOPEN = 'REOPEN',
  CREATE_AND_SUBMIT = 'CREATE_AND_SUBMIT',
  CREATE = 'CREATE',
  WITHDRAW = 'WITHDRAW',
  REPLY = 'REPLY',
  RESPONSE = 'RESPONSE',
}

export const workflowActivityTypeNameMap: {
  [DSP_Type in WorkflowActivitySystemType]?: string | undefined;
} = {
  [WorkflowActivitySystemType.TASK]: 'task',
  [WorkflowActivitySystemType.DECISION_TASK]: 'decision task',
  [WorkflowActivitySystemType.INFORMATION]: 'information request',
};

export const workflowActivityNotificationTypeMessageMap: {
  [DSP_Type in DSP_WorkflowActivityActionType]?: string[] | undefined;
} = {
  [DSP_WorkflowActivityActionType.CREATE]: ['created a new'],
  [DSP_WorkflowActivityActionType.CREATE_AND_SUBMIT]: ['created a new'],
  [DSP_WorkflowActivityActionType.COMPLETE]: ['marked the', 'as complete'],
  [DSP_WorkflowActivityActionType.WITHDRAW]: ['withdrew the'],
  [DSP_WorkflowActivityActionType.RESPONSE]: ['submitted a response to the'],
  [DSP_WorkflowActivityActionType.REPLY]: [
    'submitted a reply to a',
    'response',
  ],
};

export type DSP_OutcomeColorType =
  | keyof Pick<Palette, 'error' | 'success' | 'primary'>
  | undefined;

export const outcomeTypeColorMap: {
  [key in OutcomeType]?: DSP_OutcomeColorType;
} = {
  [OutcomeType.NEGATIVE]: 'error',
  [OutcomeType.POSITIVE]: 'success',
  [OutcomeType.NEUTRAL]: 'primary',
};
