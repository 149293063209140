import React, {useCallback} from 'react';

import {format} from 'date-fns';

import {
  BaseDateTimeRenderer,
  BaseDateTimeRendererProps,
} from 'components/DesignSystem/Library';

export type DSP_ShortDateRendererProps = Omit<
  BaseDateTimeRendererProps,
  'formatter'
>;

export const DSP_ShortDateRenderer = ({
  date,
  ...props
}: DSP_ShortDateRendererProps) => {
  const formatter = useCallback(() => format(date, 'dd/MM/yyyy'), [date]);

  return <BaseDateTimeRenderer date={date} formatter={formatter} {...props} />;
};

DSP_ShortDateRenderer.displayName = 'DSP_ShortDateRenderer';

export {
  //
  /** @deprecated use DSP_ShortDateRendererProps instead*/
  DSP_ShortDateRendererProps as ShortDateRendererProps, //
  /** @deprecated use DSP_ShortDateRenderer instead*/
  DSP_ShortDateRenderer as ShortDateRenderer,
};
