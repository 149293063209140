import {useMemo} from 'react';

import {useMediaQuery} from '@mui/material';
import {useTheme} from '@mui/material';
import {Breakpoint} from '@mui/system';

export function useCurrentBreakpoint() {
  const theme = useTheme();

  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));
  const isLg = useMediaQuery(theme.breakpoints.only('lg'));

  return useMemo(() => {
    let currentBreakpoint: Breakpoint;

    if (isXs) {
      currentBreakpoint = 'xs';
    } else if (isSm) {
      currentBreakpoint = 'sm';
    } else if (isMd) {
      currentBreakpoint = 'md';
    } else if (isLg) {
      currentBreakpoint = 'lg';
    } else {
      currentBreakpoint = 'xl';
    }

    return currentBreakpoint;
  }, [isLg, isMd, isSm, isXs]);
}
