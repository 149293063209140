import React, {ForwardedRef} from 'react';

import {DSL_BaseTypography, DSL_BaseTypographyProps} from '../BaseTypography';

export type DSL_CaptionProps = Omit<DSL_BaseTypographyProps, 'variant'>;

export const DSL_Caption = React.forwardRef(
  (props: DSL_CaptionProps, ref: ForwardedRef<HTMLElement>) => (
    <DSL_BaseTypography ref={ref} {...props} variant={'caption'} />
  ),
);

DSL_Caption.displayName = 'DSL_Caption';

export {
  //
  /** @deprecated use DSL_CaptionProps instead*/
  DSL_CaptionProps as CaptionProps, //
  /** @deprecated use DSL_Caption instead*/
  DSL_Caption as Caption,
};
