import {AbortController} from '@azure/abort-controller';
import {TransferProgressEvent} from '@azure/core-http';
import {
  BlobBeginCopyFromURLResponse,
  BlobDeleteResponse,
  BlobUploadCommonResponse,
  BlockBlobClient,
} from '@azure/storage-blob';
import {SasToken} from 'typeDefs/types';

import {downloadBlobItem, getBlockBlobClient} from './utils';

type useStorageResults = [DSP_StorageActions];

interface DSP_StorageActions {
  downloadBlobItem: (request: SasToken) => string;
  deleteFile: (request: SasToken) => Promise<BlobDeleteResponse>;
  deleteBlobItem: (request: SasToken) => Promise<BlobDeleteResponse>;
  uploadToBlobStorage: (
    file: Blob,
    request: SasToken,
    onProgressUpdate: (progress: number) => void,
    abortController: AbortController,
  ) => Promise<BlobUploadCommonResponse>;
  copyBlobToDestination: (
    fromRequest: SasToken,
    toRequest: SasToken,
  ) => Promise<BlobBeginCopyFromURLResponse>;
}

export default function useStorage(): useStorageResults {
  //NB: the server automatically deletes files when sending a delete action to onGenerateSASToken
  const deleteBlobItem = (request: SasToken): Promise<BlobDeleteResponse> => {
    const blockBlobClient = getBlockBlobClient(request);
    return blockBlobClient.delete();
  };

  const copyBlobToDestination = async (
    fromRequest: SasToken,
    toRequest: SasToken,
  ): Promise<BlobBeginCopyFromURLResponse> => {
    const sourceBlob = getBlockBlobClient(fromRequest);
    const desBlob = getBlockBlobClient(toRequest);
    const response = await desBlob.beginCopyFromURL(sourceBlob.url);
    return await response.pollUntilDone();
  };

  const uploadFile = (
    blockBlobClient: BlockBlobClient,
    file: Blob,
    onProgressUpdate: (progress: number) => void,
    abortController: AbortController,
  ): Promise<BlobUploadCommonResponse> => {
    return blockBlobClient.uploadData(file, {
      abortSignal: abortController.signal,
      onProgress: (progress: TransferProgressEvent): void => {
        onProgressUpdate(
          parseInt(((progress.loadedBytes / file.size) * 100).toString(), 10),
        );
      },
      blobHTTPHeaders: {
        blobContentType: file.type,
      },
    });
  };

  const uploadToBlobStorage = (
    file: Blob,
    request: SasToken,
    onProgressUpdate: (progress: number) => void,
    abortController: AbortController,
  ): Promise<BlobUploadCommonResponse> => {
    const blockBlobClient = getBlockBlobClient(request);
    return uploadFile(blockBlobClient, file, onProgressUpdate, abortController);
  };

  //NB: the server automatically deletes files when sending a delete action to onGenerateSASToken
  const deleteFile = (request: SasToken): Promise<BlobDeleteResponse> => {
    return deleteBlobItem({...request});
  };

  return [
    {
      downloadBlobItem,
      deleteFile,
      uploadToBlobStorage,
      copyBlobToDestination,
      deleteBlobItem,
    },
  ];
}
