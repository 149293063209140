import React, {ReactNode} from 'react';

import {DSL_BreakpointValues} from '../../../Layout/hooks/useCascadingBreakpointValue';
import {DSL_Option, DSL_RestrictedVisualProps} from '../../../types';
import {optionToLabel} from '../../utils/optionToLabel';
import {optionToValue} from '../../utils/optionToValue';
import {DSL_FormControl} from '../FormControl';
import {DSL_FormHelperText} from '../FormHelperText';

import {DSL_RadioCardGroup, DSL_RadioCardGroupProps} from './RadioCardGroup';
import {
  DSL_RadioCardOption,
  DSL_RadioCardOptionProps,
} from './RadioCardOption/RadioCardOption.Connector';

export type DSL_RadioCardGroupOptionDataMap<Options extends string> = {
  [Label in Options]?: {
    description?: ReactNode;
  };
};

export interface DSL_RadioCardGroupInputProps<Options extends string>
  extends Omit<
    DSL_RadioCardGroupProps,
    DSL_RestrictedVisualProps | 'ref' | 'columns'
  > {
  options?: readonly DSL_Option[];
  disabled?: boolean;
  error?: boolean;
  helperText?: ReactNode;
  columns?: DSL_BreakpointValues<number>;
  minColumnHeight?: DSL_RadioCardOptionProps['minHeight'];
  maxColumnHeight?: DSL_RadioCardOptionProps['maxHeight'];
  optionDataMap?: DSL_RadioCardGroupOptionDataMap<Options>;
}

export function DSL_RadioCardGroupInput<Options extends string>({
  options = [],
  helperText = '',
  id,
  error,
  value,
  columns,
  disabled,
  minColumnHeight,
  optionDataMap,
  ...radioGroupProps
}: DSL_RadioCardGroupInputProps<Options>) {
  return (
    <DSL_FormControl error={error}>
      <DSL_RadioCardGroup
        value={value}
        columns={columns ?? options?.length}
        {...radioGroupProps}
      >
        {options.map((option, index) => {
          const {isHighlighted, description} =
            typeof option === 'object'
              ? {
                  isHighlighted: value === option.value,
                  description:
                    optionDataMap?.[option.label as Options]?.description ??
                    option.description,
                }
              : {
                  isHighlighted: value === option,
                  description: optionDataMap?.[option as Options]?.description,
                };

          return (
            <DSL_RadioCardOption
              key={index}
              error={error}
              disabled={disabled}
              description={description}
              minHeight={minColumnHeight}
              value={optionToValue(option)}
              label={optionToLabel(option)}
              isHighlighted={isHighlighted}
            />
          );
        })}
      </DSL_RadioCardGroup>
      {helperText && <DSL_FormHelperText>{helperText}</DSL_FormHelperText>}
    </DSL_FormControl>
  );
}

DSL_RadioCardGroupInput.displayName = 'DSL_RadioCardGroupInput';
