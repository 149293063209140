import {
  DSL_SidebarMenuDrawer,
  DSL_SidebarMenuToolbar,
  DSL_SidebarMenuTopAndBottomLayout,
  DSL_SidebarMenuList,
  DSL_SidebarMenuDropdown,
  DSL_SidebarMenuItem,
  DSL_SidebarMenuCaption,
} from 'components/DesignSystem/Library';
import React, {useState} from 'react';
import {i18n} from 'containers-content';

export interface InternalLinks {
  id: string;
  menuTitle: string;
  icon: React.ReactNode;
  items?: {
    id: string;
    menuTitle: string;
    selected: boolean;
  }[];
}

export type SidebarViewProps = {
  internalLinks: InternalLinks[];
  handleClick: (id: string, href?: string) => void;
  pathname: string;
  isOpen: boolean;
  toggleIsOpen: () => void;
  year: React.ReactNode;
  version: React.ReactNode;
  releaseStartDate: React.ReactNode;
};

export const SidebarView: React.FC<SidebarViewProps> = ({
  internalLinks,
  handleClick,
  year,
  version,
  releaseStartDate,
  pathname,
  isOpen,
  toggleIsOpen,
}) => {
  const [selectedDropdown, setSelectedDropdown] = useState<string | null>(null);
  const [hasToggledDropdown, setHasToggledDropdown] = useState<boolean>(false);

  const handleDropdownClick = (id: string) => {
    setHasToggledDropdown(true);
    if (selectedDropdown == id) {
      setSelectedDropdown(null);
      return;
    }
    setSelectedDropdown(id);
  };

  return (
    <DSL_SidebarMenuDrawer open={isOpen} onClose={toggleIsOpen}>
      <DSL_SidebarMenuToolbar />
      <DSL_SidebarMenuTopAndBottomLayout
        topSection={
          <DSL_SidebarMenuList>
            {internalLinks.map(link => (
              <React.Fragment key={link.id}>
                {link?.items && link?.items.length > 0 ? (
                  <DSL_SidebarMenuDropdown
                    id={link.id}
                    text={link.menuTitle}
                    icon={link.icon}
                    onClick={() => handleDropdownClick(link.id)}
                    isOpen={
                      selectedDropdown == link.id ||
                      (!hasToggledDropdown && pathname.includes(link.id))
                    }
                  >
                    {link.items?.map(item => (
                      <DSL_SidebarMenuItem
                        key={item.id}
                        id={item.id}
                        text={item.menuTitle}
                        isSmall
                        selected={item.selected}
                        onClick={() => {
                          handleClick(item.id);
                          toggleIsOpen();
                        }}
                      />
                    ))}
                  </DSL_SidebarMenuDropdown>
                ) : (
                  <DSL_SidebarMenuItem
                    key={link.id}
                    id={link.id}
                    text={link.menuTitle}
                    icon={link.icon}
                    onClick={() => {
                      handleClick(link.id);
                      toggleIsOpen();
                    }}
                  />
                )}
              </React.Fragment>
            ))}
          </DSL_SidebarMenuList>
        }
        bottomSection={
          <DSL_SidebarMenuList>
            <DSL_SidebarMenuCaption
              text={
                <>
                  © {year} {i18n.get('GLOBAL_APP_NAME_SHORT')} V{version},
                  Released {releaseStartDate}
                </>
              }
            />
          </DSL_SidebarMenuList>
        }
      ></DSL_SidebarMenuTopAndBottomLayout>
    </DSL_SidebarMenuDrawer>
  );
};
