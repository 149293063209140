/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  AuthorisationSchema,
  AuthorizationDecision,
  CommonInvocationContext,
  CommonValidatorResult,
  JSONSchemaRecord,
  ObjectType,
} from '@regulatory-platform/common-utils';
import {ApolloError} from '@apollo/client';
import {AppServicesContextProps} from 'app/services/AppServicesContext';
import * as R from 'ramda';
import getFetchDataResult from 'utils/apollo/getFetchDataResult';
import {FormEventObject, SearchStoreMachineContext} from 'utils/stores/types';
import {assign, MachineOptions} from 'xstate';
import formMachineActions from './formMachineActions';

export type MachineActions = NonNullable<
  MachineOptions<SearchStoreMachineContext, FormEventObject>['actions']
>;

export default function getActions(
  baseSchema: JSONSchemaRecord,
  validator: (
    context: CommonInvocationContext,
    schema: JSONSchemaRecord,
    record: ObjectType,
  ) => CommonValidatorResult,
  actionVoter: (
    context: CommonInvocationContext,
    schema: AuthorisationSchema,
  ) => (action: string) => AuthorizationDecision,
  appServices: AppServicesContextProps,
): MachineActions {
  return R.mergeRight(
    formMachineActions(
      baseSchema,
      validator,
      actionVoter,
      appServices,
    ) as unknown as MachineActions,
    {
      onFormValueChange: assign({
        record: (context, event) => {
          const fieldValue = event.value as ObjectType;
          if (
            context.searchResultToString(fieldValue) !== context.record.search
          ) {
            return R.assoc(
              'search',
              context.searchResultToString(fieldValue),
              context.record,
            );
          }
          return context.record;
        },
        props: (context, event) => {
          return R.assoc('overrideTouched', event.flag, context.props);
        },
        storeFieldValue: (_context, event) => {
          return event.value;
        },
        storeFieldSchema: (context, event) => {
          if (R.isNil(event.schema)) return context.storeFieldSchema;
          return event.schema;
        },
        storeFieldSchema2: (context, event) => {
          if (R.isNil(event.schema2)) return context.storeFieldSchema2;
          return event.schema2;
        },
      }),
      onSearchSuccess: assign({
        data: (context, event) => {
          return R.concat(
            R.defaultTo([], context.fixedRecords),
            getFetchDataResult(event.data) as object[],
          );
        },
        props: context => R.assoc('apiError', undefined, context.props),
      }),
      onSearchError: assign({
        props: (context, event) =>
          R.assoc('apiError', event.data as ApolloError, context.props),
      }),
      onFindRelatedEntitySuccess: assign({
        relatedEntity: (context, event) =>
          getFetchDataResult(event.data) as object,
      }),
      onFindRelatedEntityError: assign({
        props: (context, event) =>
          R.assoc('apiError', event.data as ApolloError, context.props),
      }),
      onSelect: () => {
        //
      },
    } as MachineActions,
  );
}
