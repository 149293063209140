import {lazy} from 'react';
import WebAsset from '@mui/icons-material/WebAsset';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/applications/application/:permitApplicationId/vcbTestVehicles/vcbTestVehicle/:id',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "ManageAssetDetailAratTestVehicle" */ '../../../../../../containers-spatial/Assets/ManageAssetDetailAratTestVehicle/ManageAssetDetailAratTestVehicle'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Manage test vehicle',
  menuIcon: WebAsset,
  authorisation: {
    apiKey: 'AssetDetailAratTestVehicleWithRelations.findById',
    policies: [
      {
        role: 'operationsSpatialRead',
      },
      {
        role: 'partnerSpatialRead',
      },
    ] as AuthorisationPolicy[],
  },
};
