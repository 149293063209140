import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/approval-library/:approvalId/set/:id',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "VehicleUnitSetDetails" */ './VehicleUnitSetDetails'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Vehicle set',
  menuIcon: LocalShippingOutlinedIcon,
  authorisation: {
    apiKey: 'PbsVehicleUnitSetWithRelations.findById',
    policies: [
      {
        role: 'customerPBSRead',
      },
      {
        role: 'providerPBSRead',
      },
      {
        role: 'operationsPBSRead',
      },
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'PBS Approval Library',
      to: '../../..',
    },
    {
      content: 'Approval',
      to: '../..',
      state: {'Approval detail': 'Vehicle sets'},
    },
  ],
};
