import React from 'react';

import {DSL_BaseAlert, DSL_BaseAlertProps} from '../BaseAlert';

export type DSL_GlobalErrorAlertProps = Omit<DSL_BaseAlertProps, 'severity'>;

export const DSL_GlobalErrorAlert = (props: DSL_GlobalErrorAlertProps) => {
  return <DSL_BaseAlert severity="error" {...props} />;
};

DSL_GlobalErrorAlert.displayName = 'DSL_GlobalErrorAlert';

export {
  //
  /** @deprecated use DSL_GlobalErrorAlertProps instead*/
  DSL_GlobalErrorAlertProps as GlobalErrorAlertProps, //
  /** @deprecated use DSL_GlobalErrorAlert instead*/
  DSL_GlobalErrorAlert as GlobalErrorAlert,
};
