import React, {createContext, ReactNode, useMemo} from 'react';

import {useQuery} from '@apollo/client';
import {DocumentNode} from 'graphql';

import {useRequiredContext} from '../../utils/useRequiredContext';

export interface DSP_EntityLoaderContext<EntityType = Record<string, unknown>> {
  entity: EntityType | undefined | null;
  isLoading: boolean;
  isError: boolean;
}

const DSP_EntityLoaderContext = createContext<DSP_EntityLoaderContext>({
  entity: undefined,
  isError: false,
  isLoading: false,
});

interface EntityLoaderProviderProps {
  query: DocumentNode;
  variables: {id: number; [key: string]: unknown};
  children?: ReactNode;
}

export function DSP_EntityLoaderProvider<
  EntityType extends Record<string, unknown>,
>({query, variables, children}: EntityLoaderProviderProps) {
  const {loading, error, data} = useQuery(query, {
    variables,
    fetchPolicy: 'cache-first',
    errorPolicy: 'none',
  });

  const value = useMemo(() => {
    return {
      isError: !!error,
      isLoading: loading,
      entity: data ? (data[Object.keys(data)[0]] as EntityType) : undefined,
    };
  }, [data, error, loading]);

  return (
    <DSP_EntityLoaderContext.Provider value={value}>
      {children}
    </DSP_EntityLoaderContext.Provider>
  );
}

export function useEntityLoaderContext<
  EntityType extends Record<string, unknown>,
>() {
  return useRequiredContext(
    DSP_EntityLoaderContext,
    DSP_EntityLoaderProvider.displayName,
  ) as DSP_EntityLoaderContext<EntityType>;
}

DSP_EntityLoaderProvider.displayName = 'DSP_EntityLoaderProvider';
