import ListAlt from '@mui/icons-material/ListAlt';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/accessProductDocumentMedia/:id',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "ProductDocumentMediaDetails" */ './ProductDocumentMediaDetails'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Manage pre approval guide',
  menuIcon: ListAlt,
  authorisation: {
    apiKey: null,
    policies: [
      {
        role: 'operationsAccessRead',
      },
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Pre approval guides',
      to: '..',
    },
  ],
};
