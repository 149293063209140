/**
 * Experimental replacement for FormStoreManager. Not ready for general use!
 *
 * Known things we need to address which are ticketed:
 *
 * - [ ] ReadOnly Mode Support
 *    - [ ] CloseButtonStack
 *    - [ ] Render ReadOnly Component
 * - [ ] Create Mode
 *    - [ ] CreateOrCancelButtonStack
 * - [ ] Update Mode
 *    - [ ] UpdateOrCancelButtonStack
 * - [ ] Submission Redirect
 * - [ ] Route Blocker
 * - [ ] Form Save Dialog/Form Archive Dialog
 * - [ ] New PageContainer
 *   - [ ] New PageTitle
 */

import React, {ReactNode} from 'react';

import {DSP_FormProvider, DSP_FormProviderProps} from '../..';

import {DSP_FormPageContainerView} from './FormPageContainer.View';
import {
  DSP_UseFormPageContainerArgs,
  useFormPageContainer,
} from './useFormPageContainer';

export type DSP_FormPageContainerLayoutProps = DSP_UseFormPageContainerArgs;

/**
 * Use this component if you only need access to the display logic and you want to handle the FormProvider separately
 */
export const DSP_FormPageContainerLayout = ({
  title,
  mode,
  ...props
}: DSP_FormPageContainerLayoutProps) => {
  const viewProps = useFormPageContainer({title, mode});
  return <DSP_FormPageContainerView {...props} {...viewProps} />;
};

export interface DSP_FormPageContainerProps
  extends DSP_FormPageContainerLayoutProps,
    DSP_FormProviderProps {
  recordId?: string;
  hideCloseButton?: boolean;
  title?: ReactNode;
}

export const DSP_FormPageContainer = ({
  legacySupport,
  otherProps,
  recordId,
  service,
  canSave,
  isCreate,
  ...props
}: DSP_FormPageContainerProps) => {
  return (
    <DSP_FormProvider
      key={recordId}
      service={service}
      otherProps={otherProps}
      legacySupport={legacySupport}
      canSave={canSave}
      isCreate={isCreate}
    >
      <DSP_FormPageContainerLayout {...props} />
    </DSP_FormProvider>
  );
};

DSP_FormPageContainer.displayName = 'DSP_FormPageContainer';

export {
  //
  /** @deprecated use DSP_FormPageContainerLayout instead*/
  DSP_FormPageContainerLayout as FormPageContainerLayout, //
  /** @deprecated use DSP_FormPageContainerProps instead*/
  DSP_FormPageContainerProps as FormPageContainerProps, //
  /** @deprecated use DSP_FormPageContainer instead*/
  DSP_FormPageContainer as FormPageContainer,
};
