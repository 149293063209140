import React from 'react';

import * as R from 'ramda';

import {FlexRow, Chip} from 'components/DesignSystem/Library';

export type DSP_VehicleCardChipsProps = {
  tags?: string[];
};

export const DSP_VehicleCardChips = ({tags}: DSP_VehicleCardChipsProps) => {
  return (
    <FlexRow pt={1}>
      {R.defaultTo([] as string[], tags).map((tag, idx) => (
        <Chip key={idx} label={tag} variant="outlined" />
      ))}
    </FlexRow>
  );
};

export {
  //
  /** @deprecated use DSP_VehicleCardChipsProps instead*/
  DSP_VehicleCardChipsProps as VehicleCardChipsProps, //
  /** @deprecated use DSP_VehicleCardChips instead*/
  DSP_VehicleCardChips as VehicleCardChips,
};
