import React, {CSSProperties, ForwardedRef, forwardRef} from 'react';

import {
  CardActionArea as MuiCardActionArea,
  CardActionAreaProps as MuiCardActionAreaProps,
} from '@mui/material';

import {DSL_RestrictedVisualProps} from '../../../types';
import {DSL_Box} from '../../Box';

export interface DSL_BaseCardActionAreaProps
  extends Omit<MuiCardActionAreaProps, DSL_RestrictedVisualProps> {
  href?: string;
  maxWidth?: CSSProperties['maxWidth'];
  active?: boolean;
  width?: CSSProperties['width'];
  to?: string;
}

export const DSL_BaseCardActionArea = forwardRef(
  (
    {
      maxWidth = '100%',
      width,
      active,
      children,
      ...props
    }: DSL_BaseCardActionAreaProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    return (
      <DSL_Box position={'relative'} maxWidth={maxWidth} width={width}>
        <MuiCardActionArea
          ref={ref}
          sx={{
            zIndex: 1,
            position: 'absolute',
            inset: '0px',
            '&:hover': {
              bgcolor: 'action.hover',
            },
            ...(active ? {bgcolor: 'action.hover'} : {}),
          }}
          tabIndex={0}
          {...props}
        />
        {children}
      </DSL_Box>
    );
  },
);

DSL_BaseCardActionArea.displayName = 'DSL_BaseCardActionArea';

export {
  //
  /** @deprecated use DSL_BaseCardActionAreaProps instead*/
  DSL_BaseCardActionAreaProps as BaseCardActionAreaProps, //
  /** @deprecated use DSL_BaseCardActionArea instead*/
  DSL_BaseCardActionArea as BaseCardActionArea,
};
