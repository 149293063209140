import React from 'react';

import {Body2, Body2Props} from 'components/DesignSystem/Library';

type DSP_NotificationCardProps = Omit<Body2Props, 'color'>;

export const DSP_NotificationCardBody = (props: DSP_NotificationCardProps) => (
  <Body2 color={'text.secondary'} ellipsisLines={2} {...props} />
);

DSP_NotificationCardBody.displayName = 'DSP_NotificationCardBody';
