import * as Types from '../../../../../../../typeDefs/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProviderAccountsSearchQueryVariables = Types.Exact<{
  qs: Types.Scalars['String']['input'];
}>;


export type ProviderAccountsSearchQuery = { __typename?: 'Query', providerAccountsSearch?: Array<{ __typename?: 'ProviderAccountSearchPublic', id?: number | null, name?: string | null, providerTypes?: Array<string | null> | null } | null> | null };


export const ProviderAccountsSearchDocument = gql`
    query providerAccountsSearch($qs: String!) {
  providerAccountsSearch(qs: $qs) {
    id
    name
    providerTypes
  }
}
    `;

/**
 * __useProviderAccountsSearchQuery__
 *
 * To run a query within a React component, call `useProviderAccountsSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useProviderAccountsSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviderAccountsSearchQuery({
 *   variables: {
 *      qs: // value for 'qs'
 *   },
 * });
 */
export function useProviderAccountsSearchQuery(baseOptions: Apollo.QueryHookOptions<ProviderAccountsSearchQuery, ProviderAccountsSearchQueryVariables> & ({ variables: ProviderAccountsSearchQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProviderAccountsSearchQuery, ProviderAccountsSearchQueryVariables>(ProviderAccountsSearchDocument, options);
      }
export function useProviderAccountsSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProviderAccountsSearchQuery, ProviderAccountsSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProviderAccountsSearchQuery, ProviderAccountsSearchQueryVariables>(ProviderAccountsSearchDocument, options);
        }
export function useProviderAccountsSearchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProviderAccountsSearchQuery, ProviderAccountsSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProviderAccountsSearchQuery, ProviderAccountsSearchQueryVariables>(ProviderAccountsSearchDocument, options);
        }
export type ProviderAccountsSearchQueryHookResult = ReturnType<typeof useProviderAccountsSearchQuery>;
export type ProviderAccountsSearchLazyQueryHookResult = ReturnType<typeof useProviderAccountsSearchLazyQuery>;
export type ProviderAccountsSearchSuspenseQueryHookResult = ReturnType<typeof useProviderAccountsSearchSuspenseQuery>;
export type ProviderAccountsSearchQueryResult = Apollo.QueryResult<ProviderAccountsSearchQuery, ProviderAccountsSearchQueryVariables>;