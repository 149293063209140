import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';
import Today from '@mui/icons-material/Today';

export default {
  path: '/auditors',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "AuditorsOperations" */ './AuditorsOperations'
      ),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'Auditors',
  menuIcon: Today,
  authorisation: {
    apiKey: 'AccreditationAuditor.findOperations',
    policies: [{role: 'operationsAccreditationRead'}] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Accreditation',
      to: '../',
    },
  ],
};
