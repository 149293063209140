import {lazy} from 'react';
import WebAsset from '@mui/icons-material/WebAsset';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/vcbRefVehicles/create',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "NewAratRefVehicleGateway" */ './NewAratRefVehicleGateway'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Add VCB reference vehicle',
  menuIcon: WebAsset,
  authorisation: {
    apiKey: 'AratRefVehicleWithRelations.create',
    policies: [
      {
        role: 'operationsSpatialSupervisor',
      },
      {
        role: 'partnerSpatialSupervisor',
      },
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'VCB reference vehicles',
      to: '..',
    },
  ],
};
