import React from 'react';

import {DSL_IconWrapper} from '../IconWrapper';
import {DSL_IconProps} from '../types';

const DSL_Roundabout2 = (props: DSL_IconProps) => {
  return (
    <DSL_IconWrapper
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          fill="none"
          viewBox="0 0 60 60"
          {...props}
        >
          <g clipPath="url(#a)">
            <path fill="#fff" d="M0 0h60v60H0z" />
            <path
              fill="#000"
              fillOpacity={0.3}
              fillRule="evenodd"
              d="M30 21.5a8.5 8.5 0 1 0 0 17 8.5 8.5 0 0 0 0-17M16.5 30c0-7.456 6.044-13.5 13.5-13.5S43.5 22.544 43.5 30 37.456 43.5 30 43.5 16.5 37.456 16.5 30"
              clipRule="evenodd"
            />
            <path
              fill="#000"
              fillRule="evenodd"
              d="M14.351 27.683 9.52 28.966 6.154 16.3l12.668-3.364 1.283 4.832-4.26 1.131 4.15 2.382c2.247 1.289 2.694 3.927 2.005 5.843A8.5 8.5 0 0 0 21.5 30c0 2.05.724 3.926 1.932 5.394a8.5 8.5 0 0 0 5.029 2.967c1.996.365 4.04 2.064 4.04 4.639v8h-5v-7.732a13.5 13.5 0 0 1-7.931-4.698 13.46 13.46 0 0 1-3.07-8.57c0-1.58.273-3.102.776-4.517l-4.14-2.376z"
              clipRule="evenodd"
            />
          </g>
          <defs>
            <clipPath id="a">
              <path fill="#fff" d="M0 0h60v60H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      {...props}
    />
  );
};

export default React.memo(DSL_Roundabout2);
