import React from 'react';

import {
  RadioGroup as MuiRadioGroup,
  RadioGroupProps as MuiRadioGroupProps,
} from '@mui/material';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

export interface DSL_RadioGroupProps
  extends Omit<MuiRadioGroupProps, DSL_RestrictedVisualProps> {
  orientation?: 'vertical' | 'horizontal';
}

export const DSL_RadioGroup = ({
  orientation = 'vertical',
  ...props
}: DSL_RadioGroupProps) => {
  return (
    <MuiRadioGroup
      sx={{
        display: 'flex',
        gap: orientation === 'vertical' ? 0 : 2,
        flexDirection: orientation === 'vertical' ? 'column' : 'row',
      }}
      {...props}
    />
  );
};

DSL_RadioGroup.displayName = 'DSL_RadioGroup';

export {
  //
  /** @deprecated use DSL_RadioGroupProps instead*/
  DSL_RadioGroupProps as RadioGroupProps, //
  /** @deprecated use DSL_RadioGroup instead*/
  DSL_RadioGroup as RadioGroup,
};
