import React from 'react';

import {
  ListItemText as MuiListItemText,
  ListItemTextProps as MuiListItemTextProps,
} from '@mui/material';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

export type DSL_ListItemTextProps = Omit<
  MuiListItemTextProps,
  DSL_RestrictedVisualProps
> & {variant?: 'error'};

export const DSL_ListItemText = (props: DSL_ListItemTextProps) => {
  return (
    <MuiListItemText
      {...props}
      sx={theme => ({
        ...(props.variant === 'error' && {
          color: theme.palette.error.main,
        }),
      })}
    />
  );
};

DSL_ListItemText.displayName = 'DSL_ListItemText';

export {
  //
  /** @deprecated use DSL_ListItemTextProps instead*/
  DSL_ListItemTextProps as ListItemTextProps, //
  /** @deprecated use DSL_ListItemText instead*/
  DSL_ListItemText as ListItemText,
};
