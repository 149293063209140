export enum SegmentAccessType {
  APPROVED = 'networkApproved',
  APPROVED_WITH_CONDITION = 'networkApprovedWithCondition',
  RESTRICTED = 'networkRestricted',
  REMOVED = 'disabled',
  NO_ACCESS = 'networkNoAccess',
}

export enum SegmentAccessStatus {
  DRAFT = 'DRAFT',
  CURRENT = 'CURRENT',
}

export const segmentAccessTitleMap = {
  [SegmentAccessStatus.DRAFT]: {
    [SegmentAccessType.APPROVED]: 'Approve',
    [SegmentAccessType.APPROVED_WITH_CONDITION]: 'Approve with conditions',
    [SegmentAccessType.RESTRICTED]: 'Restrict',
    [SegmentAccessType.REMOVED]: 'No access defined',
    [SegmentAccessType.NO_ACCESS]: 'No access defined',
  },
  [SegmentAccessStatus.CURRENT]: {
    [SegmentAccessType.APPROVED]: 'Approved',
    [SegmentAccessType.APPROVED_WITH_CONDITION]: 'Approved with conditions',
    [SegmentAccessType.RESTRICTED]: 'Restricted',
    [SegmentAccessType.REMOVED]: 'No access defined',
    [SegmentAccessType.NO_ACCESS]: 'No access defined',
  },
};
