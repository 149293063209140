import {schemaJsonPointerResolver} from '@regulatory-platform/common-utils/dist';
import {useSelector} from '@xstate/react';
import {equals} from 'ramda';

import {useFormService} from '../FormProvider';
import {DSP_BooleanFieldProps, DSP_UseBooleanFieldProps} from '../hooks';
import useBooleanFieldProps from '../hooks/useBooleanFieldProps';

export type UseCheckboxCardFieldProps = DSP_UseBooleanFieldProps;

export function useCheckboxCardField(
  props: UseCheckboxCardFieldProps,
): DSP_BooleanFieldProps & {
  description: string | undefined;
} {
  const service = useFormService();
  const fieldProps = useBooleanFieldProps(props);

  const stateDerivedProps = useSelector(
    service,
    state => {
      const schemaRecord = schemaJsonPointerResolver(props.fieldRef)(
        state.context.schema,
      );

      return {
        description: schemaRecord.description,
      };
    },
    equals,
  );

  return {
    ...stateDerivedProps,
    ...fieldProps,
  };
}
