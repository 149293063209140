import {DSL_ChipProps} from 'components/DesignSystem/Library';

export function mapStatusToThemeColour(
  status: string | undefined | null,
): DSL_ChipProps['color'] {
  switch (status?.toLowerCase()) {
    case 'closed':
    case 'certify':
    case 'error':
    case 'deactivated':
    case 'invalid':
    case 'not applicable':
    case 'reject access':
    case 'rejected':
    case 'expired':
    case 'cancelled':
    case 'suspended':
    case 'hva expired':
    case 'insurance expired':
    case 'inactive':
    case 'unregistered':
    case 'not found':
    case 'does not conform':
    case 'not accredited - cancelled':
    case 'cancelled by nhvr':
    case 'not accredited - retired':
    case 'exited by participant':
    case 'not accredited - expired':
      return 'error';
    case 'active':
    case 'activated':
    case 'published':
    case 'issued':
    case 'success':
    case 'completed':
    case 'valid':
    case 'allow access':
    case 'current':
    case 'approved':
    case 'registered':
    case 'conforms':
    case 'accredited - complied provisional':
    case 'accredited - complied':
      return 'success';
    case 'on-hold':
    case 'warning':
    case 'withdrawn':
    case 'awaiting acceptance':
    case 'awaiting activation':
    case 'pending update':
    case 'pending':
    case 'not accredited - suspended':
      return 'warning';
    case 'assessment':
    case 'new':
      return 'primary';
    case 'archived':
    case 'draft':
    case 'excluded':
      return 'coolGrey';
    case 'submitted':
      return 'info';
    case 'information requested':
    case 'migrated':
      return 'secondary';

    default:
      return 'default';
  }
}
