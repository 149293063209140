import React from 'react';

import {DSL_PhoneIcon} from '../../Icons';
import {DSL_BaseIconInfo, DSL_BaseIconInfoProps} from '../BaseIconInfo';

export type DSL_PhoneIconInfoProps = Omit<DSL_BaseIconInfoProps, 'icon'>;

export const DSL_PhoneIconInfo = (props: DSL_PhoneIconInfoProps) => {
  return <DSL_BaseIconInfo icon={<DSL_PhoneIcon />} {...props} />;
};

DSL_PhoneIconInfo.displayName = 'DSL_PhoneIconInfo';

export {
  //
  /** @deprecated use DSL_PhoneIconInfoProps instead*/
  DSL_PhoneIconInfoProps as PhoneIconInfoProps, //
  /** @deprecated use DSL_PhoneIconInfo instead*/
  DSL_PhoneIconInfo as PhoneIconInfo,
};
