import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/providers/create',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "CreateProviderAccount" */ './CreateProviderAccount'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Add Service Provider account',
  menuIcon: LibraryAddCheckIcon,
  authorisation: {
    apiKey: null,
    policies: [{role: 'operationsAccountAdmin'}] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Service Provider accounts',
      to: '..',
    },
  ],
};
