import React from 'react';

import {
  CardContent as MuiCardContent,
  CardContentProps as MuiCardContentProps,
  Theme,
} from '@mui/material';

import {DSL_RestrictedVisualProps} from '../../../types';
import {DSL_BaseGrid, DSL_BaseGridProps} from '../../Grid';

type DSL_Sizes = Record<keyof Theme['sizes'] | 'NONE', number>;

export const sizes: DSL_Sizes = {
  NONE: 0,
  XS: 1,
  SM: 2,
  MD: 3,
  LG: 4,
  XL: 5,
} as const;

export type DSL_CardContentProps = {
  padding?: keyof typeof sizes;
  paddingX?: keyof typeof sizes;
  paddingY?: keyof typeof sizes;
  paddingBottom?: keyof typeof sizes;
  paddingTop?: keyof typeof sizes;
  spacing?: keyof typeof sizes;
  paddingOverride?: string;
} & Omit<MuiCardContentProps, 'variant' | DSL_RestrictedVisualProps> &
  Pick<
    DSL_BaseGridProps,
    | 'overflowX'
    | 'overflowY'
    | 'maxWidth'
    | 'width'
    | 'minHeight'
    | 'maxHeight'
    | 'height'
    | 'gap'
  >;

export const DSL_CardContent = ({
  padding = 'LG',
  paddingX,
  paddingY,
  paddingBottom,
  paddingTop,
  spacing = 'LG',
  children,
  overflowX,
  overflowY,
  maxWidth = '100%',
  width,
  height,
  minHeight,
  maxHeight,
  gap,
  ...props
}: DSL_CardContentProps) => {
  return (
    <MuiCardContent
      data-name={'card-content'}
      sx={{p: 0, ['&:last-child']: {pb: 0}}}
      {...props}
    >
      <DSL_BaseGrid
        p={sizes[padding]}
        px={paddingX && sizes[paddingX]}
        py={paddingY && sizes[paddingY]}
        pb={paddingBottom && sizes[paddingBottom]}
        pt={paddingTop && sizes[paddingTop]}
        columns={1}
        maxWidth={maxWidth}
        spacingUnit={sizes[spacing]}
        overflowX={overflowX}
        overflowY={overflowY}
        width={width}
        height={height}
        minHeight={minHeight}
        maxHeight={maxHeight}
        gap={gap}
      >
        {children}
      </DSL_BaseGrid>
    </MuiCardContent>
  );
};

DSL_CardContent.displayName = 'DSL_CardContent';

export {
  //
  /** @deprecated use DSL_CardContentProps instead*/
  DSL_CardContentProps as CardContentProps, //
  /** @deprecated use DSL_CardContent instead*/
  DSL_CardContent as CardContent,
};
