import React from 'react';

import {DSL_BaseEmptyState, DSL_BaseEmptyStateProps} from '../BaseEmptyState';

import {DSL_RingingBellIcon} from './RingingBellIcon';

export type DSL_RingingBellEmptyStateProps = Omit<
  DSL_BaseEmptyStateProps,
  'icon'
>;

export const DSL_RingingBellEmptyState = (
  props: DSL_RingingBellEmptyStateProps,
) => {
  return <DSL_BaseEmptyState icon={<DSL_RingingBellIcon />} {...props} />;
};

DSL_RingingBellEmptyState.displayName = 'DSL_RingingBellEmptyState';

export {
  //
  /** @deprecated use DSL_RingingBellEmptyStateProps instead*/
  DSL_RingingBellEmptyStateProps as RingingBellEmptyStateProps, //
  /** @deprecated use DSL_RingingBellEmptyState instead*/
  DSL_RingingBellEmptyState as RingingBellEmptyState,
};
