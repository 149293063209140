import * as React from 'react';

import {schemaJsonPointerResolver} from '@regulatory-platform/common-utils';

import {SelectInputProps, Option} from 'components/DesignSystem/Library';

import {useFormService} from '../FormProvider';
import {useFormFieldPropsSelector} from '../hooks/useFormFieldPropsSelector';
import {DSP_FieldProps} from '../types';
import {getArrayFieldProps} from '../utils/getArrayFieldProps';

interface DSP_UseSingleSelectField extends DSP_FieldProps, SelectInputProps {
  options?: readonly Option[];
  recordsLoaderRef?: string;
  includeOptionalSuffix?: boolean;
}

export function useSingleSelectField({
  fieldRef,
  options,
  recordsLoaderRef,
  label,
  hideLabel,
  onBlurEvent,
  onChangeEvent,
  includeOptionalSuffix,
  ...propsOverrides
}: DSP_UseSingleSelectField): SelectInputProps {
  const service = useFormService();

  return useFormFieldPropsSelector(fieldRef, service, state => {
    const {options: fieldOptions, ...fieldProps} = getArrayFieldProps<
      false,
      false,
      false
    >({
      fieldRef,
      state,
      label,
      hideLabel,
      includeOptionalSuffix,
      items: options,
    });

    // Note: in the schema for enum fields, there's a major difference between an enum value of empty string vs null.
    // An empty string denotes that the user can select the value from the list.  Null means that you can submit a null
    // value, but we shouldn't show it in the dropdown. This is inconsistent from other array fields that don't follow
    // this convention.
    const fieldOptionsFiltered = fieldOptions.filter(option => option !== null);

    const isLoading =
      recordsLoaderRef &&
      state.context.actors[recordsLoaderRef]?.state.matches('loading');

    const {schema} = state.context;
    const fieldSchema = schemaJsonPointerResolver(fieldRef)(schema);
    const fieldType = fieldSchema.type;

    return {
      ...fieldProps,
      ...propsOverrides,
      options: fieldOptionsFiltered,
      value: fieldProps.value || '',
      loading: isLoading,
      onChange: (e: React.ChangeEvent<HTMLInputElement>): void => {
        if (fieldType === 'boolean') {
          service.send({
            type: 'CHANGEBLUR',
            fieldRef,
            value: e?.target?.value === 'true',
          });
        } else {
          service.send({
            type: 'CHANGEBLUR',
            fieldRef,
            value: e?.target?.value,
          });
        }
        onChangeEvent?.();
      },
      onBlur: (): void => {
        service.send({type: 'BLUR', fieldRef});
        onBlurEvent?.();
      },
      onOpen: (): void => {
        if (recordsLoaderRef) {
          service.send('TRIGGER_ACTOR_LOAD', {
            fieldRef: recordsLoaderRef,
          });
        }
      },
    } as SelectInputProps;
  }) as SelectInputProps;
}
