import {UserProfile} from '@regulatory-platform/common-utils';
import * as R from 'ramda';
import {getEnvironment, isUATOrProduction} from './global';

export function devConsoleLog(message: string): void {
  if (isUATOrProduction()) {
    return;
  }
  window.appInsights.devConsoleLog(message);
}

export function setAuthenticatedUserContext(
  user: UserProfile,
  disableIntercom = false,
): void {
  const activeAccount = user.accountType;
  const accountId = user.accountId;
  const authenticatedUserId = user.id + '';
  const activeAccountTypeAndId = activeAccount + '-' + accountId;
  const account = {
    name: user.accountName,
  };
  const roles = R.defaultTo([], user.roles);

  if (!R.isNil(window.appInsights)) {
    window.appInsights.setAuthenticatedUserContext(
      authenticatedUserId,
      activeAccountTypeAndId,
    );
  }

  window.dataLayer.push({
    user_id: authenticatedUserId,
  });
  window.dataLayer.push({
    account_type: activeAccount,
    account_id: accountId,
    account_name: account.name,
    role: R.find(role => role.indexOf('Account') > -1, roles),
    role_access: R.find(role => role.indexOf('Account') > -1, roles),
    role_accreditation: R.find(role => role.indexOf('Account') > -1, roles),
    role_registration: R.find(role => role.indexOf('Account') > -1, roles),
    role_pbs: R.find(role => role.indexOf('Account') > -1, roles),
    role_vehicle: R.find(role => role.indexOf('Account') > -1, roles),
  });
  if (disableIntercom !== true) {
    window.Intercom('boot', {
      app_id: window.globalEnvVariables.connectors.remoteIntercomAPI.key,
      firstName: user.firstName,
      lastName: user.name,
      name: user.firstName + ' ' + user.name,
      email: user.email,
      user_id: user.id,
      user_hash:
        !R.isNil(user.thirdPartyTokens) &&
        !R.isNil(user.thirdPartyTokens.length > 0)
          ? user.thirdPartyTokens[0].token
          : '',
      role: R.find(role => role.indexOf('Account') > -1, roles),
      roleAccess: R.find(role => role.indexOf('Account') > -1, roles),
      roleAccreditation: R.find(role => role.indexOf('Account') > -1, roles),
      roleRegistration: R.find(role => role.indexOf('Account') > -1, roles),
      rolePBS: R.find(role => role.indexOf('Account') > -1, roles),
      roleVehicle: R.find(role => role.indexOf('Account') > -1, roles),
      company: {
        id: activeAccountTypeAndId,
        name: account.name,
        accountType: activeAccount,
      },
    });
  }
}

export function clearAuthenticatedUserContext(): void {
  if (!R.isNil(window.appInsights)) {
    window.appInsights.clearAuthenticatedUserContext();
  }
  window.Intercom('shutdown');
}

export function trackPageView(
  name: string,
  url: string,
  measurements?: string,
  duration?: number,
): void {
  if (!R.isNil(window.appInsights)) {
    window.appInsights.trackPageView(
      name,
      url,
      getEnvironment(),
      measurements,
      duration,
    );
  }
  window.Intercom('update', {
    last_request_at: parseInt('' + new Date().getTime() / 1000, 10),
  });
}

export function trackException(
  exception: string,
  handledAt: string,
  measurements: string,
): void {
  if (!R.isNil(window.appInsights)) {
    window.appInsights.trackException(
      JSON.stringify(R.defaultTo({}, exception)),
      handledAt,
      getEnvironment(),
      measurements,
    );
  }
  window.dataLayer.push({
    exception:
      R.defaultTo('', handledAt) +
      ' ' +
      JSON.stringify(R.defaultTo({}, exception)),
    fatal: true,
  });
}

export function trackEvent(
  eventCategory: string,
  eventAction: string,
  eventLabel: string,
  eventObject: object,
): void {
  if (!R.isNil(window.appInsights)) {
    window.appInsights.trackEvent(
      eventCategory + '-' + eventAction + '-' + eventLabel,
      getEnvironment(),
      JSON.stringify(eventObject),
    );
  }
  window.dataLayer.push({
    event: eventCategory,
    eventAction,
    eventLabel,
    ...eventObject,
  });
}

export function trackSelectContentEvent(
  content_type: string,
  content_id: string,
  item_name?: string,
  item_category?: string,
  item_category2?: string,
): void {
  window.dataLayer.push({
    event: 'select_content',
    content_type,
    content_id,
    item_name,
    item_category,
    item_category2,
  });
}

export function trackSelectItemEvent(
  item_list_id: string,
  item_list_name: string,
  item: {
    item_id: string;
    item_name: string;
    item_category?: string;
    item_category2?: string;
    item_category3?: string;
    item_variant?: string;
    location_id?: string;
  },
): void {
  window.dataLayer.push({ecommerce: null}); // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: 'select_item',
    ecommerce: {item_list_id, item_list_name, items: [item]},
  });
}

export function trackMetric(
  name: string,
  average: number,
  sampleCount: number,
  min: number,
  max: number,
): void {
  if (!R.isNil(window.appInsights)) {
    window.appInsights.trackMetric(
      name,
      average,
      sampleCount,
      min,
      max,
      getEnvironment(),
    );
  }
}

export function trackTransaction(
  transactionId: string,
  service: string,
  paymentType: string,
  price: number,
  _lineItems: {
    name: string;
    productItemCodeId: string;
    productId: number;
    rate: number;
    qty: number;
  }[],
): void {
  if (!R.isNil(window.appInsights)) {
    window.appInsights.trackEvent(
      transactionId + '-' + service + '-' + paymentType + '-' + price,
      getEnvironment(),
    );
  }
  const lineItems = R.defaultTo([], _lineItems);
  const lineItemsLength = lineItems.length;
  window.dataLayer.push({ecommerce: null}); // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: 'purchase',
    ecommerce: {
      affiliation: service, // Affiliation or store name.
      currency: 'AUD',
      items: R.map(
        lineItem => ({
          affiliation: service, // Affiliation or store name.
          currency: 'AUD',
          item_id: lineItem.productItemCodeId, // SKU/code.
          item_name: lineItem.name, // Product name. Required.
          item_category: lineItem.productId + '', // Category or variation.
          price: lineItem.rate, // Unit price.
          quantity: lineItem.qty, // Quantity.
        }),
        lineItems,
      ),
      transaction_id: transactionId, // Transaction ID. Required.
      shipping: 0, // Shipping.
      value: price, // Grand Total.
      tax: 0, // Tax.
    },
  });

  window.Intercom('trackEvent', 'Submitted an application for ' + service, {
    transactionId: transactionId,
    paymentType: paymentType,
    price: price,
    lineItem1Name: lineItemsLength < 1 ? null : lineItems[0].name,
    lineItem1Qty: lineItemsLength < 1 ? null : lineItems[0].qty,
    lineItem2Name: lineItemsLength < 2 ? null : lineItems[1].name,
    lineItem2Qty: lineItemsLength < 2 ? null : lineItems[1].qty,
    lineItem3Name: lineItemsLength < 3 ? null : lineItems[2].name,
    lineItem3Qty: lineItemsLength < 3 ? null : lineItems[2].qty,
  });
}

// Custom launcher function for Intercom messenger
export function launchIntercom(): void {
  const element = document.querySelector('#intercom_launcher');

  if (element) {
    element.dispatchEvent(
      new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      }),
    );
  }
}

export function launchIntercomHome(): void {
  launchIntercom();
  window.Intercom('showSpace', 'home');
}

export function launchIntercomMessages(): void {
  launchIntercom();
  window.Intercom('showSpace', 'messages');
}

export function launchIntercomNews(): void {
  launchIntercom();
  window.Intercom('showSpace', 'news');
}

export function launchIntercomHelp(): void {
  launchIntercom();
  window.Intercom('showSpace', 'help');
}

export function launchIntercomFeedback(): void {
  launchIntercom();
  window.Intercom('showNewMessage');
}

// Custom launcher function for Intercom national map tour
export function launchIntercomMapTour(): void {
  window.Intercom('startTour', window._productTours.nationalMap);
}
