import {JSONSchemaRecord} from '@regulatory-platform/common-utils';
import * as R from 'ramda';
import getLabel from './getLabel';

export default function getErrorMessage(
  fieldSchema: JSONSchemaRecord,
  fieldLabel?: string,
): string {
  const validSchema = fieldSchema['x-valid'];
  // const errorMessage = R.join(', ', R.defaultTo([], validSchema?.messages));
  const errorMessage = R.head(
    R.defaultTo([''], validSchema?.messages),
  ) as string;
  const title = fieldLabel ?? getLabel(fieldSchema);
  return errorMessage?.charAt(0) === errorMessage?.charAt(0).toUpperCase()
    ? errorMessage
    : title + ' ' + errorMessage;
}
