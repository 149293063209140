import React, {ReactNode} from 'react';

import {
  DSL_CircularProgress,
  DSL_FlexColumn,
} from 'components/DesignSystem/Library';

export interface ImportFromCsvLoaderProps {
  isLoading: boolean;
  children?: ReactNode;
}

export const ImportFromCsvLoader = ({
  isLoading,
  children,
}: ImportFromCsvLoaderProps) => {
  return (
    <>
      {isLoading ? (
        <DSL_FlexColumn
          flex={1}
          alignItems={'center'}
          p={5}
          justifyContent={'center'}
        >
          <DSL_CircularProgress size={32} color={'primary'} />
        </DSL_FlexColumn>
      ) : (
        children
      )}
    </>
  );
};

ImportFromCsvLoader.displayName = 'ImportFromCsvLoader';
