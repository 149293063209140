import React from 'react';

import {TabStep} from 'components/navigation/types';

import {
  DSL_Box,
  DSL_Step,
  DSL_StepLabel,
  DSL_Stepper,
  DSL_StepperProps,
} from 'components/DesignSystem/Library';

export interface DSP_WizardTabStep extends TabStep {
  optional?: boolean;
  error?: boolean;
  skipped?: boolean;
  completed?: boolean;
  subLabel?: string;
}

export interface DSP_WizardStepperProps {
  activeStep: number;
  tabSteps: DSP_WizardTabStep[];
  onStepChange: (label: string) => void;
  orientation: DSL_StepperProps['orientation'];
}

export const DSP_WizardStepper = ({
  activeStep,
  tabSteps,
  onStepChange,
  orientation,
}: DSP_WizardStepperProps) => (
  <DSL_Box
    maxWidth={orientation === 'vertical' ? 430 : undefined}
    px={orientation === 'vertical' ? 3 : undefined}
    flexShrink={orientation === 'vertical' ? 0 : undefined}
  >
    <DSL_Stepper activeStep={activeStep} orientation={orientation}>
      {tabSteps.map((step, index) => {
        return (
          <DSL_Step key={index} completed={step?.completed}>
            <DSL_StepLabel
              error={step?.error}
              optional={step?.optional}
              subLabel={step?.subLabel}
              activeStep={index === activeStep}
              label={step?.label}
              onClick={(): void => onStepChange(step?.label)}
              subContentJustifyContent={
                orientation === 'vertical' ? 'flex-start' : 'center'
              }
            ></DSL_StepLabel>
          </DSL_Step>
        );
      })}
    </DSL_Stepper>
  </DSL_Box>
);

DSP_WizardStepper.displayName = 'DSP_WizardStepper';

export {
  //
  /** @deprecated use DSP_WizardStepperProps instead*/
  DSP_WizardStepperProps as WizardStepperProps, //
  /** @deprecated use DSP_WizardStepper instead*/
  DSP_WizardStepper as WizardStepper,
};
