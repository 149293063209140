import React from 'react';

import {
  FormControlLabel,
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
} from '@mui/material';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

import {DSL_FormControl} from '../FormControl';
import {DSL_FormHelperText} from '../FormHelperText';

export interface DSL_SwitchInputProps
  extends Omit<MuiSwitchProps, DSL_RestrictedVisualProps | 'inputProps'> {
  label?: React.ReactNode;
  helperText?: React.ReactNode;
  error?: boolean;
}

export const DSL_SwitchInput = ({
  label,
  helperText,
  error,
  ...switchProps
}: DSL_SwitchInputProps) => {
  return (
    <DSL_FormControl error={error}>
      <FormControlLabel
        control={
          <MuiSwitch
            inputProps={{'aria-label': 'controlled'}}
            {...switchProps}
          />
        }
        label={label}
      />
      {helperText && <DSL_FormHelperText>{helperText}</DSL_FormHelperText>}
    </DSL_FormControl>
  );
};

DSL_SwitchInput.displayName = 'DSL_SwitchInput';

export {
  //
  /** @deprecated use DSL_SwitchInputProps instead*/
  DSL_SwitchInputProps as SwitchInputProps, //
  /** @deprecated use DSL_SwitchInput instead*/
  DSL_SwitchInput as SwitchInput,
};
