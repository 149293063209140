import {ReactNode} from 'react';

import {JSONSchemaRecord} from '@regulatory-platform/common-utils';
import {schemaJsonPointerResolver} from '@regulatory-platform/common-utils/dist';
import * as R from 'ramda';
import {getPureLabel} from 'utils/schema/getLabel';

import {DSL_TableCellProps} from 'components/DesignSystem/Library';

import {useFieldSchema, useFieldValue} from '../../FormProvider';
import {DSP_SummaryItemRendererProps} from '../SummaryItemRenderer';

export interface DSP_SummaryTableColumnGroup {
  group: string;
  columns: DSP_SummaryTableColumn[];
}

export interface DSP_SummaryTableColumn
  extends DSP_SummaryItemRendererProps,
    Omit<DSL_TableCellProps, 'variant'> {
  label?: string;
}

export type DSP_SummaryTableColumnConfig =
  | DSP_SummaryTableColumnGroup
  | DSP_SummaryTableColumn;

export interface DSP_UseSummaryTableRendererArgs {
  fieldRef: string;
  ariaLabel?: string;
  columns?: DSP_SummaryTableColumnConfig[];
  caption?:
    | string
    | ((tableValue: object[], tableSchema: JSONSchemaRecord) => ReactNode);
}

interface DSP_SummaryTableCell
  extends DSP_SummaryItemRendererProps,
    Pick<DSL_TableCellProps, 'width' | 'minWidth'> {}

type DSP_SummaryTableRow = DSP_SummaryTableCell[];

/**
 * Generate the columns automatically from the schema.
 * Unlikely any record will work exactly from this but it's very useful during development.
 */
const getDefaultColumns = (
  tableItemsSchema: JSONSchemaRecord,
): DSP_SummaryTableColumn[] => {
  const columns = tableItemsSchema?.properties || {};

  return Object.keys(columns).map(columnName => ({
    fieldRef: `#/${columnName}`,
  }));
};

const fieldRefRelativeToAbsolute = (fieldRef: string, prefix: string) =>
  fieldRef.replace(/^#\//, `${prefix}/`);

export const useSummaryTableRenderer = ({
  ariaLabel,
  fieldRef,
  columns,
  caption,
}: DSP_UseSummaryTableRendererArgs) => {
  const tableSchema = useFieldSchema({fieldRef});
  const tableItemsSchema = tableSchema['x-items'] as JSONSchemaRecord;
  const tableValue = useFieldValue(fieldRef) as object[];

  ariaLabel ??= tableItemsSchema['x-title'] ?? tableItemsSchema.title;

  const renderedCaption = R.is(Function, caption)
    ? caption(tableValue, tableSchema)
    : caption;

  const columnsToRender = columns || getDefaultColumns(tableItemsSchema);
  const {groups, flatColumns} = parseColumnConfig(columnsToRender);

  const headers = flatColumns.map(column => {
    const fieldSchema = schemaJsonPointerResolver(column.fieldRef)(
      tableItemsSchema,
    );
    return {
      label: getPureLabel(fieldSchema),
      ...column,
    };
  }) as DSP_SummaryTableColumn[];

  const rows = (tableValue?.map((tableRowData, index) => {
    return flatColumns.map(column => ({
      ...column,
      fieldRef: fieldRefRelativeToAbsolute(
        column.fieldRef,
        `${fieldRef}/${index}`,
      ),
    }));
  }) || []) as DSP_SummaryTableRow[];

  return {
    rows,
    headers,
    ariaLabel,
    columnsToRender,
    groupCount: groups.length,
    caption: renderedCaption,
  };
};

function parseColumnConfig(columnConfigs: DSP_SummaryTableColumnConfig[]) {
  const groups = columnConfigs.filter(isSummaryTableColumnGroup);
  const flatColumns = columnConfigs.flatMap(column =>
    isSummaryTableColumnGroup(column) ? column.columns : column,
  );

  return {
    groups,
    flatColumns,
  };
}

export function isSummaryTableColumnGroup(
  config: unknown,
): config is DSP_SummaryTableColumnGroup {
  return !!(config as DSP_SummaryTableColumnGroup)?.columns;
}
