import {useSelector} from '@xstate/react';
import {useFormContext} from 'components/DesignSystem/Portal/Forms/FormProvider';
import * as R from 'ramda';

export const useFormSaveDialog = () => {
  const {service} = useFormContext();
  return useSelector(
    service,
    state => {
      const isSavingToArchive =
        state.matches('userInput.archived') || state.matches('archiving');
      return {
        isSavingToArchive,
      };
    },
    R.equals,
  );
};
