import React from 'react';

import {DSL_FlexColumn} from 'components/DesignSystem/Library/Layout/FlexColumn';
import {DSL_FlexRow} from 'components/DesignSystem/Library/Layout/FlexRow';
import {DSL_Body1} from 'components/DesignSystem/Library/Typography/Body1';
import {DSL_Caption} from 'components/DesignSystem/Library/Typography/Caption';

import {DSL_BaseSummaryItemProps} from './BaseSummaryItem';

export type DSL_BaseSummaryItemHorizontalProps = DSL_BaseSummaryItemProps;

export const DSL_BaseSummaryItemHorizontal = ({
  children,
  label,
  suffix,
  ellipsisLines = 1,
  emptyState = '-',
  isSecondary = false,
  labelWidth = 300,
}: DSL_BaseSummaryItemHorizontalProps) => {
  const color = isSecondary ? 'text.secondary' : 'text.primary';
  return (
    <>
      <DSL_FlexRow gap={0.5} alignItems={'baseline'}>
        {label && (
          <DSL_FlexColumn gap={0.5} width={labelWidth}>
            <DSL_Caption color={'text.secondary'}>{label}</DSL_Caption>
          </DSL_FlexColumn>
        )}
        <DSL_FlexColumn gap={0.5}>
          <DSL_FlexRow gap={0.5} alignItems={'baseline'}>
            <DSL_Body1
              color={color}
              component={'div'}
              ellipsisLines={ellipsisLines}
              title={typeof children === 'string' ? children : undefined}
            >
              {children ?? emptyState}
            </DSL_Body1>
            {suffix && (
              <DSL_Caption color={'text.secondary'}>{suffix}</DSL_Caption>
            )}
          </DSL_FlexRow>
        </DSL_FlexColumn>
      </DSL_FlexRow>
    </>
  );
};

DSL_BaseSummaryItemHorizontal.displayName = 'DSL_BaseSummaryItemHorizontal';
