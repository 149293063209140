import {lazy} from 'react';
import WebAsset from '@mui/icons-material/WebAsset';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/assets/:assetDetailId/vcbTestVehicle/create',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "NewAssetDetailAratRefVehicleGateway" */ './NewAssetDetailAratTestVehicleGateway'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Add new asset test vehicle',
  menuIcon: WebAsset,
  authorisation: {
    apiKey: 'AssetDetailAratTestVehicleWithRelations.create',
    policies: [
      {
        role: 'operationsSpatialUser',
      },
      {
        role: 'partnerSpatialUser',
      },
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Structures',
      to: '../../..',
    },
    {
      content: 'Manage structure',
      to: '../..',
      state: {'Manage structure': 'Test Vehicles'},
    },
  ],
};
