import {lazy} from 'react';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/applications/application/:id',
  component: lazy(
    () => import(/* webpackChunkName: "NewApplication" */ './NewApplication'),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'PBS application',
  menuIcon: LocalShippingOutlinedIcon,
  authorisation: {
    apiKey: 'PbsApplicationWithRelations.findById',
    policies: [
      {role: 'operationsPBSRead'},
      {role: 'providerPBSRead'},
      {role: 'customerPBSEnabled'},
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'PBS applications',
      to: '../..',
    },
  ],
};
