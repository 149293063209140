import React from 'react';

import {DSL_IconWrapper} from '../IconWrapper';
import {DSL_IconProps} from '../types';

const DSL_Intercom = (props: DSL_IconProps) => {
  return (
    <DSL_IconWrapper
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          fill="none"
          viewBox="0 0 24 24"
          {...props}
        >
          <path
            fill="#fff"
            d="M19.317 12.455c0 .17-.07.332-.195.452a.68.68 0 0 1-.47.187.68.68 0 0 1-.47-.187.63.63 0 0 1-.195-.452V6.75c0-.17.07-.332.195-.452a.68.68 0 0 1 .47-.187.68.68 0 0 1 .47.187c.125.12.195.283.195.452zm-.23 3.953c-.089.085-2.562 2.064-7.098 2.064s-6.993-1.969-7.097-2.053a.63.63 0 0 1-.23-.428.6.6 0 0 1 .153-.459.684.684 0 0 1 .935-.069c.038.027 2.248 1.747 6.233 1.747 3.986 0 6.213-1.73 6.235-1.746a.695.695 0 0 1 .94.068.613.613 0 0 1-.066.882zM4.654 6.75c.01-.17.09-.33.222-.443a.69.69 0 0 1 .917.024.63.63 0 0 1 .192.419v5.695c0 .17-.07.332-.195.451a.68.68 0 0 1-.47.187.68.68 0 0 1-.47-.187.63.63 0 0 1-.196-.451zm3.337-1.267c.01-.17.09-.329.222-.442a.69.69 0 0 1 .917.023.63.63 0 0 1 .192.42v8.444c0 .17-.07.332-.195.451a.68.68 0 0 1-.47.187.68.68 0 0 1-.47-.187.63.63 0 0 1-.196-.451zm3.354-.316c0-.17.07-.332.195-.452a.68.68 0 0 1 .47-.187.68.68 0 0 1 .47.187c.125.12.195.282.195.452v9.183c0 .17-.07.332-.195.452a.68.68 0 0 1-.47.187.68.68 0 0 1-.47-.187.63.63 0 0 1-.195-.452zm3.298.316c0-.169.07-.331.195-.451a.68.68 0 0 1 .47-.187.68.68 0 0 1 .47.187c.126.12.196.282.196.451v8.445c0 .17-.07.332-.195.451a.68.68 0 0 1-.47.187.68.68 0 0 1-.47-.187.63.63 0 0 1-.196-.451zM19.5 2H4.5a2.6 2.6 0 0 0-.953.176 2.5 2.5 0 0 0-.81.513c-.233.221-.418.483-.544.773-.127.289-.193.6-.194.913v14.25c.001.314.067.624.194.913.126.29.311.552.544.773.232.22.507.395.81.513s.627.178.953.176H19.5c.326.002.65-.058.952-.176s.577-.292.81-.512c.232-.22.417-.481.544-.77s.193-.599.195-.912V4.375a2.3 2.3 0 0 0-.193-.912 2.4 2.4 0 0 0-.543-.772 2.5 2.5 0 0 0-.808-.513A2.6 2.6 0 0 0 19.504 2"
          />
        </svg>
      }
      {...props}
    />
  );
};

export default React.memo(DSL_Intercom);
