import React, {ForwardedRef, forwardRef} from 'react';

import {DSL_BaseButton, DSL_BaseButtonProps} from '../BaseButton';

export type DSL_OutlinedButtonProps = Omit<DSL_BaseButtonProps, 'variant'>;

export const DSL_OutlinedButton = forwardRef(
  (props: DSL_OutlinedButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    return <DSL_BaseButton variant={'outlined'} ref={ref} {...props} />;
  },
);

DSL_OutlinedButton.displayName = 'DSL_OutlinedButton';

export {
  //
  /** @deprecated use DSL_OutlinedButtonProps instead*/
  DSL_OutlinedButtonProps as OutlinedButtonProps, //
  /** @deprecated use DSL_OutlinedButton instead*/
  DSL_OutlinedButton as OutlinedButton,
};
