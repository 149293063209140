import React from 'react';

import {
  PasswordInput,
  PasswordInputProps,
} from 'components/DesignSystem/Library';

import {useInputFieldProps} from '../hooks/useInputFieldProps';
import {DSP_FieldProps} from '../types';

export type DSP_PasswordFieldProps = PasswordInputProps & DSP_FieldProps;
export const DSP_PasswordField = (props: DSP_PasswordFieldProps) => {
  const fieldProps = useInputFieldProps(props);
  return <PasswordInput {...fieldProps} />;
};

export {
  //
  /** @deprecated use DSP_PasswordFieldProps instead*/
  DSP_PasswordFieldProps as PasswordFieldProps, //
  /** @deprecated use DSP_PasswordField instead*/
  DSP_PasswordField as PasswordField,
};
