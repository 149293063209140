import React from 'react';

import {DSL_Flex, DSL_FlexProps} from '../Flex';

export type DSL_FlexColumnProps = Omit<
  DSL_FlexProps,
  'flexDirection' | 'flexRow' | 'flexColumn'
>;

export const DSL_FlexColumn = React.forwardRef(
  (
    {
      flexWrap = 'wrap',
      gap = 1,
      maxWidth = '100%',
      ...DSL_BoxProps
    }: DSL_FlexColumnProps,
    ref,
  ) => {
    return (
      <DSL_Flex
        data-name={'flex-column'}
        ref={ref}
        flexDirection={'column'}
        flexWrap={flexWrap}
        gap={gap}
        maxWidth={maxWidth}
        {...DSL_BoxProps}
      />
    );
  },
);

DSL_FlexColumn.displayName = 'DSL_FlexColumn';

export {
  //
  /** @deprecated use DSL_FlexColumnProps instead*/
  DSL_FlexColumnProps as FlexColumnProps, //
  /** @deprecated use DSL_FlexColumn instead*/
  DSL_FlexColumn as FlexColumn,
};
