import * as Types from '../../../typeDefs/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserModelControllerLogoutMutationVariables = Types.Exact<{
  refreshInput: Types.RefreshInput;
}>;


export type UserModelControllerLogoutMutation = { __typename?: 'Mutation', userModelControllerLogout?: Array<{ __typename?: 'ActionResponse', code?: string | null, message?: string | null, info?: string | null } | null> | null };


export const UserModelControllerLogoutDocument = gql`
    mutation userModelControllerLogout($refreshInput: RefreshInput!) {
  userModelControllerLogout(refreshInput: $refreshInput) {
    code
    message
    info
  }
}
    `;
export type UserModelControllerLogoutMutationFn = Apollo.MutationFunction<UserModelControllerLogoutMutation, UserModelControllerLogoutMutationVariables>;

/**
 * __useUserModelControllerLogoutMutation__
 *
 * To run a mutation, you first call `useUserModelControllerLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserModelControllerLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userModelControllerLogoutMutation, { data, loading, error }] = useUserModelControllerLogoutMutation({
 *   variables: {
 *      refreshInput: // value for 'refreshInput'
 *   },
 * });
 */
export function useUserModelControllerLogoutMutation(baseOptions?: Apollo.MutationHookOptions<UserModelControllerLogoutMutation, UserModelControllerLogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserModelControllerLogoutMutation, UserModelControllerLogoutMutationVariables>(UserModelControllerLogoutDocument, options);
      }
export type UserModelControllerLogoutMutationHookResult = ReturnType<typeof useUserModelControllerLogoutMutation>;
export type UserModelControllerLogoutMutationResult = Apollo.MutationResult<UserModelControllerLogoutMutation>;
export type UserModelControllerLogoutMutationOptions = Apollo.BaseMutationOptions<UserModelControllerLogoutMutation, UserModelControllerLogoutMutationVariables>;