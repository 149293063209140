import React from 'react';
import * as R from 'ramda';
import {NumericFormat, NumericFormatProps} from 'react-number-format';

interface NumberFormatCustomProps extends NumericFormatProps<null> {
  inputRef: (instance: React.Ref<null> | null) => void;
  onChange: (
    e: {currentTarget: {name: string; value: string | null}},
    numericValue?: number | string,
  ) => void;
  name: string;
  step?: number;
  decimalScale?: number;
  min?: number;
  max?: number;
}

const NumberFormatCustom = React.forwardRef(
  (props: NumberFormatCustomProps, ref) => {
    const {onChange, thousandSeparator, ...other} = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values): void => {
          onChange(
            {
              currentTarget: {
                name: props.name,
                value: values.value === '' ? null : values.value,
              },
            },
            R.endsWith('.', values.value) ||
              R.endsWith('.0', values.value) ||
              R.endsWith('.00', values.value)
              ? values.value
              : values.floatValue,
          );
        }}
        thousandSeparator={R.defaultTo(R.isNil(props.step), thousandSeparator)}
        decimalScale={props.decimalScale}
      />
    );
  },
);

NumberFormatCustom.displayName = 'NumberFormatCustom';

export default NumberFormatCustom;
