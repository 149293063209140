export type DSL_ScrollableLayoutSize = 'NONE' | 'XS' | 'SM' | 'MD' | 'LG';

export interface DSL_PaddingStyle {
  py: number;
  px: number;
}

const scrollableLayoutPadding: {
  [size in DSL_ScrollableLayoutSize]: {
    py: number;
    px: number;
  };
} = {
  NONE: {
    py: 0,
    px: 0,
  },
  XS: {
    py: 1,
    px: 1,
  },
  SM: {
    py: 2,
    px: 2,
  },
  MD: {
    py: 2,
    px: 3,
  },
  LG: {
    py: 3,
    px: 4,
  },
} as const;

export function useScrollableLayoutPadding(
  size: DSL_ScrollableLayoutSize,
): DSL_PaddingStyle {
  return scrollableLayoutPadding[size];
}
