import React from 'react';

import {
  DSL_FlexColumn,
  DSL_FlexColumnProps,
} from 'components/DesignSystem/Library/Layout/FlexColumn';

export type DSL_SummaryFieldsetProps = DSL_FlexColumnProps;

export const DSL_SummaryFieldset = (props: DSL_SummaryFieldsetProps) => {
  return <DSL_FlexColumn gap={3} {...props} />;
};

DSL_SummaryFieldset.displayName = 'DSL_SummaryFieldSet';

export {
  //
  /** @deprecated use DSL_SummaryFieldsetProps instead*/
  DSL_SummaryFieldsetProps as SummaryFieldsetProps, //
  /** @deprecated use DSL_SummaryFieldset instead*/
  DSL_SummaryFieldset as SummaryFieldset,
};
