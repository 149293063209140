import React, {ReactNode} from 'react';

import {badgeClasses} from '@mui/material';
import {Badge} from '@mui/material';

import {DSL_BadgeProps} from '../../../Badge';
import {DSL_Box, DSL_BoxProps} from '../../Box';

export interface DSL_CardBadgeProps
  extends Omit<
    DSL_BadgeProps,
    'anchorOrigin' | 'components' | 'componentsProps'
  > {
  width?: DSL_BoxProps['width'];
  children?: ReactNode;
  verticalSpacing?: number;
  horizontalSpacing?: number;
}

// MUI tries to pass a generated classname to the child box component but that breaks our layouts because it tries to
// style the box as inline-block.
type BadgeComponentOverrideProps = DSL_CardBadgeProps & {
  className?: string;
};

export const DSL_CardBadge = ({
  verticalSpacing = 2,
  horizontalSpacing = 2,
  variant = 'dot',
  color = 'secondary',
  width = '100%',
  onClick,
  ...props
}: DSL_CardBadgeProps) => {
  return (
    <Badge
      variant={variant}
      color={color}
      component={({className, ...boxProps}: BadgeComponentOverrideProps) => (
        <DSL_Box
          width={width}
          position={'relative'}
          {...boxProps}
          internal_sx={theme => ({
            [`& .${badgeClasses.badge}`]: {
              pointerEvents: onClick ? undefined : 'none',
              top: theme.spacing(verticalSpacing),
              right: theme.spacing(horizontalSpacing),
            },
          })}
        />
      )}
      {...props}
    />
  );
};

DSL_CardBadge.displayName = 'DSL_CardBadge';

export {
  //
  /** @deprecated use DSL_CardBadgeProps instead*/
  DSL_CardBadgeProps as CardBadgeProps, //
  /** @deprecated use DSL_CardBadge instead*/
  DSL_CardBadge as CardBadge,
};
