import React from 'react';

import {DSL_PersonOutlined} from '../../Icons';
import {DSL_BaseIconInfo, DSL_BaseIconInfoProps} from '../BaseIconInfo';

export type DSL_UserIconInfoProps = Omit<DSL_BaseIconInfoProps, 'icon'>;

export const DSL_UserIconInfo = (props: DSL_UserIconInfoProps) => {
  return <DSL_BaseIconInfo icon={<DSL_PersonOutlined />} {...props} />;
};

DSL_UserIconInfo.displayName = 'DSL_UserIconInfo';

export {
  //
  /** @deprecated use DSL_UserIconInfoProps instead*/
  DSL_UserIconInfoProps as UserIconInfoProps, //
  /** @deprecated use DSL_UserIconInfo instead*/
  DSL_UserIconInfo as UserIconInfo,
};
