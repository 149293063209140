import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import * as React from 'react';
import {useStyles} from './AppSnackbar.styles';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

type AppSnackbarMessageProps = {
  message: string;
  variant: 'success' | 'error' | 'info' | 'warning';
};

const AppSnackbarMessage: React.FC<AppSnackbarMessageProps> = ({
  message,
  variant,
}: AppSnackbarMessageProps) => {
  const classes = useStyles();
  const Icon = variantIcon[variant];
  return (
    <div id="client-snackbar" className={classes.message}>
      <Icon className={classes.icon} />
      {message}
    </div>
  );
};

export default AppSnackbarMessage;
