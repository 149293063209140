import {usePopover} from 'components/DesignSystem/Library';

import {usePartnerAccountEntityLoaderContext} from '../PartnerAccountEntityLoaderProvider';
import {usePartnerAccountEntityMapper} from '../usePartnerAccountEntityMapper';

import {DSP_PartnerAccountEntityAvatarViewProps} from './PartnerAccountEntityAvatar.View';

export function usePartnerAccountEntityAvatar(): DSP_PartnerAccountEntityAvatarViewProps {
  const popoverProps = usePopover<HTMLDivElement>();
  const {entity: partnerAccount, isLoading} =
    usePartnerAccountEntityLoaderContext();
  const partnerAccountProps = usePartnerAccountEntityMapper({partnerAccount});

  return {
    ...popoverProps,
    ...partnerAccountProps,
    isLoading,
  };
}
