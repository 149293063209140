import {RefObject, useEffect} from 'react';

import {Theme} from '@mui/material';
import {useTheme} from '@mui/styles';
import {useDebouncedCallback} from 'use-debounce';
import {noOp} from 'utils/noOp';

export interface UseSizeObserverProps {
  ref: RefObject<HTMLElement>;
  debounceMs?: number;
  onInit?: (rect: DOMRect) => void;
  onResize?: (entries: ResizeObserverEntry[], ref: HTMLElement) => void;
}

export function useSizeObserver({
  ref,
  debounceMs,
  onInit,
  onResize = noOp,
}: UseSizeObserverProps) {
  const {transitions} = useTheme<Theme>();

  const debouncedOnResize = useDebouncedCallback(
    onResize,
    debounceMs ?? transitions.duration.shortest,
  );

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    onInit?.(ref.current.getBoundingClientRect());
  }, [onInit, ref]);

  useEffect(() => {
    const current = ref.current;

    if (!current) {
      return;
    }

    const handleResize: ResizeObserverCallback = entries => {
      if (current) {
        debouncedOnResize(entries, current);
      }
    };

    const observer = new ResizeObserver(handleResize);
    observer.observe(current);

    return () => {
      observer.disconnect();
    };
  }, [debouncedOnResize, ref]);
}
