import React from 'react';

import {DSP_UserEntityNameView} from '../UserEntityName.View';
import {useUserEntityName} from '../useUserEntityName';

export const DSP_UserEntityFirstName = () => {
  const viewProps = useUserEntityName({nameVariant: 'FIRST'});

  return <DSP_UserEntityNameView skeletonWidth={115} {...viewProps} />;
};

DSP_UserEntityFirstName.displayName = 'DSP_UserEntityFirstName';

export {
  //
  /** @deprecated use DSP_UserEntityFirstName instead*/
  DSP_UserEntityFirstName as UserEntityFirstName,
};
