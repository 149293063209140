export function pluralizeLabel(
  label: string,
  countOrArray: number | unknown[] | undefined | null,
  options?: {
    pluralLabel?: string;
  },
): string {
  const count = Array.isArray(countOrArray)
    ? countOrArray?.length
    : countOrArray;

  const shouldPluralize = count !== 1;

  return shouldPluralize ? options?.pluralLabel ?? `${label}s` : label;
}
