import React from 'react';

import {DSL_Add} from 'components/DesignSystem/Library/Icons';

import {
  DSL_BaseCardIconButton,
  DSL_BaseCardIconButtonProps,
} from '../BaseCardIconButton';

type DSL_ZoomInCardIconButtonProps = Omit<
  DSL_BaseCardIconButtonProps,
  'children'
>;

export const DSL_ZoomInCardIconButton = (
  props: DSL_ZoomInCardIconButtonProps,
) => {
  return (
    <DSL_BaseCardIconButton {...props}>
      <DSL_Add color={props.disabled ? 'disabled' : 'action'} />
    </DSL_BaseCardIconButton>
  );
};

DSL_ZoomInCardIconButton.displayName = 'DSL_ZoomInCardIconButton';
