import React, {ReactNode, useContext} from 'react';

import {DSL_BaseIconButton} from '../../Buttons/IconButtons/BaseIconButton';
import {DSL_DragIndicatorIcon} from '../../Icons';

import {DSL_SortableListItemContext} from './SortableListItem';

interface DSL_DragHandleProps {
  icon?: ReactNode;
  isActive?: boolean;
}

export const DSL_DragHandle = ({
  icon = <DSL_DragIndicatorIcon />,
  isActive,
}: DSL_DragHandleProps) => {
  const {attributes, listeners, ref} = useContext(DSL_SortableListItemContext);

  return (
    <DSL_BaseIconButton
      title={!isActive ? 'Drag to reorder' : undefined}
      data-name="drag-handle"
      {...attributes}
      {...listeners}
      aria-label="drag-handle"
      ref={ref}
      disableRipple={!isActive}
    >
      {icon}
    </DSL_BaseIconButton>
  );
};
