import React from 'react';

import {DSL_BaseDrawer, DSL_BaseDrawerProps} from '../BaseDrawer';
import {DSL_DrawerSizeFixed} from '../types';

import {useFixedDrawer, DSL_UseFixedDrawerArgs} from './useFixedDrawer';

export interface DSL_FixedDrawerProps
  extends Pick<
      DSL_BaseDrawerProps,
      'onClose' | 'sidebar' | 'children' | 'id' | 'isHidden'
    >,
    DSL_UseFixedDrawerArgs {
  size?: DSL_DrawerSizeFixed;
}

export const DSL_FixedDrawer = ({
  id,
  sidebar,
  children,
  onClose,
  isHidden,
  ...args
}: DSL_FixedDrawerProps) => {
  const viewProps = useFixedDrawer(args);

  return (
    <DSL_BaseDrawer
      id={id}
      data-name={'non-modal-drawer'}
      sidebar={sidebar}
      onClose={onClose}
      isHidden={isHidden}
      {...viewProps}
    >
      {children}
    </DSL_BaseDrawer>
  );
};

DSL_FixedDrawer.displayName = 'DSL_FixedDrawer';

export {
  //
  /** @deprecated use DSL_FixedDrawerProps instead*/
  DSL_FixedDrawerProps as FixedDrawerProps, //
  /** @deprecated use DSL_FixedDrawer instead*/
  DSL_FixedDrawer as FixedDrawer,
};
