import React from 'react';
import {RouteConfig} from '../types';
import SetPageTitle from './SetPageTitle';
import {useRegisterContent} from 'containers-content';

export interface RouteElementProps extends RouteConfig {
  shouldSetPageTitle?: boolean;
}

export const RouteElement = ({
  shouldSetPageTitle = true,
  content,
  ...route
}: RouteElementProps) => {
  useRegisterContent(content);

  return (
    <>
      {shouldSetPageTitle && <SetPageTitle route={route} />}
      <route.component />
    </>
  );
};

RouteElement.displayName = 'RouteElement';
