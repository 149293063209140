import React from 'react';

import {DSL_BaseTypography, DSL_BaseTypographyProps} from '../BaseTypography';

export type DSL_Heading4Props = Omit<DSL_BaseTypographyProps, 'variant'>;
export const DSL_Heading4 = (props: DSL_Heading4Props) => (
  <DSL_BaseTypography
    {...props}
    color={props.color ?? 'text.primary'}
    variant={'h4'}
  />
);

export {
  //
  /** @deprecated use DSL_Heading4Props instead*/
  DSL_Heading4Props as Heading4Props, //
  /** @deprecated use DSL_Heading4 instead*/
  DSL_Heading4 as Heading4,
};
