import {useSelector} from '@xstate/react';
import {useFormContext} from 'components/DesignSystem/Portal/Forms/FormProvider';
import * as R from 'ramda';
import {useNavigate} from 'react-router-dom';
import getStateMetaHead from 'utils/machines/utils/getStateMetaHead';

import {DSP_CloseButtonStackViewProps} from './CloseButtonStack.View';

export const useCloseButtonStack = (): DSP_CloseButtonStackViewProps => {
  const {service} = useFormContext();
  const navigate = useNavigate();
  return useSelector(
    service,
    state => {
      const metaHead = getStateMetaHead(state);
      const navigateTo = metaHead.navigateTo;
      const navigateState = metaHead.navigateState;
      return {
        onClick: () => {
          service?.send('CANCEL');
          if (!R.isNil(navigateTo) && navigateTo !== '') {
            navigate(
              navigateTo,
              navigateState ? {state: navigateState} : undefined,
            );
          }
        },
      };
    },
    (prevFieldProps, nextFieldProps) => {
      const ignoreProps = R.omit(['onClick']);
      return R.equals(ignoreProps(prevFieldProps), ignoreProps(nextFieldProps));
    },
  );
};
