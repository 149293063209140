import React, {ReactNode} from 'react';

import {RouteBlocker} from 'app/router/components/RouteBlocker';
import {SubmissionRedirect} from 'app/router/components/SubmissionRedirect';
import {FormMachineService} from 'utils/stores/types';

import {DSP_FormProvider, DSP_FormProviderProps} from '../FormProvider';

import {DSP_SimpleFormContainerView} from './SimpleFormContainer.View';
import {
  useSimpleFormContainer,
  DSP_UseSimpleFormContainerArgs,
} from './useSimpleFormContainer';

interface DSP_SimpleFormContainerLayoutProps
  extends DSP_UseSimpleFormContainerArgs {
  hasSubmissionRedirect?: boolean;
  hasRouteBlocker?: boolean;
  children?: ReactNode;
}

/**
 * Use this component if you just want the display logic and don't need to use the FormProvider wrapper
 * @param props
 * @constructor
 */
export const DSP_SimpleFormContainerLayout = ({
  hasSubmissionRedirect,
  hasRouteBlocker,
  onSubmit,
  machineSubmitEvent,
  children,
}: DSP_SimpleFormContainerLayoutProps) => {
  const {service, ...viewProps} = useSimpleFormContainer({
    onSubmit,
    machineSubmitEvent,
  });
  return (
    <>
      <DSP_SimpleFormContainerView {...viewProps}>
        {children}
      </DSP_SimpleFormContainerView>
      {hasSubmissionRedirect && <SubmissionRedirect />}
      {hasRouteBlocker && <RouteBlocker title={'simple-form'} />}
    </>
  );
};

export interface DSP_SimpleFormContainerProps<
  T extends FormMachineService = FormMachineService,
> extends Omit<DSP_SimpleFormContainerLayoutProps, 'children'>,
    DSP_FormProviderProps<T> {}

/**
 * Typically for simple forms this is the container you want.
 * If you need to split out the provider from the presenter you can do that by
 * using the `SimpleFormContainerLayout` component.
 */
export const DSP_SimpleFormContainer = ({
  service,
  children,
  onSubmit,
  otherProps,
  legacySupport,
  machineSubmitEvent,
  hasSubmissionRedirect = true,
  hasRouteBlocker = true,
}: DSP_SimpleFormContainerProps) => {
  return (
    <DSP_FormProvider
      service={service}
      otherProps={otherProps}
      legacySupport={legacySupport}
    >
      <DSP_SimpleFormContainerLayout
        onSubmit={onSubmit}
        machineSubmitEvent={machineSubmitEvent}
        hasSubmissionRedirect={hasSubmissionRedirect}
        hasRouteBlocker={hasRouteBlocker}
      >
        {children}
      </DSP_SimpleFormContainerLayout>
    </DSP_FormProvider>
  );
};

export {
  //
  /** @deprecated use DSP_SimpleFormContainerLayout instead*/
  DSP_SimpleFormContainerLayout as SimpleFormContainerLayout, //
  /** @deprecated use DSP_SimpleFormContainer instead*/
  DSP_SimpleFormContainer as SimpleFormContainer,
};
