import React from 'react';

import {Theme} from '@mui/material';
import {useTheme} from '@mui/styles';

import {
  DSL_Add,
  DSL_Box,
  DSL_Divider,
  DSL_FileUploadIcon,
  DSL_FlexColumn,
  DSL_FlexRow,
  DSL_Table,
  DSL_TableBody,
  DSL_TableCell,
  DSL_TableContainer,
  DSL_TableHead,
  DSL_TableRow,
  DSL_TextButton,
} from 'components/DesignSystem/Library';

import {DSP_FormTableRow} from './FormTableRow';
import {
  DSP_FormTableColumnConfig,
  DSP_FormTableRowRenderer,
  DSP_FormTableRowRendererProps,
  DSP_FormTableRowType,
} from './types';
import {flattenFormTableColumnConfig, isFormTableColumnGroup} from './utils';

export interface DSP_FormTableViewProps<ItemType> {
  tableLabel: string;
  columns: DSP_FormTableColumnConfig<ItemType>[];
  rows: DSP_FormTableRowType[];
  canAddRow?: boolean;
  canRemoveRow?: boolean;
  onAddRow: () => void;
  onRemoveRow?: (index: number) => void;
  onImport?: () => void;
  RowRenderer?: DSP_FormTableRowRenderer;
}

const emptyCell = <DSL_TableCell key={'action-cell'} width={'100%'} />;

export function DSP_FormTableView<ItemType>({
  tableLabel,
  columns,
  rows,
  canAddRow = true,
  onAddRow,
  onRemoveRow,
  onImport,
  RowRenderer = DSP_FormTableRow,
}: DSP_FormTableViewProps<ItemType>) {
  const {palette} = useTheme<Theme>();
  const groupCount = columns.filter(isFormTableColumnGroup).length;

  return (
    <DSL_FlexColumn gap={0}>
      <DSL_FlexRow justifyContent={'flex-end'} py={1} px={2}>
        {canAddRow && (
          <DSL_TextButton startIcon={<DSL_Add />} onClick={onAddRow}>
            Add row
          </DSL_TextButton>
        )}
        {onImport && (
          <>
            <DSL_Divider orientation="vertical" />
            <DSL_TextButton
              startIcon={<DSL_FileUploadIcon />}
              onClick={onImport}
            >
              Import
            </DSL_TextButton>
          </>
        )}
      </DSL_FlexRow>
      <DSL_Divider />
      <DSL_TableContainer>
        <DSL_Table
          stickyHeader
          display={'block'}
          whiteSpace={'nowrap'}
          overflowX={'auto'}
          aria-label={tableLabel}
        >
          <DSL_TableHead>
            {!!groupCount && (
              <DSL_TableRow>
                {columns.map((colOrGroup, colIndex) => {
                  const isGroup = isFormTableColumnGroup(colOrGroup);

                  return (
                    <DSL_TableCell
                      padding={'none'}
                      key={colIndex}
                      colSpan={isGroup ? colOrGroup.columns.length : 1}
                      minWidth={!isGroup ? colOrGroup?.width : undefined}
                    >
                      {isGroup && (
                        <DSL_Box
                          bgcolor={palette.primary.shades?.['8p']}
                          px={2}
                          py={0.5}
                        >
                          {colOrGroup.group}
                        </DSL_Box>
                      )}
                    </DSL_TableCell>
                  );
                })}
                {emptyCell}
              </DSL_TableRow>
            )}
            <DSL_TableRow>
              {flattenFormTableColumnConfig(columns).map((config, colIndex) => (
                <DSL_TableCell
                  key={colIndex}
                  title={config.description}
                  maxWidth={config.width}
                  overflow={'hidden'}
                  textOverflow={'ellipsis'}
                >
                  {config.title}
                </DSL_TableCell>
              ))}
              {emptyCell}
            </DSL_TableRow>
          </DSL_TableHead>
          <DSL_TableBody>
            {rows.map((row, rowIndex) => (
              <RowRenderer
                key={rowIndex}
                rowIndex={rowIndex}
                onRemoveRow={onRemoveRow}
                columns={columns as DSP_FormTableRowRendererProps['columns']}
                {...row}
              />
            ))}
          </DSL_TableBody>
        </DSL_Table>
      </DSL_TableContainer>
    </DSL_FlexColumn>
  );
}

DSP_FormTableView.displayName = 'DSP_FormTableView';
