import {DSP_WorkflowActivityNotificationItem} from '../types';

import {DSP_WorkflowActivityNotificationCardViewProps} from './WorkflowActivityNotificationCard.View';

export interface DSP_UseAccessProductChangeSetNotificationCardProps {
  notification: DSP_WorkflowActivityNotificationItem;
}

export function useWorkflowActivityNotificationCard({
  notification,
}: DSP_UseAccessProductChangeSetNotificationCardProps): DSP_WorkflowActivityNotificationCardViewProps {
  return {
    notification,
    userId: notification.data.createdByUser?.id,
  };
}
