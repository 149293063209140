import React from 'react';

import {
  DSL_Box,
  DSL_BoxProps,
} from 'components/DesignSystem/Library/Layout/Box';

export type DSL_TableCaptionProps = DSL_BoxProps;

export const DSL_TableCaption = (props: DSL_TableCaptionProps) => {
  return (
    <caption>
      <DSL_Box {...props} />
    </caption>
  );
};

DSL_TableCaption.displayName = 'DSL_TableCaption';

export {
  //
  /** @deprecated use DSL_TableCaptionProps instead*/
  DSL_TableCaptionProps as TableCaptionProps, //
  /** @deprecated use DSL_TableCaption instead*/
  DSL_TableCaption as TableCaption,
};
