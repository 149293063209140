import React from 'react';

import {DSL_BaseEmptyState, DSL_BaseEmptyStateProps} from '../BaseEmptyState';

import {DSL_HourGlassIcon} from './HourGlassIcon';

export type DSL_HourGlassEmptyStateProps = Omit<
  DSL_BaseEmptyStateProps,
  'icon'
>;

export const DSL_HourGlassEmptyState = (
  props: DSL_HourGlassEmptyStateProps,
) => {
  return <DSL_BaseEmptyState icon={<DSL_HourGlassIcon />} {...props} />;
};

DSL_HourGlassEmptyState.displayName = 'DSL_HourGlassEmptyState';

export {
  //
  /** @deprecated use DSL_HourGlassEmptyStateProps instead*/
  DSL_HourGlassEmptyStateProps as HourGlassEmptyStateProps, //
  /** @deprecated use DSL_HourGlassEmptyState instead*/
  DSL_HourGlassEmptyState as HourGlassEmptyState,
};
