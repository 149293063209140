import React from 'react';

import {
  LocalizationProvider as MuiLocalizationProvider,
  LocalizationProviderProps as MuiLocalizationProviderProps,
} from '@mui/x-date-pickers-pro';

export type DSL_LocalizationProviderProps<TDateTime, TLocale> =
  MuiLocalizationProviderProps<TDateTime, TLocale>;

export function DSL_LocalizationProvider<TDateTime, TLocale>({
  children,
  ...props
}: DSL_LocalizationProviderProps<TDateTime, TLocale>) {
  return (
    <MuiLocalizationProvider {...props}>{children}</MuiLocalizationProvider>
  );
}
