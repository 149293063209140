import ListAlt from '@mui/icons-material/ListAlt';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';
export default {
  path: '/accessProductDocumentMedia',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "ProductDocumentMedia" */ './ProductDocumentMedia'
      ),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'Pre approval guides',
  menuIcon: ListAlt,
  authorisation: {
    apiKey: null,
    policies: [
      {
        role: 'operationsAccessRead',
      },
    ] as AuthorisationPolicy[],
  },
  sections: [],
};
