import * as Types from '../../../../../typeDefs/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NotificationsFindQueryVariables = Types.Exact<{
  notificationControllerFindFilter?: Types.InputMaybe<Types.NotificationControllerFindFilterInput>;
}>;


export type NotificationsFindQuery = { __typename?: 'Query', notificationsFind?: Array<{ __typename?: 'Notification', accountId?: number | null, accountType?: string | null, createdBy?: string | null, created?: string | null, data: any, geom?: any | null, notificationId?: number | null, notificationType: string, fromUserId?: number | null, modifiedBy?: string | null, modified?: string | null, viewedDate?: string | null, userViews?: Array<{ __typename?: 'NotificationUserViews', createdBy?: string | null, created?: string | null, notificationId: number, notificationUserViewId?: number | null, modifiedBy?: string | null, modified?: string | null, userId?: number | null, viewedDate?: string | null } | null> | null } | null> | null };


export const NotificationsFindDocument = gql`
    query NotificationsFind($notificationControllerFindFilter: NotificationControllerFindFilterInput) {
  notificationsFind(
    notificationControllerFindFilter: $notificationControllerFindFilter
  ) {
    accountId
    accountType
    createdBy
    created
    data
    geom
    notificationId
    notificationType
    fromUserId
    modifiedBy
    modified
    userViews {
      createdBy
      created
      notificationId
      notificationUserViewId
      modifiedBy
      modified
      userId
      viewedDate
    }
    viewedDate
  }
}
    `;

/**
 * __useNotificationsFindQuery__
 *
 * To run a query within a React component, call `useNotificationsFindQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsFindQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsFindQuery({
 *   variables: {
 *      notificationControllerFindFilter: // value for 'notificationControllerFindFilter'
 *   },
 * });
 */
export function useNotificationsFindQuery(baseOptions?: Apollo.QueryHookOptions<NotificationsFindQuery, NotificationsFindQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsFindQuery, NotificationsFindQueryVariables>(NotificationsFindDocument, options);
      }
export function useNotificationsFindLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsFindQuery, NotificationsFindQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsFindQuery, NotificationsFindQueryVariables>(NotificationsFindDocument, options);
        }
export function useNotificationsFindSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<NotificationsFindQuery, NotificationsFindQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NotificationsFindQuery, NotificationsFindQueryVariables>(NotificationsFindDocument, options);
        }
export type NotificationsFindQueryHookResult = ReturnType<typeof useNotificationsFindQuery>;
export type NotificationsFindLazyQueryHookResult = ReturnType<typeof useNotificationsFindLazyQuery>;
export type NotificationsFindSuspenseQueryHookResult = ReturnType<typeof useNotificationsFindSuspenseQuery>;
export type NotificationsFindQueryResult = Apollo.QueryResult<NotificationsFindQuery, NotificationsFindQueryVariables>;