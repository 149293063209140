import React from 'react';

import {DSL_BaseBox, DSL_BaseBoxProps} from '../Box/BaseBox';

export type DSL_FlexProps = Omit<DSL_BaseBoxProps, 'display'>;

export const DSL_Flex = React.forwardRef((props: DSL_FlexProps, ref) => {
  return (
    <DSL_BaseBox data-name={'flex'} ref={ref} display={'flex'} {...props} />
  );
});

DSL_Flex.displayName = 'DSL_Flex';

export {
  //
  /** @deprecated use DSL_FlexProps instead*/
  DSL_FlexProps as FlexProps, //
  /** @deprecated use DSL_Flex instead*/
  DSL_Flex as Flex,
};
