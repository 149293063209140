import React, {ReactNode} from 'react';

import {
  DSL_ButtonCardActionArea,
  DSL_ButtonCardActionAreaProps,
} from '../../../Layout/Card/CardActionArea';
import {DSL_CardContent} from '../../../Layout/Card/CardContent';
import {DSL_OutlinedCard} from '../../../Layout/Card/OutlinedCard';
import {DSL_FlexColumn} from '../../../Layout/FlexColumn';
import {
  DSL_BreakpointValues,
  useCascadingBreakpointValue,
} from '../../../Layout/hooks/useCascadingBreakpointValue';
import {DSL_FormControlProps} from '../FormControl';

type BPHeight = DSL_BreakpointValues<number | string>;

export interface DSL_BaseCardInputProps
  extends Pick<DSL_FormControlProps, 'disabled' | 'error'> {
  onClickCard: DSL_ButtonCardActionAreaProps['onClick'];
  height?: BPHeight;
  minHeight?: BPHeight;
  maxHeight?: BPHeight;
  isHighlighted?: boolean;
  children?: ReactNode;
}

export const DSL_BaseCardInput = ({
  disabled,
  isHighlighted,
  error,
  minHeight,
  height,
  maxHeight,
  onClickCard,
  children,
}: DSL_BaseCardInputProps) => (
  <DSL_ButtonCardActionArea disabled={disabled} onClick={onClickCard}>
    <DSL_OutlinedCard
      borderWidth={isHighlighted ? '2px' : undefined}
      borderColor={
        error ? 'error.main' : isHighlighted ? 'primary.main' : undefined
      }
      bgcolor={disabled ? 'background.default' : undefined}
    >
      <DSL_CardContent
        height={useCascadingBreakpointValue(height)}
        minHeight={useCascadingBreakpointValue(minHeight)}
        maxHeight={useCascadingBreakpointValue(maxHeight)}
      >
        <DSL_FlexColumn internal_sx={{m: isHighlighted ? '-1px' : undefined}}>
          {children}
        </DSL_FlexColumn>
      </DSL_CardContent>
    </DSL_OutlinedCard>
  </DSL_ButtonCardActionArea>
);

DSL_BaseCardInput.displayName = 'DSL_BaseCardInputView';
