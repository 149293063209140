import {ThemeOptions, Theme} from '@mui/material/styles';
import URWGeometricRegularWoff from './webfonts/3464D4_3_0.woff';
import URWGeometricRegularWoff2 from './webfonts/3464D4_3_0.woff2';
import URWGeometricRegularTTF from './webfonts/3464D4_3_0.ttf';

export const getDesignTokens = (theme: Theme): ThemeOptions =>
  ({
    typography: theme.typography,
    palette: {
      mode: theme.palette.mode,
      primary: theme.palette.primary,
      secondary: theme.palette.secondary,
      error: theme.palette.error,
      info: theme.palette.info,
      warning: theme.palette.warning,
      success: theme.palette.success,
      coolGrey: theme.palette.coolGrey,
      action: theme.palette.action,
      background: theme.palette.background,
      text: theme.palette.text,
      divider: theme.palette.divider,
    },
    /* These hard coded values will be replaced with Figma token values
     as part of https://nhvr.atlassian.net/browse/NHVRREP-29215 */
    zIndex: {
      overlay: 10,
      mapContextMenu: 1000,
      drawer: 1200,
      drawerNonModal: 1250,
      drawerNonModalTopOfStack: 1280,
      mapOverlay: 1298,
      sidebarMenu: 1299,
      appBar: 1300,
      drawerModal: 1350,
      modal: 1400,
      snackbar: 1450,
      tooltip: 1500,
      streetview: 1550,
    },
    shape: {
      borderRadius: theme.shape.borderRadius,
    },
    breakpoints: {
      values: theme.breakpoints.values,
    },
    sizes: theme.sizes,
    mapBox: theme.mapBox,
    statusColours: theme.statusColours,
    elevation: {
      drawers: 3,
      appBar: 1,
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
              body: {
                fontSize: '1rem';
                lineHeight: 1.43;
                letterSpacing: '0.01071em';
              }
              @font-face {
                font-family: 'URWGeometric-Regular';
                src: url('webfonts/3464D4_3_0.eot');
                src: url('webfonts/3464D4_3_0.eot?#iefix') format('embedded-opentype'),
                  url(${URWGeometricRegularWoff2}) format('woff2'),
                  url(${URWGeometricRegularWoff}) format('woff'),
                  url(${URWGeometricRegularTTF}) format('truetype');
              }
            `,
      },
      MuiFormControl: {
        // TODO: remove defaultProps when the old fields have all been removed.
        defaultProps: {
          size: 'small',
          margin: 'dense',
        },
        styleOverrides: {
          root: {
            margin: 0,
          },
        },
      },
      MUIDataTableSearch: {
        // TODO: Remove when we switch to the new DS data table components
        styleOverrides: {
          viewsSelectControl: {
            justifyContent: 'center',
          },
        },
      },
      MUIDataTableBodyCell: {
        // TODO: Remove when we switch to the new DS data table components
        styleOverrides: {
          root: {
            minWidth: 70,
            maxWidth: 300,
            lineHeight: 2,
          },
        },
      },
      MUIDataTableFilter: {
        // TODO: Remove when we switch to the new DS data table components
        styleOverrides: {
          gridListTile: {
            marginTop: '0px',
          },
        },
      },
      MUIDataTablePagination: {
        // TODO: Remove when we switch to the new DS data table components
        styleOverrides: {
          navContainer: {
            justifyContent: 'center',
          },
          selectRoot: {
            paddingTop: '7px',
          },
        },
      },
      MuiAlert: {
        // TODO: Remove when we switch to the new DS alert components
        styleOverrides: {
          root: {
            marginTop: 8,
            marginBottom: 8,
          },
        },
      },
      MuiGridListTile: {
        // TODO: Remove when we switch to the new DS grid list tile component
        styleOverrides: {
          root: {
            padding: '5px 10px !important',
          },
        },
      },
      MuiStepLabel: {
        // TODO: Remove when we have swapped out all the step label components
        styleOverrides: {
          label: {
            fontSize: '0.9rem',
            '&.Mui-active': {
              fontWeight: '500 !important',
            },
            '&.Mui-completed': {
              fontWeight: 400,
            },
          },
        },
      },
      MuiPickersCalendarHeader: {
        // TODO: Remove when we have swapped out all the date picker components
        styleOverrides: {
          root: {
            paddingLeft: 12,
            justifyContent: 'space-between',
          },
          label: {
            marginRight: 0,
            whiteSpace: 'nowrap',
          },
          labelContainer: {
            marginRight: 0,
          },
          switchViewButton: {
            padding: 2,
          },
        },
      },
      MuiPickersArrowSwitcher: {
        // TODO: Remove when we have swapped out all the date picker components
        styleOverrides: {
          button: {
            padding: 2,
          },
        },
      },
      MuiPickersFadeTransitionGroup: {
        // TODO: Remove when we have swapped out all the date picker components
        styleOverrides: {
          root: {
            display: 'flex',
            alignItems: 'center',
          },
        },
      },
    },
  } as ThemeOptions);

interface StatusColours {
  draft: React.CSSProperties['color'];
  submitted: React.CSSProperties['color'];
  issued: React.CSSProperties['color'];
  onHold: React.CSSProperties['color'];
  closed: React.CSSProperties['color'];
  assessment: React.CSSProperties['color'];
  requestedInfo: React.CSSProperties['color'];
  active: React.CSSProperties['color'];
  archived: React.CSSProperties['color'];
  migrated: React.CSSProperties['color'];
  activated: React.CSSProperties['color'];
  deactivated: React.CSSProperties['color'];
  awaitingAcceptance: React.CSSProperties['color'];
  awaitingActivation: React.CSSProperties['color'];
}

interface MapBoxColours {
  primary: React.CSSProperties['color'];
  secondary: React.CSSProperties['color'];
  secondaryHover: React.CSSProperties['color'];
  info: React.CSSProperties['color'];
  action: React.CSSProperties['color'];
  accent: React.CSSProperties['color'];
  darkAccent: React.CSSProperties['color'];
  success: React.CSSProperties['color'];
  positive: React.CSSProperties['color'];
  error: React.CSSProperties['color'];
  warning: React.CSSProperties['color'];
  tint1: React.CSSProperties['color'];
  tint2: React.CSSProperties['color'];
  tint3: React.CSSProperties['color'];
  tint4: React.CSSProperties['color'];
  tint5: React.CSSProperties['color'];
  tint6: React.CSSProperties['color'];
  tint9: React.CSSProperties['color'];
  tint8: React.CSSProperties['color'];
  tint7: React.CSSProperties['color'];
  disabled: React.CSSProperties['color'];
  pink: React.CSSProperties['color'];
  darkPink: React.CSSProperties['color'];
  green: React.CSSProperties['color'];
  statusPublished: React.CSSProperties['color'];
  gray: React.CSSProperties['color'];
  networkApproved: React.CSSProperties['color'];
  networkApprovedWithCondition: React.CSSProperties['color'];
  networkRestricted: React.CSSProperties['color'];
  networkNoAccess: React.CSSProperties['color'];
  statusRoads: React.CSSProperties['color'];
  unmanagedRoads: React.CSSProperties['color'];
  white: React.CSSProperties['color'];
  statusNew: React.CSSProperties['color'];
  statusPendingUpdate: React.CSSProperties['color'];
  archived: React.CSSProperties['color'];
}

declare module '@mui/material/styles' {
  interface Palette {
    coolGrey: PaletteColor;
  }
  interface PaletteColor {
    shades?: {
      ['4p']?: string;
      ['8p']?: string;
      ['12p']?: string;
      ['20p']?: string;
      ['30p']?: string;
      ['50p']?: string;
      ['70p']?: string;
    };
  }

  interface Theme {
    statusColours: StatusColours;
    mapBox: MapBoxColours;
    sizes: {
      XS: string;
      SM: string;
      MD: string;
      LG: string;
      XL: string;
    };
    elevation: {
      drawers: number;
      appBar: number;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    statusColours: StatusColours;
    mapBox: MapBoxColours;
    sizes: {
      XS: string;
      SM: string;
      MD: string;
      LG: string;
      XL: string;
    };
    elevation: {
      drawers: number;
      appBar: number;
    };
  }

  interface ZIndex {
    overlay: number;
    mapContextMenu: number;
    drawerNonModal: number;
    drawerNonModalTopOfStack: number;
    sidebarMenu: number;
    drawerModal: number;
    streetview: number;
    mapOverlay: number;
  }
}

declare module '@mui/material/Chip' {
  export interface ChipPropsColorOverrides {
    coolGrey: true;
  }
}
