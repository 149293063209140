import React from 'react';

import {DSL_ArrowBackIcon} from '../../Icons';
import {DSL_OutlinedButton, DSL_OutlinedButtonProps} from '../OutlinedButton';

export type DSL_PreviousButtonProps = Omit<
  DSL_OutlinedButtonProps,
  'variant' | 'children'
>;

export const DSL_PreviousButton = (props: DSL_PreviousButtonProps) => {
  return (
    <DSL_OutlinedButton startIcon={<DSL_ArrowBackIcon />} {...props}>
      Previous
    </DSL_OutlinedButton>
  );
};

DSL_PreviousButton.displayName = 'DSL_PreviousButton';

export {
  //
  /** @deprecated use DSL_PreviousButtonProps instead*/
  DSL_PreviousButtonProps as PreviousButtonProps, //
  /** @deprecated use DSL_PreviousButton instead*/
  DSL_PreviousButton as PreviousButton,
};
