import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/applications',
  component: lazy(
    () => import(/* webpackChunkName: "Applications" */ './Applications'),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'Applications',
  menuDescription:
    'Submit and track the status of your Design Approval and Vehicle Approval applications',
  menuIcon: LocalShippingOutlinedIcon,
  parent: true,
  authorisation: {
    apiKey: 'PbsApplicationWithRelations.findById',
    policies: [
      {role: 'operationsPBSRead'},
      {role: 'providerPBSRead'},
      {role: 'customerPBSEnabled'},
    ] as AuthorisationPolicy[],
  },
  sections: [],
};
