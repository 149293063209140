import React, {ReactNode} from 'react';

import {DSL_Box} from '../Box';

export interface DSL_FullHeightColumnPageLayoutHeaderProps {
  children?: ReactNode;
}

export const DSL_FullHeightColumnPageLayoutHeader = ({
  children,
}: DSL_FullHeightColumnPageLayoutHeaderProps) => (
  <DSL_Box width={'100%'}>{children}</DSL_Box>
);

export {
  //
  /** @deprecated use DSL_FullHeightColumnPageLayoutHeaderProps instead*/
  DSL_FullHeightColumnPageLayoutHeaderProps as FullHeightColumnPageLayoutHeaderProps, //
  /** @deprecated use DSL_FullHeightColumnPageLayoutHeader instead*/
  DSL_FullHeightColumnPageLayoutHeader as FullHeightColumnPageLayoutHeader,
};
