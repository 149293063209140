import React, {useMemo, useState} from 'react';
import * as R from 'ramda';
import {useMachine} from '@xstate/react';
import {useApolloClient} from '@apollo/client';
import {vehicleComponentsMachine} from '../../api/queryLoaderMachines';
import VehicleComponentImage from '../VehicleComponentImage';
import {VehicleComponentWithRelations} from 'typeDefs/types';
import NoVehicleComponent from '../NoVehicleComponent/NoVehicleComponent';
import {Box} from '@mui/system';

export type VehicleComponentImageProps = {
  vehicleComponentIds: number[];
  setBadges?: string[];
  mode?:
    | 'icon'
    | 'single'
    | 'card'
    | 'cardConfiguration'
    | 'configuration'
    | 'iconConfiguration';
};

export const VehicleConfigurationImages: React.FC<
  VehicleComponentImageProps
> = ({
  vehicleComponentIds,
  setBadges,
  mode = 'configuration',
}: VehicleComponentImageProps) => {
  if (R.isEmpty(vehicleComponentIds)) {
    return <NoVehicleComponent mode={mode} />;
  }
  /*
  The way useMachine works, it requires the same machine instance across every render. Because the machine relies on the vehicleComponentIds, we need to force a new component to be rendered whenever the vehicleComponentIds change
   */
  return (
    <VehicleConfigurationImagesInternal
      vehicleComponentIds={vehicleComponentIds}
      key={vehicleComponentIds.join(',')}
      setBadges={setBadges}
      mode={mode}
    />
  );
};

/**
 * VehicleComponentImage
 */
const VehicleConfigurationImagesInternal: React.FC<
  VehicleComponentImageProps
> = ({
  vehicleComponentIds,
  setBadges,
  mode = 'configuration',
}: VehicleComponentImageProps) => {
  const client = useApolloClient();
  // don't allow the vehicleComponentIds to change for the life of the component
  const [_vehicleComponentIds] = useState(vehicleComponentIds);
  const machine = useMemo(
    () => vehicleComponentsMachine(client, _vehicleComponentIds),
    [client, _vehicleComponentIds],
  );
  const [state] = useMachine(machine);
  const vehicleComponents = state.context.record as
    | VehicleComponentWithRelations[]
    | undefined;

  const vehicleComponentsToUse =
    state.matches('loading') || state.matches('error')
      ? null
      : vehicleComponents;

  return (
    <Box whiteSpace={'nowrap'}>
      {vehicleComponentIds.map((vehicleComponentId, idx) => {
        const vehicleComponent = R.find(
          rec => rec.id === vehicleComponentId,
          vehicleComponentsToUse || [],
        );
        return (
          <VehicleComponentImage
            key={idx + '-' + vehicleComponentId}
            vehicleComponent={vehicleComponent}
            idx={idx}
            mode={mode}
            badgeContent={
              R.isNil(setBadges) || setBadges.length < idx
                ? undefined
                : setBadges[idx]
            }
            disableRearOffset={idx + 1 === vehicleComponentIds.length}
          />
        );
      })}
    </Box>
  );
};

VehicleConfigurationImages.displayName = 'VehicleConfigurationImages';
