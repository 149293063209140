import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {VirtualElement} from '@popperjs/core';
import {v4} from 'uuid';

import {
  DSL_BreakpointValues,
  useCascadingBreakpointValue,
} from '../hooks/useCascadingBreakpointValue';

import {DSL_OpenerProps, DSL_PopperProps} from './Popper';
import {
  DSL_PopperProvider,
  usePopperContext,
} from './PopperProvider/PopperProvider';

export type DSL_PopperAnchor = 'left' | 'right';

export interface DSL_UsePopperProps {
  onClose?: () => void;
  onOpen?: () => void;
  anchor?: DSL_BreakpointValues<DSL_PopperAnchor | undefined>;
  isSingleton?: boolean;
  id?: string;
}

const virtualElementMap = {
  left: {
    getBoundingClientRect: () => ({top: 0, width: 0, height: 0, left: 0}),
  } as VirtualElement,
  right: {
    getBoundingClientRect: () => ({top: 0, width: 0, height: 0, right: 0}),
  } as VirtualElement,
};

export function usePopper<RefType extends HTMLElement>(
  props?: DSL_UsePopperProps,
) {
  const {anchor, isSingleton, onClose, onOpen} = props ?? {};
  const {createPopper, removePopper, closeOtherPoppers} =
    useValidatedContext(props) ?? {};

  const anchorRef = useRef<RefType>(null);
  const [isOpen, setIsOpenInternal] = useState(false);
  const anchorForBp = useCascadingBreakpointValue(anchor);

  const v4Id = useMemo(() => v4(), []);
  const id = props?.id ?? v4Id;

  const closePopperInternal = useCallback(() => {
    setIsOpenInternal(false);
    onClose?.();
  }, [onClose]);

  const setIsOpen: typeof setIsOpenInternal = useCallback(
    value => {
      const handleNewState = (newState: boolean): boolean => {
        if (newState) {
          onOpen?.();
          if (isSingleton) {
            closeOtherPoppers?.(id);
          }
        } else {
          onClose?.();
        }
        return newState;
      };

      if (typeof value === 'function') {
        setIsOpenInternal(prevIsOpen => handleNewState(value(prevIsOpen)));
      } else {
        setIsOpenInternal(handleNewState(value));
      }
    },
    [closeOtherPoppers, id, isSingleton, onClose, onOpen],
  );

  const closePopper: DSL_PopperProps['closePopper'] = useCallback(
    event => {
      if (anchorRef.current?.contains(event?.target as RefType)) {
        return;
      }
      if (isOpen) {
        setIsOpen(false);
      }
    },
    [isOpen, setIsOpen],
  );

  const toggleIsOpen = useCallback(
    () => setIsOpen(prevIsOpen => !prevIsOpen),
    [setIsOpen],
  );

  const {popperProps, openerProps} = useMemo(
    () => ({
      popperProps: {
        closePopper,
        anchorEl: anchorForBp
          ? virtualElementMap[anchorForBp]
          : anchorRef.current,
        open: isOpen,
      } as DSL_PopperProps,
      openerProps: {
        onClick: toggleIsOpen,
        ref: anchorRef,
      } as DSL_OpenerProps<RefType>,
    }),
    [closePopper, anchorForBp, isOpen, toggleIsOpen],
  );

  useEffect(() => {
    createPopper?.({
      id,
      closePopper: closePopperInternal,
    });
    return () => removePopper?.(id);
  }, [createPopper, id, removePopper, closePopperInternal]);

  return {
    id,
    popperProps,
    openerProps,
    toggleIsOpen,
    setIsOpen,
  };
}

function useValidatedContext(props?: DSL_UsePopperProps) {
  const context = usePopperContext();

  if (!context && props?.isSingleton) {
    throw new Error(
      `No ${DSL_PopperProvider.displayName} in the hierarchy, but 'isSingleton' specified. Add a parent ${DSL_PopperProvider.displayName} to use this feature.`,
    );
  }

  return context;
}
