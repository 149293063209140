import React from 'react';

import {DSL_CloseIcon} from '../../../Icons';
import {DSL_IconProps} from '../../../Icons/types';
import {DSL_BaseIconButton, DSL_BaseIconButtonProps} from '../BaseIconButton';

export interface DSL_CloseIconButtonProps
  extends Omit<DSL_BaseIconButtonProps, 'children' | 'color'> {
  color?: DSL_IconProps['color'];
}

export const DSL_CloseIconButton = ({
  size = 'large',
  color,
  ...props
}: DSL_CloseIconButtonProps): JSX.Element => {
  return (
    <DSL_BaseIconButton aria-label="close" size={size} {...props}>
      <DSL_CloseIcon color={color} fontSize={size} />
    </DSL_BaseIconButton>
  );
};

DSL_CloseIconButton.displayName = 'DSL_CloseIconButton';
