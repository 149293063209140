import Layers from '@mui/icons-material/Layers';
import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/accessVehicleCategorys',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "AccessVehicleCategorys" */ './AccessVehicleCategorys'
      ),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'Access vehicle category',
  menuDescription: 'Access vehicle categories',
  menuIcon: Layers,
  parent: true,
  authorisation: {
    apiKey: 'AccessVehicleCategoryWithRelations.findById',
    policies: [
      {
        role: 'operationsSpatialRead',
      },
    ] as AuthorisationPolicy[],
  },
  sections: [],
};
