import React from 'react';

import {
  DSL_ScrollableLayoutFooter,
  DSL_ScrollableLayoutFooterProps,
} from '../../Layout/ScrollableLayout';

export type DSL_DrawerFooterProps = DSL_ScrollableLayoutFooterProps;

export const DSL_DrawerFooter = (props: DSL_DrawerFooterProps) => {
  return <DSL_ScrollableLayoutFooter {...props} />;
};

DSL_DrawerFooter.displayName = 'DSL_DrawerFooter';

export {
  //
  /** @deprecated use DSL_DrawerFooterProps instead*/
  DSL_DrawerFooterProps as DrawerFooterProps, //
  /** @deprecated use DSL_DrawerFooter instead*/
  DSL_DrawerFooter as DrawerFooter,
};
