import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/accessProducts/:id',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "AccessProductDetails" */ './AccessProductDetails'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Manage reference vehicle',
  menuIcon: LibraryAddCheckIcon,
  authorisation: {
    apiKey: 'AccessProductWithRelations.findById',
    policies: [{role: 'operationsSpatialRead'}] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Reference vehicles',
      to: '..',
    },
  ],
};
