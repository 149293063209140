import React, {useMemo, useContext} from 'react';
import {useSelector} from '@xstate/react';
import {useLocation, useNavigate} from 'react-router';
import getSideBarSections from 'app/router/utils/getSideBarSections';
import {DSL_HomeOutlined} from 'components/DesignSystem/Library/Icons';
import {useUserProfile} from 'app/services/useUserProfile';
import {getEnvironmentStatus} from 'utils/global';
import AppServicesContext from 'app/services/AppServicesContext';

export const useSidebar = () => {
  const year = new Date().getFullYear();
  const {version, releaseStartDate} = getEnvironmentStatus();
  const {auth} = useContext(AppServicesContext);
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const userProfile = useUserProfile();
  const sections = useMemo(
    () => getSideBarSections(userProfile, pathname),
    [userProfile, pathname],
  );
  const showInternalLinks = useSelector(auth.service, state => {
    return state.matches('authenticated') || state.matches('loggingOut');
  });

  return useMemo(
    () => ({
      internalLinks: showInternalLinks
        ? [
            {
              id: '/',
              menuTitle: 'Home',
              icon: <DSL_HomeOutlined />,
            },
            ...sections.slice(1).map(section => {
              const Icon = section?.icon ?? DSL_HomeOutlined;
              return {
                id: section.id,
                menuTitle: section?.menuTitle ?? '',
                icon: <Icon className="" />,
                items: section.items?.map(item => ({
                  id: item.id,
                  menuTitle: item.menuTitle,
                  selected: item.selected,
                })),
              };
            }),
          ]
        : [],
      handleClick: (id: string, href?: string) => {
        if (href) {
          window?.open(href, '_blank')?.focus();
          return;
        }
        navigate(id);
      },
      year,
      version,
      pathname,
      releaseStartDate,
    }),
    [
      showInternalLinks,
      sections,
      pathname,
      year,
      version,
      releaseStartDate,
      navigate,
    ],
  );
};
