import React, {useState} from 'react';

import {Button as MuiButton} from '@mui/material';
import {useCopyToClipboard} from 'usehooks-ts';

import {DSL_CopyAllIcon} from '../Icons';
import {DSL_FlexColumn} from '../Layout/FlexColumn';
import {DSL_FlexRow} from '../Layout/FlexRow';

export interface DSL_CopyAbleProps {
  children: React.ReactNode;
  copyText: string;
}

export const DSL_CopyAble = ({children, copyText}: DSL_CopyAbleProps) => {
  const [copied, setCopied] = useState(false);
  const [, copy] = useCopyToClipboard();

  const onClick = async () => {
    await copy(copyText);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };
  return (
    <DSL_FlexRow
      width={'100%'}
      justifyContent={'space-between'}
      alignItems={'center'}
      gap={1}
    >
      <DSL_FlexColumn flexGrow={1}>{children}</DSL_FlexColumn>
      <DSL_FlexColumn>
        <MuiButton
          variant={'text'}
          onClick={onClick}
          sx={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0, boxShadow: 0}}
        >
          {copied ? 'COPIED' : <DSL_CopyAllIcon />}
        </MuiButton>
      </DSL_FlexColumn>
    </DSL_FlexRow>
  );
};

DSL_CopyAble.displayName = 'DSL_CopyAble';
