import React from 'react';

import {DSL_BaseAlert, DSL_BaseAlertProps} from '../BaseAlert';

export type DSL_GlobalWarningAlertProps = Omit<
  DSL_BaseAlertProps,
  'variant' | 'severity'
>;

export const DSL_GlobalWarningAlert = (props: DSL_GlobalWarningAlertProps) => {
  return <DSL_BaseAlert severity="warning" {...props} />;
};

DSL_GlobalWarningAlert.displayName = 'DSL_GlobalWarningAlert';

export {
  //
  /** @deprecated use DSL_GlobalWarningAlertProps instead*/
  DSL_GlobalWarningAlertProps as GlobalWarningAlertProps, //
  /** @deprecated use DSL_GlobalWarningAlert instead*/
  DSL_GlobalWarningAlert as GlobalWarningAlert,
};
