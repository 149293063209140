import React, {Fragment} from 'react';

import {SegmentAccessType} from 'components/DesignSystem/Portal/Layout/Segments';
import {
  segmentAccessTitleMap,
  SegmentAccessStatus,
} from 'components/DesignSystem/Portal/Layout/Segments/constants';

import {
  DSL_Popper,
  DSL_PopperBody,
  DSL_FlexColumn,
  DSL_Heading6,
  DSL_Flex,
  DSL_RadioGroupInput,
  DSL_Divider,
  DSL_PopperFooter,
  DSL_Box,
  DSL_TextButton,
  DSL_Option,
} from 'components/DesignSystem/Library';

import {Conflict} from './Conflict';
import {ContextualOption} from './ContextualOption';
import {ExternalLinkOption} from './ExternalLinkOption';
import {NestedOption} from './NestedOption';
import {RevertChange} from './RevertChange';
import {useSelectedSegmentChangeOptions} from './useSelectedSegmentChangeOptions';

export interface DSP_SelectedSegmentChangeOptionsProps {
  segmentId: number;
  currentAccessType?: SegmentAccessType;
  segmentAccessType: SegmentAccessType;
  lastAppliedAccessType?: SegmentAccessType;
}

export const DSP_SelectedSegmentChangeOptions = ({
  segmentId,
  currentAccessType,
  segmentAccessType,
  lastAppliedAccessType,
}: DSP_SelectedSegmentChangeOptionsProps) => {
  const {
    radioOptions,
    popperProps,
    openerProps,
    options,
    onChangeButtonClick,
    onRevertChangesClick,
    onRadioChange,
    onRadioClick,
    title,
    onClearChangesClick,
    conflicts,
  } = useSelectedSegmentChangeOptions({
    segmentAccessType,
    currentAccessType,
    segmentId,
    lastAppliedAccessType,
  });

  return (
    <>
      <DSL_Popper
        maxWidth={{xs: 310}}
        placement={'right-start'}
        {...popperProps}
      >
        <DSL_PopperBody padding={'NONE'}>
          <DSL_FlexColumn
            gap={0}
            pb={radioOptions?.length > 0 ? 1 : 0}
            data-name={'popper-body-content-wrapper'}
          >
            <DSL_FlexColumn gap={0} data-name={'radio-options-wrapper'}>
              {title && (
                <DSL_Heading6 bold px={2} py={2}>
                  {title}
                </DSL_Heading6>
              )}
              {conflicts.length > 0 &&
                conflicts.map((conflict, index) => (
                  <Conflict
                    key={index}
                    label={conflict.label}
                    subLabel={conflict.subLabel}
                    actions={conflict?.actions ?? []}
                  />
                ))}

              <DSL_Flex px={2}>
                <DSL_RadioGroupInput
                  boldSelectedOption
                  onChange={onRadioChange}
                  onClick={onRadioClick}
                  value={segmentAccessType}
                  options={radioOptions as DSL_Option[]}
                />
              </DSL_Flex>
            </DSL_FlexColumn>
            {currentAccessType &&
              onRevertChangesClick &&
              lastAppliedAccessType && (
                <>
                  <DSL_Flex pt={1} />
                  <DSL_Divider />
                  <DSL_Flex pt={1} />
                  <RevertChange
                    onClick={() => {
                      onRevertChangesClick?.();
                      popperProps.closePopper();
                    }}
                    segmentAccessType={lastAppliedAccessType}
                    label={
                      lastAppliedAccessType === currentAccessType
                        ? segmentAccessTitleMap[SegmentAccessStatus.CURRENT][
                            lastAppliedAccessType
                          ]
                        : segmentAccessTitleMap[SegmentAccessStatus.DRAFT][
                            lastAppliedAccessType
                          ]
                    }
                  />
                </>
              )}
            {currentAccessType && onClearChangesClick && (
              <>
                <DSL_Flex pt={1} />
                <DSL_Divider />
                <DSL_Flex pt={1} />
                <RevertChange
                  clearChanges
                  onClick={() => {
                    onClearChangesClick?.();
                    popperProps.closePopper();
                  }}
                  segmentAccessType={currentAccessType}
                  label={
                    segmentAccessTitleMap[SegmentAccessStatus.CURRENT][
                      currentAccessType
                    ]
                  }
                />
              </>
            )}
          </DSL_FlexColumn>
        </DSL_PopperBody>
        {options.length > 0 && (
          <DSL_PopperFooter padding={'NONE'}>
            <DSL_FlexColumn
              gap={0}
              py={1}
              width={'100%'}
              data-name={'options-wrapper'}
            >
              {options.map((option, index) => (
                <Fragment key={index}>
                  {option?.options ? (
                    <NestedOption key={index} {...option} {...popperProps} />
                  ) : option.href || option.to ? (
                    <ExternalLinkOption
                      key={index}
                      {...option}
                      closePopper={popperProps.closePopper}
                    />
                  ) : (
                    <ContextualOption
                      key={index}
                      {...option}
                      closePopper={popperProps.closePopper}
                    />
                  )}
                </Fragment>
              ))}
            </DSL_FlexColumn>
          </DSL_PopperFooter>
        )}
      </DSL_Popper>
      <DSL_Box>
        <DSL_TextButton
          size={'small'}
          {...openerProps}
          onClick={event => {
            onChangeButtonClick(segmentId);
            openerProps.onClick(event);
          }}
        >
          Change
        </DSL_TextButton>
      </DSL_Box>
    </>
  );
};
