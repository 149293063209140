import {useNavigate} from 'react-router-dom';
import {NotificationItem} from 'utils/machines/notificationsMachine';

import {roadManagementTypeMessageMap} from './constants';
import {DSP_RoadManagementNotificationCardViewProps} from './RoadManagementNotificationCard.View';

export interface DSP_UseRoadManagementCardProps {
  notification: NotificationItem;
}

export function useRoadManagementCard({
  notification,
}: DSP_UseRoadManagementCardProps): DSP_RoadManagementNotificationCardViewProps {
  const navigate = useNavigate();

  const handleMenuItemClick = (): void => {
    // For all Road Management notifications, we simply navigate to the proposal URL.
    // Our Map then loads the proposal and appropriate segments on the map
    if (notification?.data?.proposalUUID) {
      navigate(`/access/road-management/${notification.data.proposalUUID}`, {
        replace: true,
      });
    }
  };

  return {
    created: notification.created,
    readByLoggedInUser: notification.readByLoggedInUser,
    message: roadManagementTypeMessageMap[notification.notificationType],
    roadManagerName: notification?.data?.roadManagerName as
      | string[]
      | undefined as string | undefined,
    roadName: notification.data.roadName as string | undefined,
    suburb: notification.data.suburb as string | undefined,
    onClick: handleMenuItemClick,
  };
}
