import {
  schemaJsonPointerSettor,
  ValidatorResult,
} from '@regulatory-platform/common-utils';
import * as R from 'ramda';
import {FormEventObject, FormMachineContext} from './types';
import {ObjectType} from '@regulatory-platform/common-utils/dist/types';

/**
 * Set record and schema from onLocalValidatorExec and reset x-dirty flag
 */
export default function onAfterValidate<T extends ObjectType>(
  context: FormMachineContext<T>,
  event: FormEventObject,
): FormMachineContext<T> {
  const result = event?.data as
    | (ValidatorResult & {fieldRef: string})
    | undefined;
  if (R.isNil(result)) {
    return context;
  }
  const nextContext = context;
  nextContext.record = result.processedRecord as T;
  nextContext.schema = result.validatedSchema;
  nextContext.schema = schemaJsonPointerSettor(
    result.fieldRef,
    'x-dirty',
    false,
  )(nextContext.schema);
  return nextContext;
}
