import React from 'react';

import {DSL_LocationOnIcon} from '../../Icons';
import {DSL_BaseIconInfo, DSL_BaseIconInfoProps} from '../BaseIconInfo';

export type DSL_LocationOnIconInfoProps = Omit<DSL_BaseIconInfoProps, 'icon'>;

export const DSL_LocationOnIconInfo = (props: DSL_LocationOnIconInfoProps) => {
  return <DSL_BaseIconInfo icon={<DSL_LocationOnIcon />} {...props} />;
};

DSL_LocationOnIconInfo.displayName = 'DSL_LocationOnIconInfo';
