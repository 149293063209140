import React from 'react';

import {
  DSP_UserEntityLoaderProvider,
  DSP_UserEntityAvatarWithName,
} from 'components/DesignSystem/Portal/Entity/UserEntity';

import {
  DSP_BaseWorkflowActivityNotificationCard,
  DSP_BaseWorkflowActivityNotificationCardProps,
} from '../Base/BaseWorkflowActivityNotificationCard.Connector';

export interface DSP_WorkflowActivityNotificationCardViewProps
  extends Omit<DSP_BaseWorkflowActivityNotificationCardProps, 'avatar'> {
  userId: number | null | undefined;
}

export const DSP_WorkflowActivityNotificationCardView = ({
  notification,
  userId,
}: DSP_WorkflowActivityNotificationCardViewProps) => {
  return (
    <DSP_BaseWorkflowActivityNotificationCard
      notification={notification}
      avatar={
        userId && (
          <DSP_UserEntityLoaderProvider entityId={userId}>
            <DSP_UserEntityAvatarWithName />
          </DSP_UserEntityLoaderProvider>
        )
      }
    />
  );
};

DSP_WorkflowActivityNotificationCardView.displayName =
  'DSP_WorkflowActivityNotificationCardView';

export {
  //
  /** @deprecated use DSP_WorkflowActivityNotificationCardViewProps instead*/
  DSP_WorkflowActivityNotificationCardViewProps as WorkflowActivityNotificationCardViewProps, //
  /** @deprecated use DSP_WorkflowActivityNotificationCardView instead*/
  DSP_WorkflowActivityNotificationCardView as WorkflowActivityNotificationCardView,
};
