import React, {ReactNode} from 'react';

import {useCurrentBreakpoint} from 'components/DesignSystem/Library/hooks/useCurrentBreakpoint';
import {
  DSL_ButtonCardActionArea,
  DSL_ButtonCardActionAreaProps,
} from 'components/DesignSystem/Library/Layout/Card/CardActionArea';
import {DSL_CardContent} from 'components/DesignSystem/Library/Layout/Card/CardContent';
import {DSL_ElevatedCard} from 'components/DesignSystem/Library/Layout/Card/ElevatedCard';

import {DSL_BorderlessCard} from '../../Layout/Card/BorderlessCard';

export interface DSL_BaseCardIconButtonProps
  extends Pick<DSL_ButtonCardActionAreaProps, 'onClick' | 'disabled'> {
  children: ReactNode;
  elevated?: boolean;
}

export const DSL_BaseCardIconButton = ({
  children,
  onClick,
  disabled,
  elevated = true,
}: DSL_BaseCardIconButtonProps) => {
  const currentBreakpoint = useCurrentBreakpoint();
  const Card = elevated ? DSL_ElevatedCard : DSL_BorderlessCard;
  return (
    <Card square>
      <DSL_ButtonCardActionArea onClick={onClick} disabled={disabled}>
        <DSL_CardContent
          padding={['xs', 'sm', 'md'].includes(currentBreakpoint) ? 'SM' : 'XS'}
        >
          {children}
        </DSL_CardContent>
      </DSL_ButtonCardActionArea>
    </Card>
  );
};

DSL_BaseCardIconButton.displayName = 'DSL_BaseCardIconButton';
