import React from 'react';

import {useTheme} from '@mui/material';

import {DSL_Box, DSL_BoxProps} from '../../Box';

export const DSL_CardActionAreaExclusion = (
  props: Omit<DSL_BoxProps, 'zIndex'>,
) => {
  const theme = useTheme();
  return (
    <DSL_Box
      data-name={'card-action-area-exclusion'}
      zIndex={theme.zIndex.overlay}
      {...props}
    />
  );
};

DSL_CardActionAreaExclusion.displayName = 'DSL_CardActionAreaExclusion';

export {
  /** @deprecated use DSL_CardActionAreaExclusion instead*/
  DSL_CardActionAreaExclusion as CardActionAreaExclusion,
};
