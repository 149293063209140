import React, {ReactNode} from 'react';

import {Box, BoxProps} from '@mui/material';
import {DSL_Caption} from 'components/DesignSystem/Library/Typography/Caption';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

export interface DSL_SidebarMenuCaptionProps
  extends Omit<BoxProps, DSL_RestrictedVisualProps> {
  text: ReactNode;
}

export const DSL_SidebarMenuCaption = ({
  text,
  ...boxProps
}: DSL_SidebarMenuCaptionProps) => {
  return (
    <Box {...boxProps} sx={{p: 3}}>
      <DSL_Caption>{text}</DSL_Caption>
    </Box>
  );
};

DSL_SidebarMenuCaption.displayName = 'DSL_SidebarMenuCaption';

export {
  //
  /** @deprecated use DSL_SidebarMenuCaptionProps instead*/
  DSL_SidebarMenuCaptionProps as SidebarMenuCaptionProps, //
  /** @deprecated use DSL_SidebarMenuCaption instead*/
  DSL_SidebarMenuCaption as SidebarMenuCaption,
};
