import {
  CommonValidator,
  validatorExec,
} from '@regulatory-platform/common-utils';
import * as R from 'ramda';
import getSharedDataFromEvent from './getSharedDataFromEvent';
import {FormEventObject, FormMachineContext, FormRecord} from './types';

export default function onAddSharedDataHandler<T extends FormRecord>(
  context: FormMachineContext<T>,
  event: FormEventObject,
  validator: CommonValidator,
): FormMachineContext<T> {
  const nextContext = getSharedDataFromEvent(context, event);
  const {record: origRecord, schema: origSchema, shared} = nextContext;

  const result = validatorExec(
    {
      record: origRecord,
      origRecord,
      schema: origSchema,
      isClient: true,
      methodName: context.methodName,
      userProfile: context.authorisations?.userProfile,
      shared,
    },
    validator,
  );

  if (context.record !== result?.processedRecord) {
    nextContext.record = result?.processedRecord as T;
  }
  if (!R.isNil(result) && context.schema !== result.validatedSchema) {
    nextContext.schema = result?.validatedSchema;
  }
  return nextContext;
}
