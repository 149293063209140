import React from 'react';

import {
  TableCell as MuiTableCell,
  TableCellProps as MuiTableCellProps,
  useTheme,
} from '@mui/material';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

export type DSL_TableCellProps = Omit<
  MuiTableCellProps,
  DSL_RestrictedVisualProps
> & {
  pinLeft?: boolean;
  width?: React.CSSProperties['width'];
  minWidth?: React.CSSProperties['minWidth'];
  maxWidth?: React.CSSProperties['maxWidth'];
  verticalAlign?: React.CSSProperties['verticalAlign'];
  borderBottom?: React.CSSProperties['borderBottom'];
  borderTop?: React.CSSProperties['borderTop'];
  paddingTop?: React.CSSProperties['paddingTop'];
  textOverflow?: React.CSSProperties['textOverflow'];
  overflow?: React.CSSProperties['overflow'];
};

export const DSL_TableCell = ({
  pinLeft = false,
  minWidth = 'fit-content',
  width = 'fit-content',
  verticalAlign = 'top',
  maxWidth,
  color,
  borderBottom,
  borderTop,
  paddingTop,
  textOverflow,
  overflow,
  ...props
}: DSL_TableCellProps) => {
  const theme = useTheme();
  return (
    <MuiTableCell
      {...props}
      sx={{
        position: pinLeft ? 'sticky' : undefined,
        left: pinLeft ? 0 : undefined,
        bgcolor: 'background.paper',
        zIndex: pinLeft ? theme.zIndex.overlay : undefined,
        width,
        minWidth,
        maxWidth,
        verticalAlign,
        color,
        borderBottom,
        borderTop,
        paddingTop,
        textOverflow,
        overflow,
      }}
    />
  );
};

DSL_TableCell.displayName = 'DSL_TableCell';

export {
  //
  /** @deprecated use DSL_TableCellProps instead*/
  DSL_TableCellProps as TableCellProps, //
  /** @deprecated use DSL_TableCell instead*/
  DSL_TableCell as TableCell,
};
