import React, {forwardRef} from 'react';

import {
  FlexRow,
  MoreHorizIconButton,
  Tooltip,
  TooltipProps,
} from 'components/DesignSystem/Library';

export interface DSP_AccountToolTipProps
  extends Omit<TooltipProps, 'title' | 'onClick' | 'children'> {
  open?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export const DSP_AccountToolTip = forwardRef(
  ({open, onClick, ...tooltipProps}: DSP_AccountToolTipProps, ref) => {
    return (
      <Tooltip ref={ref} title={open ? '' : 'Account'} {...tooltipProps}>
        <FlexRow alignItems={'center'} onClick={onClick} cursor={'pointer'}>
          <MoreHorizIconButton
            id="Account-button"
            aria-controls={open ? 'Account-list' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            aria-label="Toggle Account menu"
          />
        </FlexRow>
      </Tooltip>
    );
  },
);

DSP_AccountToolTip.displayName = 'DSP_AccountToolTip';

export {
  //
  /** @deprecated use DSP_AccountToolTipProps instead*/
  DSP_AccountToolTipProps as AccountToolTipProps, //
  /** @deprecated use DSP_AccountToolTip instead*/
  DSP_AccountToolTip as AccountToolTip,
};
