import {DSP_AccessProductChangeSetNotificationItem} from '../types';

import {DSP_AccessProductChangeSetOperationsNotificationCardViewProps} from './AccessProductChangeSetOperationsNotificationCard.View';

export interface DSP_UseAccessProductChangeSetNotificationCardProps {
  notification: DSP_AccessProductChangeSetNotificationItem;
}

export function useAccessProductChangeSetOperationsNotificationCard({
  notification,
}: DSP_UseAccessProductChangeSetNotificationCardProps): DSP_AccessProductChangeSetOperationsNotificationCardViewProps {
  return {
    notification,
    partnerAccountId: notification.data.partnerAccount?.id,
  };
}
