import React, {ForwardedRef, forwardRef, ReactNode} from 'react';

import {DSL_Box, DSL_BoxProps} from '../../Layout/Box';
import {DSL_SkeletonLoader} from '../../Skeleton/SkeletonLoader';

export interface DSL_EntityTitleProps extends Omit<DSL_BoxProps, 'ref'> {
  isLoading: boolean;
  skeletonWidth?: number;
  skeletonHeight?: number;
  children?: ReactNode;
}

export const DSL_EntityTitle = forwardRef(
  (
    {
      skeletonWidth = 225,
      children,
      isLoading,
      skeletonHeight,
      ...props
    }: DSL_EntityTitleProps,
    ref?: ForwardedRef<HTMLElement>,
  ) => (
    <DSL_Box ref={ref} component={'span'} display={'inline-block'} {...props}>
      <DSL_SkeletonLoader
        isLoading={isLoading}
        width={skeletonWidth}
        height={skeletonHeight}
      >
        {children}
      </DSL_SkeletonLoader>
    </DSL_Box>
  ),
);

DSL_EntityTitle.displayName = 'DSL_EntityTitle';

export {
  //
  /** @deprecated use DSL_EntityTitleProps instead*/
  DSL_EntityTitleProps as EntityTitleProps, //
  /** @deprecated use DSL_EntityTitle instead*/
  DSL_EntityTitle as EntityTitle,
};
