import * as R from 'ramda';
import {useEffect} from 'react';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {FormStoreContainer} from 'utils/stores/types';
import {TabStep} from '../types';

export interface LocationStateTab {
  [key: string]: string;
}
export type useTabsResults = [TabStep, (label: string) => void];

export default function useTabs(
  label: string,
  tabSteps: TabStep[],
  service?: FormStoreContainer['service'],
  tabsUrlParam?: string,
): useTabsResults {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const initialTab = tabsUrlParam
    ? searchParams.get(tabsUrlParam)
    : (location.state as LocationStateTab)?.[label];

  const selectedTab = R.defaultTo(tabSteps[0].label, initialTab);

  const activeStep = R.defaultTo(
    tabSteps[0],
    R.find(R.propEq('label', selectedTab), tabSteps),
  );

  useEffect(() => {
    if (tabsUrlParam) {
      searchParams.set(tabsUrlParam, activeStep.label);
      setSearchParams(searchParams);
    }
  }, [tabsUrlParam, searchParams, setSearchParams, activeStep.label, label]);

  const onStepChange = (nextTabLabel: string): void => {
    const nextStep = R.find(R.propEq('label', nextTabLabel), tabSteps);
    if (R.isNil(nextStep)) {
      return;
    }
    if (!R.isNil(service) && !R.isNil(activeStep.validateFields)) {
      const validateFields = R.defaultTo([], activeStep.validateFields);
      service.send({
        type: 'TAB_TOUCHED',
        fieldRef: activeStep.label,
        fieldRefs: validateFields,
      });
    }
    if (tabsUrlParam) {
      searchParams.set(tabsUrlParam, nextTabLabel);
    }
    const search = searchParams.toString();
    navigate(location.pathname + search ? `?${search}` : '', {
      state: R.assoc(label, nextTabLabel, location.state),
    });
    if (!R.isNil(service) && nextStep.onClickBlurField) {
      service.send({
        type: 'BLUR',
        fieldRef: nextStep.onClickBlurField as string,
      });
    }
    if (!R.isNil(service) && nextStep.onClickValidateField) {
      service.send({
        type: 'VALIDATE',
        fieldRef: nextStep.onClickValidateField as string,
      });
    }
  };
  return [activeStep, onStepChange];
}
