import React from 'react';

import {DSL_IconWrapper} from '../IconWrapper';
import {DSL_IconProps} from '../types';

const DSL_Roundabout5 = (props: DSL_IconProps) => {
  return (
    <DSL_IconWrapper
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          fill="none"
          viewBox="0 0 60 60"
          {...props}
        >
          <g clipPath="url(#a)">
            <path fill="#fff" d="M0 0h60v60H0z" />
            <path
              fill="#000"
              fillOpacity={0.3}
              fillRule="evenodd"
              d="M30 21.5a8.5 8.5 0 1 0 0 17 8.5 8.5 0 0 0 0-17M16.5 30c0-7.456 6.044-13.5 13.5-13.5S43.5 22.544 43.5 30 37.456 43.5 30 43.5 16.5 37.456 16.5 30"
              clipRule="evenodd"
            />
            <path
              fill="#000"
              fillRule="evenodd"
              d="M21.5 30a8.5 8.5 0 0 1 17 0c0 .993-.17 1.941-.478 2.82-.69 1.964-.183 4.68 2.176 5.916l4.366 2.287-4.221 1.12 1.283 4.833 12.668-3.364-3.365-12.668-4.832 1.284 1.22 4.592-4.562-2.389c.483-1.39.745-2.883.745-4.431 0-7.456-6.044-13.5-13.5-13.5S16.5 22.544 16.5 30c0 6.603 4.738 12.095 11 13.269V51h5v-8c0-2.575-2.045-4.274-4.04-4.639A8.5 8.5 0 0 1 21.5 30m26.607 9.796-.174.332.245-.065z"
              clipRule="evenodd"
            />
          </g>
          <defs>
            <clipPath id="a">
              <path fill="#fff" d="M0 0h60v60H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      {...props}
    />
  );
};

export default React.memo(DSL_Roundabout5);
