import Layers from '@mui/icons-material/Layers';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {Fragment, lazy} from 'react';
import Redirect from './Redirect';

export default {
  path: '/standalone',
  component: lazy(
    () => import(/* webpackChunkName: "Network" */ './Network.Connector'),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'Standalone networks',
  menuDescription: 'Standalone network maps',
  menuIcon: Layers,
  parent: true,
  authorisation: {
    apiKey: 'Network.findById',
    policies: [
      {role: 'operationsSpatialRead'},
      {role: 'partnerSpatialRead'},
    ] as AuthorisationPolicy[],
  },
  category: 'Map',
  sections: [],
  nestedRoutes: [
    {
      path: '',
      component: Fragment,
    },
    {
      path: 'create',
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "createNetwork" */ 'containers-spatial/NetworkManagement/NetworkDetails/Create'
          ),
      ),
    },
    {
      path: ':networkId',
      component: Redirect,
    },
    {
      path: ':networkId/view',
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "viewNetwork" */ 'containers-spatial/NetworkManagement/NetworkDetails/View'
          ),
      ),
    },
    {
      path: ':networkId/edit',
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "editNetwork" */ 'containers-spatial/NetworkManagement/NetworkDetails/Edit'
          ),
      ),
    },
    {
      path: ':networkId/duplicate',
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "dupeNetwork" */ 'containers-spatial/NetworkManagement/NetworkDetails/Duplicate'
          ),
      ),
    },
    {
      path: ':networkId/nur/:nurId',
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "manageNur" */ 'containers-spatial/NetworkManagement/NetworkUpdateRequests/Edit'
          ),
      ),
    },
  ],
};
