import React from 'react';

import {
  Accordion as MuiAccordion,
  AccordionProps as MuiAccordionProps,
} from '@mui/material';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

export type DSL_AccordionProps = Omit<
  MuiAccordionProps,
  DSL_RestrictedVisualProps
>;

export const DSL_Accordion = (props: DSL_AccordionProps) => {
  return (
    <MuiAccordion
      TransitionProps={{unmountOnExit: true}}
      sx={{width: '100%'}}
      {...props}
    />
  );
};

DSL_Accordion.displayName = 'DSL_Accordion';

export {
  //
  /** @deprecated use DSL_AccordionProps instead*/
  DSL_AccordionProps as AccordionProps, //
  /** @deprecated use DSL_Accordion instead*/
  DSL_Accordion as Accordion,
};
