import React from 'react';

import {
  CircularProgress as MuiCircularProgress,
  CircularProgressProps as MuiCircularProgressProps,
} from '@mui/material';

import {DSL_RestrictedVisualProps} from '../types';

export interface DSL_CircularProgressProps
  extends Omit<MuiCircularProgressProps, DSL_RestrictedVisualProps> {
  isAction?: boolean;
}

export const DSL_CircularProgress = ({
  isAction,
  ...props
}: DSL_CircularProgressProps) => {
  return (
    <MuiCircularProgress
      color={isAction ? undefined : 'inherit'}
      sx={{color: props.color ?? (isAction ? 'action.active' : 'inherit')}}
      size={20}
      {...props}
    />
  );
};

DSL_CircularProgress.displayName = 'DSL_CircularProgress';

export {
  //
  /** @deprecated use DSL_CircularProgressProps instead*/
  DSL_CircularProgressProps as CircularProgressProps, //
  /** @deprecated use DSL_CircularProgress instead*/
  DSL_CircularProgress as CircularProgress,
};
