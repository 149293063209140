import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/accessInstruments/:accessInstrumentId/accessProduct/:id/createAccessProductApplication',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "CreateAccessProductApplication" */ 'containers-access/AccessProductApplication/CreateAccessProductApplication/CreateAccessProductApplication'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Add case',
  menuIcon: LocalShippingOutlinedIcon,
  authorisation: {
    apiKey: 'AccessProductApplicationWithRelations.create',
    policies: [
      {
        role: 'operationsSpatialSupervisor',
      },
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Access instruments',
      to: '../../../..',
    },
    {
      content: 'Manage access instrument',
      to: '../../..',
      state: {'Access instrument tabs': 'Reference vehicles'},
    },
    {
      content: 'Manage reference vehicle',
      to: '..',
      state: {'Access Product Tabs': 'Cases'},
    },
  ],
};
