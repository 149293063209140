import {updateFieldPropOnSubSchema} from '@regulatory-platform/common-utils';
import * as R from 'ramda';
import {FormEventObject, FormMachineContext} from './types';

export default function onUpdateSchemaEventHandler<
  C extends FormMachineContext,
>(context: C, event: FormEventObject, updateArrayProp = true): C {
  const {propRef, value, fieldRefs} = event;
  if (R.isNil(propRef)) {
    return context;
  }
  let fieldsRefsToUpdate = fieldRefs;
  if (R.isNil(fieldsRefsToUpdate)) {
    fieldsRefsToUpdate = R.keys(R.defaultTo({}, context.record)) as string[];
  }
  const schema = R.reduce(
    (accSchema, fieldRef) => {
      return updateFieldPropOnSubSchema(
        accSchema,
        fieldRef,
        propRef,
        value,
        false, //if field doesn't exist then create
        updateArrayProp,
      )(accSchema);
    },
    context.schema,
    fieldsRefsToUpdate,
  );

  const nextContext = context;
  if (context.schema !== schema) {
    nextContext.schema = schema;
  }
  return nextContext;
}
