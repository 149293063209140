import React, {ReactNode} from 'react';

import {DSL_FlexColumn} from '../FlexColumn';

export interface DSL_FullHeightColumnPageLayoutMainProps {
  id?: string;
  children?: ReactNode;
}

export const DSL_FullHeightColumnPageLayoutMain = ({
  id,
  children,
}: DSL_FullHeightColumnPageLayoutMainProps) => (
  <DSL_FlexColumn
    id={id}
    component={'main'}
    width={'100%'}
    flexGrow={1}
    overflow={'hidden auto'}
    flexWrap={'nowrap'}
    gap={0}
  >
    {children}
  </DSL_FlexColumn>
);
