import React, {createContext, ReactNode, useEffect, useMemo} from 'react';
import {useMutation} from '@apollo/client';
import {DocumentNode} from 'graphql';
import {useRequiredContext} from 'components/DesignSystem/utils/useRequiredContext';

export interface DataLoaderContext<DataType = Record<string, unknown>> {
  data: DataType | undefined | null;
  isLoading: boolean;
  isError: boolean;
}

const DataLoaderContext = createContext<DataLoaderContext>({
  data: undefined,
  isError: false,
  isLoading: false,
});

interface DataLoaderProviderProps {
  document: DocumentNode;
  query: Object;
  children?: ReactNode;
}

export function DataLoaderProvider<DataType extends Record<string, unknown>>({
  document,
  query,
  children,
}: DataLoaderProviderProps) {
  const [mutation, {data: mutationData, loading, error}] =
    useMutation(document);

  const data = mutationData
    ? (mutationData[Object.keys(mutationData)[0]] as DataType)
    : undefined;

  useEffect(() => {
    mutation({variables: query});
  }, [mutation, query]);

  const value = useMemo(() => {
    return {
      isError: !!error,
      isLoading: loading,
      data,
    };
  }, [data, error, loading]);

  return (
    <DataLoaderContext.Provider value={value}>
      {children}
    </DataLoaderContext.Provider>
  );
}

export function useDataLoaderContext<
  DataType extends Record<string, unknown>,
>() {
  return useRequiredContext(
    DataLoaderContext,
    DataLoaderProvider.displayName,
  ) as DataLoaderContext<DataType>;
}

DataLoaderProvider.displayName = 'DataLoaderProvider';
