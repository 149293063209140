import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/accessProducts/:productId/accessProductDocument/:id',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "ManageAccessProductDocument" */ 'containers-access/AccessProductDocument/AccessProductDocumentDetail/ManageAccessProductDocument'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Vehicle network',
  menuIcon: LocalShippingOutlinedIcon,
  authorisation: {
    apiKey: 'AccessProductDocumentWithRelations.create',
    policies: [
      {
        role: 'operationsSpatialRead',
      },
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Reference vehicles',
      to: '../../..',
    },
    {
      content: 'Vehicle network',
      to: '../..',
      state: {'Access Product Tabs': 'Vehicle networks'},
    },
  ],
};
