import {AccreditationAuditorPublic} from 'typeDefs/types';

import {
  DSL_EntityCardProps,
  DSL_EntityContactDetailsProps,
} from 'components/DesignSystem/Library';

import {useAuditorEntityLoaderContext} from '../AuditorEntityLoaderProvider';

export function useAuditorEntityCard(): DSL_EntityCardProps &
  DSL_EntityContactDetailsProps {
  const {entity: auditor, isLoading} = useAuditorEntityLoaderContext();

  return {
    isLoading,
    title: auditor?.name ?? '',
    phone: auditor?.phone ?? undefined,
    email: auditor?.email ?? undefined,
    imgUrl: undefined, //todo review when implementing auditor onboarding NHVRREP-32899
    location: mapLocation(auditor),
    subTitle: mapSubTitle(auditor),
  };
}

function mapSubTitle(
  auditor: AccreditationAuditorPublic | null | undefined,
): string {
  if (auditor?.massScope && auditor?.fatigueScope) {
    return 'Auditor (Mass, Maintenance and Fatigue)';
  } else if (auditor?.massScope && !auditor?.fatigueScope) {
    return 'Auditor (Mass and Maintenance)';
  } else if (!auditor?.massScope && auditor?.fatigueScope) {
    return 'Auditor (Maintenance and Fatigue)';
  } else {
    return 'Auditor';
  }
}

function mapLocation(
  auditor: AccreditationAuditorPublic | null | undefined,
): string | undefined {
  if (!auditor) {
    return;
  }

  const {addressLocality, addressPostcode, addressState} = auditor;

  const location: string[] = [];

  [addressLocality, addressState, addressPostcode].forEach(segment => {
    if (segment) {
      location.push(segment);
    }
  });

  return location.join(', ');
}
