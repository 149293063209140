import {FetchResult} from '@apollo/client';

export default function createSyntheticFetchResult<T>(
  result?: T,
  error?: string,
): FetchResult {
  return {
    data: {
      dataType: result,
    },
    extensions: {
      graphQLErrors: [
        {
          extensions: {
            responseBody: {
              error: {
                message: error,
              },
            },
          },
        },
      ],
    },
  };
}
