import {useCallback, useRef, useState} from 'react';

import {equals} from 'ramda';
import {useBoolean} from 'usehooks-ts';

import {useSizeObserver} from '../../hooks/useSizeObserver';

export function usePreviewTypography() {
  const previewRef = useRef<HTMLDivElement>(null);
  const overflowRef = useRef<HTMLDivElement>(null);

  const {value: isPreview, toggle: toggleIsPreview} = useBoolean(true);
  const [state, setState] = useState({
    previewHeight: 0,
    overflowHeight: 0,
    hasOverflowContents: false,
  });

  useSizeObserver({
    ref: overflowRef,
    onResize: useCallback(
      () =>
        setState(prevState => {
          const previewHeight = previewRef.current?.scrollHeight ?? 0;
          const overflowHeight = overflowRef.current?.scrollHeight ?? 0;
          const newState = {
            overflowHeight,
            previewHeight,
            hasOverflowContents: overflowHeight > previewHeight,
          };
          return equals(prevState, newState) ? prevState : newState;
        }),
      [],
    ),
  });

  return {
    ...state,
    previewRef,
    overflowRef,
    isPreview,
    toggleIsPreview,
  };
}
