import {
  jsonPointerResolver,
  JSONSchemaRecord,
} from '@regulatory-platform/common-utils';
import * as R from 'ramda';
import {FormStore} from 'utils/stores/types';

export default function isChanged(store?: FormStore | null): boolean {
  if (R.isNil(store) || R.isNil(store?.schema)) {
    return false;
  }
  const schema = store?.schema;
  return (
    jsonPointerResolver('#/x-valid/changed')(schema as JSONSchemaRecord) ===
      true ||
    jsonPointerResolver('#/x-valid/changedChildren')(
      schema as JSONSchemaRecord,
    ) === true
  );
}
