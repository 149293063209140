import React from 'react';

import {
  GridItem,
  UseGridItemStyles,
  SelectInput,
  SelectInputProps,
} from 'components/DesignSystem/Library';

import {DSP_FieldProps} from '../types';

import {useMultiSelectField} from './useMultiSelectField';

export interface DSP_MultiSelectFieldProps
  extends DSP_FieldProps,
    UseGridItemStyles,
    Omit<SelectInputProps, 'value' | 'multiple'> {
  recordsLoaderRef?: string;
}

export const DSP_MultiSelectField = ({
  span,
  ...props
}: DSP_MultiSelectFieldProps) => {
  const fieldProps = useMultiSelectField(props);
  return (
    <GridItem span={span}>
      <SelectInput multiple {...fieldProps} />
    </GridItem>
  );
};

export {
  //
  /** @deprecated use DSP_MultiSelectFieldProps instead*/
  DSP_MultiSelectFieldProps as MultiSelectFieldProps, //
  /** @deprecated use DSP_MultiSelectField instead*/
  DSP_MultiSelectField as MultiSelectField,
};
