import React from 'react';

import {
  DSL_Body1,
  DSL_Body2,
  DSL_Flex,
  DSL_FlexColumn,
  DSL_FlexRow,
  DSL_GlobalErrorAlert,
  DSL_Subtitle1,
} from 'components/DesignSystem/Library';

export interface ConflictProps {
  label: string;
  subLabel?: string;
  actions: Array<{
    label: string;
    onClick: () => void;
  }>;
}

export const Conflict = ({label, subLabel, actions}: ConflictProps) => {
  return (
    <DSL_GlobalErrorAlert noMargin>
      <DSL_FlexColumn width={'100%'}>
        <DSL_Body1>{label}</DSL_Body1>

        <DSL_Body2>{subLabel}</DSL_Body2>

        <DSL_FlexRow
          flexWrap={'nowrap'}
          justifyContent={'space-between'}
          width={'100%'}
        >
          {actions.map((action, index) => (
            <DSL_Flex
              key={index}
              internal_sx={{
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            >
              <DSL_Subtitle1
                color={'primary.main'}
                bold
                key={index}
                onClick={action.onClick}
              >
                {action.label}
              </DSL_Subtitle1>
            </DSL_Flex>
          ))}
        </DSL_FlexRow>
      </DSL_FlexColumn>
    </DSL_GlobalErrorAlert>
  );
};
