import {ReactNode} from 'react';

import {JSONSchemaRecord, ValueType} from '@regulatory-platform/common-utils';
import * as R from 'ramda';
import {getPureLabel} from 'utils/schema/getLabel';

import {DSL_BaseSummaryItemProps} from 'components/DesignSystem/Library';

import {useFieldSchema, useFieldValue} from '../../FormProvider';

import {
  arrayRenderer,
  booleanRenderer,
  numberRenderer,
  stringRenderer,
  dateTimeRenderer,
  objectWithNameRenderer,
  htmlRenderer,
  dateShortRenderer,
  dateLongRenderer,
  statusRenderer,
  addressRenderer,
} from './valueRenderers';

export type DSP_ValueRendererFunction = (
  value: ValueType,
  schema: JSONSchemaRecord,
) => {
  value?: ReactNode;
  suffix?: ReactNode;
};

const rendererTypeValueMap = {
  string: stringRenderer,
  html: htmlRenderer,
  number: numberRenderer,
  boolean: booleanRenderer,
  array: arrayRenderer,
  object: objectWithNameRenderer,
  ['date-time']: dateTimeRenderer,
  ['date-short']: dateShortRenderer,
  ['date-long']: dateLongRenderer,
  status: statusRenderer,
  address: addressRenderer,
} as const;

type RendererType = keyof typeof rendererTypeValueMap;

type DSP_SchemaTypeValueRenderer = keyof typeof rendererTypeValueMap;

export interface DSP_UseSummaryItemRendererArgs
  extends Omit<DSL_BaseSummaryItemProps, 'children'> {
  fieldRef: string;
  fieldRelationRef?: string;
  type?: RendererType;
  valueRenderer?: DSP_SchemaTypeValueRenderer | DSP_ValueRendererFunction;
}

/**
 * Things we need to support:
 *
 * Plain text
 * Multiline text
 * Selects
 * MultiSelects
 * Dates, DateTimes
 * Groups - Contact Details, Address
 * Tables
 * Custom Renderers
 *
 * Things we want to achieve:
 *
 * Enable the rendering of groups, accordions,
 * Decouple the renderer from the field name
 * Do we still want config driven or do we go with composition based summaries?
 *
 * ```tsx
 * const summary = [{
 *   sectionTitle: "Application information",
 *   fields: [
 *   '#/foo',
 *   {
 *     fieldRef: '#/foo',
 *     renderer: 'text'
 *   }, {
 *     fieldRef: '#/foo',
 *     renderer: MyComponent
 *   }]
 * }]
 *
 * return <SummaryRenderer summary={summary} />
 * ```
 *
 * Compared to:
 *
 * ```jsx
 *   <DSL_SummaryContainer>
 *     <DSL_SummaryFieldset>
 *       <DSL_SummaryFieldsetHeader>
 *         Application information
 *       </DSL_SummaryFieldsetHeader>
 *       <DSL_SummaryItemRenderer fieldRef="#/foo" />
 *       <DSL_SummaryItemRenderer fieldRef="#/foo" renderer={'text'} />
 *       <DSL_SummaryItemRenderer fieldRef="#/foo" renderer={MyComponent} />
 *       <MyCustomComponentThatDoesFancyThings fieldRef="#/foo" />
 *     </DSL_SummaryFieldset>
 *   <DSL_SummaryContainer>
 * ```
 *
 * @param fieldRef
 */
interface DSP_UseSummaryItemRenderer
  extends Omit<DSL_BaseSummaryItemProps, 'children'> {
  value?: ReactNode;
  renderedValue: ReactNode;
}

export const useSummaryItemRenderer = ({
  fieldRef,
  valueRenderer,
  fieldRelationRef,
  type: typeOverride,
  label: labelOverride,
  ...props
}: DSP_UseSummaryItemRendererArgs): DSP_UseSummaryItemRenderer => {
  // for fields with relations, we will typically get the schema from the id field,
  // e.g. providerAccountId but we'll fetch the value from the relation ref e.g. providerAccount
  const fieldValueRef = fieldRelationRef || fieldRef.replace(/Id$/, '');
  const fieldSchema = useFieldSchema({fieldRef});
  const fieldValueSchema = useFieldSchema({fieldRef: fieldValueRef});
  const value = useFieldValue(fieldValueRef);
  const label = labelOverride ?? getPureLabel(fieldSchema);

  let valueRendererFn;
  if (R.is(Function, valueRenderer)) {
    valueRendererFn = valueRenderer;
  } else {
    const type: RendererType =
      typeOverride ??
      valueRenderer ??
      (fieldValueSchema.format as RendererType) ??
      (fieldValueSchema.type as RendererType) ??
      'string';
    valueRendererFn =
      rendererTypeValueMap[type] || rendererTypeValueMap['string'];
  }

  const {value: renderedValue, suffix} =
    valueRendererFn?.(value, fieldValueSchema) || {};

  return {
    value,
    renderedValue,
    suffix,
    label,
    ...props,
  };
};
