import React from 'react';

import {DSL_MoreHorizIcon} from '../../../Icons';
import {DSL_BaseIconButton, DSL_BaseIconButtonProps} from '../BaseIconButton';

export type DSL_MoreHorizIconButtonProps = Omit<
  DSL_BaseIconButtonProps,
  'color' | 'children'
>;

export const DSL_MoreHorizIconButton = (
  props: DSL_MoreHorizIconButtonProps,
): JSX.Element => {
  return (
    <DSL_BaseIconButton aria-label="more" {...props}>
      <DSL_MoreHorizIcon />
    </DSL_BaseIconButton>
  );
};

DSL_MoreHorizIconButton.displayName = 'DSL_MoreHorizIconButton';

export {
  //
  /** @deprecated use DSL_MoreHorizIconButtonProps instead*/
  DSL_MoreHorizIconButtonProps as MoreHorizIconButtonProps, //
  /** @deprecated use DSL_MoreHorizIconButton instead*/
  DSL_MoreHorizIconButton as MoreHorizIconButton,
};
