import Layers from '@mui/icons-material/Layers';
import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/vcbRefVehicles',
  component: lazy(
    () => import(/* webpackChunkName: "AratRefVehicles" */ './AratRefVehicles'),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'VCB reference vehicles',
  menuDescription: 'Reference vehicles for the VCB',
  menuIcon: Layers,
  parent: true,
  authorisation: {
    apiKey: 'AratRefVehicleWithRelations.findById',
    policies: [
      {
        role: 'operationsSpatialSupervisor',
      },
      {
        role: 'partnerSpatialSupervisor',
      },
    ] as AuthorisationPolicy[],
  },
  sections: [],
};
