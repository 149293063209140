import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';
import {lazy} from 'react';

export default {
  path: '/featureTokens/:id',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "FeatureTokenDetails" */ './FeatureTokenDetails'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Feature token',
  menuIcon: LocalShippingOutlinedIcon,
  authorisation: {
    apiKey: 'FeatureTokenWithRelations.findById',
    policies: [{role: 'operationsAccountAdmin'}] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Feature Tokens',
      to: '..',
    },
  ],
};
