import {lazy} from 'react';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/afmApplications/:id/operationManual',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "AfmApplicationOperationManualGateway" */ './AfmApplicationOperationManualGateway'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'AFM Application Operation Manual',
  menuIcon: LocalShippingOutlinedIcon,
  authorisation: {
    apiKey: 'AfmApplicationWithRelations.findById',
    policies: [
      {role: 'operationsAccreditationRead'},
      {role: 'customerAccreditationRead'},
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'AFM Applications',
      to: '../..',
    },
  ],
};
