import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/preapprovals/:accessProductDocumentId/accessProductApplication/:accessProductApplicationId',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "AccessProductApplicationDetails" */ 'containers-access/AccessProductApplication/AccessProductApplicationDetails/AccessProductApplicationDetails'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Manage network consultation case',
  menuIcon: LocalShippingOutlinedIcon,
  authorisation: {
    apiKey: 'AccessProductApplicationWithRelations.create',
    policies: [
      {
        role: 'operationsSpatialRead',
      },
      {role: 'partnerSpatialRead'},
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Vehicle networks',
      to: '..',
    },
  ],
};
