import React from 'react';

import {
  DSL_BaseAutocompleteInput,
  DSL_BaseAutocompleteInputProps,
} from '../BaseAutocompleteInput';

export type DSL_MultiSelectAutocompleteInputProps<
  T,
  FreeSolo extends boolean | undefined = undefined,
> = Omit<
  DSL_BaseAutocompleteInputProps<T, true, boolean, FreeSolo>,
  'multiple' | 'disableClearable'
>;
export const DSL_MultiSelectAutocompleteInput = <
  T,
  FreeSolo extends boolean | undefined = undefined,
>(
  props: DSL_MultiSelectAutocompleteInputProps<T, FreeSolo>,
) => {
  // UX tweak so that the clear button doesn't show if the input is empty
  const disableClearable = (props.value || []).length > 0;
  return (
    <DSL_BaseAutocompleteInput<T, true, boolean, FreeSolo>
      multiple
      disableClearable={disableClearable}
      {...props}
    />
  );
};

export {
  //
  /** @deprecated use DSL_MultiSelectAutocompleteInputProps instead*/
  DSL_MultiSelectAutocompleteInputProps as MultiSelectAutocompleteInputProps, //
  /** @deprecated use DSL_MultiSelectAutocompleteInput instead*/
  DSL_MultiSelectAutocompleteInput as MultiSelectAutocompleteInput,
};
