import React, {useMemo} from 'react';

import {Box} from '@mui/material';

import {DSL_Flex} from '../../../Layout/Flex';
import {DSL_BaseInput, DSL_BaseInputProps} from '../BaseInput';

export type DSL_MultilineTextInputProps = Omit<
  DSL_BaseInputProps,
  'multiline' | 'minRows' | 'maxRows' | 'type'
> & {
  maxLength?: number;
  hideCharCount?: boolean;
};

export const DSL_MultiLineTextInput = ({
  hideCharCount = false,
  helperText,
  maxLength,
  ...props
}: DSL_MultilineTextInputProps) => {
  const counterAwareHelperText = useMemo(() => {
    if (maxLength === undefined || hideCharCount) {
      return helperText;
    }

    const valueLength = (props.value as string)?.length ?? '0';
    const charCountText = `${valueLength}/${maxLength}`;

    return (
      <DSL_Flex component={'span'} gap={2}>
        <Box component={'span'} flex={1}>
          {helperText}
        </Box>
        <Box component={'span'}>{charCountText}</Box>
      </DSL_Flex>
    );
  }, [helperText, hideCharCount, maxLength, props.value]);

  return (
    <DSL_BaseInput
      multiline
      type={'text'}
      helperText={counterAwareHelperText}
      {...props}
    />
  );
};

DSL_MultiLineTextInput.displayName = 'DSL_MultiLineTextInput';

export {
  //
  /** @deprecated use DSL_MultilineTextInputProps instead*/
  DSL_MultilineTextInputProps as MultilineTextInputProps, //
  /** @deprecated use DSL_MultiLineTextInput instead*/
  DSL_MultiLineTextInput as MultiLineTextInput,
};
