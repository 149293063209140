import React from 'react';

import {Step as MuiStep, StepProps as MuiStepProps} from '@mui/material';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

export type DSL_StepProps = Omit<MuiStepProps, DSL_RestrictedVisualProps>;

export const DSL_Step = (props: DSL_StepProps) => {
  return <MuiStep {...props} />;
};

DSL_Step.displayName = 'DSL_Step';

export {
  //
  /** @deprecated use DSL_StepProps instead*/
  DSL_StepProps as StepProps, //
  /** @deprecated use DSL_Step instead*/
  DSL_Step as Step,
};
