import React from 'react';

import {
  DSP_EntityLoaderProvider,
  useEntityLoaderContext,
} from '../../EntityLoaderProvider';
import {DSP_EntityLoaderProviderProps} from '../../types';

import {GetCustomerAccountFindByIdDocument} from './api/GetCustomerAccountFindById.generated';
import {DSP_CustomerAccountEveryonePublic} from './types';

export const DSP_CustomerAccountEntityLoaderProvider = ({
  entityId: id,
  ...props
}: DSP_EntityLoaderProviderProps) => (
  <DSP_EntityLoaderProvider<DSP_CustomerAccountEveryonePublic>
    query={GetCustomerAccountFindByIdDocument}
    variables={{id}}
    {...props}
  />
);

export const useCustomerAccountEntityLoaderContext = () =>
  useEntityLoaderContext<DSP_CustomerAccountEveryonePublic>();

DSP_CustomerAccountEntityLoaderProvider.displayName =
  'DSP_CustomerAccountEntityLoaderProvider';
