import React from 'react';

import {DSL_IconWrapper} from '../IconWrapper';
import {DSL_IconProps} from '../types';

const DSL_Customer = (props: DSL_IconProps) => {
  return (
    <DSL_IconWrapper
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          fill="none"
          viewBox="0 0 24 24"
          {...props}
        >
          <path
            fill="#000"
            fillOpacity={0.54}
            d="M7.612 4v.941l1.247.83v-.524h5.611v5.62h1.247V4z"
          />
          <path
            fill="#000"
            fillOpacity={0.54}
            fillRule="evenodd"
            d="M16.245 11.482c.794 0 1.438.644 1.438 1.439h.922a.72.72 0 0 1 .65.411l.75 1.581h1.276a.72.72 0 0 1 .719.72v2.324a.72.72 0 0 1-.72.72h-1.474q.035.174.036.359a1.798 1.798 0 1 1-3.561-.36h-3.67q.037.175.037.36a1.799 1.799 0 1 1-3.562-.36H7.612v-3.453H2v-6.56l4.47-2.98L10.73 8.52v2.962zm-6.763 0V9.088L6.471 7.174l-3.224 2.05v4.752h1.87v-3.741h2.495v2.686c0-.795.644-1.439 1.438-1.439zm1.367 7.194a.36.36 0 1 0 0 .72.36.36 0 0 0 0-.72m4.676-1.439v-4.316h-6.68v4.316zm2.518 1.44a.36.36 0 1 0 0 .719.36.36 0 0 0 0-.72m2.878-1.44V15.8h-1.666L18.403 14h-1.439v3.237z"
            clipRule="evenodd"
          />
          <path
            fill="#000"
            fillOpacity={0.54}
            d="M11.976 8.988h1.247v1.247h-1.247zM11.976 6.494h1.247v1.247h-1.247zM9.943 6.494l.786.524v-.524z"
          />
        </svg>
      }
      {...props}
    />
  );
};

export default React.memo(DSL_Customer);
