import React, {ReactNode} from 'react';

import {Skeleton, SkeletonProps} from '@mui/material';

export interface DSL_SkeletonLoaderProps extends SkeletonProps {
  children: ReactNode;
  isLoading?: boolean;
}

export const DSL_SkeletonLoader = ({
  children,
  isLoading,
  ...props
}: DSL_SkeletonLoaderProps) => {
  return isLoading ? (
    <Skeleton variant={'rectangular'} {...props} />
  ) : (
    <>{children}</>
  );
};

DSL_SkeletonLoader.displayName = 'DSL_SkeletonLoader';

export {
  //
  /** @deprecated use DSL_SkeletonLoaderProps instead*/
  DSL_SkeletonLoaderProps as SkeletonLoaderProps, //
  /** @deprecated use DSL_SkeletonLoader instead*/
  DSL_SkeletonLoader as SkeletonLoader,
};
