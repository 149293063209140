import React from 'react';

import {DSL_BaseGrid, DSL_BaseGridProps} from './BaseGrid';

export type DSL_FormGridProps = Omit<DSL_BaseGridProps, 'spacingUnit'>;
export const DSL_FormGrid = (props: DSL_FormGridProps) => (
  <DSL_BaseGrid columns={1} {...props} />
);

export {
  //
  /** @deprecated use DSL_FormGridProps instead*/
  DSL_FormGridProps as FormGridProps, //
  /** @deprecated use DSL_FormGrid instead*/
  DSL_FormGrid as FormGrid,
};
