import {
  AuthorizationDecision,
  UserProfile,
  UserRoles,
  anyRolesVoter,
} from '@regulatory-platform/common-utils/dist';
import {i18n} from 'containers-content';
import {useNavigate} from 'react-router';

export const DSP_NHVRRecord = {
  accountName: 'National Heavy Vehicle Regulator',
  accountType: 'operations',
  accountId: 1,
  accountImageUrl: '',
  __typename: 'AccountUserMapping',
};
export const DSP_HVSPathwaysRecord = {
  accountName: 'HVS Pathways',
  accountType: 'operations',
  accountId: 10, //todo change to enum once id confirmed NHVRREP-33428
  accountImageUrl: '',
  __typename: 'AccountUserMapping',
};
// Check that we can rely on accountId: 1 before merging this
// Needs to be back to regulator account not back to NHVRRecord

export const useAccountHeader = (userProfile: UserProfile) => {
  const navigate = useNavigate();
  const canImpersonate =
    userProfile?.accountType === 'operations' ||
    userProfile?.impersonationAccountType === 'operations';

  const accountCount = userProfile?.accountCount ?? 0;
  const accountType = userProfile?.accountType ?? '';
  const roles = [(accountType + 'AccountAdmin') as UserRoles];
  const record = i18n.fromMapWithDefault({
    default: DSP_NHVRRecord,
    wa: DSP_HVSPathwaysRecord,
  });
  return {
    canImpersonate,
    hasOtherAccounts: accountCount > 1 || canImpersonate,
    isAdmin:
      !userProfile?.accountType ||
      anyRolesVoter(userProfile, roles) === AuthorizationDecision.DENY
        ? false
        : true,
    userProfile,
    navigateToRoute: (route: string, options: object = {}) => {
      navigate(route, options);
    },
    firstRecords:
      canImpersonate && userProfile?.accountType !== 'operations'
        ? [record]
        : [],
  };
};
