import React, {ReactNode} from 'react';

import {useFormContext} from '../FormProvider';
import {DSP_FieldProps} from '../types';
import {
  DSP_GenericFieldProps,
  getGenericFieldProps,
} from '../utils/getGenericFieldProps';

import {useFormFieldPropsSelector} from './useFormFieldPropsSelector';

export interface DSP_UseBooleanFieldProps
  extends DSP_FieldProps,
    Partial<Omit<DSP_GenericFieldProps, 'value' | 'error' | 'helperText'>> {}

export interface DSP_BooleanFieldProps
  extends Omit<DSP_GenericFieldProps, 'value' | 'helperText' | 'label'> {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: () => void;
  checked: boolean;
  label: ReactNode;
}

export default function useBooleanFieldProps({
  fieldRef,
  label,
  hideLabel,
  onBlurEvent,
  onChangeEvent,
  useLabelForValidationErrors,
  ...propsOverrides
}: DSP_UseBooleanFieldProps): DSP_BooleanFieldProps {
  const {service} = useFormContext();
  const send = service.send;

  return useFormFieldPropsSelector(fieldRef, service, state => {
    /**
     * Omit helperText props which are not relevant for boolean
     * fields
     */
    const {value, helperText, ...genericFieldProps} = getGenericFieldProps({
      fieldRef,
      state,
      includeOptionalSuffix: false,
      label,
      hideLabel,
      useLabelForValidationErrors,
    });

    return {
      ...genericFieldProps,
      ...propsOverrides,
      checked: value as boolean,
      onChange: (e: React.ChangeEvent<HTMLInputElement>): void => {
        onChangeEvent?.();
        send({type: 'CHANGEBLUR', fieldRef, value: e.currentTarget.checked});
      },
      onBlur: (): void => {
        onBlurEvent?.();
        send({type: 'BLUR', fieldRef});
      },
    };
  });
}
