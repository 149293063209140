import * as R from 'ramda';
import {useSelector} from '@xstate/react';
import {schemaJsonPointerResolver} from '@regulatory-platform/common-utils';
import {useFormContext} from 'components/DesignSystem/Portal';

export interface UseTextFieldArgs {
  fieldRef: string;
  multiline?: boolean;
}

export const useTextField = ({fieldRef, multiline}: UseTextFieldArgs) => {
  const {service} = useFormContext();

  return useSelector(
    service,
    state => {
      const {schema} = state.context;
      const fieldSchema = schemaJsonPointerResolver(fieldRef)(schema);

      const optional = fieldSchema['x-required'] !== true;

      const type =
        fieldSchema.type === 'number'
          ? 'number'
          : multiline
          ? 'multiline'
          : undefined;

      return {
        optional,
        type,
      };
    },
    R.equals,
  );
};
