import React, {ReactNode} from 'react';

import {TabStep} from 'components/navigation/types';

import {
  DSL_OutlinedCard,
  DSL_CardContent,
  DSL_StepperProps,
  DSL_FlexColumn,
  DSL_Flex,
} from 'components/DesignSystem/Library';

import {
  DSP_CloseButtonStack,
  DSP_FormPageContainerLayout,
  DSP_FormPageContainerLayoutProps,
} from '../../FormPageContainer';
import {DSP_FormContainerMode} from '../../FormPageContainer/FormPageContainer.View';
import {DSP_FormSaveDialog} from '../../FormSaveDialog';
import {DSP_ActiveWizardStep} from '../ActiveWizardStep';
import {DSP_WizardFooter} from '../WizardFooter';
import {DSP_WizardStepper} from '../WizardStepper';

export interface DSP_WizardLayoutViewProps
  extends DSP_FormPageContainerLayoutProps {
  submitConfirmMessage?: string;
  tabSteps: TabStep[];
  recordId?: string;
  dialogOpen: boolean;
  openDialog: () => void;
  closeDialog: () => void;
  handleNext: () => void;
  handleBack: () => void;
  onStepChange: (label: string) => void;
  activeStep: number;
  orientation: DSL_StepperProps['orientation'];
  isCancelButtonVisible?: boolean;
  isCreate?: boolean;
}
export const DSP_WizardLayoutView = ({
  recordId,
  dialogOpen,
  openDialog,
  closeDialog,
  submitConfirmMessage,
  handleNext,
  handleBack,
  activeStep,
  onStepChange,
  tabSteps,
  orientation,
  isCancelButtonVisible,
  isCreate,
  mode: overrideMode,
  ...formPageContainerProps
}: DSP_WizardLayoutViewProps) => {
  return (
    <DSP_FormPageContainerLayout
      key={recordId}
      mode={overrideMode}
      renderButtonStack={(mode: DSP_FormContainerMode): ReactNode => {
        if (orientation === 'vertical') return null;
        if (mode !== 'readonly') {
          return (
            <DSP_WizardFooter
              isCreate={isCreate}
              openDialog={openDialog}
              onNextClick={handleNext}
              onPreviousClick={handleBack}
              isPreviousButtonDisabled={activeStep === 0}
              isPrintButtonVisible={tabSteps[activeStep]?.showPrint}
              isSubmitButtonVisible={tabSteps.length - 1 === activeStep}
              isCancelButtonVisible={isCancelButtonVisible}
            />
          );
        }
        return <DSP_CloseButtonStack />;
      }}
      {...formPageContainerProps}
    >
      <DSL_Flex
        flexDirection={orientation === 'vertical' ? 'row' : 'column'}
        flexWrap={'nowrap'}
      >
        <DSP_WizardStepper
          activeStep={activeStep}
          tabSteps={tabSteps}
          onStepChange={onStepChange}
          orientation={orientation}
        />
        <DSL_FlexColumn gap={3} flexGrow={1}>
          <DSL_OutlinedCard data-name={'wizard-layout-outlined-card'}>
            <DSL_CardContent
              data-name={'wizard-layout-card-content'}
              spacing={'NONE'}
              padding={'MD'}
            >
              <DSP_ActiveWizardStep
                tabSteps={tabSteps}
                activeStep={activeStep}
                onStepChange={onStepChange}
              />
            </DSL_CardContent>
          </DSL_OutlinedCard>
          <DSP_FormSaveDialog
            open={dialogOpen}
            onClose={closeDialog}
            isCreate={isCreate}
          />
          {orientation === 'vertical' &&
            (formPageContainerProps.readOnlyComponent ? (
              <DSP_CloseButtonStack />
            ) : (
              <DSP_WizardFooter
                isCreate={isCreate}
                openDialog={openDialog}
                onNextClick={handleNext}
                onPreviousClick={handleBack}
                isPreviousButtonDisabled={activeStep === 0}
                isPrintButtonVisible={tabSteps[activeStep]?.showPrint}
                isSubmitButtonVisible={tabSteps.length - 1 === activeStep}
                isCancelButtonVisible={isCancelButtonVisible}
              />
            ))}
        </DSL_FlexColumn>
      </DSL_Flex>
    </DSP_FormPageContainerLayout>
  );
};

DSP_WizardLayoutView.displayName = 'DSP_WizardLayoutView';

export {
  //
  /** @deprecated use DSP_WizardLayoutViewProps instead*/
  DSP_WizardLayoutViewProps as WizardLayoutViewProps, //
  /** @deprecated use DSP_WizardLayoutView instead*/
  DSP_WizardLayoutView as WizardLayoutView,
};
