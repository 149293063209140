import React from 'react';

import {
  InputLabel as MuiInputLabel,
  InputLabelProps as MuiInputLabelProps,
} from '@mui/material';

import {DSL_RestrictedVisualProps} from '../../../types';

export type DSL_InputLabelProps = Omit<
  MuiInputLabelProps,
  DSL_RestrictedVisualProps
>;

export const DSL_InputLabel = (props: DSL_InputLabelProps) => {
  return <MuiInputLabel {...props} />;
};

DSL_InputLabel.displayName = 'DSL_InputLabel';

export {
  //
  /** @deprecated use DSL_InputLabelProps instead*/
  DSL_InputLabelProps as InputLabelProps, //
  /** @deprecated use DSL_InputLabel instead*/
  DSL_InputLabel as InputLabel,
};
