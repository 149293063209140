import {schemaJsonPointerResolver} from '@regulatory-platform/common-utils/dist';
import {useSelector} from '@xstate/react';
import {useFormContext} from 'components/DesignSystem/Portal/Forms/FormProvider';
import * as R from 'ramda';
import {useNavigate} from 'react-router-dom';
import getStateMetaHead from 'utils/machines/utils/getStateMetaHead';
import isChanged from 'utils/stores/isChanged';

import {DSP_SaveOrCancelButtonStackViewProps} from './SaveOrCancelButtonStack.View';

export const useSaveOrCancelButtonStack = (
  openDialog: () => void,
): DSP_SaveOrCancelButtonStackViewProps => {
  const {service} = useFormContext();
  const navigate = useNavigate();

  return useSelector(
    service,
    state => {
      const metaHead = getStateMetaHead(state);
      const navigateTo = metaHead.navigateTo;
      const navigateState = metaHead.navigateState;

      const _hasArchiveReasonField = !!schemaJsonPointerResolver(
        '#/reasonForArchive',
      )(state.context.schema);
      const _isSavingToArchive =
        state.matches('userInput.archived') || state.matches('archiving');

      const _showArchiveDialog = _isSavingToArchive && _hasArchiveReasonField;

      return {
        disabled: !isChanged(state?.context),
        onSaveClick: event => {
          event.preventDefault();
          if (_showArchiveDialog) {
            openDialog();
            return;
          }
          service?.send('SAVE');
        },
        onCancelClick: () => {
          service?.send('CANCEL');
          if (!R.isNil(navigateTo) && navigateTo !== '') {
            navigate(
              navigateTo,
              navigateState ? {state: navigateState} : undefined,
            );
          }
        },
      };
    },
    (prevFieldProps, nextFieldProps) => {
      const ignoreProps = R.omit(['onSaveClick', 'onCancelClick']);
      return R.equals(ignoreProps(prevFieldProps), ignoreProps(nextFieldProps));
    },
  );
};
