import React from 'react';

import {DSP_CustomerAccountEntityAvatarView} from './CustomerAccountEntityAvatar.View';
import {useCustomerAccountEntityAvatar} from './useCustomerAccountEntityAvatar';

export const DSP_CustomerAccountEntityAvatar = () => {
  const viewProps = useCustomerAccountEntityAvatar();

  return <DSP_CustomerAccountEntityAvatarView {...viewProps} />;
};

DSP_CustomerAccountEntityAvatar.displayName = 'DSP_CustomerAccountEntityAvatar';

export {
  //
  /** @deprecated use DSP_CustomerAccountEntityAvatar instead*/
  DSP_CustomerAccountEntityAvatar as CustomerAccountEntityAvatar,
};
