import React from 'react';

import {DSL_PaddingProps} from '../../../types';
import {DSL_Box, DSL_BoxProps} from '../../Box';

import {
  DSL_UserScrollableLayoutBodyProps,
  useScrollableLayoutBody,
} from './useScrollableLayoutBody';

export interface DSL_ScrollableLayoutBodyProps
  extends Partial<DSL_UserScrollableLayoutBodyProps>,
    Omit<DSL_BoxProps, DSL_PaddingProps | 'height' | 'ref' | 'internal_sx'> {
  neverShowBorder?: boolean;
}

export const DSL_ScrollableLayoutBody = ({
  padding = 'SM',
  alwaysShowBorder,
  neverShowBorder,
  children,
  onScrollableLayoutChange,
  overflowY = 'auto',
  ...props
}: DSL_ScrollableLayoutBodyProps) => {
  const {borderStyle, paddingStyle, refContentDiv} = useScrollableLayoutBody({
    padding,
    alwaysShowBorder,
    onScrollableLayoutChange,
  });

  return (
    <DSL_Box
      ref={refContentDiv}
      height={'100%'}
      overflowY={overflowY}
      internal_sx={{
        ...(!neverShowBorder && borderStyle),
        ...paddingStyle,
      }}
      {...props}
    >
      {children}
    </DSL_Box>
  );
};

DSL_ScrollableLayoutBody.displayName = 'DSL_ScrollableLayoutBody';
