import React, {ReactNode} from 'react';

import {LinkProps} from '@mui/material';
import {DSL_RouterLink} from 'components/DesignSystem/Library/Links/RouterLink';
import {DSL_Body1} from 'components/DesignSystem/Library/Typography/Body1';

import {DSL_FlexRow} from '../../FlexRow';

// @TODO: If we have a need to use these components without react router we may need to make
// the link component configurable. either via a prop or some kind of context based configuration.

export interface DSL_BreadcrumbProps extends LinkProps {
  icon?: ReactNode;
  label?: string;
  active?: boolean;
}

export const DSL_Breadcrumb = ({
  active,
  icon,
  href,
  label,
  ...linkProps
}: DSL_BreadcrumbProps) => {
  return !active ? (
    <DSL_RouterLink
      {...linkProps}
      color={active ? 'text.primary' : 'action.active'}
      underline={'hover'}
      href={href}
      variant={'body1'}
      data-id={'inactive-breadcrumb'}
    >
      <DSL_FlexRow gap={1} alignItems={'center'}>
        {icon}
        <DSL_Body1>{label}</DSL_Body1>
      </DSL_FlexRow>
    </DSL_RouterLink>
  ) : (
    <DSL_Body1 data-id={'active-breadcrumb'} color={'text.primary'}>
      {label}
    </DSL_Body1>
  );
};

DSL_Breadcrumb.displayName = 'DSL_Breadcrumb';

export {
  //
  /** @deprecated use DSL_BreadcrumbProps instead*/
  DSL_BreadcrumbProps as BreadcrumbProps, //
  /** @deprecated use DSL_Breadcrumb instead*/
  DSL_Breadcrumb as Breadcrumb,
};
