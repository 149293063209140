import React, {
  CSSProperties,
  MouseEventHandler,
  RefObject,
  SyntheticEvent,
  useCallback,
} from 'react';

import {
  ClickAwayListener,
  ClickAwayListenerProps,
  useTheme,
} from '@mui/material';
import {
  Popper as MuiPopper,
  PopperProps as MuiPopperProps,
} from '@mui/material';

import {DSL_BackgroundColor, DSL_RestrictedStyleProps} from '../../types';
import {
  DSL_BreakpointValues,
  useCascadingBreakpointValue,
} from '../hooks/useCascadingBreakpointValue';
import {DSL_ScrollableLayout} from '../ScrollableLayout';

export interface DSL_PopperProps
  extends Omit<MuiPopperProps, DSL_RestrictedStyleProps> {
  closePopper: (event?: Event | SyntheticEvent) => void;
  bgcolor?: DSL_BackgroundColor;
  boxShadow?: number;
  disableClickAway?: boolean;
  height?: DSL_BreakpointValues<CSSProperties['height']>;
  maxHeight?: DSL_BreakpointValues<CSSProperties['maxHeight']>;
  width?: DSL_BreakpointValues<CSSProperties['width']>;
  maxWidth?: DSL_BreakpointValues<CSSProperties['maxWidth']>;
}

export interface DSL_OpenerProps<RefType = HTMLElement> {
  onClick: MouseEventHandler<HTMLElement>;
  ref: RefObject<RefType>;
}

export const DSL_Popper = ({
  bgcolor = 'background.paper',
  boxShadow = 6,
  maxWidth = {xs: '100%', sm: 'auto', md: 'auto', lg: 'auto', xl: 'auto'},
  width = {xs: '100%', sm: 'auto', md: 'auto', lg: 'auto', xl: 'auto'},
  maxHeight = {
    xs: '80vh',
    sm: '90vh',
  },
  height,
  closePopper,
  children,
  disablePortal,
  disableClickAway,
  ...props
}: DSL_PopperProps) => {
  const {zIndex} = useTheme();
  const maxHeightForBreakpoint = useCascadingBreakpointValue(maxHeight);

  const onClickAway: ClickAwayListenerProps['onClickAway'] = useCallback(
    event => {
      const {target, type} = event;
      // Portaled child elements end up dropping a click event handler on the body
      // so here we have to specifically ignore those events to prevent select items from
      // causing our popper to close.
      // See this open bug in MUI https://github.com/mui/material-ui/issues/25578
      if (
        target instanceof Element &&
        target?.nodeName === 'BODY' &&
        type === 'click'
      ) {
        return;
      }
      closePopper(event);
    },
    [closePopper],
  );

  const Children = (
    <DSL_ScrollableLayout maxHeight={'inherit'} height={'inherit'}>
      {children}
    </DSL_ScrollableLayout>
  );

  return (
    <MuiPopper
      disablePortal={disablePortal}
      sx={{
        bgcolor,
        boxShadow,
        width,
        maxWidth,
        height,
        maxHeight: maxHeightForBreakpoint,
        overflowY: 'auto',
        zIndex: disablePortal ? zIndex.overlay : zIndex.tooltip,
      }}
      placement="bottom-end"
      {...props}
    >
      {disableClickAway ? (
        Children
      ) : (
        <ClickAwayListener onClickAway={onClickAway}>
          {Children}
        </ClickAwayListener>
      )}
    </MuiPopper>
  );
};

DSL_Popper.displayName = 'DSL_Popper';

export {
  /** @deprecated use DSL_PopperProps instead*/
  DSL_PopperProps as PopperProps, //
  /** @deprecated use DSL_OpenerProps instead*/
  DSL_OpenerProps as OpenerProps, //
  /** @deprecated use DSL_Popper instead*/
  DSL_Popper as Popper,
};
