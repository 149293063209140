import React from 'react';

import {DSL_BaseTypography, DSL_BaseTypographyProps} from '../BaseTypography';

export type DSL_Body2Props = Omit<DSL_BaseTypographyProps, 'variant'>;
export const DSL_Body2 = (props: DSL_Body2Props) => (
  <DSL_BaseTypography color={'text.primary'} {...props} variant={'body2'} />
);

export {
  //
  /** @deprecated use DSL_Body2Props instead*/
  DSL_Body2Props as Body2Props, //
  /** @deprecated use DSL_Body2 instead*/
  DSL_Body2 as Body2,
};
