import {i18n} from 'containers-content';

import {DSP_AccreditationActionNotificationItem} from '../types';

export interface DSP_UseBaseAccreditationActionNotificationCardProps {
  notification: DSP_AccreditationActionNotificationItem;
}

export function useBaseAccreditationActionNotificationCard({
  notification,
}: DSP_UseBaseAccreditationActionNotificationCardProps) {
  const {id, applicationType} = notification.data;

  return {
    actionDescription: 'Accreditation Application is overdue',
    isRead: notification.readByLoggedInUser,
    href: `/accreditation`,
    created: notification.created ?? undefined,
    name: applicationType ?? 'Accreditation',
    applicationId: `${i18n.fromMapWithDefault({
      default: 'NHVAS',
      wa: 'WAHVA',
    })}${id}`,
    status: 'Overdue',
  };
}
