import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';
export default {
  path: '/auditors/:id',
  component: lazy(
    () => import(/* webpackChunkName: "AuditorDetails" */ './AuditorDetails'),
  ),
  visibleInMenu: false,
  menuTitle: 'Auditor',
  hideInProduction: true,
  authorisation: {
    apiKey: 'AccreditationAuditorWithRelations.updateById',
    policies: [
      {role: 'operationsAccreditationRead'},
      {role: 'customerAccreditationRead'},
    ] as AuthorisationPolicy[],
  },
  sections: [],
};
