import * as Types from '../../../typeDefs/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserModelsMyAccountMappingsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserModelsMyAccountMappingsQuery = { __typename?: 'Query', userModelsMyAccountMappings?: Array<{ __typename?: 'AccountUserMapping', accountId?: number | null, accountName?: string | null, accountType?: string | null, accountImageUrl?: string | null } | null> | null };


export const UserModelsMyAccountMappingsDocument = gql`
    query userModelsMyAccountMappings {
  userModelsMyAccountMappings {
    accountId
    accountName
    accountType
    accountImageUrl
  }
}
    `;

/**
 * __useUserModelsMyAccountMappingsQuery__
 *
 * To run a query within a React component, call `useUserModelsMyAccountMappingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserModelsMyAccountMappingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserModelsMyAccountMappingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserModelsMyAccountMappingsQuery(baseOptions?: Apollo.QueryHookOptions<UserModelsMyAccountMappingsQuery, UserModelsMyAccountMappingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserModelsMyAccountMappingsQuery, UserModelsMyAccountMappingsQueryVariables>(UserModelsMyAccountMappingsDocument, options);
      }
export function useUserModelsMyAccountMappingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserModelsMyAccountMappingsQuery, UserModelsMyAccountMappingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserModelsMyAccountMappingsQuery, UserModelsMyAccountMappingsQueryVariables>(UserModelsMyAccountMappingsDocument, options);
        }
export function useUserModelsMyAccountMappingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserModelsMyAccountMappingsQuery, UserModelsMyAccountMappingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserModelsMyAccountMappingsQuery, UserModelsMyAccountMappingsQueryVariables>(UserModelsMyAccountMappingsDocument, options);
        }
export type UserModelsMyAccountMappingsQueryHookResult = ReturnType<typeof useUserModelsMyAccountMappingsQuery>;
export type UserModelsMyAccountMappingsLazyQueryHookResult = ReturnType<typeof useUserModelsMyAccountMappingsLazyQuery>;
export type UserModelsMyAccountMappingsSuspenseQueryHookResult = ReturnType<typeof useUserModelsMyAccountMappingsSuspenseQuery>;
export type UserModelsMyAccountMappingsQueryResult = Apollo.QueryResult<UserModelsMyAccountMappingsQuery, UserModelsMyAccountMappingsQueryVariables>;