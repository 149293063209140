/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  AuthorisationSchema,
  AuthorizationDecision,
  CommonInvocationContext,
  CommonValidatorResult,
  jsonPointerResolver,
  JSONSchemaRecord,
  ObjectType,
  UserProfile,
} from '@regulatory-platform/common-utils';
import {ApolloError} from '@apollo/client';
import {AppServicesContextProps} from 'app/services/AppServicesContext';
import * as R from 'ramda';
import getFetchDataResult from 'utils/apollo/getFetchDataResult';
import getFormStore from 'utils/stores/getFormStore';
import isAuthorised from 'utils/stores/isAuthorised';
import getSharedDataFromEvent from 'utils/stores/getSharedDataFromEvent';
import onAddSharedDataHandler from 'utils/stores/onAddSharedDataHandler';
import onBeforeValidate from 'utils/stores/onBeforeValidate';
import onAfterValidate from 'utils/stores/onAfterValidate';
import onBlurEventHandler from 'utils/stores/onBlurEventHandler';
import onChangeBlurEventHandler from 'utils/stores/onChangeBlurEventHandler';
import onChangeEventHandler from 'utils/stores/onChangeEventHandler';
import onInsertNewArrayItemIntoSchema from 'utils/stores/onInsertNewArrayItemIntoSchema';
import onInsertNewArrayItemsIntoSchema from 'utils/stores/onInsertNewArrayItemsIntoSchema';
import onRemoveArrayItemFromSchema from 'utils/stores/onRemoveArrayItemFromSchema';
import onRemoveAllArrayItemsFromSchema from 'utils/stores/onRemoveAllArrayItemsFromSchema';
import onUpdateSchemaEventHandler from 'utils/stores/onUpdateSchemaEventHandler';
import {FormEventObject, FormMachineContext} from 'utils/stores/types';
import {assign, MachineOptions, send} from 'xstate';

export type MachineActions<
  T extends FormMachineContext = FormMachineContext,
  E extends FormEventObject = FormEventObject,
> = NonNullable<MachineOptions<T, E>['actions']>;

export default function getActions<
  R extends ObjectType = ObjectType,
  Context extends FormMachineContext<R> = FormMachineContext<R>,
  EventObject extends FormEventObject = FormEventObject,
>(
  baseSchema: JSONSchemaRecord,
  validator: (
    context: CommonInvocationContext,
    schema: JSONSchemaRecord,
    record: ObjectType,
  ) => CommonValidatorResult,
  actionVoter: (
    context: CommonInvocationContext,
    schema: AuthorisationSchema,
  ) => (action: string) => AuthorizationDecision,
  appServices: AppServicesContextProps,
  _messages = {} as {
    onSaveError?: string;
    onSubmitError?: string;
    onSaveSuccess?: string;
    onSubmitSuccess?: string;
  },
): MachineActions<Context, EventObject> {
  const messages = R.mergeRight(
    {
      onSaveError: 'Form has errors and could not be saved.',
      onSubmitError: 'Form could not be submitted.',
      onSaveSuccess: 'Form successfully updated.',
      onSubmitSuccess: 'Form successfully submitted.',
    },
    _messages,
  );
  return {
    loadRecord: assign((context, event) => {
      return R.mergeRight(
        context,
        getFormStore<R>(
          baseSchema,
          validator,
          getFetchDataResult(event.data),
          context.shared,
          context.authorisations?.userProfile,
        ),
      ) as Context;
    }),
    updateRecord: assign((context, event) => {
      return R.mergeRight(
        context,
        getFormStore(
          baseSchema,
          validator,
          getFetchDataResult(event.data),
          context.shared,
          context.authorisations?.userProfile,
        ),
      ) as Context;
    }),
    changeRecord: assign((context, event) => {
      return R.mergeRight(
        context,
        getFormStore(
          baseSchema,
          validator,
          event.record,
          context.shared,
          context.authorisations?.userProfile,
        ),
      ) as Context;
    }),
    updateAuthorisations: assign({
      authorisations: (context, event) => {
        let userProfile = context.authorisations?.userProfile;
        if (event.type === 'AUTH_UPDATE') {
          userProfile = event.record as unknown as UserProfile;
        }
        if (R.isNil(userProfile)) {
          return undefined;
        }
        const updateMethodName = R.defaultTo(
          'updateById',
          context.props.updateMethodName,
        );
        return {
          userProfile,
          create: isAuthorised(actionVoter, 'create', context),
          findById: isAuthorised(actionVoter, 'findById', context),
          updateById: isAuthorised(actionVoter, updateMethodName, context),
          submit: isAuthorised(
            actionVoter,
            updateMethodName,
            context,
            R.mergeRight(context.record, {status: 'Submitted'}),
          ),
        } as Context['authorisations'];
      },
    }),
    incrementUpdateCounter: assign({
      props: context => {
        const updateCounter = R.defaultTo(0, context.props.updateCounter);
        return R.assoc(
          'updateCounter',
          updateCounter + 1,
          context.props,
        ) as Context['props'];
      },
    }),
    setOverrideTouched: assign({
      props: context => {
        return R.assoc('overrideTouched', true, context.props);
      },
    }),
    setDialogOpen: assign({
      props: (context, event) => {
        context.props.dialogOpen = R.assoc(
          event.fieldRef,
          true,
          R.defaultTo({}, context.props.dialogOpen),
        );
        return context.props;
      },
    }),
    setDialogClose: assign({
      props: context => {
        context.props.dialogOpen = {};
        return context.props;
      },
    }),
    setSelectedRecordIndex: assign({
      props: (context, event) => {
        const {index, record} = event;
        context.props.selectedRecordIndex = index;
        context.props.selectedRecordSnapshot = R.clone(record);
        return context.props;
      },
    }),
    onChange: assign((context, event) => {
      return onChangeEventHandler(context, event, validator) as Context;
    }),
    onBlur: assign((context, event) => {
      return onBlurEventHandler(context, event, validator) as Context;
    }),
    onChangeBlur: assign((context, event) => {
      return onChangeBlurEventHandler(context, event, validator) as Context;
    }),
    onBeforeValidate: assign((context, event) => {
      return onBeforeValidate(context, event) as Context;
    }),
    onLocalValidatorExec: assign((context, event) => {
      return onAfterValidate(context, event) as Context;
    }),
    onAddNewArrayItem: assign((context, event) => {
      const {fieldRef, record} = event;
      return onInsertNewArrayItemIntoSchema(
        context,
        {
          type: '',
          fieldRef,
          record,
        },
        validator,
      ) as Context;
    }),
    onAddNewArrayItemWithIndex: assign((context, event) => {
      const {fieldRef, record, index} = event;
      return onInsertNewArrayItemIntoSchema(
        context,
        {
          type: '',
          fieldRef,
          index,
          record,
        },
        validator,
      ) as Context;
    }),
    onInsertNewArrayItems: assign((context, event) => {
      const {fieldRef, records} = event;
      return onInsertNewArrayItemsIntoSchema(
        context,
        {
          type: '',
          fieldRef,
          records,
        },
        validator,
      ) as Context;
    }),
    onRemoveArrayItem: assign((context, event) => {
      const {fieldRef, index} = event;
      if (R.isNil(index)) {
        return context;
      }
      const arrayItem = jsonPointerResolver(fieldRef)(
        context.record,
      ) as ObjectType[];
      if (!R.isNil(arrayItem) && arrayItem.length > index) {
        return onRemoveArrayItemFromSchema(
          context,
          {
            type: '',
            fieldRef,
            index,
          },
          validator,
        );
      }
      return context as Context;
    }),
    onRemoveAllArrayItems: assign((context, event) => {
      return onRemoveAllArrayItemsFromSchema(
        context,
        event,
        validator,
      ) as Context;
    }),
    setTabTouched: assign({
      props: (context, event) => {
        context.props.tabTouched = R.assoc(
          event.fieldRef,
          true,
          R.defaultTo({}, context.props.tabTouched),
        );
        return context.props;
      },
    }),
    setAttachmentUrl: assign({
      props: (context, event) => {
        const unixNow = new Date().getTime() / 1000;
        context.props.attachmentUrls = R.assoc(
          event.fieldRef,
          {
            url: event.value as string,
            expires: unixNow + 60 * 12, //12 minutes
          },
          R.defaultTo({}, context.props.attachmentUrls),
        );
        return context.props;
      },
    }),
    touchFields: assign((context, event) => {
      return onUpdateSchemaEventHandler(context, {
        type: '',
        fieldRef: '',
        fieldRefs: event.fieldRefs,
        propRef: 'x-valid/touched',
        value: true,
      });
    }),
    setSharedData: assign((context, event) => {
      return getSharedDataFromEvent(context, event) as Context;
    }),
    onLoadSharedData: assign((context, event) => {
      return onAddSharedDataHandler(context, event, validator) as Context;
    }),
    sendLoadEventToActor: send(
      (_context, event) => R.mergeRight(event, {type: 'LOAD'}),
      {to: (context, event) => context.actors[event.fieldRef]},
    ),
    sendReloadEventToActor: send(
      (_context, event) => R.mergeRight(event, {type: 'RELOAD'}),
      {to: (context, event) => context.actors[event.fieldRef]},
    ),
    setApiError: assign({
      props: (context, event) =>
        R.assoc('apiError', event.data as ApolloError, context.props),
    }),
    unsetApiError: assign({
      props: context => R.assoc('apiError', undefined, context.props),
    }),
    onLoadSuccess: () => {
      //
    },
    onSaveError: () => {
      appServices.snackbar.service.send('TRIGGER', {
        record: {
          variant: 'error',
          message: messages.onSaveError,
        },
      });
    },
    onSaveSuccess: () => {
      appServices.snackbar.service.send('TRIGGER', {
        record: {
          variant: 'success',
          message: messages.onSaveSuccess,
        },
      });
    },
    onSubmitError: () => {
      appServices.snackbar.service.send('TRIGGER', {
        record: {
          variant: 'error',
          message: messages.onSubmitError,
        },
      });
    },
    onSubmitSuccess: () => {
      appServices.snackbar.service.send('TRIGGER', {
        record: {
          variant: 'success',
          message: messages.onSubmitSuccess,
        },
      });
    },
  } as MachineActions<Context, EventObject>;
}
