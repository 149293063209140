import React from 'react';

import {MenuItem} from '@mui/material';

import {DSL_Option} from '../../../types';
import {optionToLabel} from '../../utils/optionToLabel';
import {optionToValue} from '../../utils/optionToValue';
import {
  DSL_BaseSelectInput,
  DSL_BaseSelectInputProps,
} from '../BaseSelectInput';

export type DSL_SelectInputProps<T = string> = DSL_BaseSelectInputProps<T> & {
  options?: readonly DSL_Option[];
};

export const DSL_SelectInput = function <T = string>({
  options,
  ...props
}: DSL_SelectInputProps<T>) {
  const DSL_Wrapper = props.native ? 'option' : MenuItem;

  return (
    <DSL_BaseSelectInput {...props}>
      {options?.map((option, index) => (
        <DSL_Wrapper
          key={index}
          value={optionToValue(option)}
          id={optionToValue(option) + ''}
        >
          {/* Currently mui selects don't work if you have an empty value but text for the label,
          so we need ot display the label as empty here*/}
          {optionToValue(option) === '' && props.native
            ? ''
            : optionToLabel(option)}
        </DSL_Wrapper>
      ))}
    </DSL_BaseSelectInput>
  );
};

DSL_SelectInput.displayName = 'DSL_SelectInput';

export {
  //
  /** @deprecated use DSL_SelectInputProps instead*/
  DSL_SelectInputProps as SelectInputProps, //
  /** @deprecated use DSL_SelectInput instead*/
  DSL_SelectInput as SelectInput,
};
