import {lazy} from 'react';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/afmApplications/create',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "AfmApplicationGateway" */ './AfmApplicationGateway'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Add application',
  menuIcon: LocalShippingOutlinedIcon,
  authorisation: {
    apiKey: 'AfmApplicationWithRelations.create',
    policies: [{role: 'customerAccreditationUser'}] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'AFM Applications',
      to: '..',
    },
  ],
};
