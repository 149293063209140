import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/roads/create',
  component: lazy(
    () =>
      import(/* webpackChunkName: "CreateRoadDetail" */ './CreateRoadDetail'),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Add road detail',
  menuIcon: LibraryAddCheckIcon,
  authorisation: {
    apiKey: 'RoadDetailWithRelations.create',
    policies: [
      {
        role: 'partnerSpatialUser',
      },
      {
        role: 'operationsSpatialUser',
      },
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Road details',
      to: '..',
    },
  ],
};
