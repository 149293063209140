import React from 'react';

import {DSL_IconWrapper} from '../IconWrapper';
import {DSL_IconProps} from '../types';

const DSL_ExternalLinkOutlined = (props: DSL_IconProps) => {
  return (
    <DSL_IconWrapper
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          fill="none"
          viewBox="0 0 24 24"
          {...props}
        >
          <path
            fill="#000"
            fillOpacity={0.54}
            d="M20.784 2.392a1 1 0 0 0-.089.008H16A.8.8 0 1 0 16 4h2.869l-8.235 8.234a.8.8 0 1 0 1.132 1.132L20 5.13V8a.8.8 0 1 0 1.6 0V3.302a.8.8 0 0 0-.816-.91M4.8 5.6c-.874 0-1.6.726-1.6 1.6v12c0 .875.726 1.6 1.6 1.6h12c.874 0 1.6-.726 1.6-1.6V9.138l-1.6 1.6V19.2h-12v-12h8.462l1.6-1.6H4.8"
          />
        </svg>
      }
      {...props}
    />
  );
};

export default React.memo(DSL_ExternalLinkOutlined);
