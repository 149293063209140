export const GovernmentLogosSizeMap = {
  xl: {
    width: 100,
    height: 66,
  },
  lg: {
    width: 100,
    height: 66,
  },
  md: {
    width: 100,
    height: 66,
  },
  sm: {
    width: 80,
    height: 45,
  },
  xs: {
    width: 50,
    height: 35,
  },
};
