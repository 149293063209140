import React from 'react';

import {DSL_AccessTimeIcon} from '../../Icons';
import {DSL_BaseIconInfo, DSL_BaseIconInfoProps} from '../BaseIconInfo';

export type DSL_AccessTimeIconInfoProps = Omit<DSL_BaseIconInfoProps, 'icon'>;

export const DSL_AccessTimeIconInfo = (props: DSL_AccessTimeIconInfoProps) => {
  return <DSL_BaseIconInfo icon={<DSL_AccessTimeIcon />} {...props} />;
};

DSL_AccessTimeIconInfo.displayName = 'DSL_AccessTimeIconInfo';
