import React from 'react';

import {DSL_RemoveRedEyeIcon} from '../../../Icons';
import {DSL_BaseIconButton, DSL_BaseIconButtonProps} from '../BaseIconButton';

export type DSL_RemoveRedEyeIconButtonProps = Omit<
  DSL_BaseIconButtonProps,
  'color' | 'children'
>;

export const DSL_RemoveRedEyeIconButton = ({
  size = 'medium',
  ...props
}: DSL_RemoveRedEyeIconButtonProps): JSX.Element => {
  return (
    <DSL_BaseIconButton aria-label="back" size={size} {...props}>
      <DSL_RemoveRedEyeIcon fontSize={size} />
    </DSL_BaseIconButton>
  );
};

DSL_RemoveRedEyeIconButton.displayName = 'DSL_RemoveRedEyeIconButton';

export {
  //
  /** @deprecated use DSL_RemoveRedEyeIconButtonProps instead*/
  DSL_RemoveRedEyeIconButtonProps as RemoveRedEyeIconButtonProps, //
  /** @deprecated use DSL_RemoveRedEyeIconButton instead*/
  DSL_RemoveRedEyeIconButton as RemoveRedEyeIconButton,
};
