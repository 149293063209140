import React, {ForwardedRef} from 'react';

import {
  Badge as MuiBadge,
  BadgeProps as MuiBadgeProps,
  badgeClasses as MuiBadgeClasses,
} from '@mui/material';
import {SystemCssProperties} from '@mui/system';

import {DSL_RestrictedVisualProps} from '../types';

export interface DSL_BadgeProps
  extends Omit<MuiBadgeProps, DSL_RestrictedVisualProps> {
  inline?: boolean;
}

const inlineBadgeStyles = {
  [`& .${MuiBadgeClasses.badge}`]: {
    position: 'static',
    transform: 'none',
  },
  [`&.${MuiBadgeClasses.root}`]: {
    position: 'static' as SystemCssProperties['position'],
  },
};

export const DSL_Badge = React.forwardRef(
  (
    {color = 'secondary', inline, ...badgeProps}: DSL_BadgeProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const sx = inline ? inlineBadgeStyles : null;
    return <MuiBadge sx={sx} color={color} {...badgeProps} ref={ref} />;
  },
);

DSL_Badge.displayName = 'DSL_Badge';

export {
  //
  /** @deprecated use DSL_BadgeProps instead*/
  DSL_BadgeProps as BadgeProps, //
  /** @deprecated use DSL_Badge instead*/
  DSL_Badge as Badge,
};
