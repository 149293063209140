import {australiaStateIconMap} from 'utils/global';

import {DSP_PartnerAccountEveryonePublic} from './types';

export interface DSP_UsePartnerAccountEntityMapperProps {
  partnerAccount: DSP_PartnerAccountEveryonePublic | null | undefined;
}

export function usePartnerAccountEntityMapper({
  partnerAccount,
}: DSP_UsePartnerAccountEntityMapperProps) {
  const {name, roleCategory, website, state, fileList, id} =
    partnerAccount ?? {};

  const initials = name
    ?.split(' ')
    .map(section => section[0])
    .slice(0, 2)
    .join('');

  const subTitle: string[] = [];

  if (roleCategory) {
    subTitle.push(roleCategory);
  }

  if (state) {
    subTitle.push(state);
  }

  return {
    initials,
    title: name ?? '',
    subTitle: subTitle.join(', '),
    website: website ?? undefined,
    src:
      australiaStateIconMap?.[`${id}` as keyof typeof australiaStateIconMap] ??
      fileList?.accountImageUrl ??
      australiaStateIconMap?.[state as keyof typeof australiaStateIconMap],
  };
}
