import React, {ReactNode} from 'react';

import {useBreakpoint} from 'components/DesignSystem/Library/hooks/useBreakpoint';

import {DSL_Box} from '../../Box';
import {DSL_FlexColumn} from '../../FlexColumn';
import {DSL_FlexRow, DSL_FlexRowProps} from '../../FlexRow';

export interface DSL_CardAdornmentLayoutProps {
  endAdornment?: ReactNode;
  children?: ReactNode;
  width?: DSL_FlexRowProps['width'];
  smallMobilePaddingLeft?: boolean;
  smallMobilePaddingRight?: boolean;
}

export const DSL_CardAdornmentLayout = ({
  endAdornment,
  children,
  width,
  smallMobilePaddingLeft,
  smallMobilePaddingRight,
}: DSL_CardAdornmentLayoutProps) => {
  const {isSm} = useBreakpoint();
  return (
    <DSL_FlexRow
      gap={0}
      flexWrap={'nowrap'}
      alignItems={'center'}
      width={width}
    >
      <DSL_Box width={'100%'}>{children}</DSL_Box>
      {endAdornment && (
        <DSL_FlexColumn
          pl={smallMobilePaddingLeft && isSm ? 1 : 2}
          pr={smallMobilePaddingRight && isSm ? 1 : 2}
        >
          {endAdornment}
        </DSL_FlexColumn>
      )}
    </DSL_FlexRow>
  );
};

DSL_CardAdornmentLayout.displayName = 'DSL_CardAdornmentLayout';

export {
  //
  /** @deprecated use DSL_CardAdornmentLayoutProps instead*/
  DSL_CardAdornmentLayoutProps as CardAdornmentLayoutProps, //
  /** @deprecated use DSL_CardAdornmentLayout instead*/
  DSL_CardAdornmentLayout as CardAdornmentLayout,
};
