import React from 'react';

import {DSL_MoreVertIcon} from '../../../Icons';
import {DSL_BaseIconButton, DSL_BaseIconButtonProps} from '../BaseIconButton';

export type DSL_ActionsMenuIconButtonProps = Omit<
  DSL_BaseIconButtonProps,
  'color' | 'children'
>;

export const DSL_ActionsMenuIconButton = (
  props: DSL_ActionsMenuIconButtonProps,
): JSX.Element => {
  return (
    <DSL_BaseIconButton aria-label={'actions menu'} {...props}>
      <DSL_MoreVertIcon fontSize={props?.size ?? 'large'} />
    </DSL_BaseIconButton>
  );
};

DSL_ActionsMenuIconButton.displayName = 'DSL_ActionsMenuIconButton';
