/* eslint-disable @typescript-eslint/explicit-function-return-type */
import * as R from 'ramda';
import {from} from 'rxjs';
import snackbarMachine, {
  SnackbarMessage,
  SnackbarObservable,
  SnackbarService,
} from 'utils/machines/snackbarMachine';
import {assign, interpret} from 'xstate';

const machine = snackbarMachine.withConfig({
  guards: {
    messageInQueue: (context): boolean => {
      return context.messageQueue.length > 0;
    },
    emptyQueue: (context): boolean => {
      return context.messageQueue.length === 0;
    },
  },
  actions: {
    dropMessage: assign({
      messageQueue: context => {
        return R.tail(context.messageQueue);
      },
    }),
    addMessage: assign({
      messageQueue: (context, event) => {
        if (R.isNil(event.record?.message)) {
          return context.messageQueue;
        }
        return [
          R.assoc(
            'key',
            new Date().getTime(),
            event.record as unknown as SnackbarMessage,
          ),
        ];
        // return R.append(
        //   R.assoc('key', new Date().getTime(), (event.record as unknown) as SnackbarMessage),
        //   context.messageQueue,
        // );
      },
    }),
  },
});

const service = interpret(machine) as SnackbarService;

const state$ = from(service as never) as SnackbarObservable;

export default {state$, service};
