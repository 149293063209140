import ListOutlined from '@mui/icons-material/ListOutlined';
import {
  BuiltInRoles,
  AuthorisationPolicy,
} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/nHVASApprovedAuditor',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "NHVASApprovedAuditors" */ './NHVASApprovedAuditors'
      ),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'NHVAS Approved Auditors',
  menuDescription:
    'List of NHVAS Approved Auditors who can audit an NHVAS management system on behalf of the NHVR.',
  menuIcon: ListOutlined,
  authorisation: {
    apiKey: null,
    policies: [BuiltInRoles.EVERYONE] as AuthorisationPolicy[],
  },
  sections: [],
};
