import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/customers/create',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "CreateCustomerAccount" */ './CreateCustomerAccount'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Add Customer account',
  menuIcon: LibraryAddCheckIcon,
  authorisation: {
    apiKey: null,
    policies: [
      {role: 'operationsAccountSysAdmin'},
      {role: 'operationsAccountFinanceAdmin'},
      {role: 'operationsAccountAdmin'},
      {role: 'operationsAccountSupervisor'},
      {role: 'operationsAccountPay'},
      {role: 'operationsAccountUser'},
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Customer accounts',
      to: '..',
    },
  ],
};
