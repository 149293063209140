import React, {useCallback, useRef} from 'react';

import {ListItem as MuiListItem} from '@mui/material';
import InfiniteScroll from 'react-infinite-scroller';

import {DSL_CircularProgress} from '../../CircularProgress';
import {useInfiniteLoopProtection} from '../../hooks/useInfiniteLoopProtection';
import {DSL_Box, DSL_BoxProps} from '../Box';

import {DSL_List, DSL_ListProps} from './List';

type ListBoxProps = Pick<DSL_BoxProps, 'overflowY'>;
export interface DSL_ListWithInfiniteScrollProps
  extends DSL_ListProps,
    ListBoxProps {
  /**
   * Name of the element that the component should render as.
   * Defaults to 'div'.
   */
  component?: React.ReactNode;
  /**
   * Whether there are more items to be loaded. Event listeners are removed if false.
   * Defaults to false.
   */
  hasMore?: boolean;
  /**
   * Whether the component should load the first set of items.
   * Defaults to true.
   */
  initialLoad?: boolean;
  /**
   * Whether new items should be loaded when user scrolls to the top of the scrollable area.
   * Default to false.
   */
  isReverse?: boolean;
  /**
   * A callback for when more items are requested by the user.
   * Page param is next page index.
   */
  loadMore(page: number): void;
  /**
   * The number of the first page to load, with the default of 0, the first page is 1.
   * Defaults to 0.
   */
  pageStart?: number;
  /**
   * The component to render for the list wrapper.  This component must accept a ref and a subset of DSP_List props.
   */
  ListComponent?: React.ElementType;

  /**
   * The height of the list. Note that you MUST either specify a height or cause a height via flex layout.
   * If the height is zero then you'll get into an infinite loadMore loop;
   */
  height?: DSL_ListProps['height'];
}

export const DSL_ListWithInfiniteScroll = ({
  pageStart,
  loadMore,
  isReverse,
  initialLoad,
  hasMore,
  children,
  height,
  ListComponent = DSL_List,
  overflowY = 'auto',
  component,
  ...listPropsOverrides
}: DSL_ListWithInfiniteScrollProps) => {
  const listRef = useRef<HTMLUListElement>(null);
  const checkForInfiniteLoop = useInfiniteLoopProtection({
    errorToThrow:
      'InfiniteScroll infinite loop detected. You may not have provided sufficient height to the list or your loadMore function may be returning the same results for the given page.',
  });

  const loadMoreWithSafety = useCallback(
    newPageNum => {
      checkForInfiniteLoop();
      loadMore(newPageNum);
    },
    [loadMore, checkForInfiniteLoop],
  );

  return (
    <ListComponent
      overflowY={overflowY}
      height={height}
      ref={listRef}
      {...listPropsOverrides}
    >
      <InfiniteScroll
        isReverse={isReverse}
        element={component}
        initialLoad={initialLoad}
        hasMore={hasMore}
        pageStart={pageStart}
        loadMore={loadMoreWithSafety}
        useWindow={false}
        getScrollParent={() => listRef.current}
        loader={
          <MuiListItem key={'loader'}>
            <DSL_Box
              display={'flex'}
              width={'100%'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <DSL_CircularProgress size={24} color={'primary'} />
            </DSL_Box>
          </MuiListItem>
        }
      >
        {/*foo*/}
        {children}
      </InfiniteScroll>
    </ListComponent>
  );
};
