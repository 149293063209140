import React from 'react';

import {
  DSL_ScrollableLayoutFooterProps,
  DSL_ScrollableLayoutFooter,
} from '../../ScrollableLayout';

export type DSL_PopperFooterProps = DSL_ScrollableLayoutFooterProps;

export const DSL_PopperFooter = (props: DSL_PopperFooterProps) => {
  return <DSL_ScrollableLayoutFooter {...props} />;
};

DSL_PopperFooter.displayName = 'DSL_PopperFooter';
