import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import GroupIcon from '@mui/icons-material/Group';
export default {
  path: '/regulators',
  component: lazy(
    () =>
      import(/* webpackChunkName: "OperationsAccount" */ './OperationsAccount'),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'Regulator accounts',
  menuIcon: GroupIcon,
  authorisation: {
    apiKey: null,
    policies: [{role: 'operationsAccountRead'}] as AuthorisationPolicy[],
  },
  sections: [],
};
