import React, {ReactNode} from 'react';

/* eslint-disable no-restricted-imports */
// TODO: migrate these components to the new design system
import Loading from 'components/templates/content/Loading';
import Unauthorised from 'components/templates/errors/Unauthorised';
/* eslint-enable no-restricted-imports */

import {DSL_Box, DSL_FlexColumn} from 'components/DesignSystem/Library';

import {
  DSP_PageContainer,
  DSP_PageContainerProps,
} from '../../Layout/PageContainer';

import {
  DSP_CloseButtonStack,
  DSP_SaveOrCancelButtonStack,
  DSP_CreateOrCancelButtonStack,
} from './ButtonStacks';

export type DSP_FormContainerMode =
  | 'update'
  | 'create'
  | 'readonly'
  | 'loading'
  | 'unauthorised';

export interface DSP_FormPageContainerViewProps
  extends Pick<
    DSP_PageContainerProps,
    | 'title'
    | 'subTitle'
    | 'actionItems'
    | 'pageInfo'
    | 'activeBreadcrumbLabel'
    | 'breadcrumbs'
    | 'hideBreadcrumbs'
    | 'showDivider'
  > {
  children?: ReactNode;
  mode: DSP_FormContainerMode;
  readOnlyComponent?: ReactNode;
  buttonStackOverride?: ReactNode;
  submitConfirmMessage?: string;
  renderButtonStack?: (mode: DSP_FormContainerMode) => ReactNode;
  hideBreadcrumbs?: boolean;
  unauthenticated?: boolean;
}

const defaultRenderButtonStack = (mode: DSP_FormContainerMode) => {
  if (mode === 'readonly') {
    return <DSP_CloseButtonStack />;
  }
  if (mode === 'update') {
    return <DSP_SaveOrCancelButtonStack />;
  }
  if (mode === 'create') {
    return <DSP_CreateOrCancelButtonStack />;
  }
  return null;
};

export const DSP_FormPageContainerView = ({
  title,
  subTitle,
  children,
  mode,
  readOnlyComponent,
  actionItems,
  pageInfo,
  activeBreadcrumbLabel,
  renderButtonStack = defaultRenderButtonStack,
  hideBreadcrumbs,
  showDivider,
  breadcrumbs,
}: DSP_FormPageContainerViewProps) => {
  if (mode === 'loading') {
    return <Loading />;
  }
  if (mode === 'unauthorised') {
    return <Unauthorised />;
  }

  const formContents =
    mode === 'readonly' && readOnlyComponent ? readOnlyComponent : children;

  return (
    <DSP_PageContainer
      title={title}
      subTitle={subTitle}
      actionItems={actionItems}
      pageInfo={pageInfo}
      activeBreadcrumbLabel={activeBreadcrumbLabel}
      breadcrumbs={breadcrumbs}
      hideBreadcrumbs={hideBreadcrumbs}
      showDivider={showDivider}
    >
      <form noValidate autoComplete="off">
        <DSL_FlexColumn gap={3}>
          <DSL_Box data-id={'page-container-view'} maxWidth={'100%'}>
            {formContents}
          </DSL_Box>
          {renderButtonStack(mode)}
        </DSL_FlexColumn>
      </form>
    </DSP_PageContainer>
  );
};

DSP_FormPageContainerView.displayName = 'DSP_FormPageContainerView';

export {
  //
  /** @deprecated use DSP_FormPageContainerViewProps instead*/
  DSP_FormPageContainerViewProps as FormPageContainerViewProps, //
  /** @deprecated use DSP_FormPageContainerView instead*/
  DSP_FormPageContainerView as FormPageContainerView,
};
