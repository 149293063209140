import React from 'react';
import * as R from 'ramda';
import clsx from 'clsx';
import BaseButton from '../BaseButton';
import Button from '@mui/material/Button';
import {useStyles} from '../../form.styles';
import {ButtonProps} from '../types';

/**
 * Primary Button
 */
const PrimaryButton: React.FC<ButtonProps> = (props: ButtonProps) => {
  const classes = useStyles();
  const {critical, children, fileInputProps, formMargin, ...otherProps} = props;

  if (!R.isNil(fileInputProps)) {
    return (
      <Button
        variant="contained"
        color={critical === true ? 'inherit' : 'primary'}
        disableElevation={true}
        className={critical === true ? classes.errorButton : classes.button}
        component="label"
      >
        {children}
        {R.isNil(fileInputProps) ? null : (
          <input
            type="file"
            accept={fileInputProps.accept}
            style={{display: 'none'}}
            onChange={fileInputProps.onChange}
          />
        )}
      </Button>
    );
  }
  return (
    <BaseButton
      variant="contained"
      disableElevation={true}
      color={critical === true ? 'error' : 'primary'}
      className={clsx(classes.button, formMargin ? classes.formMargin : '')}
      {...otherProps}
    >
      {children}
    </BaseButton>
  );
};

export default PrimaryButton;
