import {lazy} from 'react';
import Assignment from '@mui/icons-material/Assignment';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';
import {RouteConfig} from 'app/router/types';
import {ManageCustomer} from './Accreditation';

const ManageCustomers = {
  path: '/manage/customers',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "ManageAccreditations" */ './ManageAccreditations'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: true,
  menuTitle: 'Accreditation Library',
  menuIcon: Assignment,
  authorisation: {
    apiKey: 'AccreditationWithRelations.findOperations',
    policies: [{role: 'operationsAccreditationRead'}] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Accreditation',
      to: '/accreditation',
    },
  ],
} as RouteConfig;

export default [ManageCustomers, ManageCustomer];
