import React from 'react';

import {CheckboxGroupInputProps} from 'components/DesignSystem/Library';

import {useFormService} from '../FormProvider';
import {useFormFieldPropsSelector} from '../hooks/useFormFieldPropsSelector';
import {getArrayFieldProps} from '../utils/getArrayFieldProps';

import {CheckboxGroupFieldProps} from './CheckboxGroupField.Connector';

export function useCheckboxGroupField({
  fieldRef,
  options,
  onBlurEvent,
  onChangeEvent,
  recordsLoaderRef,
  label,
  includeOptionalSuffix,
  ...restProps
}: CheckboxGroupFieldProps): CheckboxGroupInputProps {
  const service = useFormService();

  const stateDerivedProps = useFormFieldPropsSelector(
    fieldRef,
    service,
    state => {
      const {value, ...fieldProps} = getArrayFieldProps<true, false, false>({
        fieldRef,
        state,
        items: options,
        label,
        multiple: true,
        includeOptionalSuffix,
      });

      const valueArray = value as (string | number)[];

      return {
        ...fieldProps,
        value: valueArray,
        onChange: (e: React.ChangeEvent<HTMLInputElement>): void => {
          const nextValue = e?.currentTarget?.value || [];
          service.send({
            type: 'CHANGEBLUR',
            fieldRef,
            value: nextValue,
          });
          onChangeEvent?.();
        },
        onBlur: (): void => {
          service.send({type: 'BLUR', fieldRef});
          onBlurEvent?.();
        },
      };
    },
  );
  return {
    ...stateDerivedProps,
    ...restProps,
  };
}
