import React, {ReactNode} from 'react';

import {DSL_FlexRow} from 'components/DesignSystem/Library/Layout/FlexRow';
import {
  DSL_Heading5,
  DSL_Heading5Props,
} from 'components/DesignSystem/Library/Typography/Heading5';

export interface DSL_SummaryFieldsetHeaderProps extends DSL_Heading5Props {
  rightSection?: ReactNode;
}

export const DSL_SummaryFieldsetHeader = ({
  rightSection,
  ...props
}: DSL_SummaryFieldsetHeaderProps) => {
  return (
    <DSL_FlexRow justifyContent={'space-between'} alignItems={'center'}>
      <DSL_Heading5 {...props} />
      {rightSection}
    </DSL_FlexRow>
  );
};

DSL_SummaryFieldsetHeader.displayName = 'DSL_SummaryFieldsetHeader';

export {
  //
  /** @deprecated use DSL_SummaryFieldsetHeaderProps instead*/
  DSL_SummaryFieldsetHeaderProps as SummaryFieldsetHeaderProps, //
  /** @deprecated use DSL_SummaryFieldsetHeader instead*/
  DSL_SummaryFieldsetHeader as SummaryFieldsetHeader,
};
