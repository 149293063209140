import DataUsage from '@mui/icons-material/Dashboard';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/operationManualDocumentTemplates',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "OperationManualDocumentTemplates" */ './OperationManualDocumentTemplates'
      ),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'Operation Manual Document Templates',
  menuIcon: DataUsage,
  authorisation: {
    apiKey: null,
    policies: [{role: 'operationsAccreditationAdmin'}] as AuthorisationPolicy[],
  },
  category: 'Admin',
  sections: [],
};
