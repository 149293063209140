import React from 'react';

export const DSP_SegmentAccessTypeIcon = () => {
  return (
    <></>
    // TODO: we plan to add this soon, please don't remove this
    // <svg
    //   width="48"
    //   height="4"
    //   viewBox="0 0 48 4"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <g clipPath="url(#clip0_2506_16226)">
    //     <rect width="48" height="4" rx="2" fill="#C1C7CD" />
    //     <rect width="13.1" height="4" fill="#49BF31" />
    //     <rect
    //       width="26.1"
    //       height="4"
    //       transform="translate(15.1001)"
    //       fill="#F5D64D"
    //     />
    //     <rect
    //       width="35"
    //       height="4"
    //       transform="translate(43.2002)"
    //       fill="#D10A0A"
    //     />
    //   </g>
    //   <defs>
    //     <clipPath id="clip0_2506_16226">
    //       <rect width="48" height="4" rx="2" fill="white" />
    //     </clipPath>
    //   </defs>
    // </svg>
  );
};
