import {HttpLink} from '@apollo/client';
import fetch from 'unfetch';
import {getGraphqlEndpoint, getGraphqlEndpointKey} from 'utils/global';

export const httpLink = new HttpLink({
  uri: getGraphqlEndpoint(),
  fetch: fetch,
  headers: {
    'Ocp-Apim-Subscription-Key': getGraphqlEndpointKey(),
  },
});
