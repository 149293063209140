import {DSP_RegulatorAccountEveryonePublic} from './types';

export interface DSP_UseRegulatorAccountEntityMapperProps {
  regulatorAccount: DSP_RegulatorAccountEveryonePublic | null | undefined;
}

export function useRegulatorAccountEntityMapper({
  regulatorAccount,
}: DSP_UseRegulatorAccountEntityMapperProps) {
  const {name, website, fileList} = regulatorAccount ?? {};

  const initials = name
    ?.split(' ')
    .map(section => section[0])
    .slice(0, 2)
    .join('');

  return {
    initials,
    title: name ?? '',
    subTitle: 'Regulator',
    website: website ?? undefined,
    src: fileList?.accountImageUrl ?? undefined,
  };
}
