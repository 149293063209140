import {UserProfile} from '@regulatory-platform/common-utils/dist';
import {useAppServicesContext} from './AppServicesContext';
import {useSelector} from '@xstate/react';
import * as R from 'ramda';

export function useUserProfile(): UserProfile {
  const {auth} = useAppServicesContext();
  return useSelector(
    auth.service,
    authState => {
      return (
        authState.context.userProfile ||
        /**
         * Assume this function is always used on authorised routes
         * When running tests provide a fake user profile where no auth context exists
         */
        ({
          accountId: 0,
          accountType: 'customer',
          id: 0,
          email: '',
        } as UserProfile)
      );
    },
    R.equals,
  );
}
