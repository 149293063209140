import {
  BrowserHistoryObservable,
  BrowserHistoryState,
} from 'app/services/browserHistoryService';
import React, {useContext} from 'react';
import {AppObservable, AppService} from 'utils/machines/appMachine';
import {
  AuthStoreObservable,
  AuthStoreService,
} from 'utils/machines/authMachine';
import {
  SnackbarObservable,
  SnackbarService,
} from 'utils/machines/snackbarMachine';
import {WebSocketService} from './websocketService';
import {useSelector} from '@xstate/react';

export type AppServicesContextProps = {
  browserHistory: {
    history$: BrowserHistoryObservable;
    history: BrowserHistoryState;
  };
  app: {
    state$: AppObservable;
    service: AppService;
  };
  auth: {
    state$: AuthStoreObservable;
    service: AuthStoreService;
  };
  snackbar: {
    state$: SnackbarObservable;
    service: SnackbarService;
  };
  webSocketService: WebSocketService;
};

const AppServicesContext = React.createContext({} as AppServicesContextProps);

export function useAppServicesContext(): AppServicesContextProps {
  return useContext(AppServicesContext);
}

export function useIsBetaMode() {
  const {app} = useAppServicesContext();
  return useSelector(app.service, appState => appState.matches('beta'));
}

export function useSnackbarService(): AppServicesContextProps['snackbar']['service'] {
  return useContext(AppServicesContext)?.snackbar.service;
}
export function useWebsocketService(): WebSocketService {
  return useContext(AppServicesContext)?.webSocketService;
}

export default AppServicesContext;
