import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/levelCrossings/:id',
  component: lazy(
    () => import(/* webpackChunkName: "LevelCrossing" */ './LevelCrossing'),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Manage level crossing',
  menuIcon: LibraryAddCheckIcon,
  authorisation: {
    apiKey: 'LevelCrossingWithRelations.findById',
    policies: [
      {
        role: 'partnerAccessRead',
      },
      {
        role: 'partnerSpatialRead',
      },
      {
        role: 'providerSpatialRead',
      },
      {
        role: 'operationsSpatialRead',
      },
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Level crossings',
      to: '..',
    },
  ],
};
