import {
  JSONSchemaRecord,
  schemaJsonPointerResolver,
} from '@regulatory-platform/common-utils/dist';
import {parse as dateFnsParse, parseISO} from 'date-fns';
import {parse} from 'papaparse';
import {isNil, max, trim} from 'ramda';
import getFormStore from 'utils/stores/getFormStore';
import {FormRecord} from 'utils/stores/types';
import {assign, createMachine} from 'xstate';

import {createImportFromCsvRowMachine} from './importFromCsvRowMachine';
import {
  CsvAcceptedEvent,
  CsvColumn,
  CsvParseCompleteEvent,
  CsvRowMap,
  CsvRowUpdatedEvent,
  ImportFromCsvMachineContext,
  ImportFromCsvMachineEvents,
  InvokeResultEvent,
  SetErrorEvent,
} from './types';

export const importFromCsvMachine = createMachine<
  ImportFromCsvMachineContext,
  ImportFromCsvMachineEvents
>(
  {
    id: 'importFromCsv',
    initial: 'closed',
    entry: ['init'],
    states: {
      closed: {
        id: 'closed',
        entry: ['resetImport'],
        on: {
          OPEN_DIALOG: {
            target: 'open',
          },
        },
      },
      open: {
        initial: 'idle',
        states: {
          idle: {
            on: {
              DROP_ACCEPTED: {
                target: 'parsing',
                actions: ['clearFormError'],
              },
              DROP_REJECTED: {
                target: 'idle',
                actions: ['setFormError'],
              },
            },
          },
          parsing: {
            invoke: {
              src: 'parseCsv',
              onDone: {
                target: 'userInput',
                actions: ['onCsvParsed'],
              },
              onError: {
                target: 'idle',
                actions: ['setFormError'],
              },
            },
          },
          userInput: {
            on: {
              ROW_UPDATED: {
                actions: ['updateRow'],
              },
              RESET: {
                target: 'idle',
                actions: ['resetImport'],
              },
              IMPORT: [
                {cond: 'hasSelectedRows', target: 'importing'},
                {
                  target: 'userInput',
                  actions: assign({
                    formErrorMessage: (
                      _, // eslint-disable-line @typescript-eslint/no-unused-vars
                    ) => 'At least one row must be selected to import data.',
                  }),
                },
              ],
            },
          },
          importing: {
            invoke: {
              src: 'importData',
              onDone: {
                target: '#closed',
              },
              onError: {
                target: 'userInput',
                actions: ['setFormError'],
              },
            },
          },
        },
      },
    },
    on: {
      CLOSE_DIALOG: {
        target: 'closed',
      },
    },
  },
  {
    guards: {
      hasSelectedRows: ({rows}) =>
        !!Object.values(rows).find(row => row.isSelected),
    },
    /* eslint-disable @typescript-eslint/no-unused-vars */
    actions: {
      setFormError: assign<
        ImportFromCsvMachineContext,
        ImportFromCsvMachineEvents
      >({
        formErrorMessage: (_, event) =>
          (event as SetErrorEvent).errorMessage ??
          (event as InvokeResultEvent<string>).data,
      }),
      clearFormError: assign({
        formErrorMessage: _ => undefined,
      }),
      updateRow: assign({
        rows: ({rows}, event) => {
          const {type, lineIndex, ...newRow} = event as CsvRowUpdatedEvent;
          rows[lineIndex] = {...rows[lineIndex], ...newRow};
          return rows;
        },
        formErrorMessage: ({formErrorMessage}, event) =>
          (event as CsvRowUpdatedEvent).isSelected
            ? undefined
            : formErrorMessage,
      }),
      resetImport: assign(
        (context): Partial<ImportFromCsvMachineContext> => ({
          ...context,
          csv: [],
          rows: {},
          file: undefined,
          formErrorMessage: undefined,
        }),
      ),
      onCsvParsed: assign((context, event) => {
        const fieldName = context.fieldRef?.replace('#/', '');
        const {
          data: {csv, file, omittedRowCount},
        } = event as InvokeResultEvent<CsvParseCompleteEvent>;

        const rows = csv.reduce((rowMap: CsvRowMap, record, lineIndex) => {
          const formStore = getFormStore<FormRecord>(
            context.schema,
            context.validator,
            fieldName ? {[fieldName]: [record]} : (record as FormRecord),
          );
          const xValid = formStore.schema['x-valid'];
          const isValid = (xValid?.valid && xValid?.validChildren) ?? false;
          const isSelected = isValid;
          const machine = createImportFromCsvRowMachine({
            ...formStore,
            lineIndex,
            isSelected,
            actors: {},
            shared: {},
            props: {
              overrideTouched: true,
            },
            validator: context.validator,
            onGenerateSASTokenFunc: async () => undefined,
          });

          rowMap[lineIndex] = {
            machine,
            isValid,
            isSelected,
            record: formStore.record,
          };

          return rowMap;
        }, {});

        return {
          ...context,
          csv,
          file,
          rows,
          omittedRowCount,
        };
      }),
    },
    /* eslint-enable @typescript-eslint/no-unused-vars */
    services: {
      parseCsv: async ({schema, columns, maxRows}, event) => {
        const {file} = event as CsvAcceptedEvent;
        const {csv, rowCount} = prepareCsv(schema, await file.text());

        return new Promise<CsvParseCompleteEvent>((resolve, reject) => {
          parse<Record<string, unknown>>(csv, {
            header: true,
            worker: true,
            preview: maxRows,
            skipEmptyLines: 'greedy',
            error: (error: Error) => reject(error.message),
            complete: ({data}) => {
              if (!data.length) {
                reject('CSV file contains no rows');
              }

              resolve({
                file,
                csv: transformResults(schema, columns, data),
                omittedRowCount: max(0, maxRows ? rowCount - maxRows : 0),
              } as CsvParseCompleteEvent);
            },
          });
        });
      },
    },
  },
);

function prepareCsv(schema: JSONSchemaRecord, csv: string) {
  const lines = csv
    .replace(/\r|\n|\r\n/g, '\n')
    .split('\n')
    .filter(line => !!line);

  const headers = lines[0].split(',');

  lines[0] = headers
    .map(header => transformHeaderColumn(schema, header))
    .join(',');

  return {
    csv: lines.join('\n'),
    rowCount: lines.length - 1,
  };
}

function transformHeaderColumn(
  schema: JSONSchemaRecord,
  header: string,
): string {
  const schemaProperties = Object.entries(schema.properties ?? {});
  const propertyIndex = schemaProperties.findIndex(
    ([, property]) => (property as JSONSchemaRecord).title === header,
  );
  return schemaProperties[propertyIndex]?.[0] ?? header;
}

function transformResults(
  schema: JSONSchemaRecord,
  columns: CsvColumn[],
  rows: Record<string, unknown>[],
): Record<string, unknown>[] {
  return rows.map(row => {
    for (const [columnTitle, value] of Object.entries(row)) {
      const {fieldName, fieldRef} =
        resolveCsvColumnFromTitle(columns, columnTitle) ?? {};

      if (!fieldName) {
        continue;
      }

      delete row[columnTitle];
      row[fieldName] = transformValue(
        schema,
        fieldRef,
        fieldName,
        value as string,
      );
    }
    return row;
  });
}

function resolveCsvColumnFromTitle(
  columns: CsvColumn[],
  columnTitle: string,
): CsvColumn | undefined {
  return columns.find(
    col => col.title === columnTitle || col.fieldName === columnTitle,
  );
}

function transformValue(
  schema: JSONSchemaRecord,
  fieldRef: string | undefined,
  fieldName: string,
  value: string,
) {
  const fieldSchema = schemaJsonPointerResolver(
    fieldRef ?? `#/${fieldName}`,
    fieldRef ? `x-items/properties/${fieldName}` : undefined,
  )(schema);

  value = trim(value);

  if (isNil(value)) {
    return null;
  }

  try {
    switch (fieldSchema.type) {
      case 'string':
        switch (fieldSchema.format) {
          case 'date':
          case 'date-time':
            return parseDate(value);
          default:
            return value;
        }
      case 'boolean':
        return parseBoolean(value);
      case 'integer':
      case 'number':
        return parseNumber(value);
      case 'null':
        return null;
    }
  } catch (error) {
    return null;
  }
}

const truthyValues = ['true', 'y', 'yes', 1];
const falsyValues = ['false', 'n', 'no', 0];

function parseBoolean(maybeBool: string): boolean | null {
  const lowercase = maybeBool.toLowerCase();

  for (const truthy of truthyValues) {
    if (lowercase === truthy) {
      return true;
    }
  }

  for (const falsy of falsyValues) {
    if (lowercase === falsy) {
      return false;
    }
  }

  return null;
}

const dateFormats: [RegExp, string][] = [
  [/^\d{4}-\d{2}-\d{2}$/, 'yyyy-MM-dd'],
  [/^\d{2}\/\d{2}\/\d{4}$/, 'dd/MM/yyyy'],
  [/^\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}:\d{2}$/, 'dd/MM/yyyy HH:mm:ss'],
];

function parseDate(maybeDate: string): string | null {
  // ISO format with time, e.g. 2024-03-05T03:42:58.296Z
  if (maybeDate.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}[zZ]?$/)) {
    return parseISO(maybeDate).toISOString();
  }

  for (const [regEx, format] of dateFormats) {
    if (maybeDate.match(regEx)) {
      return dateFnsParse(maybeDate, format, new Date()).toISOString();
    }
  }

  return null;
}

function parseNumber(maybeNumber: string): number | null {
  const number = Number(maybeNumber);
  return isNaN(number) ? null : number;
}
