import React, {ReactNode} from 'react';

import {Theme} from '@mui/material';
import {useTheme} from '@mui/styles';

import {
  DSL_Body1,
  DSL_Box,
  DSL_Caption,
  DSL_FlexColumn,
  DSL_Table,
  DSL_TableBody,
  DSL_TableCell,
  DSL_TableHead,
  DSL_TableRow,
} from 'components/DesignSystem/Library';

import {DSP_SummaryItemRenderer} from '../SummaryItemRenderer';

import {
  DSP_UseSummaryTableRendererArgs,
  isSummaryTableColumnGroup,
  useSummaryTableRenderer,
} from './useSummaryTableRenderer';

export interface DSP_SummaryTableRendererProps
  extends DSP_UseSummaryTableRendererArgs {
  emptyState?: ReactNode;
  label?: ReactNode;
  ariaLabel?: string;
}

export const DSP_SummaryTableRenderer = ({
  emptyState = '-',
  label,
  ...props
}: DSP_SummaryTableRendererProps) => {
  const {palette} = useTheme<Theme>();
  const {headers, columnsToRender, rows, groupCount, caption, ariaLabel} =
    useSummaryTableRenderer(props);

  return (
    <DSL_FlexColumn gap={0.5}>
      {label && <DSL_Caption color={'text.secondary'}>{label}</DSL_Caption>}
      {!rows.length ? (
        <DSL_Body1>{emptyState}</DSL_Body1>
      ) : (
        <DSL_Table aria-label={`${ariaLabel} table`}>
          {headers && (
            <DSL_TableHead>
              {!!groupCount && (
                <DSL_TableRow>
                  {columnsToRender.map((colOrGroup, colIndex) => {
                    const isGroup = isSummaryTableColumnGroup(colOrGroup);

                    return (
                      <DSL_TableCell
                        padding={'none'}
                        key={colIndex}
                        colSpan={isGroup ? colOrGroup.columns.length : 1}
                        minWidth={!isGroup ? colOrGroup?.width : undefined}
                      >
                        {isGroup && (
                          <DSL_Box
                            bgcolor={palette.primary.shades?.['8p']}
                            px={2}
                            py={0.5}
                          >
                            {colOrGroup.group}
                          </DSL_Box>
                        )}
                      </DSL_TableCell>
                    );
                  })}
                </DSL_TableRow>
              )}
              <DSL_TableRow>
                {headers.map(header => (
                  <DSL_TableCell key={header.fieldRef}>
                    {header.label}
                  </DSL_TableCell>
                ))}
              </DSL_TableRow>
            </DSL_TableHead>
          )}
          <DSL_TableBody>
            {rows.map((row, idx) => (
              <DSL_TableRow key={idx}>
                {row.map(({width, minWidth, ...summaryItemProps}, cellidx) => (
                  <DSL_TableCell
                    key={cellidx}
                    width={width}
                    minWidth={minWidth}
                  >
                    <DSP_SummaryItemRenderer
                      variant={'table'}
                      {...summaryItemProps}
                    />
                  </DSL_TableCell>
                ))}
              </DSL_TableRow>
            ))}
          </DSL_TableBody>
          {caption}
        </DSL_Table>
      )}
    </DSL_FlexColumn>
  );
};

DSP_SummaryTableRenderer.displayName = 'DSP_SummaryTableRenderer';

export {
  /** @deprecated use DSP_SummaryTableRenderer instead*/
  DSP_SummaryTableRenderer as SummaryTableRenderer,
};
