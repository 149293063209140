import React from 'react';

import {DSL_ReplyOutlinedIcon} from '../../Icons';
import {DSL_BaseIconInfo, DSL_BaseIconInfoProps} from '../BaseIconInfo';

export type DSL_ReplyIconInfoProps = Omit<DSL_BaseIconInfoProps, 'icon'>;

export const DSL_ReplyIconInfo = (props: DSL_ReplyIconInfoProps) => {
  return <DSL_BaseIconInfo icon={<DSL_ReplyOutlinedIcon />} {...props} />;
};

DSL_ReplyIconInfo.displayName = 'DSL_ReplyIconInfo';

export {
  //
  /** @deprecated use DSL_ReplyIconInfoProps instead*/
  DSL_ReplyIconInfoProps as ReplyIconInfoProps, //
  /** @deprecated use DSL_ReplyIconInfo instead*/
  DSL_ReplyIconInfo as ReplyIconInfo,
};
