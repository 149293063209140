import React from 'react';

import {
  DSL_ButtonBarGroup,
  DSL_ContainedButton,
  DSL_Dialog,
  DSL_DialogActions,
  DSL_DialogContent,
  DSL_DialogProps,
  DSL_DialogTitle,
  DSL_FlexColumn,
  DSL_PreviousButton,
  DSL_TextButton,
} from 'components/DesignSystem/Library';

import {ImportFromCsvLoader} from './ImportFromCsvLoader';
import {
  ImportFromCsvEditStep,
  ImportFromCsvEditStepProps,
} from './Steps/ImportFromCsvEditStep';
import {
  ImportFromCsvSelectFileStep,
  ImportFromCsvImportStepProps,
} from './Steps/ImportFromCsvSelectFileStep';

export interface DSP_ImportFromCsvProps
  extends Omit<ImportFromCsvImportStepProps, 'errorMessage'>,
    Omit<ImportFromCsvEditStepProps, 'tableTitle'> {
  step: 'SELECT' | 'EDIT';
  open: DSL_DialogProps['open'];
  isLoading: boolean;
  nameOfData: string;
  onClose: () => void;
  onImport: () => void;
  onReset: () => void;
  maxRows: number;
  size?: DSL_DialogProps['size'];
}

export const DSP_ImportFromCsv = ({
  size = 'xl',
  isLoading,
  maxRows,
  step,
  omittedRowCount,
  columns,
  rowMachines,
  formError,
  open,
  nameOfData,
  onClose,
  onDropRejected,
  onDropAccepted,
  onImport,
  selectedRowCount,
  onDownloadFormat,
  invalidRowCount,
  csvService,
  onReset,
}: DSP_ImportFromCsvProps) => {
  const label = `import-${nameOfData}-from-csv-dialog`;
  const titleId = `import-${nameOfData}-from-csv-dialog-title`;
  const contentId = `import-${nameOfData}-from-csv-dialog-content`;

  return (
    <DSL_Dialog
      open={open}
      size={size}
      aria-label={label}
      aria-labelledby={titleId}
      aria-describedby={contentId}
    >
      <DSL_DialogTitle
        id={titleId}
        onClose={onClose}
        title={`Import ${nameOfData}s`}
        subTitle={
          step === 'SELECT' ? 'Select a CSV file' : 'Select data to import'
        }
      />
      <DSL_DialogContent id={contentId}>
        <ImportFromCsvLoader isLoading={isLoading}>
          <DSL_FlexColumn gap={2}>
            {step === 'SELECT' && (
              <ImportFromCsvSelectFileStep
                maxRows={maxRows}
                nameOfData={nameOfData}
                errorMessage={formError}
                onDropRejected={onDropRejected}
                onDropAccepted={onDropAccepted}
                onDownloadFormat={onDownloadFormat}
              />
            )}
            {step === 'EDIT' && (
              <ImportFromCsvEditStep
                columns={columns}
                maxRows={maxRows}
                formError={formError}
                csvService={csvService}
                rowMachines={rowMachines}
                omittedRowCount={omittedRowCount}
                invalidRowCount={invalidRowCount}
                selectedRowCount={selectedRowCount}
                tableTitle={`${nameOfData} import table`}
              />
            )}
          </DSL_FlexColumn>
        </ImportFromCsvLoader>
      </DSL_DialogContent>
      <DSL_DialogActions>
        {step === 'EDIT' && (
          <DSL_ButtonBarGroup>
            <DSL_PreviousButton onClick={onReset} />
          </DSL_ButtonBarGroup>
        )}
        <DSL_ButtonBarGroup>
          <DSL_TextButton onClick={onClose}>Cancel</DSL_TextButton>
          {step === 'EDIT' && (
            <DSL_ContainedButton onClick={onImport}>Import</DSL_ContainedButton>
          )}
        </DSL_ButtonBarGroup>
      </DSL_DialogActions>
    </DSL_Dialog>
  );
};

DSP_ImportFromCsv.displayName = 'DSL_ImportFromCsv';
