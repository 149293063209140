import React from 'react';

import {
  DSL_FlexColumn,
  DSL_FlexColumnProps,
} from 'components/DesignSystem/Library/Layout/FlexColumn';

export type DSL_SummaryContainerProps = DSL_FlexColumnProps;

export const DSL_SummaryContainer = (props: DSL_SummaryContainerProps) => {
  return <DSL_FlexColumn gap={4} {...props} />;
};

DSL_SummaryContainer.displayName = 'DSL_SummaryContainer';

export {
  //
  /** @deprecated use DSL_SummaryContainerProps instead*/
  DSL_SummaryContainerProps as SummaryContainerProps, //
  /** @deprecated use DSL_SummaryContainer instead*/
  DSL_SummaryContainer as SummaryContainer,
};
