import React from 'react';

import {DSL_Heading5} from 'components/DesignSystem/Library/Typography/Heading5';

export interface DSL_FormSectionHeaderProps {
  children: React.ReactNode;
}

export const DSL_FormSectionHeader = ({
  children,
}: DSL_FormSectionHeaderProps) => {
  return <DSL_Heading5>{children}</DSL_Heading5>;
};

DSL_FormSectionHeader.displayName = 'DSL_FormSectionHeader';

export {
  //
  /** @deprecated use DSL_FormSectionHeaderProps instead*/
  DSL_FormSectionHeaderProps as FormSectionHeaderProps, //
  /** @deprecated use DSL_FormSectionHeader instead*/
  DSL_FormSectionHeader as FormSectionHeader,
};
