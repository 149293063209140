import React, {ReactNode} from 'react';

import {ListItem} from '@mui/material';

import {DSL_List} from '../../List';

import {DSL_PopperListSubheader} from './PopperListSubheader';

export interface DSL_PopperListItemGroupProps {
  title: ReactNode;
  children: ReactNode;
}

export const DSL_PopperListItemGroup = ({
  title,
  children,
}: DSL_PopperListItemGroupProps) => {
  return (
    <ListItem disablePadding>
      <DSL_List disablePadding>
        <DSL_PopperListSubheader>{title}</DSL_PopperListSubheader>
        {children}
      </DSL_List>
    </ListItem>
  );
};

DSL_PopperListItemGroup.displayName = 'DSL_PopperListItemGroup';

export {
  //
  /** @deprecated use DSL_PopperListItemGroupProps instead*/
  DSL_PopperListItemGroupProps as PopperListItemGroupProps, //
  /** @deprecated use DSL_PopperListItemGroup instead*/
  DSL_PopperListItemGroup as PopperListItemGroup,
};
