import React, {ReactNode} from 'react';

import TextButton from '../../../../../forms/buttons/TextButton';
import {DSL_Badge} from '../../../Badge';
import {DSL_TextButton} from '../../../Buttons/TextButton';
import {DSL_FilterAltOutlinedIcon} from '../../../Icons';
import {DSL_FlexColumn} from '../../FlexColumn';
import {DSL_FlexRow} from '../../FlexRow';
import {DSL_Popper, DSL_PopperProps, DSL_OpenerProps} from '../Popper';
import {DSL_PopperBody} from '../PopperBody/PopperBody';
import {DSL_PopperHeader} from '../PopperHeader/PopperHeader';

export interface FilterPopperProps {
  popperProps: DSL_PopperProps;
  openerProps: DSL_OpenerProps<HTMLButtonElement>;
  filterCount?: number;
  children?: ReactNode;
  onReset: () => void;
}

export const FilterPopper = React.memo(
  ({
    popperProps,
    openerProps,
    filterCount,
    children,
    onReset,
  }: FilterPopperProps) => {
    const filterBadge = filterCount ? (
      <DSL_Badge color={'primary'} inline badgeContent={filterCount} />
    ) : null;

    return (
      <>
        <DSL_TextButton
          startIcon={<DSL_FilterAltOutlinedIcon />}
          endIcon={filterBadge}
          {...openerProps}
        >
          Filters
        </DSL_TextButton>
        <DSL_Popper
          {...popperProps}
          placement={'right-start'}
          width={{
            xs: '100%',
            sm: '100%',
            md: '393px',
            lg: '393px',
            xl: '393px',
          }}
        >
          <DSL_PopperHeader
            title={'Filters'}
            onClose={popperProps.closePopper}
          />
          <DSL_PopperBody padding={'NONE'}>
            <DSL_FlexColumn px={3} py={2} gap={3} overflowX={'hidden'}>
              <DSL_FlexRow justifyContent={'flex-end'}>
                <TextButton onClick={onReset} disabled={filterCount === 0}>
                  Reset
                </TextButton>
              </DSL_FlexRow>
              {children}
            </DSL_FlexColumn>
          </DSL_PopperBody>
        </DSL_Popper>
      </>
    );
  },
);

FilterPopper.displayName = 'FilterPopper';
