import React from 'react';

import {useTheme} from '@mui/material';
import {DSL_Intercom} from 'components/DesignSystem/Library/Icons';

import {DSL_Avatar, DSL_AvatarProps} from '../Avatar';

export interface DSL_IntercomAvatarProps
  extends Omit<DSL_AvatarProps, 'bgcolor'> {
  error?: boolean;
}

export const DSL_IntercomAvatar = ({
  error,
  ...props
}: DSL_IntercomAvatarProps) => {
  const {palette} = useTheme();

  const bgColor = palette.primary.dark;

  return (
    <DSL_Avatar bgcolor={bgColor} {...props}>
      <DSL_Intercom />
    </DSL_Avatar>
  );
};

DSL_IntercomAvatar.displayName = 'DSL_IntercomAvatar';

export {
  //
  /** @deprecated use DSL_IntercomAvatarProps instead*/
  DSL_IntercomAvatarProps as IntercomAvatarProps, //
  /** @deprecated use DSL_IntercomAvatar instead*/
  DSL_IntercomAvatar as IntercomAvatar,
};
