import React, {ReactNode} from 'react';

import {
  Chip,
  HorizontalStack,
  RouterLink,
} from 'components/DesignSystem/Library';

import {DSP_NotificationCard} from '../../NotificationCard';
import {DSP_NotificationCardBody} from '../../NotificationCardBody';
import {DSP_NotificationCardHeader} from '../../NotificationCardHeader';
import {DSP_NotificationCardTitle} from '../../NotificationCardTitle';

export interface DSP_BaseAccessProductChangeSetNotificationCardViewProps {
  avatar: ReactNode | undefined;
  href?: string;
  changeSetId?: string;
  created?: Date;
  actionDescription?: string;
  name?: string;
  description?: string;
  status?: string;
  isRead?: boolean;
}

export const DSP_BaseAccessProductChangeSetNotificationCardView = ({
  avatar,
  href,
  created,
  actionDescription,
  name,
  description,
  status,
  changeSetId,
  isRead,
}: DSP_BaseAccessProductChangeSetNotificationCardViewProps) => {
  return (
    <DSP_NotificationCard href={href} isRead={isRead}>
      <DSP_NotificationCardHeader
        created={created}
        actionDescription={actionDescription}
        avatar={avatar}
      />
      {name && <DSP_NotificationCardTitle>{name}</DSP_NotificationCardTitle>}
      {description && (
        <DSP_NotificationCardBody>{description}</DSP_NotificationCardBody>
      )}
      <HorizontalStack>
        <RouterLink href={href}>{changeSetId}</RouterLink>
        {status && <Chip variant={'outlined'} size={'small'} label={status} />}
      </HorizontalStack>
    </DSP_NotificationCard>
  );
};

DSP_BaseAccessProductChangeSetNotificationCardView.displayName =
  'DSP_BaseAccessProductChangeSetNotificationCardView';

export {
  //
  /** @deprecated use DSP_BaseAccessProductChangeSetNotificationCardViewProps instead*/
  DSP_BaseAccessProductChangeSetNotificationCardViewProps as BaseAccessProductChangeSetNotificationCardViewProps, //
  /** @deprecated use DSP_BaseAccessProductChangeSetNotificationCardView instead*/
  DSP_BaseAccessProductChangeSetNotificationCardView as BaseAccessProductChangeSetNotificationCardView,
};
