/* eslint-disable @typescript-eslint/explicit-function-return-type */
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import CloseIcon from '@mui/icons-material/Close';
import {useSelector} from '@xstate/react';
import * as R from 'ramda';
import React from 'react';
import {
  MessageVariant,
  SnackbarService,
} from '../../utils/machines/snackbarMachine';
import {useStyles} from './AppSnackbar.styles';
import AppSnackbarMessage from './AppSnackbarMessage';

type AppSnackbarProps = {
  service: SnackbarService;
};

const AppSnackbar: React.FC<AppSnackbarProps> = ({
  service,
}: AppSnackbarProps) => {
  const classes = useStyles();
  //snackbar already started further up component hierarchy
  const snackbarState = useSelector(
    service,
    R.identity,
    (/*_prevState, _nextState*/) => {
      //true for no change
      // const prevQueue = _prevState.context.messageQueue
      // const nextQueue = nextState.context.messageQueue
      // return !((_prevState.matches('inactive') && nextState.matches('active')) || nextQueue.length === 0); //nextState.event.type === 'CLOSE';
      return false;
    },
  );

  const handleCloseSnackbar = (): void => {
    service.send('CLOSE');
  };

  const snackBarVisible = snackbarState.context.messageQueue.length > 0;
  const snackBarOpen = snackbarState.matches('active');
  let variant = 'success' as MessageVariant;
  let message = '';
  let key;
  if (snackBarVisible) {
    variant = snackbarState.context.messageQueue[0].variant;
    message = snackbarState.context.messageQueue[0].message;
    key = snackbarState.context.messageQueue[0].key;
  }

  if (!snackBarVisible) {
    return null;
  }

  return (
    <Snackbar
      key={key}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      className={classes.noPrint}
      autoHideDuration={6000}
      open={snackBarOpen}
      onClose={handleCloseSnackbar}
      TransitionComponent={props => <Slide {...props} direction="up" />}
    >
      <SnackbarContent
        className={classes[variant]}
        aria-describedby="client-snackbar"
        message={<AppSnackbarMessage variant={variant} message={message} />}
        action={
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleCloseSnackbar}
            size="large"
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
        }
      />
    </Snackbar>
  );
};

export default AppSnackbar;
