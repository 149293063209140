import React from 'react';

import {TabStep} from 'components/navigation/types';
import {FormMachineContext} from 'utils/stores/types';

import {DSP_FormTabsView} from './FormTabs.View';
import {useFormTabs} from './useFormTabs';

export interface DSP_FormTabsProps {
  label: string;
  tabSteps: TabStep[] | ((context: FormMachineContext) => TabStep[]);
  disabled?: boolean;
  variant?: 'fullWidth' | 'scrollable' | 'standard';
  parentTabLabel?: string;
}

export const DSP_FormTabs = ({
  label,
  tabSteps,
  disabled,
  variant,
  parentTabLabel = '',
}: DSP_FormTabsProps) => {
  const props = useFormTabs(label, tabSteps, parentTabLabel);
  return (
    <DSP_FormTabsView
      variant={variant}
      label={label}
      disabled={disabled}
      {...props}
    />
  );
};

DSP_FormTabs.displayName = 'DSP_FormTabs';

export {
  //
  /** @deprecated use DSP_FormTabsProps instead*/
  DSP_FormTabsProps as FormTabsProps, //
  /** @deprecated use DSP_FormTabs instead*/
  DSP_FormTabs as FormTabs,
};
