import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/preapprovals/create',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "CreatePermitStandardCondition" */ './CreatePreApproval'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Add pre approval',
  menuIcon: LibraryAddCheckIcon,
  authorisation: {
    apiKey: 'AccessProductDocumentWithRelations.create',
    policies: [
      {role: 'operationsAccessSupervisor'},
      {role: 'operationsSpatialSupervisor'},
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Pre approvals',
      to: '..',
    },
  ],
};
