import DataUsage from '@mui/icons-material/Dashboard';
import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/approval-library',
  component: lazy(
    () =>
      import(/* webpackChunkName: "Approval Library" */ './ApprovalLibrary'),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'Approval Library',
  menuIcon: DataUsage,
  authorisation: {
    apiKey: 'PbsApprovalWithRelations.findById',
    policies: [
      {role: 'operationsPBSRead'},
      {role: 'providerPBSRead'},
    ] as AuthorisationPolicy[],
  },
  sections: [],
};
