import React from 'react';

import {DSL_EditIcon} from '../../../Icons';
import {DSL_BaseIconButton, DSL_BaseIconButtonProps} from '../BaseIconButton';

export type DSL_EditIconButtonProps = Omit<
  DSL_BaseIconButtonProps,
  'color' | 'children'
>;

export const DSL_EditIconButton = (
  props: DSL_EditIconButtonProps,
): JSX.Element => {
  return (
    <DSL_BaseIconButton aria-label={'edit'} {...props}>
      <DSL_EditIcon />
    </DSL_BaseIconButton>
  );
};

DSL_EditIconButton.displayName = 'DSL_EditIconButton';

export {
  //
  /** @deprecated use DSL_EditIconButtonProps instead*/
  DSL_EditIconButtonProps as EditIconButtonProps, //
  /** @deprecated use DSL_EditIconButton instead*/
  DSL_EditIconButton as EditIconButton,
};
