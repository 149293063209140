import {Observable} from 'rxjs';
import {
  createMachine,
  MachineConfig,
  State,
  EventObject,
  InterpreterFrom,
} from 'xstate';
import machineDefinition from '../../utils/machines/notificationsMachine.json';
import {Notification} from 'typeDefs/types';
import {FetchResult} from '@apollo/client';
import {AuthorisationAccountTypes} from '@regulatory-platform/common-utils/dist';

export interface NotificationsMachineStateSchema {
  states: {
    loading: {};
    idle: {};
  };
}
export interface NotificationsMachineContext {
  notifications: NotificationItem[];
  filteredNotifications: NotificationItem[];
  filterBy: NotificationFilter;
  paginationLimit: number;
  paginationPage: number;
  countUnread: number;
}

export enum NotificationType {
  ACCESS_PRODUCT_CHANGE_SET = 'AccessProductChangeSet',
  ACCREDITATION_APPLICATION = 'AccreditationApplication',
  ACCREDITATION_ACTION = 'AccreditationAction',
  ROAD_MANAGEMENT_PROPOSAL = 'roadManagementProposal',
  ROAD_MANAGEMENT_ACCEPT = 'roadManagementAccept',
  ROAD_MANAGEMENT_REJECT = 'roadManagementReject',
  ROAD_MANAGEMENT_AUTO_TRANSFER = 'roadManagementAutoTransfer',
  ROAD_MANAGEMENT_CLAIM_CO_MANAGED = 'roadManagementClaimCoManaged',
  ROAD_MANAGEMENT_RELINQUISH = 'roadManagementRelinquish',
  ACCESS_PRODUCT_APPLICATION_ACTIVITY_TASK = 'AccessProductApplicationActivity_Task',
  ACCESS_PRODUCT_APPLICATION_ACTIVITY_INFORMATION = 'AccessProductApplicationActivity_Information',
  PNA_PUBLISH = 'AccessPublicNoticeOfAmendmentPublish',
}

type BaseNotificationData = Record<string, unknown>;

export interface NotificationItem<DataType = BaseNotificationData>
  extends Omit<RawNotificationItem, 'created' | 'modified' | 'viewedDate'> {
  data: DataType;
  accountType: AuthorisationAccountTypes;
  notificationType: NotificationType;
  created: Date | undefined | null;
  modified: Date | undefined | null;
  viewedDate: Date | undefined | null;
}

export enum NotificationFilter {
  UnreadOnly = 'Unread only',
  AllNotifications = 'All notifications',
}

export interface RawNotificationItem extends Notification {
  // a calculated boolean to indicate if the user has viewed the notification
  // note that the server response contains 'user views' - but that tracks views of users within an account, so this
  // additional bool is for this particular user (the logged-in user)
  readByLoggedInUser: boolean;
}

export type NotificationState = State<
  NotificationsMachineContext,
  NotificationsEventObject,
  NotificationsMachineStateSchema
>;

export type NotificationObservable = Observable<NotificationState>;

type NotificationMachineConfig = MachineConfig<
  NotificationsMachineContext,
  NotificationsMachineStateSchema,
  NotificationsEventObject
>;

export interface NotificationsEventObject extends EventObject {
  data?: FetchResult;
  filterBy?: NotificationFilter;
  markAsReadEvent?: MarkAsReadEvent;
  markAllAsReadEvent?: {}; // no data needed
}

export interface MarkAsReadEvent {
  notificationId: number;
}

const machine = createMachine<
  NotificationsMachineContext,
  NotificationsEventObject
>(machineDefinition as NotificationMachineConfig);
export default machine;

export type NotificationService = InterpreterFrom<typeof machine>;
