import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';

export default {
  path: '/regulators/:accountId/members/:id',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "ManageRegulatorAccountUser" */ './ManageRegulatorAccountUser'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Manage member',
  menuIcon: LocalShippingOutlinedIcon,
  authorisation: {
    apiKey: null,
    policies: [{role: 'operationsAccountRead'}] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Regulator accounts',
      to: '../../..',
    },
    {
      content: 'Regulator account',
      to: '../..',
      state: {'Manage account': 'Members'},
    },
  ],
};
