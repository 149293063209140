import {LicenseInfo} from '@mui/x-license-pro';
import React from 'react';
import {render} from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {getMuiDataGridKey} from 'utils/global';
import {App} from './app';
// import {registerServiceWorker} from './app/serviceWorker';

LicenseInfo.setLicenseKey(getMuiDataGridKey());

const root = (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

render(root, document.getElementById('root'));
// registerServiceWorker();
