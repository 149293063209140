import React, {ReactNode} from 'react';

import {
  DSL_OpenerProps,
  DSL_PopperProps,
  DSL_BorderlessCard,
  DSL_ButtonCardActionArea,
  DSL_FlexRow,
  DSL_Body1,
  DSL_ChevronRightIcon,
} from 'components/DesignSystem/Library';

export interface ContextualOptionProps {
  label: ReactNode;
  openerProps?: DSL_OpenerProps<HTMLButtonElement>;
  popperProps?: DSL_PopperProps;
  nestedContextualOptions?: ContextualOptionProps[];
  closePopper?: () => void;
  onClick?: () => void;
}

export const ContextualOption = ({
  label,
  openerProps,
  popperProps,
  nestedContextualOptions,
  closePopper,
  onClick,
}: ContextualOptionProps) => {
  const buttonCardActionAreaProps =
    nestedContextualOptions && openerProps
      ? openerProps
      : {
          onClick: () => {
            onClick?.();
            popperProps?.closePopper();
            closePopper?.();
          },
        };
  return (
    <DSL_BorderlessCard square>
      <DSL_ButtonCardActionArea
        {...buttonCardActionAreaProps}
        active={popperProps?.open}
      >
        <DSL_FlexRow px={2} py={1} justifyContent={'space-between'}>
          <DSL_Body1>{label}</DSL_Body1>
          {nestedContextualOptions && <DSL_ChevronRightIcon color={'action'} />}
        </DSL_FlexRow>
      </DSL_ButtonCardActionArea>
    </DSL_BorderlessCard>
  );
};
