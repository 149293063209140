import React from 'react';

import {useUserProfile} from 'app/services/useUserProfile';
import {UserModelInAccountPublic} from 'typeDefs/types';

import {
  DSP_EntityLoaderProvider,
  useEntityLoaderContext,
} from '../EntityLoaderProvider';
import {DSP_EntityLoaderProviderProps} from '../types';

import {GetUserModelInAccountPublicDocument} from './api/query/UserProfileInAccountQuery.generated';

export const DSP_UserEntityLoaderProvider = ({
  entityId: id,
  ...props
}: DSP_EntityLoaderProviderProps) => {
  const {accountType, accountId} = useUserProfile();

  return (
    <DSP_EntityLoaderProvider<UserModelInAccountPublic>
      query={GetUserModelInAccountPublicDocument}
      variables={{id, accountType, accountId}}
      {...props}
    />
  );
};

export const useUserEntityLoaderContext = () =>
  useEntityLoaderContext<UserModelInAccountPublic>();

DSP_UserEntityLoaderProvider.displayName = 'DSP_UserEntityLoaderProvider';
