import {jsonPointerResolver} from '@regulatory-platform/common-utils';
import * as R from 'ramda';
import {FormMachineContext} from 'utils/stores/types';

const isSubmitted = (context: FormMachineContext): boolean => {
  const statusFieldRef = R.defaultTo('#/status', context.props?.statusFieldRef);
  return R.includes(
    R.defaultTo('Draft', jsonPointerResolver(statusFieldRef)(context.record)),
    ['Submitted', 'Migrated'],
  );
};
export default isSubmitted;
