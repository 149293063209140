import React from 'react';

import {
  Stepper as MuiStepper,
  StepperProps as MuiStepperProps,
} from '@mui/material';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

export type DSL_StepperProps = Omit<MuiStepperProps, DSL_RestrictedVisualProps>;

export const DSL_Stepper = (props: DSL_StepperProps) => {
  return (
    <MuiStepper
      sx={{py: 3, width: '100%'}}
      alternativeLabel={props.orientation !== 'vertical'}
      nonLinear
      {...props}
    />
  );
};

DSL_Stepper.displayName = 'DSL_Stepper';

export {
  //
  /** @deprecated use DSL_StepperProps instead*/
  DSL_StepperProps as StepperProps, //
  /** @deprecated use DSL_Stepper instead*/
  DSL_Stepper as Stepper,
};
