import {Theme, darken, useTheme} from '@mui/material';
import {SegmentAccessType} from 'components/DesignSystem/Portal/Layout/Segments';
import {
  segmentAccessTitleMap,
  SegmentAccessStatus,
} from 'components/DesignSystem/Portal/Layout/Segments/constants';

import {usePopper} from 'components/DesignSystem/Library';

import {useDspSelectedSegmentsDrawerContext} from '../SelectedSegmentsDrawer.Provider';

export interface UseSelectedSegmentChangeOptionsArgs {
  segmentAccessType: SegmentAccessType;
  currentAccessType?: SegmentAccessType;
  segmentId: number;
  lastAppliedAccessType?: SegmentAccessType;
}

export const useSelectedSegmentChangeOptions = ({
  segmentAccessType,
  currentAccessType,
}: UseSelectedSegmentChangeOptionsArgs) => {
  const theme: Theme = useTheme();
  const {onChange, options} = useDspSelectedSegmentsDrawerContext();
  const {openerProps, popperProps} = usePopper<HTMLButtonElement>();

  const noChangesAllowed = options.some(option => option.noChangesAllowed);

  if (
    !noChangesAllowed &&
    !options.some(option => option.value === segmentAccessType)
  ) {
    options.push({
      value: segmentAccessType,
      label:
        segmentAccessTitleMap[SegmentAccessStatus.DRAFT][segmentAccessType],
      radioColor:
        segmentAccessType === SegmentAccessType.NO_ACCESS
          ? darken(theme.mapBox[SegmentAccessType.NO_ACCESS] as string, 0.4)
          : theme.mapBox[segmentAccessType],
    });
  }

  const filteredOptions = noChangesAllowed
    ? options.filter(option => !option.hasConflict)
    : options.filter(
        option =>
          !option.radioColor &&
          !option.isClearUpdates &&
          !option.isRevertUpdate &&
          !option.hasConflict,
      );

  const conflicts = options.filter(option => option.hasConflict);

  const radioOptions = options.filter(
    option => !!option.radioColor && option.value !== currentAccessType,
  );

  const onRadioClick: React.MouseEventHandler<HTMLLabelElement> = event => {
    popperProps.closePopper();
    radioOptions
      ?.find(
        option => option.label === (event.target as HTMLElement).textContent,
      )
      ?.onClick?.();
  };

  const onRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    popperProps.closePopper();
    radioOptions
      .find(option => option.value === event.target.value)
      ?.onClick?.();
  };

  return {
    openerProps,
    popperProps,
    options: filteredOptions.sort((a, b) => a.label.localeCompare(b.label)), // Sort by label alphabetically,
    radioOptions: radioOptions.sort((a, b) => a.label.localeCompare(b.label)), // Sort by label alphabetically
    onChangeButtonClick: onChange,
    onRevertChangesClick: options.find(option => {
      return option.isRevertUpdate;
    })?.onClick,
    onClearChangesClick: options.find(option => {
      return option.isClearUpdates;
    })?.onClick,
    onRadioClick,
    onRadioChange,
    title:
      conflicts.length > 0
        ? `${conflicts.length} conflicts`
        : noChangesAllowed
        ? 'No changes allowed'
        : 'Change options',
    conflicts,
  };
};
