import React, {ForwardedRef, forwardRef} from 'react';
import {
  DSL_AppBar,
  DSL_AppBarProps,
  DSL_Body2,
  DSL_CloseIconButton,
  DSL_Flex,
  DSL_FlexRow,
  DSL_MenuIconButton,
  DSL_Toolbar,
} from 'components/DesignSystem/Library';
import {
  AuthorisationAccountTypes,
  UserProfile,
} from '@regulatory-platform/common-utils/dist';
import {Sidebar} from 'components/navigation/Sidebar';
import {isUATOrProduction, mapAccountTypeToReadable} from 'utils/global';
import ThemeToggle from '../ThemeToggle';
import {
  DSP_AccountHeader,
  DSP_AccountHeaderMenu,
  DSP_HelpMenu,
  DSP_NotificationsPopout,
  DSP_ProfileMenu,
} from 'components/DesignSystem/Portal';
import {i18n} from 'containers-content';

export interface AppBarViewProps extends DSL_AppBarProps {
  anchorEl: HTMLElement | null;
  anchorRef: React.MutableRefObject<null>;
  accountType: AuthorisationAccountTypes;
  isProfileMenuOpen: boolean;
  isSidebarOpen: boolean;
  toggleSidebarOpen: () => void;
  handleProfileMenuOpen: (event: React.MouseEvent<HTMLElement>) => void;
  handleMenuClose: () => void;
  showNotifications?: boolean;
  userProfile?: UserProfile;
  logoutUrl?: string;
  navigateToHome: () => void;
}

export const AppBarView = forwardRef(
  (
    {
      anchorEl,
      anchorRef,
      accountType,
      isProfileMenuOpen,
      isSidebarOpen,
      toggleSidebarOpen,
      handleProfileMenuOpen,
      handleMenuClose,
      showNotifications,
      userProfile,
      logoutUrl,
      navigateToHome,
      ...props
    }: AppBarViewProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const brandIcon = i18n.get('GLOBAL_BRAND_ICON');

    return (
      <>
        {userProfile && (
          <DSL_AppBar ref={ref} {...props}>
            <DSL_Toolbar>
              <DSL_FlexRow
                justifyContent={'space-between'}
                width={'100%'}
                data-name={'app-bar-contents-wrapper'}
              >
                <Sidebar
                  isOpen={isSidebarOpen}
                  toggleIsOpen={toggleSidebarOpen}
                />
                <DSL_FlexRow
                  hiddenDownTo={'sm'}
                  alignItems={'center'}
                  data-name={'app-bar-contents-left'}
                >
                  {isSidebarOpen ? (
                    <DSL_CloseIconButton
                      aria-label="close drawer"
                      onClick={toggleSidebarOpen}
                    />
                  ) : (
                    <DSL_MenuIconButton
                      aria-label="open drawer"
                      onClick={toggleSidebarOpen}
                    />
                  )}
                  <DSL_Flex
                    alignItems={'center'}
                    cursor={'pointer'}
                    role={'button'}
                    aria-label={'Navigate to Home'}
                    onClick={navigateToHome}
                  >
                    {brandIcon}
                  </DSL_Flex>
                  {!isUATOrProduction() && <ThemeToggle />}
                  <DSL_FlexRow hiddenDownTo={'md'} alignItems={'center'}>
                    <DSP_AccountHeader userProfile={userProfile} />
                    <DSL_Body2 color={'text.secondary'}>
                      {mapAccountTypeToReadable(userProfile?.accountType ?? '')}
                    </DSL_Body2>
                  </DSL_FlexRow>
                </DSL_FlexRow>
                <DSL_FlexRow
                  alignItems={'center'}
                  data-name={'app-bar-contents-right'}
                >
                  <DSL_FlexRow alignItems={'center'} gap={2}>
                    <DSL_FlexRow hiddenUpTo={'md'} alignItems={'center'}>
                      <DSP_AccountHeaderMenu userProfile={userProfile} />
                    </DSL_FlexRow>
                    <DSP_HelpMenu />
                    {showNotifications && (
                      <DSP_NotificationsPopout userProfile={userProfile} />
                    )}

                    <DSP_ProfileMenu
                      userProfile={userProfile}
                      logoutUrl={logoutUrl}
                    />
                  </DSL_FlexRow>
                </DSL_FlexRow>
              </DSL_FlexRow>
            </DSL_Toolbar>
          </DSL_AppBar>
        )}
      </>
    );
  },
);

AppBarView.displayName = 'AppBarView';
