import {
  JSONSchemaRecord,
  schemaJsonPointerResolver,
} from '@regulatory-platform/common-utils';
import {jsonPointerResolver} from '@regulatory-platform/common-utils/dist';

import {SliderInputProps} from 'components/DesignSystem/Library';

import {useFormContext} from '../FormProvider';
import {useFormFieldPropsSelector} from '../hooks';
import {getGenericFieldProps} from '../utils/getGenericFieldProps';

import {DSP_RangeSliderFieldProps} from './RangeSliderField';

const makeLabelSuffix = ({
  minValue,
  maxValue,
  minSchema,
  maxSchema,
  uom,
}: {
  minValue?: number | null;
  maxValue?: number | null;
  minSchema?: number | null;
  maxSchema?: number | null;
  uom?: string;
}) => {
  const minValueSupplied = minValue && minValue !== minSchema;
  const maxValueSupplied = maxValue && maxValue !== maxSchema;
  if (minValueSupplied || maxValueSupplied) {
    return `: ${minValue || minSchema} - ${maxValue || maxSchema}${uom}`;
  }

  return '';
};

export const useRangeSliderField = ({
  minFieldRef,
  maxFieldRef,
  label,
  hideLabel,
  onBlurEvent,
  onChangeEvent,
  includeOptionalSuffix,
  ...propsOverrides
}: DSP_RangeSliderFieldProps): SliderInputProps => {
  const {service} = useFormContext();
  const send = service.send;
  const {valueSuffix, ...props} = useFormFieldPropsSelector(
    minFieldRef,
    service,
    state => {
      const {schema} = state.context;
      const fieldConfig = schemaJsonPointerResolver(minFieldRef)(
        schema,
      ) as JSONSchemaRecord;
      const max = fieldConfig.maximum ?? 1;
      const min = fieldConfig.minimum ?? 0;
      const defaultValue = (fieldConfig.default as number[]) ?? [min, max];
      const fieldFormatting = fieldConfig['x-formatting'];
      const {error, helperText, ...genericFieldProps} = getGenericFieldProps({
        fieldRef: maxFieldRef,
        state,
        label,
        hideLabel,
        includeOptionalSuffix,
      });

      const maxValue = genericFieldProps.value as number | undefined;
      const minValue = jsonPointerResolver(minFieldRef)(state.context.record);
      const markStep = fieldFormatting?.decimalScale
        ? fieldFormatting.decimalScale
        : 1;
      const suffix = fieldFormatting?.suffix ?? fieldFormatting?.uom ?? '';
      const step = fieldFormatting?.step ?? 1;
      //required for the slider to show the end mark when the max value is not a multiple of the markStep
      const requiresEndMark = (max - min) % markStep !== 0;
      const marks = new Array(
        Math.floor((max - min) / markStep) + (requiresEndMark ? 2 : 1),
      )
        .fill(undefined)
        .map((_, i, array) => {
          const markValue =
            requiresEndMark && i + 1 === array.length
              ? max
              : min + i * markStep;
          return {
            value: markValue,
            label:
              markValue === min || markValue === max
                ? `${markValue}${suffix ?? ''}`
                : undefined,
          };
        });

      const value = [
        minValue ?? defaultValue[0],
        maxValue ?? defaultValue[1],
      ] as [number, number];

      const labelSuffix = makeLabelSuffix({
        minValue: minValue as number | null,
        maxValue: maxValue as number | null,
        minSchema: min,
        maxSchema: max,
        uom: fieldFormatting?.uom,
      });
      const fullLabel = (genericFieldProps.label ?? '') + (labelSuffix ?? '');
      const onChange: SliderInputProps['onChange'] = (_, rangeValue): void => {
        const [newMin, newMax] = rangeValue as [number, number];
        // if the range is set to min and max, clear the range selection entirely, to account for nulls.
        send({
          type: 'CHANGE',
          fieldRef: minFieldRef,
          value: newMin === min ? null : newMin,
        });
        send({
          type: 'CHANGE',
          fieldRef: maxFieldRef,
          value: newMax === max ? null : newMax,
        });
        onChangeEvent?.();
      };

      return {
        ...genericFieldProps,
        label: fullLabel,
        onChange,
        //empty array is not a valid value for the slider
        value,
        valueSuffix: suffix,
        defaultValue,
        marks,
        min,
        max,
        step,
        disabled: min === max,
      };
    },
  );
  function valueText(valueNumber: number) {
    return `${valueNumber}${valueSuffix}`;
  }
  return {
    ...props,
    label: props.label as string,
    getAriaValueText: valueText,
    valueLabelFormat: valueText,
    getAriaLabel: () => label ?? '',
    onBlur: () => {
      send({type: 'BLUR', fieldRef: minFieldRef});
      send({type: 'BLUR', fieldRef: maxFieldRef});
      onBlurEvent?.();
    },
    valueLabelDisplay: 'auto',
    ...propsOverrides,
  };
};
