import React from 'react';

import {useBreakpoint} from 'components/DesignSystem/Library/hooks/useBreakpoint';

import {DSL_ArrowBackIcon} from '../../../Icons';
import {DSL_BaseIconButton, DSL_BaseIconButtonProps} from '../BaseIconButton';

export type DSL_BackIconButtonProps = Omit<
  DSL_BaseIconButtonProps,
  'color' | 'children'
>;

export const DSL_BackIconButton = ({
  size,
  ...props
}: DSL_BackIconButtonProps): JSX.Element => {
  const {isSm} = useBreakpoint();
  const finalSize = size ?? (isSm ? 'medium' : 'large');

  return (
    <DSL_BaseIconButton size={finalSize} aria-label="back" {...props}>
      <DSL_ArrowBackIcon fontSize={finalSize} />
    </DSL_BaseIconButton>
  );
};

DSL_BackIconButton.displayName = 'DSL_BackIconButton';

export {
  //
  /** @deprecated use DSL_BackIconButtonProps instead*/
  DSL_BackIconButtonProps as BackIconButtonProps, //
  /** @deprecated use DSL_BackIconButton instead*/
  DSL_BackIconButton as BackIconButton,
};
