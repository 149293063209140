import DataUsage from '@mui/icons-material/Dashboard';
import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/providerContacts',
  component: lazy(
    () =>
      import(/* webpackChunkName: "ProviderContacts" */ './ProviderContacts'),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'Contacts',
  menuIcon: DataUsage,
  authorisation: {
    apiKey: 'ProviderContactWithRelations.findById',
    policies: [{role: 'providerAccountAdmin'}] as AuthorisationPolicy[],
  },
  category: 'Admin',
  sections: [],
};
