import React, {ForwardedRef, forwardRef} from 'react';

import {DSL_BaseButton, DSL_BaseButtonProps} from '../BaseButton';

export type DSL_ContainedButtonProps = Omit<DSL_BaseButtonProps, 'variant'>;

export const DSL_ContainedButton = forwardRef(
  (props: DSL_ContainedButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    return <DSL_BaseButton ref={ref} variant={'contained'} {...props} />;
  },
);

DSL_ContainedButton.displayName = 'DSL_ContainedButton';

export {
  //
  /** @deprecated use DSL_ContainedButtonProps instead*/
  DSL_ContainedButtonProps as ContainedButtonProps, //
  /** @deprecated use DSL_ContainedButton instead*/
  DSL_ContainedButton as ContainedButton,
};
