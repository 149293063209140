import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    canvas: {
      backgroundColor: 'transparent',
      backfaceVisibility: 'hidden',
      whiteSpace: 'nowrap',
      overflowX: 'auto',
      transform: 'translateX(0)',
      marginBottom: theme.spacing(1),
    },
    singleImage: {
      marginLeft: '0.2em',
      marginBottom: '8px',
      height: '25px',
      width: 'auto',
    },
    cardImage: {
      // marginLeft: '0.2em',
      // marginBottom: '8px',
      height: '64px',
      width: 'auto',
    },
    cardConfigurationImage: {
      height: '48px',
      width: 'auto',
    },
    iconImage: {
      marginBottom: '8px',
      height: '25px',
      width: 'auto',
      maxWidth: '40px',
    },
    defaultImage: {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
  }),
);
