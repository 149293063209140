import React, {ReactNode} from 'react';

import {
  DSL_CardContent,
  DSL_Divider,
  DSL_FlexColumn,
  DSL_Heading4,
  DSL_OutlinedCard,
} from 'components/DesignSystem/Library';

import {DSP_SummaryItemConfig, DSP_SummaryRenderer} from '../../Forms/Summary';

export interface DSP_SummaryCardProps {
  fields: DSP_SummaryItemConfig[];
  highlight?: boolean;
  heading?: ReactNode;
}

export const DSP_SummaryCard = ({
  fields,
  highlight,
  heading,
}: DSP_SummaryCardProps) => {
  return (
    <DSL_OutlinedCard>
      <DSL_CardContent spacing={'NONE'} padding={'MD'}>
        <DSL_FlexColumn gap={4}>
          <DSL_Heading4 gutterBottom={false}>{heading}</DSL_Heading4>
          <DSL_Divider />
          {highlight ? (
            <DSL_OutlinedCard bgcolor={'background.default'}>
              <DSL_CardContent spacing={'NONE'} padding={'MD'}>
                <DSP_SummaryRenderer
                  summary={[
                    {
                      fields: fields,
                    },
                  ]}
                  layout="horizontal"
                  labelWidth={100}
                />
              </DSL_CardContent>
            </DSL_OutlinedCard>
          ) : (
            <DSP_SummaryRenderer
              summary={[
                {
                  fields: fields,
                },
              ]}
              layout="horizontal"
              labelWidth={100}
            />
          )}
        </DSL_FlexColumn>
      </DSL_CardContent>
    </DSL_OutlinedCard>
  );
};

DSP_SummaryCard.displayName = 'DSP_SummaryCard';
