import React from 'react';

import {
  DSL_EntityPopover,
  DSL_EntityPopoverProps,
} from 'components/DesignSystem/Library';

import {
  DSP_CustomerAccountEntityDetails,
  DSP_CustomerAccountEntityDetailsProps,
} from './CustomerAccountEntityDetails';

export interface DSP_CustomerAccountEntityPopoverProps
  extends DSL_EntityPopoverProps,
    DSP_CustomerAccountEntityDetailsProps {
  title: string;
  subTitle?: string;
}

export const DSP_CustomerAccountEntityPopover = ({
  isLoading,
  website,
  title,
  subTitle,
  ...popoverProps
}: DSP_CustomerAccountEntityPopoverProps) => {
  return (
    <DSL_EntityPopover
      isLoading={isLoading}
      title={title}
      subTitle={subTitle}
      {...popoverProps}
    >
      {website && (
        <DSP_CustomerAccountEntityDetails
          website={website}
          isLoading={isLoading}
        />
      )}
    </DSL_EntityPopover>
  );
};

DSP_CustomerAccountEntityPopover.displayName =
  'DSP_CustomerAccountEntityPopover';
