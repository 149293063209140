import React from 'react';

import {DSP_PartnerAccountEntityNameView} from './PartnerAccountEntityName.View';
import {usePartnerAccountEntityName} from './usePartnerAccountEntityName';

export const DSP_PartnerAccountEntityName = () => {
  const viewProps = usePartnerAccountEntityName();

  return <DSP_PartnerAccountEntityNameView {...viewProps} />;
};

DSP_PartnerAccountEntityName.displayName = 'DSP_PartnerAccountEntityName';
