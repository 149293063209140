import React from 'react';

import {DSL_OpenInNew} from 'components/DesignSystem/Library/Icons';
import {DSL_Body1} from 'components/DesignSystem/Library/Typography/Body1';

import {DSL_Box} from '../../Box';
import {DSL_FlexColumn} from '../../FlexColumn';
import {DSL_FlexRow} from '../../FlexRow';
import {DSL_Popper} from '../Popper';
import {
  DSL_PopperList,
  DSL_PopperListItem,
  DSL_PopperListItemButton,
} from '../PopperList';
import {usePopper} from '../usePopper';

import {ActionsMenuToolTip} from './ActionsMenuTooltip';

export interface ActionsMenuProps {
  menuItems: {label: string; onClick?: () => void; openInNewTab?: boolean}[];
}

export const ActionsMenu = ({menuItems}: ActionsMenuProps) => {
  const {popperProps, openerProps} = usePopper();

  const onListItemClick = () => {
    popperProps.closePopper();
  };

  return (
    <>
      <DSL_Box {...openerProps}>
        <ActionsMenuToolTip open={popperProps.open} />
      </DSL_Box>

      <DSL_Popper id="actions-menu-list" {...popperProps}>
        <DSL_PopperList disablePadding aria-labelledby="actions-menu-subheader">
          {menuItems.map((item, index) => (
            <DSL_PopperListItem key={index} disablePaddingTop>
              <DSL_PopperListItemButton
                aria-label={item.label}
                onClick={(): void => {
                  onListItemClick();
                  item?.onClick?.();
                }}
              >
                <DSL_FlexRow
                  width={'100%'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <DSL_FlexRow alignItems={'center'} gap={2}>
                    <DSL_FlexColumn
                      gap={0}
                      flex={1}
                      flexWrap={'wrap'}
                      maxWidth={'250px'}
                    >
                      <DSL_Body1>{item.label}</DSL_Body1>
                    </DSL_FlexColumn>
                  </DSL_FlexRow>
                  {item?.openInNewTab && (
                    <DSL_OpenInNew color="action" fontSize="small" />
                  )}
                </DSL_FlexRow>
              </DSL_PopperListItemButton>
            </DSL_PopperListItem>
          ))}
        </DSL_PopperList>
      </DSL_Popper>
    </>
  );
};

ActionsMenu.displayName = 'ActionsMenu';
