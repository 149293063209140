import {DSP_CustomerAccountEveryonePublic} from './types';

export interface DSP_UseCustomerAccountEntityMapperProps {
  customerAccount: DSP_CustomerAccountEveryonePublic | null | undefined;
}

export function useCustomerAccountEntityMapper({
  customerAccount,
}: DSP_UseCustomerAccountEntityMapperProps) {
  const {name, website, fileList} = customerAccount ?? {};

  const initials = name
    ?.split(' ')
    .map(section => section[0])
    .slice(0, 2)
    .join('');

  return {
    initials,
    title: name ?? '',
    website: website ?? undefined,
    src: fileList?.accountImageUrl,
  };
}
