import {
  MessageListener,
  SubscribeRequest,
  SubscriptionWithMessageListeners,
} from 'utils/machines/websocketMachine';
import * as R from 'ramda';
import {UserProfile} from '@regulatory-platform/common-utils';
import {groupNameFromPubSubGroup} from '@regulatory-platform/common-utils-notification';

/**
 * addSubscription adds the subscription to the provided (assumed to be current) subscriptions, if it doesn't already exist
 *
 * This will return a list of unique subscriptions. For any subscriptions that already exist, the message listener will
 * be appended to the existing subscription.
 *
 * @param currentSubscriptions
 * @param toAdd
 * @param userProfile
 */
export function addSubscription(
  currentSubscriptions:
    | Map<string, SubscriptionWithMessageListeners>
    | undefined,
  toAdd: SubscribeRequest,
  userProfile: UserProfile,
): Map<string, SubscriptionWithMessageListeners> {
  if (!currentSubscriptions) {
    currentSubscriptions = new Map<string, SubscriptionWithMessageListeners>();
  }

  // clone, so that we do not mutate any state
  const newSubscriptions = R.clone(currentSubscriptions);

  const key = groupNameFromPubSubGroup(
    toAdd.group,
    userProfile.id,
    userProfile.accountType,
    userProfile.accountId,
  );
  const subscriptionWithMessageListeners = newSubscriptions.get(key) ?? {
    group: toAdd.group,
    messageListeners: new Map<string, MessageListener>(),
  };

  subscriptionWithMessageListeners.messageListeners.set(
    toAdd.subscriptionKey,
    toAdd.messageListener,
  );

  newSubscriptions.set(key, subscriptionWithMessageListeners);

  return newSubscriptions;
}
