import React from 'react';

import {DSL_RemoveCircleIcon} from '../../../Icons';
import {DSL_BaseIconButton, DSL_BaseIconButtonProps} from '../BaseIconButton';

export type DSL_RemoveCircleIconButtonProps = Omit<
  DSL_BaseIconButtonProps,
  'color' | 'children'
>;

export const DSL_RemoveCircleIconButton = ({
  size = 'medium',
  ...props
}: DSL_RemoveCircleIconButtonProps): JSX.Element => {
  return (
    <DSL_BaseIconButton aria-label="back" size={size} {...props}>
      <DSL_RemoveCircleIcon fontSize={size} />
    </DSL_BaseIconButton>
  );
};

DSL_RemoveCircleIconButton.displayName = 'DSL_RemoveCircleIconButton';

export {
  //
  /** @deprecated use DSL_RemoveCircleIconButtonProps instead*/
  DSL_RemoveCircleIconButtonProps as RemoveCircleIconButtonProps, //
  /** @deprecated use DSL_RemoveCircleIconButton instead*/
  DSL_RemoveCircleIconButton as RemoveCircleIconButton,
};
