import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import {
  AuthorisationPolicy,
  BuiltInRoles,
} from '@regulatory-platform/common-utils';
import {lazy} from 'react';
export default {
  path: '/',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "VehicleNetworkMap" */ './VehicleNetworkMap.Connector'
      ),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'National Network Map',
  menuDescription:
    'Single national map for Heavy Vehicle network access across Australia.',
  menuIcon: MapOutlinedIcon,
  parent: true,
  authorisation: {
    apiKey: null,
    policies: [BuiltInRoles.EVERYONE] as AuthorisationPolicy[],
  },
  category: 'Map',
  sections: [],
};
