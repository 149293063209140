import React from 'react';

import {DSL_CalendarTodayIcon} from '../../Icons';
import {DSL_BaseIconInfo, DSL_BaseIconInfoProps} from '../BaseIconInfo';

export type DSL_CalendarTodayIconInfoProps = Omit<
  DSL_BaseIconInfoProps,
  'icon'
>;

export const DSL_CalendarTodayIconInfo = (
  props: DSL_CalendarTodayIconInfoProps,
) => {
  return <DSL_BaseIconInfo icon={<DSL_CalendarTodayIcon />} {...props} />;
};

DSL_CalendarTodayIconInfo.displayName = 'DSL_CalendarTodayIconInfo';

export {
  //
  /** @deprecated use DSL_CalendarTodayIconInfoProps instead*/
  DSL_CalendarTodayIconInfoProps as CalendarTodayIconInfoProps, //
  /** @deprecated use DSL_CalendarTodayIconInfo instead*/
  DSL_CalendarTodayIconInfo as CalendarTodayIconInfo,
};
