import {useSelector} from '@xstate/react';
import {useFormContext} from 'components/DesignSystem/Portal/Forms/FormProvider';
import * as R from 'ramda';

export const useFormSaveDialogSavingToArchive = (onClose: () => void) => {
  const {service} = useFormContext();
  return useSelector(
    service,
    state => {
      const reasonForArchiveValue = state.context.record?.reasonForArchive;

      return {
        isConfirmButtonDisabled:
          R.isNil(reasonForArchiveValue) || R.isEmpty(reasonForArchiveValue),
        onConfirm: () => {
          service.send('SAVE');
          onClose();
        },
      };
    },
    (prevFieldProps, nextFieldProps) => {
      const ignoreProps = R.omit(['onConfirm']);
      return R.equals(ignoreProps(prevFieldProps), ignoreProps(nextFieldProps));
    },
  );
};
