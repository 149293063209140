import React from 'react';

import {
  ButtonBar,
  ButtonBarGroup,
  OutlinedButton,
} from 'components/DesignSystem/Library';

import {DSP_SubmitButton} from '../../../SubmitButton';

export interface DSP_CreateOrCancelButtonStackViewProps {
  onCreateClick: React.MouseEventHandler<HTMLButtonElement>;
  onCancelClick: () => void;
}

export const DSP_CreateOrCancelButtonStackView = ({
  onCreateClick,
  onCancelClick,
}: DSP_CreateOrCancelButtonStackViewProps) => {
  return (
    <ButtonBar>
      <ButtonBarGroup>
        <OutlinedButton onClick={onCancelClick}>Cancel</OutlinedButton>
        <DSP_SubmitButton onClick={onCreateClick}>Create</DSP_SubmitButton>
      </ButtonBarGroup>
    </ButtonBar>
  );
};

DSP_CreateOrCancelButtonStackView.displayName =
  'DSP_CreateOrCancelButtonStackView';

export {
  //
  /** @deprecated use DSP_CreateOrCancelButtonStackViewProps instead*/
  DSP_CreateOrCancelButtonStackViewProps as CreateOrCancelButtonStackViewProps, //
  /** @deprecated use DSP_CreateOrCancelButtonStackView instead*/
  DSP_CreateOrCancelButtonStackView as CreateOrCancelButtonStackView,
};
