import {useCallback, useMemo} from 'react';

import {SegmentAccessType} from 'components/DesignSystem/Portal/Layout/Segments';
import {segmentAccessTitleMap} from 'components/DesignSystem/Portal/Layout/Segments/constants';

import {useDspSelectedSegmentsDrawerContext} from '../SelectedSegmentsDrawer.Provider';

export const useDspSelectedSegmentsDrawerHeader = () => {
  const {
    hasSelectedRoadId,
    hasFilterByAccessType,
    roads,
    selectedRoadId,
    filterByAccessTypeValue,
    setSelectedRoadId,
    setFilterByAccessTypeValue,
    showSingleListView,
    resetToDefaults,
    onBack,
  } = useDspSelectedSegmentsDrawerContext();

  const title = useMemo(() => {
    if (showSingleListView) {
      return 'Selection';
    }
    if (hasSelectedRoadId) {
      return (
        roads?.find(road => road.id === selectedRoadId)?.name ??
        'Road not found'
      );
    }
    if (hasFilterByAccessType) {
      return segmentAccessTitleMap.CURRENT[
        filterByAccessTypeValue as SegmentAccessType
      ];
    }
    return 'Selection';
  }, [
    showSingleListView,
    hasSelectedRoadId,
    hasFilterByAccessType,
    roads,
    selectedRoadId,
    filterByAccessTypeValue,
  ]);

  const onBackClick = useCallback(() => {
    onBack();
    if (hasSelectedRoadId && filterByAccessTypeValue === 'All') {
      resetToDefaults();
      return;
    }
    if (hasSelectedRoadId) {
      setSelectedRoadId(undefined);
      return;
    }
    if (hasFilterByAccessType) {
      setFilterByAccessTypeValue(undefined);
    }
  }, [
    hasSelectedRoadId,
    hasFilterByAccessType,
    filterByAccessTypeValue,
    setSelectedRoadId,
    setFilterByAccessTypeValue,
    onBack,
    resetToDefaults,
  ]);

  return {
    title,
    onBackClick: showSingleListView
      ? undefined
      : hasSelectedRoadId || hasFilterByAccessType
      ? onBackClick
      : undefined,
  };
};
