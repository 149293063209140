import React, {CSSProperties} from 'react';

import {
  TableContainer as MuiTableContainer,
  TableContainerProps as MuiTableContainerProps,
} from '@mui/material';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

import {DSL_Box} from '../../Layout/Box';

export interface DSL_TableContainerProps
  extends Omit<MuiTableContainerProps, DSL_RestrictedVisualProps> {
  maxHeight?: CSSProperties['maxHeight'];
}

export const DSL_TableContainer = ({
  maxHeight,
  ...props
}: DSL_TableContainerProps) => {
  return (
    /* @TODO: maxwidth 100%, width 100% should be working for overflowX scroll bar on large tables.
    It should not require to se the display to "grid"
    JIRA: https://nhvr.atlassian.net/browse/NHVRREP-31102
    */
    <DSL_Box overflowX="auto" display="grid">
      <MuiTableContainer sx={{maxHeight}} {...props} />
    </DSL_Box>
  );
};

DSL_TableContainer.displayName = 'DSL_TableContainer';

export {
  //
  /** @deprecated use DSL_TableContainerProps instead*/
  DSL_TableContainerProps as TableContainerProps, //
  /** @deprecated use DSL_TableContainer instead*/
  DSL_TableContainer as TableContainer,
};
