import HomeIcon from '@mui/icons-material/Home';
import {
  BuiltInRoles,
  AuthorisationPolicy,
} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/logout',
  component: lazy(() => import(/* webpackChunkName: "Logout" */ './Logout')),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Logout',
  menuIcon: HomeIcon,
  authorisation: {
    apiKey: null,
    policies: [BuiltInRoles.EVERYONE] as AuthorisationPolicy[],
  },
  sections: [],
};
