import React from 'react';

import {DSP_FormTableView} from './FormTable.View';
import {useFormTable, UseFormTableProps} from './useFormTable';

export type DSP_FormTableProps<ItemType extends Record<string, unknown>> =
  UseFormTableProps<ItemType>;

export function DSP_FormTable<ItemType extends Record<string, unknown>>(
  props: DSP_FormTableProps<ItemType>,
) {
  const viewProps = useFormTable(props);

  return <DSP_FormTableView {...viewProps} />;
}

DSP_FormTable.displayName = 'DSP_FormTable';
