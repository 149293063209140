import CropSquareOutlined from '@mui/icons-material/CropSquareOutlined';
import {
  AuthorisationPolicy,
  BuiltInRoles,
} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/roadmap',
  component: lazy(() => import(/* webpackChunkName: "Roadmap" */ './Roadmap')),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'Portal Roadmap',
  menuDescription:
    'NHVR Portal roadmap providing information on recent releases, work in progress and under consideration.',
  menuIcon: CropSquareOutlined,
  authorisation: {
    apiKey: null,
    policies: [BuiltInRoles.EVERYONE] as AuthorisationPolicy[],
  },
  sections: [],
};
