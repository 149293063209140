import {
  JSONSchemaRecord,
  schemaJsonPointerResolver,
} from '@regulatory-platform/common-utils';
import * as R from 'ramda';

export function getFieldSchema(fieldRef: string, schema: JSONSchemaRecord) {
  const fieldSchema = schemaJsonPointerResolver(fieldRef)(schema);

  if (R.isNil(fieldSchema)) {
    throw new Error(`Schema not found for field ${fieldRef}`);
  }

  return fieldSchema;
}
