import React from 'react';

import {alpha} from '@mui/material';
import {useTheme} from '@mui/material';

import {DSL_FileUploadIcon} from '../../Icons';
import {DSL_Avatar, DSL_AvatarProps} from '../Avatar';

export interface DSL_FileAvatarProps extends Omit<DSL_AvatarProps, 'bgcolor'> {
  error?: boolean;
}

export const DSL_FileAvatar = ({error, ...props}: DSL_FileAvatarProps) => {
  const {palette} = useTheme();

  const bgColor = error
    ? alpha(palette.error.main, 0.08)
    : alpha(palette.primary.main, 0.08);

  return (
    <DSL_Avatar bgcolor={bgColor} {...props}>
      <DSL_FileUploadIcon color={error ? 'error' : 'primary'} />
    </DSL_Avatar>
  );
};

DSL_FileAvatar.displayName = 'DSL_FileAvatar';

export {
  //
  /** @deprecated use DSL_FileAvatarProps instead*/
  DSL_FileAvatarProps as FileAvatarProps, //
  /** @deprecated use DSL_FileAvatar instead*/
  DSL_FileAvatar as FileAvatar,
};
