import React from 'react';

import {
  DSL_CheckboxCardInputProps,
  DSL_CheckboxCardInput,
} from 'components/DesignSystem/Library';

import {
  useCheckboxCardField,
  UseCheckboxCardFieldProps,
} from './useCheckboxCardField';

export interface CheckboxCardFieldProps
  extends UseCheckboxCardFieldProps,
    Pick<
      DSL_CheckboxCardInputProps,
      | 'icon'
      | 'description'
      | 'describedBy'
      | 'subLabel'
      | 'minHeight'
      | 'maxHeight'
      | 'height'
    > {}

export const DSP_CheckboxCardField = ({
  icon,
  describedBy,
  minHeight,
  maxHeight,
  height,
  ...props
}: CheckboxCardFieldProps) => {
  const fieldProps = useCheckboxCardField(props);

  return (
    <DSL_CheckboxCardInput
      aria-describedby={describedBy}
      icon={icon}
      height={height}
      minHeight={minHeight}
      maxHeight={maxHeight}
      {...fieldProps}
    />
  );
};

DSP_CheckboxCardField.displayName = 'DSP_CheckboxCardField';
