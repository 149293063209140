import React from 'react';

import {DSL_ChipProps, DSL_Chip} from 'components/DesignSystem/Library';

import {mapStatusToThemeColour} from './mapStatusToThemeColour';

export type DSP_StatusChipViewProps = Omit<DSL_ChipProps, 'color'> & {
  effectiveStatus: string | undefined | null;
};

export const DSP_StatusChipView = ({
  effectiveStatus,
  label,
  ...props
}: DSP_StatusChipViewProps) => {
  const color = mapStatusToThemeColour(effectiveStatus);

  return <DSL_Chip color={color} label={label ?? effectiveStatus} {...props} />;
};
