import {DSP_AccreditationActionNotificationItem} from '../types';

import {DSP_AccreditationActionOperationsNotificationCardViewProps} from './AccreditationActionOperationsNotificationCard.View';

export interface DSP_UseAccreditationActionNotificationCardProps {
  notification: DSP_AccreditationActionNotificationItem;
}

export function useAccreditationOperationsNotificationCard({
  notification,
}: DSP_UseAccreditationActionNotificationCardProps): DSP_AccreditationActionOperationsNotificationCardViewProps {
  return {
    notification,
    customerAccountId: notification.data.customerAccount?.id,
  };
}
