import React from 'react';

import {List, ListProps} from '@mui/material';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

export type DSL_SidebarMenuListProps = Omit<
  ListProps,
  DSL_RestrictedVisualProps
>;

export const DSL_SidebarMenuList = (props: DSL_SidebarMenuListProps) => {
  return (
    <List
      sx={{
        padding: 1,
        marginTop: 1,
      }}
      component="nav"
      aria-labelledby="nested-list"
      {...props}
    />
  );
};

DSL_SidebarMenuList.displayName = 'DSL_SidebarMenuList';

export {
  //
  /** @deprecated use DSL_SidebarMenuListProps instead*/
  DSL_SidebarMenuListProps as SidebarMenuListProps, //
  /** @deprecated use DSL_SidebarMenuList instead*/
  DSL_SidebarMenuList as SidebarMenuList,
};
