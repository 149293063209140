import React from 'react';
import {GlobalStyles as MuiGlobalStyles, Theme} from '@mui/material';

// new file: GlobalStyles.tsx
const GlobalStylesInternal = ({theme}: {theme: Theme}) => {
  return (
    <MuiGlobalStyles
      styles={{
        body: {
          color: theme.palette.text.primary,
          fontSize: theme.typography.body1.fontSize,
          lineHeight: theme.typography.body1.lineHeight,
          fontWeight: theme.typography.body1.fontWeight,
          letterSpacing: theme.typography.body1.letterSpacing,
          fontFamily: theme.typography.body1.fontFamily,
          overscrollBehavior: 'none',
        },
        a: {
          color: theme.palette.primary.main,
          '&:hover, &:focus, &:active, &:visited': {
            color: theme.palette.primary.dark,
          },
          textDecoration: 'underline',
          cursor: 'pointer',
        },
      }}
    />
  );
};

export const GlobalStyles = React.memo(GlobalStylesInternal);
