import {useTheme} from '@mui/material';

import {DSL_BaseDrawerProps} from '../BaseDrawer';
import {DSL_DrawerSizeFixed} from '../types';
import {DSL_UseDrawerArgs, useDrawer} from '../useDrawer';

export type DSL_UseFixedDrawerArgs = Omit<DSL_UseDrawerArgs, 'variant'> & {
  size?: DSL_DrawerSizeFixed;
};

export function useFixedDrawer(args: DSL_UseDrawerArgs): DSL_BaseDrawerProps {
  const theme = useTheme();
  const {zIndexModifier, ...props} = useDrawer({
    ...args,
    variant: 'fixed',
  });

  return {
    ...props,
    muiVariant: 'persistent' as const,
    zIndex: theme.zIndex.drawer + zIndexModifier,
  };
}
