import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';
import {lazy} from 'react';

export default {
  path: '/providerContacts/:id',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "ProviderContactDetails" */ './ProviderContactDetails'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Contacts',
  menuIcon: LocalShippingOutlinedIcon,
  authorisation: {
    apiKey: 'ProviderContactWithRelations.findById',
    policies: [{role: 'providerAccountAdmin'}] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Contacts',
      to: '..',
    },
  ],
};
