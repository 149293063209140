import React from 'react';

import {useDialog} from 'components/DesignSystem/Library';

import {DSP_FormSaveDialog} from '../../../FormSaveDialog';

import {DSP_SaveOrCancelButtonStackView} from './SaveOrCancelButtonStack.View';
import {useSaveOrCancelButtonStack} from './useSaveOrCancelButtonStack';

export const DSP_SaveOrCancelButtonStack = () => {
  const [dialogOpen, openDialog, closeDialog] = useDialog();
  const viewProps = useSaveOrCancelButtonStack(openDialog);
  return (
    <>
      <DSP_SaveOrCancelButtonStackView {...viewProps} />
      <DSP_FormSaveDialog open={dialogOpen} onClose={closeDialog} />
    </>
  );
};

DSP_SaveOrCancelButtonStack.displayName = 'DSP_SaveOrCancelButtonStack';

export {
  //
  /** @deprecated use DSP_SaveOrCancelButtonStack instead*/
  DSP_SaveOrCancelButtonStack as SaveOrCancelButtonStack,
};
