import {useDspSelectedSegmentsDrawerContext} from '../SelectedSegmentsDrawer.Provider';

import {useDspCalculateSummary} from './calculateSummary';

export const useDspSelectedSegmentsSummary = () => {
  const {isViewByRoads, hasSelectedRoadId, hasFilterByAccessType} =
    useDspSelectedSegmentsDrawerContext();

  const {summary, segmentIds, isRootList} = useDspCalculateSummary();

  const showSegmentAccessTypeIcon =
    (!hasSelectedRoadId && (!isViewByRoads || hasFilterByAccessType)) ||
    hasSelectedRoadId;

  return {
    summary,
    segmentIds,
    isRootList,
    showSegmentAccessTypeIcon,
  };
};
