import React from 'react';

import {
  DSL_SelectFileDropTarget,
  DSL_SelectFileDropTargetProps,
} from '../../../Files';
import {DSL_FlexColumn} from '../../../Layout/FlexColumn';

import {
  DSL_UploadedFiles,
  DSL_UploadedFilesProps,
  DSL_UploadedFileType,
} from './UploadedFiles';
import {DSL_UploadingFile} from './UploadingFile';

export interface DSL_FileUploadInputProps<T>
  extends DSL_SelectFileDropTargetProps,
    Omit<DSL_UploadedFilesProps<T>, 'file'> {
  filesList?: T[];
}

export const DSL_FileUploadInput = <
  T extends DSL_UploadedFileType = DSL_UploadedFileType,
>({
  acceptedMimeTypes,
  errorMessage,
  maxFiles,
  maxSize,
  minSize,
  disabled,
  onDropAccepted,
  onDropRejected,
  onFileEdit,
  onFileRemove,
  blobUrl,
  filesList = [],
}: DSL_FileUploadInputProps<T>) => {
  return (
    <>
      <DSL_SelectFileDropTarget
        errorMessage={errorMessage}
        disabled={disabled}
        minSize={minSize}
        maxSize={maxSize}
        maxFiles={maxFiles}
        acceptedMimeTypes={acceptedMimeTypes}
        onDropRejected={onDropRejected}
        onDropAccepted={onDropAccepted}
      />
      <DSL_FlexColumn gap={1} pt={2}>
        {filesList
          .filter(file => file.progress !== undefined)
          .map(file => (
            <DSL_UploadingFile
              key={file.name}
              file={file}
              onFileRemove={onFileRemove}
            />
          ))}
        <DSL_UploadedFiles
          files={filesList
            .filter(file => file.progress === undefined)
            ?.reverse()}
          onFileEdit={onFileEdit}
          onFileRemove={onFileRemove}
          blobUrl={blobUrl}
        />
      </DSL_FlexColumn>
    </>
  );
};
