import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/operationManualDocumentTemplates/:id',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "OperationManualDocumentTemplateDetails" */ './OperationManualDocumentTemplateDetails'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Operation Manual Document Template',
  menuIcon: LocalShippingOutlinedIcon,
  authorisation: {
    apiKey: null,
    policies: [{role: 'operationsAccreditationAdmin'}] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Operation Manual Document Templates',
      to: '..',
    },
  ],
};
