import {
  CommonInvocationContext,
  CommonValidatorResult,
  JSONSchemaRecord,
  ObjectType,
} from '@regulatory-platform/common-utils';

export function validator(
  context: CommonInvocationContext,
  schema: JSONSchemaRecord,
  record: ObjectType,
): CommonValidatorResult {
  return {
    processedSchema: schema,
    processedRecord: record,
  };
}
