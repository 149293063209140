import React from 'react';

import {DSL_IconWrapper} from '../IconWrapper';
import {DSL_IconProps} from '../types';

const DSL_InformationRequest = (props: DSL_IconProps) => {
  return (
    <DSL_IconWrapper
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          fill="none"
          viewBox="0 0 24 24"
          {...props}
        >
          <path
            fill="#000"
            fillOpacity={0.54}
            d="M16 11.21c-.295.273-.594.548-.792.906-.249.45-.235.96-.225 1.322l.003.136h1.338c0-.685.046-.824.162-1.037.14-.256.336-.447.566-.669.243-.235.523-.506.81-.928.5-.74.31-1.736-.015-2.269-.38-.62-1.125-1.115-2.213-1.115-1.3 0-2.194.847-2.523 1.833l1.2.505c.161-.491.546-1.084 1.328-1.084 1.194 0 1.43 1.116 1.01 1.713-.178.254-.412.47-.649.688M15.639 16.352c.51 0 .926-.417.926-.926a.93.93 0 0 0-.926-.926.93.93 0 0 0-.926.926c0 .51.417.926.926.926M5.333 9.778c0-.614.498-1.111 1.111-1.111h4.445a1.111 1.111 0 0 1 0 2.222H6.444a1.11 1.11 0 0 1-1.11-1.111M6.444 12a1.111 1.111 0 0 0 0 2.222h2.223a1.111 1.111 0 1 0 0-2.222z"
          />
          <path
            fill="#000"
            fillOpacity={0.54}
            fillRule="evenodd"
            d="M19.778 2H4.222C3 2 2 3 2 4.222v15.556C2 21 3 22 4.222 22h15.556C21 22 22 21 22 19.778V4.222C22 3 21 2 19.778 2M4.222 4.222h15.556v15.556H4.222z"
            clipRule="evenodd"
          />
        </svg>
      }
      {...props}
    />
  );
};

export default React.memo(DSL_InformationRequest);
