import React from 'react';

import {TextInput, TextInputProps} from 'components/DesignSystem/Library';

import {
  useInputFieldProps,
  DSP_UseInputFieldProps,
} from '../hooks/useInputFieldProps';

export type DSP_TextFieldProps = TextInputProps & DSP_UseInputFieldProps;
export const DSP_TextField = (props: DSP_TextFieldProps) => {
  const fieldProps = useInputFieldProps(props);
  return <TextInput {...fieldProps} />;
};

export {
  //
  /** @deprecated use DSP_TextFieldProps instead*/
  DSP_TextFieldProps as TextFieldProps, //
  /** @deprecated use DSP_TextField instead*/
  DSP_TextField as TextField,
};
