import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/accessProductApplications/create',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "CreateProductApplication" */ './CreateAccessProductApplication'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Add case',
  menuIcon: LibraryAddCheckIcon,
  authorisation: {
    apiKey: 'AccessProductApplicationWithRelations.create',
    policies: [{role: 'operationsSpatialSupervisor'}] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Network consultation cases',
      to: '..',
    },
  ],
};
