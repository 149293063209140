import React from 'react';

import {
  DSL_BaseSummaryItem,
  DSL_BaseSummaryItemProps,
  DSL_BaseSummaryItemHorizontal,
  DSL_FlexColumnProps,
  DSL_CopyAble,
} from 'components/DesignSystem/Library';

import {
  DSP_UseSummaryItemRendererArgs,
  useSummaryItemRenderer,
} from './useSummaryItemRenderer';

export interface DSP_SummaryItemRendererProps
  extends Pick<DSL_BaseSummaryItemProps, 'ellipsisLines'>,
    DSP_UseSummaryItemRendererArgs {
  variant?: DSL_BaseSummaryItemProps['variant'];
  isSecondary?: DSL_BaseSummaryItemProps['isSecondary'];
  hideLabel?: boolean;
  layout?: 'vertical' | 'horizontal';
  labelWidth?: DSL_FlexColumnProps['width'];
  isCopyable?: boolean;
}

export const DSP_SummaryItemRenderer = ({
  variant = 'page',
  layout = 'vertical',
  isSecondary,
  ellipsisLines,
  hideLabel,
  fullWidth,
  labelWidth,
  isCopyable,
  ...props
}: DSP_SummaryItemRendererProps) => {
  const {
    renderedValue: children,
    label,
    suffix,
  } = useSummaryItemRenderer(props);

  const labelToRender = variant === 'table' ? null : label;

  const DSL_SummaryItemLayout =
    layout === 'vertical' ? DSL_BaseSummaryItem : DSL_BaseSummaryItemHorizontal;

  return (
    <DSL_SummaryItemLayout
      variant={variant}
      label={!hideLabel && labelToRender}
      suffix={suffix}
      isSecondary={isSecondary}
      ellipsisLines={ellipsisLines}
      fullWidth={fullWidth}
      labelWidth={labelWidth}
    >
      {isCopyable && !!children ? (
        <DSL_CopyAble copyText={children as string}>{children}</DSL_CopyAble>
      ) : (
        children
      )}
    </DSL_SummaryItemLayout>
  );
};

DSP_SummaryItemRenderer.displayName = 'DSP_SummaryItemRenderer';

export {
  /** @deprecated use DSP_SummaryItemRenderer instead*/
  DSP_SummaryItemRenderer as SummaryItemRenderer,
};
