import React from 'react';

import {DSP_FormSaveConfirmationDialogView} from './FormSaveConfirmationDialog.View';
import {useFormSaveConfirmationDialog} from './useFormSaveConfirmationDialog';

export const DSP_FormSaveConfirmationDialog = ({
  onClose,
  open,
  submitConfirmMessage,
  isCreate,
}: {
  onClose: () => void;
  open: boolean;
  submitConfirmMessage?: string;
  isCreate?: boolean;
}) => {
  const {onConfirm} = useFormSaveConfirmationDialog(onClose, isCreate);

  return (
    <DSP_FormSaveConfirmationDialogView
      onConfirm={onConfirm}
      onClose={onClose}
      open={open}
      submitConfirmMessage={submitConfirmMessage}
    />
  );
};

DSP_FormSaveConfirmationDialog.displayName = 'DSP_FormSaveConfirmationDialog';
