import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/accessProductDocumentMedia/create',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "CreatePermitStandardCondition" */ './CreateProductDocumentMedia'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Add pre approval guide',
  menuIcon: LibraryAddCheckIcon,
  authorisation: {
    apiKey: null,
    policies: [
      {
        role: 'operationsAccessRead',
      },
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Pre approval guides',
      to: '..',
    },
  ],
};
