import React from 'react';

import {DSL_BaseButton, DSL_BaseButtonProps} from '../BaseButton';

export type DSL_CriticalActionButtonProps = Omit<
  DSL_BaseButtonProps,
  'variant'
>;

export const DSL_CriticalActionButton = (
  props: DSL_CriticalActionButtonProps,
) => {
  return <DSL_BaseButton variant={'contained'} color={'error'} {...props} />;
};

DSL_CriticalActionButton.displayName = 'DSL_CriticalActionButton';

export {
  //
  /** @deprecated use DSL_CriticalActionButtonProps instead*/
  DSL_CriticalActionButtonProps as CriticalActionButtonProps, //
  /** @deprecated use DSL_CriticalActionButton instead*/
  DSL_CriticalActionButton as CriticalActionButton,
};
