import React, {ReactNode} from 'react';

import {DSL_BaseButtonProps} from '../Buttons/BaseButton';
import {DSL_ContainedButton} from '../Buttons/ContainedButton';
import {DSL_OutlinedButton} from '../Buttons/OutlinedButton';
import {DSL_TextButton} from '../Buttons/TextButton';
import {DSL_FlexColumn} from '../Layout/FlexColumn';
import {DSL_Body1} from '../Typography/Body1';
import {DSL_Heading5} from '../Typography/Heading5';

export interface DSL_BaseEmptyStateProps {
  title: string;
  icon: ReactNode;
  subheader?: string;
  primaryButton?: DSL_BaseButtonProps | false;
  secondaryButton?: DSL_BaseButtonProps | false;
  tertiaryButton?: DSL_BaseButtonProps | false;
}

export const DSL_BaseEmptyState = ({
  icon,
  title,
  subheader,
  primaryButton,
  secondaryButton,
  tertiaryButton,
}: DSL_BaseEmptyStateProps) => {
  return (
    <DSL_FlexColumn
      gap={4}
      alignItems={'center'}
      justifyContent={'center'}
      p={5}
      maxWidth={512}
      marginX={'auto'}
    >
      {icon}
      <DSL_FlexColumn textAlign={'center'}>
        <DSL_Heading5>{title}</DSL_Heading5>
        {subheader && <DSL_Body1>{subheader}</DSL_Body1>}
      </DSL_FlexColumn>
      <DSL_FlexColumn>
        {primaryButton && <DSL_ContainedButton {...primaryButton} />}
        {secondaryButton && <DSL_OutlinedButton {...secondaryButton} />}
        {tertiaryButton && <DSL_TextButton {...tertiaryButton} />}
      </DSL_FlexColumn>
    </DSL_FlexColumn>
  );
};

DSL_BaseEmptyState.displayName = 'DSL_BaseEmptyState';

export {
  //
  /** @deprecated use DSL_BaseEmptyStateProps instead*/
  DSL_BaseEmptyStateProps as BaseEmptyStateProps, //
  /** @deprecated use DSL_BaseEmptyState instead*/
  DSL_BaseEmptyState as BaseEmptyState,
};
