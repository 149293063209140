import React from 'react';

import {DSP_FormSaveDialogSavingToArchiveView} from './FormSaveDialogSavingToArchive.View';
import {useFormSaveDialogSavingToArchive} from './useFormSaveDialogSavingToArchive';

export const DSP_FormSaveDialogSavingToArchive = ({
  onClose,
  open,
  submitConfirmMessage,
}: {
  onClose: () => void;
  open: boolean;
  submitConfirmMessage?: string;
}) => {
  const {onConfirm, isConfirmButtonDisabled} =
    useFormSaveDialogSavingToArchive(onClose);

  return (
    <DSP_FormSaveDialogSavingToArchiveView
      isConfirmButtonDisabled={isConfirmButtonDisabled}
      onConfirm={onConfirm}
      onClose={onClose}
      open={open}
      submitConfirmMessage={submitConfirmMessage}
    />
  );
};

DSP_FormSaveDialogSavingToArchive.displayName =
  'DSP_FormSaveDialogSavingToArchive';
