import React, {ForwardedRef, useCallback} from 'react';

import {formatDistanceToNow} from 'date-fns';

import {
  BaseDateTimeRenderer,
  BaseDateTimeRendererProps,
} from 'components/DesignSystem/Library';

export type DSP_TimeDistanceRendererProps = Omit<
  BaseDateTimeRendererProps,
  'formatter'
>;

export const DSP_TimeDistanceRenderer = React.forwardRef(
  (
    {date, ...props}: DSP_TimeDistanceRendererProps,
    ref: ForwardedRef<HTMLSpanElement>,
  ) => {
    const formatter = useCallback(
      () =>
        formatDistanceToNow(date, {
          addSuffix: true,
          includeSeconds: true,
        }),
      [date],
    );

    return (
      <BaseDateTimeRenderer
        date={date}
        formatter={formatter}
        ref={ref}
        {...props}
      />
    );
  },
);

DSP_TimeDistanceRenderer.displayName = 'DSP_TimeDistanceRenderer';

export {
  //
  /** @deprecated use DSP_TimeDistanceRendererProps instead*/
  DSP_TimeDistanceRendererProps as TimeDistanceRendererProps, //
  /** @deprecated use DSP_TimeDistanceRenderer instead*/
  DSP_TimeDistanceRenderer as TimeDistanceRenderer,
};
