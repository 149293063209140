import {useCallback, useRef, useState} from 'react';

import {useWindowSize} from 'usehooks-ts';

import {useSizeObserver} from '../hooks/useSizeObserver';

import {useDrawerContext} from './DrawerProvider';
import {DSL_DrawerAnchor, DSL_DrawerPositionProps} from './types';
import {DSL_UseDrawerArgs} from './useDrawer';
import {useDrawerTransitions} from './useDrawerTransitions';

export interface DSL_UseDrawerSidebarProps
  extends Pick<
    Required<DSL_UseDrawerArgs>,
    'anchor' | 'open' | 'sidebarAnchor'
  > {
  initialDrawerWidth: number;
  offsetTop: number | undefined;
}

export function useDrawerSidebar({
  open,
  anchor,
  offsetTop,
  sidebarAnchor,
  initialDrawerWidth,
}: DSL_UseDrawerSidebarProps) {
  const {drawerDimensions} = useDrawerContext();
  const {transition} = useDrawerTransitions(drawerDimensions);
  const refSidebar = useRef<HTMLDivElement>(null);
  const {width: viewWidth} = useWindowSize();

  const [size, setSize] = useState({width: 0, height: 0});

  const onInit = useCallback(
    (rect: DOMRect) =>
      setSize({
        width: rect.width,
        height: rect.height,
      }),
    [],
  );

  const onResize = useCallback(
    (entries: ResizeObserverEntry[]) => {
      const resizeWidth = Math.round(entries[0].contentRect.width);
      const resizeHeight = Math.round(entries[0].contentRect.height);

      if (resizeWidth !== size.width || resizeHeight !== size.height) {
        setSize({
          width: resizeWidth,
          height: resizeHeight,
        });
      }
    },
    [size],
  );

  useSizeObserver({ref: refSidebar, onResize, onInit});

  const position = calculateSidebarPosition({
    open,
    anchor,
    sidebarAnchor,
    offsetTop,
    initialDrawerWidth,
    sidebarWidth: size.width,
    viewWidth,
  });

  return {
    ...position,
    refSidebar,
    transition,
    width: size.width,
    height: size.height,
  };
}

function calculateSidebarPosition(args: {
  open: boolean;
  anchor: DSL_DrawerAnchor;
  sidebarAnchor: DSL_DrawerAnchor;
  offsetTop: number | undefined;
  initialDrawerWidth: number;
  sidebarWidth: number;
  viewWidth: number;
}): DSL_DrawerPositionProps {
  const position = {
    // Drawer anchor
    left: {
      // Sidebar anchor
      bottom: {
        bottom: 0,
        left: 0,
      },
      // Sidebar anchor
      left: {
        top: args.offsetTop,
        left: 0,
      },
      // Sidebar anchor
      right: {
        top: args.offsetTop,
        left: args.open
          ? Math.min(
              args.initialDrawerWidth,
              args.viewWidth - args.sidebarWidth,
            )
          : 0,
      },
    },
    // Drawer anchor
    right: {
      // Sidebar anchor
      bottom: {
        bottom: 0,
        left: 0,
      },
      // Sidebar anchor
      left: {
        top: args.offsetTop,
        right: args.open ? args.initialDrawerWidth : 0,
      },
      // Sidebar anchor
      right: {
        top: args.offsetTop,
        right: 0,
      },
    },
    // Drawer anchor
    bottom: {
      // Sidebar anchor
      bottom: {
        bottom: 0,
        left: 0,
      },
      // Sidebar anchor
      left: {
        top: args.offsetTop,
        left: 0,
      },
      // Sidebar anchor
      right: {
        top: args.offsetTop,
        right: 0,
      },
    },
  };

  return {
    left: undefined,
    top: undefined,
    bottom: undefined,
    ...position[args.anchor][args.sidebarAnchor],
  };
}
