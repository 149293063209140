import React from 'react';

import {
  DSL_EntityInfoContainer,
  DSL_EntityInfoRow,
  DSL_LanguageIconInfo,
  DSL_ExternalLink,
} from 'components/DesignSystem/Library';

export interface DSP_RegulatorAccountEntityDetailsProps {
  website: string | undefined;
  isLoading: boolean;
}

export const DSP_RegulatorAccountEntityDetails = ({
  website,
  isLoading,
}: DSP_RegulatorAccountEntityDetailsProps) => {
  return (
    <DSL_EntityInfoContainer>
      <DSL_EntityInfoRow isLoading={isLoading}>
        <DSL_LanguageIconInfo
          label={<DSL_ExternalLink href={website}>{website}</DSL_ExternalLink>}
        />
      </DSL_EntityInfoRow>
    </DSL_EntityInfoContainer>
  );
};

DSP_RegulatorAccountEntityDetails.displayName =
  'DSP_RegulatorAccountEntityDetails';
