import React, {FC, ReactNode} from 'react';

import {DSL_FlexColumn, DSL_FlexColumnProps} from '../Layout/FlexColumn';

export interface DSL_DrawerContainerProps extends DSL_FlexColumnProps {
  width: number | string | undefined;
  height: number | string | undefined;
  children?: ReactNode;
}

export const DSL_DrawerContainer: FC<DSL_DrawerContainerProps> = ({
  children,
  ...props
}: DSL_DrawerContainerProps): JSX.Element => {
  return (
    <DSL_FlexColumn
      data-name={'DrawerContainer'}
      gap={0}
      flexWrap={'nowrap'}
      {...props}
    >
      {children}
    </DSL_FlexColumn>
  );
};

export {
  //
  /** @deprecated use DSL_DrawerContainerProps instead*/
  DSL_DrawerContainerProps as DrawerContainerProps, //
  /** @deprecated use DSL_DrawerContainer instead*/
  DSL_DrawerContainer as DrawerContainer,
};
