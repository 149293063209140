import {
  jsonPointerResolver,
  schemaJsonPointerResolver,
} from '@regulatory-platform/common-utils';
import * as R from 'ramda';
import getErrorMessage from '../schema/getErrorMessage';
import getLabel from '../schema/getLabel';
import isError from '../schema/isError';
import {FieldProps, FormStoreContainer} from './types';

/**
 * Get basic props for form fields using storeContainer schema
 * @param storeContainer - form store container
 * @param fieldRef - JSON pointer to field in record
 */
export default function getFieldProps(
  storeContainer: FormStoreContainer,
  fieldRef: string,
): FieldProps {
  const {state} = storeContainer;
  const {schema, record, props} = state.context;
  const fieldSchema = schemaJsonPointerResolver(fieldRef)(schema);
  const isNewRecord = fieldRef.indexOf('/-1/') > -1;
  let fieldValue = isNewRecord
    ? fieldSchema['x-origValue']
    : jsonPointerResolver(fieldRef)(record);
  if (
    !R.isNil(fieldSchema) &&
    !R.isNil(fieldSchema['x-items']) &&
    R.is(Array, fieldValue)
  ) {
    fieldValue = R.join(', ', fieldValue as unknown as string[]);
  }
  const isValidError = isError(fieldSchema, props.overrideTouched === true);
  return {
    id: R.replace(/\//g, '-', R.replace(/#\//g, '', fieldRef)),
    variant: 'outlined',
    error: isValidError === true,
    helperText: isValidError ? getErrorMessage(fieldSchema) : undefined,
    value: fieldValue as string | number,
    label: fieldSchema['x-required']
      ? getLabel(fieldSchema)
      : getLabel(fieldSchema) + ' (optional)',
  };
}
