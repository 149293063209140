import {Theme, useMediaQuery} from '@mui/material';
import {useTheme} from '@mui/styles';
import {Breakpoint} from '@mui/system';

export type DSL_BreakpointValueMap<DSL_ValueType> = {
  [BpName in Breakpoint]?: DSL_ValueType;
};

export type DSL_BreakpointValues<DSL_ValueType> =
  | DSL_BreakpointValueMap<DSL_ValueType>
  | DSL_ValueType
  | undefined;

export function useCascadingBreakpointValue<DSL_ValueType>(
  breakPointValues: DSL_BreakpointValues<DSL_ValueType>,
): DSL_ValueType | undefined {
  const {breakpoints} = useTheme<Theme>();

  const isUpMap: Record<Breakpoint, boolean> = {
    xs: useMediaQuery(breakpoints.up('xs')),
    sm: useMediaQuery(breakpoints.up('sm')),
    md: useMediaQuery(breakpoints.up('md')),
    lg: useMediaQuery(breakpoints.up('lg')),
    xl: useMediaQuery(breakpoints.up('xl')),
  };

  if (!isValuesMap(breakPointValues)) {
    return breakPointValues;
  }

  return Object.entries(breakPointValues).reduce(
    (effectiveValue, [nextBreakpoint, nextValue]) => {
      if (isUpMap[nextBreakpoint as Breakpoint]) {
        effectiveValue = nextValue;
      }
      return effectiveValue;
    },
    breakPointValues?.xs,
  );
}

const bpKeys: Breakpoint[] = ['xs', 'sm', 'md', 'lg', 'xl'];

function isValuesMap(
  breakPointValues: DSL_BreakpointValues<unknown>,
): breakPointValues is DSL_BreakpointValueMap<unknown> {
  return (
    breakPointValues !== null &&
    breakPointValues !== undefined &&
    typeof breakPointValues === 'object' &&
    Object.keys(breakPointValues).every(key =>
      bpKeys.includes(key as Breakpoint),
    )
  );
}
