import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import LegacyTypography from 'components/typography/LegacyTypography';

import PrimaryButton from 'components/forms/buttons/PrimaryButton';
import SecondaryButton from 'components/forms/buttons/SecondaryButton';
import FieldRow from 'components/forms/FieldRow';
import TextField from 'components/forms/TextField';
import * as R from 'ramda';
import React from 'react';
import {FormStoreContainer} from 'utils/stores/types';
import {useSelector} from '@xstate/react';

type FormSaveDialogProps = {
  service: FormStoreContainer['service'];
  dialogOpen: boolean;
  isSavingToArchive?: boolean;
  submitConfirmMessage?: string;
  handleDialogClose: () => void;
  onSubmit?: Function;
  onUpdate?: Function;
};

const FormSaveDialog: React.FC<FormSaveDialogProps> = ({
  service,
  dialogOpen,
  handleDialogClose,
  submitConfirmMessage,
  isSavingToArchive,
}: FormSaveDialogProps) => {
  const reasonForArchiveValue = useSelector(
    service,
    state => state.context.record?.reasonForArchive,
  );

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleDialogClose}
      aria-labelledby={'form-save-dialog-title'}
    >
      <DialogTitle id={'form-save-dialog-title'}>Confirm</DialogTitle>
      <DialogContent>
        {isSavingToArchive ? (
          <>
            {
              'The record will be archived and hidden from the default view. Please provide a justification to continue.'
            }
            <FieldRow>
              <TextField fieldRef="#/reasonForArchive" />
            </FieldRow>
          </>
        ) : (
          <LegacyTypography variant="body1" style={{whiteSpace: 'pre-line'}}>
            {submitConfirmMessage}
          </LegacyTypography>
        )}
      </DialogContent>
      <DialogActions>
        <PrimaryButton
          onClick={(): void => {
            if (isSavingToArchive) {
              service.send('SAVE');
            } else {
              service.send('SUBMIT');
            }
            handleDialogClose();
          }}
          disabled={
            isSavingToArchive &&
            (R.isNil(reasonForArchiveValue) || R.isEmpty(reasonForArchiveValue))
          }
          autoFocus
        >
          {isSavingToArchive ? 'Confirm' : 'Yes'}
        </PrimaryButton>
        <SecondaryButton ml={1} onClick={handleDialogClose}>
          {isSavingToArchive ? 'Cancel' : 'No'}
        </SecondaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default FormSaveDialog;
