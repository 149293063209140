import MuiAlert from '@mui/material/Alert';
import FieldRow from 'components/forms/FieldRow';
import Loading from 'components/templates/content/Loading';
import React, {useEffect, useRef} from 'react';
import Recaptcha, {ReCAPTCHA} from 'react-google-recaptcha';
import getError from 'utils/apollo/getError';
import {getRecaptchaSiteKey} from 'utils/global';
import useVerifyMachine from './api/useVerifyMachine';
import * as R from 'ramda';

const recaptchaSiteKey = getRecaptchaSiteKey();
const useRecaptcha = recaptchaSiteKey !== '';

const Verify: React.FC = () => {
  const [state, send] = useVerifyMachine();
  const sharedContext = state.context.shared;
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const submitError = state.matches('error');
  const verifyingEmail = state.matches('verifyingEmail');
  let verifyingToken = state.matches('verifyingToken');
  const invalidUrl = state.matches('invalidUrl');
  if (!verifyingToken) {
    verifyingToken = R.all(
      param => !R.isNil(param),
      [
        sharedContext?.email,
        sharedContext?.userId,
        sharedContext?.secret,
        sharedContext?.iv,
        sharedContext?.accountType,
        sharedContext?.accountId,
        sharedContext?.clientId,
      ],
    );
  }
  const getReCAPTCHA = async (): Promise<void> => {
    let token;
    if (useRecaptcha) {
      try {
        token = await recaptchaRef?.current?.executeAsync();
      } catch (err) {
        // handle error
      }
    }
    send({type: 'RECAPTCHA', fieldRef: '#', value: token as string});
  };

  useEffect(
    () => {
      getReCAPTCHA();
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  if (verifyingEmail || verifyingToken)
    return <Loading unauthenticated={true} />;

  if (invalidUrl)
    return (
      <FieldRow>
        <MuiAlert elevation={0} variant="filled" severity="error">
          Invalid URL
        </MuiAlert>
      </FieldRow>
    );

  return (
    <>
      {submitError && (
        <FieldRow>
          <MuiAlert elevation={0} variant="filled" severity="error">
            {getError(state.context.props.apiError)?.message}
          </MuiAlert>
        </FieldRow>
      )}
      {!useRecaptcha ? null : (
        <Recaptcha
          ref={recaptchaRef}
          badge="bottomright"
          size="invisible"
          sitekey={recaptchaSiteKey}
        />
      )}
    </>
  );
};

export default Verify;
