import * as ApolloReactCommon from '@apollo/client';
import {jsonPointerResolver} from '@regulatory-platform/common-utils/dist';
import {useSelector} from '@xstate/react';
import * as R from 'ramda';

import {
  RelatedRecord,
  DSL_SearchMultiSelectAutocompleteInputProps,
} from 'components/DesignSystem/Library';

import {SearchStoreMachineContext} from '../../../../../utils/stores/types';
import {useFormContext} from '../FormProvider';
import {
  DSP_GetGenericFieldProps,
  getGenericFieldProps,
} from '../utils/getGenericFieldProps';

import useFieldRelationsMultiSelectSearchMachine from './useFieldRelationsMultiSelectSearchMachine';

export interface DSP_UseSearchMultiSelectRelationFieldArgs
  extends Omit<DSL_SearchMultiSelectAutocompleteInputProps, 'options'>,
    Omit<DSP_GetGenericFieldProps, 'state'> {
  fieldRelationRef: string;
  query: ApolloReactCommon.DocumentNode;
  queryVariables?: {[key: string]: string | number | boolean | undefined};
  emptyRecord?: RelatedRecord;
  validateSearchTerm?: (searchTerm?: string) => string;
  clientSideFilter?: SearchStoreMachineContext['clientSideFilter'];
  findRelatedEntity?: SearchStoreMachineContext['findRelatedEntity'];
}

export function useSearchMultiSelectRelationField({
  fieldRef,
  fieldRelationRef,
  query,
  queryVariables,
  emptyRecord = {
    id: null,
  },
  validateSearchTerm = (searchTerm?: string): string => {
    return R.defaultTo('', searchTerm);
  },
  renderOption,
  clientSideFilter,
  findRelatedEntity,
  label,
  hideLabel,
  includeOptionalSuffix,
  getOptionLabel = (searchResult?: RelatedRecord): string =>
    searchResult?.name ?? '',
  useLabelForValidationErrors,
  ...props
}: DSP_UseSearchMultiSelectRelationFieldArgs): DSL_SearchMultiSelectAutocompleteInputProps {
  const {service} = useFormContext();

  const {currentRecord, ...genericProps} = useSelector(
    service,
    state => {
      const {value, ...fieldProps} = getGenericFieldProps({
        fieldRef,
        state,
        label,
        hideLabel,
        includeOptionalSuffix,
        useLabelForValidationErrors,
      });
      const _currentRecord = jsonPointerResolver(fieldRelationRef)(
        state.context.record,
      );

      return {
        ...fieldProps,
        currentRecord: _currentRecord,
      };
    },
    R.equals,
  );

  const [searchState, searchSend] = useFieldRelationsMultiSelectSearchMachine(
    service,
    fieldRef,
    fieldRelationRef,
    query,
    queryVariables,
    emptyRecord,
    getOptionLabel as (option?: RelatedRecord) => string,
    clientSideFilter,
    findRelatedEntity,
  );

  const inputValue = (searchState.context.record.search as string) || '';

  const fieldRelationValue = searchState?.context?.storeFieldValue
    ? searchState?.context?.storeFieldValue
    : undefined;

  const isLoading =
    searchState.matches('userInput.debouncing') ||
    searchState.matches('userInput.searching');

  const options = searchState.context.data;

  const value = (fieldRelationValue as RelatedRecord[]) ?? [];

  return {
    ...genericProps,
    value,
    inputValue,
    loading: isLoading,
    options: [
      ...options.filter(
        (option: RelatedRecord) => !value.some(v => v?.id === option?.id),
      ),
      ...value,
    ],
    renderOption,
    getOptionLabel: getOptionLabel,
    onOpen: (): void => {
      if (R.length(options) === 0 && !!inputValue) searchSend('SEARCH');
    },
    onChange: (_event, searchResult: RelatedRecord | null): void => {
      searchSend('SELECT', {
        fieldRef: fieldRef,
        value: searchResult,
      });
    },
    onInputChange: (_event, searchTerm, reason): void => {
      if (reason === 'reset') {
        return; //programmatic change
      }
      searchTerm = validateSearchTerm(searchTerm);
      if (
        getOptionLabel(fieldRelationValue!) !== searchTerm ||
        searchTerm === ''
      ) {
        searchSend('CHANGE', {fieldRef: '#/search', value: searchTerm});
      }
    },
    getOptionDisabled: searchResult => searchResult?.archived === true,
    isOptionEqualToValue: (
      searchResult: RelatedRecord,
      selectedValue: RelatedRecord,
    ): boolean => {
      if (!searchResult || !value) return false;
      if (searchResult?.id) {
        return searchResult?.id === selectedValue?.id;
      }
      return searchResult === value;
    },
    ...props,
  };
}
