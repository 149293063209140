import React, {ReactNode} from 'react';

import {
  DragOverlay,
  defaultDropAnimationSideEffects,
  DropAnimation,
} from '@dnd-kit/core';

const dropAnimationConfig: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.4',
      },
    },
  }),
};

interface DSL_SortableOverlayProps {
  children: ReactNode;
}

export const DSL_SortableOverlay = ({children}: DSL_SortableOverlayProps) => {
  return (
    <DragOverlay dropAnimation={dropAnimationConfig}>{children}</DragOverlay>
  );
};
