import * as R from 'ramda';
import {JSONSchemaRecord} from '@regulatory-platform/common-utils';

export default function isError(
  fieldSchema: JSONSchemaRecord,
  overrideTouched = false,
): boolean {
  const validSchema = fieldSchema['x-valid'];
  if (R.isNil(validSchema)) {
    return false;
  }
  return (
    !validSchema.valid && (validSchema.touched === true || overrideTouched)
  );
}
