import React from 'react';

import {DSL_BaseButton, DSL_BaseButtonProps} from '../BaseButton';

export type DSL_TextButtonProps = Omit<DSL_BaseButtonProps, 'variant'>;

export const DSL_TextButton = React.forwardRef<
  HTMLButtonElement,
  DSL_TextButtonProps
>((props: DSL_TextButtonProps, ref) => {
  return <DSL_BaseButton ref={ref} variant={'text'} {...props} />;
});

DSL_TextButton.displayName = 'DSL_TextButton';

export {
  //
  /** @deprecated use DSL_TextButtonProps instead*/
  DSL_TextButtonProps as TextButtonProps, //
  /** @deprecated use DSL_TextButton instead*/
  DSL_TextButton as TextButton,
};
