import {useState, useCallback} from 'react';

export type DSL_UseDialogResult = [boolean, () => void, () => void, () => void];

export const useDialog = (): DSL_UseDialogResult => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const openDialog = useCallback(() => {
    setDialogOpen(true);
  }, []);

  const closeDialog = useCallback(() => {
    setDialogOpen(false);
  }, []);

  const toggleDialog = useCallback(() => {
    setDialogOpen(prev => !prev);
  }, []);

  return [dialogOpen, openDialog, closeDialog, toggleDialog];
};
