import React from 'react';

import {TabList} from '@mui/lab';
import {TabsProps as MuiTabsProps} from '@mui/material';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

import {useVerticalTabList} from './useVerticalTabList';

export interface DSL_VerticalTabListProps
  extends Omit<
    MuiTabsProps,
    DSL_RestrictedVisualProps | 'orientation' | 'TabIndicatorProps'
  > {
  indicatorAnchor?: 'left' | 'right';
}

export const DSL_VerticalTabList = ({
  indicatorAnchor = 'left',
  children,
  ...props
}: DSL_VerticalTabListProps) => {
  const {TabIndicatorProps} = useVerticalTabList(indicatorAnchor);

  return (
    <TabList
      orientation={'vertical'}
      TabIndicatorProps={TabIndicatorProps}
      {...props}
    >
      {children}
    </TabList>
  );
};

DSL_VerticalTabList.displayName = 'DSL_VerticalTabs';

export {
  //
  /** @deprecated use DSL_VerticalTabListProps instead*/
  DSL_VerticalTabListProps as VerticalTabListProps, //
  /** @deprecated use DSL_VerticalTabList instead*/
  DSL_VerticalTabList as VerticalTabList,
};
