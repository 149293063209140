import React from 'react';

import {NotificationItem} from 'utils/machines/notificationsMachine';

import {PopperListItem} from 'components/DesignSystem/Library';

import {notificationTypeRendererMap} from './constants';

export interface DSP_NotificationListItemProps {
  notification: NotificationItem;
  onNotificationClicked: (notification: NotificationItem) => void;
}

export const DSP_NotificationListItem = ({
  notification,
  onNotificationClicked,
}: DSP_NotificationListItemProps) => {
  const DSP_NotificationRenderer =
    notificationTypeRendererMap[notification.notificationType];

  if (!DSP_NotificationRenderer) {
    return null;
  }

  return (
    <PopperListItem
      aria-label="Open notification"
      onClick={() => onNotificationClicked(notification)}
    >
      <DSP_NotificationRenderer notification={notification} />
    </PopperListItem>
  );
};

DSP_NotificationListItem.displayName = 'DSP_NotificationListItem';

export {
  //
  /** @deprecated use DSP_NotificationListItemProps instead*/
  DSP_NotificationListItemProps as NotificationListItemProps, //
  /** @deprecated use DSP_NotificationListItem instead*/
  DSP_NotificationListItem as NotificationListItem,
};
