import React from 'react';

import {DSL_IconWrapper} from '../IconWrapper';
import {DSL_IconProps} from '../types';

const DSL_Pdf = (props: DSL_IconProps) => {
  return (
    <DSL_IconWrapper
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          fill="none"
          viewBox="0 0 24 24"
          {...props}
        >
          <path
            fill="#000"
            fillOpacity={0.54}
            d="M7 3c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V7.6L14.4 3zm10 16H7V5h6v4h4zm-.7-5.5c-.2-.6-1.1-.8-2.6-.8h-.2c-.3-.3-.8-.9-1-1.2-.2-.2-.3-.3-.4-.6.2-.7.2-1 .3-1.5.1-.9 0-1.6-.2-1.8-.4-.2-.7-.2-.9-.2-.1 0-.3.2-.7.7-.2.7-.1 1.8.6 2.8-.2.8-.7 1.6-1 2.4-.8.2-1.5.7-1.9 1.1-.7.7-.9 1.1-.7 1.6 0 .3.2.6.7.6.3-.1.3-.2.7-.3.6-.3 1.2-1.7 1.7-2.4.8-.2 1.7-.3 2-.3h.6c.8.8 1.2 1.1 1.8 1.2h.3c.3 0 .8-.1 1-.6 0-.1 0-.3-.1-.7m-8 2.2c-.1.1-.2.1-.2.1 0-.1 0-.3.6-.8.2-.2.6-.3.9-.7-.6.7-1 1.2-1.3 1.4m3-6.7c0-.1.1-.2.1-.2s.2.2.1 1.2c0 .1 0 .3-.1.7-.1-.6-.4-1.2-.1-1.7m-.4 4.1c.2-.6.6-1 .7-1.5l.2.2c.1.2.3.7.7.9-.3.1-.9.3-1.6.4m4.3 1H15c-.2 0-.7-.2-1-.7 1.1 0 1.6.2 1.6.6-.1.1-.2.1-.4.1"
          />
        </svg>
      }
      {...props}
    />
  );
};

export default React.memo(DSL_Pdf);
