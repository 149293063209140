import React, {useEffect} from 'react';
import {ThemeProvider as MuiThemeProvider} from '@mui/material/styles';
import {useThemeSelection} from './ThemeSelectionProvider';
import {GlobalStyles} from './GlobalStyles';
import {i18n} from 'containers-content';
import {AuthorisationDomains} from '@regulatory-platform/common-utils/dist';

interface ThemeProviderProps {
  children: React.ReactNode;
}

function setFavicon(theme: string) {
  const link: HTMLAnchorElement | null =
    document.querySelector("link[rel~='icon']");
  const domain = i18n.getDomain().toUpperCase();
  const environment =
    AuthorisationDomains[domain as keyof typeof AuthorisationDomains] || 'nhvr';

  if (link) link.href = `/favicon/${environment}_${theme}.ico`;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({children}) => {
  const {selectedTheme, availableThemes} = useThemeSelection();

  useEffect(() => {
    const theme = selectedTheme === 'dark' ? 'dark' : 'light'; //This line needs to be removed when themes mode will contain only two modes, light and dark
    window.document.title = `${
      window.document.title.split(' | ')[0]
    } | ${i18n.get('GLOBAL_BRAND_NAME_SHORT')}`;
    setFavicon(theme);
  }, [selectedTheme]);

  if (!selectedTheme) {
    /* eslint-disable no-console */
    console.error(
      'the Theme component must be within the scope of ThemeSelectionProvider',
    );
  }
  const theme = availableThemes[selectedTheme];

  return (
    <MuiThemeProvider theme={theme}>
      <GlobalStyles theme={theme} />
      {children}
    </MuiThemeProvider>
  );
};
