import React from 'react';

import {
  LinearProgress as MuiLinearProgress,
  LinearProgressProps as MuiLinearProgressProps,
} from '@mui/material';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

export type DSL_LinearProgressProps = Omit<
  MuiLinearProgressProps,
  DSL_RestrictedVisualProps
>;

export const DSL_LinearProgress = (props: DSL_LinearProgressProps) => {
  return (
    <MuiLinearProgress
      sx={{width: '100%'}}
      variant="indeterminate"
      {...props}
    />
  );
};

DSL_LinearProgress.displayName = 'DSL_LinearProgress';
