import {DSL_TabListProps} from './TabList';

export function useTabList(anchor: DSL_TabListProps['indicatorAnchor']) {
  if (anchor === 'bottom') {
    return {};
  }

  return {
    TabIndicatorProps: {
      sx: {
        top: 0,
      },
    },
  };
}
