import {SelectChangeEvent} from '@mui/material';

import {useDspSelectedSegmentsDrawerContext} from '../SelectedSegmentsDrawer.Provider';
import {DSP_FilterByAccessType} from '../types';

import {useDspAccessTypeSelectOptions} from './useAccessTypeSelectOptions';

export const useAccessTypeSelect = () => {
  const options = useDspAccessTypeSelectOptions();
  const {filterByAccessTypeValue, setFilterByAccessTypeValue} =
    useDspSelectedSegmentsDrawerContext();

  const handleFilterByAccessTypeChange = (
    event: SelectChangeEvent<DSP_FilterByAccessType>,
  ): void => {
    setFilterByAccessTypeValue(event.target.value as DSP_FilterByAccessType);
  };

  return {
    options: options.sort((a, b) => a.label.localeCompare(b.label)),
    value: filterByAccessTypeValue ?? 'All',
    onChange: handleFilterByAccessTypeChange,
  };
};
