import React from 'react';

import {
  DSL_Avatar,
  DSL_Body1,
  DSL_Body2,
  DSL_FlexRow,
  DSL_EntityContactDetails,
} from 'components/DesignSystem/Library';

export interface DSP_UserSearchRelationItemProps {
  name: string;
  imageSrc?: string;
  hideUserAvatar?: boolean;
  phone?: string | null;
  email?: string | null;
  position?: string;
}

export const DSP_UserSearchRelationOption = ({
  name,
  imageSrc,
  phone,
  email,
  position,
  hideUserAvatar = false,
}: DSP_UserSearchRelationItemProps) => {
  return (
    <DSL_FlexRow gap={1} width={'fit-content'}>
      {!hideUserAvatar && <DSL_Avatar src={imageSrc} alt={name} />}
      <div>
        <DSL_Body1>{name}</DSL_Body1>
        {position && <DSL_Body2 color={'text.secondary'}>{position}</DSL_Body2>}
        <DSL_EntityContactDetails phone={phone} email={email} />
      </div>
    </DSL_FlexRow>
  );
};

DSP_UserSearchRelationOption.displayName = 'DSP_UserSearchRelationOption';

export {
  //
  /** @deprecated use DSP_UserSearchRelationItemProps instead*/
  DSP_UserSearchRelationItemProps as UserSearchRelationItemProps, //
  /** @deprecated use DSP_UserSearchRelationOption instead*/
  DSP_UserSearchRelationOption as UserSearchRelationOption,
};
