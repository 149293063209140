import {useState, useCallback, MouseEventHandler} from 'react';

import * as R from 'ramda';

import {useDialog} from 'components/DesignSystem/Library';

import {useFormService} from '../../FormProvider';
import {DSP_FileChangeList, DSP_FileListWithIndex} from '../Attachments.types';

export interface DSP_DialogState {
  file: DSP_FileListWithIndex | null;
}

export type DSP_UseDeleteFileDialogResults = {
  deleteDialogOpen: boolean;
  deleteDialogState: DSP_DialogState;
  handleDeleteDialogOpen: (file: DSP_FileListWithIndex) => void;
  handleDeleteDialogClose: () => void;
  onDeleteDialogSubmit: MouseEventHandler<HTMLButtonElement>;
};

interface DSP_UseDeleteFileDialog {
  fileChangeList: DSP_FileChangeList;
  fieldRef: string;
}

export function useDeleteFileDialog({
  fileChangeList,
  fieldRef,
}: DSP_UseDeleteFileDialog): DSP_UseDeleteFileDialogResults {
  const {send} = useFormService();

  const [dialogOpen, openDialog, closeDialog] = useDialog();

  const [deleteDialogState, setDialogState] = useState<DSP_DialogState>({
    file: null,
  });

  const handleDeleteDialogOpen = useCallback(
    (file: DSP_FileListWithIndex): void => {
      setDialogState(prevState => ({
        ...prevState,
        file,
      }));
      openDialog();
    },
    [],
  );

  const handleDeleteDialogClose = useCallback((): void => {
    setDialogState(prevState => ({
      ...prevState,
      file: null,
    }));
    closeDialog();
  }, []);

  const onDeleteDialogSubmit = useCallback(async () => {
    //check if uploading then abort
    const fileListItem = deleteDialogState.file;
    if (R.isNil(fileListItem)) {
      return;
    }
    if (
      fileChangeList[fileListItem._key] &&
      fileChangeList[fileListItem._key].progress < 100
    ) {
      fileChangeList[fileListItem._key].abortController.abort();
      handleDeleteDialogClose();
    } else {
      send({
        type: 'REMOVE_ITEM',
        fieldRef,
        index: fileListItem._idx,
        value: fileListItem,
      });
      handleDeleteDialogClose();
    }
  }, [deleteDialogState, send]);

  return {
    deleteDialogOpen: dialogOpen,
    deleteDialogState,
    handleDeleteDialogOpen,
    handleDeleteDialogClose,
    onDeleteDialogSubmit,
  };
}
