import {UserProfile} from '@regulatory-platform/common-utils';
import {Observable} from 'rxjs';
import {FormEventObject, FormStoreProps} from 'utils/stores/types';
import {createMachine, InterpreterFrom, MachineConfig} from 'xstate';
import machineDefinition from './authMachine.json';

export type AuthStoreMachineContext = {
  userProfile?: UserProfile;
  browserLoggingInRetries: number;
  props: FormStoreProps;
};
export interface AuthStoreMachineStateSchema {
  states: {
    entry: {};
    unauthenticated: {};
    userLoggingIn: {};
    browserLoggingIn: {
      states: {
        retry: {};
        login: {};
      };
    };
    authenticated: {
      states: {
        unlocked: {};
        locked: {};
        unLocking: {};
        changingAccount: {};
      };
    };
    loggingOut: {};
  };
}
// export type AuthStoreState = State<
//   AuthStoreMachineContext,
//   FormEventObject,
//   AuthStoreMachineStateSchema
// >;
export type AuthStoreObservable = Observable<AuthStoreState>;

export type AuthStoreConfig = MachineConfig<
  AuthStoreMachineContext,
  AuthStoreMachineStateSchema,
  FormEventObject
>;

const machine = createMachine<AuthStoreMachineContext, FormEventObject>(
  machineDefinition as AuthStoreConfig,
);

export type AuthStoreService = InterpreterFrom<typeof machine>;

export type AuthStoreState = AuthStoreService['state'];

export default machine;
