import React from 'react';

import {DSL_BaseTypography, DSL_BaseTypographyProps} from '../BaseTypography';

export type DSL_Heading2Props = Omit<DSL_BaseTypographyProps, 'variant'>;
export const DSL_Heading2 = (props: DSL_Heading2Props) => (
  <DSL_BaseTypography color={'text.primary'} {...props} variant={'h2'} />
);

export {
  //
  /** @deprecated use DSL_Heading2Props instead*/
  DSL_Heading2Props as Heading2Props, //
  /** @deprecated use DSL_Heading2 instead*/
  DSL_Heading2 as Heading2,
};
