import {useCallback, useRef} from 'react';

import {DSL_BaseCardInputProps} from './BaseCardInput';

export interface UseBaseCardInputProps {
  onClickCard?: DSL_BaseCardInputProps['onClickCard'];
}

export function useBaseCardInput<
  ElementType extends HTMLElement = HTMLButtonElement,
>({onClickCard}: UseBaseCardInputProps) {
  const ref = useRef<ElementType>(null);

  // Listen for click on card and simulate click on input element if no custom handler provided
  const defaultOnCardClick = useCallback(() => ref.current?.click(), [ref]);

  return {
    ref,
    onClickCard: onClickCard ?? defaultOnCardClick,
  };
}
