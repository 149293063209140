import React, {ReactNode} from 'react';

import {DSL_FiberManualRecordIcon} from '../Icons';
import {DSL_IconProps} from '../Icons/types';
import {DSL_Flex} from '../Layout/Flex';
import {DSL_Body1} from '../Typography/Body1';

export type DSL_ProgressBarSegmentLabelProps = {
  color: DSL_IconProps['htmlColor'];
  label: ReactNode;
};
export const DSL_ProgressBarSegmentLabel = ({
  color,
  label,
}: DSL_ProgressBarSegmentLabelProps) => (
  <DSL_Flex alignItems={'center'}>
    <DSL_FiberManualRecordIcon htmlColor={color} sx={{fontSize: 16}} />
    <DSL_Body1 color={'text.secondary'} component="span">
      {label}
    </DSL_Body1>
  </DSL_Flex>
);

export {
  //
  /** @deprecated use DSL_ProgressBarSegmentLabelProps instead*/
  DSL_ProgressBarSegmentLabelProps as ProgressBarSegmentLabelProps, //
  /** @deprecated use DSL_ProgressBarSegmentLabel instead*/
  DSL_ProgressBarSegmentLabel as ProgressBarSegmentLabel,
};
