import {DSP_AccreditationApplicationNotificationItem} from '../types';

import {DSP_AccreditationApplicationCustomerNotificationCardViewProps} from './AccreditationApplicationCustomerNotificationCard.View';

export interface DSP_UseAccreditationApplicationNotificationCardProps {
  notification: DSP_AccreditationApplicationNotificationItem;
}

export function useAccreditationApplicationCustomerNotificationCard({
  notification,
}: DSP_UseAccreditationApplicationNotificationCardProps): DSP_AccreditationApplicationCustomerNotificationCardViewProps {
  return {
    notification,
    userId: notification.fromUserId ?? undefined,
  };
}
