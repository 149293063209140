import React, {ReactNode} from 'react';

import {DSL_PaddingProps} from '../../types';
import {DSL_Box, DSL_BoxProps} from '../Box';

import {
  DSL_ScrollableLayoutSize,
  useScrollableLayoutPadding,
} from './useScrollableLayoutPadding';

export interface DSL_ScrollableLayoutHeaderProps
  extends Omit<DSL_BoxProps, DSL_PaddingProps | 'title'> {
  padding?: DSL_ScrollableLayoutSize;
  children?: ReactNode;
}

export const DSL_ScrollableLayoutHeader = React.forwardRef(
  ({padding = 'SM', ...props}: DSL_ScrollableLayoutHeaderProps, ref) => {
    const paddingStyle = useScrollableLayoutPadding(padding);

    return <DSL_Box ref={ref} {...paddingStyle} {...props} />;
  },
);

DSL_ScrollableLayoutHeader.displayName = 'DSL_ScrollableLayoutHeader';
