import React from 'react';

import {
  TableHead as MuiTableHead,
  TableHeadProps as MuiTableHeadProps,
} from '@mui/material';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

export type DSL_TableHeadProps = Omit<
  MuiTableHeadProps,
  DSL_RestrictedVisualProps
>;

export const DSL_TableHead = (props: MuiTableHeadProps) => {
  return <MuiTableHead {...props} />;
};

DSL_TableHead.displayName = 'DSL_TableHead';

export {
  //
  /** @deprecated use DSL_TableHeadProps instead*/
  DSL_TableHeadProps as TableHeadProps, //
  /** @deprecated use DSL_TableHead instead*/
  DSL_TableHead as TableHead,
};
