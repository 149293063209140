import React from 'react';

import {DSL_IconWrapper} from '../IconWrapper';
import {DSL_IconProps} from '../types';

const DSL_TurnSlightleft = (props: DSL_IconProps) => {
  return (
    <DSL_IconWrapper
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          fill="none"
          viewBox="0 0 60 60"
          {...props}
        >
          <g clipPath="url(#a)">
            <path fill="#fff" d="M0 0h60v60H0z" />
            <path
              fill="#000"
              fillRule="evenodd"
              d="M25.303 24.705h-5V11.598H33.41v5h-4.821l5.533 5.534a16.5 16.5 0 0 1 4.833 11.667V48h-5V33.8a11.5 11.5 0 0 0-3.368-8.132l-5.284-5.284z"
              clipRule="evenodd"
            />
          </g>
          <defs>
            <clipPath id="a">
              <path fill="#fff" d="M0 0h60v60H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      {...props}
    />
  );
};

export default React.memo(DSL_TurnSlightleft);
