import React, {ReactNode} from 'react';

import {DSP_BaseAccessProductChangeSetNotificationCardView} from './BaseAccessProductChangeSetNotificationCard.View';
import {
  useBaseAccessProductChangeSetNotificationCard,
  DSP_UseBaseAccessProductChangeSetNotificationCardProps,
} from './useBaseAccessProductChangeSetNotificationCard';

export interface DSP_BaseAccessProductChangeSetNotificationCardProps
  extends DSP_UseBaseAccessProductChangeSetNotificationCardProps {
  avatar: ReactNode | undefined;
}

export const DSP_BaseAccessProductChangeSetNotificationCard = ({
  avatar,
  ...props
}: DSP_BaseAccessProductChangeSetNotificationCardProps) => {
  const viewProps = useBaseAccessProductChangeSetNotificationCard(props);

  return (
    <DSP_BaseAccessProductChangeSetNotificationCardView
      avatar={avatar}
      {...viewProps}
    />
  );
};

DSP_BaseAccessProductChangeSetNotificationCard.displayName =
  'DSP_BaseAccessProductChangeSetNotificationCard';

export {
  //
  /** @deprecated use DSP_BaseAccessProductChangeSetNotificationCardProps instead*/
  DSP_BaseAccessProductChangeSetNotificationCardProps as BaseAccessProductChangeSetNotificationCardProps, //
  /** @deprecated use DSP_BaseAccessProductChangeSetNotificationCard instead*/
  DSP_BaseAccessProductChangeSetNotificationCard as BaseAccessProductChangeSetNotificationCard,
};
