import React, {ReactNode} from 'react';

import LegacyFormContext from 'app/stores/FormContext';
import * as R from 'ramda';
import {
  FormContextProps as LegacyFormContextProps,
  FormMachineService,
} from 'utils/stores/types';

import {
  useFormContext,
  DSP_FormContextProps,
  FormContextProps,
} from '../useFormContext';

interface DSP_LegacyFormProviderProps<
  T extends FormMachineService = FormMachineService,
> {
  service: T;
  canSave?: boolean | ((context: T) => boolean);
  otherProps?: {[key: string]: unknown};
  children?: ReactNode | ((context: LegacyFormContextProps<T>) => ReactNode);
}

interface DSP_LegacyFormProviderMockProps<
  T extends FormMachineService = FormMachineService,
> extends DSP_LegacyFormProviderProps<T> {
  children?: ReactNode | ((context: DSP_FormContextProps<T>) => ReactNode);
}

/**
 * In order to support legacy forms
 * This provider also allows for the use of children as a function which will be deprecated in the design system.
 *
 * @param service
 * @param children
 * @constructor
 */
export const DSP_LegacyFormProvider = <
  T extends FormMachineService = FormMachineService,
>({
  otherProps,
  children,
}: DSP_LegacyFormProviderProps<T>) => {
  const globalFormContext = useFormContext<T>();
  const contextValues = {
    ...globalFormContext,
    otherProps,
  };

  const renderedChildren = R.is(Function, children)
    ? children(contextValues)
    : children;

  return (
    <LegacyFormContext.Provider
      value={contextValues as unknown as FormContextProps<T>}
    >
      {renderedChildren}
    </LegacyFormContext.Provider>
  );
};

export const DSP_LegacyFormProviderMock = <
  T extends FormMachineService = FormMachineService,
>({
  children,
}: DSP_LegacyFormProviderMockProps<T>) => {
  const contextValues = useFormContext<T>();

  const renderedChildren = R.is(Function, children)
    ? children(contextValues)
    : children;

  return renderedChildren;
};
