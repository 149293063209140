import React from 'react';

import {AccreditationAuditorPublic} from 'typeDefs/types';

import {
  DSP_EntityLoaderProvider,
  useEntityLoaderContext,
} from '../EntityLoaderProvider';
import {DSP_EntityLoaderProviderProps} from '../types';

import {GetAccreditationAuditorPublicFindByIdEveryoneDocument} from './api/AuditorFindByIdEveryone.generated';

export const DSP_AuditorEntityLoaderProvider = ({
  entityId: id,
  ...props
}: DSP_EntityLoaderProviderProps) => (
  <DSP_EntityLoaderProvider<AccreditationAuditorPublic>
    query={GetAccreditationAuditorPublicFindByIdEveryoneDocument}
    variables={{id}}
    {...props}
  />
);

export const useAuditorEntityLoaderContext = () =>
  useEntityLoaderContext<AccreditationAuditorPublic>();

DSP_AuditorEntityLoaderProvider.displayName = 'DSP_AuditorEntityLoaderProvider';
