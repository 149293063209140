import {useCallback, useState} from 'react';

export function useClickableTimeDistance() {
  const [showFullDate, setShowFullDate] = useState(false);

  const onDateClicked = useCallback(
    () => setShowFullDate(prevState => !prevState),
    [],
  );

  return {
    showFullDate,
    onDateClicked,
  };
}
