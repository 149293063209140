import React from 'react';

import {
  DSL_Avatar,
  DSL_AvatarProps,
  DSL_Box,
  usePopover,
} from 'components/DesignSystem/Library';

import {
  DSP_RegulatorAccountEntityPopover,
  DSP_RegulatorAccountEntityPopoverProps,
} from '../RegulatorAccountEntityPopover';

export interface DSP_RegulatorAccountEntityAvatarViewProps
  extends Omit<DSP_RegulatorAccountEntityPopoverProps, 'open' | 'anchorEl'>,
    ReturnType<typeof usePopover<HTMLDivElement>>,
    Pick<DSL_AvatarProps, 'src'> {
  initials: string | undefined;
}

export const DSP_RegulatorAccountEntityAvatarView = ({
  isPopoverOpen,
  anchorRef,
  openPopover,
  closePopover,
  isLoading,
  src,
  initials,
  title,
  subTitle,
  website,
}: DSP_RegulatorAccountEntityAvatarViewProps) => {
  return (
    <DSL_Box
      ref={anchorRef}
      width={'fit-content'}
      onMouseEnter={openPopover}
      onMouseLeave={closePopover}
    >
      <DSP_RegulatorAccountEntityPopover
        anchorEl={anchorRef.current}
        open={isPopoverOpen}
        onMouseEnter={openPopover}
        onMouseLeave={closePopover}
        isLoading={isLoading}
        title={title}
        subTitle={subTitle}
        website={website}
      />
      <DSL_Avatar src={src} alt={title}>
        {initials}
      </DSL_Avatar>
    </DSL_Box>
  );
};

DSP_RegulatorAccountEntityAvatarView.displayName =
  'DSP_RegulatorAccountEntityAvatarView';
