import HomeIcon from '@mui/icons-material/Home';
import {
  BuiltInRoles,
  AuthorisationPolicy,
} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/changeAccount',
  component: lazy(
    () => import(/* webpackChunkName: "ChangeAccount" */ './ChangeAccount'),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Change Account',
  menuIcon: HomeIcon,
  authorisation: {
    apiKey: null,
    policies: [BuiltInRoles.EVERYONE] as AuthorisationPolicy[],
  },
  sections: [],
};
