import React from 'react';

import {DSL_PrintIcon} from '../../../Icons';
import {DSL_BaseIconButton, DSL_BaseIconButtonProps} from '../BaseIconButton';

export type DSL_PrintIconButtonProps = Omit<
  DSL_BaseIconButtonProps,
  'color' | 'children'
>;

export const DSL_PrintIconButton = ({
  size = 'large',
  ...props
}: DSL_PrintIconButtonProps): JSX.Element => {
  return (
    <DSL_BaseIconButton aria-label="print" size={size} {...props}>
      <DSL_PrintIcon fontSize={size} />
    </DSL_BaseIconButton>
  );
};

DSL_PrintIconButton.displayName = 'DSL_PrintIconButton';

export {
  //
  /** @deprecated use DSL_PrintIconButtonProps instead*/
  DSL_PrintIconButtonProps as PrintIconButtonProps, //
  /** @deprecated use DSL_PrintIconButton instead*/
  DSL_PrintIconButton as PrintIconButton,
};
