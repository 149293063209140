import {useMemo, useState} from 'react';

import {RoadSegmentsLengthProps} from 'containers-spatial/NetworkManagement/drawers/SelectedSegmentsDrawer/RoadSegmentsLength/RoadSegmentsLength.Connector';

import {useCurrentBreakpoint} from 'components/DesignSystem/Library';

import {SegmentAccessType} from '../../Layout/Segments';

import {getFilteredRoads} from './getFilteredRoads';
import {
  DSP_FilterByRoadsOrAccessType,
  DSP_FilterByAccessType,
  DSP_Road,
  DSP_SelectedSegmentsDrawerContext,
  DSP_Segment,
} from './types';
import {useSelectedSegmentsMinimizeDrawer} from './useSelectedSegmentsMinimizeDrawer';

export interface SegmentChangeOption {
  label: string;
  subLabel?: string;
  onClick?: () => void;
  options?: SegmentChangeOption[];
  href?: string;
  to?: string;
  value?: SegmentAccessType;
  radioColor?: React.CSSProperties['color'];
  isClearUpdates?: boolean;
  isRevertUpdate?: boolean;
  noChangesAllowed?: boolean;
  hasConflict?: boolean;
  actions?: {
    label: string;
    onClick: () => void;
  }[];
}

export interface UseDspSelectedSegmentsDrawerArgs {
  roads: DSP_Road[];
  activeSegmentId?: number;
  selectSegment: (id: number) => void;
  getSegmentsByIds: (segmentIds?: number[]) => DSP_Segment[];
  RenderRoadSegmentsLengthComponent?: React.ComponentType<RoadSegmentsLengthProps>;
  onSegmentHover: (segmentIds: number[]) => void;
  onSegmentHoverOut: () => void;
  onBack: () => void;
  onChange: (segmentId: number) => void;
  options: SegmentChangeOption[];
}

export const useDspSelectedSegmentsDrawer = ({
  roads,
  selectSegment,
  activeSegmentId,
  getSegmentsByIds,
  RenderRoadSegmentsLengthComponent,
  onSegmentHover,
  onSegmentHoverOut,
  onBack,
  onChange,
  options,
}: UseDspSelectedSegmentsDrawerArgs): DSP_SelectedSegmentsDrawerContext => {
  const {isMinimized, toggleMinimizeDrawer} =
    useSelectedSegmentsMinimizeDrawer();
  const maxNumberOfSegmentsForSingleList = 100;
  const currentBreakpoint = useCurrentBreakpoint();

  const [selectedRoadId, setSelectedRoadId] = useState<number | undefined>();

  const [filterByRoadsOrAccessTypeValue, setFilterByRoadsOrAccessTypeValue] =
    useState<DSP_FilterByRoadsOrAccessType>(
      DSP_FilterByRoadsOrAccessType.ROADS,
    );

  const [filterByAccessTypeValue, setFilterByAccessTypeValue] =
    useState<DSP_FilterByAccessType>();

  const hasSelectedRoadId = selectedRoadId !== undefined;
  const hasFilterByAccessType = filterByAccessTypeValue !== undefined;
  const isViewByRoads = filterByRoadsOrAccessTypeValue === 'roads';
  const isViewportSmall = ['xs', 'sm'].includes(currentBreakpoint);

  const filteredRoads = useMemo(() => {
    return getFilteredRoads({
      roads,
      filterByAccessTypeValue,
      getSegmentsByIds,
    });
  }, [roads, filterByAccessTypeValue, getSegmentsByIds]);

  const selectedSegmentIds = roads.flatMap(road => road.segmentIds);
  const showSingleListView =
    maxNumberOfSegmentsForSingleList >= (selectedSegmentIds?.length ?? 0);

  const resetToDefaults = () => {
    setSelectedRoadId(undefined);
    setFilterByAccessTypeValue(undefined);
    setFilterByRoadsOrAccessTypeValue(DSP_FilterByRoadsOrAccessType.ROADS);
  };

  return {
    isMinimized,
    resetToDefaults,
    toggleMinimizeDrawer: () => {
      toggleMinimizeDrawer();
      resetToDefaults();
      onBack();
    },
    showSingleListView,
    filterByRoadsOrAccessTypeValue,
    filterByAccessTypeValue,
    hasSelectedRoadId,
    currentBreakpoint,
    isViewportSmall,
    filteredRoads,
    isViewByRoads,
    hasFilterByAccessType,
    roads,
    selectedRoadId,
    activeSegmentId,
    getSegmentsByIds,
    setSelectedRoadId,
    setFilterByAccessTypeValue,
    setFilterByRoadsOrAccessTypeValue,
    selectSegment,
    RenderRoadSegmentsLengthComponent,
    onSegmentHover,
    onSegmentHoverOut,
    onBack,
    onChange,
    options,
  };
};
