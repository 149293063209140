import React from 'react';

import {ApolloQueryResult} from '@apollo/client';
import {
  NetworksSearchDocument,
  NetworksSearchQuery,
} from 'components/DesignSystem/Portal/Forms/NetworkSearchRelationField/query/NetworkSearch.generated';
import * as R from 'ramda';

import {
  DSP_SearchRelationField,
  DSP_SearchRelationFieldProps,
} from '../SearchRelationField';

export type DSP_NetworkSearchRelationFieldProps = Omit<
  DSP_SearchRelationFieldProps,
  'query' | 'queryVariables'
>;

export const DSP_NetworkSearchRelationField = ({
  fieldRef,
  fieldRelationRef,
  span,
}: Omit<DSP_NetworkSearchRelationFieldProps, 'query'>): JSX.Element => {
  return (
    <DSP_SearchRelationField
      fieldRef={fieldRef}
      fieldRelationRef={fieldRelationRef}
      query={NetworksSearchDocument}
      queryVariables={{showHidden: true}}
      span={span}
      getOptionLabel={(searchResult): string =>
        searchResult?.networkDisplayName
      }
      clientSideFilter={(result: ApolloQueryResult<NetworksSearchQuery>) => {
        return {
          ...result,
          data: {
            networksSearch: result.data?.networksSearch?.map(network =>
              R.assoc('id', network?.networkId, network),
            ),
          },
        };
      }}
    />
  );
};
