import React, {ReactNode} from 'react';

import {AdapterLuxon as DateAdapter} from '@mui/x-date-pickers-pro/AdapterLuxon';

import {DSL_LocalizationProvider} from 'components/DesignSystem/Library';

export interface DSP_LocalizationProviderProps {
  children?: ReactNode;
}

export const DSP_LocalizationProvider = ({
  children,
}: DSP_LocalizationProviderProps) => {
  return (
    <DSL_LocalizationProvider dateAdapter={DateAdapter} adapterLocale={'en-gb'}>
      {children}
    </DSL_LocalizationProvider>
  );
};
