import React from 'react';

import {DSL_RestrictedVisualProps} from '../../../types';
import {DSL_BaseStack, DSL_BaseStackProps} from '../BaseStack';

export type DSL_HorizontalStackProps = Omit<
  DSL_BaseStackProps,
  DSL_RestrictedVisualProps | 'direction' | 'alignItems'
>;

export const DSL_HorizontalStack = ({
  spacing = 1,
  divider = <span>{'\u2022'}</span>,
  ...props
}: DSL_HorizontalStackProps) => {
  return (
    <DSL_BaseStack
      direction={'row'}
      alignItems={'center'}
      spacing={spacing}
      divider={divider}
      {...props}
    />
  );
};

DSL_HorizontalStack.displayName = 'DSL_HorizontalStack';

export {
  //
  /** @deprecated use DSL_HorizontalStackProps instead*/
  DSL_HorizontalStackProps as HorizontalStackProps, //
  /** @deprecated use DSL_HorizontalStack instead*/
  DSL_HorizontalStack as HorizontalStack,
};
