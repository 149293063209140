import {AuthorisationAccountTypes} from '@regulatory-platform/common-utils/dist';

const getAccountTypeName = (
  authorisationAccountType: AuthorisationAccountTypes,
) => {
  const DSP_AccountTypeName = {
    [AuthorisationAccountTypes.CUSTOMER]: 'Customer Account',
    [AuthorisationAccountTypes.OPERATIONS]: 'Regulator',
    [AuthorisationAccountTypes.PARTNER]: 'Road Manager',
    [AuthorisationAccountTypes.PROVIDER]: 'Service Provider',
  };
  return DSP_AccountTypeName[authorisationAccountType];
};
export default getAccountTypeName;
