import {BrowserHistory, createBrowserHistory} from 'history';
import {fromEventPattern, Observable} from 'rxjs';

export type BrowserHistoryState = BrowserHistory;

export type BrowserHistoryObservable = Observable<BrowserHistoryState>;

const history = createBrowserHistory() as BrowserHistoryState;

const history$ = fromEventPattern<BrowserHistoryState>(history.listen);

export default {history$, history};
