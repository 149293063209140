import {lazy} from 'react';

export default {
  path: '/sandbox',
  component: lazy(
    () => import(/* webpackChunkName: "AccredSandbox" */ './AccredSandbox'),
  ),
  menuTitle: 'Sandbox',
  visibleInMenu: false,
  hideInProduction: true,
};
