import {AuthorisationAccountTypes} from '@regulatory-platform/common-utils/dist';
import {AccreditationApplicationWorkflowState} from 'containers-accreditation/Shared/types';

import {DSP_NotificationRendererComponent} from '../../types';

import {DSP_AccreditationApplicationCustomerNotificationCard} from './Customer/AccreditationApplicationCustomerNotificationCard.Connector';
import {DSP_AccreditationApplicationOperationsNotificationCard} from './Operations/AccreditationApplicationOperationsNotificationCard.Connector';
import {DSP_AccreditationApplicationNotificationItem} from './types';

export const applicationWorkflowStateDescriptionMap: {
  [DSP_ApplicationState in AccreditationApplicationWorkflowState]?:
    | string
    | undefined;
} = {
  [AccreditationApplicationWorkflowState.ASSESSMENT_STATE]:
    'started reviewing the accreditation application',
  [AccreditationApplicationWorkflowState.APPROVED_STATE]:
    'approved the accreditation application',
  [AccreditationApplicationWorkflowState.SUBMITTED_STATE]:
    'created a new accreditation application',
  [AccreditationApplicationWorkflowState.WITHDRAWN_STATE]:
    'Accreditation application is closed/withdrawn',
};

export const accreditationApplicationAccountTypeNotificationRendererMap: {
  [DSP_Type in AuthorisationAccountTypes]?:
    | DSP_NotificationRendererComponent<DSP_AccreditationApplicationNotificationItem>
    | undefined;
} = {
  [AuthorisationAccountTypes.CUSTOMER]:
    DSP_AccreditationApplicationCustomerNotificationCard,
  [AuthorisationAccountTypes.OPERATIONS]:
    DSP_AccreditationApplicationOperationsNotificationCard,
};
