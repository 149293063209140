import React, {ReactNode} from 'react';

import {DSP_BaseAccreditationActionNotificationCardView} from './BaseAccreditationActionNotificationCard.View';
import {
  useBaseAccreditationActionNotificationCard,
  DSP_UseBaseAccreditationActionNotificationCardProps,
} from './useBaseAccreditationActionNotificationCard';

export interface DSP_BaseAccreditationActionNotificationCardProps
  extends DSP_UseBaseAccreditationActionNotificationCardProps {
  avatar: ReactNode | undefined;
}

export const DSP_BaseAccreditationActionNotificationCard = ({
  avatar,
  ...props
}: DSP_BaseAccreditationActionNotificationCardProps) => {
  const viewProps = useBaseAccreditationActionNotificationCard(props);

  return (
    <DSP_BaseAccreditationActionNotificationCardView
      avatar={avatar}
      {...viewProps}
    />
  );
};

DSP_BaseAccreditationActionNotificationCard.displayName =
  'DSP_BaseAccreditationActionNotificationCard';
