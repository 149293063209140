import React from 'react';

import {
  GridItem,
  UseGridItemStyles,
  DSL_SearchMultiSelectAutocompleteInputProps,
  DSL_SearchMultiSelectAutocompleteInput,
} from 'components/DesignSystem/Library';

import {DSP_FieldProps} from '../types';

import {
  DSP_UseSearchMultiSelectRelationFieldArgs,
  useSearchMultiSelectRelationField,
} from './useSearchMultiSelectRelationField';

export interface DSP_SearchMultiSelectRelationFieldProps
  extends DSP_UseSearchMultiSelectRelationFieldArgs,
    Omit<DSP_FieldProps, 'fieldRef'>,
    UseGridItemStyles,
    Omit<
      DSL_SearchMultiSelectAutocompleteInputProps,
      'value' | 'options' | 'fieldRef'
    >,
    Partial<Pick<DSL_SearchMultiSelectAutocompleteInputProps, 'options'>> {}

export const DSP_SearchMultiSelectRelationField = ({
  span,
  ...props
}: DSP_SearchMultiSelectRelationFieldProps) => {
  const fieldProps = useSearchMultiSelectRelationField(props);

  return (
    <GridItem span={span}>
      <DSL_SearchMultiSelectAutocompleteInput
        {...fieldProps}
        filterSelectedOptions
        disableCloseOnSelect={false}
      />
    </GridItem>
  );
};
