import {
  denormaliseSchema,
  buildRecordSchema,
  getDefaultRecord,
  JSONSchemaRecord,
  ObjectType,
} from '@regulatory-platform/common-utils';
import * as R from 'ramda';
import {APIMethodNames} from './authChecks';

export default function getFormRecord(
  schema: JSONSchemaRecord,
  protectedFieldsMethodName?: APIMethodNames,
): (record?: ObjectType) => ObjectType {
  return record => {
    const denormalisedSchema = denormaliseSchema(schema)(schema);
    const builtSchema = buildRecordSchema(record)(denormalisedSchema);
    let omitFields = [] as string[];
    if (!R.isNil(protectedFieldsMethodName)) {
      omitFields = R.defaultTo(
        [],
        schema['x-protectedFields']?.[protectedFieldsMethodName],
      ) as string[];
    }
    return R.omit(
      omitFields,
      getDefaultRecord(
        R.defaultTo(
          {
            id: -1,
          },
          record,
        ),
      )(builtSchema),
    );
  };
}
