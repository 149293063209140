import {useTheme} from '@mui/material';

import {DSL_BaseDrawerProps} from '../BaseDrawer';
import {DSL_UseDrawerArgs, useDrawer} from '../useDrawer';

export type DSL_UseModalDrawerArgs = Omit<DSL_UseDrawerArgs, 'variant'>;

export function useModalDrawer(args: DSL_UseDrawerArgs): DSL_BaseDrawerProps {
  const theme = useTheme();
  const {zIndexModifier, ...props} = useDrawer({
    ...args,
    variant: 'modal',
  });

  const overrideProps: Partial<DSL_BaseDrawerProps> = {
    top: 0,
    height: '100vh',
  };

  return {
    ...props,
    ...overrideProps,
    muiVariant: 'temporary' as const,
    zIndex: theme.zIndex.drawerModal + zIndexModifier,
  };
}
