import React, {ReactNode} from 'react';

import {DSL_Avatar} from '../../Avatar';
import {DSL_CardContent} from '../../Layout/Card/CardContent';
import {DSL_OutlinedCard} from '../../Layout/Card/OutlinedCard';
import {DSL_FlexColumn} from '../../Layout/FlexColumn';
import {DSL_FlexRow} from '../../Layout/FlexRow';
import {DSL_EntitySubtitle} from '../EntitySubtitle';
import {DSL_EntityTitle} from '../EntityTitle';

export interface DSL_EntityCardProps {
  isLoading?: boolean;
  title: string;
  subTitle?: string;
  imgUrl?: string;
  children?: ReactNode;
}

export const DSL_EntityCard = ({
  isLoading = false,
  imgUrl,
  title,
  subTitle,
  children,
}: DSL_EntityCardProps) => {
  return (
    <DSL_OutlinedCard bgcolor={'background.default'}>
      <DSL_CardContent padding={'SM'}>
        <DSL_FlexRow gap={1}>
          <DSL_Avatar src={imgUrl} alt={title} />
          <DSL_FlexColumn>
            <DSL_EntityTitle isLoading={isLoading}>{title}</DSL_EntityTitle>
            {subTitle && (
              <DSL_EntitySubtitle isLoading={isLoading}>
                {subTitle}
              </DSL_EntitySubtitle>
            )}
            {children}
          </DSL_FlexColumn>
        </DSL_FlexRow>
      </DSL_CardContent>
    </DSL_OutlinedCard>
  );
};

DSL_EntityCard.displayName = 'DSL_EntityCard';
