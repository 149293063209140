import Layers from '@mui/icons-material/Layers';
import {
  AuthorisationPolicy,
  AuthorisationDomains,
} from '@regulatory-platform/common-utils';
import {lazy} from 'react';
export default {
  path: '/private',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "VehicleNetworkMap" */ './VehicleNetworkMap.Connector'
      ),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'Public & Draft Maps',
  menuDescription: 'Public and draft heavy vehicle network maps',
  menuIcon: Layers,
  parent: true,
  authorisation: {
    apiKey: null,
    policies: [
      {role: 'operationsSpatialRead'},
      {role: 'partnerSpatialRead'},
      {domain: AuthorisationDomains.ADF},
    ] as AuthorisationPolicy[],
  },
  category: 'Map',
  sections: [],
};
