import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/pnas/accessPublicNoticeOfAmendment/:id',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "ViewAccessPublicNoticeOfAmendment" */ './ViewAccessPublicNoticeOfAmendment.Connector'
      ),
  ),
  menuTitle: 'Public Notice of Amendment',
  visibleInMenu: false,
  hideInProduction: false,
  menuIcon: LibraryAddCheckIcon,
  authorisation: {
    policies: [
      {role: 'partnerSpatialRead'},
      {role: 'operationsSpatialRead'},
    ] as AuthorisationPolicy[],
  },
  sections: [],
};
