import React from 'react';

import {
  DSL_BaseCardActionArea,
  DSL_BaseCardActionAreaProps,
} from '../BaseCardActionArea';

export interface DSL_BaseLinkCardActionAreaProps
  extends Omit<DSL_BaseCardActionAreaProps, 'onClick'> {
  href: NonNullable<DSL_BaseCardActionAreaProps['href']>;
  LinkComponent: NonNullable<DSL_BaseCardActionAreaProps>['LinkComponent'];
}

export const DSL_BaseLinkCardActionArea = (
  props: DSL_BaseLinkCardActionAreaProps,
) => {
  return <DSL_BaseCardActionArea {...props} />;
};

DSL_BaseLinkCardActionArea.displayName = 'DSL_BaseLinkCardActionArea';

export {
  //
  /** @deprecated use DSL_BaseLinkCardActionAreaProps instead*/
  DSL_BaseLinkCardActionAreaProps as BaseLinkCardActionAreaProps, //
  /** @deprecated use DSL_BaseLinkCardActionArea instead*/
  DSL_BaseLinkCardActionArea as BaseLinkCardActionArea,
};
