import {useMemo} from 'react';

import * as R from 'ramda';

import {DSL_GridItemProps, DSL_GridBreakpoints} from '../Grid';

export type DSL_UseGridItemStyles = Pick<
  DSL_GridItemProps,
  'span' | 'xs' | 'sm' | 'md' | 'lg' | 'xl'
>;

export {
  //

  /** @deprecated use DSL_UseGridItemStyles instead*/
  DSL_UseGridItemStyles as UseGridItemStyles,
};

export function useGridItemStyles({span, ...props}: DSL_UseGridItemStyles) {
  // @TODO: see if we can make the last item in a row stretch full width
  // @TODO: experiment with combined fixed and variable width fields
  // we use the context here so that the grid styles are only generated if the item is wrapped in a grid container
  return useMemo(() => {
    const breakPoints = R.is(Number, span)
      ? {xs: span}
      : R.pick(['xs', 'sm', 'md', 'lg', 'xl'], props);
    const gridColumnStyles = Object.keys(breakPoints).reduce(
      (accum, breakpoint) => {
        const value = (breakPoints as DSL_GridBreakpoints)[
          breakpoint as keyof DSL_GridBreakpoints
        ];
        return value
          ? {
              ...accum,
              [breakpoint]: `span ${value}`,
            }
          : accum;
      },
      {},
    );
    return Object.keys(gridColumnStyles).length > 0
      ? {
          width: '100%',
          gridColumn: gridColumnStyles,
        }
      : {};
  }, [span, props]);
}
