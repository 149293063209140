import React from 'react';

import {DSL_Body1} from 'components/DesignSystem/Library/Typography/Body1';

export interface DSL_FormFieldsetHeaderProps {
  children: React.ReactNode;
}

export const DSL_FormFieldsetHeader = ({
  children,
}: DSL_FormFieldsetHeaderProps) => {
  return <DSL_Body1>{children}</DSL_Body1>;
};

DSL_FormFieldsetHeader.displayName = 'DSL_FormFieldsetHeader';

export {
  //
  /** @deprecated use DSL_FormFieldsetHeaderProps instead*/
  DSL_FormFieldsetHeaderProps as FormFieldsetHeaderProps, //
  /** @deprecated use DSL_FormFieldsetHeader instead*/
  DSL_FormFieldsetHeader as FormFieldsetHeader,
};
