import React from 'react';
import {Navigate} from 'react-router-dom';
import {useSubmissionRedirect} from './useSubmissionRedirect';

interface SubmissionRedirectProps {
  pagePath?: string;
}

export const SubmissionRedirect = ({pagePath}: SubmissionRedirectProps) => {
  const {shouldNavigate, to} = useSubmissionRedirect(pagePath);
  if (shouldNavigate) {
    return <Navigate to={to} />;
  }

  return null;
};
