import {
  CommonValidator,
  insertNewArrayItemIntoSchema,
  JSONSchemaRecord,
  schemaJsonPointerResolver,
  updateFieldPropOnSubSchema,
  validatorExec,
} from '@regulatory-platform/common-utils';
import * as R from 'ramda';
import {FormEventObject, FormMachineContext} from './types';
import {v4} from 'uuid';
import {ObjectType} from '@regulatory-platform/common-utils/dist/types';

export default function onInsertNewArrayItemIntoSchema<T extends ObjectType>(
  context: FormMachineContext<T>,
  event: FormEventObject,
  validator: CommonValidator,
): FormMachineContext<T> {
  const {fieldRef, index, record: fieldRecord} = event;
  const {record: origRecord, shared} = context;

  let {schema: origSchema} = context;

  origSchema = updateFieldPropOnSubSchema(
    origSchema,
    fieldRef + '/-1',
    'x-valid/touched',
    false,
    true,
  )(origSchema);
  origSchema = updateFieldPropOnSubSchema(
    origSchema,
    fieldRef + '/-1',
    'x-origValue',
    undefined,
    true,
  )(origSchema);
  const [record, schema] = insertNewArrayItemIntoSchema(
    fieldRef,
    fieldRecord,
    index,
    v4,
  )(origRecord, origSchema);

  const result = validatorExec(
    {
      record,
      origRecord,
      schema: schema,
      isClient: true,
      methodName: context.methodName,
      userProfile: context.authorisations?.userProfile,
      shared: R.mergeRight(R.defaultTo({}, shared), {fieldRef}),
    },
    validator,
  );

  let validatedSchema = result?.validatedSchema as JSONSchemaRecord;

  const ignoreValidationOnNewItemsRefs: string[] =
    (context?.shared?.ignoreValidationOnNewItemsRefs as string[]) || [];
  const setNewToInvalidIfTouched =
    !ignoreValidationOnNewItemsRefs.includes(fieldRef);

  //set validation flags to true for new record if original array was valid
  if (
    setNewToInvalidIfTouched &&
    schemaJsonPointerResolver<boolean | undefined>(
      fieldRef,
      'x-valid/touched',
    )(origSchema) === true
  ) {
    validatedSchema = updateFieldPropOnSubSchema(
      validatedSchema,
      fieldRef,
      'x-valid/touched',
      true,
      false,
    )(validatedSchema);
  }

  const nextContext = context;
  if (context.record !== result?.processedRecord) {
    nextContext.record = result?.processedRecord as T;
  }
  if (!R.isNil(result) && context.schema !== validatedSchema) {
    nextContext.schema = validatedSchema;
  }
  if (!R.isNil(context.props) && !R.isNil(context.props.apiError)) {
    nextContext.props.apiError = undefined;
  }
  return nextContext;
}
