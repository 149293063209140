import {useCallback, useMemo} from 'react';

import {SegmentAccessType} from 'components/DesignSystem/Portal/Layout/Segments';

import {dsp_calculateRoadsByAccessType} from '../calculateRoadsByAccessType';
import {useDspSelectedSegmentsDrawerContext} from '../SelectedSegmentsDrawer.Provider';

interface UseDspRoadsByAccessTypeListArgs {
  onRemoveSegments?: (segmentIds: number[]) => void;
}

export const useDspRoadsByAccessTypeList = ({
  onRemoveSegments,
}: UseDspRoadsByAccessTypeListArgs) => {
  const {
    setFilterByAccessTypeValue,
    currentBreakpoint,
    hasSelectedRoadId,
    isViewByRoads,
    hasFilterByAccessType,
    roads,
    getSegmentsByIds,
    onSegmentHover,
    onSegmentHoverOut,
  } = useDspSelectedSegmentsDrawerContext();

  const {roadsByAccessType, segmentsByAccessType} = useMemo(() => {
    if (hasSelectedRoadId || isViewByRoads || hasFilterByAccessType) {
      return {
        roadsByAccessType: [],
        segmentsByAccessType: null,
      };
    }
    return dsp_calculateRoadsByAccessType(roads, getSegmentsByIds);
  }, [
    getSegmentsByIds,
    hasFilterByAccessType,
    hasSelectedRoadId,
    isViewByRoads,
    roads,
  ]);

  const handleRoadsByAccessTypeClick = (
    segmentAccessType: SegmentAccessType,
  ): void => {
    setFilterByAccessTypeValue(segmentAccessType);
  };

  const onRemoveCircleIconButtonClick = useCallback(
    (segmentAccessType: SegmentAccessType) => {
      const segmentIds = segmentsByAccessType
        ? Array.from(segmentsByAccessType[segmentAccessType])
        : [];

      onRemoveSegments?.(segmentIds);
    },
    [segmentsByAccessType, onRemoveSegments],
  );

  const onRoadsByAccessTypeHover = useCallback(
    (segmentAccessType: SegmentAccessType) => {
      const segmentIds = segmentsByAccessType
        ? Array.from(segmentsByAccessType[segmentAccessType])
        : [];

      onSegmentHover?.(segmentIds);
    },
    [segmentsByAccessType, onSegmentHover],
  );
  return {
    roadsByAccessType: roadsByAccessType ?? [],
    onClick: handleRoadsByAccessTypeClick,
    currentBreakpoint,
    onRemoveCircleIconButtonClick,
    onRoadsByAccessTypeHover,
    onSegmentHoverOut,
  };
};
