import {
  SubscriptionWithMessageListeners,
  UnsubscribeRequest,
} from 'utils/machines/websocketMachine';
import * as R from 'ramda';

/**
 * removeSubscription removes any message listeners from the provided (assumed to be current) subscriptions, that are mapped to the supplied subscription key
 *
 * For any subscriptions that have no message listeners following the removal, the entire subscription will be removed
 *
 * @param currentSubscriptions
 * @param toRemove
 */
export function removeSubscription(
  currentSubscriptions:
    | Map<string, SubscriptionWithMessageListeners>
    | undefined,
  toRemove: UnsubscribeRequest,
): Map<string, SubscriptionWithMessageListeners> {
  if (!currentSubscriptions) {
    currentSubscriptions = new Map<string, SubscriptionWithMessageListeners>();
  }

  // clone, so that we do not mutate any state
  const clonedSubscriptions = R.clone(currentSubscriptions);

  clonedSubscriptions.forEach((subscriptionWithMessageListeners, key) => {
    subscriptionWithMessageListeners.messageListeners.delete(
      toRemove.subscriptionKey,
    );

    if (subscriptionWithMessageListeners.messageListeners.size === 0) {
      clonedSubscriptions.delete(key);
    }
  });

  return clonedSubscriptions;
}
