import React from 'react';

import {
  MultiLineTextInput,
  MultilineTextInputProps,
} from 'components/DesignSystem/Library';

import {DSP_FieldProps} from '../types';

import {
  useMultiLineTextField,
  DSP_UseMultiLineTextFieldProps,
} from './useMultiLineTextField';

export type DSP_MultiLineTextFieldProps = MultilineTextInputProps &
  DSP_UseMultiLineTextFieldProps &
  DSP_FieldProps;

export const DSP_MultiLineTextField = (props: DSP_MultiLineTextFieldProps) => {
  const fieldProps = useMultiLineTextField(props);

  return <MultiLineTextInput {...fieldProps} />;
};

export {
  //
  /** @deprecated use DSP_MultiLineTextFieldProps instead*/
  DSP_MultiLineTextFieldProps as MultiLineTextFieldProps, //
  /** @deprecated use DSP_MultiLineTextField instead*/
  DSP_MultiLineTextField as MultiLineTextField,
};
