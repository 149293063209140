import {lazy} from 'react';
import {RouteConfig} from 'app/router/types';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';
import Assignment from '@mui/icons-material/Assignment';
import ManageAccreditationApplication from './Application';

const ManageAccreditationApplications = {
  path: '/manage/applications',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "ManageAccreditationApplications" */ './ManageAccreditationApplications'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: true,
  menuTitle: 'Applications',
  menuIcon: Assignment,
  authorisation: {
    apiKey: 'AccreditationApplicationWithRelations.findOperations',
    policies: [{role: 'operationsAccreditationRead'}] as AuthorisationPolicy[],
  },
  sections: [],
} as RouteConfig;
export default [
  ManageAccreditationApplications,
  ManageAccreditationApplication,
];
