import Layers from '@mui/icons-material/Layers';
import {lazy} from 'react';
import {
  AuthorisationPolicy,
  BuiltInRoles,
} from '@regulatory-platform/common-utils';

export default {
  path: '',
  component: lazy(
    () => import(/* webpackChunkName: "SSORequest" */ './SSORequest'),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'SSO',
  menuIcon: Layers,
  parent: true,
  sections: [],
  authorisation: {
    apiKey: null,
    policies: [BuiltInRoles.AUTHENTICATED] as AuthorisationPolicy[],
  },
};
