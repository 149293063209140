import React, {CSSProperties} from 'react';

import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from '@mui/material';
import {CircularProgress} from '@mui/material';

import {useGridItemStyles, DSL_UseGridItemStyles} from '../../';
import {DSL_RestrictedVisualProps} from '../../types';

export type DSL_BaseButtonProps = Omit<
  MuiButtonProps,
  DSL_RestrictedVisualProps
> &
  DSL_UseGridItemStyles & {
    loading?: boolean;
    autoWidth?: boolean;
    noUppercaseTransform?: boolean;
    height?: CSSProperties['height'];
  };

// eslint-disable- react/prop-types
export const DSL_BaseButton = React.forwardRef<
  HTMLButtonElement,
  DSL_BaseButtonProps
>((props, ref) => {
  const {
    height,
    // eslint-disable-next-line react/prop-types
    startIcon,
    // eslint-disable-next-line react/prop-types
    loading,
    // eslint-disable-next-line react/prop-types
    span,
    // eslint-disable-next-line react/prop-types
    xs,
    // eslint-disable-next-line react/prop-types
    sm,
    // eslint-disable-next-line react/prop-types
    md,
    // eslint-disable-next-line react/prop-types
    lg,
    // eslint-disable-next-line react/prop-types
    xl,
    // eslint-disable-next-line react/prop-types
    autoWidth,
    // eslint-disable-next-line react/prop-types
    fullWidth,
    noUppercaseTransform,
    ...rest
  } = props;

  const sx = useGridItemStyles({span, xs, sm, md, lg, xl});

  const actualStartIcon = loading ? (
    <CircularProgress color="inherit" size={'1rem'} />
  ) : (
    startIcon
  );
  return (
    <MuiButton
      ref={ref}
      sx={{
        ...sx,
        textTransform: noUppercaseTransform ? 'none' : undefined,
        width: fullWidth ? '100%' : autoWidth ? 'auto' : undefined,
        height,
        '&:visited': {
          color:
            rest.href && rest.variant === 'contained'
              ? 'primary.contrastText'
              : undefined,
        },
      }}
      size={'large'}
      startIcon={actualStartIcon}
      {...rest}
    />
  );
});

DSL_BaseButton.displayName = 'DSL_BaseButton';

export {
  //
  /** @deprecated use DSL_BaseButtonProps instead*/
  DSL_BaseButtonProps as BaseButtonProps, //
  /** @deprecated use DSL_BaseButton instead*/
  DSL_BaseButton as BaseButton,
};
