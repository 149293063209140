import {
  DSL_Dialog,
  DSL_DialogTitle,
  DSL_DialogContent,
  DSL_DialogActions,
  DSL_ButtonBarGroup,
  DSL_TextButton,
  DSL_ContainedButton,
} from 'components/DesignSystem/Library';
import React from 'react';

export type RouteBlockerViewProps = {
  title?: string | React.ReactElement | React.ReactNode;
  open: boolean;
  onClose: () => void;
  onLeave: () => void;
  onStay: () => void;
};

export const RouteBlockerView = ({
  title,
  open,
  onClose,
  onStay,
  onLeave,
}: RouteBlockerViewProps) => {
  return (
    <DSL_Dialog
      open={open}
      onClose={onClose}
      aria-labelledby={title + '-dialog-title'}
    >
      <DSL_DialogTitle
        id={title + '-dialog-title'}
        onClose={onClose}
        title={'Unsaved changes'}
      />
      <DSL_DialogContent>
        Do you want to leave this page without saving changes?
      </DSL_DialogContent>
      <DSL_DialogActions>
        <DSL_ButtonBarGroup>
          <DSL_TextButton onClick={onStay}>Stay on page</DSL_TextButton>
          <DSL_ContainedButton onClick={onLeave}>
            Leave page
          </DSL_ContainedButton>
        </DSL_ButtonBarGroup>
      </DSL_DialogActions>
    </DSL_Dialog>
  );
};
