import React from 'react';

import {
  DSL_BaseIconInfo,
  DSL_BaseIconInfoProps,
} from 'components/DesignSystem/Library/IconInfo/BaseIconInfo';
import {DSL_FilePresentIcon} from 'components/DesignSystem/Library/Icons';

export type DSL_FilePresentIconInfoProps = Omit<DSL_BaseIconInfoProps, 'icon'>;

export const DSL_FilePresentIconInfo = (
  props: DSL_FilePresentIconInfoProps,
) => {
  return <DSL_BaseIconInfo icon={<DSL_FilePresentIcon />} {...props} />;
};

DSL_FilePresentIconInfo.displayName = 'DSL_FilePresentIconInfo';

export {
  //
  /** @deprecated use DSL_FilePresentIconInfoProps instead*/
  DSL_FilePresentIconInfoProps as FilePresentIconInfoProps, //
  /** @deprecated use DSL_FilePresentIconInfo instead*/
  DSL_FilePresentIconInfo as FilePresentIconInfo,
};
