import {usePopover} from 'components/DesignSystem/Library';

import {usePartnerAccountEntityLoaderContext} from '../PartnerAccountEntityLoaderProvider';
import {usePartnerAccountEntityMapper} from '../usePartnerAccountEntityMapper';

export function usePartnerAccountEntityName() {
  const popoverProps = usePopover<HTMLDivElement>();
  const {entity: partnerAccount, isLoading} =
    usePartnerAccountEntityLoaderContext();
  const {src, ...userProps} = usePartnerAccountEntityMapper({
    partnerAccount,
  });

  return {
    ...popoverProps,
    ...userProps,
    isLoading,
  };
}
