export enum AccreditationApplicationWorkflowState {
  DRAFTING_STATE = 1,
  SUBMITTED_STATE = 2,
  ASSIGNED_STATE = 3,
  ASSESSMENT_STATE = 4,
  INFORMATION_REQUESTED_STATE = 5,
  APPROVED_STATE = 6,
  EXPIRED_STATE = 7,
  CANCELLED_STATE = 8,
  WITHDRAWN_STATE = 9,
  ARCHIVED_STATE = 10,
}

export enum AccreditationWorkflowState {
  NO_APPLICATION_STATE = 1,
  DRAFT_STATE = 2,
  SUBMITTED_STATE = 3,
  ISSUED_STATE = 4,
  PROVISIONAL_STATE = 5,
  SUSPENDED_STATE = 6,
  PROVISIONAL_EXPIRED_STATE = 7,
  EXPIRED_STATE = 8,
  RETIRED_STATE = 9,
  REVOKED_STATE = 10,
}
export enum AccreditationModuleStatus {
  Current = 'Current',
  Expired = 'Expired',
  Cancelled = 'Cancelled',
  Suspended = 'Suspended',
}
export enum WaAccreditationStatusEnum {
  Draft = 'Draft',
  Submitted = 'Submitted',
  Issued = 'Accredited - Complied',
  Provisional = 'Accredited - Complied Provisional',
  ProvisionalExpired = 'Provisional Expired',
  Suspended = 'Not Accredited - Suspended',
  Expired = 'Not Accredited - Expired',
  Revoked = 'Not Accredited - Cancelled',
  Retired = 'Not Accredited - Retired',
}
export enum NhvrAccreditationStatusEnum {
  Draft = 'Draft',
  Submitted = 'Submitted',
  Issued = 'Current',
  ProvisionalExpired = 'Provisional Expired',
  Revoked = 'Cancelled by NHVR',
  Retired = 'Exited by Participant',
  Provisional = 'Provisional', //currently unused
  Suspended = 'Suspended', //currently unused
  Expired = 'Expired',
}
