import React, {ReactNode} from 'react';

import {
  DSL_Body2,
  DSL_Box,
  DSL_Subtitle1,
  DSL_HighlightedText,
} from 'components/DesignSystem/Library';

import {DSP_BaseVehicleCard} from '../BaseVehicleCard/BaseVehicleCard';
import {
  DSP_VehicleCardChips,
  DSP_VehicleCardChipsProps,
} from '../VehicleCardChips';

type DSP_BaseDisplayVehicleReferenceCardProps = {
  title: string;
  subheader?: ReactNode;
  vehicleComponentIds: number[];
  imageUrl?: string | null;
  onClick: (vehicleId: number) => void;
  highlightText?: string;
  vehicleId: number;
};

export type DSP_DisplayVehicleReferenceCardProps =
  DSP_BaseDisplayVehicleReferenceCardProps & DSP_VehicleCardChipsProps;

const DSP_DisplayVehicleReferenceCardInternal = ({
  vehicleComponentIds,
  imageUrl,
  tags,
  highlightText,
  title,
  subheader,
  onClick,
  vehicleId,
}: DSP_DisplayVehicleReferenceCardProps) => {
  return (
    <DSP_BaseVehicleCard
      onClick={() => onClick(vehicleId)}
      vehicleComponentIds={vehicleComponentIds}
      imageUrl={imageUrl}
    >
      <DSL_Box maxWidth={'100%'}>
        <DSL_Subtitle1 color={'text.primary'} bold>
          <DSL_HighlightedText value={title} highlightText={highlightText} />
        </DSL_Subtitle1>
        {subheader && (
          <DSL_Body2 color={'text.secondary'}>{subheader}</DSL_Body2>
        )}
        {tags && <DSP_VehicleCardChips tags={tags} />}
      </DSL_Box>
    </DSP_BaseVehicleCard>
  );
};

export const DSP_DisplayVehicleReferenceCard = React.memo(
  DSP_DisplayVehicleReferenceCardInternal,
);

DSP_DisplayVehicleReferenceCard.displayName = 'DSP_DisplayVehicleReferenceCard';

export {
  //
  /** @deprecated use DSP_DisplayVehicleReferenceCardProps instead*/
  DSP_DisplayVehicleReferenceCardProps as DisplayVehicleReferenceCardProps, //
  /** @deprecated use DSP_DisplayVehicleReferenceCard instead*/
  DSP_DisplayVehicleReferenceCard as DisplayVehicleReferenceCard,
};
