import React from 'react';

import {DSL_BaseAlert, DSL_BaseAlertProps} from '../BaseAlert';

export type DSL_InlineSuccessAlertProps = Omit<
  DSL_BaseAlertProps,
  'variant' | 'severity'
>;

export const DSL_InlineSuccessAlert = (props: DSL_InlineSuccessAlertProps) => {
  return <DSL_BaseAlert variant={'outlined'} severity="success" {...props} />;
};

DSL_InlineSuccessAlert.displayName = 'DSL_InlineSuccessAlert';

export {
  //
  /** @deprecated use DSL_InlineSuccessAlertProps instead*/
  DSL_InlineSuccessAlertProps as InlineSuccessAlertProps, //
  /** @deprecated use DSL_InlineSuccessAlert instead*/
  DSL_InlineSuccessAlert as InlineSuccessAlert,
};
