import {authenticatedContainers} from 'app/router/containerRegistry';
import {RouteConfig} from 'app/router/types';
import rootContainer from 'containers/index';
import * as R from 'ramda';
import {matchPath, useLocation} from 'react-router';

export const useCurrentRouteConfig = (): RouteConfig | undefined => {
  const location = useLocation();

  const containers = [rootContainer, ...authenticatedContainers];

  const isMatch = (route?: string) => {
    if (!route) return false;
    const path = {path: route};
    const match = matchPath(path, location.pathname);

    return match !== null;
  };

  const routes: RouteConfig[] =
    R.flatten(containers.map(container => container?.routes)) ?? [];

  const matchedRoute: RouteConfig | undefined = routes?.find(route =>
    containers.some(container => isMatch(container?.path + route?.path)),
  );

  if (matchedRoute) {
    return matchedRoute;
  }
};
