import React from 'react';

import {Drawer, DrawerProps, useTheme} from '@mui/material';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

export interface DSL_SidebarMenuDrawerProps
  extends Omit<DrawerProps, DSL_RestrictedVisualProps> {
  drawerWidth?: number;
}

export const DSL_SidebarMenuDrawer = ({
  drawerWidth = 320,
  ...restProps
}: DSL_SidebarMenuDrawerProps) => {
  const theme = useTheme();
  return (
    <Drawer
      {...restProps}
      sx={{
        flexShrink: 0,
        zIndex: theme.zIndex.sidebarMenu,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      anchor="left"
    />
  );
};

DSL_SidebarMenuDrawer.displayName = 'DSL_SidebarMenuDrawer';

export {
  //
  /** @deprecated use DSL_SidebarMenuDrawerProps instead*/
  DSL_SidebarMenuDrawerProps as SidebarMenuDrawerProps, //
  /** @deprecated use DSL_SidebarMenuDrawer instead*/
  DSL_SidebarMenuDrawer as SidebarMenuDrawer,
};
