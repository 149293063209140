import React from 'react';

import {DSL_ExternalLink} from '../../../../../Links/ExternalLink';
import {
  DSL_BaseLinkCardActionArea,
  DSL_BaseLinkCardActionAreaProps,
} from '../BaseLinkCardActionArea';

export type DSL_ExternalLinkCardActionAreaProps = Omit<
  DSL_BaseLinkCardActionAreaProps,
  'LinkComponent'
>;

export const DSL_ExternalLinkCardActionArea = (
  props: DSL_ExternalLinkCardActionAreaProps,
) => {
  return (
    <DSL_BaseLinkCardActionArea LinkComponent={DSL_ExternalLink} {...props} />
  );
};

DSL_ExternalLinkCardActionArea.displayName = 'DSL_ExternalLinkCardActionArea';

export {
  //
  /** @deprecated use DSL_ExternalLinkCardActionAreaProps instead*/
  DSL_ExternalLinkCardActionAreaProps as ExternalLinkCardActionAreaProps, //
  /** @deprecated use DSL_ExternalLinkCardActionArea instead*/
  DSL_ExternalLinkCardActionArea as ExternalLinkCardActionArea,
};
