import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';

export default {
  path: '/certifiers/create',
  component: lazy(
    () => import(/* webpackChunkName: "CreateCertifier" */ './CreateCertifier'),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Add PBS Certifier',
  menuIcon: LocalShippingOutlinedIcon,
  authorisation: {
    apiKey: 'PbsCertifierWithRelations.create',
    policies: [{role: 'operationsPBSUser'}] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'PBS Certifiers',
      to: '..',
    },
  ],
};
