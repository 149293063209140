import React from 'react';

import {
  Toolbar as MuiToolbar,
  ToolbarProps as MuiToolbarProps,
} from '@mui/material';

import {DSL_RestrictedStyleProps} from '../../types';

export type DSL_ToolbarProps = Omit<MuiToolbarProps, DSL_RestrictedStyleProps>;
export const DSL_Toolbar = (props: DSL_ToolbarProps) => {
  return <MuiToolbar {...props} />;
};

export {
  //
  /** @deprecated use DSL_ToolbarProps instead*/
  DSL_ToolbarProps as ToolbarProps, //
  /** @deprecated use DSL_Toolbar instead*/
  DSL_Toolbar as Toolbar,
};
