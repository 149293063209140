import {DSP_Road, DSP_FilterByAccessType, DSP_Segment} from './types';

interface FilteredRoadsArgs {
  filterByAccessTypeValue?: DSP_FilterByAccessType;
  roads: DSP_Road[];
  getSegmentsByIds: (segmentIds?: number[]) => DSP_Segment[];
}

export const getFilteredRoads = ({
  filterByAccessTypeValue,
  roads,
  getSegmentsByIds,
}: FilteredRoadsArgs) => {
  if (!filterByAccessTypeValue || filterByAccessTypeValue === 'All') {
    return roads;
  }

  const allSegments = getSegmentsByIds();
  const segmentMap = new Map(allSegments.map(segment => [segment.id, segment]));

  return roads
    .map(road => {
      const filteredSegmentIds = road.segmentIds.filter(segmentId => {
        const segment = segmentMap.get(segmentId);
        return segment && segment.accessType === filterByAccessTypeValue;
      });

      return {
        ...road,
        segmentIds: filteredSegmentIds,
      };
    })
    .filter(({segmentIds}) => segmentIds.length !== 0);
};
