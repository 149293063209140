import {notificationTypesDescriptionMap} from './constants';
import {DSP_AccessPublicNoticeOfAmendmentNotificationItem} from './types';

export interface DSP_UseAccessPublicNoticeOfAmendmentNotificationCardProps {
  notification: DSP_AccessPublicNoticeOfAmendmentNotificationItem;
}

export function useAccessPublicNoticeOfAmendmentNotificationCard({
  notification,
}: DSP_UseAccessPublicNoticeOfAmendmentNotificationCardProps) {
  const {accessPublicNoticeOfAmendment, changeSet} = notification.data;

  const actionDescription =
    notificationTypesDescriptionMap[notification.notificationType];

  return {
    actionDescription: actionDescription ?? undefined,
    description: changeSet?.name
      ? `This PNA contained the changeset: ${changeSet?.name}`
      : undefined,
    isRead: notification.readByLoggedInUser,
    href: `/networks/pnas/accessPublicNoticeOfAmendment/${accessPublicNoticeOfAmendment?.id}`,
    status: accessPublicNoticeOfAmendment?.status ?? undefined,
    title: accessPublicNoticeOfAmendment?.pnaTitle ?? undefined,
    created: notification.created ?? undefined,
  };
}
