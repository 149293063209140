import websocketMachine, {
  SubscribeRequest,
  SubscriptionWithMessageListeners,
  UnsubscribeRequest,
  WebSocketMachinePubSubClient,
  WebSocketObservable,
  WebSocketMachineInterpreter,
} from 'utils/machines/websocketMachine';
import {assign, interpret} from 'xstate';
import {PubSubToken} from '../../typeDefs/types';
import getFetchDataResult from 'utils/apollo/getFetchDataResult';
import {from} from 'rxjs';
import {client} from '../apollo/clients';
import {PubSubTokenControllerGeneratePubSubTokenDocument} from '../api/mutations/GeneratePubSubToken.generated';
import {addSubscription} from './websocket/addSubscription';
import {WebPubSubClient} from '@azure/web-pubsub-client';
import authService from './authService';
import {
  PubSubGroup,
  WebSocketMessage,
} from '@regulatory-platform/common-utils-notification';
import {removeSubscription} from './websocket/removeSubscription';
import {getWebsocketEndpoint} from 'utils/global';

const machine = websocketMachine
  .withContext({
    pubSubToken: null,
    subscriptions: new Map<string, SubscriptionWithMessageListeners>(),
  })
  .withConfig({
    actions: {
      savePubSubToken: assign({
        pubSubToken: (context, event) => {
          const result = getFetchDataResult<PubSubToken>(event.data);
          if (result) {
            return result;
          }
          return context.pubSubToken;
        },
      }),
      addSubscription: assign({
        subscriptions: (
          context,
          event,
        ): Map<string, SubscriptionWithMessageListeners> => {
          if (
            !authService.service.state.context.userProfile ||
            !event.subscribeRequest
          ) {
            // user profile not yet set, or an invalid subscription request.
            return (
              context.subscriptions ??
              new Map<string, SubscriptionWithMessageListeners>()
            );
          }

          return addSubscription(
            context.subscriptions,
            event.subscribeRequest,
            authService.service.state.context.userProfile,
          );
        },
      }),
      removeSubscription: assign({
        subscriptions: (
          context,
          event,
        ): Map<string, SubscriptionWithMessageListeners> => {
          if (!event.unsubscribeRequest) {
            // invalid request.
            return (
              context.subscriptions ??
              new Map<string, SubscriptionWithMessageListeners>()
            );
          }

          return removeSubscription(
            context.subscriptions,
            event.unsubscribeRequest,
          );
        },
      }),
      clearRetiredClients: assign({
        clients: context => {
          // clear all retired clients. i.e. keep solely the primary
          const clients = [];
          const primaryClient = context.clients?.find(
            ctxClient => ctxClient.isPrimary,
          );
          if (primaryClient) {
            clients.push(primaryClient);
          }
          return clients;
        },
      }),
      createPrimaryClient: assign({
        clients: context => {
          const clients = context.clients ?? [];
          if (!context.pubSubToken) {
            return clients;
          }

          // flag any current clients to be non-primary. These will be retired (closed) when the primary completes setup
          for (const ctxClient of clients) {
            ctxClient.isPrimary = false;
          }

          // for the websocket url, instead of using the url from the token (which will contain the Azure Web Pub Sub
          // hostname), we use the endpoint from our release/env variables, which is our Azure Application Gateway with
          // /webpubsub/* path. We simply use this and suffix the access token
          const urlWithAccessToken =
            getWebsocketEndpoint() +
            `?access_token=${context.pubSubToken.token}`;

          const newPrimary: WebSocketMachinePubSubClient = {
            client: new WebPubSubClient(urlWithAccessToken),
            isPrimary: true,
          };

          clients.push(newPrimary);
          return clients;
        },
      }),
      // purposefully empty onError. There's likely nothing meaningful to show the user. Dev's can add logs for debugging
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    },
    services: {
      apiGeneratePubSubToken: context => {
        if (!context.subscriptions) {
          throw new Error('no subscriptions');
        }

        const toSubscribeTo: PubSubGroup[] = [];
        context.subscriptions.forEach(subscription => {
          toSubscribeTo.push(subscription.group);
        });

        return client.mutate({
          mutation: PubSubTokenControllerGeneratePubSubTokenDocument,
          variables: {
            pubSubTokenRequestInput: {
              subscriptions: toSubscribeTo,
            },
          },
          errorPolicy: 'none',
        });
      },
      setupEventListeners: async (context): Promise<void> => {
        // add event listeners to our primary client
        const primaryClient = context.clients?.find(
          ctxClient => ctxClient.isPrimary,
        )?.client;

        if (!primaryClient) {
          throw new Error('primary client must exist');
        }

        // listen to group messages and delegate to the appropriate message listeners
        primaryClient.on('group-message', e => {
          machineInterpreter.send('GROUP_MESSAGE', {
            groupMessage: e.message,
          });
        });

        // start the client
        await primaryClient.start();
      },
      informSubscribers: async (context, event): Promise<void> => {
        if (!event || !event.groupMessage) {
          return;
        }

        const key = event.groupMessage.group;
        const notification = event.groupMessage.data as WebSocketMessage;

        const subscriptionWithMessageListeners =
          context.subscriptions?.get(key);
        if (subscriptionWithMessageListeners) {
          subscriptionWithMessageListeners.messageListeners.forEach(
            messageListener => {
              messageListener(notification);
            },
          );
        }
      },
      closeRetiredClients: async (context): Promise<void> => {
        // close all retired (i.e. non-primary) clients
        (context.clients ?? []).forEach(ctxClient => {
          if (!ctxClient.isPrimary) {
            ctxClient.client.stop();
          }
        });
      },
    },
  });

export interface WebSocketService {
  // subscribeallows components to subscribe to websocket messages without knowing the internal constants of the machine
  subscribe: (subscribeRequest: SubscribeRequest) => void;

  // unsubscribe allows components to unsubscribe from websocket messages without knowing the internal constants of the machine
  unsubscribe: (unsubscribeRequest: UnsubscribeRequest) => void;
}

// machineInterpreter is the xstate machine interpreter
const machineInterpreter = interpret(
  machine,
) as unknown as WebSocketMachineInterpreter;

// service is the service that is exposed to components, using our WebSocketService interface
// the purpose of this, is that components should not need to know the internal constants of the machine - or even that it is using xstate
// it should merely know that it can subscribe/unsubscribe and be aware of the typed request objects
const service: WebSocketService = {
  subscribe: (subscribeRequest: SubscribeRequest) => {
    machineInterpreter.send('SUBSCRIBE', {
      subscribeRequest: subscribeRequest,
    });
  },
  unsubscribe: (unsubscribeRequest: UnsubscribeRequest) => {
    machineInterpreter.send('UNSUBSCRIBE', {
      unsubscribeRequest: unsubscribeRequest,
    });
  },
};

const state$ = from(machineInterpreter as never) as WebSocketObservable;

export default {state$, machineInterpreter, service};
