import React from 'react';

import {
  usePopover,
  DSL_AvatarProps,
  DSL_Avatar,
  DSL_Box,
} from 'components/DesignSystem/Library';

import {
  DSP_PartnerAccountEntityPopover,
  DSP_PartnerAccountEntityPopoverProps,
} from '../PartnerAccountEntityPopover';

export interface DSP_PartnerAccountEntityAvatarViewProps
  extends Omit<DSP_PartnerAccountEntityPopoverProps, 'open' | 'anchorEl'>,
    ReturnType<typeof usePopover<HTMLDivElement>>,
    Pick<DSL_AvatarProps, 'src'> {
  initials: string | undefined;
}

export const DSP_PartnerAccountEntityAvatarView = ({
  isPopoverOpen,
  anchorRef,
  openPopover,
  closePopover,
  isLoading,
  src,
  initials,
  title,
  subTitle,
  website,
}: DSP_PartnerAccountEntityAvatarViewProps) => {
  return (
    <DSL_Box
      ref={anchorRef}
      width={'fit-content'}
      onMouseEnter={openPopover}
      onMouseLeave={closePopover}
    >
      <DSP_PartnerAccountEntityPopover
        anchorEl={anchorRef.current}
        open={isPopoverOpen}
        onMouseEnter={openPopover}
        onMouseLeave={closePopover}
        isLoading={isLoading}
        title={title}
        subTitle={subTitle}
        website={website}
      />
      <DSL_Avatar variant={'square'} alt={title} src={src} size={'LG'}>
        {initials}
      </DSL_Avatar>
    </DSL_Box>
  );
};

DSP_PartnerAccountEntityAvatarView.displayName =
  'DSP_PartnerAccountEntityAvatarView';
