import React from 'react';

import {UserProfile} from '@regulatory-platform/common-utils/dist';
import SelectAccounts from 'components/navigation/AppBar/SelectAccounts';
import * as R from 'ramda';
import {mapAccountTypeToReadable} from 'utils/global';
import {RelatedRecord} from 'utils/stores/types';

import {
  Body1,
  Body2,
  FieldGroup,
  Flex,
  FlexColumn,
  Box,
  FlexRow,
  ProfileIcon,
  BusinessCenterIcon,
  SupervisorAccountIcon,
} from 'components/DesignSystem/Library';

import {DSP_SearchAutocompleteField} from '../../Forms/SearchAutocompleteField';

export interface DSP_AccountHeaderViewProps {
  canImpersonate: boolean;
  hasOtherAccounts: boolean;
  isAdmin: boolean;
  userProfile: UserProfile;
  navigateToRoute: (route: string, options: object) => void;
  firstRecords: RelatedRecord[];
}

export const DSP_AccountHeaderView = ({
  canImpersonate,
  hasOtherAccounts,
  isAdmin,
  userProfile,
  navigateToRoute,
  firstRecords,
}: DSP_AccountHeaderViewProps) => {
  return (
    <>
      {hasOtherAccounts || isAdmin ? (
        <>
          {canImpersonate ? (
            <Box width={{xs: '100%', md: '20rem'}}>
              <FieldGroup>
                <DSP_SearchAutocompleteField
                  size={'small'}
                  span={12}
                  label={'Organisation'}
                  renderOption={(props, option) => {
                    return (
                      <li
                        {...props}
                        key={`${option.accountName}-${option.accountId}-${option.accountType}`}
                      >
                        <FlexRow
                          alignItems={'center'}
                          color={'action.active'}
                          gap={2}
                        >
                          <Flex flex={0}>
                            {option.accountType === 'customer' ? (
                              <ProfileIcon />
                            ) : option.accountType === 'partner' ? (
                              <BusinessCenterIcon />
                            ) : option.accountType === 'operations' ? (
                              <SupervisorAccountIcon />
                            ) : option.accountType === 'provider' ? (
                              <ProfileIcon />
                            ) : (
                              <ProfileIcon />
                            )}
                          </Flex>
                          <FlexColumn gap={0} flex={1} flexWrap={'wrap'}>
                            <Body1>{option.accountName}</Body1>
                            <Body2 color={'text.secondary'}>
                              {mapAccountTypeToReadable(option.accountType)}
                            </Body2>
                          </FlexColumn>
                        </FlexRow>
                      </li>
                    );
                  }}
                  getOptionLabel={option => option?.accountName ?? ''}
                  onOptionSelect={option =>
                    navigateToRoute('/changeAccount', {
                      state: {
                        changeToAccount: `${option.accountId}-${option.accountType}`,
                        navigateTo: location.pathname,
                      },
                    })
                  }
                  currentRecord={{
                    id: userProfile.accountId,
                    accountId: userProfile.accountId,
                    accountType: userProfile.accountType,
                    accountName: userProfile.accountName,
                    accountImageUrl: userProfile.accountImageUrl,
                  }}
                  placeholder={userProfile.accountName}
                  mapOptions={options => R.uniq([...firstRecords, ...options])}
                />
              </FieldGroup>
            </Box>
          ) : (
            <SelectAccounts userProfile={userProfile} isAdmin={isAdmin} />
          )}
        </>
      ) : (
        <Flex maxWidth={'30rem'} overflowX={'hidden'}>
          <Body1 textOverflow={'ellipsis'}>{userProfile?.accountName}</Body1>
        </Flex>
      )}
    </>
  );
};

DSP_AccountHeaderView.displayName = 'DSP_AccountHeaderView';

export {
  //
  /** @deprecated use DSP_AccountHeaderViewProps instead*/
  DSP_AccountHeaderViewProps as AccountHeaderViewProps, //
  /** @deprecated use DSP_AccountHeaderView instead*/
  DSP_AccountHeaderView as AccountHeaderView,
};
