import Layers from '@mui/icons-material/Layers';
import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/accessProductApplications/:accessProductApplicationId/accessProductDocuments/:accessProductDocumentId/network/:networkId/nur/:nurId/networkSegments',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "NetworkUpdateRequestSegments" */ 'containers-spatial/NetworkManagement/NetworkUpdateRequests/NetworkUpdateRequestSegments/NetworkUpdateRequestSegments'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Import network segments',
  menuDescription:
    'Data table for the segments associated with the network update request',
  menuIcon: Layers,
  parent: true,
  authorisation: {
    apiKey: 'NetworkAccessChangeSetSegmentWithRelations.findById',
    policies: [
      {
        role: 'partnerSpatialRead',
      },
      {
        role: 'operationsSpatialRead',
      },
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: '< Back',
      to: '..',
    },
  ],
};
