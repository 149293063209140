import {ContentMap, DomainContentMap} from './types';
import React from 'react';
import {AuthorisationDomains} from '@regulatory-platform/common-utils/dist';
import {ContentKeys} from './index';
import {WaLogo} from '../app/theming/WaLogo';
import {NHVRLogo} from '../app/theming/NHVRLogo';
import {NHVRIcon} from '../app/theming/NHVRIcon';
import {WaIcon} from '../app/theming/WaIcon';

export type ContentKeysGlobal =
  | 'GLOBAL_BRAND_NAME_LONG'
  | 'GLOBAL_BRAND_NAME_SHORT'
  | 'GLOBAL_APP_NAME_LONG'
  | 'GLOBAL_APP_NAME_SHORT'
  | 'GLOBAL_BRAND_LOGO'
  | 'GLOBAL_BRAND_ICON';

const nhvrBrandNameLong = 'National Heavy Vehicle Regulator';
const nhvrBrandNameShort = 'NHVR';

const nhvr: ContentMap<ContentKeysGlobal> = {
  GLOBAL_BRAND_LOGO: <NHVRLogo />,
  GLOBAL_BRAND_ICON: <NHVRIcon />,
  GLOBAL_BRAND_NAME_LONG: nhvrBrandNameLong,
  GLOBAL_BRAND_NAME_SHORT: nhvrBrandNameShort,
  GLOBAL_APP_NAME_LONG: `${nhvrBrandNameLong} Portal`,
  GLOBAL_APP_NAME_SHORT: `${nhvrBrandNameShort} Portal`,
};

const waBrandName = 'MRWA';
const waAppName = 'HVS PATH-Ways';

const wa: ContentMap<ContentKeysGlobal> = {
  GLOBAL_BRAND_LOGO: <WaLogo />,
  GLOBAL_BRAND_ICON: <WaIcon />,
  GLOBAL_BRAND_NAME_LONG: waBrandName,
  GLOBAL_BRAND_NAME_SHORT: waBrandName,
  GLOBAL_APP_NAME_LONG: waAppName,
  GLOBAL_APP_NAME_SHORT: waAppName,
};

const adfBrandName = 'ADF Portal';

const adf: ContentMap<ContentKeysGlobal> = {
  GLOBAL_BRAND_NAME_LONG: adfBrandName,
  GLOBAL_BRAND_NAME_SHORT: adfBrandName,
  GLOBAL_APP_NAME_LONG: adfBrandName,
  GLOBAL_APP_NAME_SHORT: adfBrandName,
};

export const globalContent: DomainContentMap<
  AuthorisationDomains,
  ContentKeys
> = {
  nhvr,
  wa,
  adf,
};
