import React from 'react';

import {DSL_CopyAble} from 'components/DesignSystem/Library';

import {Address} from '../../../../../typeDefs/types';

export interface DSP_AddressRendererProps {
  address: Address;
}

const formatAddress = (address: Address): string => {
  const {
    line1 = '',
    line2 = '',
    locality = '',
    state: addressState = '',
    postcode = '',
  } = address;
  const line = `${line1}${line2 ? ', ' + line2 : ''}`;
  const addressend = `${locality} ${addressState} ${postcode}`;
  return `${line ? line + ', ' : ''}${addressend}`;
};

const emptyAddress = (address: Address): boolean => {
  return (
    !address ||
    (!address.country &&
      !address.line1 &&
      !address.line2 &&
      !address.locality &&
      !address.state &&
      !address.postcode)
  );
};

export const DSP_AddressRenderer = ({address}: DSP_AddressRendererProps) => {
  const addressString = emptyAddress(address) ? null : formatAddress(address);
  return addressString ? (
    <DSL_CopyAble copyText={addressString}>{addressString}</DSL_CopyAble>
  ) : null;
};

DSP_AddressRenderer.displayName = 'DSP_AddressRenderer';
