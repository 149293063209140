import React from 'react';

import {
  DialogContentText as MuiDialogContentText,
  DialogContentTextProps as MuiDialogContentTextProps,
} from '@mui/material';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

export type DSL_DialogContentTextProps = Omit<
  MuiDialogContentTextProps,
  DSL_RestrictedVisualProps
>;

export const DSL_DialogContentText = (props: DSL_DialogContentTextProps) => {
  return <MuiDialogContentText {...props} />;
};

DSL_DialogContentText.displayName = 'DSL_DialogContentText';

export {
  //
  /** @deprecated use DSL_DialogContentTextProps instead*/
  DSL_DialogContentTextProps as DialogContentTextProps, //
  /** @deprecated use DSL_DialogContentText instead*/
  DSL_DialogContentText as DialogContentText,
};
