import React, {ForwardedRef} from 'react';

import {DSL_Badge} from 'components/DesignSystem/Library/Badge';
import {DSL_BaseTypography} from 'components/DesignSystem/Library/Typography/BaseTypography';

import {DSL_BaseTab, DSL_BaseTabProps} from '../BaseTab/BaseTab';

interface DSL_BadgeProps {
  badgeContent?: number;
  max?: number;
}

export interface DSL_TabProps
  extends Omit<DSL_BaseTabProps, 'aria-invalid' | 'sx'> {
  error?: boolean;
  badge?: DSL_BadgeProps;
}

/**
 * this function calculates padding for numbers based on their length, giving
 * the badge, if it exists, enough room for numbers up to three digits long with
 * a trailing '+' sign (i.e. '999+')
 */
const calculateBadgePadding = (
  badge: DSL_BadgeProps,
): {paddingRight: '1.75em'} | {paddingRight: '1.25em'} | null => {
  if (!badge?.badgeContent) return null;

  return badge.badgeContent > 999
    ? {paddingRight: '1.75em'}
    : badge.badgeContent > 9
    ? {paddingRight: '1.25em'}
    : null;
};

export const DSL_Tab = React.forwardRef(
  (
    {label, error, badge, ...props}: DSL_TabProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    if (badge) {
      return (
        <DSL_BaseTab
          ref={ref}
          {...props}
          aria-invalid={error}
          label={
            <DSL_Badge
              max={badge.max ?? 99}
              badgeContent={badge.badgeContent}
              color="primary"
            >
              <DSL_BaseTypography
                color={error ? 'error' : 'inherit'}
                paddingRight={1.5}
              >
                {label}
              </DSL_BaseTypography>
            </DSL_Badge>
          }
          sx={calculateBadgePadding(badge)}
        />
      );
    }

    return (
      <DSL_BaseTab ref={ref} {...props} aria-invalid={error} label={label} />
    );
  },
);

DSL_Tab.displayName = 'DSL_Tab';

export {
  //
  /** @deprecated use DSL_TabProps instead*/
  DSL_TabProps as TabProps, //
  /** @deprecated use DSL_Tab instead*/
  DSL_Tab as Tab,
};
