import React from 'react';

import {
  DSP_UserEntityLoaderProvider,
  DSP_UserEntityAvatarWithName,
} from 'components/DesignSystem/Portal/Entity/UserEntity';

import {
  DSP_BaseAccessProductChangeSetNotificationCard,
  DSP_BaseAccessProductChangeSetNotificationCardProps,
} from '../Base/BaseAccessProductChangeSetNotificationCard.Connector';

export interface DSP_AccessProductChangeSetPartnerNotificationCardViewProps
  extends Omit<DSP_BaseAccessProductChangeSetNotificationCardProps, 'avatar'> {
  userId: number | undefined;
}

export const DSP_AccessProductChangeSetPartnerNotificationCardView = ({
  userId,
  notification,
}: DSP_AccessProductChangeSetPartnerNotificationCardViewProps) => {
  return (
    <DSP_BaseAccessProductChangeSetNotificationCard
      notification={notification}
      avatar={
        userId && (
          <DSP_UserEntityLoaderProvider entityId={userId}>
            <DSP_UserEntityAvatarWithName />
          </DSP_UserEntityLoaderProvider>
        )
      }
    />
  );
};

DSP_AccessProductChangeSetPartnerNotificationCardView.displayName =
  'DSP_AccessProductChangeSetPartnerNotificationCardView';

export {
  //
  /** @deprecated use DSP_AccessProductChangeSetPartnerNotificationCardViewProps instead*/
  DSP_AccessProductChangeSetPartnerNotificationCardViewProps as AccessProductChangeSetPartnerNotificationCardViewProps, //
  /** @deprecated use DSP_AccessProductChangeSetPartnerNotificationCardView instead*/
  DSP_AccessProductChangeSetPartnerNotificationCardView as AccessProductChangeSetPartnerNotificationCardView,
};
