import * as Types from '../../../../typeDefs/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserModelControllerVerifyEmailMutationVariables = Types.Exact<{
  verifyEmailInput: Types.VerifyEmailInput;
}>;


export type UserModelControllerVerifyEmailMutation = { __typename?: 'Mutation', userModelControllerVerifyEmail?: string | null };


export const UserModelControllerVerifyEmailDocument = gql`
    mutation userModelControllerVerifyEmail($verifyEmailInput: VerifyEmailInput!) {
  userModelControllerVerifyEmail(verifyEmailInput: $verifyEmailInput)
}
    `;
export type UserModelControllerVerifyEmailMutationFn = Apollo.MutationFunction<UserModelControllerVerifyEmailMutation, UserModelControllerVerifyEmailMutationVariables>;

/**
 * __useUserModelControllerVerifyEmailMutation__
 *
 * To run a mutation, you first call `useUserModelControllerVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserModelControllerVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userModelControllerVerifyEmailMutation, { data, loading, error }] = useUserModelControllerVerifyEmailMutation({
 *   variables: {
 *      verifyEmailInput: // value for 'verifyEmailInput'
 *   },
 * });
 */
export function useUserModelControllerVerifyEmailMutation(baseOptions?: Apollo.MutationHookOptions<UserModelControllerVerifyEmailMutation, UserModelControllerVerifyEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserModelControllerVerifyEmailMutation, UserModelControllerVerifyEmailMutationVariables>(UserModelControllerVerifyEmailDocument, options);
      }
export type UserModelControllerVerifyEmailMutationHookResult = ReturnType<typeof useUserModelControllerVerifyEmailMutation>;
export type UserModelControllerVerifyEmailMutationResult = Apollo.MutationResult<UserModelControllerVerifyEmailMutation>;
export type UserModelControllerVerifyEmailMutationOptions = Apollo.BaseMutationOptions<UserModelControllerVerifyEmailMutation, UserModelControllerVerifyEmailMutationVariables>;