import React from 'react';

import {Breakpoint} from '@mui/material';
import {segmentAccessTitleMap} from 'components/DesignSystem/Portal/Layout/Segments/constants';

import {
  DSL_BorderlessCard,
  DSL_ButtonCardActionArea,
  DSL_CardAdornmentLayout,
  DSL_ChevronRightIcon,
  DSL_CardContent,
  DSL_FlexRow,
  DSL_FlexColumn,
  DSL_Subtitle2,
  DSL_Body2,
  DSL_ArrowForwardIcon,
  DSL_CardActionAreaExclusion,
  DSL_RemoveCircleIconButton,
  DSL_Fade,
  DSL_Tooltip,
} from 'components/DesignSystem/Library';

import {DSP_SegmentAccessIcon} from '../SegmentAccessIcon';
import {DSP_SelectedSegmentChangeOptions} from '../SelectedSegmentsChangeOptions/SelectedSegmentChangeOptions';
import {DSP_Segment} from '../types';

import {useSelectedSegmentsSegmentCard} from './useSelectedSegmentsSegmentCard';

type DSP_SelectedSegmentsSegmentCardProps = {
  segment: DSP_Segment;
  onClick: (id: number) => void;
  currentBreakpoint: Breakpoint;
  active?: boolean;
  onRemoveSegment?: (segmentId: number) => void;
  roadName?: string;
  showChangeOptions?: boolean;
};

export const DSP_SelectedSegmentsSegmentCard = ({
  roadName,
  segment,
  currentBreakpoint,
  onClick,
  active,
  onRemoveSegment,
  showChangeOptions,
}: DSP_SelectedSegmentsSegmentCardProps) => {
  const {isHovering, setIsHovering} = useSelectedSegmentsSegmentCard(
    segment.id,
  );

  return (
    <DSL_BorderlessCard
      data-name={roadName}
      square
      onMouseOver={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <DSL_ButtonCardActionArea
        onClick={() => onClick(segment.id)}
        active={active}
        disableRipple
      >
        <DSL_CardAdornmentLayout
          endAdornment={<DSL_ChevronRightIcon color={'action'} />}
        >
          <DSL_CardContent padding={'SM'}>
            <DSL_FlexRow justifyContent={'space-between'}>
              <DSL_FlexRow flexWrap={'nowrap'} alignItems={'center'} gap={3}>
                <DSL_FlexRow flexWrap={'nowrap'} alignItems={'center'} gap={1}>
                  {onRemoveSegment && (
                    <DSL_Fade in={isHovering}>
                      <DSL_Tooltip title={'Remove segment from the selection'}>
                        <DSL_FlexRow>
                          <DSL_CardActionAreaExclusion>
                            <DSL_RemoveCircleIconButton
                              onClick={() => onRemoveSegment(segment.id)}
                            />
                          </DSL_CardActionAreaExclusion>
                        </DSL_FlexRow>
                      </DSL_Tooltip>
                    </DSL_Fade>
                  )}
                  <DSL_FlexRow
                    flexWrap={'nowrap'}
                    alignItems={'center'}
                    gap={0}
                  >
                    {segment?.currentAccessType && (
                      <>
                        <DSP_SegmentAccessIcon
                          segmentAccessType={segment.currentAccessType}
                        />
                        <DSL_ArrowForwardIcon
                          fontSize="medium"
                          color={'action'}
                        />
                      </>
                    )}
                    <DSP_SegmentAccessIcon
                      segmentAccessType={segment.accessType}
                      isSegmentUnmanaged={segment.isUnmanaged}
                    />
                  </DSL_FlexRow>
                </DSL_FlexRow>
                <DSL_FlexColumn gap={0}>
                  <DSL_Body2 ellipsisLines={1} color={'text.secondary'}>
                    {segment?.id}
                  </DSL_Body2>
                  <DSL_Subtitle2 ellipsisLines={1}>
                    {segment?.currentAccessType
                      ? segmentAccessTitleMap.DRAFT[segment?.accessType]
                      : segmentAccessTitleMap.CURRENT[segment?.accessType]}
                  </DSL_Subtitle2>
                </DSL_FlexColumn>
              </DSL_FlexRow>
              <DSL_FlexRow
                alignItems={'center'}
                mr={currentBreakpoint === 'xs' ? -2 : 0}
              >
                {showChangeOptions && (
                  <DSL_CardActionAreaExclusion>
                    <DSP_SelectedSegmentChangeOptions
                      segmentAccessType={segment.accessType}
                      currentAccessType={segment?.currentAccessType}
                      lastAppliedAccessType={segment?.lastAppliedAccessType}
                      segmentId={segment.id}
                    />
                  </DSL_CardActionAreaExclusion>
                )}
              </DSL_FlexRow>
            </DSL_FlexRow>
          </DSL_CardContent>
        </DSL_CardAdornmentLayout>
      </DSL_ButtonCardActionArea>
    </DSL_BorderlessCard>
  );
};

DSP_SelectedSegmentsSegmentCard.displayName = 'DSP_SelectedSegmentsSegmentCard';
