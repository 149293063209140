import Assignment from '@mui/icons-material/Assignment';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/vehicleSightings',
  component: lazy(
    () => import(/* webpackChunkName: "VehicleDefects" */ './VehicleSightings'),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'Vehicle Sightings',
  menuIcon: Assignment,
  authorisation: {
    apiKey: 'VehicleSightingsWithRelations.findById',
    policies: [{role: 'operationsComplianceRead'}] as AuthorisationPolicy[],
  },
  sections: [],
};
