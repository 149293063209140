import React, {ReactNode} from 'react';

import {DSL_BaseDrawer} from '../../BaseDrawer';
import {DSL_NonModalDrawerProps} from '../NonModalDrawer';

import {
  useCollapsibleNonModalDrawer,
  DSL_UseCollapsibleNonModalDrawerProps,
} from './useCollapsibleNonModalDrawer';

export interface DSL_CollapsibleNonModalDrawerProps
  extends Omit<DSL_NonModalDrawerProps, 'anchor' | 'sidebarAnchor' | 'size'>,
    DSL_UseCollapsibleNonModalDrawerProps {
  collapsedContent?: ReactNode;
}

export const DSL_CollapsibleNonModalDrawer = ({
  id,
  isHidden,
  sidebar,
  children,
  collapsedContent,
  isTransparentBackground,
  ...props
}: DSL_CollapsibleNonModalDrawerProps) => {
  const {isCollapsed, ...drawerProps} = useCollapsibleNonModalDrawer(props);

  return (
    <DSL_BaseDrawer
      id={id}
      isHidden={isHidden}
      sidebar={sidebar}
      isTransparentBackground={isTransparentBackground}
      {...drawerProps}
    >
      {isCollapsed ? collapsedContent ?? children : children}
    </DSL_BaseDrawer>
  );
};

DSL_CollapsibleNonModalDrawer.displayName = 'DSL_CollapsibleNonModalDrawer';

export {
  //
  /** @deprecated use DSL_CollapsibleNonModalDrawerProps instead*/
  DSL_CollapsibleNonModalDrawerProps as CollapsibleNonModalDrawerProps, //
  /** @deprecated use DSL_CollapsibleNonModalDrawer instead*/
  DSL_CollapsibleNonModalDrawer as CollapsibleNonModalDrawer,
};
