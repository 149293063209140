import React from 'react';

import {
  ListItem as MuiListItem,
  ListItemProps as MuiListItemProps,
} from '@mui/material';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

export interface DSL_PopperListItemProps
  extends Omit<MuiListItemProps, DSL_RestrictedVisualProps | 'component'> {
  disablePaddingTop?: boolean;
}

export const DSL_PopperListItem = ({
  disablePaddingTop,
  ...props
}: DSL_PopperListItemProps) => {
  return (
    <MuiListItem
      component="li"
      disablePadding
      sx={{
        pt: disablePaddingTop ? 0 : 1,
      }}
      {...props}
    />
  );
};

DSL_PopperListItem.displayName = 'DSL_PopperListItem';

export {
  //
  /** @deprecated use DSL_PopperListItemProps instead*/
  DSL_PopperListItemProps as PopperListItemProps, //
  /** @deprecated use DSL_PopperListItem instead*/
  DSL_PopperListItem as PopperListItem,
};
