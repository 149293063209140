import React from 'react';

import {
  DSL_ScrollableLayoutBody,
  DSL_ScrollableLayoutBodyProps,
} from '../../ScrollableLayout';

export type DSL_PopperBodyProps = DSL_ScrollableLayoutBodyProps;

export const DSL_PopperBody = ({
  alwaysShowBorder = true,
  ...props
}: DSL_PopperBodyProps) => {
  return (
    <DSL_ScrollableLayoutBody alwaysShowBorder={alwaysShowBorder} {...props} />
  );
};

DSL_PopperBody.displayName = 'DSL_PopperBody';
