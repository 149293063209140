import Layers from '@mui/icons-material/Layers';
import {
  BuiltInRoles,
  AuthorisationPolicy,
} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/inspectionFiles/:id',
  component: lazy(
    () => import(/* webpackChunkName: "InspectionFile" */ './InspectionFile'),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Inspection File',
  menuIcon: Layers,
  parent: true,
  sections: [],
  authorisation: {
    apiKey: null,
    policies: [BuiltInRoles.EVERYONE] as AuthorisationPolicy[],
  },
};
