import React, {ReactNode} from 'react';

import {
  CardActionAreaExclusion,
  Body1,
  FlexRow,
} from 'components/DesignSystem/Library';

import {DSP_ClickableTimeDistance} from '../../../DateTime/ClickableTimeDistance/ClickableTimeDistance';

export interface DSP_NotificationCardHeaderProps {
  avatar?: ReactNode;
  created?: Date;
  actionDescription?: ReactNode;
}

export const DSP_NotificationCardHeader = ({
  actionDescription,
  avatar,
  created,
}: DSP_NotificationCardHeaderProps) => {
  return (
    <FlexRow
      alignItems={'center'}
      justifyContent={'space-between'}
      flexWrap={'nowrap'}
    >
      <FlexRow alignItems={'center'} flexShrink={1} gap={0} columnGap={1}>
        {avatar && <CardActionAreaExclusion>{avatar}</CardActionAreaExclusion>}
        {actionDescription && (
          <Body1 color={'text.secondary'} ellipsisLines={2}>
            {actionDescription}
          </Body1>
        )}
      </FlexRow>
      {created && (
        <CardActionAreaExclusion flexShrink={0}>
          <DSP_ClickableTimeDistance date={created} />
        </CardActionAreaExclusion>
      )}
    </FlexRow>
  );
};

DSP_NotificationCardHeader.displayName = 'DSP_NotificationCardHeader';

export {
  //
  /** @deprecated use DSP_NotificationCardHeaderProps instead*/
  DSP_NotificationCardHeaderProps as NotificationCardHeaderProps, //
  /** @deprecated use DSP_NotificationCardHeader instead*/
  DSP_NotificationCardHeader as NotificationCardHeader,
};
