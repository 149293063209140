// TODO: this file is horrid, we must split it up
import {useReducer} from 'react';

import {AbortController} from '@azure/abort-controller';
import {BlobUploadCommonResponse} from '@azure/storage-blob';
import {
  UserProfile,
  jsonPointerResolver,
  FilesConfig,
  files,
} from '@regulatory-platform/common-utils';
import {useSnackbarService} from 'app/services/AppServicesContext';
import moment from 'moment/moment';
import * as R from 'ramda';
import {SasToken} from 'typeDefs/types';
import {onGenerateSASTokenFunc} from 'utils/stores/types';

import {useFormService} from '../FormProvider';
import {useFormFieldPropsSelector} from '../hooks';

import {DSP_FileListWithIndex, RecordWithFileList} from './Attachments.types';
import {
  DSP_FileChangeList,
  FileListFile,
  FileRejection,
} from './Attachments.types';
import useStorage from './useStorage';
import {
  getAbortMessage,
  getDropRejectMessage,
  getFileLimitExceedMessage,
  getFileListKey,
  getFileListWithIndexByPrefix,
  getFilesAddedMessage,
  getVersionedFileIfRequired,
  getDownloadURL,
  getRecordIdIfExists,
  getAccountIdFromRecordWithUserProfileFallback,
} from './utils';

const {getAccountType, getAttachmentsConfig} = files;

type fileChangeListReducerAction = {
  type: 'progress' | 'add' | 'success' | 'abort' | 'reset';
  key?: string;
  progress?: number;
  nextState?: DSP_FileChangeList;
  fileList?: FileListFile[];
};

function fileChangeListReducer(
  state: DSP_FileChangeList,
  action: fileChangeListReducerAction,
): DSP_FileChangeList {
  switch (action.type) {
    case 'progress':
      if (
        !R.isNil(action.progress) &&
        !R.isNil(action.key) &&
        state[action.key]
      ) {
        state[action.key].progress = action.progress;
      }
      return R.mapObjIndexed(obj => obj, state); //use to change object reference
    case 'add':
      if (!R.isNil(action.nextState)) {
        return R.mergeRight(state, action.nextState);
      }
      return state;
    case 'success':
      if (!R.isNil(action.fileList)) {
        const successKeys = R.map(
          fileListItem => getFileListKey(fileListItem),
          action.fileList,
        );
        return R.reduce(
          (accState, key) => {
            if (!R.includes(key, successKeys)) {
              accState[key] = state[key];
            }
            return accState;
          },
          {} as DSP_FileChangeList,
          R.keys(state),
        );
      }
      return state;
    case 'abort':
      if (!R.isNil(action.key) && state[action.key]) {
        delete state[action.key];
      }
      return R.mapObjIndexed(obj => obj, state); //use to change object reference
    case 'reset':
      return R.defaultTo({}, action.nextState);
    default:
      throw new Error();
  }
}
export interface DSP_UseAttachmentsField {
  fieldRef: string;
  prefix: string;
  fileGroupId?: string;
  filesLimit?: number;
  maxFileSize: number;
  onGenerateSASToken: onGenerateSASTokenFunc;
  accountId?: boolean;
}
export function useAttachmentsField({
  fieldRef = '#/filesList',
  prefix,
  fileGroupId,
  filesLimit,
  maxFileSize,
  onGenerateSASToken,
  accountId,
}: DSP_UseAttachmentsField) {
  const snackbarService = useSnackbarService();
  const service = useFormService();
  const {send} = service;

  const [fileChangeList, setFileChangeList] = useReducer<
    (
      state: DSP_FileChangeList,
      action: fileChangeListReducerAction,
    ) => DSP_FileChangeList
  >(fileChangeListReducer, {});

  const [{uploadToBlobStorage}] = useStorage();

  return useFormFieldPropsSelector(
    fieldRef,
    service,
    state => {
      const schema = state.context.schema;
      const activeRecord = state.context.record as RecordWithFileList;

      const attachmentConfig = {
        ...getAttachmentsConfig(schema),
        ...(accountId && {accountId: '/#'}),
      } as FilesConfig;

      const allowedFileTypes = files.getAllowedFileTypes(
        prefix,
        attachmentConfig,
      );

      const userProfile = state.context.authorisations
        ?.userProfile as UserProfile;
      const deleteItem = async (file: DSP_FileListWithIndex): Promise<void> => {
        //simply remove from clientside fileList
        //on saving record the server will delete file
        send({
          type: 'REMOVE_ITEM',
          fieldRef,
          index: file._idx,
          value: file,
        });
      };

      const uploadFile = async (
        fileListItem: DSP_FileListWithIndex,
        file: File,
        abortController: AbortController,
        onProgressUpdate: (progress: number) => void,
      ): Promise<BlobUploadCommonResponse> => {
        const accountTypeFromSchema = getAccountType(
          fileListItem.prefix,
          attachmentConfig,
        );
        const sasToken = (await onGenerateSASToken({
          blobName: fileListItem.name,
          prefix: fileListItem.prefix,
          fileGroupId: fileListItem.fileGroupId,
          accountType: accountTypeFromSchema,
          accountId:
            getAccountIdFromRecordWithUserProfileFallback(
              fileListItem.prefix,
              attachmentConfig,
              activeRecord,
              accountTypeFromSchema,
              userProfile,
            ) || -1,
          recordId: getRecordIdIfExists(
            fileListItem.prefix,
            attachmentConfig,
            activeRecord,
          ),
          action: 'upload',
        })) as SasToken;
        if (!sasToken.token) {
          throw new Error('Error generating token');
        }

        const response = await uploadToBlobStorage(
          file,
          sasToken,
          onProgressUpdate,
          abortController,
        );

        return response;
      };

      const blobUrl = (
        name: string,
        filePrefix: string,
        progress?: number,
      ): void => {
        if (R.isNil(progress)) {
          getDownloadURL(
            name,
            filePrefix,
            fileGroupId,
            attachmentConfig,
            activeRecord as RecordWithFileList,
            onGenerateSASToken,
          ).then(newPageUrl => {
            window.open(newPageUrl, '_blank');
          });
        }
      };

      const uploadedBy = `${userProfile?.firstName} ${userProfile?.name}`;
      const fileList = (jsonPointerResolver(fieldRef)(state.context.record) ??
        []) as FileListFile[];

      const fileListWithIndexByPrefix = getFileListWithIndexByPrefix(
        prefix,
        fileGroupId,
        fileList,
      );

      const fileListWithChanges = fileListWithIndexByPrefix.map(
        fileWithIndex => ({
          ...fileWithIndex,
          ...fileChangeList[fileWithIndex._key],
        }),
      );

      const onDropAccepted = async (validFiles: File[]): Promise<void> => {
        //validate on file size
        if (
          R.defaultTo(0, filesLimit) > 0 &&
          fileList.length + validFiles.length > R.defaultTo(0, filesLimit)
        ) {
          if (getFileLimitExceedMessage) {
            snackbarService.send('TRIGGER', {
              record: {
                message: getFileLimitExceedMessage(
                  R.defaultTo(0, filesLimit),
                  validFiles.length,
                ),
                variant: 'error',
              },
            });
          }
          return;
        }

        //add to the record fileList
        const nextFileChangeList = validFiles.reduce(
          (accNextFileChangeList, file, idx): DSP_FileChangeList => {
            const validFileDetails = getVersionedFileIfRequired(
              file.name,
              fileList,
            );
            const fileListItem = {
              prefix,
              fileGroupId,
              name: validFileDetails.name,
              originalFileName: file.name,
              size: file.size,
              type: file.type,
              version: validFileDetails.version,
              uploaded: moment().toISOString(),
              uploadedBy: uploadedBy,
              _idx: fileListWithIndexByPrefix.length + idx,
              _key: prefix + ':' + validFileDetails.name,
            };

            if (R.isEmpty(activeRecord?.fileList)) {
              send({
                type: 'ADD_ITEM',
                fieldRef: fieldRef,
                index: 0,
                record: fileListItem,
              });
            } else {
              send({
                type: 'ADD_ITEM',
                fieldRef: fieldRef,
                index: fileListItem._idx,
                record: fileListItem,
              });
            }
            accNextFileChangeList[fileListItem._key] = {
              fileListItem,
              file,
              progress: 0,
              abortController: new AbortController(),
            };
            return accNextFileChangeList;
          },
          {} as DSP_FileChangeList,
        );
        setFileChangeList({
          type: 'add',
          nextState: nextFileChangeList,
        });

        //upload in parallel
        const uploadedFiles = await Promise.all(
          R.keys(nextFileChangeList).map(async fileChangeListIndex => {
            const {fileListItem, file, abortController} =
              nextFileChangeList[fileChangeListIndex];
            try {
              const response = await uploadFile(
                fileListItem,
                file,
                abortController,
                progress => {
                  setFileChangeList({
                    type: 'progress',
                    progress,
                    key: fileListItem._key,
                  });
                },
              );
              if (response.errorCode) {
                throw Error(response.errorCode);
              }
              return fileListItem;
            } catch (e) {
              setFileChangeList({
                type: 'abort',
                key: fileListItem._key,
              });
              const isCancelled =
                (e as Error).message === 'The operation was aborted.';
              const variant = isCancelled ? 'success' : 'error';
              snackbarService.send('TRIGGER', {
                record: {
                  message: getAbortMessage(isCancelled, fileListItem.name),
                  variant: variant,
                },
              });
              send({
                type: 'REMOVE_ITEM',
                fieldRef,
                index: fileListItem._idx,
                value: file,
              });
            }
          }),
        );

        //upload process completed
        const successfullyUploadedFiles = R.filter(
          file => !R.isNil(file),
          uploadedFiles,
        );
        if (successfullyUploadedFiles.length > 0) {
          snackbarService.send('TRIGGER', {
            record: {
              message: getFilesAddedMessage(
                successfullyUploadedFiles as FileListFile[],
              ),
              variant: 'success',
            },
          });

          // inform any listeners that the blob list has been updated
          send('BLOB_LIST_UPDATED', {value: successfullyUploadedFiles});
        }
        setFileChangeList({
          type: 'success',
          fileList: successfullyUploadedFiles as FileListFile[],
        });
      };

      const onDropRejected = (fileRejections: FileRejection[]): void => {
        const fileLimitExceeded =
          R.defaultTo([], fileList).length + fileRejections.length >
          R.defaultTo(0, filesLimit);

        const message = fileLimitExceeded
          ? getFileLimitExceedMessage(
              R.defaultTo(0, filesLimit),
              R.defaultTo([], fileList).length + fileRejections.length,
            )
          : getDropRejectMessage(fileRejections, allowedFileTypes, maxFileSize);

        snackbarService.send('TRIGGER', {
          record: {
            message,
            variant: 'error',
          },
        });
      };

      const accept = allowedFileTypes;

      return {
        onDropAccepted,
        onDropRejected,
        accept,
        blobUrl,
        deleteItem,
        fileList: fileListWithChanges,
        fileChangeList,
        attachmentConfig,
        activeRecord,
      };
    },
    ['onDropAccepted', 'onDropRejected', 'blobUrl', 'deleteItem'],
  );
}
