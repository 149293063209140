import React from 'react';

import {
  DSP_EntityLoaderProvider,
  useEntityLoaderContext,
} from '../../EntityLoaderProvider';
import {DSP_EntityLoaderProviderProps} from '../../types';

import {GetPartnerAccountFindByIdEveryoneDocument} from './api/GetPartnerAccountFindByIdEveryone.generated';
import {DSP_PartnerAccountEveryonePublic} from './types';

export const DSP_PartnerAccountEntityLoaderProvider = ({
  entityId: id,
  ...props
}: DSP_EntityLoaderProviderProps) => (
  <DSP_EntityLoaderProvider<DSP_PartnerAccountEveryonePublic>
    query={GetPartnerAccountFindByIdEveryoneDocument}
    variables={{id}}
    {...props}
  />
);

export const usePartnerAccountEntityLoaderContext = () =>
  useEntityLoaderContext<DSP_PartnerAccountEveryonePublic>();

DSP_PartnerAccountEntityLoaderProvider.displayName =
  'DSP_PartnerAccountEntityLoaderProvider';
