import React, {ForwardedRef, forwardRef} from 'react';

import {Link as MuiLink, LinkProps as MuiLinkProps} from '@mui/material';

/*
  This component is an opinionated external link component.
  All external links should open in a new tab.
*/

export type DSL_ExternalLinkProps = Omit<
  MuiLinkProps,
  'component' | 'target' | 'rel'
>;

export const DSL_ExternalLink = forwardRef(
  (props: DSL_ExternalLinkProps, ref: ForwardedRef<HTMLAnchorElement>) => {
    return <MuiLink ref={ref} target="_blank" rel="noopener" {...props} />;
  },
);

DSL_ExternalLink.displayName = 'DSL_ExternalLink';

export {
  //
  /** @deprecated use DSL_ExternalLinkProps instead*/
  DSL_ExternalLinkProps as ExternalLinkProps, //
  /** @deprecated use DSL_ExternalLink instead*/
  DSL_ExternalLink as ExternalLink,
};
