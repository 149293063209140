import React from 'react';

export const NHVRIcon = () => {
  return (
    <svg
      width="86"
      height="24"
      viewBox="0 0 86 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.064 0.269086H18.3781C18.8813 0.269086 18.8813 0.637453 18.8813 0.905854V23.2968C18.8813 23.544 18.7234 23.8329 18.2758 23.8329C17.9215 23.8329 17.7293 23.6529 17.5872 23.4762C16.0905 21.6542 4.69389 7.98494 2.3569 5.18247V22.9599C2.3569 23.2318 2.32051 23.4981 1.88456 23.4981H0.503922C0.146234 23.4981 0 23.3324 0 22.9257V0.504622C0 0.211571 0.226559 0 0.539622 0C0.798449 0 0.992741 0.0883259 1.21037 0.303321C3.19517 2.46422 14.2279 15.8795 16.5251 18.6772V0.805888C16.5251 0.334817 16.7839 0.269086 17.064 0.269086Z"
        fill="#0F2D52"
      />
      <path
        d="M40.0914 0.269083H41.2688C41.7905 0.269083 41.8743 0.477231 41.8743 0.972952V22.9599C41.8743 23.4509 41.6992 23.4981 41.2351 23.4981H40.0914C39.7474 23.4981 39.5188 23.4064 39.5188 22.9599V14.3245H25.3492V22.9599C25.3492 23.3468 25.1879 23.4981 24.7753 23.4981H23.5649C23.1111 23.4981 22.993 23.3728 22.993 22.8935V0.940087C22.993 0.432041 23.1399 0.269083 23.5979 0.269083H24.8103C25.26 0.269083 25.3492 0.515574 25.3492 0.940087V12.1089H39.5188V0.972952C39.5188 0.506673 39.6073 0.269083 40.0914 0.269083Z"
        fill="#0F2D52"
      />
      <path
        d="M65.0684 0.269083H63.6541C63.385 0.269083 63.2648 0.488871 63.1344 0.777129L54.8986 19.4351L46.1685 0.61691C46.0456 0.311535 45.8129 0.269083 45.5795 0.269083H44.1666C44.0382 0.269083 43.8913 0.269083 43.7972 0.399861C43.6943 0.541528 43.7525 0.713978 43.817 0.904903C43.9091 1.10258 44.4525 2.2727 45.2475 3.98464L45.2584 4.0082C48.1085 10.1453 54.1633 23.1834 54.3123 23.4789C54.3707 23.6351 54.508 24 54.9041 24C55.2371 24 55.3613 23.7802 55.4849 23.5015C55.6854 23.0983 64.9874 2.09586 65.3917 1.18247L65.3924 1.1806C65.547 0.719881 65.5989 0.565345 65.4844 0.408077C65.3849 0.269083 65.2318 0.269083 65.0684 0.269083Z"
        fill="#0F2D52"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.627 22.5224L85.6648 22.5799C85.7774 22.7463 85.8385 22.8367 85.8385 23.0599C85.8385 23.4981 85.4142 23.4981 85.2323 23.4981H83.886C83.5976 23.4981 83.3916 23.3831 83.2193 23.1243L77.1015 14.6025H69.7171V22.96C69.7171 23.2318 69.6807 23.4981 69.2461 23.4981H67.8325C67.3595 23.4981 67.3595 23.1209 67.3595 22.96V0.80589C67.3595 0.30743 67.689 0.268402 67.7886 0.268402L76.5832 0.269087C81.0869 0.269087 84.3576 3.34612 84.3576 7.58645C84.3576 10.6792 82.611 13.0572 79.5539 14.1506L79.6787 14.3217C80.6539 15.6579 83.6832 19.8086 85.627 22.5224ZM76.4815 2.41836H69.717V12.4875H76.3133C79.8833 12.4875 82.1022 10.6231 82.1022 7.62069C82.1022 4.60596 79.7385 2.41836 76.4815 2.41836Z"
        fill="#0F2D52"
      />
    </svg>
  );
};
