import {DSP_AccessProductChangeSetNotificationItem} from '../types';

import {DSP_AccessProductChangeSetPartnerNotificationCardViewProps} from './AccessProductChangeSetPartnerNotificationCard.View';

export interface DSP_UseAccessProductChangeSetNotificationCardProps {
  notification: DSP_AccessProductChangeSetNotificationItem;
}

export function useAccessProductChangeSetPartnerNotificationCard({
  notification,
}: DSP_UseAccessProductChangeSetNotificationCardProps): DSP_AccessProductChangeSetPartnerNotificationCardViewProps {
  return {
    notification,
    userId: notification.fromUserId ?? undefined,
  };
}
