import React from 'react';

import {DSL_OrganisationIcon} from '../../Icons';
import {DSL_BaseIconInfo, DSL_BaseIconInfoProps} from '../BaseIconInfo';

export type DSL_OrganisationIconInfoProps = Omit<DSL_BaseIconInfoProps, 'icon'>;

export const DSL_OrganisationIconInfo = (
  props: DSL_OrganisationIconInfoProps,
) => {
  return <DSL_BaseIconInfo icon={<DSL_OrganisationIcon />} {...props} />;
};

DSL_OrganisationIconInfo.displayName = 'DSL_OrganisationIconInfo';

export {
  //
  /** @deprecated use DSL_OrganisationIconInfoProps instead*/
  DSL_OrganisationIconInfoProps as OrganisationIconInfoProps, //
  /** @deprecated use DSL_OrganisationIconInfo instead*/
  DSL_OrganisationIconInfo as OrganisationIconInfo,
};
