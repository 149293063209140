import React from 'react';

import {
  StepLabel as MuiStepLabel,
  StepLabelProps as MuiStepLabelProps,
} from '@mui/material';
import {DSL_FlexRow} from 'components/DesignSystem/Library/Layout/FlexRow';
import {DSL_Body2} from 'components/DesignSystem/Library/Typography/Body2';
import {DSL_Caption} from 'components/DesignSystem/Library/Typography/Caption';
import {DSL_Subtitle2} from 'components/DesignSystem/Library/Typography/Subtitle2';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

import {DSL_Box} from '../../Box';
import {DSL_FlexColumn} from '../../FlexColumn';
import {DSL_FlexRowProps} from '../../FlexRow';

export interface DSL_StepLabelProps
  extends Omit<MuiStepLabelProps, DSL_RestrictedVisualProps> {
  activeStep?: boolean;
  label?: React.ReactNode;
  subLabel?: React.ReactNode;
  subContentJustifyContent?: DSL_FlexRowProps['justifyContent'];
}

export const DSL_StepLabel = ({
  activeStep,
  error,
  label,
  optional,
  subLabel,
  subContentJustifyContent = 'center',
  ...props
}: DSL_StepLabelProps) => {
  return (
    <MuiStepLabel
      sx={{cursor: props.onClick ? 'pointer' : undefined}}
      error={error}
      {...props}
    >
      {activeStep ? (
        <DSL_Subtitle2 bold color={error ? 'error' : 'text.primary'}>
          {label}
        </DSL_Subtitle2>
      ) : (
        <DSL_Body2 color={error ? 'error' : 'text.secondary'}>
          {label}
        </DSL_Body2>
      )}
      {(optional || subLabel) && (
        <DSL_FlexRow justifyContent={subContentJustifyContent}>
          <DSL_Caption
            color={
              error ? 'error' : activeStep ? 'text.secondary' : 'text.disabled'
            }
          >
            <DSL_FlexColumn>
              {optional && <DSL_Box>Optional</DSL_Box>}
              {subLabel && <DSL_Box>{subLabel}</DSL_Box>}
            </DSL_FlexColumn>
          </DSL_Caption>
        </DSL_FlexRow>
      )}
    </MuiStepLabel>
  );
};

DSL_StepLabel.displayName = 'DSL_StepLabel';

export {
  //
  /** @deprecated use DSL_StepLabelProps instead*/
  DSL_StepLabelProps as StepLabelProps, //
  /** @deprecated use DSL_StepLabel instead*/
  DSL_StepLabel as StepLabel,
};
