import React from 'react';

import {
  GridItem,
  UseGridItemStyles,
  SearchAutocompleteInput,
  SearchAutocompleteInputProps,
} from 'components/DesignSystem/Library';

import {DSP_FieldProps} from '../types';

import {
  DSP_UseSearchAutocompleteArgs,
  useSearchAutocomplete,
} from './useSearchAutocomplete';

export interface DSP_SearchAutoCompleteFieldProps
  extends DSP_UseSearchAutocompleteArgs,
    Omit<DSP_FieldProps, 'fieldRef'>,
    UseGridItemStyles,
    Omit<SearchAutocompleteInputProps, 'value' | 'options' | 'fieldRef'>,
    Partial<Pick<SearchAutocompleteInputProps, 'options'>> {}

export const DSP_SearchAutocompleteField = ({
  span,
  currentRecord,
  onOptionSelect,
  mapOptions,
  query,
  queryVariables,
  emptyRecord,
  getOptionLabel,
  validateSearchTerm,
  fixedRecords,
  ...propsOverrides
}: DSP_SearchAutoCompleteFieldProps) => {
  const fieldProps = useSearchAutocomplete({
    currentRecord,
    onOptionSelect,
    mapOptions,
    query,
    queryVariables,
    emptyRecord,
    getOptionLabel,
    validateSearchTerm,
    fixedRecords,
  });

  return (
    <GridItem span={span}>
      <SearchAutocompleteInput {...fieldProps} {...propsOverrides} />
    </GridItem>
  );
};

export {
  //
  /** @deprecated use DSP_SearchAutoCompleteFieldProps instead*/
  DSP_SearchAutoCompleteFieldProps as SearchAutoCompleteFieldProps, //
  /** @deprecated use DSP_SearchAutocompleteField instead*/
  DSP_SearchAutocompleteField as SearchAutocompleteField,
};
