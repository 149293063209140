import React, {ReactNode} from 'react';

import {
  DSL_FlexRow,
  DSL_FlexColumn,
  DSL_Subtitle1,
  DSL_Body2,
  DSL_StarIconButton,
  DSL_CardActionAreaExclusion,
} from 'components/DesignSystem/Library';

import {DSP_BaseVehicleCard} from '../BaseVehicleCard/BaseVehicleCard';

export type DSP_FavouritedVehicleNetworkCardProps = {
  title?: ReactNode;
  subheader?: ReactNode;
  vehicleComponentIds: number[];
  imageUrl?: string | null;
  onClick: () => void;
  onUnfavouriteClick?: () => void;
};

export const DSP_FavouritedVehicleNetworkCard = ({
  vehicleComponentIds,
  title,
  subheader,
  imageUrl,
  onClick,
  onUnfavouriteClick,
}: DSP_FavouritedVehicleNetworkCardProps) => {
  return (
    <DSP_BaseVehicleCard
      onClick={onClick}
      vehicleComponentIds={vehicleComponentIds}
      imageUrl={imageUrl}
    >
      <DSL_FlexRow
        justifyContent={'space-between'}
        alignItems={'flex-start'}
        flexWrap={'nowrap'}
      >
        <DSL_FlexColumn gap={0} flex={1}>
          <DSL_Subtitle1 color={'text.primary'} bold>
            {title}
          </DSL_Subtitle1>
          <DSL_Body2 color={'text.secondary'}>{subheader}</DSL_Body2>
        </DSL_FlexColumn>
        <DSL_FlexRow>
          <DSL_CardActionAreaExclusion>
            <DSL_StarIconButton onClick={onUnfavouriteClick} />
          </DSL_CardActionAreaExclusion>
        </DSL_FlexRow>
      </DSL_FlexRow>
    </DSP_BaseVehicleCard>
  );
};

DSP_FavouritedVehicleNetworkCard.displayName =
  'DSP_FavouritedVehicleNetworkCard';

export {
  //
  /** @deprecated use DSP_FavouritedVehicleNetworkCardProps instead*/
  DSP_FavouritedVehicleNetworkCardProps as FavouritedVehicleNetworkCardProps, //
  /** @deprecated use DSP_FavouritedVehicleNetworkCard instead*/
  DSP_FavouritedVehicleNetworkCard as FavouritedVehicleNetworkCard,
};
