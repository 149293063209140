import {
  CommonValidator,
  jsonPointerSettor,
  schemaJsonPointerSettor,
  validatorExec,
} from '@regulatory-platform/common-utils';
import * as R from 'ramda';
import {FormEventObject, FormMachineContext, FormRecord} from './types';
import {ValidateRecordOptions} from '@regulatory-platform/common-utils/dist';
import {ObjectType} from '@regulatory-platform/common-utils/dist/types';

export default function onChangeEventHandler<T extends ObjectType = FormRecord>(
  context: FormMachineContext<T>,
  event: FormEventObject,
  validator: CommonValidator,
  validateRecordOptions?: ValidateRecordOptions | null,
): FormMachineContext<T> {
  const {fieldRef, value, fieldRefRelation, valueRelation} = event;
  const {record: origRecord, schema: origSchema, shared} = context;
  const isNewRecord = fieldRef.indexOf('/-1/') > -1;
  let record = isNewRecord
    ? origRecord
    : jsonPointerSettor(fieldRef, value)(origRecord);
  if (!isNewRecord && !R.isNil(fieldRefRelation)) {
    record = jsonPointerSettor(fieldRefRelation, valueRelation)(record);
  }
  const schema = isNewRecord
    ? schemaJsonPointerSettor(fieldRef, 'x-origValue', value)(origSchema)
    : origSchema;

  if (validateRecordOptions === null) {
    validateRecordOptions = undefined;
  } else if (validateRecordOptions === undefined) {
    validateRecordOptions = {
      returnCompiledValidators: false,
      recompileValidators: false,
      exactMatchPtrs: [fieldRef],
    };
  }

  const result = validatorExec(
    {
      record,
      origRecord,
      schema,
      isClient: true,
      methodName: context.methodName,
      userProfile: context.authorisations?.userProfile,
      shared: R.mergeRight(R.defaultTo({}, shared), {fieldRef, updating: true}),
      validateRecordOptions,
    },
    validator,
  );
  const nextContext = context;
  if (context.record !== result?.processedRecord) {
    nextContext.record = result?.processedRecord as T;
  }
  if (!R.isNil(result) && context.schema !== result.validatedSchema) {
    nextContext.schema = result?.validatedSchema;
  }
  if (!R.isNil(context.props) && !R.isNil(context.props.apiError)) {
    nextContext.props.apiError = undefined;
  }
  return nextContext;
}
