import React, {ReactNode} from 'react';

import {useRequiredContext} from '../../utils/useRequiredContext';

export type PubSubCallback<TData = unknown> = ({
  event,
  data,
}: {
  event: string;
  data: TData;
}) => void;

export type PubSubSubscriber<TData = unknown> = {
  event: string;
  callback: PubSubCallback<TData>;
};

class PubSub<TData = unknown> {
  private subscribers: Record<string, PubSubCallback<TData>[]> = {};

  constructor() {
    this.subscribers = {};
  }

  subscribe(event: string, callback: PubSubCallback<TData>) {
    this.subscribers[event] ??= [];
    this.subscribers[event].push(callback);
  }

  unsubscribe(event: string, callback: PubSubCallback<TData>) {
    this.subscribers[event] = this.subscribers[event].filter(
      callbackCandidate => callbackCandidate !== callback,
    );
  }

  publish(event: string, data: TData) {
    (this.subscribers[event] ?? []).forEach(callback => {
      callback({event, data});
    });
  }
}

export const PubSubContext = React.createContext<PubSub>(new PubSub());

export function usePubSubContext<TData = unknown>() {
  return useRequiredContext(PubSubContext, 'PubSubContext') as PubSub<TData>;
}

export function DSL_PubSubProvider({children}: {children: ReactNode}) {
  const [pubSub] = React.useState(new PubSub());
  return (
    <PubSubContext.Provider value={pubSub}>{children}</PubSubContext.Provider>
  );
}
DSL_PubSubProvider.displayName = 'DSL_PubSubProvider';
