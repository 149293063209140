import React from 'react';

export const NHVRLogo = () => {
  return (
    <svg
      width="211"
      height="84"
      viewBox="0 0 211 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_602_141)">
        <path
          d="M112.958 48.6969H111.052C110.643 48.6969 110.264 48.7967 110.264 49.4751V75.5146C106.922 71.4341 90.8889 51.8895 88.0056 48.7368C87.6864 48.4275 87.407 48.2978 87.0279 48.2978C86.569 48.2978 86.2397 48.6071 86.2397 49.0361V81.7103C86.2397 82.2989 86.4493 82.5483 86.9681 82.5483H88.9734C89.6119 82.5483 89.6618 82.1592 89.6618 81.7601V55.8503C93.0539 59.9308 109.615 79.8546 111.79 82.5084C112 82.7678 112.279 83.0272 112.788 83.0272C113.437 83.0272 113.666 82.6082 113.666 82.249V49.6248C113.696 49.2357 113.696 48.6969 112.958 48.6969Z"
          fill="white"
        />
        <path
          d="M146.23 48.6969H144.514C143.816 48.6969 143.676 49.0461 143.676 49.7245V65.9469H123.084V49.6746C123.084 49.0561 122.954 48.6969 122.306 48.6969H120.54C119.872 48.6969 119.662 48.9364 119.662 49.6746V81.6604C119.662 82.3587 119.832 82.5383 120.49 82.5383H122.246C122.845 82.5383 123.084 82.3188 123.084 81.7502V69.1793H143.676V81.7601C143.676 82.4086 144.006 82.5483 144.514 82.5483H146.181C146.859 82.5483 147.108 82.4785 147.108 81.7601V49.7245C147.108 48.9962 146.989 48.6969 146.23 48.6969Z"
          fill="white"
        />
        <path
          d="M180.81 48.6969H178.755C178.366 48.6969 178.186 49.0162 177.997 49.4352L166.024 76.6221L153.344 49.2057C153.164 48.7568 152.825 48.6969 152.486 48.6969H150.431C150.241 48.6969 150.032 48.6969 149.892 48.8865C149.742 49.096 149.822 49.3454 149.922 49.6248C150.53 50.9417 164.897 81.9696 165.176 82.5184C165.256 82.7478 165.456 83.2766 166.034 83.2766C166.523 83.2766 166.703 82.9573 166.882 82.5483C167.172 81.9597 180.69 51.3607 181.279 50.0238C181.508 49.3554 181.578 49.1259 181.419 48.8964C181.269 48.6969 181.05 48.6969 180.81 48.6969Z"
          fill="white"
        />
        <path
          d="M210.741 81.2014L210.681 81.1216C207.718 76.9812 203.028 70.5362 201.851 68.92C206.291 67.3237 208.835 63.8617 208.835 59.3522C208.835 53.1765 204.086 48.6869 197.541 48.6869H184.761C184.611 48.6869 184.132 48.7468 184.132 49.4751V81.7601C184.132 81.9996 184.132 82.5483 184.821 82.5483H186.876C187.504 82.5483 187.564 82.1592 187.564 81.7601V69.5784H198.299L207.189 81.9996C207.438 82.3787 207.738 82.5483 208.157 82.5483H210.112C210.371 82.5483 210.99 82.5483 210.99 81.9098C211 81.5805 210.91 81.4508 210.741 81.2014ZM187.564 51.8296H197.392C202.121 51.8296 205.563 55.0122 205.563 59.412C205.563 63.7819 202.34 66.5056 197.152 66.5056H187.564V51.8296Z"
          fill="white"
        />
        <path
          d="M3.48191 55.4113C6.72438 54.3837 45.8136 40.3663 45.8136 40.3663C59.2025 36.1261 54.9624 22.7372 54.9624 22.7372L47.3401 0C47.3401 0 53.4259 0.0399073 54.6132 3.80117C54.9923 4.99839 60.1403 19.9337 60.1403 19.9337C66.5155 40.057 47.5496 45.8834 47.5496 45.8834L0 62.5747C0 62.5747 0.229467 56.4389 3.48191 55.4113Z"
          fill="white"
        />
        <path
          d="M153.793 12.1019C150.54 13.1295 79.0464 36.2359 79.0464 36.2359C65.6575 40.486 69.8977 53.8749 69.8977 53.8749L72.9705 63.5724C72.9705 63.5724 66.8847 63.5325 65.6974 59.7712C65.3183 58.574 64.7197 56.6684 64.7197 56.6684C58.3345 36.5451 77.3005 30.7286 77.3005 30.7286L157.275 4.93853C157.275 4.92855 157.045 11.0743 153.793 12.1019Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_602_141">
          <rect width="211" height="83.2766" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
