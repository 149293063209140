import React from 'react';

import {DSP_UserEntityAvatarWithNameView} from './UserEntityAvatarWithName.View';
import {useUserEntityAvatarWithName} from './useUserEntityAvatarWithName';

export const DSP_UserEntityAvatarWithName = () => {
  const viewProps = useUserEntityAvatarWithName();

  return <DSP_UserEntityAvatarWithNameView {...viewProps} />;
};

DSP_UserEntityAvatarWithName.displayName = 'DSP_UserEntityAvatarWithName';
