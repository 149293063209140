import React from 'react';

import {
  ListSubheader as MuiListSubheader,
  ListSubheaderProps as MuiListSubheaderProps,
} from '@mui/material';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

export type DSL_ListSubheaderProps = Omit<
  MuiListSubheaderProps,
  DSL_RestrictedVisualProps
>;

export const DSL_ListSubheader = (props: DSL_ListSubheaderProps) => {
  return <MuiListSubheader component={'span'} {...props} />;
};

DSL_ListSubheader.displayName = 'DSL_ListSubheader';

export {
  //
  /** @deprecated use DSL_ListSubheaderProps instead*/
  DSL_ListSubheaderProps as ListSubheaderProps, //
  /** @deprecated use DSL_ListSubheader instead*/
  DSL_ListSubheader as ListSubheader,
};
