import React from 'react';

import {
  ListItemIcon as MuiListItemIcon,
  ListItemIconProps as MuiListItemIconProps,
} from '@mui/material';

export type DSL_PopperListItemIconProps = MuiListItemIconProps;

export const DSL_PopperListItemIcon = (props: DSL_PopperListItemIconProps) => {
  return <MuiListItemIcon sx={{minWidth: 'auto'}} color="action" {...props} />;
};

DSL_PopperListItemIcon.displayName = 'DSL_PopperListItemIcon';

export {
  //
  /** @deprecated use DSL_PopperListItemIconProps instead*/
  DSL_PopperListItemIconProps as PopperListItemIconProps, //
  /** @deprecated use DSL_PopperListItemIcon instead*/
  DSL_PopperListItemIcon as PopperListItemIcon,
};
