import React from 'react';

import {DSL_IconWrapper} from '../IconWrapper';
import {DSL_IconProps} from '../types';

const DSL_Mass = (props: DSL_IconProps) => {
  return (
    <DSL_IconWrapper
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          fill="none"
          viewBox="0 0 24 24"
          {...props}
        >
          <path
            fill="#000"
            fillOpacity={0.54}
            fillRule="evenodd"
            d="M4 6h1v2H3.714v6H13V8h-2V6h3a2 2 0 0 1 2 2h1.281a1 1 0 0 1 .904.572l1.041 2.197H21a1 1 0 0 1 1 1V15a1 1 0 0 1-1 1h-2.05q.05.243.05.5a2.5 2.5 0 1 1-4.95-.5h-5.1q.05.243.05.5a2.5 2.5 0 1 1-4.95-.5H2V8a2 2 0 0 1 2-2m2.5 10a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1m10 0a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1m4-2v-2h-2.315L17 9.5h-2V14z"
            clipRule="evenodd"
          />
          <path
            fill="#000"
            fillOpacity={0.54}
            d="M9.745 8.865 10.76 9.88l-2.88 2.88L5 9.88l1.015-1.015 1.145 1.137V7H8.6v3.002z"
          />
        </svg>
      }
      {...props}
    />
  );
};

export default React.memo(DSL_Mass);
