import React from 'react';

import {DSL_ArrowForwardIcon} from '../../../Icons';
import {DSL_BaseIconButton, DSL_BaseIconButtonProps} from '../BaseIconButton';

export type DSL_ForwardIconButtonProps = Omit<
  DSL_BaseIconButtonProps,
  'color' | 'children'
>;

export const DSL_ForwardIconButton = (
  props: DSL_ForwardIconButtonProps,
): JSX.Element => {
  return (
    <DSL_BaseIconButton aria-label="forward" {...props}>
      <DSL_ArrowForwardIcon />
    </DSL_BaseIconButton>
  );
};

DSL_ForwardIconButton.displayName = 'DSL_ForwardIconButton';

export {
  //
  /** @deprecated use DSL_ForwardIconButtonProps instead*/
  DSL_ForwardIconButtonProps as ForwardIconButtonProps, //
  /** @deprecated use DSL_ForwardIconButton instead*/
  DSL_ForwardIconButton as ForwardIconButton,
};
