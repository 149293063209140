import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import DataUsage from '@mui/icons-material/Dashboard';

export default {
  path: '/reference-data',
  component: lazy(
    () => import(/* webpackChunkName: "ReferenceData" */ './ReferenceData'),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'PBS Reference Data',
  menuIcon: DataUsage,
  authorisation: {
    apiKey: null,
    policies: [
      {
        role: 'operationsPBSAdmin',
      },
    ] as AuthorisationPolicy[],
  },
  sections: [],
};
