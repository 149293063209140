import React from 'react';

import {DSL_FullscreenIcon} from '../../../Icons';
import {DSL_BaseIconButton, DSL_BaseIconButtonProps} from '../BaseIconButton';

export type DSL_FullscreenIconButtonProps = Omit<
  DSL_BaseIconButtonProps,
  'color' | 'children'
>;

export const DSL_FullscreenIconButton = (
  props: DSL_FullscreenIconButtonProps,
): JSX.Element => {
  return (
    <DSL_BaseIconButton aria-label={'fullscreen'} {...props}>
      <DSL_FullscreenIcon fontSize={props.size} />
    </DSL_BaseIconButton>
  );
};

DSL_FullscreenIconButton.displayName = 'DSL_FullscreenIconButton';

export {
  //
  /** @deprecated use DSL_FullscreenIconButtonProps instead*/
  DSL_FullscreenIconButtonProps as FullscreenIconButtonProps, //
  /** @deprecated use DSL_FullscreenIconButton instead*/
  DSL_FullscreenIconButton as FullscreenIconButton,
};
