import React from 'react';

import {
  DSL_GridItem,
  DSL_UseGridItemStyles,
  DSL_RadioGroupInput,
  DSL_RadioGroupInputProps,
} from 'components/DesignSystem/Library';

import {DSP_FieldProps} from '../types';

import {useRadioGroup} from './useRadioGroup';

export type DSP_RadioGroupFieldProps = DSL_RadioGroupInputProps &
  DSP_FieldProps &
  DSL_UseGridItemStyles;
export const DSP_RadioGroupField = ({
  fieldRef,
  span,
  ...propsOverrides
}: DSP_RadioGroupFieldProps) => {
  const fieldProps = useRadioGroup({fieldRef});

  return (
    <DSL_GridItem span={span}>
      <DSL_RadioGroupInput {...fieldProps} {...propsOverrides} />
    </DSL_GridItem>
  );
};

export {
  /** @deprecated use DSP_RadioGroupField instead*/
  DSP_RadioGroupField as RadioGroupField,
};
