import React, {ForwardedRef, forwardRef} from 'react';

import {
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Theme,
  useTheme,
} from '@mui/material';

import {DSL_RestrictedStyleProps} from '../../types';

export type DSL_AppBarProps = Omit<MuiAppBarProps, DSL_RestrictedStyleProps>;

export const DSL_AppBar = forwardRef(
  (props: DSL_AppBarProps, ref: ForwardedRef<HTMLElement>) => {
    const theme: Theme = useTheme();

    return (
      <MuiAppBar
        ref={ref}
        position="relative"
        elevation={theme.elevation.appBar}
        sx={{
          zIndex: theme.zIndex.appBar,
          backgroundColor: 'background.paper',
        }}
        {...props}
      />
    );
  },
);

DSL_AppBar.displayName = 'DSL_AppBar';

export {
  //
  /** @deprecated use DSL_AppBarProps instead*/
  DSL_AppBarProps as AppBarProps, //
  /** @deprecated use DSL_AppBar instead*/
  DSL_AppBar as AppBar,
};
