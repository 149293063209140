import React from 'react';

import {
  DSL_FlexColumn,
  DSL_FlexColumnProps,
} from 'components/DesignSystem/Library/Layout/FlexColumn';

export type DSL_SummaryFieldGroupProps = DSL_FlexColumnProps;

export const DSL_SummaryFieldGroup = (props: DSL_SummaryFieldGroupProps) => {
  return <DSL_FlexColumn gap={2} {...props} />;
};

DSL_SummaryFieldGroup.displayName = 'DSL_SummaryFieldGroup';

export {
  //
  /** @deprecated use DSL_SummaryFieldGroupProps instead*/
  DSL_SummaryFieldGroupProps as SummaryFieldGroupProps, //
  /** @deprecated use DSL_SummaryFieldGroup instead*/
  DSL_SummaryFieldGroup as SummaryFieldGroup,
};
