import {usePopover} from 'components/DesignSystem/Library';

import {useUserEntityLoaderContext} from '../UserEntityLoaderProvider';
import {useUserEntityMapper} from '../useUserEntityMapper';

export function useUserEntityAvatarWithName() {
  const popoverProps = usePopover<HTMLDivElement>();
  const {entity: user, isLoading} = useUserEntityLoaderContext();
  const userProps = useUserEntityMapper({user});

  return {
    ...popoverProps,
    ...userProps,
    isLoading,
  };
}
