import React, {useEffect, useState} from 'react';

import {Breakpoint} from '@mui/material';
import {pluralizeLabel} from 'components/DesignSystem/utils/pluralizeLabel';

import {
  DSL_BorderlessCard,
  DSL_ButtonCardActionArea,
  DSL_CardAdornmentLayout,
  DSL_CardContent,
  DSL_FlexRow,
  DSL_FlexColumn,
  DSL_Subtitle2,
  DSL_Body2,
  DSL_Flex,
  DSL_ChevronRightIcon,
  DSL_CardActionAreaExclusion,
  DSL_Fade,
  DSL_RemoveCircleIconButton,
  DSL_Tooltip,
} from 'components/DesignSystem/Library';

import {DSP_SegmentAccessTypeIcon} from '../SegmentAccessTypeIcon';
import {DSP_Road} from '../types';

type DSP_SelectedSegmentsRoadCardProps = {
  road: DSP_Road;
  onClick: (index: number) => void;
  onRemoveSegments?: (segmentIds: number[]) => void;
  currentBreakpoint: Breakpoint;
  onSegmentHover: (segmentIds: number[]) => void;
  onSegmentHoverOut: () => void;
};

export const DSP_SelectedSegmentsRoadCard: React.FC<
  DSP_SelectedSegmentsRoadCardProps
> = ({
  road,
  onClick,
  onRemoveSegments,
  currentBreakpoint,
  onSegmentHover,
  onSegmentHoverOut,
}) => {
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    if (isHovering) {
      onSegmentHover(road?.segmentIds);
    } else {
      onSegmentHoverOut();
    }
  }, [isHovering, road.segmentIds, onSegmentHover, onSegmentHoverOut]);

  return (
    <DSL_BorderlessCard
      square
      onMouseOver={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <DSL_ButtonCardActionArea onClick={() => onClick(road.id)} disableRipple>
        <DSL_CardAdornmentLayout
          endAdornment={<DSL_ChevronRightIcon color={'action'} />}
        >
          <DSL_CardContent padding={'SM'}>
            <DSL_FlexRow
              justifyContent={'space-between'}
              flexWrap={'nowrap'}
              gap={2}
            >
              <DSL_FlexRow flexWrap={'nowrap'} alignItems={'center'} gap={3}>
                <DSL_FlexRow flexWrap={'nowrap'} alignItems={'center'} gap={1}>
                  {onRemoveSegments && (
                    <DSL_Fade in={isHovering}>
                      <DSL_Tooltip title={'Remove segments from the selection'}>
                        <DSL_FlexRow>
                          <DSL_CardActionAreaExclusion>
                            <DSL_RemoveCircleIconButton
                              onClick={() => onRemoveSegments(road?.segmentIds)}
                            />
                          </DSL_CardActionAreaExclusion>
                        </DSL_FlexRow>
                      </DSL_Tooltip>
                    </DSL_Fade>
                  )}
                  <DSL_FlexColumn gap={0} flexGrow={1}>
                    <DSL_Subtitle2 ellipsisLines={1}>
                      {road?.name}
                    </DSL_Subtitle2>
                    <DSL_Body2 ellipsisLines={1} color={'text.primary'}>
                      {road?.suburb}
                    </DSL_Body2>
                  </DSL_FlexColumn>
                </DSL_FlexRow>
              </DSL_FlexRow>
              <DSL_FlexRow
                flexGrow={0}
                flexWrap={'nowrap'}
                gap={1}
                mr={currentBreakpoint === 'xs' ? -2 : 0}
              >
                <DSL_Flex
                  alignItems={'center'}
                  justifyContent={'flex-start'}
                  width={currentBreakpoint !== 'xs' ? '124px' : '36px'}
                >
                  <DSL_Body2 color={'text.secondary'}>{`${
                    road?.segmentIds.length
                  } ${
                    currentBreakpoint !== 'xs'
                      ? pluralizeLabel('segment', road?.segmentIds.length)
                      : ''
                  }`}</DSL_Body2>
                </DSL_Flex>
                <DSL_Flex alignItems={'center'}>
                  <DSP_SegmentAccessTypeIcon />
                </DSL_Flex>
              </DSL_FlexRow>
            </DSL_FlexRow>
          </DSL_CardContent>
        </DSL_CardAdornmentLayout>
      </DSL_ButtonCardActionArea>
    </DSL_BorderlessCard>
  );
};

DSP_SelectedSegmentsRoadCard.displayName = 'DSP_SelectedSegmentsRoadCard';
