import Pbs from './Pbs';
import Accreditation from './Accreditation';
import OperationManual from './OperationManual';
import Afm from './Afm';

export const DocumentTemplateRoutes = [
  ...Pbs,
  ...Accreditation,
  ...OperationManual,
  ...Afm,
];
