import React from 'react';

import {InputAdornment, InputProps as InputPropsType} from '@mui/material';
import * as R from 'ramda';
import {NumericFormat} from 'react-number-format';

import {DSL_NumberInput, DSL_NumberInputProps} from '../NumberInput';

// TODO: This component is a work in progress and potentially doesn't even belong in the design system.

interface DSL_NumberFormatCustomProps {
  inputRef: (instance: React.Ref<number> | null) => void;
  onChange: (
    event: {
      currentTarget: {
        value?: string;
      };
    },
    numericValue?: number,
  ) => void;
  name: string;
  step?: number;
  decimalScale?: number;
  min?: number;
  max?: number;
}

const DSL_NumberFormatCustom = React.forwardRef(
  (props: DSL_NumberFormatCustomProps, ref) => {
    const {onChange, ...other} = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values): void => {
          onChange(
            {
              currentTarget: {
                value: values.value,
              },
            },
            values.floatValue,
          );
        }}
        thousandSeparator={props.step === undefined}
        decimalScale={props.decimalScale}
      />
    );
  },
);

DSL_NumberFormatCustom.displayName = 'DSL_NumberFormatCustom';

export interface DSL_FormattedNumberInputProps
  extends Omit<DSL_NumberInputProps, 'onChange'>,
    Omit<DSL_NumberFormatCustomProps, 'inputRef' | 'name'> {
  uom?: string;
  uomConversion?: number;
}

export const DSL_FormattedNumberInput = ({
  uom,
  decimalScale,
  step,
  min,
  max,
  uomConversion = 1,
  ...restProps
}: DSL_FormattedNumberInputProps) => {
  const InputProps: InputPropsType = {
    inputProps: {},
  };

  InputProps.inputComponent = DSL_NumberFormatCustom as never;

  if (uom) {
    InputProps.endAdornment = (
      <InputAdornment position="end">{uom}</InputAdornment>
    );
  }

  if (!R.isNil(decimalScale)) {
    let modifier = 0;
    if (uomConversion === 10) {
      modifier = -1;
    }
    if (uomConversion === 100) {
      modifier = -2;
    }
    if (uomConversion === 1000) {
      modifier = -3;
    }
    InputProps.inputProps = {
      ...(InputProps.inputProps || {}),
      decimalScale: decimalScale - modifier,
    };
  }
  if (step) {
    InputProps.inputProps = {
      ...(InputProps.inputProps || {}),
      step: step * uomConversion,
    };
  }

  if (min) {
    InputProps.inputProps = {
      ...(InputProps.inputProps || {}),
      min: min * uomConversion,
    };
  }

  if (max) {
    InputProps.inputProps = {
      ...(InputProps.inputProps || {}),
      max: max * uomConversion,
    };
  }

  return <DSL_NumberInput InputProps={InputProps} {...restProps} />;
};

DSL_FormattedNumberInput.displayName = 'DSL_FormattedNumberInput';

export {
  //
  /** @deprecated use DSL_FormattedNumberInputProps instead*/
  DSL_FormattedNumberInputProps as FormattedNumberInputProps, //
  /** @deprecated use DSL_FormattedNumberInput instead*/
  DSL_FormattedNumberInput as FormattedNumberInput,
};
