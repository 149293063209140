import React from 'react';

import {TabList as MuiTabList, TabListProps as MuiTabListProps} from '@mui/lab';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

import {useTabList} from './useTabList';

export interface DSL_TabListProps
  extends Omit<MuiTabListProps, DSL_RestrictedVisualProps> {
  indicatorAnchor?: 'top' | 'bottom';
  error?: boolean;
}

export const DSL_TabList = ({
  indicatorAnchor = 'bottom',
  error,
  ...props
}: DSL_TabListProps) => {
  const {TabIndicatorProps} = useTabList(indicatorAnchor);

  return (
    <MuiTabList
      TabIndicatorProps={TabIndicatorProps}
      {...props}
      sx={{
        '& .MuiTabs-indicator': {
          bgcolor: error ? 'error.main' : 'primary.main',
        },
      }}
    />
  );
};

DSL_TabList.displayName = 'DSL_TabList';

export {
  //
  /** @deprecated use DSL_TabListProps instead*/
  DSL_TabListProps as TabListProps, //
  /** @deprecated use DSL_TabList instead*/
  DSL_TabList as TabList,
};
