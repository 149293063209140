import React from 'react';

import {
  DSL_MinimizeableNonModalDrawer,
  DSL_Divider,
} from 'components/DesignSystem/Library';

import {useDspSelectedSegmentsDrawerContext} from './SelectedSegmentsDrawer.Provider';
import {DSP_SelectedSegmentsDrawerContents} from './SelectedSegmentsDrawerContents';
import {DSP_SelectedSegmentsDrawerHeader} from './SelectedSegmentsDrawerHeader';
import {DSP_SelectedSegmentsMinimizedDrawerHeader} from './SelectedSegmentsMinimizedDrawerHeader';

export interface DSP_SelectedSegmentsDrawerProps {
  open?: boolean;
  onClose: () => void;
  onRemoveSegment?: (segmentId: number) => void;
  onRemoveSegments?: (segmentIds: number[]) => void;
  showChangeOptions?: boolean;
}

export const DSP_SelectedSegmentsDrawer = ({
  open,
  onClose,
  onRemoveSegment,
  onRemoveSegments,
  showChangeOptions,
}: DSP_SelectedSegmentsDrawerProps) => {
  const {isViewportSmall, isMinimized, toggleMinimizeDrawer} =
    useDspSelectedSegmentsDrawerContext();
  return (
    <DSL_MinimizeableNonModalDrawer
      open={open}
      size={isViewportSmall ? 'FULL' : 'SM'}
      isMinimized={isMinimized}
    >
      {isMinimized ? (
        <DSP_SelectedSegmentsMinimizedDrawerHeader
          isMinimized={isMinimized}
          onClose={onClose}
          toggleMinimizeDrawer={toggleMinimizeDrawer}
        />
      ) : (
        <>
          <DSP_SelectedSegmentsDrawerHeader
            isMinimized={isMinimized}
            onClose={onClose}
            toggleMinimizeDrawer={toggleMinimizeDrawer}
          />
          <DSL_Divider />
          <DSP_SelectedSegmentsDrawerContents
            showChangeOptions={showChangeOptions}
            onRemoveSegment={onRemoveSegment}
            onRemoveSegments={onRemoveSegments}
          />
        </>
      )}
    </DSL_MinimizeableNonModalDrawer>
  );
};

DSP_SelectedSegmentsDrawer.displayName = 'DSP_SelectedSegmentsDrawer';
