import * as React from 'react';
import {ComponentProps, ForwardedRef} from 'react';

import {SvgIcon} from '@mui/material';

export interface DSL_IconWrapperProps
  extends Omit<ComponentProps<typeof SvgIcon>, 'sx'> {
  icon: React.ReactNode;
}

export const DSL_IconWrapper = React.forwardRef(
  (
    {icon, color, ...props}: DSL_IconWrapperProps,
    ref: ForwardedRef<SVGSVGElement>,
  ) => {
    const fill = color ? 'currentColor' : undefined;

    return (
      <SvgIcon
        ref={ref}
        color={color}
        sx={{
          '& svg': {fill},
          '& path': {fill},
        }}
        {...props}
      >
        {icon}
      </SvgIcon>
    );
  },
);

DSL_IconWrapper.displayName = 'DSL_IconWrapper';

export {
  /** @deprecated use DSL_IconWrapper instead*/
  DSL_IconWrapper as IconWrapper,
};
