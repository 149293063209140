import React, {useCallback} from 'react';

export function useMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);
  const handleOpenMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget),
    [],
  );
  const handleCloseMenu = useCallback(() => setAnchorEl(null), []);
  return {isOpen, anchorEl, handleOpenMenu, handleCloseMenu};
}
