// import Register from 'containers/Register';
import {HomeOutlined} from '@mui/icons-material';
import ChangeAccount from 'containers/ChangeAccount';
import CreateAccount from 'containers/CreateAccount';
import Home from 'containers/Home';
import InspectionFile from 'containers/InspectionFile';
import Logout from 'containers/Logout';
import ResetPassword from 'containers/ResetPassword';
import Status from 'containers/Status';
import Verify from 'containers/Verify';
import B2CLogin from './B2CLogin';
import TermsAndConditions from 'containers/TermsAndConditions';
export default {
  routes: [
    Home,
    ResetPassword,
    B2CLogin,
    Status,
    Verify,
    ChangeAccount,
    Logout,
    InspectionFile,
    TermsAndConditions,
    CreateAccount,
  ],
  menuTitle: 'Home',
  menuDescription: 'Home',
  path: '/',
  showChildRoutesInMenu: false,
  menuIcon: HomeOutlined,
};
