import React from 'react';

import {DSL_StarIcon} from '../../../Icons';
import {DSL_BaseIconButton, DSL_BaseIconButtonProps} from '../BaseIconButton';

export type DSL_StarIconButtonProps = Omit<
  DSL_BaseIconButtonProps,
  'color' | 'children'
>;

export const DSL_StarIconButton = (
  props: DSL_StarIconButtonProps,
): JSX.Element => {
  return (
    <DSL_BaseIconButton aria-label="save" {...props}>
      <DSL_StarIcon fontSize={props.size} />
    </DSL_BaseIconButton>
  );
};

DSL_StarIconButton.displayName = 'DSL_StarIconButton';

export {
  //
  /** @deprecated use DSL_StarIconButtonProps instead*/
  DSL_StarIconButtonProps as StarIconButtonProps, //
  /** @deprecated use DSL_StarIconButton instead*/
  DSL_StarIconButton as StarIconButton,
};
