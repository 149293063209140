import React, {FormEventHandler, ReactNode} from 'react';

import {DSP_FormContextProps} from '../FormProvider';

export interface DSP_SimpleFormContainerViewProps {
  children?: ReactNode | ((context: DSP_FormContextProps) => ReactNode);
  onSubmit?: FormEventHandler<HTMLFormElement>;
}

/**
 * Container for basic forms that don't require any page layout, like the login form
 */
export const DSP_SimpleFormContainerView = ({
  children,
  onSubmit,
}: DSP_SimpleFormContainerViewProps) => {
  return (
    <form
      data-name={'simple-form-container'}
      noValidate
      autoComplete="off"
      onSubmit={onSubmit}
      style={{width: '100%'}}
    >
      {children}
    </form>
  );
};

export {
  //
  /** @deprecated use DSP_SimpleFormContainerViewProps instead*/
  DSP_SimpleFormContainerViewProps as SimpleFormContainerViewProps, //
  /** @deprecated use DSP_SimpleFormContainerView instead*/
  DSP_SimpleFormContainerView as SimpleFormContainerView,
};
