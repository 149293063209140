import Layers from '@mui/icons-material/Layers';
import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/standalone/:networkId/networkSegments',
  component: lazy(
    () => import(/* webpackChunkName: "NetworkSegments" */ './NetworkSegments'),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Import network segments',
  menuDescription: 'Data table for the segments associated with the network',
  menuIcon: Layers,
  parent: true,
  authorisation: {
    apiKey: 'NetworkSegmentWithRelations.findById',
    policies: [
      {
        role: 'partnerSpatialRead',
      },
      {
        role: 'operationsSpatialRead',
      },
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: '< Back',
      to: '../view',
    },
  ],
};
