import React from 'react';

import {DSL_LayersIcon} from 'components/DesignSystem/Library/Icons';

import {
  DSL_BaseCardIconButton,
  DSL_BaseCardIconButtonProps,
} from '../BaseCardIconButton';

type DSL_LayersCardIconButtonProps = Omit<
  DSL_BaseCardIconButtonProps,
  'children'
>;

export const DSL_LayersCardIconButton = (
  props: DSL_LayersCardIconButtonProps,
) => {
  return (
    <DSL_BaseCardIconButton {...props}>
      <DSL_LayersIcon color={props.disabled ? 'disabled' : 'action'} />
    </DSL_BaseCardIconButton>
  );
};

DSL_LayersCardIconButton.displayName = 'DSL_LayersCardIconButton';
