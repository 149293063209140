import React, {ReactNode} from 'react';

import {DSL_Box} from '../../Layout/Box';
import {DSL_SkeletonLoader} from '../../Skeleton/SkeletonLoader';

export interface DSL_EntitySubtitleProps {
  isLoading: boolean;
  children?: ReactNode;
}

export const DSL_EntitySubtitle = ({
  isLoading,
  children,
}: DSL_EntitySubtitleProps) => (
  <DSL_SkeletonLoader isLoading={isLoading} width={300} height={22}>
    <DSL_Box component={'span'} color={'text.secondary'}>
      {children}
    </DSL_Box>
  </DSL_SkeletonLoader>
);

DSL_EntitySubtitle.displayName = 'DSL_EntitySubtitle';

export {
  //
  /** @deprecated use DSL_EntitySubtitleProps instead*/
  DSL_EntitySubtitleProps as EntitySubtitleProps, //
  /** @deprecated use DSL_EntitySubtitle instead*/
  DSL_EntitySubtitle as EntitySubtitle,
};
