import React, {useState} from 'react';

export const useMenu = ({
  initialOpen = false,
}: {initialOpen?: boolean} = {}) => {
  const [menuState, setMenuState] = useState<{
    open: boolean;
    anchorEl: null | HTMLElement;
  }>({open: initialOpen, anchorEl: null});

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuState({open: true, anchorEl: event.currentTarget});
  };
  const onClose = () => {
    setMenuState({open: false, anchorEl: null});
  };

  return {
    handleOpen,
    menuProps: {
      onClose,
      ...menuState,
    },
  };
};
