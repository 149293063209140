import React from 'react';

import {DSL_SelectInput} from 'components/DesignSystem/Library';

import {useAccessTypeSelect} from './useAccessTypeSelect';

export const DSP_AccessTypeSelect = () => {
  const props = useAccessTypeSelect();

  return <DSL_SelectInput label={'Access Type'} size={'small'} {...props} />;
};
DSP_AccessTypeSelect.displayName = 'DSP_AccessTypeSelect';
