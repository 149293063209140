import React, {FC, ReactNode} from 'react';

import {TabPanel, TabPanelProps} from '@mui/lab';

import {DSL_RestrictedVisualProps} from '../types';

import {DSL_DrawerContainer} from './DrawerContainer';

export interface DSL_DrawerTabPanelProps
  extends Omit<TabPanelProps, DSL_RestrictedVisualProps> {
  children?: ReactNode;
}

export const DSL_DrawerTabPanel: FC<DSL_DrawerTabPanelProps> = ({
  children,
  ...props
}: DSL_DrawerTabPanelProps): JSX.Element => {
  return (
    <TabPanel
      sx={{
        padding: 0,
        height: '100%',
      }}
      {...props}
    >
      <DSL_DrawerContainer width={'100%'} height={'100%'}>
        {children}
      </DSL_DrawerContainer>
    </TabPanel>
  );
};

export {
  //
  /** @deprecated use DSL_DrawerTabPanelProps instead*/
  DSL_DrawerTabPanelProps as DrawerTabPanelProps, //
  /** @deprecated use DSL_DrawerTabPanel instead*/
  DSL_DrawerTabPanel as DrawerTabPanel,
};
