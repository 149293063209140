import React from 'react';

import {List as MuiList, ListProps as MuiListProps} from '@mui/material';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

export type DSL_PopperSubListProps = Omit<
  MuiListProps,
  DSL_RestrictedVisualProps | 'component'
>;

export const DSL_PopperSubList = (props: DSL_PopperSubListProps) => {
  return (
    <MuiList component="ul" disablePadding sx={{px: 0, py: 0}} {...props} />
  );
};

DSL_PopperSubList.displayName = 'DSL_PopperSubList';

export {
  //
  /** @deprecated use DSL_PopperSubListProps instead*/
  DSL_PopperSubListProps as PopperSubListProps, //
  /** @deprecated use DSL_PopperSubList instead*/
  DSL_PopperSubList as PopperSubList,
};
