import React from 'react';

import {Fade as MuiFade, FadeProps as MuiFadeProps} from '@mui/material';

import {DSL_RestrictedVisualProps} from '../../types';

export type DSL_FadeProps = Omit<MuiFadeProps, DSL_RestrictedVisualProps>;

export const DSL_Fade = (props: DSL_FadeProps) => {
  return <MuiFade {...props} />;
};

DSL_Fade.displayName = 'DSL_Fade';
