import {useEffect} from 'react';

import {VehicleNetworkMapSearchParams} from 'containers-map/VehicleNetworkMap/constants';
import {useSearchParams} from 'react-router-dom';
import {useBoolean} from 'usehooks-ts';

export const useSelectedSegmentsMinimizeDrawer = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const {toggle: toggleMinimizeDrawer, value: isMinimized} = useBoolean(false);

  useEffect(() => {
    searchParams.set(
      VehicleNetworkMapSearchParams.SELECTED_SEGMENTS_DRAWER,
      'true',
    );
    setSearchParams(searchParams);

    if (isMinimized) {
      searchParams.set(
        VehicleNetworkMapSearchParams.IS_SELECTED_SEGMENTS_DRAWER_MINIMIZED,
        isMinimized.toString(),
      );
      setSearchParams(searchParams);
    } else {
      searchParams.delete(
        VehicleNetworkMapSearchParams.IS_SELECTED_SEGMENTS_DRAWER_MINIMIZED,
      );
      setSearchParams(searchParams);
    }

    return () => {
      searchParams.delete(
        VehicleNetworkMapSearchParams.SELECTED_SEGMENTS_DRAWER,
      );
      searchParams.delete(
        VehicleNetworkMapSearchParams.IS_SELECTED_SEGMENTS_DRAWER_MINIMIZED,
      );
      setSearchParams(searchParams);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMinimized, searchParams, setSearchParams]);

  return {
    toggleMinimizeDrawer,
    isMinimized,
  };
};
