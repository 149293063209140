import React from 'react';

import {DSL_BaseTypography, DSL_BaseTypographyProps} from '../BaseTypography';

export type DSL_Heading3Props = Omit<DSL_BaseTypographyProps, 'variant'>;
export const DSL_Heading3 = (props: DSL_Heading3Props) => (
  <DSL_BaseTypography color={'text.primary'} {...props} variant={'h3'} />
);

export {
  //
  /** @deprecated use DSL_Heading3Props instead*/
  DSL_Heading3Props as Heading3Props, //
  /** @deprecated use DSL_Heading3 instead*/
  DSL_Heading3 as Heading3,
};
