import {usePopover} from 'components/DesignSystem/Library';

import {useRegulatorAccountEntityLoaderContext} from '../RegulatorAccountEntityLoaderProvider';
import {useRegulatorAccountEntityMapper} from '../useRegulatorAccountEntityMapper';

export function useRegulatorAccountEntityName() {
  const popoverProps = usePopover<HTMLDivElement>();
  const {entity: regulatorAccount, isLoading} =
    useRegulatorAccountEntityLoaderContext();
  const {src, ...userProps} = useRegulatorAccountEntityMapper({
    regulatorAccount,
  });

  return {
    ...popoverProps,
    ...userProps,
    isLoading,
  };
}
