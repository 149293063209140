import React, {forwardRef} from 'react';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

import {DSL_BaseListItem, DSL_BaseListItemProps} from './BaseListItem';

export interface DSL_ListItemProps
  extends Omit<
    DSL_BaseListItemProps,
    DSL_RestrictedVisualProps | 'component'
  > {}

export const DSL_ListItem = forwardRef<HTMLLIElement, DSL_ListItemProps>(
  (props, ref) => {
    return <DSL_BaseListItem ref={ref} {...props} />;
  },
);

DSL_ListItem.displayName = 'DSL_ListItem';

export {
  //
  /** @deprecated use DSL_ListItemProps instead*/
  DSL_ListItemProps as ListItemProps, //
  /** @deprecated use DSL_ListItem instead*/
  DSL_ListItem as ListItem,
};
