import React from 'react';
import {useStyles} from '../../form.styles';

type ButtonStackProps = {
  children: React.ReactNode;
  flexStart?: boolean;
  floatRight?: boolean;
};

/**
 * Stacking container for buttons
 */
const ButtonStack: React.FC<ButtonStackProps> = ({
  children,
  flexStart,
  floatRight,
}: ButtonStackProps) => {
  const classes = useStyles();
  return (
    <div
      data-name="button-stack"
      className={
        flexStart
          ? classes.buttonStackFlex
          : floatRight
          ? classes.buttonStackRight
          : classes.buttonStack
      }
    >
      {children}
    </div>
  );
};

export default ButtonStack;
