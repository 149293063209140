import React, {ReactNode} from 'react';

import {DSL_FlexColumn} from '../Layout/FlexColumn';
import {DSL_FlexRow} from '../Layout/FlexRow';
import {DSL_Body2} from '../Typography/Body2';

import {
  DSL_ProgressBarSegment,
  DSL_ProgressBarSegmentProps,
} from './ProgressBarSegment';
import {DSL_ProgressBarSegmentLabel} from './ProgressBarSegmentLabel';

export type DSL_SegmentedProgressBarProps = {
  segments: ({id: string} & DSL_ProgressBarSegmentProps)[];
  title?: ReactNode;
  size?: 'lg' | 'md' | 'sm';
};

export const DSL_SegmentedProgressBar = ({
  segments,
  title,
  size = 'lg',
}: DSL_SegmentedProgressBarProps) => {
  const sizeMap = {
    lg: 16,
    md: 8,
    sm: 4,
  };
  const sizeNum = sizeMap[size];

  return (
    <DSL_FlexColumn gap={1}>
      <DSL_FlexRow
        bgcolor={'coolGrey.shades.50p'}
        height={sizeNum}
        overflow={'hidden'}
        borderRadius={3}
        gap={'2px'}
      >
        {segments.map(segment => (
          <DSL_ProgressBarSegment key={segment.id} {...segment} />
        ))}
      </DSL_FlexRow>
      <DSL_FlexRow rowGap={0} columnGap={1}>
        {segments.map(({color, label, id}) => (
          <DSL_ProgressBarSegmentLabel key={id} color={color} label={label} />
        ))}
      </DSL_FlexRow>
      {title && (
        <DSL_Body2 color={'text.secondary'} textAlign={'center'}>
          {title}
        </DSL_Body2>
      )}
    </DSL_FlexColumn>
  );
};

DSL_SegmentedProgressBar.displayName = 'DSL_SegmentedProgressBar';

export {
  //
  /** @deprecated use DSL_SegmentedProgressBarProps instead*/
  DSL_SegmentedProgressBarProps as SegmentedProgressBarProps, //
  /** @deprecated use DSL_SegmentedProgressBar instead*/
  DSL_SegmentedProgressBar as SegmentedProgressBar,
};
