import {ReactNode} from 'react';

import {
  schemaJsonPointerResolver,
  jsonPointerResolver,
} from '@regulatory-platform/common-utils';
import * as R from 'ramda';
import {FormMachineState} from 'utils/machines/formMachine';
import getErrorMessage from 'utils/schema/getErrorMessage';
import getLabel from 'utils/schema/getLabel';
import isError from 'utils/schema/isError';

export interface DSP_GetGenericFieldProps {
  fieldRef: string;
  state: FormMachineState;
  label?: ReactNode;
  includeOptionalSuffix?: boolean;
  hideLabel?: boolean;
  useLabelForValidationErrors?: boolean;
}

export interface DSP_GenericFieldProps {
  id: string;
  name: string;
  error: boolean;
  helperText?: ReactNode;
  value: boolean | string | number | string[] | null;
  label: ReactNode;
  disabled: boolean;
  useLabelForValidationErrors?: boolean;
}

export function getGenericFieldProps({
  fieldRef,
  state,
  includeOptionalSuffix = true,
  label,
  hideLabel,
  useLabelForValidationErrors = false,
}: DSP_GetGenericFieldProps): DSP_GenericFieldProps {
  const {schema, record, props} = state.context;
  const fieldSchema = schemaJsonPointerResolver(fieldRef)(schema);
  if (R.isNil(fieldSchema)) {
    throw new Error(`Schema not found for field ${fieldRef}`);
  }
  const isNewRecord = fieldRef.indexOf('/-1/') > -1;
  const fieldValue = isNewRecord
    ? fieldSchema['x-origValue']
    : jsonPointerResolver(fieldRef)(record);

  const isValidError = isError(fieldSchema, props.overrideTouched === true);
  const name = R.replace(/\//g, '-', R.replace(/#\//g, '', fieldRef));
  const optionalSuffix = includeOptionalSuffix ? ' (optional)' : '';
  const schemaLabel =
    (label ?? getLabel(fieldSchema)) +
    (fieldSchema['x-required'] ? '' : optionalSuffix);
  const labelString = hideLabel === true ? undefined : schemaLabel;
  const fieldLabel = useLabelForValidationErrors ? schemaLabel : undefined;

  return {
    name,
    id: name,
    error: isValidError,
    disabled: fieldSchema.readOnly ?? false,
    helperText: isValidError
      ? getErrorMessage(fieldSchema, fieldLabel)
      : undefined,
    value: fieldValue as boolean | string | number | string[] | null,
    label: labelString,
  };
}
