import React from 'react';

import {DSL_BaseAlert, DSL_BaseAlertProps} from '../BaseAlert';

export type DSL_InlineWarningAlertProps = Omit<
  DSL_BaseAlertProps,
  'variant' | 'severity'
>;

export const DSL_InlineWarningAlert = (props: DSL_InlineWarningAlertProps) => {
  return <DSL_BaseAlert variant={'outlined'} severity="warning" {...props} />;
};

DSL_InlineWarningAlert.displayName = 'DSL_InlineWarningAlert';

export {
  //
  /** @deprecated use DSL_InlineWarningAlertProps instead*/
  DSL_InlineWarningAlertProps as InlineWarningAlertProps, //
  /** @deprecated use DSL_InlineWarningAlert instead*/
  DSL_InlineWarningAlert as InlineWarningAlert,
};
