import React from 'react';

import {Body1, VerticalSpacer} from 'components/DesignSystem/Library';

export interface DSP_FormFieldsetHeaderProps {
  children: React.ReactNode;
}

export const DSP_FormFieldsetHeader = ({
  children,
}: DSP_FormFieldsetHeaderProps) => {
  return (
    <div>
      <Body1>{children}</Body1>
      <VerticalSpacer size={'MD'} />
    </div>
  );
};

DSP_FormFieldsetHeader.displayName = 'DSP_FormFieldsetHeader';

export {
  //
  /** @deprecated use DSP_FormFieldsetHeaderProps instead*/
  DSP_FormFieldsetHeaderProps as FormFieldsetHeaderProps, //
  /** @deprecated use DSP_FormFieldsetHeader instead*/
  DSP_FormFieldsetHeader as FormFieldsetHeader,
};
