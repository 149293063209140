import React, {useEffect, useState} from 'react';

import {Breakpoint} from '@mui/material';
import {
  segmentAccessTitleMap,
  SegmentAccessType,
} from 'components/DesignSystem/Portal/Layout/Segments/constants';
import {pluralizeLabel} from 'components/DesignSystem/utils/pluralizeLabel';

import {
  DSL_BorderlessCard,
  DSL_ButtonCardActionArea,
  DSL_CardAdornmentLayout,
  DSL_ChevronRightIcon,
  DSL_CardContent,
  DSL_FlexRow,
  DSL_FlexColumn,
  DSL_Body2,
  DSL_Flex,
  DSL_Subtitle2,
  DSL_CardActionAreaExclusion,
  DSL_Fade,
  DSL_RemoveCircleIconButton,
  DSL_Tooltip,
} from 'components/DesignSystem/Library';

import {DSP_SegmentAccessIcon} from '../SegmentAccessIcon';
import {DSP_RoadsByAccessType} from '../types';

interface DSP_RoadsByAccessTypeCardProps extends DSP_RoadsByAccessType {
  onClick: (
    segmentAccessType: SegmentAccessType,
    conditionName?: string,
  ) => void;
  currentBreakpoint: Breakpoint;
  onRemoveCircleIconButtonClick?: (
    segmentAccessType: SegmentAccessType,
  ) => void;
  onSegmentHoverOut: () => void;
  onRoadsByAccessTypeHover: (segmentAccessType: SegmentAccessType) => void;
}

export const DSP_RoadsByAccessTypeCard = ({
  segmentAccessType = SegmentAccessType.APPROVED,
  currentBreakpoint,
  withIcon,
  conditionName,
  totalRoads,
  onRemoveCircleIconButtonClick,
  onClick,
  onRoadsByAccessTypeHover,
  onSegmentHoverOut,
}: DSP_RoadsByAccessTypeCardProps) => {
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    if (isHovering) {
      onRoadsByAccessTypeHover(segmentAccessType);
    } else {
      onSegmentHoverOut();
    }
  }, [
    isHovering,
    onSegmentHoverOut,
    onRoadsByAccessTypeHover,
    segmentAccessType,
  ]);

  return (
    <DSL_BorderlessCard
      square
      onMouseOver={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <DSL_ButtonCardActionArea
        onClick={() => onClick(segmentAccessType, conditionName)}
        disableRipple
      >
        <DSL_CardAdornmentLayout
          endAdornment={<DSL_ChevronRightIcon color={'action'} />}
        >
          <DSL_CardContent padding={'SM'}>
            <DSL_FlexRow justifyContent={'space-between'} flexWrap={'nowrap'}>
              <DSL_FlexRow
                flexWrap={'nowrap'}
                alignItems={'center'}
                maxWidth={currentBreakpoint === 'xs' ? '65%' : '75%'}
                gap={1}
              >
                {onRemoveCircleIconButtonClick && (
                  <DSL_Fade in={isHovering}>
                    <DSL_Tooltip title={'Remove segments from the selection'}>
                      <DSL_FlexRow>
                        <DSL_CardActionAreaExclusion>
                          <DSL_RemoveCircleIconButton
                            onClick={() =>
                              onRemoveCircleIconButtonClick(segmentAccessType)
                            }
                          />
                        </DSL_CardActionAreaExclusion>
                      </DSL_FlexRow>
                    </DSL_Tooltip>
                  </DSL_Fade>
                )}
                <DSL_FlexRow flexWrap={'nowrap'} alignItems={'center'}>
                  {withIcon && (
                    <DSL_FlexRow>
                      <DSP_SegmentAccessIcon
                        segmentAccessType={segmentAccessType}
                      />
                    </DSL_FlexRow>
                  )}
                  <DSL_FlexColumn gap={0}>
                    <DSL_Subtitle2 ellipsisLines={1}>
                      {conditionName ??
                        segmentAccessTitleMap.CURRENT[segmentAccessType]}
                    </DSL_Subtitle2>
                  </DSL_FlexColumn>
                </DSL_FlexRow>
              </DSL_FlexRow>
              <DSL_FlexRow
                width={'86px'}
                mr={currentBreakpoint === 'xs' ? -2 : 0}
              >
                <DSL_Flex alignItems={'center'}>
                  <DSL_Body2 color={'text.secondary'}>
                    {`${totalRoads} ${pluralizeLabel(`road`, totalRoads)}`}
                  </DSL_Body2>
                </DSL_Flex>
              </DSL_FlexRow>
            </DSL_FlexRow>
          </DSL_CardContent>
        </DSL_CardAdornmentLayout>
      </DSL_ButtonCardActionArea>
    </DSL_BorderlessCard>
  );
};

DSP_RoadsByAccessTypeCard.displayName = 'DSP_RoadsByAccessTypeCard';
