import React from 'react';

import {SegmentAccessType} from 'components/DesignSystem/Portal/Layout/Segments';

import {
  DSL_BorderlessCard,
  DSL_ButtonCardActionArea,
  DSL_FlexRow,
  DSL_FlexColumn,
  DSL_Caption,
  DSL_Body1,
} from 'components/DesignSystem/Library';

import {DSP_SegmentAccessIcon} from '../SegmentAccessIcon';

export interface RevertChangeProps {
  onClick: () => void;
  segmentAccessType: SegmentAccessType;
  label: string;
  clearChanges?: boolean;
}

export const RevertChange = ({
  onClick,
  segmentAccessType,
  label,
  clearChanges,
}: RevertChangeProps) => {
  return (
    <DSL_BorderlessCard square>
      <DSL_ButtonCardActionArea onClick={onClick}>
        <DSL_FlexRow py={2} px={2} alignItems={'flex-end'}>
          <DSP_SegmentAccessIcon segmentAccessType={segmentAccessType} />
          <DSL_FlexColumn gap={0}>
            <DSL_Caption color={'text.secondary'}>{`${
              clearChanges
                ? 'Revert to (clear changes)'
                : 'Revert to (last saved changes)'
            }`}</DSL_Caption>
            <DSL_Body1 bold>{label}</DSL_Body1>
          </DSL_FlexColumn>
        </DSL_FlexRow>
      </DSL_ButtonCardActionArea>
    </DSL_BorderlessCard>
  );
};
