import React from 'react';

import {
  TableRow as MuiTableRow,
  TableRowProps as MuiTableRowProps,
} from '@mui/material';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

export type DSL_TableRowProps = Omit<
  MuiTableRowProps,
  DSL_RestrictedVisualProps
>;

export const DSL_TableRow = (props: MuiTableRowProps) => {
  return <MuiTableRow {...props} />;
};

DSL_TableRow.displayName = 'DSL_TableRow';

export {
  //
  /** @deprecated use DSL_TableRowProps instead*/
  DSL_TableRowProps as TableRowProps, //
  /** @deprecated use DSL_TableRow instead*/
  DSL_TableRow as TableRow,
};
