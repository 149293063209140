import {DSP_AccreditationApplicationNotificationItem} from '../types';

import {DSP_AccreditationApplicationOperationsNotificationCardViewProps} from './AccreditationApplicationOperationsNotificationCard.View';

export interface DSP_UseAccreditationApplicationNotificationCardProps {
  notification: DSP_AccreditationApplicationNotificationItem;
}

export function useAccreditationApplicationOperationsNotificationCard({
  notification,
}: DSP_UseAccreditationApplicationNotificationCardProps): DSP_AccreditationApplicationOperationsNotificationCardViewProps {
  return {
    notification,
    customerAccountId: notification.data.customerAccount?.id,
  };
}
