import React from 'react';

import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from '@mui/x-date-pickers-pro';

import {DSL_RestrictedVisualProps} from '../../../types';
import {DSL_BaseInput, DSL_BaseInputProps} from '../BaseInput';

export type DSL_DatePickerProps<DSL_DateType> = Omit<
  MuiDatePickerProps<DSL_DateType>,
  DSL_RestrictedVisualProps | 'renderInput' | 'components' | 'componentsProps'
> & {
  size?: DSL_BaseInputProps['size'];
  inputProps?: Omit<DSL_BaseInputProps['inputProps'], 'disabled'>;
};

export function DSL_DatePicker<DSL_DateType = Date>({
  value,
  onChange,
  label,
  onError,
  minDate,
  maxDate,
  ...props
}: DSL_DatePickerProps<DSL_DateType>) {
  return (
    <MuiDatePicker<DSL_DateType>
      onChange={onChange}
      onError={onError}
      value={value}
      label={label}
      maxDate={maxDate}
      minDate={minDate}
      slots={{textField: DSL_BaseInput}}
      slotProps={{
        textField: ({slots, slotProps, ...textFieldProps}) => ({
          ...textFieldProps,
          ...props,
          ...props.inputProps,
        }),
      }}
    />
  );
}

export {
  //
  /** @deprecated use DSL_DatePickerProps instead*/
  DSL_DatePickerProps as DatePickerProps, //
  /** @deprecated use DSL_DatePicker instead*/
  DSL_DatePicker as DatePicker,
};
