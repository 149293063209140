import {useTheme} from '@mui/material';

import {DSL_BaseDrawerProps} from '../../BaseDrawer';
import {DSL_DrawerSize} from '../../types';
import {DSL_UseDrawerArgs} from '../../useDrawer';
import {useNonModalDrawer} from '../useNonModalDrawer';

export interface DSL_UseMinimizeableNonModalDrawerArgs
  extends Omit<DSL_UseDrawerArgs, 'variant'> {
  isMinimized?: boolean;
  minimizedMargin?: number;
  minimizedHeight?: DSL_DrawerSize;
  width?: number | string;
}

export function useMinimizeableNonModalDrawer({
  isMinimized,
  minimizedMargin = 0,
  ...args
}: DSL_UseMinimizeableNonModalDrawerArgs) {
  const {spacing, palette} = useTheme();
  const nonModalDrawerProps = useNonModalDrawer(args);

  const overrideProps: Partial<DSL_BaseDrawerProps> = {};

  if (isMinimized) {
    nonModalDrawerProps.top ??= 0;
    const spacingInPx = spacing(minimizedMargin);

    nonModalDrawerProps.top ??= 0;
    overrideProps.top = `calc(${nonModalDrawerProps.top}px + ${spacingInPx})`;
    overrideProps.height = 'auto';
    overrideProps.border = `1px ${palette.divider} solid`;

    if (nonModalDrawerProps.anchor === 'left') {
      nonModalDrawerProps.left ??= 0;
      overrideProps.left = `calc(${nonModalDrawerProps.left}px + ${spacingInPx})`;
    }

    if (nonModalDrawerProps.anchor === 'right') {
      nonModalDrawerProps.right ??= 0;
      overrideProps.right = `calc(${nonModalDrawerProps.right}px + ${spacingInPx})`;
    }

    if (args.width) {
      overrideProps.width = args.width;
    }
  }

  return {
    isMinimized,
    ...nonModalDrawerProps,
    ...overrideProps,
  };
}
