import React from 'react';

import {
  TableBody as MuiTableBody,
  TableBodyProps as MuiTableBodyProps,
} from '@mui/material';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

export type DSL_TableBodyProps = Omit<
  MuiTableBodyProps,
  DSL_RestrictedVisualProps
>;

export const DSL_TableBody = (props: DSL_TableBodyProps) => {
  return <MuiTableBody {...props} />;
};

DSL_TableBody.displayName = 'DSL_TableBody';

export {
  //
  /** @deprecated use DSL_TableBodyProps instead*/
  DSL_TableBodyProps as TableBodyProps, //
  /** @deprecated use DSL_TableBody instead*/
  DSL_TableBody as TableBody,
};
