import React from 'react';

// TODO: need to decouple this type dependency
import {DSL_DeleteIconButton} from 'components/DesignSystem/Library/Buttons/IconButtons/DeleteIconButton';
import {DSL_LinearProgress} from 'components/DesignSystem/Library/LinearProgress';
import {DSL_Body2} from 'components/DesignSystem/Library/Typography/Body2';
import {File} from 'typeDefs/types';

import {DSL_FlexColumn} from '../../../Layout/FlexColumn';
import {DSL_FlexRow} from '../../../Layout/FlexRow';

export interface DSL_UploadingFileType extends File {
  progress?: number;
  error?: boolean;
}

export interface DSL_UploadingFileProps<T> {
  file: T;
  onFileRemove?: (file: T) => void;
}
export const DSL_UploadingFile = <T extends DSL_UploadingFileType>({
  file,
  onFileRemove,
}: DSL_UploadingFileProps<T>) => {
  return (
    <DSL_FlexColumn gap={1} pr={2} pl={2}>
      <DSL_FlexRow gap={8} alignItems={'center'}>
        <DSL_FlexRow>
          <DSL_Body2>{file.name}</DSL_Body2>
        </DSL_FlexRow>
        <DSL_FlexRow>
          <DSL_Body2 color={'text.secondary'}>Uploading...</DSL_Body2>
        </DSL_FlexRow>
        <DSL_FlexRow flexGrow={1}>
          <DSL_LinearProgress value={file.progress} />
        </DSL_FlexRow>
        <DSL_FlexRow>
          <DSL_DeleteIconButton onClick={() => onFileRemove?.(file)} />
        </DSL_FlexRow>
      </DSL_FlexRow>
    </DSL_FlexColumn>
  );
};

export {
  //
  /** @deprecated use DSL_UploadingFileProps instead*/
  DSL_UploadingFileProps as UploadingFileProps, //
  /** @deprecated use DSL_UploadingFile instead*/
  DSL_UploadingFile as UploadingFile,
};
