import WebAsset from '@mui/icons-material/WebAsset';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';
import {lazy} from 'react';

export default {
  path: '/accessInstruments/:accessInstrumentId/accessProductDocument/:accessProductDocumentId/accessProductApplication/:accessProductApplicationId/accessVehicleExemptionSet/:id',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "ManageAccessVehicleExemptionSet" */ 'containers-admin/AccessVehicleExemptionSet/ManageAccessVehicleExemptionSet/ManageAccessVehicleExemptionSet'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Reference vehicle',
  authorisation: {
    apiKey: 'AccessVehicleExemptionSetWithRelations.findById',
    policies: [
      {
        role: 'operationsSpatialRead',
      },
      {
        role: 'operationsAccessRead',
      },
      {
        role: 'partnerSpatialRead',
      },
      {
        role: 'partnerAccessRead',
      },
    ] as AuthorisationPolicy[],
  },
  menuIcon: WebAsset,
  sections: [
    {
      content: 'Vehicle networks',
      to: '../../..',
    },
    {
      content: 'Manage network consultation case',
      to: '../..',
    },
  ],
};
