import React from 'react';

import {AccreditationWithRelations} from 'typeDefs/types';
import {NotificationItem} from 'utils/machines/notificationsMachine';

import {DSP_AccreditationActionOperationsNotificationCardView} from './AccreditationActionOperationsNotificationCard.View';
import {useAccreditationOperationsNotificationCard} from './useAccreditationActionOperationsNotificationCard';

export interface DSP_AccreditationOperationsNotificationCardProps {
  notification: NotificationItem<AccreditationWithRelations>;
}

export const DSP_AccreditationActionOperationsNotificationCard = (
  props: DSP_AccreditationOperationsNotificationCardProps,
) => {
  const viewProps = useAccreditationOperationsNotificationCard(props);

  return (
    <DSP_AccreditationActionOperationsNotificationCardView {...viewProps} />
  );
};

DSP_AccreditationActionOperationsNotificationCard.displayName =
  'DSP_AccreditationActionOperationsNotificationCard';
