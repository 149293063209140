import React, {useEffect} from 'react';
import {useNavigate} from 'react-router';

// temp redirect until react-router is updated
const Redirect = (): JSX.Element => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`./view`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default Redirect;
