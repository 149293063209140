import * as Types from '../../../../../../../typeDefs/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUserModelInAccountPublicQueryVariables = Types.Exact<{
  id: Types.Scalars['Float']['input'];
  accountId?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  accountType?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetUserModelInAccountPublicQuery = { __typename?: 'Query', userModelInAccountPublic?: { __typename?: 'UserModelInAccountPublic', id?: number | null, fileList?: any | null, email?: string | null, firstName?: string | null, name?: string | null, contactPreference?: string | null, accountId?: number | null, accountType?: any | null, position?: string | null, division?: string | null, phoneNumbers?: Array<{ __typename?: 'Phone', country?: string | null, phoneNumber?: string | null, phoneType?: string | null, workPhoneNumber?: string | null } | null> | null } | null };


export const GetUserModelInAccountPublicDocument = gql`
    query getUserModelInAccountPublic($id: Float!, $accountId: Float, $accountType: String) {
  userModelInAccountPublic(
    id: $id
    accountId: $accountId
    accountType: $accountType
  ) {
    id
    fileList
    email
    firstName
    name
    phoneNumbers {
      country
      phoneNumber
      phoneType
      workPhoneNumber
    }
    contactPreference
    accountId
    accountType
    position
    division
  }
}
    `;

/**
 * __useGetUserModelInAccountPublicQuery__
 *
 * To run a query within a React component, call `useGetUserModelInAccountPublicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserModelInAccountPublicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserModelInAccountPublicQuery({
 *   variables: {
 *      id: // value for 'id'
 *      accountId: // value for 'accountId'
 *      accountType: // value for 'accountType'
 *   },
 * });
 */
export function useGetUserModelInAccountPublicQuery(baseOptions: Apollo.QueryHookOptions<GetUserModelInAccountPublicQuery, GetUserModelInAccountPublicQueryVariables> & ({ variables: GetUserModelInAccountPublicQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserModelInAccountPublicQuery, GetUserModelInAccountPublicQueryVariables>(GetUserModelInAccountPublicDocument, options);
      }
export function useGetUserModelInAccountPublicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserModelInAccountPublicQuery, GetUserModelInAccountPublicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserModelInAccountPublicQuery, GetUserModelInAccountPublicQueryVariables>(GetUserModelInAccountPublicDocument, options);
        }
export function useGetUserModelInAccountPublicSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserModelInAccountPublicQuery, GetUserModelInAccountPublicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserModelInAccountPublicQuery, GetUserModelInAccountPublicQueryVariables>(GetUserModelInAccountPublicDocument, options);
        }
export type GetUserModelInAccountPublicQueryHookResult = ReturnType<typeof useGetUserModelInAccountPublicQuery>;
export type GetUserModelInAccountPublicLazyQueryHookResult = ReturnType<typeof useGetUserModelInAccountPublicLazyQuery>;
export type GetUserModelInAccountPublicSuspenseQueryHookResult = ReturnType<typeof useGetUserModelInAccountPublicSuspenseQuery>;
export type GetUserModelInAccountPublicQueryResult = Apollo.QueryResult<GetUserModelInAccountPublicQuery, GetUserModelInAccountPublicQueryVariables>;