import * as Types from '../../../../../../typeDefs/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NetworksSearchQueryVariables = Types.Exact<{
  qs: Types.Scalars['String']['input'];
  showHidden?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type NetworksSearchQuery = { __typename?: 'Query', networksSearch?: Array<{ __typename?: 'NetworkSearchPublic', networkId?: number | null, networkName: string, networkDisplayName?: string | null, networkType: any, createdPartnerAccountId?: number | null, networkWkid?: number | null } | null> | null };


export const NetworksSearchDocument = gql`
    query networksSearch($qs: String!, $showHidden: Boolean) {
  networksSearch(qs: $qs, showHidden: $showHidden) {
    networkId
    networkName
    networkDisplayName
    networkType
    createdPartnerAccountId
    networkWkid
  }
}
    `;

/**
 * __useNetworksSearchQuery__
 *
 * To run a query within a React component, call `useNetworksSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useNetworksSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNetworksSearchQuery({
 *   variables: {
 *      qs: // value for 'qs'
 *      showHidden: // value for 'showHidden'
 *   },
 * });
 */
export function useNetworksSearchQuery(baseOptions: Apollo.QueryHookOptions<NetworksSearchQuery, NetworksSearchQueryVariables> & ({ variables: NetworksSearchQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NetworksSearchQuery, NetworksSearchQueryVariables>(NetworksSearchDocument, options);
      }
export function useNetworksSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NetworksSearchQuery, NetworksSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NetworksSearchQuery, NetworksSearchQueryVariables>(NetworksSearchDocument, options);
        }
export function useNetworksSearchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<NetworksSearchQuery, NetworksSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NetworksSearchQuery, NetworksSearchQueryVariables>(NetworksSearchDocument, options);
        }
export type NetworksSearchQueryHookResult = ReturnType<typeof useNetworksSearchQuery>;
export type NetworksSearchLazyQueryHookResult = ReturnType<typeof useNetworksSearchLazyQuery>;
export type NetworksSearchSuspenseQueryHookResult = ReturnType<typeof useNetworksSearchSuspenseQuery>;
export type NetworksSearchQueryResult = Apollo.QueryResult<NetworksSearchQuery, NetworksSearchQueryVariables>;