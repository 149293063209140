import React from 'react';
import {Navigate} from 'react-router-dom';
import {useLocation} from 'react-router-dom';

export interface RedirectRouteProps {
  from: string;
  to: string;
}

/**
 * Wraps the <Navigate> component and replaces location path from - to
 */
const RedirectRoute: React.FC<RedirectRouteProps> = ({
  from,
  to,
  ...otherProps
}) => {
  const location = useLocation();
  return (
    <Navigate
      to={location.pathname.replace(from, to) + location.search}
      {...otherProps}
      replace
    />
  );
};

export default RedirectRoute;
