import {lazy} from 'react';
import HomeIcon from '@mui/icons-material/Home';
import {
  AuthorisationPolicy,
  BuiltInRoles,
} from '@regulatory-platform/common-utils';

export default {
  path: '/b2cLogin',
  component: lazy(() => import(/* webpackChunkName: "Login" */ './B2CLogin')),
  visibleInMenu: false,
  menuTitle: 'B2CLogin',
  menuIcon: HomeIcon,
  authorisation: {
    apiKey: null,
    policies: [BuiltInRoles.EVERYONE] as AuthorisationPolicy[],
  },
  sections: [],
};
