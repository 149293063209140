import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import GroupIcon from '@mui/icons-material/Group';
export default {
  path: '/manage',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "ManageAccountSwitch" */ './ManageAccountSwitch'
      ),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'Manage account',
  menuIcon: GroupIcon,
  authorisation: {
    apiKey: null,
    policies: [
      {role: 'operationsAccountAdmin'},
      {role: 'providerAccountAdmin'},
      {role: 'customerAccountAdmin'},
      {role: 'partnerAccountAdmin'},
    ] as AuthorisationPolicy[],
  },
  sections: [],
};
