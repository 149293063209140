import {ChangeEvent} from 'react';

import {useFormContext} from '../FormProvider/useFormContext';
import {DSP_FieldProps} from '../types';
import {
  DSP_GetGenericFieldProps,
  DSP_GenericFieldProps,
  getGenericFieldProps,
} from '../utils/getGenericFieldProps';

import {useFormFieldPropsSelector} from './useFormFieldPropsSelector';

export interface DSP_UseInputFieldProps
  extends DSP_FieldProps,
    Omit<DSP_GetGenericFieldProps, 'state'> {}

export interface DSP_InputFieldProps extends DSP_GenericFieldProps {
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur: () => void;
}

export function useInputFieldProps({
  fieldRef,
  hideLabel,
  onBlurEvent,
  onChangeEvent,
  includeOptionalSuffix,
  label,
  useLabelForValidationErrors,
  ...propsOverrides
}: DSP_UseInputFieldProps): DSP_InputFieldProps {
  const {service} = useFormContext();
  const send = service.send;

  return useFormFieldPropsSelector(fieldRef, service, state => {
    const {value, ...genericFieldProps} = getGenericFieldProps({
      label,
      fieldRef,
      state,
      hideLabel,
      includeOptionalSuffix,
      useLabelForValidationErrors,
    });

    return {
      ...genericFieldProps,
      ...propsOverrides,
      value: value || '',
      onChange: (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      ): void => {
        const newValue = e.currentTarget.value;
        send({type: 'CHANGE', fieldRef, value: newValue});
        onChangeEvent?.();
      },
      onBlur: (): void => {
        send({type: 'BLUR', fieldRef});
        onBlurEvent?.();
      },
    };
  });
}
