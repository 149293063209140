import * as Types from '../../../../typeDefs/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPbsApprovalVehicleMappingsQueryVariables = Types.Exact<{
  id: Types.Scalars['Float']['input'];
}>;


export type GetPbsApprovalVehicleMappingsQuery = { __typename?: 'Query', pbsApprovalVehicleMappingPublic?: { __typename?: 'PbsApprovalVehicleMappingPublic', id?: number | null, pbsApprovalId?: number | null, pbsVehicleUnitSetId?: number | null, status?: string | null, unitPosition?: Array<number | null> | null, vehicleRegistrationExtendedId?: string | null, vehicleVin?: string | null } | null };


export const GetPbsApprovalVehicleMappingsDocument = gql`
    query getPbsApprovalVehicleMappings($id: Float!) {
  pbsApprovalVehicleMappingPublic(id: $id) {
    id
    pbsApprovalId
    pbsVehicleUnitSetId
    status
    unitPosition
    vehicleRegistrationExtendedId
    vehicleVin
  }
}
    `;

/**
 * __useGetPbsApprovalVehicleMappingsQuery__
 *
 * To run a query within a React component, call `useGetPbsApprovalVehicleMappingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPbsApprovalVehicleMappingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPbsApprovalVehicleMappingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPbsApprovalVehicleMappingsQuery(baseOptions: Apollo.QueryHookOptions<GetPbsApprovalVehicleMappingsQuery, GetPbsApprovalVehicleMappingsQueryVariables> & ({ variables: GetPbsApprovalVehicleMappingsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPbsApprovalVehicleMappingsQuery, GetPbsApprovalVehicleMappingsQueryVariables>(GetPbsApprovalVehicleMappingsDocument, options);
      }
export function useGetPbsApprovalVehicleMappingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPbsApprovalVehicleMappingsQuery, GetPbsApprovalVehicleMappingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPbsApprovalVehicleMappingsQuery, GetPbsApprovalVehicleMappingsQueryVariables>(GetPbsApprovalVehicleMappingsDocument, options);
        }
export function useGetPbsApprovalVehicleMappingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPbsApprovalVehicleMappingsQuery, GetPbsApprovalVehicleMappingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPbsApprovalVehicleMappingsQuery, GetPbsApprovalVehicleMappingsQueryVariables>(GetPbsApprovalVehicleMappingsDocument, options);
        }
export type GetPbsApprovalVehicleMappingsQueryHookResult = ReturnType<typeof useGetPbsApprovalVehicleMappingsQuery>;
export type GetPbsApprovalVehicleMappingsLazyQueryHookResult = ReturnType<typeof useGetPbsApprovalVehicleMappingsLazyQuery>;
export type GetPbsApprovalVehicleMappingsSuspenseQueryHookResult = ReturnType<typeof useGetPbsApprovalVehicleMappingsSuspenseQuery>;
export type GetPbsApprovalVehicleMappingsQueryResult = Apollo.QueryResult<GetPbsApprovalVehicleMappingsQuery, GetPbsApprovalVehicleMappingsQueryVariables>;