import {JSONSchemaRecord} from '@regulatory-platform/common-utils';
import * as R from 'ramda';

import {Option} from 'components/DesignSystem/Library';

function itemToItemsObject(item: JSONSchemaRecord): Option {
  if (R.is(Object, item) && R.is(Array, item.enum)) {
    const value = item.enum[0] as string;
    const label = R.defaultTo(value, item.title) as string;
    return {label, value};
  }

  return item as string;
}

export function getItemsFromSchema(
  fieldSchema: JSONSchemaRecord,
): readonly Option[] {
  // go through each of these keys in the schema, looking for items
  const valuesInPrecidenceOrder = [
    fieldSchema.oneOf,
    fieldSchema?.['x-contains'],
    (fieldSchema['x-items'] as JSONSchemaRecord)?.enum,
    fieldSchema.enum,
    (fieldSchema['x-items'] as JSONSchemaRecord)?.['x-contains'],
    (fieldSchema['x-items'] as JSONSchemaRecord)?.oneOf,
  ] as JSONSchemaRecord[];

  for (const key in valuesInPrecidenceOrder) {
    const valueList = valuesInPrecidenceOrder[key];
    if (R.is(Array, valueList)) {
      return R.map(itemToItemsObject, valueList as JSONSchemaRecord[]);
    }
  }

  return [] as Option[];
}
