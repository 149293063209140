import React, {ReactNode} from 'react';

import {DSL_FlexColumn} from '../FlexColumn';

export interface DSL_ThreadContainerProps {
  children: ReactNode;
}

export const DSL_ThreadContainer = ({children}: DSL_ThreadContainerProps) => {
  return <DSL_FlexColumn gap={0}>{children}</DSL_FlexColumn>;
};

DSL_ThreadContainer.displayName = 'DSL_ThreadContainer';

export {
  //
  /** @deprecated use DSL_ThreadContainerProps instead*/
  DSL_ThreadContainerProps as ThreadContainerProps, //
  /** @deprecated use DSL_ThreadContainer instead*/
  DSL_ThreadContainer as ThreadContainer,
};
