import {lazy} from 'react';
import InfoIcon from '@mui/icons-material/Info';
import {
  AuthorisationPolicy,
  BuiltInRoles,
} from '@regulatory-platform/common-utils';

export default {
  path: '/',
  component: lazy(() => import(/* webpackChunkName: "Home" */ './Home')),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Information Hub',
  menuDescription:
    'The Information Hub contains useful services such as the National Network Map, Route Planner Tool, NHVAS Approved Auditors and more.',
  menuIcon: InfoIcon,
  authorisation: {
    apiKey: null,
    policies: [BuiltInRoles.EVERYONE] as AuthorisationPolicy[],
  },
  sections: [],
};
