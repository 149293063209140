import {Context, useContext} from 'react';

export const useRequiredContext = <ContextType>(
  context: Context<ContextType>,
  name: string,
) => {
  const instance = useContext(context);

  if (!instance) {
    throw new Error(
      `No <${name}> found in the component hierarchy. A ${name} is required when attempting to consume this context.`,
    );
  }

  return instance;
};
