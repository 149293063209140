import React from 'react';

import {
  DSL_ScrollableLayoutBody,
  DSL_ScrollableLayoutBodyProps,
} from '../../Layout/ScrollableLayout';

export type DSL_DrawerContentProps = DSL_ScrollableLayoutBodyProps;

export const DSL_DrawerContent = (props: DSL_DrawerContentProps) => {
  return <DSL_ScrollableLayoutBody {...props} />;
};

export {
  //
  /** @deprecated use DSL_DrawerContentProps instead*/
  DSL_DrawerContentProps as DrawerContentProps, //
  /** @deprecated use DSL_DrawerContent instead*/
  DSL_DrawerContent as DrawerContent,
};
