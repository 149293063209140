import React from 'react';

import {
  ListItemIcon as MuiListItemIcon,
  ListItemIconProps as MuiListItemIconProps,
} from '@mui/material';

export type DSL_ListItemIconProps = MuiListItemIconProps;

export const DSL_ListItemIcon = (props: DSL_ListItemIconProps) => {
  return <MuiListItemIcon {...props} />;
};

DSL_ListItemIcon.displayName = 'DSL_ListItemIcon';

export {
  //
  /** @deprecated use DSL_ListItemIconProps instead*/
  DSL_ListItemIconProps as ListItemIconProps, //
  /** @deprecated use DSL_ListItemIcon instead*/
  DSL_ListItemIcon as ListItemIcon,
};
