import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import DataUsage from '@mui/icons-material/Dashboard';

export default {
  path: '/vehicle-builder',
  component: lazy(
    () => import(/* webpackChunkName: "VehicleBuilder" */ './VehicleBuilder'),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'Vehicle Builder',
  menuIcon: DataUsage,
  authorisation: {
    apiKey: null,
    policies: [
      {
        role: 'operationsVSAdmin',
      },
    ] as AuthorisationPolicy[],
  },
  sections: [],
};
