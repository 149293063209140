import ListOutlined from '@mui/icons-material/ListOutlined';
import {
  BuiltInRoles,
  AuthorisationPolicy,
} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/nHVASApprovedRTOs',
  component: lazy(
    () =>
      import(/* webpackChunkName: "NHVASApprovedRTOs" */ './NHVASApprovedRTOs'),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'NHVAS Approved RTOs',
  menuDescription:
    'List of Registered Training Organisations (RTOs) for NHVAS that have been approved by the NHVR.',
  menuIcon: ListOutlined,
  authorisation: {
    apiKey: null,
    policies: [BuiltInRoles.EVERYONE] as AuthorisationPolicy[],
  },
  sections: [],
};
