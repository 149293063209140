import React from 'react';

import {UserProfile} from '@regulatory-platform/common-utils/dist';

import {DSP_AccountHeaderMenuView} from './AccountHeaderMenu.View';
import {useAccountHeaderMenu} from './useAccountHeaderMenu';

export const DSP_AccountHeaderMenu = ({
  userProfile,
}: {
  userProfile: UserProfile;
}) => {
  const props = useAccountHeaderMenu<HTMLDivElement>();
  return <DSP_AccountHeaderMenuView userProfile={userProfile} {...props} />;
};

DSP_AccountHeaderMenu.displayName = 'DSP_AccountHeaderMenu';

export {
  //
  /** @deprecated use DSP_AccountHeaderMenu instead*/
  DSP_AccountHeaderMenu as AccountHeaderMenu,
};
