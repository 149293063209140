import React, {ReactNode} from 'react';

import * as R from 'ramda';

import {MenuItem, SelectInputProps} from 'components/DesignSystem/Library';
import {BaseSelectInput, RelatedRecord} from 'components/DesignSystem/Library';

export interface DSP_SelectRelationBoxViewProps
  extends SelectInputProps<RelatedRecord> {
  items?: RelatedRecord[];
  renderOption?: (item: RelatedRecord) => React.ReactNode;
  isLoading?: boolean;
  helperText?: ReactNode;
}

export const DSP_SelectRelationBoxView = ({
  items,
  renderOption,
  span,
  ...props
}: DSP_SelectRelationBoxViewProps) => {
  return (
    <BaseSelectInput<RelatedRecord> {...props}>
      {items?.map((item, index) => (
        <MenuItem key={index} value={R.defaultTo('', item?.id)}>
          {renderOption ? renderOption(item) : item.name}
        </MenuItem>
      ))}
    </BaseSelectInput>
  );
};

export {
  //
  /** @deprecated use DSP_SelectRelationBoxViewProps instead*/
  DSP_SelectRelationBoxViewProps as SelectRelationBoxViewProps, //
  /** @deprecated use DSP_SelectRelationBoxView instead*/
  DSP_SelectRelationBoxView as SelectRelationBoxView,
};
