import React from 'react';
import {useRouteBlocker} from './useRouteBlocker';
import {RouteBlockerView} from './RouteBlocker.View';

export interface RouteBlockerProps {
  title?: string | React.ReactElement | React.ReactNode;
}

export const RouteBlocker = ({title}: RouteBlockerProps) => {
  const props = useRouteBlocker();
  return <RouteBlockerView title={title} {...props} />;
};

RouteBlocker.displayName = 'RouteBlocker';
