import * as Types from '../../../../../typeDefs/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NotificationUserViewCreateMutationVariables = Types.Exact<{
  notificationUserViewsInput: Types.NotificationUserViewsInput;
}>;


export type NotificationUserViewCreateMutation = { __typename?: 'Mutation', notificationUserViewsControllerCreate?: { __typename?: 'NotificationUserViews', notificationUserViewId?: number | null, notificationId: number, userId?: number | null, viewedDate?: string | null } | null };


export const NotificationUserViewCreateDocument = gql`
    mutation notificationUserViewCreate($notificationUserViewsInput: NotificationUserViewsInput!) {
  notificationUserViewsControllerCreate(
    notificationUserViewsInput: $notificationUserViewsInput
  ) {
    notificationUserViewId
    notificationId
    userId
    viewedDate
  }
}
    `;
export type NotificationUserViewCreateMutationFn = Apollo.MutationFunction<NotificationUserViewCreateMutation, NotificationUserViewCreateMutationVariables>;

/**
 * __useNotificationUserViewCreateMutation__
 *
 * To run a mutation, you first call `useNotificationUserViewCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationUserViewCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationUserViewCreateMutation, { data, loading, error }] = useNotificationUserViewCreateMutation({
 *   variables: {
 *      notificationUserViewsInput: // value for 'notificationUserViewsInput'
 *   },
 * });
 */
export function useNotificationUserViewCreateMutation(baseOptions?: Apollo.MutationHookOptions<NotificationUserViewCreateMutation, NotificationUserViewCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NotificationUserViewCreateMutation, NotificationUserViewCreateMutationVariables>(NotificationUserViewCreateDocument, options);
      }
export type NotificationUserViewCreateMutationHookResult = ReturnType<typeof useNotificationUserViewCreateMutation>;
export type NotificationUserViewCreateMutationResult = Apollo.MutationResult<NotificationUserViewCreateMutation>;
export type NotificationUserViewCreateMutationOptions = Apollo.BaseMutationOptions<NotificationUserViewCreateMutation, NotificationUserViewCreateMutationVariables>;