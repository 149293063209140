import React, {ForwardedRef, forwardRef} from 'react';
import {useAppBar} from './useAppBar';
import {AppBarView} from './AppBar.View';
import {AppBarMapView} from './AppBarMap.View';

export interface AppBarProps {
  map?: boolean;
}

export const AppBar = forwardRef(
  ({map}: AppBarProps, ref: ForwardedRef<HTMLDivElement>) => {
    const {handleLogin, ...props} = useAppBar(map);

    return map ? (
      <AppBarMapView ref={ref} handleLogin={handleLogin} {...props} />
    ) : (
      <AppBarView ref={ref} {...props} />
    );
  },
);

AppBar.displayName = 'AppBar';
