import React, {ReactNode} from 'react';

import {
  BorderlessCard,
  CardContent,
  RouterLinkCardActionArea,
  CardContentProps,
  CardBadge,
} from 'components/DesignSystem/Library';

export interface DSP_NotificationCardProps
  extends Pick<CardContentProps, 'padding' | 'spacing'> {
  href?: string;
  children?: ReactNode;
  isRead?: boolean;
}

export const DSP_NotificationCard = ({
  padding = 'SM',
  spacing = 'XS',
  isRead = true,
  href,
  children,
}: DSP_NotificationCardProps) => {
  const DSP_Content = (
    <CardContent padding={padding} spacing={spacing}>
      {children}
    </CardContent>
  );

  return (
    <CardBadge invisible={isRead}>
      <BorderlessCard square>
        {href ? (
          <RouterLinkCardActionArea href={href}>
            {DSP_Content}
          </RouterLinkCardActionArea>
        ) : (
          DSP_Content
        )}
      </BorderlessCard>
    </CardBadge>
  );
};

DSP_NotificationCard.displayName = 'DSP_NotificationCard';

export {
  //
  /** @deprecated use DSP_NotificationCardProps instead*/
  DSP_NotificationCardProps as NotificationCardProps, //
  /** @deprecated use DSP_NotificationCard instead*/
  DSP_NotificationCard as NotificationCard,
};
