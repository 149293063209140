import React, {useMemo} from 'react';

import prettyBytes from 'pretty-bytes';

export interface DSL_FileSizeRendererProps {
  size: number;
}

export const DSL_FileSizeRenderer = ({size}: DSL_FileSizeRendererProps) => {
  return <span>{useMemo(() => prettyBytes(size, {space: true}), [size])}</span>;
};

DSL_FileSizeRenderer.displayName = 'DSL_FileSizeRenderer';
