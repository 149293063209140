import * as Types from '../../../typeDefs/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserModelControllerVerifyB2CTokenAccessMutationVariables = Types.Exact<{
  verifyB2CTokenAccessInput: Types.VerifyB2CTokenAccessInput;
}>;


export type UserModelControllerVerifyB2CTokenAccessMutation = { __typename?: 'Mutation', userModelControllerVerifyB2CTokenAccess?: { __typename?: 'JWTToken', refreshToken?: string | null, token?: string | null } | null };


export const UserModelControllerVerifyB2CTokenAccessDocument = gql`
    mutation userModelControllerVerifyB2CTokenAccess($verifyB2CTokenAccessInput: VerifyB2CTokenAccessInput!) {
  userModelControllerVerifyB2CTokenAccess(
    verifyB2CTokenAccessInput: $verifyB2CTokenAccessInput
  ) {
    refreshToken
    token
  }
}
    `;
export type UserModelControllerVerifyB2CTokenAccessMutationFn = Apollo.MutationFunction<UserModelControllerVerifyB2CTokenAccessMutation, UserModelControllerVerifyB2CTokenAccessMutationVariables>;

/**
 * __useUserModelControllerVerifyB2CTokenAccessMutation__
 *
 * To run a mutation, you first call `useUserModelControllerVerifyB2CTokenAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserModelControllerVerifyB2CTokenAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userModelControllerVerifyB2CTokenAccessMutation, { data, loading, error }] = useUserModelControllerVerifyB2CTokenAccessMutation({
 *   variables: {
 *      verifyB2CTokenAccessInput: // value for 'verifyB2CTokenAccessInput'
 *   },
 * });
 */
export function useUserModelControllerVerifyB2CTokenAccessMutation(baseOptions?: Apollo.MutationHookOptions<UserModelControllerVerifyB2CTokenAccessMutation, UserModelControllerVerifyB2CTokenAccessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserModelControllerVerifyB2CTokenAccessMutation, UserModelControllerVerifyB2CTokenAccessMutationVariables>(UserModelControllerVerifyB2CTokenAccessDocument, options);
      }
export type UserModelControllerVerifyB2CTokenAccessMutationHookResult = ReturnType<typeof useUserModelControllerVerifyB2CTokenAccessMutation>;
export type UserModelControllerVerifyB2CTokenAccessMutationResult = Apollo.MutationResult<UserModelControllerVerifyB2CTokenAccessMutation>;
export type UserModelControllerVerifyB2CTokenAccessMutationOptions = Apollo.BaseMutationOptions<UserModelControllerVerifyB2CTokenAccessMutation, UserModelControllerVerifyB2CTokenAccessMutationVariables>;