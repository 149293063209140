import React from 'react';

import {
  FormHelperText as MuiFormHelperText,
  FormHelperTextProps as MuiFormHelperTextProps,
} from '@mui/material';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

export type DSL_FormHelperTextProps = Omit<
  MuiFormHelperTextProps,
  DSL_RestrictedVisualProps
>;

export const DSL_FormHelperText = (props: DSL_FormHelperTextProps) => {
  return <MuiFormHelperText {...props} sx={{ml: 0}} />;
};

DSL_FormHelperText.displayName = 'DSL_FormHelperText';

export {
  //
  /** @deprecated use DSL_FormHelperTextProps instead*/
  DSL_FormHelperTextProps as FormHelperTextProps, //
  /** @deprecated use DSL_FormHelperText instead*/
  DSL_FormHelperText as FormHelperText,
};
