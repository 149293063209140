/* eslint-disable @typescript-eslint/explicit-function-return-type */
import * as R from 'ramda';
import {ApolloError} from '@apollo/client';
import getFetchDataResult from 'utils/apollo/getFetchDataResult';
import {
  BasicMachineContext,
  FormEventObject,
  FormRecord,
} from 'utils/stores/types';
import {
  assign,
  createMachine,
  Interpreter,
  MachineConfig,
  MachineOptions,
  State,
} from 'xstate';
import machineDefinition from './queryLoaderMachine.json';
import {RecursivePartial} from '../../typeDefs/utils';

export interface QueryLoaderMachineStateSchema {
  states: {
    loading: {};
    loaded: {};
    error: {};
  };
}
export type QueryLoaderMachineService = Interpreter<
  BasicMachineContext,
  QueryLoaderMachineStateSchema,
  FormEventObject
>;
export type QueryLoaderMachineState = State<
  BasicMachineContext,
  FormEventObject,
  QueryLoaderMachineStateSchema
>;

export type QueryLoaderMachineConfig<RecordType = FormRecord> = MachineConfig<
  BasicMachineContext<RecordType>,
  QueryLoaderMachineStateSchema,
  FormEventObject
>;

export type QueryLoaderMachineOptions<RecordType = FormRecord> = MachineOptions<
  BasicMachineContext<RecordType>,
  FormEventObject
>;

export default function getMachine<RecordType>(
  config: RecursivePartial<QueryLoaderMachineConfig<RecordType>>,
  options: Partial<QueryLoaderMachineOptions<RecordType>>,
) {
  return createMachine<BasicMachineContext<RecordType>, FormEventObject>(
    R.mergeDeepRight(
      machineDefinition as QueryLoaderMachineConfig<RecordType>,
      config,
    ) as QueryLoaderMachineConfig<RecordType>,
    R.mergeDeepRight(
      {
        actions: {
          onLoadSuccess: assign({
            record: (_context, event) => getFetchDataResult(event.data),
            props: context => R.assoc('apiError', undefined, context.props),
          }),
          onLoadError: assign({
            props: (context, event) =>
              R.assoc('apiError', event.data as ApolloError, context.props),
          }),
        } as QueryLoaderMachineOptions<RecordType>['actions'],
      } as QueryLoaderMachineOptions<RecordType>,
      options,
    ) as Partial<QueryLoaderMachineOptions<RecordType>>,
  );
}
