import React from 'react';

import {Box, BoxProps} from '@mui/material';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

export interface DSL_SidebarMenuTopAndBottomLayoutProps
  extends Omit<BoxProps, DSL_RestrictedVisualProps> {
  topSection: React.ReactNode;
  bottomSection: React.ReactNode;
}

export const DSL_SidebarMenuTopAndBottomLayout = ({
  topSection,
  bottomSection,
  ...boxProps
}: DSL_SidebarMenuTopAndBottomLayoutProps) => {
  return (
    <Box
      {...boxProps}
      sx={{
        height: 'inherit',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: 6,
      }}
      role="presentation"
    >
      {topSection}
      {bottomSection}
    </Box>
  );
};

DSL_SidebarMenuTopAndBottomLayout.displayName =
  'DSL_SidebarMenuTopAndBottomLayout';

export {
  //
  /** @deprecated use DSL_SidebarMenuTopAndBottomLayoutProps instead*/
  DSL_SidebarMenuTopAndBottomLayoutProps as SidebarMenuTopAndBottomLayoutProps, //
  /** @deprecated use DSL_SidebarMenuTopAndBottomLayout instead*/
  DSL_SidebarMenuTopAndBottomLayout as SidebarMenuTopAndBottomLayout,
};
