import React from 'react';

import {
  GridItem,
  UseGridItemStyles,
  CheckboxGroupInput,
  CheckboxGroupInputProps,
} from 'components/DesignSystem/Library';

import {FieldProps} from '../types';
import {DSP_GetGenericFieldProps} from '../utils/getGenericFieldProps';

import {useCheckboxGroupField} from './useCheckboxGroupField';

export interface DSP_CheckboxGroupFieldProps
  extends FieldProps,
    Pick<
      DSP_GetGenericFieldProps,
      'label' | 'hideLabel' | 'includeOptionalSuffix'
    >,
    UseGridItemStyles,
    Omit<CheckboxGroupInputProps, 'value' | 'multiple' | 'options'> {
  recordsLoaderRef?: string;
  options?: CheckboxGroupInputProps['options'];
}

export const DSP_CheckboxGroupField = ({
  span,
  xs,
  sm,
  md,
  lg,
  xl,
  ...props
}: DSP_CheckboxGroupFieldProps) => {
  const fieldProps = useCheckboxGroupField(props);
  return (
    <GridItem span={span} xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      <CheckboxGroupInput {...fieldProps} />
    </GridItem>
  );
};

export {
  //
  /** @deprecated use DSP_CheckboxGroupFieldProps instead*/
  DSP_CheckboxGroupFieldProps as CheckboxGroupFieldProps, //
  /** @deprecated use DSP_CheckboxGroupField instead*/
  DSP_CheckboxGroupField as CheckboxGroupField,
};
