import React from 'react';

import {
  ButtonBar,
  ButtonBarGroup,
  ContainedButton,
} from 'components/DesignSystem/Library';

export interface DSP_CloseButtonStackViewProps {
  onClick: () => void;
}

export const DSP_CloseButtonStackView = (
  props: DSP_CloseButtonStackViewProps,
) => {
  return (
    <ButtonBar>
      <ButtonBarGroup>
        <ContainedButton {...props}>Close</ContainedButton>
      </ButtonBarGroup>
    </ButtonBar>
  );
};

DSP_CloseButtonStackView.displayName = 'DSP_CloseButtonStackView';

export {
  //
  /** @deprecated use DSP_CloseButtonStackViewProps instead*/
  DSP_CloseButtonStackViewProps as CloseButtonStackViewProps, //
  /** @deprecated use DSP_CloseButtonStackView instead*/
  DSP_CloseButtonStackView as CloseButtonStackView,
};
