import React, {useMemo} from 'react';

import {DSL_BaseBox} from '../../Layout/Box/BaseBox';
import {DSL_Popper, usePopper} from '../../Layout/Popper';
import {DSL_BaseTypographyProps} from '../../Typography/BaseTypography';
import {DSL_Body1} from '../../Typography/Body1';

export interface DSL_HtmlWithPreviewRendererProps {
  html?: string;
  TypographyComponent?: React.ComponentType<DSL_BaseTypographyProps>;
}
export const DSL_HtmlWithPreviewRenderer = ({
  html,
  TypographyComponent = DSL_Body1,
}: DSL_HtmlWithPreviewRendererProps) => {
  const {popperProps, openerProps, setIsOpen} = usePopper();

  const plainText = useMemo(() => {
    // Create a new div element
    const tempDivElement = document.createElement('div');

    // Set the HTML content with the given value
    tempDivElement.innerHTML = html || '';

    // Retrieve the text property of the element
    return tempDivElement.textContent || tempDivElement.innerText;
  }, [html]);

  if (!plainText) {
    return null;
  }

  const {onClick, ...rest} = openerProps;

  return (
    <DSL_BaseBox
      width={'100%'}
      cursor={'pointer'}
      {...rest}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <TypographyComponent
        component={'div'}
        noWrap
        width={'100%'}
        ellipsisLines={0}
      >
        {plainText}
      </TypographyComponent>
      <DSL_Popper
        maxWidth={{
          xs: '100%',
          sm: '32rem',
          md: '40rem',
        }}
        maxHeight={'50vh'}
        placement={'auto'}
        {...popperProps}
      >
        <DSL_Body1
          p={2}
          component={'div'}
          width={'100%'}
          dangerouslySetInnerHTML={{__html: html || ''}}
        />
      </DSL_Popper>
    </DSL_BaseBox>
  );
};
