import React from 'react';

import {
  DSL_EntityTitle,
  DSL_EntityTitleProps,
  usePopover,
} from 'components/DesignSystem/Library';

import {
  DSP_RegulatorAccountEntityPopover,
  DSP_RegulatorAccountEntityPopoverProps,
} from '../RegulatorAccountEntityPopover';

export interface DSP_RegulatorAccountEntityNameViewProps
  extends Omit<DSP_RegulatorAccountEntityPopoverProps, 'open'>,
    ReturnType<typeof usePopover<HTMLElement>>,
    Pick<DSL_EntityTitleProps, 'skeletonWidth'> {}

export const DSP_RegulatorAccountEntityNameView = ({
  isLoading,
  title,
  subTitle,
  anchorRef,
  skeletonWidth,
  openPopover,
  closePopover,
  isPopoverOpen,
  ...props
}: DSP_RegulatorAccountEntityNameViewProps) => {
  return (
    <>
      <DSP_RegulatorAccountEntityPopover
        anchorEl={anchorRef.current}
        onMouseEnter={openPopover}
        onMouseLeave={closePopover}
        isLoading={isLoading}
        open={isPopoverOpen}
        title={title}
        subTitle={subTitle}
        {...props}
      />
      <DSL_EntityTitle
        ref={anchorRef}
        isLoading={isLoading}
        onMouseEnter={openPopover}
        onMouseLeave={closePopover}
        skeletonWidth={skeletonWidth}
        color={'primary.main'}
      >
        {title}
      </DSL_EntityTitle>
    </>
  );
};

DSP_RegulatorAccountEntityNameView.displayName =
  'DSP_RegulatorAccountEntityNameView';
