import React from 'react';

import {
  ListItemButton as MuiListItemButton,
  ListItemButtonProps as MuiListItemButtonProps,
} from '@mui/material';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

export interface DSL_PopperListItemButtonProps
  extends Omit<
    MuiListItemButtonProps,
    DSL_RestrictedVisualProps | 'disableRipple'
  > {
  padding?: React.CSSProperties['padding'];
}

export const DSL_PopperListItemButton = ({
  padding,
  ...props
}: DSL_PopperListItemButtonProps) => {
  return (
    <MuiListItemButton
      disableRipple
      sx={{
        paddingRight: 2,
        paddingLeft: 2,
        paddingTop: 1,
        paddingBottom: 1,
        ...(padding && {
          padding,
        }),
      }}
      {...props}
    />
  );
};

DSL_PopperListItemButton.displayName = 'DSL_PopperListItemButton';

export {
  //
  /** @deprecated use DSL_PopperListItemButtonProps instead*/
  DSL_PopperListItemButtonProps as PopperListItemButtonProps, //
  /** @deprecated use DSL_PopperListItemButton instead*/
  DSL_PopperListItemButton as PopperListItemButton,
};
