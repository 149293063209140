import GroupIcon from '@mui/icons-material/Group';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/manage/members/:id',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "ManageMemberSwitch" */ './ManageMemberSwitch'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Manage member',
  menuIcon: GroupIcon,
  authorisation: {
    apiKey: null,
    policies: [
      {role: 'operationsAccountRead'},
      {role: 'providerAccountRead'},
      {role: 'customerAccountRead'},
      {role: 'partnerAccountRead'},
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Account',
      to: '../..',
      state: {'Manage account': 'Members'},
    },
  ],
};
