import React, {ReactNode} from 'react';

import {DSL_Chip, DSL_HorizontalStack} from 'components/DesignSystem/Library';

import {DSP_NotificationCard} from '../NotificationCard';
import {DSP_NotificationCardBody} from '../NotificationCardBody';
import {DSP_NotificationCardHeader} from '../NotificationCardHeader';
import {DSP_NotificationCardTitle} from '../NotificationCardTitle';

export interface DSP_AccessPublicNoticeOfAmendmentNotificationCardViewProps {
  avatar: ReactNode | undefined;
  href?: string;
  accessPublicNoticeOfAmendmentId?: string;
  created?: Date;
  actionDescription?: string;
  description?: string;
  isRead?: boolean;
  status?: string;
  title?: string;
}

export const DSP_AccessPublicNoticeOfAmendmentNotificationCardView = ({
  avatar,
  href,
  created,
  actionDescription,
  description,
  isRead,
  status,
  title,
}: DSP_AccessPublicNoticeOfAmendmentNotificationCardViewProps) => {
  return (
    <DSP_NotificationCard href={href} isRead={isRead}>
      <DSP_NotificationCardHeader
        created={created}
        actionDescription={actionDescription}
        avatar={avatar}
      />
      {title && <DSP_NotificationCardTitle>{title}</DSP_NotificationCardTitle>}
      {description && (
        <DSP_NotificationCardBody>{description}</DSP_NotificationCardBody>
      )}
      <DSL_HorizontalStack>
        <DSL_Chip variant={'outlined'} size={'small'} label={status} />
      </DSL_HorizontalStack>
    </DSP_NotificationCard>
  );
};

DSP_AccessPublicNoticeOfAmendmentNotificationCardView.displayName =
  'DSP_AccessPublicNoticeOfAmendmentNotificationCardView';
