import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';

export default {
  path: '/operationManualDocumentTemplates/create',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "OperationManualCreateDocumentTemplate" */ './OperationManualCreateDocumentTemplate'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Create Operation Manuel Document Template',
  menuIcon: LocalShippingOutlinedIcon,
  authorisation: {
    apiKey: null,
    policies: [{role: 'operationsAccreditationAdmin'}] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Operation Manual Document Templates',
      to: '..',
    },
  ],
};
