import React from 'react';

import {useBaseCardInput, UseBaseCardInputProps} from '../BaseCardInput';

import {
  DSL_CheckboxCardInputView,
  DSL_CheckboxCardInputViewProps,
} from './CheckboxCardInput.View';

export interface DSL_CheckboxCardInputProps
  extends Omit<DSL_CheckboxCardInputViewProps, 'onClickCard'>,
    UseBaseCardInputProps {}

export const DSL_CheckboxCardInput = ({
  onClickCard,
  ...props
}: DSL_CheckboxCardInputProps) => {
  const {ref, ...viewProps} = useBaseCardInput<HTMLInputElement>({onClickCard});

  return <DSL_CheckboxCardInputView ref={ref} {...props} {...viewProps} />;
};

DSL_CheckboxCardInput.displayName = 'DSL_CheckboxCardInput';
