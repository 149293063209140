import {ConsentWorkflowState} from 'containers-access/AccessProductApplication/types';

import {consentWorkflowStateDescriptionMap} from '../constants';
import {DSP_AccessProductChangeSetNotificationItem} from '../types';

export interface DSP_UseBaseAccessProductChangeSetNotificationCardProps {
  notification: DSP_AccessProductChangeSetNotificationItem;
}

export function useBaseAccessProductChangeSetNotificationCard({
  notification,
}: DSP_UseBaseAccessProductChangeSetNotificationCardProps) {
  const {
    networkAccessChangeSetId,
    networkId,
    consentState,
    name,
    status,
    accessProductDocumentId,
    accessProductApplicationId,
    parentAccessProductChangeSetId,
  } = notification.data;

  const actionDescription = !parentAccessProductChangeSetId
    ? consentWorkflowStateDescriptionMap[
        consentState?.workflowState as ConsentWorkflowState
      ]
    : 'responded to your update request';

  return {
    actionDescription,
    isRead: notification.readByLoggedInUser,
    href: `/networks/accessProductChangeSets/${accessProductDocumentId}/accessProductApplication/${accessProductApplicationId}/network/${networkId}/nur/${networkAccessChangeSetId}`,
    created: notification.created ?? undefined,
    changeSetId: `NUR${networkAccessChangeSetId ?? ''}`,
    name: name ?? undefined,
    status: !parentAccessProductChangeSetId && status ? status : undefined,
  };
}
