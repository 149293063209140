import React from 'react';

import {DSP_RegulatorAccountEntityAvatarView} from './RegulatorAccountEntityAvatar.View';
import {useRegulatorAccountEntityAvatar} from './useRegulatorAccountEntityAvatar';

export const DSP_RegulatorAccountEntityAvatar = () => {
  const viewProps = useRegulatorAccountEntityAvatar();

  return <DSP_RegulatorAccountEntityAvatarView {...viewProps} />;
};

DSP_RegulatorAccountEntityAvatar.displayName =
  'DSP_RegulatorAccountEntityAvatar';
