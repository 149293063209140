import {useSelector} from '@xstate/react';
import AuthenticatedRouter from 'app/router/components/AuthenticatedRouter';
import MapRouter from 'app/router/components/MapRouter';
import UnauthenticatedRouter from 'app/router/components/UnauthenticatedRouter';
import AppServicesContext, {
  useIsBetaMode,
} from 'app/services/AppServicesContext';
import * as R from 'ramda';
import React, {FC, useContext} from 'react';
import Verify from '../../containers/Verify/Verify';
import {DSP_PageContainer} from '../../components/DesignSystem/Portal';
import {useSearchParams} from 'react-router-dom';

// const selectState = (state) => (state);
// const shouldTriggerRender = (prevState, nextState) => prevState.value === nextState.value;

const Router: FC<{}> = () => {
  const {auth, app} = useContext(AppServicesContext);

  const authState = useSelector(auth.service, R.identity);
  const appState = useSelector(app.service, R.identity);
  const [searchParams] = useSearchParams();
  const isBetaMode = useIsBetaMode();

  const iframeMode = appState.matches('iframe');

  const path = window.location.pathname;

  const isUnauthenticatedMapUrl =
    !authState.matches('authenticated') && path.match(/\/maps/);

  if (appState.matches('map') || isUnauthenticatedMapUrl) {
    return <MapRouter iframeMode={iframeMode} authState={authState} />;
  }

  if (appState.matches('thirdPartyVerification')) {
    return <Verify />;
  }

  if (authState.matches('authenticated') || authState.matches('loggingOut')) {
    const clientId = searchParams.get('clientId');

    if (
      authState.matches('authenticated') &&
      isBetaMode &&
      !iframeMode &&
      !R.isNil(clientId)
    ) {
      return <DSP_PageContainer title={`Logging in. Please wait...`} loading />;
    }
    return (
      <AuthenticatedRouter
        iframeMode={iframeMode}
        authState={authState}
        authService={auth.service}
      />
    );
  }

  return <UnauthenticatedRouter iframeMode={iframeMode} />;
};

export default Router;
