import React from 'react';
import {useStyles} from '../form.styles';

type FormTextProps = {
  children: React.ReactNode;
};

/**
 * Standard row for all forms inputs providing consistent spacing
 */
const FieldRow: React.FC<FormTextProps> = (props: FormTextProps) => {
  const classes = useStyles();
  return <div className={classes.fieldRow}>{props.children}</div>;
};

export default FieldRow;
