import {lazy} from 'react';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/applications/vehicleApproval',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "VehicleApprovalGateway" */ './VehicleApprovalGateway'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Apply for vehicle approval',
  menuIcon: LocalShippingOutlinedIcon,
  authorisation: {
    apiKey: 'PbsApplicationWithRelations.create',
    policies: [{role: 'providerPBSUser'}] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'PBS applications',
      to: '..',
    },
  ],
};
