import React from 'react';

import {DSL_BaseEmptyState, DSL_BaseEmptyStateProps} from '../BaseEmptyState';

import {DSL_SaveEmptyStateIcon} from './SaveEmptyStateIcon';

export type DSL_SaveEmptyStateProps = Omit<DSL_BaseEmptyStateProps, 'icon'>;

export const DSL_SaveEmptyState = (props: DSL_SaveEmptyStateProps) => {
  return <DSL_BaseEmptyState icon={<DSL_SaveEmptyStateIcon />} {...props} />;
};

DSL_SaveEmptyState.displayName = 'DSL_SaveEmptyState';

export {
  //
  /** @deprecated use DSL_SaveEmptyStateProps instead*/
  DSL_SaveEmptyStateProps as SaveEmptyStateProps, //
  /** @deprecated use DSL_SaveEmptyState instead*/
  DSL_SaveEmptyState as SaveEmptyState,
};
