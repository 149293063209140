import {useCallback, useMemo, useState} from 'react';

import {clone} from 'rambda';
import {equals} from 'ramda';
import {v4} from 'uuid';

import {
  DSL_DrawerContext,
  DSL_DrawerDetails,
  DSL_DrawerDimensions,
} from './types';

const initialDimensions = {width: 0, height: 0};
const initialDimensionsByAnchor = {
  left: initialDimensions,
  right: initialDimensions,
  bottom: initialDimensions,
};
const initialDrawerDimensions: DSL_DrawerDimensions = {
  fixed: initialDimensionsByAnchor,
  nonModal: initialDimensionsByAnchor,
  modal: initialDimensionsByAnchor,
  fixedAndNonModal: initialDimensionsByAnchor,
  all: initialDimensionsByAnchor,
};

export function useDrawerProvider(): DSL_DrawerContext {
  const [offsetTop, setOffsetTopInternal] = useState(0);
  const [drawers, setDrawers] = useState<DSL_DrawerDetails[]>([]);
  const [minimizedDrawerHeight, setMinimizedDrawerHeight] = useState(0);

  const createDrawer: DSL_DrawerContext['createDrawer'] = useCallback(
    drawer => {
      const id = v4();
      setDrawers(drawerList => [...drawerList, {...drawer, id}]);
      return id;
    },
    [],
  );

  const updateDrawer: DSL_DrawerContext['updateDrawer'] = useCallback(
    newDrawer => {
      const drawerIndex = drawers.findIndex(({id}) => newDrawer.id === id);
      if (drawerIndex === -1) {
        return;
      }
      if (!drawers.some(oldDrawer => equals(oldDrawer, newDrawer))) {
        setDrawers(drawerList => [
          ...drawerList.filter(({id}) => newDrawer.id !== id),
          newDrawer,
        ]);
      }
    },
    [drawers],
  );

  const removeDrawer: DSL_DrawerContext['removeDrawer'] = useCallback(
    drawerId => {
      if (drawerId) {
        setDrawers(drawerList => drawerList.filter(({id}) => id !== drawerId));
      }
    },
    [],
  );

  const setOffsetTop = useCallback(
    (newOffsetTop: number) => {
      if (offsetTop !== newOffsetTop) {
        setOffsetTopInternal(newOffsetTop);
      }
    },
    [offsetTop],
  );

  const drawerDimensions = useMemo(
    () => getDrawerDimensions(drawers),
    [drawers],
  );

  return useMemo((): DSL_DrawerContext => {
    // TODO: remove these deprecated props and refactor consumers to use drawerDimensions, https://nhvr.atlassian.net/browse/NHVRREP-32450
    // start
    const maxSidebarWidths = {
      left: Math.max(
        0,
        ...drawers.filter(d => d.anchor === 'left').map(d => d.sidebarWidth),
      ),
      right: Math.max(
        0,
        ...drawers.filter(d => d.anchor === 'right').map(d => d.sidebarWidth),
      ),
      bottom: 0,
    };
    const maxWidths = {
      left:
        drawerDimensions.all.left.width > 0
          ? drawerDimensions.all.left.width - maxSidebarWidths.left
          : drawerDimensions.all.left.width,
      right:
        drawerDimensions.all.right.width > 0
          ? drawerDimensions.all.right.width - maxSidebarWidths.right
          : drawerDimensions.all.right.width,
      bottom: 0,
    };
    const maxNonModalWidths = {
      left:
        drawerDimensions.nonModal.left.width > 0
          ? drawerDimensions.nonModal.left.width - maxSidebarWidths.left
          : drawerDimensions.nonModal.left.width,
      right:
        drawerDimensions.nonModal.right.width > 0
          ? drawerDimensions.nonModal.right.width - maxSidebarWidths.right
          : drawerDimensions.nonModal.right.width,
      bottom: 0,
    };
    const maxFixedWidths = {
      left:
        drawerDimensions.fixed.left.width > 0
          ? drawerDimensions.fixed.left.width - maxSidebarWidths.left
          : drawerDimensions.fixed.left.width,
      right:
        drawerDimensions.fixed.right.width > 0
          ? drawerDimensions.fixed.right.width - maxSidebarWidths.right
          : drawerDimensions.fixed.right.width,
      bottom: 0,
    };
    // end

    return {
      drawers,
      drawerDimensions,
      offsetTop,
      setOffsetTop,
      createDrawer,
      updateDrawer,
      removeDrawer,
      maxWidths,
      maxNonModalWidths,
      maxFixedWidths,
      maxSidebarWidths,
      minimizedDrawerHeight,
      setMinimizedDrawerHeight,
    };
  }, [
    drawers,
    drawerDimensions,
    offsetTop,
    setOffsetTop,
    createDrawer,
    updateDrawer,
    removeDrawer,
    minimizedDrawerHeight,
    setMinimizedDrawerHeight,
  ]);
}

function getDrawerDimensions(drawerList: DSL_DrawerDetails[]) {
  return drawerList.reduce((drawerDimensions, nextDrawer) => {
    const {
      open,
      variant,
      width,
      height,
      anchor,
      sidebarAnchor,
      sidebarWidth,
      sidebarHeight,
    } = nextDrawer;

    const allDimensions = drawerDimensions.all[anchor];
    const fixedAndNonModal = drawerDimensions.fixedAndNonModal[anchor];
    const dimensionsForVariant = drawerDimensions[variant][anchor];

    const sidebarWidthInclusion = sidebarAnchor === 'bottom' ? 0 : sidebarWidth;
    const sidebarHeightInclusion =
      sidebarAnchor === 'bottom' ? sidebarHeight : 0;

    const nextWidth = open
      ? width + sidebarWidthInclusion
      : sidebarWidthInclusion;
    const nextHeight = open
      ? height + sidebarHeightInclusion
      : sidebarHeightInclusion;

    [allDimensions, dimensionsForVariant].forEach(dimension => {
      dimension.width = Math.max(dimension.width, nextWidth);
      dimension.height = Math.max(dimension.height, nextHeight);
    });

    [fixedAndNonModal, dimensionsForVariant].forEach(dimension => {
      if (variant === 'fixed' || variant === 'nonModal') {
        dimension.width = Math.max(dimension.width, nextWidth);
        dimension.height = Math.max(dimension.height, nextHeight);
      }
    });

    return drawerDimensions;
  }, clone(initialDrawerDimensions));
}
