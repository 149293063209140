import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/permitCustomConditions',
  component: lazy(
    () =>
      import(/* webpackChunkName: "PreApproval" */ './PermitPartnerCondition'),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'Custom conditions',
  menuIcon: LibraryAddCheckIcon,
  authorisation: {
    apiKey: 'PermitPartnerConditionWithRelations.findById',
    policies: [{role: 'operationsAccessRead'}] as AuthorisationPolicy[],
  },
  sections: [],
};
