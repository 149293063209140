import React from 'react';

import {Body1, Body1Props} from 'components/DesignSystem/Library';

type DSP_NotificationCardTitleProps = Body1Props;

export const DSP_NotificationCardTitle = (
  props: DSP_NotificationCardTitleProps,
) => <Body1 {...props} />;

DSP_NotificationCardTitle.displayName = 'DSP_NotificationCardTitle';
