import Layers from '@mui/icons-material/Layers';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {Fragment, lazy} from 'react';

export default {
  path: '/accessInstruments/:accessInstrumentId/accessProductDocument/:accessProductDocumentId/network',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "NetworkManagement" */ 'containers-spatial/NetworkManagement/NetworkManagement.Connector'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Network management',
  menuDescription:
    'Plan, manage & publish networks in your area of responsibility',
  menuIcon: Layers,
  parent: false,
  authorisation: {
    apiKey: 'AccessProductDocumentWithRelations.findById',
    policies: [
      {role: 'operationsSpatialRead'},
      {role: 'partnerSpatialRead'},
    ] as AuthorisationPolicy[],
  },
  category: 'Map',
  sections: [],
  nestedRoutes: [
    {
      path: '',
      component: Fragment,
    },
    {
      path: 'create',
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "createNetwork" */ 'containers-spatial/NetworkManagement/NetworkDetails/Create'
          ),
      ),
    },
    {
      path: ':networkId/view',
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "viewNetwork" */ 'containers-spatial/NetworkManagement/NetworkDetails/View'
          ),
      ),
    },
    {
      path: ':networkId/edit',
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "editNetwork" */ 'containers-spatial/NetworkManagement/NetworkDetails/Edit'
          ),
      ),
    },
    {
      path: ':networkId/duplicate',
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "dupeNetwork" */ 'containers-spatial/NetworkManagement/NetworkDetails/Duplicate'
          ),
      ),
    },
    {
      path: ':networkId/nur/:nurId',
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "manageNur" */ 'containers-spatial/NetworkManagement/NetworkUpdateRequests/Edit'
          ),
      ),
    },
  ],
};
