import {useCallback, useMemo, useState} from 'react';

import {DSL_PopperProps} from '../Popper';

import {DSL_PopperContext} from './PopperProvider';

export interface IdentifiablePopper
  extends Pick<DSL_PopperProps, 'closePopper'> {
  id: string;
}

export function usePopperProvider(): DSL_PopperContext {
  const [poppers, setPoppers] = useState<IdentifiablePopper[]>([]);

  const createPopper: DSL_PopperContext['createPopper'] = useCallback(
    popper =>
      setPoppers(prevList => {
        prevList.push(popper);
        return [...prevList];
      }),
    [],
  );

  const removePopper: DSL_PopperContext['removePopper'] = useCallback(
    _id => setPoppers(prevList => prevList.filter(({id}) => id !== _id)),
    [],
  );

  const closeOtherPoppers: DSL_PopperContext['closeOtherPoppers'] = useCallback(
    _id =>
      poppers.forEach(({id, closePopper}) => {
        if (id !== _id) {
          closePopper();
        }
      }),
    [poppers],
  );

  return useMemo(
    () => ({
      createPopper,
      removePopper,
      closeOtherPoppers,
    }),
    [closeOtherPoppers, createPopper, removePopper],
  );
}
