import React from 'react';

import {
  DSL_CloseIconButton,
  DSL_FlexRow,
  DSL_TableCell,
  DSL_TableRow,
} from 'components/DesignSystem/Library';

import {DSP_FormTableCell} from './FormTableCell';
import {DSP_FormTableRowRenderer, DSP_FormTableRowRendererProps} from './types';
import {flattenFormTableColumnConfig} from './utils';

export const DSP_FormTableRow: DSP_FormTableRowRenderer = ({
  border = 'all',
  fieldRefPath,
  rowIndex,
  columns,
  onRemoveRow,
  actions,
}: DSP_FormTableRowRendererProps) => {
  const cellBorder = {
    borderTop: border === 'top' || border === 'all' ? undefined : 'none',
    borderBottom: border === 'bottom' || border === 'all' ? undefined : 'none',
  };

  return (
    <DSL_TableRow>
      {flattenFormTableColumnConfig(columns).map(
        (
          {CellRenderer = DSP_FormTableCell, size, field, width, title},
          colIndex,
        ) => (
          <CellRenderer
            key={colIndex}
            size={size}
            width={width}
            title={title}
            rowIndex={rowIndex}
            fieldRef={`${fieldRefPath}/${rowIndex}/${field}`}
            {...cellBorder}
          />
        ),
      )}
      <DSL_TableCell verticalAlign={'center'} {...cellBorder}>
        <DSL_FlexRow flexWrap={'nowrap'} alignItems={'center'}>
          {actions}
          {onRemoveRow && (
            <DSL_CloseIconButton
              size={'medium'}
              color={'error'}
              title={'Remove row'}
              onClick={() => onRemoveRow(rowIndex)}
            />
          )}
        </DSL_FlexRow>
      </DSL_TableCell>
    </DSL_TableRow>
  );
};

DSP_FormTableRow.displayName = 'DSP_FormTableRow';
