import React from 'react';

import {useCurrentBreakpoint} from '../hooks/useCurrentBreakpoint';

import {GovernmentLogosSizeMap} from './constants';
import {GovernmentLogoProps} from './types';

export const DSL_VicLogo = ({
  sizeMap = GovernmentLogosSizeMap,
}: GovernmentLogoProps) => {
  const breakpoint = useCurrentBreakpoint();
  return (
    <svg
      {...sizeMap[breakpoint ?? 'md']}
      viewBox="0 0 100 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>VIC Government Logo</title>
      <path
        d="M58.1625 35.4584C61.2124 35.4584 63.6581 33.1682 63.6581 30.1473C63.6581 27.1264 61.2116 24.8353 58.1625 24.8353C55.1133 24.8353 52.6659 27.1256 52.6659 30.1473C52.6659 33.169 55.1125 35.4584 58.1625 35.4584ZM58.1625 27.5989C59.5698 27.5989 60.7323 28.7513 60.7323 30.1473C60.7323 31.5432 59.5706 32.6956 58.1625 32.6956C56.7543 32.6956 55.5926 31.5432 55.5926 30.1473C55.5926 28.7513 56.7543 27.5989 58.1625 27.5989Z"
        fill="#094792"
      />
      <path
        d="M71.8039 31.8133C72.7883 31.1359 73.5681 30.1319 73.5681 28.7642C73.5681 26.6369 71.8579 25.0651 69.6973 25.0651H65.0354V35.2278H67.8251V32.4625H69.1374L70.4094 35.2278H73.5407L71.8047 31.8133H71.8039ZM69.6981 29.9561H67.8259V27.557H69.6981C70.328 27.557 70.7389 28.2618 70.7389 28.7634C70.7389 29.265 70.3289 29.9561 69.6981 29.9561Z"
        fill="#094792"
      />
      <path
        d="M75.2611 25.0651H78.0508V35.2278H75.2611V25.0651Z"
        fill="#094792"
      />
      <path
        d="M83.8459 25.0643L79.1429 35.2262H82.1324L82.623 34.0754H86.1128L86.6034 35.2262H89.5929L84.8923 25.0643H83.8459ZM83.4753 31.6634L84.3421 29.6852H84.3977L85.2629 31.6634H83.4753Z"
        fill="#094792"
      />
      <path
        d="M46.4708 35.2286H49.2606V27.5586H52.064V25.0659H45.3108L51.8699 10.8986H10.1631L16.7633 25.1571H19.8406L22.3661 30.6118L24.8908 25.1571H27.9746L23.3546 35.1383H21.3841L31.0165 55.9474L40.8704 34.6592C40.1075 35.102 39.2198 35.3552 38.2724 35.3552C35.295 35.3552 32.9298 33.1061 32.9298 30.1384C32.9298 27.1707 35.295 24.9216 38.2724 24.9216C40.1752 24.9216 41.8355 25.9466 42.7394 27.4635L40.4129 28.861C39.9746 28.1417 39.1763 27.6368 38.2724 27.6368C36.8908 27.6368 35.7743 28.7683 35.7743 30.1392C35.7743 31.5101 36.8908 32.6424 38.2724 32.6424C39.1771 32.6424 39.9746 32.1496 40.4129 31.4174L41.9459 32.3383L44.158 27.5594H46.47V35.2302H46.4717L46.4708 35.2286ZM31.661 35.1383H28.8132V25.1571H31.661V35.1383Z"
        fill="#094792"
      />
      <path
        d="M48.1239 43.6171C47.4714 43.6171 46.872 43.3631 46.5063 43.0695L45.9786 43.8848C46.524 44.3251 47.3505 44.5485 48.1191 44.5485C49.2114 44.5485 50.1975 44.0163 50.1975 42.8937C50.1975 42.0082 49.4869 41.6204 48.5807 41.4712L48.2947 41.4244C47.6849 41.322 47.2458 41.2107 47.2458 40.8349C47.2458 40.4591 47.647 40.226 48.2173 40.226C48.7877 40.226 49.259 40.4099 49.6577 40.6575L50.1363 39.8293C49.4982 39.4494 48.9061 39.2898 48.1964 39.2898C47.1145 39.2898 46.172 39.8406 46.172 40.9002C46.172 41.8204 46.8245 42.2155 47.7203 42.3558L48.0433 42.4074C48.7498 42.5203 49.1196 42.6518 49.1196 43.0026C49.1204 43.3937 48.6854 43.6171 48.1231 43.6171"
        fill="#094792"
      />
      <path
        d="M53.6359 44.1808L53.2879 43.438C53.0961 43.5558 52.8537 43.6687 52.6289 43.6687C52.3357 43.6687 52.1037 43.5082 52.1037 43.0695V41.7841H53.3749V40.9413H52.1037V39.8962H51.1563V40.9413H50.478V41.7841H51.1563V43.1453C51.1563 44.0566 51.601 44.5501 52.4734 44.5501C52.9737 44.5501 53.3273 44.3904 53.6375 44.1808"
        fill="#094792"
      />
      <path
        d="M57.4801 44.4598V40.9421H56.5545V41.3284C56.3225 41.0429 55.9858 40.847 55.5024 40.847C54.5349 40.847 53.8026 41.6429 53.8026 42.6986C53.8026 43.7542 54.5341 44.5501 55.5024 44.5501C55.9858 44.5501 56.3257 44.3533 56.5545 44.0735V44.4606H57.4801V44.459V44.4598ZM55.678 43.6679C55.123 43.6679 54.7709 43.2356 54.7709 42.6986C54.7709 42.1615 55.123 41.73 55.678 41.73C56.2331 41.73 56.5835 42.1429 56.5835 42.6986C56.5835 43.2542 56.2178 43.6679 55.678 43.6679Z"
        fill="#094792"
      />
      <path
        d="M59.9747 44.5501C60.4758 44.5501 60.8303 44.3904 61.1388 44.1816L60.7908 43.4405C60.5991 43.5574 60.3566 43.6703 60.1302 43.6703C59.837 43.6703 59.605 43.509 59.605 43.0711V41.7849H60.8794V40.9422H59.605V39.897H58.6592V40.9422H57.9809V41.7849H58.6592V43.1469C58.6592 44.0566 59.1055 44.5509 59.9763 44.5509"
        fill="#094792"
      />
      <path
        d="M64.072 43.4058C63.8174 43.6211 63.4887 43.7453 63.1826 43.7453C62.7379 43.7453 62.3826 43.5235 62.2795 43.0139H64.772C64.7849 42.934 64.7897 42.8042 64.7897 42.6945C64.7865 41.5978 64.0937 40.847 63.0835 40.847C62.0733 40.847 61.3056 41.6018 61.3056 42.709C61.3056 43.8163 62.0524 44.5485 63.1294 44.5485C63.7207 44.5485 64.229 44.3816 64.627 44.0163L64.072 43.4058ZM63.0746 41.6639C63.4798 41.6639 63.7513 41.9179 63.8399 42.3679H62.2836C62.3665 41.9324 62.6283 41.6639 63.0746 41.6639Z"
        fill="#094792"
      />
      <path
        d="M48.7547 48.1726V49.0177H50.0452C49.9115 49.7411 49.429 50.1411 48.6024 50.1411C47.7227 50.1411 47.0178 49.4903 47.0178 48.4524C47.0178 47.4146 47.6929 46.7791 48.6201 46.7791C49.1398 46.7791 49.5595 46.9823 49.8471 47.3113L50.5761 46.5968C50.1266 46.1065 49.4555 45.8178 48.6508 45.8178C47.0903 45.8178 45.9706 46.9178 45.9706 48.4532C45.9706 50.0951 47.2184 51.0838 48.625 51.0838C50.0315 51.0838 51.1062 50.0008 51.1062 48.4605C51.1062 48.367 51.1014 48.271 51.0909 48.1742H48.7555V48.1726H48.7547Z"
        fill="#094792"
      />
      <path
        d="M53.4801 47.3807C52.3877 47.3807 51.5829 48.1726 51.5829 49.2323C51.5829 50.2919 52.3885 51.0838 53.4801 51.0838C54.5716 51.0838 55.3845 50.2927 55.3845 49.2323C55.3845 48.1718 54.5757 47.3807 53.4801 47.3807ZM53.4801 50.2024C52.946 50.2024 52.5488 49.804 52.5488 49.2323C52.5488 48.6605 52.9468 48.2645 53.4801 48.2645C54.0134 48.2645 54.4162 48.6629 54.4162 49.2323C54.4162 49.8016 54.0126 50.2024 53.4801 50.2024Z"
        fill="#094792"
      />
      <path
        d="M57.418 49.9951L56.5069 47.475H55.4781L56.867 50.9935H57.9585L59.3329 47.475H58.3356L57.4172 49.9951H57.418Z"
        fill="#094792"
      />
      <path
        d="M61.2076 47.3807C60.1555 47.3807 59.4288 48.1363 59.4288 49.2427C59.4288 50.3492 60.1756 51.0822 61.2543 51.0822C61.8448 51.0822 62.3539 50.9161 62.7511 50.55L62.196 49.9395C61.9431 50.1532 61.6136 50.2782 61.3083 50.2782C60.862 50.2782 60.5067 50.0564 60.4044 49.5492H62.8977C62.9082 49.4677 62.9154 49.3371 62.9154 49.2282C62.9106 48.1315 62.2186 47.3807 61.2068 47.3807M60.4084 48.9024C60.4922 48.467 60.7532 48.1984 61.1995 48.1984C61.6047 48.1984 61.8762 48.4524 61.964 48.9024H60.4084Z"
        fill="#094792"
      />
      <path
        d="M64.4598 47.8597V47.4742H63.5414V50.9927H64.4879V49.0774C64.4879 48.5524 64.7884 48.2839 65.2275 48.2839C65.4192 48.2839 65.644 48.3283 65.8011 48.3976L65.9412 47.4581C65.8107 47.4105 65.6512 47.3807 65.449 47.3807C65.018 47.3815 64.6813 47.5315 64.4589 47.8605"
        fill="#094792"
      />
      <path
        d="M68.3983 47.3807C67.9995 47.3807 67.6152 47.5146 67.3647 47.8871V47.4742H66.4439V50.9927H67.3905V49.0645C67.3905 48.5347 67.728 48.2686 68.1348 48.2686C68.5658 48.2686 68.8196 48.5476 68.8196 49.0581V50.9919H69.7661V48.7524C69.7653 47.9363 69.1958 47.3815 68.3975 47.3815"
        fill="#094792"
      />
      <path
        d="M74.8929 47.3807C74.4313 47.3807 73.9729 47.5371 73.6998 47.9847C73.4638 47.6105 73.0481 47.3807 72.5398 47.3807C72.1418 47.3807 71.7568 47.5146 71.5054 47.8871V47.4742H70.5846V50.9927H71.532V49.0645C71.532 48.5347 71.8695 48.2686 72.2756 48.2686C72.7074 48.2686 72.9611 48.5476 72.9611 49.0581V50.9919H73.9085V49.0637C73.9085 48.5339 74.2452 48.2678 74.6504 48.2678C75.0798 48.2678 75.336 48.5468 75.336 49.0573V50.9911H76.2793V48.7516C76.2777 47.9363 75.7202 47.3815 74.8921 47.3815"
        fill="#094792"
      />
      <path
        d="M78.6571 47.3807C77.605 47.3807 76.8776 48.1363 76.8776 49.2427C76.8776 50.3492 77.6268 51.0822 78.7022 51.0822C79.2943 51.0822 79.801 50.9161 80.2014 50.55L79.6464 49.9395C79.3934 50.1532 79.0631 50.2782 78.7586 50.2782C78.3115 50.2782 77.9546 50.0564 77.8547 49.5492H80.3464C80.3569 49.4677 80.3641 49.3371 80.3641 49.2282C80.3593 48.1315 79.6673 47.3807 78.6571 47.3807ZM77.8588 48.9024C77.9425 48.467 78.2028 48.1984 78.6499 48.1984C79.0559 48.1984 79.3265 48.4524 79.4152 48.9024H77.8588Z"
        fill="#094792"
      />
      <path
        d="M82.9463 47.3807C82.5475 47.3807 82.164 47.5146 81.9127 47.8871V47.4742H80.9919V50.9927H81.9393V49.0645C81.9393 48.5347 82.2752 48.2686 82.6828 48.2686C83.1138 48.2686 83.3684 48.5476 83.3684 49.0581V50.9919H84.3141V48.7524C84.3133 47.9363 83.7438 47.3815 82.9455 47.3815"
        fill="#094792"
      />
      <path
        d="M86.8998 50.2024C86.605 50.2024 86.3721 50.0419 86.3721 49.6032V48.3178H87.645V47.4758H86.3721V46.4299H85.4264V47.4758H84.7481V48.3178H85.4264V49.679C85.4264 50.5911 85.8727 51.0838 86.7435 51.0838C87.2446 51.0838 87.5974 50.9241 87.9076 50.7153L87.5596 49.9726C87.3678 50.0895 87.1254 50.2024 86.9006 50.2024"
        fill="#094792"
      />
    </svg>
  );
};
