import React from 'react';

import {useGridItemStyles} from '../../';
import {DSL_BaseBox, DSL_BaseBoxProps} from '../Box/BaseBox';

export interface DSL_GridBreakpoints {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}

export interface DSL_GridItemProps
  extends DSL_BaseBoxProps,
    DSL_GridBreakpoints {
  span?: number;
}

export const DSL_GridItem = ({
  span,
  xs,
  sm,
  md,
  lg,
  xl,
  ...props
}: DSL_GridItemProps) => {
  const gridStyles = useGridItemStyles({span, xs, sm, md, lg, xl});
  return (
    <DSL_BaseBox data-name={'grid-item'} internal_sx={gridStyles} {...props} />
  );
};

export {
  //
  /** @deprecated use DSL_GridItemProps instead*/
  DSL_GridItemProps as GridItemProps, //
  /** @deprecated use DSL_GridItem instead*/
  DSL_GridItem as GridItem,
};
