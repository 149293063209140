import React from 'react';

import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemProps,
  ListItemText,
} from '@mui/material';
import {DSL_Body1} from 'components/DesignSystem/Library/Typography/Body1';
import {DSL_Body2} from 'components/DesignSystem/Library/Typography/Body2';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

export interface DSL_SidebarMenuItemProps
  extends Omit<ListItemProps, DSL_RestrictedVisualProps> {
  id: string;
  text: string;
  onClick: () => void;
  selected?: boolean;
  disabled?: boolean;
  isSmall?: boolean;
  children?: React.ReactNode;
  icon?: React.ReactNode;
}

export const DSL_SidebarMenuItem = ({
  id,
  text,
  icon,
  selected,
  disabled,
  isSmall,
  onClick,
  children,
  ...restProps
}: DSL_SidebarMenuItemProps) => {
  return (
    <ListItem key={id} disablePadding {...restProps}>
      <ListItemButton selected={selected} disabled={disabled} onClick={onClick}>
        <ListItemIcon color="action">{icon}</ListItemIcon>
        <ListItemText
          primary={
            isSmall ? (
              <DSL_Body2>{text}</DSL_Body2>
            ) : (
              <DSL_Body1>{text}</DSL_Body1>
            )
          }
        />
        {children}
      </ListItemButton>
    </ListItem>
  );
};

DSL_SidebarMenuItem.displayName = 'DSL_SidebarMenuItem';

export {
  //
  /** @deprecated use DSL_SidebarMenuItemProps instead*/
  DSL_SidebarMenuItemProps as SidebarMenuItemProps, //
  /** @deprecated use DSL_SidebarMenuItem instead*/
  DSL_SidebarMenuItem as SidebarMenuItem,
};
