import searchMachine, {SearchMachineConfig} from './searchMachine';

const machine = searchMachine({
  id: 'accountSearch',
  invoke: {},
} as unknown as SearchMachineConfig);

// export type BaseSearchMachine = StateMachine<
//   SearchStoreMachineContext,
//   SearchMachineStateSchema,
//   FormEventObject
// >;
export type BaseSearchMachine = typeof machine;

export default machine;
