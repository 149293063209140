import {useEffect} from 'react';

import {PubSubSubscriber, usePubSubContext} from './PubSubContext';

export function usePubSubSubscribe<TData = unknown>({
  event,
  callback,
}: PubSubSubscriber<TData>) {
  const pubSub = usePubSubContext<TData>();

  useEffect(() => {
    pubSub.subscribe(event, callback);
    return () => {
      pubSub.unsubscribe(event, callback);
    };
  }, [event, callback, pubSub]);
}
