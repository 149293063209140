import {useCurrentBreakpoint} from './useCurrentBreakpoint';

export const useBreakpoint = () => {
  const currentBreakpoint = useCurrentBreakpoint();
  return {
    isXl: ['xl', 'lg', 'md', 'sm', 'xs'].includes(currentBreakpoint),
    isLg: ['lg', 'md', 'sm', 'xs'].includes(currentBreakpoint),
    isMd: ['md', 'sm', 'xs'].includes(currentBreakpoint),
    isSm: ['sm', 'xs'].includes(currentBreakpoint),
    isXs: ['xs'].includes(currentBreakpoint),
  };
};
