import {AssignmentOutlined} from '@mui/icons-material';
import VehicleDefects from 'containers-compliance/VehicleDefects';
import VehicleSightings from 'containers-compliance/VehicleSightings';
export default {
  routes: [VehicleDefects, VehicleSightings],
  path: '/compliance',
  menuTitle: 'Compliance',
  menuDescription:
    'Compliance services and access to compliance information for your organisation and vehicle fleet',
  showChildRoutesInMenu: true,
  menuIcon: AssignmentOutlined,
};
