import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/partners/create',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "CreatePartnerAccount" */ './CreatePartnerAccount'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Add Road Manager account',
  menuIcon: LibraryAddCheckIcon,
  authorisation: {
    apiKey: null,
    policies: [{role: 'operationsAccountAdmin'}] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Road Manager accounts',
      to: '..',
    },
  ],
};
