import React, {ReactNode} from 'react';

import {
  TimelineConnector as MuiTimelineConnector,
  TimelineDot as MuiTimelineDot,
  TimelineSeparator as MuiTimelineSeparator,
} from '@mui/lab';
import {useTheme} from '@mui/material';

export interface DSL_TimelineSeparatorProps {
  icon: ReactNode;
}
export const DSL_TimelineSeparator = ({icon}: DSL_TimelineSeparatorProps) => {
  const theme = useTheme();
  return (
    <MuiTimelineSeparator>
      <MuiTimelineConnector
        sx={{
          maxHeight: theme.spacing(2),
          bgcolor: 'action.focus',
          width: '1px',
        }}
      />
      <MuiTimelineDot
        sx={{
          m: 0,
          bgcolor: 'action.hover',
          color: 'text.secondary',
        }}
      >
        {icon}
      </MuiTimelineDot>
      <MuiTimelineConnector sx={{bgcolor: 'action.focus', width: '1px'}} />
    </MuiTimelineSeparator>
  );
};

export {
  //
  /** @deprecated use DSL_TimelineSeparatorProps instead*/
  DSL_TimelineSeparatorProps as TimelineSeparatorProps, //
  /** @deprecated use DSL_TimelineSeparator instead*/
  DSL_TimelineSeparator as TimelineSeparator,
};
