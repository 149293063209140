import React from 'react';

import {SliderInput, SliderInputProps} from 'components/DesignSystem/Library';

import {DSP_GetGenericFieldProps} from '../utils/getGenericFieldProps';

import {useRangeSliderField} from './useRangeSliderField';

export interface DSP_RangeSliderFieldProps
  extends Omit<
      SliderInputProps,
      | 'defaultValue'
      | 'getAriaValueText'
      | 'valueLabelFormat'
      | 'marks'
      | 'min'
      | 'max'
      | 'value'
      | 'getAriaLabel'
    >,
    Omit<DSP_GetGenericFieldProps, 'state' | 'fieldRef' | 'label'> {
  onBlurEvent?: () => void;
  onChangeEvent?: () => void;
  minFieldRef: string;
  maxFieldRef: string;
}

export const DSP_RangeSliderField = (props: DSP_RangeSliderFieldProps) => {
  const derivedProps = useRangeSliderField(props);
  return <SliderInput {...derivedProps} />;
};

DSP_RangeSliderField.displayName = 'DSP_RangeSliderField';

export {
  //
  /** @deprecated use DSP_RangeSliderFieldProps instead*/
  DSP_RangeSliderFieldProps as RangeSliderFieldProps, //
  /** @deprecated use DSP_RangeSliderField instead*/
  DSP_RangeSliderField as RangeSliderField,
};
