import React from 'react';

import {
  DSL_EntityTitle,
  DSL_EntityTitleProps,
  usePopover,
} from 'components/DesignSystem/Library';

import {
  DSP_PartnerAccountEntityPopover,
  DSP_PartnerAccountEntityPopoverProps,
} from '../PartnerAccountEntityPopover';

export interface DSP_PartnerAccountEntityNameViewProps
  extends Omit<DSP_PartnerAccountEntityPopoverProps, 'open'>,
    ReturnType<typeof usePopover<HTMLElement>>,
    Pick<DSL_EntityTitleProps, 'skeletonWidth'> {}

export const DSP_PartnerAccountEntityNameView = ({
  isLoading,
  title,
  subTitle,
  anchorRef,
  skeletonWidth,
  openPopover,
  closePopover,
  isPopoverOpen,
  ...props
}: DSP_PartnerAccountEntityNameViewProps) => {
  return (
    <>
      <DSP_PartnerAccountEntityPopover
        anchorEl={anchorRef.current}
        onMouseEnter={openPopover}
        onMouseLeave={closePopover}
        isLoading={isLoading}
        open={isPopoverOpen}
        title={title}
        subTitle={subTitle}
        {...props}
      />
      <DSL_EntityTitle
        ref={anchorRef}
        isLoading={isLoading}
        onMouseEnter={openPopover}
        onMouseLeave={closePopover}
        skeletonWidth={skeletonWidth}
        color={'primary.main'}
      >
        {title}
      </DSL_EntityTitle>
    </>
  );
};

DSP_PartnerAccountEntityNameView.displayName =
  'DSP_PartnerAccountEntityNameView';
