import * as Types from '../../../../../../../typeDefs/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UsersSearchQueryVariables = Types.Exact<{
  qs: Types.Scalars['String']['input'];
  accountType?: Types.InputMaybe<Types.Scalars['String']['input']>;
  accountId?: Types.InputMaybe<Types.Scalars['Float']['input']>;
}>;


export type UsersSearchQuery = { __typename?: 'Query', usersSearch?: Array<{ __typename?: 'UserModelSearchPublic', id?: number | null, firstName?: string | null, name?: string | null, email?: string | null, fileList?: any | null, phoneNumbers?: Array<{ __typename?: 'Phone', phoneType?: string | null, phoneNumber?: string | null, workPhoneNumber?: string | null, country?: string | null } | null> | null } | null> | null };


export const UsersSearchDocument = gql`
    query usersSearch($qs: String!, $accountType: String, $accountId: Float) {
  usersSearch(qs: $qs, accountType: $accountType, accountId: $accountId) {
    id
    firstName
    name
    email
    phoneNumbers {
      phoneType
      phoneNumber
      workPhoneNumber
      country
    }
    fileList
  }
}
    `;

/**
 * __useUsersSearchQuery__
 *
 * To run a query within a React component, call `useUsersSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersSearchQuery({
 *   variables: {
 *      qs: // value for 'qs'
 *      accountType: // value for 'accountType'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useUsersSearchQuery(baseOptions: Apollo.QueryHookOptions<UsersSearchQuery, UsersSearchQueryVariables> & ({ variables: UsersSearchQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersSearchQuery, UsersSearchQueryVariables>(UsersSearchDocument, options);
      }
export function useUsersSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersSearchQuery, UsersSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersSearchQuery, UsersSearchQueryVariables>(UsersSearchDocument, options);
        }
export function useUsersSearchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UsersSearchQuery, UsersSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UsersSearchQuery, UsersSearchQueryVariables>(UsersSearchDocument, options);
        }
export type UsersSearchQueryHookResult = ReturnType<typeof useUsersSearchQuery>;
export type UsersSearchLazyQueryHookResult = ReturnType<typeof useUsersSearchLazyQuery>;
export type UsersSearchSuspenseQueryHookResult = ReturnType<typeof useUsersSearchSuspenseQuery>;
export type UsersSearchQueryResult = Apollo.QueryResult<UsersSearchQuery, UsersSearchQueryVariables>;