import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/accessInstruments/:accessInstrumentId/accessProduct/:id',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "AccessProductDetails" */ 'containers-access/AccessProduct/AccessProductDetails/AccessProductDetails'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Manage reference vehicle',
  menuIcon: LocalShippingOutlinedIcon,
  authorisation: {
    apiKey: 'AccessProductWithRelations.create',
    policies: [
      {
        role: 'operationsSpatialRead',
      },
      {role: 'partnerSpatialRead'},
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Access instruments',
      to: '../../..',
    },
    {
      content: 'Manage access instrument',
      to: '../..',
      state: {'Access instrument tabs': 'Reference vehicles'},
    },
  ],
};
