import {lazy} from 'react';
import HomeIcon from '@mui/icons-material/Home';
import {
  AuthorisationPolicy,
  BuiltInRoles,
} from '@regulatory-platform/common-utils';
import content from './content';

export default {
  content,
  path: '/',
  component: lazy(() => import(/* webpackChunkName: "Home" */ './Home')),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'Home',
  menuIcon: HomeIcon,
  authorisation: {
    apiKey: null,
    policies: [BuiltInRoles.AUTHENTICATED] as AuthorisationPolicy[],
  },
  sections: [],
};
