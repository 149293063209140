import WebAsset from '@mui/icons-material/WebAsset';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';
import {lazy} from 'react';

export default {
  path: '/accessVehicleCategorys/:id',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "ManageAccessVehicleCategory" */ './ManageAccessVehicleCategory'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Vehicle category',
  authorisation: {
    apiKey: 'AccessVehicleCategoryWithRelations.findById',
    policies: [
      {
        role: 'operationsSpatialRead',
      },
      {
        role: 'operationsAccessRead',
      },
    ] as AuthorisationPolicy[],
  },
  menuIcon: WebAsset,
  sections: [
    {
      content: 'Vehicle categorys',
      to: '..',
    },
  ],
};
