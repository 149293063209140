import Layers from '@mui/icons-material/Layers';
import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/levelCrossings',
  component: lazy(
    () => import(/* webpackChunkName: "Roads" */ './LevelCrossings'),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'Level crossings',
  menuDescription:
    'Tools to define the heavy vehicle access capability of rail level crossings',
  menuIcon: Layers,
  parent: true,
  authorisation: {
    apiKey: 'LevelCrossingWithRelations.findById',
    policies: [
      {
        role: 'partnerAccessRead',
      },
      {
        role: 'partnerSpatialRead',
      },
      {
        role: 'providerSpatialRead',
      },
      {
        role: 'operationsSpatialRead',
      },
    ] as AuthorisationPolicy[],
  },
  sections: [],
};
