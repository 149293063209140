import {useMemo} from 'react';

import {useDspSelectedSegmentsDrawerContext} from '../SelectedSegmentsDrawer.Provider';

export const useDspSelectedSegmentsSegmentList = () => {
  const {
    filteredRoads,
    selectedRoadId,
    hasSelectedRoadId,
    currentBreakpoint,
    activeSegmentId,
    selectSegment,
    getSegmentsByIds,
  } = useDspSelectedSegmentsDrawerContext();

  const segments = useMemo(() => {
    if (!hasSelectedRoadId) {
      return [];
    }

    const segmentIds =
      filteredRoads?.find(road => road.id === selectedRoadId)?.segmentIds ?? [];

    return getSegmentsByIds(segmentIds);
  }, [filteredRoads, getSegmentsByIds, hasSelectedRoadId, selectedRoadId]);

  const handleSegmentClick = (id: number) => {
    selectSegment(id);
  };

  return {
    segments,
    onClick: handleSegmentClick,
    currentBreakpoint,
    activeSegmentId,
  };
};
