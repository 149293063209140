import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/accessProducts/create',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "CreateAccessProduct" */ './CreateAccessProduct'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Add reference vehicle',
  menuIcon: LibraryAddCheckIcon,
  authorisation: {
    apiKey: 'AccessProductWithRelations.create',
    policies: [{role: 'operationsSpatialAdmin'}] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Reference vehicles',
      to: '..',
    },
  ],
};
