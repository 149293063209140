/* eslint-disable @typescript-eslint/explicit-function-return-type */
import * as R from 'ramda';
import isChangingField from 'utils/stores/isChangingField';
import isFieldTouched from 'utils/stores/isFieldTouched';
import isSavingToArchivedStatus from 'utils/stores/isSavingToArchivedStatus';
import isSubmitted from 'utils/stores/isSubmitted';
import isValid from 'utils/stores/isValid';
import {FormEventObject, FormMachineContext} from 'utils/stores/types';
import {MachineOptions} from 'xstate';

export type MachineGuards<T = FormMachineContext> = NonNullable<
  MachineOptions<T, FormEventObject>['guards']
>;

export default function getGuards<
  T extends FormMachineContext = FormMachineContext,
>(): MachineGuards<T> {
  return {
    isChangingField: (context, event): boolean => {
      return isChangingField(context, event);
    },
    fieldNotTouched: (context, event): boolean => {
      return !isFieldTouched(context, event);
    },
    isChangingFieldOrNotTouched: (context, event): boolean => {
      return isChangingField(context, event) || !isFieldTouched(context, event);
    },
    isValidStore: (context): boolean => {
      return isValid(context) && !isSavingToArchivedStatus(context);
    },
    isInvalidStore: (context): boolean => {
      return !isValid(context) && !isSavingToArchivedStatus(context);
    },
    isArchived: (context): boolean => {
      return isSavingToArchivedStatus(context);
    },
    isSubmitted: (context): boolean => {
      return isSubmitted(context);
    },
    isAuthorisedUpdateById: (context): boolean => {
      return context.authorisations?.updateById === true;
    },
    isSubmittable: (context): boolean => {
      return isValid(context) && context.authorisations?.updateById === true;
    },
    actorIsNotLoaded: (context, event): boolean => {
      return (
        R.isNil(context.actors[event.fieldRef]) ||
        !context.actors[event.fieldRef].state.matches('loaded')
      );
    },
    isUnauthorised: (context): boolean => {
      return !R.isNil(context.props.apiError);
    },
    isNotFound: (context): boolean => {
      return !R.isNil(context.props.apiError);
    },
  };
}
