import {useDrawerResponsiveFloatingPopper} from '../hooks/useDrawerResponsiveFloatingPopper';

export const useFilterPopper = () => {
  return useDrawerResponsiveFloatingPopper<HTMLButtonElement>({
    anchor: 'left',
    size: {
      xs: 'FULL',
      sm: 'FULL',
      md: 'MD',
      lg: 'LG',
      xl: 'LG',
    },
  });
};
