import React from 'react';

import {DSL_StarOutlinedIcon} from '../../../Icons';
import {DSL_BaseIconButton, DSL_BaseIconButtonProps} from '../BaseIconButton';

export type DSL_StarOutlinedIconButtonProps = Omit<
  DSL_BaseIconButtonProps,
  'color' | 'children'
>;

export const DSL_StarOutlinedIconButton = (
  props: DSL_StarOutlinedIconButtonProps,
): JSX.Element => {
  return (
    <DSL_BaseIconButton {...props}>
      <DSL_StarOutlinedIcon fontSize={props.size} />
    </DSL_BaseIconButton>
  );
};

DSL_StarOutlinedIconButton.displayName = 'DSL_StarOutlinedIconButton';

export {
  //
  /** @deprecated use DSL_StarOutlinedIconButtonProps instead*/
  DSL_StarOutlinedIconButtonProps as StarOutlinedIconButtonProps, //
  /** @deprecated use DSL_StarOutlinedIconButton instead*/
  DSL_StarOutlinedIconButton as StarOutlinedIconButton,
};
