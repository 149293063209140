import * as Types from '../../../typeDefs/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserModelControllerEncryptedReturnUrlMutationVariables = Types.Exact<{
  encryptedReturnUrlInput: Types.EncryptedReturnUrlInput;
}>;


export type UserModelControllerEncryptedReturnUrlMutation = { __typename?: 'Mutation', userModelControllerEncryptedReturnUrl?: string | null };


export const UserModelControllerEncryptedReturnUrlDocument = gql`
    mutation userModelControllerEncryptedReturnUrl($encryptedReturnUrlInput: EncryptedReturnUrlInput!) {
  userModelControllerEncryptedReturnUrl(
    encryptedReturnUrlInput: $encryptedReturnUrlInput
  )
}
    `;
export type UserModelControllerEncryptedReturnUrlMutationFn = Apollo.MutationFunction<UserModelControllerEncryptedReturnUrlMutation, UserModelControllerEncryptedReturnUrlMutationVariables>;

/**
 * __useUserModelControllerEncryptedReturnUrlMutation__
 *
 * To run a mutation, you first call `useUserModelControllerEncryptedReturnUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserModelControllerEncryptedReturnUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userModelControllerEncryptedReturnUrlMutation, { data, loading, error }] = useUserModelControllerEncryptedReturnUrlMutation({
 *   variables: {
 *      encryptedReturnUrlInput: // value for 'encryptedReturnUrlInput'
 *   },
 * });
 */
export function useUserModelControllerEncryptedReturnUrlMutation(baseOptions?: Apollo.MutationHookOptions<UserModelControllerEncryptedReturnUrlMutation, UserModelControllerEncryptedReturnUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserModelControllerEncryptedReturnUrlMutation, UserModelControllerEncryptedReturnUrlMutationVariables>(UserModelControllerEncryptedReturnUrlDocument, options);
      }
export type UserModelControllerEncryptedReturnUrlMutationHookResult = ReturnType<typeof useUserModelControllerEncryptedReturnUrlMutation>;
export type UserModelControllerEncryptedReturnUrlMutationResult = Apollo.MutationResult<UserModelControllerEncryptedReturnUrlMutation>;
export type UserModelControllerEncryptedReturnUrlMutationOptions = Apollo.BaseMutationOptions<UserModelControllerEncryptedReturnUrlMutation, UserModelControllerEncryptedReturnUrlMutationVariables>;