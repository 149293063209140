import React from 'react';

import {
  MenuList as MuiMenuList,
  MenuListProps as MuiMenuListProps,
} from '@mui/material';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

export type DSL_MenuListProps = Omit<
  MuiMenuListProps,
  DSL_RestrictedVisualProps
>;

export const DSL_MenuList = (props: DSL_MenuListProps) => {
  return <MuiMenuList {...props} />;
};

DSL_MenuList.displayName = 'DSL_MenuList';

export {
  //
  /** @deprecated use DSL_MenuListProps instead*/
  DSL_MenuListProps as MenuListProps, //
  /** @deprecated use DSL_MenuList instead*/
  DSL_MenuList as MenuList,
};
