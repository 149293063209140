import React, {ReactNode} from 'react';

import {IconButton} from '@mui/material';

import {useMenu} from '../../hooks/useMenu';
import {DSL_MoreHorizIcon} from '../../Icons';
import {DSL_Flex} from '../../Layout/Flex';
import {DSL_FlexColumn} from '../../Layout/FlexColumn';
import {DSL_FlexRow} from '../../Layout/FlexRow';
import {useOverflowManager} from '../../Layout/hooks/useOverflowManager';
import {DSL_OverflowManager} from '../../Layout/OverflowManager';
import {DSL_OverflowManagerProps} from '../../Layout/OverflowManager';
import {DSL_Menu} from '../../Menus';

export type DSL_FilterBarProps = {
  leftContent?: ReactNode;
  rightContent?: ReactNode[];
  leftFlex?: number;
  rightFlex?: number;
  rightContentMaxItems?: DSL_OverflowManagerProps['maxItems'];
};

export const DSL_FilterBar = ({
  rightContentMaxItems = 0,
  rightContent = [],
  leftContent,
  leftFlex,
  rightFlex,
}: DSL_FilterBarProps) => {
  const {handleCloseMenu, anchorEl, isOpen, handleOpenMenu} = useMenu();
  const {effectiveMaxItems} = useOverflowManager({
    children: rightContent,
    maxItems: rightContentMaxItems,
  });

  return (
    <DSL_FlexRow gap={2} data-name={'filter-bar'}>
      {leftContent && (
        <DSL_FlexColumn
          flex={effectiveMaxItems ? leftFlex : undefined}
          flexGrow={effectiveMaxItems ? undefined : leftFlex}
          justifyContent={'center'}
        >
          {leftContent}
        </DSL_FlexColumn>
      )}
      <DSL_OverflowManager
        maxItems={rightContentMaxItems}
        flex={effectiveMaxItems ? rightFlex : undefined}
        gap={2}
        justifyContent={'center'}
        alignItems={'center'}
        renderOverflowItem={(maxItems: number) => (
          <>
            <DSL_Flex alignItems={'center'}>
              <IconButton
                onClick={handleOpenMenu}
                aria-controls={isOpen ? 'simple-menu' : undefined}
                aria-expanded={isOpen || undefined}
                aria-haspopup="menu"
                id="filter-button"
              >
                <DSL_MoreHorizIcon />
              </IconButton>
            </DSL_Flex>
            {rightContent && (
              <DSL_Menu
                open={isOpen}
                anchorEl={anchorEl}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
                onClose={handleCloseMenu}
                MenuListProps={{
                  'aria-labelledby': 'filter-button',
                }}
              >
                <DSL_FlexColumn gap={2} p={2}>
                  {rightContent.slice(maxItems)}
                </DSL_FlexColumn>
              </DSL_Menu>
            )}
          </>
        )}
      >
        {rightContent.map((content, index) => (
          <DSL_FlexColumn key={index} flex={1}>
            {content}
          </DSL_FlexColumn>
        ))}
      </DSL_OverflowManager>
    </DSL_FlexRow>
  );
};

DSL_FilterBar.displayName = 'DSL_FilterBar';

export {
  //
  /** @deprecated use DSL_FilterBarProps instead*/
  DSL_FilterBarProps as FilterBarProps, //
  /** @deprecated use DSL_FilterBar instead*/
  DSL_FilterBar as FilterBar,
};
