import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';

export default {
  path: '/providers/:accountId/members/create',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "CreateProviderAccountMember" */ './CreateProviderAccountMember'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Add member',
  menuIcon: LocalShippingOutlinedIcon,
  authorisation: {
    apiKey: null,
    policies: [
      {role: 'operationsAccountAdmin'},
      {role: 'providerAccountAdmin'},
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Service Provider accounts',
      to: '../../..',
    },
    {
      content: 'Service Provider account',
      to: '../..',
      state: {'Manage account': 'Members'},
    },
  ],
};
