import {useSelector} from '@xstate/react';
import * as R from 'ramda';
import getStateMetaHead from 'utils/machines/utils/getStateMetaHead';
import {useFormService} from 'components/DesignSystem/Portal';

export function useSubmissionRedirect(pagePath?: string) {
  const service = useFormService();
  return useSelector(
    service,
    state => {
      const {navigateTo} = getStateMetaHead(state);
      const path = pagePath || state.context.props.recordId;
      return {
        shouldNavigate:
          !!state.matches('submitted') &&
          !R.isNil(navigateTo) &&
          navigateTo !== '',
        to: navigateTo + '/' + path,
      };
    },
    R.equals,
  );
}
