import React from 'react';

import {DSP_CreateOrCancelButtonStackView} from './CreateOrCancelButtonStack.View';
import {useCreateOrCancelButtonStack} from './useCreateOrCancelButtonStack';

export const DSP_CreateOrCancelButtonStack = () => {
  const viewProps = useCreateOrCancelButtonStack();
  return <DSP_CreateOrCancelButtonStackView {...viewProps} />;
};

DSP_CreateOrCancelButtonStack.displayName = 'DSP_CreateOrCancelButtonStack';

export {
  //
  /** @deprecated use DSP_CreateOrCancelButtonStack instead*/
  DSP_CreateOrCancelButtonStack as CreateOrCancelButtonStack,
};
