import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';
export default {
  path: '/permitStandardConditions',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "PermitStandardCondition" */ './PermitStandardCondition'
      ),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'Standard conditions',
  menuIcon: LibraryAddCheckIcon,
  authorisation: {
    apiKey: 'PermitStandardConditionWithRelations.findById',
    policies: [
      {
        role: 'operationsAccessRead',
      },
      {
        role: 'operationsPBSRead',
      },
    ] as AuthorisationPolicy[],
  },
  sections: [],
};
