import Layers from '@mui/icons-material/Layers';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/road-management',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "RoadManagement" */ './RoadManagementContainer'
      ),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'Road management',
  menuDescription:
    'Manage the authorities for granting heavy vehicle access consent',
  menuIcon: Layers,
  parent: true,
  authorisation: {
    apiKey: 'RoadManagerRoadSegmentsWithRelations.findById',
    policies: [
      {role: 'operationsSpatialRead'},
      {role: 'partnerSpatialRead'},
    ] as AuthorisationPolicy[],
  },
  category: 'Map',
  sections: [],
  nestedRoutes: [
    {
      path: '',
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "createProposal" */ './RoadManagementEditor'
          ),
      ),
    },
    {
      path: ':roadManagementProposalUUID',
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "proposalReview" */ './ProposalReviewDrawer'
          ),
      ),
    },
  ],
};
