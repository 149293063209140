import React, {useEffect, useMemo} from 'react';

import {CKEditor} from '@ckeditor/ckeditor5-react';
import {FormLabel, GlobalStyles, Theme} from '@mui/material';
import {useTheme} from '@mui/styles';
import ClassicEditor from '@regulatory-platform/ckeditor5-build-standard/build/ckeditor';
import {DSL_UseGridItemStyles} from 'components/DesignSystem/Library/Layout/hooks';
import * as R from 'ramda';

import {DSL_Box} from '../../../Layout/Box';
import {DSL_Flex} from '../../../Layout/Flex';
import {DSL_FlexColumn} from '../../../Layout/FlexColumn';
import {DSL_FormControl} from '../FormControl';
import {DSL_FormHelperText} from '../FormHelperText';
import {DSL_TextInputProps} from '../TextInput';

import generateCKEditorTheme from './generateCKEditorTheme';

export interface DSL_RichTextInputProps
  extends Omit<
      DSL_TextInputProps,
      'placeholder' | 'value' | 'onChange' | 'onBlur' | 'onFocus'
    >,
    DSL_UseGridItemStyles {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  readOnly?: boolean;
  maxLength?: number;
  enableFieldTags?: boolean;
  variant?: 'basic' | 'standard' | 'full';
  placeholder?: string;
}

export const DSL_RichTextInput = ({
  id = 'rich-text-input',
  value,
  onChange,
  onBlur,
  onFocus,
  disabled,
  readOnly,
  label,
  error,
  helperText,
  maxLength,
  placeholder,
  span,
  xs,
  sm,
  md,
  lg,
  xl,
  enableFieldTags = false,
  variant = 'basic',
}: DSL_RichTextInputProps) => {
  const theme: Theme = useTheme();

  const [state, setState] = React.useState<typeof ClassicEditor>(); // instance of editor

  const styles = useMemo(
    () => ({
      [':root']: {...generateCKEditorTheme(theme)},
      ['.ck.ck-icon, .ck.ck-icon *']: {
        fill: theme.palette.text.primary,
      },
      ['.ck-editor__editable_inline']: {
        minHeight: '192px',
        wordBreak: 'break-word' as const,
      },
      ['.ck-content .table']: {
        width: '100%',
        height: '100%',
      },
      ['.ck-content .table table']: {
        borderStyle: 'solid',
        borderColor: 'hsl(0, 0%, 70%)',
        borderWidth: '1px',
      },
      ['.ck-content .table table th']: {
        textAlign: 'left' as const,
        verticalAlign: 'middle',
        padding: '16px',
        background: 'rgba(6, 98, 157, 0.08)',
      },
      ['.ck-content .table table td']: {
        textAlign: 'left' as const,
        verticalAlign: 'middle',
        padding: '16px',
      },
    }),
    [theme],
  );

  const counterAwareHelperText = useMemo(() => {
    if (maxLength === undefined) {
      return helperText;
    }

    const valueLength = (value as string)?.length ?? '0';
    /*
    setting helper text with char percent instead of count.
    With rich text editor character count will not be the actual reflection of
    what user sees on the screen. Rich text editor will also consider html tags
    as part of character count.
    */
    const percent = ((valueLength / maxLength) * 100).toFixed(1);
    const charPercentText = `${percent} %`;

    return (
      <DSL_Flex component={'span'} gap={2}>
        <DSL_Box component={'span'} flex={1}>
          {helperText}
        </DSL_Box>
        <DSL_Box component={'span'}>{charPercentText}</DSL_Box>
      </DSL_Flex>
    );
  }, [helperText, maxLength, value]);

  useEffect(() => {
    if (!state) {
      return;
    }
    if (readOnly) {
      state?.enableReadOnlyMode(id);
      return;
    }
    state?.disableReadOnlyMode(id);
  }, [readOnly, state, id]);

  return (
    <DSL_FormControl
      error={error}
      span={span}
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
    >
      <GlobalStyles styles={styles} />
      <DSL_FlexColumn gap={1}>
        <FormLabel id={id}>{label}</FormLabel>
        <CKEditor
          id={id}
          disabled={disabled}
          config={{
            placeholder,
            ui: {
              poweredBy: {
                label: null,
                position: 'inside',
                side: 'right',
                verticalOffset: 2,
                horizontalOffset: 2,
              },
            },
            toolbar: {
              items:
                variant === 'basic'
                  ? ['bold', 'italic', '|', 'undo', 'redo']
                  : variant === 'standard'
                  ? [
                      'bold',
                      'italic',
                      '|',
                      'bulletedList',
                      'numberedList',
                      'outdent',
                      'indent',
                      '|',
                      'insertTable',
                      '|',
                      'undo',
                      'redo',
                      '|',
                      'removeFormat',
                    ]
                  : [
                      'bold',
                      'italic',
                      '|',
                      'bulletedList',
                      'numberedList',
                      'outdent',
                      'indent',
                      '|',
                      'alignment',
                      '|',
                      'insertTable',
                      '|',
                      'imageInsert',
                      '|',
                      'undo',
                      'redo',
                      '|',
                      'removeFormat',
                    ],
            },
            language: 'en-au',
          }}
          data={
            enableFieldTags
              ? R.defaultTo('', value).replace(/<>/g, '&lt;&gt;')
              : value
          }
          editor={ClassicEditor}
          onReady={(editor: typeof ClassicEditor) => {
            setState(editor);
          }}
          onChange={(_event, editor) => {
            const data = enableFieldTags
              ? editor.getData().replace(/&lt;&gt;/g, '<>')
              : editor.getData();
            const event = {
              currentTarget: {
                value: data,
              },
            } as React.ChangeEvent<HTMLInputElement>;
            onChange?.(event);
          }}
          onBlur={onBlur}
          onFocus={onFocus}
        />
      </DSL_FlexColumn>
      {counterAwareHelperText && (
        <DSL_FormHelperText>{counterAwareHelperText}</DSL_FormHelperText>
      )}
    </DSL_FormControl>
  );
};

DSL_RichTextInput.displayName = 'DSL_RichTextInput';

export {
  //
  /** @deprecated use DSL_RichTextInputProps instead*/
  DSL_RichTextInputProps as RichTextInputProps, //
  /** @deprecated use DSL_RichTextInput instead*/
  DSL_RichTextInput as RichTextInput,
};
