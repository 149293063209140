import {useBoolean} from 'usehooks-ts';

import {DSL_DismissibleGlobalAlertViewProps} from './DismissibleGlobalAlert.View';

export type UseDismissibleGlobalAlertProps = Omit<
  DSL_DismissibleGlobalAlertViewProps,
  'isOpen' | 'onClose'
>;

export function useDismissibleGlobalAlert(
  props: UseDismissibleGlobalAlertProps,
): DSL_DismissibleGlobalAlertViewProps {
  const {setFalse: onClose, value: isOpen} = useBoolean(true);

  return {
    ...props,
    isOpen,
    onClose,
  };
}
