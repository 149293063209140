import React from 'react';

import {DSL_ExpandMore} from '../../../Icons';
import {DSL_BaseIconButton, DSL_BaseIconButtonProps} from '../BaseIconButton';

export type DSL_ExpandMoreIconButtonProps = Omit<
  DSL_BaseIconButtonProps,
  'color' | 'children'
>;

export const DSL_ExpandMoreIconButton = (
  props: DSL_ExpandMoreIconButtonProps,
): JSX.Element => {
  return (
    <DSL_BaseIconButton aria-label="more" {...props}>
      <DSL_ExpandMore />
    </DSL_BaseIconButton>
  );
};

DSL_ExpandMoreIconButton.displayName = 'DSL_ExpandMoreIconButton';
