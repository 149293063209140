import React, {memo} from 'react';

import {
  DSL_CheckboxInput,
  DSL_TableCell,
  DSL_TableRow,
} from 'components/DesignSystem/Library';

import {
  DSP_FormProvider,
  DSP_FormProviderProps,
} from '../../../Forms/FormProvider';
import {
  CsvColumn,
  ImportFromCsvRowMachine,
  ImportFromCsvService,
} from '../machines/types';

import {ImportFromCsvColumn} from './ImportFromCsvColumn';
import {useImportFromCsvRow} from './useImportFromCsvRow';

export interface ImportFromCsvRowProps {
  csvService: ImportFromCsvService;
  rowMachine: ImportFromCsvRowMachine;
  columns: CsvColumn[];
}

export const ImportFromCsvRow = memo((props: ImportFromCsvRowProps) => {
  const {service, error, checked, toggleChecked, columns} =
    useImportFromCsvRow(props);

  return (
    <DSP_FormProvider
      service={service as unknown as DSP_FormProviderProps['service']}
    >
      <DSL_TableRow>
        <DSL_TableCell verticalAlign={'center'}>
          <DSL_CheckboxInput
            error={error}
            disabled={error}
            checked={checked}
            onChange={toggleChecked}
          />
        </DSL_TableCell>
        {columns.map(({width, ...column}, colIndex) => (
          <ImportFromCsvColumn
            key={colIndex}
            width={colIndex + 1 === columns.length ? 'fit-content' : width}
            {...column}
          />
        ))}
      </DSL_TableRow>
    </DSP_FormProvider>
  );
});

ImportFromCsvRow.displayName = 'ImportFromCsvRow';
