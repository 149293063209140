import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/manage/members/create',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "CreateMemberSwitch" */ './CreateMemberSwitch'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Add member',
  menuIcon: LibraryAddCheckIcon,
  authorisation: {
    apiKey: null,
    policies: [
      {role: 'operationsAccountAdmin'},
      {role: 'providerAccountAdmin'},
      {role: 'customerAccountAdmin'},
      {role: 'partnerAccountAdmin'},
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Account',
      to: '../..',
      state: {'Manage account': 'Members'},
    },
  ],
};
