import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/accessInstruments/:accessInstrumentId/accessProductDocument/:accessProductDocumentId/createAccessProductChangeSet',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "CreateAccessProductChangeSet" */ 'containers-access/AccessProductChangeSet/CreateAccessProductChangeSet/CreateAccessProductChangeSet'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Create network update request',
  menuIcon: LibraryAddCheckIcon,
  authorisation: {
    apiKey: 'AccessProductChangeSetWithRelations.create',
    policies: [
      {role: 'operationsSpatialSupervisor'},
      {role: 'partnerSpatialSupervisor'},
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Access instruments',
      to: '../../../..',
    },
    {
      content: 'Manage access instrument',
      to: '../../..',
      state: {'Access instrument tabs': 'Vehicle networks'},
    },
    {
      content: 'Manage vehicle network',
      to: '..',
    },
  ],
};
