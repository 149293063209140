import SearchOutlined from '@mui/icons-material/SearchOutlined';
import {
  BuiltInRoles,
  AuthorisationPolicy,
} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/avmSearch',
  component: lazy(
    () => import(/* webpackChunkName: "AvmSearch" */ './AvmSearch'),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'NHVAS AVM Search',
  menuDescription:
    'Check the registration details, accreditation modules information of the heavy vehicles.',
  menuIcon: SearchOutlined,
  authorisation: {
    apiKey: null,
    policies: [BuiltInRoles.EVERYONE] as AuthorisationPolicy[],
  },
  sections: [],
};
