import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';
export default {
  path: '/accessProductApplications',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "AccessProductApplications" */ './AccessProductApplications'
      ),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'Consultation cases',
  menuIcon: LibraryAddCheckIcon,
  authorisation: {
    apiKey: 'AccessProductApplicationWithRelations.findById',
    policies: [
      {role: 'operationsSpatialRead'},
      {role: '$impersonator'},
    ] as AuthorisationPolicy[],
  },
  sections: [],
};
