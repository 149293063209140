import {
  AuthorisationSchema,
  AuthorizationDecision,
  CommonInvocationContext,
} from '@regulatory-platform/common-utils';
import * as R from 'ramda';
import {FormMachineContext, FormRecord} from 'utils/stores/types';

export default function isAuthorised(
  actionVoter: (
    context: CommonInvocationContext,
    schema: AuthorisationSchema,
  ) => (action: string) => AuthorizationDecision,
  methodName: string,
  context: FormMachineContext,
  nextRecord?: FormRecord,
): boolean {
  const {schema, record, authorisations} = context;
  if (R.isNil(authorisations) || R.isNil(authorisations.userProfile)) {
    return false;
  }

  const authoriserContext = {
    userProfile: authorisations.userProfile,
    record: R.defaultTo(record, nextRecord),
    origRecord: record, //use current record as original because we're on the client and serverside distinction not possible - unless nextRecord provided
    schema,
    isClient: true,
    methodName,
  } as CommonInvocationContext;

  return schema['x-auth']
    ? actionVoter(authoriserContext, schema['x-auth'])(methodName) ===
        AuthorizationDecision.ALLOW
    : false;
}
