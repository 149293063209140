import React from 'react';

import {DSL_LanguageIcon} from '../../Icons';
import {DSL_BaseIconInfo, DSL_BaseIconInfoProps} from '../BaseIconInfo';

export type DSL_LanguageIconInfoProps = Omit<DSL_BaseIconInfoProps, 'icon'>;

export const DSL_LanguageIconInfo = (props: DSL_LanguageIconInfoProps) => {
  return <DSL_BaseIconInfo icon={<DSL_LanguageIcon />} {...props} />;
};

DSL_LanguageIconInfo.displayName = 'DSL_LanguageIconInfo';

export {
  //
  /** @deprecated use DSL_LanguageIconInfoProps instead*/
  DSL_LanguageIconInfoProps as LanguageIconInfoProps, //
  /** @deprecated use DSL_LanguageIconInfo instead*/
  DSL_LanguageIconInfo as LanguageIconInfo,
};
