import {useCurrentBreakpoint} from 'components/DesignSystem/Library/hooks/useCurrentBreakpoint';

import {useDrawerContext} from '../../../Drawers/DrawerProvider';
import {useCascadingBreakpointValue} from '../../hooks/useCascadingBreakpointValue';

import {
  DSL_UseFloatingPopperProps,
  useFloatingPopper,
} from './useFloatingPopper';

export type DSL_UseDrawerAwareFloatingPopperProps = Omit<
  DSL_UseFloatingPopperProps,
  'offsetX' | 'offsetY'
>;

export function useDrawerResponsiveFloatingPopper<RefType extends HTMLElement>(
  props?: DSL_UseDrawerAwareFloatingPopperProps,
) {
  const {drawerDimensions, offsetTop} = useDrawerContext();

  const anchorForBp = useCascadingBreakpointValue(props?.anchor) ?? 'left';
  const currentBreakpoint = useCurrentBreakpoint();

  const offsetX = ['xs', 'sm'].includes(currentBreakpoint)
    ? 0
    : drawerDimensions.nonModal[anchorForBp].width;

  const offsetY = offsetTop;

  return useFloatingPopper<RefType>({
    ...props,
    offsetY,
    offsetX,
    anchor: anchorForBp,
  });
}
