import React from 'react';

import {
  TimelineContentProps as TimelineContentMuiProps,
  TimelineContent as TimelineContentMui,
} from '@mui/lab';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

export type DSL_TimelineContentProps = Omit<
  TimelineContentMuiProps,
  DSL_RestrictedVisualProps
>;

export const DSL_TimelineContent = (props: DSL_TimelineContentProps) => {
  return <TimelineContentMui sx={{px: 2, py: 2}} {...props} />;
};

DSL_TimelineContent.displayName = 'DSL_TimelineContent';

export {
  //
  /** @deprecated use DSL_TimelineContentProps instead*/
  DSL_TimelineContentProps as TimelineContentProps, //
  /** @deprecated use DSL_TimelineContent instead*/
  DSL_TimelineContent as TimelineContent,
};
