import React, {ForwardedRef, useMemo} from 'react';

export interface DSL_BaseDateTimeRendererProps {
  date: Date;
  formatter: (date: Date) => string;
  onClick?: (date: Date) => void;
}

export const DSL_BaseDateTimeRenderer = React.forwardRef(
  (
    {date, formatter, onClick, ...props}: DSL_BaseDateTimeRendererProps,
    ref: ForwardedRef<HTMLSpanElement>,
  ) => {
    const renderedDate = useMemo(() => formatter(date), [date, formatter]);
    return (
      <span
        onClick={event => {
          event.stopPropagation();
          onClick?.(date);
        }}
        style={{cursor: onClick ? 'pointer' : undefined}}
        ref={ref}
        {...props}
      >
        {renderedDate}
      </span>
    );
  },
);

DSL_BaseDateTimeRenderer.displayName = 'DSL_BaseDateTimeRenderer';

export {
  //
  /** @deprecated use DSL_BaseDateTimeRendererProps instead*/
  DSL_BaseDateTimeRendererProps as BaseDateTimeRendererProps, //
  /** @deprecated use DSL_BaseDateTimeRenderer instead*/
  DSL_BaseDateTimeRenderer as BaseDateTimeRenderer,
};
