import React, {useCallback} from 'react';

import {format} from 'date-fns';

import {
  BaseDateTimeRenderer,
  BaseDateTimeRendererProps,
} from 'components/DesignSystem/Library';

export type DSP_DateTimeRendererProps = Omit<
  BaseDateTimeRendererProps,
  'formatter'
>;

export const DSP_DateTimeRenderer = ({
  date,
  ...props
}: DSP_DateTimeRendererProps) => {
  const formatter = useCallback(
    () => format(date, 'dd MMM yyyy hh:mm:ss a'),
    [date],
  );

  return <BaseDateTimeRenderer date={date} formatter={formatter} {...props} />;
};

DSP_DateTimeRenderer.displayName = 'DSP_DateTimeRenderer';

export {
  //
  /** @deprecated use DSP_DateTimeRendererProps instead*/
  DSP_DateTimeRendererProps as DateTimeRendererProps, //
  /** @deprecated use DSP_DateTimeRenderer instead*/
  DSP_DateTimeRenderer as DateTimeRenderer,
};
