import React, {ReactNode} from 'react';

import {DSL_Box, DSL_BoxProps} from '../../Layout/Box';
import {DSL_MarginProps} from '../../types';

import {useDrawerResponsiveContainer} from './useDrawerResponsiveContainer';

export interface DSL_DrawerResponsiveContainerProps
  extends Omit<DSL_BoxProps, DSL_MarginProps | 'sx'> {
  children?: ReactNode;
}

export const DSL_DrawerResponsiveContainer = ({
  children,
  ...props
}: DSL_DrawerResponsiveContainerProps) => {
  const containerStyles = useDrawerResponsiveContainer();

  return (
    <DSL_Box internal_sx={containerStyles} {...props}>
      {children}
    </DSL_Box>
  );
};

DSL_DrawerResponsiveContainer.displayName = 'DSL_DrawerResponsiveContainer';

export {
  //
  /** @deprecated use DSL_DrawerResponsiveContainerProps instead*/
  DSL_DrawerResponsiveContainerProps as DrawerResponsiveContainerProps, //
  /** @deprecated use DSL_DrawerResponsiveContainer instead*/
  DSL_DrawerResponsiveContainer as DrawerResponsiveContainer,
};
