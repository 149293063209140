import {usePopover} from 'components/DesignSystem/Library';

import {useCustomerAccountEntityLoaderContext} from '../CustomerAccountEntityLoaderProvider';
import {useCustomerAccountEntityMapper} from '../useCustomerAccountEntityMapper';

export function useCustomerAccountEntityName() {
  const popoverProps = usePopover<HTMLDivElement>();
  const {entity: customerAccount, isLoading} =
    useCustomerAccountEntityLoaderContext();
  const {src, ...userProps} = useCustomerAccountEntityMapper({
    customerAccount,
  });

  return {
    ...popoverProps,
    ...userProps,
    isLoading,
  };
}
