import React from 'react';

import {
  Divider as MuiDivider,
  DividerProps as MuiDividerProps,
} from '@mui/material';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

export type DSL_DividerProps = Omit<MuiDividerProps, DSL_RestrictedVisualProps>;

export const DSL_Divider = (props: DSL_DividerProps) => {
  return <MuiDivider {...props} />;
};

DSL_Divider.displayName = 'DSL_Divider';

export {
  //
  /** @deprecated use DSL_DividerProps instead*/
  DSL_DividerProps as DividerProps, //
  /** @deprecated use DSL_Divider instead*/
  DSL_Divider as Divider,
};
