import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/assets/:assetDetailId/roadDetail/:id',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "RoadDetail" */ 'containers-spatial/Roads/RoadDetail/RoadDetail'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Manage road detail',
  menuIcon: LibraryAddCheckIcon,
  authorisation: {
    apiKey: 'RoadDetailWithRelations.findById',
    policies: [
      {
        role: 'partnerSpatialRead',
      },
      {
        role: 'providerSpatialRead',
      },
      {
        role: 'operationsSpatialRead',
      },
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Structure capability',
      to: '../../..',
    },
    {
      content: 'Manage structure',
      to: '../..',
      state: {'Manage structure': 'Road Details'},
    },
  ],
};
