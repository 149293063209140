import {lazy} from 'react';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/specifications/create',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "VehicleSpecificationGateway" */ './VehicleSpecificationGateway'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Create new vehicle specification',
  menuIcon: LocalShippingOutlinedIcon,
  authorisation: {
    apiKey: 'VehicleRegistrationExtendedWithRelations.create',
    policies: [
      {
        role: 'providerRegistrationEnabled',
        accountSubType: 'Manufacturer',
      },
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Vehicle Specifications',
      to: '..',
    },
  ],
};
