import React from 'react';

import {DSP_PartnerAccountEntityAvatarWithNameView} from './PartnerAccountEntityAvatarWithName.View';
import {usePartnerAccountEntityAvatarWithName} from './usePartnerAccountEntityAvatarWithName';

export const DSP_PartnerAccountEntityAvatarWithName = () => {
  const viewProps = usePartnerAccountEntityAvatarWithName();

  return <DSP_PartnerAccountEntityAvatarWithNameView {...viewProps} />;
};

DSP_PartnerAccountEntityAvatarWithName.displayName =
  'DSP_PartnerAccountEntityAvatarWithName';

export {
  //
  /** @deprecated use DSP_PartnerAccountEntityAvatarWithName instead*/
  DSP_PartnerAccountEntityAvatarWithName as PartnerAccountEntityAvatarWithName,
};
