import React from 'react';

import {DSL_CoManaged} from '../../../Icons';
import {DSL_BaseIconButton, DSL_BaseIconButtonProps} from '../BaseIconButton';

export type DSL_CoManagedIconButtonProps = Omit<
  DSL_BaseIconButtonProps,
  'color' | 'children'
>;

export const DSL_CoManagedIconButton = ({
  size = 'large',
  ...props
}: DSL_CoManagedIconButtonProps): JSX.Element => {
  return (
    <DSL_BaseIconButton aria-label="close" size={size} {...props}>
      <DSL_CoManaged fontSize={size} />
    </DSL_BaseIconButton>
  );
};

DSL_CoManagedIconButton.displayName = 'DSL_CoManagedIconButton';

export {
  //
  /** @deprecated use DSL_CoManagedIconButtonProps instead*/
  DSL_CoManagedIconButtonProps as CoManagedIconButtonProps, //
  /** @deprecated use DSL_CoManagedIconButton instead*/
  DSL_CoManagedIconButton as CoManagedIconButton,
};
