import {useMemo} from 'react';

import {Theme} from '@mui/material';
import {useTheme} from '@mui/styles';
import * as R from 'ramda';
import {useWindowSize} from 'usehooks-ts';

import {
  DSL_BreakpointValues,
  useCascadingBreakpointValue,
} from '../../hooks/useCascadingBreakpointValue';
import {DSL_PopperProps} from '../Popper';
import {DSL_UsePopperProps, usePopper} from '../usePopper';

export type DSL_FloatingPopperSize =
  | 'SM'
  | 'MD'
  | 'LG'
  | 'XL'
  | 'DYNAMIC'
  | 'FULL';

export interface DSL_UseFloatingPopperProps extends DSL_UsePopperProps {
  size?: DSL_BreakpointValues<DSL_FloatingPopperSize>;
  padding?: DSL_BreakpointValues<number>;
  offsetX?: number;
  offsetY?: number;
  dynamicOffset?: number;
  dynamicLimit?: number;
}

export function useFloatingPopper<RefType extends HTMLElement>(
  props?: DSL_UseFloatingPopperProps,
): ReturnType<typeof usePopper<RefType>> {
  const {width: viewportWidth} = useWindowSize();

  const {
    padding = {xs: 0, md: 2},
    offsetX = 0,
    offsetY = 0,
    size = 'SM',
    anchor = 'left',
    isSingleton,
    dynamicOffset,
    dynamicLimit,
  } = props ?? {};

  const anchorForBp = useCascadingBreakpointValue(anchor) ?? 'left';
  const sizeForBp = useCascadingBreakpointValue(size) ?? 'SM';

  const {id, popperProps, openerProps, setIsOpen, toggleIsOpen} =
    usePopper<RefType>({
      isSingleton,
      anchor: anchorForBp,
    });

  const {spacing} = useTheme<Theme>();
  const {height: vh, width: vw} = useWindowSize();

  const effectivePadding = useCascadingBreakpointValue(padding) ?? 0;

  const values = useMemo(() => {
    const sizeWidthMap: {[Size in DSL_FloatingPopperSize]: number} = {
      SM: 350,
      MD: 393,
      LG: 473,
      XL: 750,
      DYNAMIC: R.clamp(
        0,
        dynamicLimit ?? viewportWidth,
        viewportWidth - (dynamicOffset ?? 0),
      ),
      FULL: viewportWidth,
    };
    const paddingPx = parseInt(spacing(effectivePadding), 10);

    const width = sizeWidthMap[sizeForBp];
    const height = vh - offsetY - paddingPx * 2;

    const offsets = {
      left: {x: paddingPx + offsetX, y: paddingPx + offsetY},
      right: {x: vw - width - paddingPx - offsetX, y: paddingPx + offsetY},
    };

    const modifiers = [
      {
        name: 'computeStyles',
        options: {
          adaptive: false,
          roundOffsets: () => offsets[anchorForBp],
        },
      },
    ];

    return {
      modifiers,
      offsets,
      width,
      height,
    };
  }, [
    anchorForBp,
    effectivePadding,
    offsetY,
    offsetX,
    sizeForBp,
    spacing,
    vh,
    vw,
    viewportWidth,
  ]);

  return {
    openerProps,
    setIsOpen,
    toggleIsOpen,
    id,
    popperProps: useMemo(
      () =>
        ({
          ...popperProps,
          disableClickAway: isSingleton,
          maxHeight: {xs: undefined},
          maxWidth: {xs: undefined},
          modifiers: values.modifiers,
          height: values.height,
          width: values.width,
        } as DSL_PopperProps),
      [isSingleton, popperProps, values.height, values.modifiers, values.width],
    ),
  };
}
