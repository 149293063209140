import React from 'react';

import {DSL_BaseEmptyState, DSL_BaseEmptyStateProps} from '../BaseEmptyState';

import {DSL_WarningEmptyStateIcon} from './WarningEmptyStateIcon';

export type DSL_WarningEmptyStateProps = Omit<DSL_BaseEmptyStateProps, 'icon'>;

export const DSL_WarningEmptyState = (props: DSL_WarningEmptyStateProps) => {
  return <DSL_BaseEmptyState icon={<DSL_WarningEmptyStateIcon />} {...props} />;
};

DSL_WarningEmptyState.displayName = 'DSL_WarningEmptyState';
