import React from 'react';

import {DSL_IconWrapper} from '../IconWrapper';
import {DSL_IconProps} from '../types';

const DSL_Maintenance = (props: DSL_IconProps) => {
  return (
    <DSL_IconWrapper
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          fill="none"
          viewBox="0 0 24 24"
          {...props}
        >
          <path
            fill="#000"
            fillOpacity={0.54}
            fillRule="evenodd"
            d="M2 8a2 2 0 0 1 2-2v2h-.286v6H13V8h-1V6h2a2 2 0 0 1 2 2h1.281a1 1 0 0 1 .904.572l1.041 2.197H21a1 1 0 0 1 1 1V15a1 1 0 0 1-1 1h-2.05q.05.243.05.5a2.5 2.5 0 1 1-4.95-.5h-5.1q.05.243.05.5a2.5 2.5 0 1 1-4.95-.5H2zm4.5 8a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1m10 0a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1m4-2v-2h-2.315L17 9.5h-2V14z"
            clipRule="evenodd"
          />
          <path
            fill="#000"
            fillOpacity={0.54}
            d="M9.272 9.247a1.69 1.69 0 0 0-.394-1.757 1.68 1.68 0 0 0-1.99-.287l1.14 1.141-.684.685-1.14-1.141a1.68 1.68 0 0 0 .286 1.99 1.69 1.69 0 0 0 1.757.394l1.656 1.655a.24.24 0 0 0 .345 0l.68-.68a.24.24 0 0 0 0-.344z"
          />
        </svg>
      }
      {...props}
    />
  );
};

export default React.memo(DSL_Maintenance);
