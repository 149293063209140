import {usePopover} from 'components/DesignSystem/Library';

import {useUserEntityLoaderContext} from '../UserEntityLoaderProvider';
import {
  useUserEntityMapper,
  DSP_UseUserEntityMapperProps,
} from '../useUserEntityMapper';

export type DSP_UseUserEntityNameProps = Pick<
  DSP_UseUserEntityMapperProps,
  'nameVariant'
>;

export function useUserEntityName(props?: DSP_UseUserEntityNameProps) {
  const popoverProps = usePopover<HTMLDivElement>();
  const {entity: user, isLoading} = useUserEntityLoaderContext();
  const {src, ...userProps} = useUserEntityMapper({
    user,
    nameVariant: props?.nameVariant,
  });

  return {
    ...popoverProps,
    ...userProps,
    isLoading,
  };
}
