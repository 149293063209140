import React, {ReactNode} from 'react';

import {DSP_BaseWorkflowActivityNotificationCardView} from './BaseWorkflowActivityNotificationCard.View';
import {
  useBaseWorkflowActivityNotificationCard,
  DSP_UseBaseWorkflowActivityNotificationCardProps,
} from './useBaseWorkflowActivityNotificationCard';

export interface DSP_BaseWorkflowActivityNotificationCardProps
  extends DSP_UseBaseWorkflowActivityNotificationCardProps {
  avatar: ReactNode | undefined;
}

export const DSP_BaseWorkflowActivityNotificationCard = ({
  avatar,
  ...props
}: DSP_BaseWorkflowActivityNotificationCardProps) => {
  const viewProps = useBaseWorkflowActivityNotificationCard(props);

  return (
    <DSP_BaseWorkflowActivityNotificationCardView
      avatar={avatar}
      {...viewProps}
    />
  );
};

DSP_BaseWorkflowActivityNotificationCard.displayName =
  'DSP_BaseWorkflowActivityNotificationCard';

export {
  //
  /** @deprecated use DSP_BaseWorkflowActivityNotificationCardProps instead*/
  DSP_BaseWorkflowActivityNotificationCardProps as BaseWorkflowActivityNotificationCardProps, //
  /** @deprecated use DSP_BaseWorkflowActivityNotificationCard instead*/
  DSP_BaseWorkflowActivityNotificationCard as BaseWorkflowActivityNotificationCard,
};
