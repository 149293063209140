import React, {useContext} from 'react';

import {FormMachineService} from 'utils/stores/types';

export interface DSP_FormContextProps<
  T extends FormMachineService = FormMachineService,
  P extends FormMachineService = FormMachineService,
> {
  service: T;
  parentService?: P;
  otherProps?: {[key: string]: unknown};
  isValidForm: boolean;
  hasChangesToSave: boolean;
  readOnly: boolean;
  recordId?: string | number | null;
  canSave?: boolean;
  isCreate?: boolean;
  submitConfirmMessage?: string;
}
export const DSP_FormContext = React.createContext<DSP_FormContextProps>(
  {} as DSP_FormContextProps,
);

export function useFormContext<
  T extends FormMachineService = FormMachineService,
>(): DSP_FormContextProps<T> {
  return useContext(DSP_FormContext) as unknown as DSP_FormContextProps<T>;
}

export {
  //
  /** @deprecated use DSP_FormContextProps instead*/
  DSP_FormContextProps as FormContextProps, //
};
