import React from 'react';

import {ExpandLess} from '@mui/icons-material';
import {ExpandMore} from '@mui/icons-material';
import {Collapse, List, ListItemProps} from '@mui/material';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

import {DSL_SidebarMenuItem} from '../SidebarMenuItem';

export interface DSL_SidebarMenuDropdownProps
  extends Omit<ListItemProps, DSL_RestrictedVisualProps> {
  icon: React.ReactNode;
  id: string;
  text: string;
  isOpen: boolean;
  onClick: () => void;
  children: React.ReactNode;
}

export const DSL_SidebarMenuDropdown = ({
  children,
  id,
  isOpen,
  onClick,
  ...restProps
}: DSL_SidebarMenuDropdownProps) => {
  const DSL_MenuIcon = isOpen ? ExpandLess : ExpandMore;
  return (
    <React.Fragment key={id}>
      <DSL_SidebarMenuItem onClick={onClick} {...restProps} id={id}>
        <DSL_MenuIcon color="action" fontSize={'small'} />
      </DSL_SidebarMenuItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </React.Fragment>
  );
};

DSL_SidebarMenuDropdown.displayName = 'DSL_SidebarMenuDropdown';

export {
  //
  /** @deprecated use DSL_SidebarMenuDropdownProps instead*/
  DSL_SidebarMenuDropdownProps as SidebarMenuDropdownProps, //
  /** @deprecated use DSL_SidebarMenuDropdown instead*/
  DSL_SidebarMenuDropdown as SidebarMenuDropdown,
};
