import {useDrawerOffsetObserver} from 'components/DesignSystem/Library';
import React, {FC} from 'react';
import {AppBar, AppBarProps} from './AppBar.Connector';

export const AppBarWithDrawerOffsetObserver: FC<AppBarProps> = (
  props: AppBarProps,
): JSX.Element => {
  const {offsettingContainerRef} = useDrawerOffsetObserver();

  return <AppBar ref={offsettingContainerRef} {...props} />;
};
