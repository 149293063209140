import {lazy} from 'react';
import HomeIcon from '@mui/icons-material/Home';
import {
  AuthorisationPolicy,
  BuiltInRoles,
} from '@regulatory-platform/common-utils';

export default {
  path: '/reset-password',
  component: lazy(
    () => import(/* webpackChunkName: "ResetPassword" */ './ResetPassword'),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Reset Password',
  menuIcon: HomeIcon,
  authorisation: {
    apiKey: null,
    policies: [BuiltInRoles.EVERYONE] as AuthorisationPolicy[],
  },
  sections: [],
};
