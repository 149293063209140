import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/accessInstruments/create',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "CreateAccessInstrument" */ './CreateAccessInstrument'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Add Access Instrument',
  menuIcon: LibraryAddCheckIcon,
  authorisation: {
    apiKey: 'AccessInstrumentWithRelations.create',
    policies: [{role: 'operationsSpatialAdmin'}] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Access Instruments',
      to: '..',
    },
  ],
};
