import React, {ReactNode} from 'react';

import {DSL_Heading6} from 'components/DesignSystem/Library/Typography/Heading6';

export type DSL_FilterBarTitleProps = {
  children: ReactNode;
};

export const DSL_FilterBarTitle = ({children}: DSL_FilterBarTitleProps) => (
  <DSL_Heading6>{children}</DSL_Heading6>
);

DSL_FilterBarTitle.displayName = 'DSL_FilterBarTitle';

export {
  //
  /** @deprecated use DSL_FilterBarTitleProps instead*/
  DSL_FilterBarTitleProps as FilterBarTitleProps, //
  /** @deprecated use DSL_FilterBarTitle instead*/
  DSL_FilterBarTitle as FilterBarTitle,
};
