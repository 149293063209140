import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/providers/:id',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "ManageProviderAccount" */ './ManageProviderAccount'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Manage Service Provider account',
  menuIcon: LocalShippingOutlinedIcon,
  authorisation: {
    apiKey: null,
    policies: [
      {role: 'operationsAccountRead'},
      {role: 'providerAccountRead'},
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Service Provider accounts',
      to: '..',
    },
  ],
};
