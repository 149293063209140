import {Observable} from 'rxjs';
import {FormEventObject} from 'utils/stores/types';
import {createMachine, InterpreterFrom, MachineConfig, State} from 'xstate';
import machineDefinition from './appMachine.json';

export type AppMachineContext = {
  mode: string;
  type: string;
  pathname: string;
  hostname: string;
};
export interface AppMachineStateSchema {
  states: {
    normal: {};
    thirdPartyVerification: {};
    iframe: {};
    map: {};
    beta: {};
    finished: {};
  };
}
export type AppState = State<
  AppMachineContext,
  FormEventObject,
  AppMachineStateSchema
>;
export type AppObservable = Observable<AppState>;

export type AppConfig = MachineConfig<
  AppMachineContext,
  AppMachineStateSchema,
  FormEventObject
>;

const machine = createMachine<AppMachineContext, FormEventObject>(
  machineDefinition as AppConfig,
);
export type AppService = InterpreterFrom<typeof machine>;

export default machine;
