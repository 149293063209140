import React from 'react';

import {AlertTitle, AlertTitleProps} from '@mui/material';

import {DSL_RestrictedVisualProps} from '../../types';

export type DSL_AlertTitleProps = Omit<
  AlertTitleProps,
  DSL_RestrictedVisualProps
>;

export const DSL_AlertTitle = (props: DSL_AlertTitleProps) => (
  <AlertTitle {...props} />
);

DSL_AlertTitle.displayName = 'DSL_AlertTitle';
