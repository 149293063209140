import {usePopover} from 'components/DesignSystem/Library';

import {useRegulatorAccountEntityLoaderContext} from '../RegulatorAccountEntityLoaderProvider';
import {useRegulatorAccountEntityMapper} from '../useRegulatorAccountEntityMapper';

import {DSP_RegulatorAccountEntityAvatarViewProps} from './RegulatorAccountEntityAvatar.View';

export function useRegulatorAccountEntityAvatar(): DSP_RegulatorAccountEntityAvatarViewProps {
  const popoverProps = usePopover<HTMLDivElement>();
  const {entity: regulatorAccount, isLoading} =
    useRegulatorAccountEntityLoaderContext();
  const regulatorAccountProps = useRegulatorAccountEntityMapper({
    regulatorAccount,
  });

  return {
    ...popoverProps,
    ...regulatorAccountProps,
    isLoading,
  };
}
