import React from 'react';

import {
  AccordionSummary as MuiAccordionSummary,
  AccordionSummaryProps as MuiAccordionSummaryProps,
} from '@mui/material';
import {DSL_ExpandMore} from 'components/DesignSystem/Library/Icons';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

export type DSL_AccordionSummaryProps = Omit<
  MuiAccordionSummaryProps,
  DSL_RestrictedVisualProps
>;

export const DSL_AccordionSummary = (props: DSL_AccordionSummaryProps) => {
  return <MuiAccordionSummary expandIcon={<DSL_ExpandMore />} {...props} />;
};

DSL_AccordionSummary.displayName = 'DSL_AccordionSummary';

export {
  //
  /** @deprecated use DSL_AccordionSummaryProps instead*/
  DSL_AccordionSummaryProps as AccordionSummaryProps, //
  /** @deprecated use DSL_AccordionSummary instead*/
  DSL_AccordionSummary as AccordionSummary,
};
