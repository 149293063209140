import React from 'react';

import {DSL_MenuIcon} from '../../../Icons';
import {DSL_BaseIconButton, DSL_BaseIconButtonProps} from '../BaseIconButton';

export type DSL_MenuIconButtonProps = Omit<
  DSL_BaseIconButtonProps,
  'color' | 'children'
>;

export const DSL_MenuIconButton = ({
  size = 'large',
  ...props
}: DSL_MenuIconButtonProps): JSX.Element => {
  return (
    <DSL_BaseIconButton aria-label={'menu'} size={size} {...props}>
      <DSL_MenuIcon fontSize={size} />
    </DSL_BaseIconButton>
  );
};

DSL_MenuIconButton.displayName = 'DSL_MenuIconButton';

export {
  //
  /** @deprecated use DSL_MenuIconButtonProps instead*/
  DSL_MenuIconButtonProps as MenuIconButtonProps, //
  /** @deprecated use DSL_MenuIconButton instead*/
  DSL_MenuIconButton as MenuIconButton,
};
