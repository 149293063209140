import {jsonPointerResolver} from '@regulatory-platform/common-utils';
import {FormMachineContext} from './types';

export default function isValid(
  context: FormMachineContext,
  schemaFieldRef = '#/',
): boolean {
  const schema = context.schema;
  return (
    jsonPointerResolver(schemaFieldRef + 'x-valid/valid')(schema) === true &&
    jsonPointerResolver(schemaFieldRef + 'x-valid/validChildren')(schema) ===
      true
  );
}
