import React from 'react';

import {DSL_RestrictedVisualProps} from '../../types';
import {DSL_BaseTypography, DSL_BaseTypographyProps} from '../BaseTypography';

export type DSL_Subtitle1Props = Omit<
  DSL_BaseTypographyProps,
  DSL_RestrictedVisualProps
>;

export const DSL_Subtitle1 = (props: DSL_Subtitle1Props) => (
  <DSL_BaseTypography color="text.secondary" {...props} variant={'subtitle1'} />
);

export {
  //
  /** @deprecated use DSL_Subtitle1Props instead*/
  DSL_Subtitle1Props as Subtitle1Props, //
  /** @deprecated use DSL_Subtitle1 instead*/
  DSL_Subtitle1 as Subtitle1,
};
