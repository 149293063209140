import * as Types from '../../../../typeDefs/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPbsVehicleUnitSetPbsApprovalVehicleMappingsQueryVariables = Types.Exact<{
  id: Types.Scalars['Float']['input'];
}>;


export type GetPbsVehicleUnitSetPbsApprovalVehicleMappingsQuery = { __typename?: 'Query', pbsVehicleUnitSetPbsApprovalVehicleMappings?: Array<{ __typename?: 'PbsApprovalVehicleMappingPublic', id?: number | null, pbsApprovalId?: number | null, pbsVehicleUnitSetId?: number | null, status?: string | null, unitPosition?: Array<number | null> | null, vehicleRegistrationExtendedId?: string | null, vehicleVin?: string | null } | null> | null };


export const GetPbsVehicleUnitSetPbsApprovalVehicleMappingsDocument = gql`
    query getPbsVehicleUnitSetPbsApprovalVehicleMappings($id: Float!) {
  pbsVehicleUnitSetPbsApprovalVehicleMappings(id: $id) {
    id
    pbsApprovalId
    pbsVehicleUnitSetId
    status
    unitPosition
    vehicleRegistrationExtendedId
    vehicleVin
  }
}
    `;

/**
 * __useGetPbsVehicleUnitSetPbsApprovalVehicleMappingsQuery__
 *
 * To run a query within a React component, call `useGetPbsVehicleUnitSetPbsApprovalVehicleMappingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPbsVehicleUnitSetPbsApprovalVehicleMappingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPbsVehicleUnitSetPbsApprovalVehicleMappingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPbsVehicleUnitSetPbsApprovalVehicleMappingsQuery(baseOptions: Apollo.QueryHookOptions<GetPbsVehicleUnitSetPbsApprovalVehicleMappingsQuery, GetPbsVehicleUnitSetPbsApprovalVehicleMappingsQueryVariables> & ({ variables: GetPbsVehicleUnitSetPbsApprovalVehicleMappingsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPbsVehicleUnitSetPbsApprovalVehicleMappingsQuery, GetPbsVehicleUnitSetPbsApprovalVehicleMappingsQueryVariables>(GetPbsVehicleUnitSetPbsApprovalVehicleMappingsDocument, options);
      }
export function useGetPbsVehicleUnitSetPbsApprovalVehicleMappingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPbsVehicleUnitSetPbsApprovalVehicleMappingsQuery, GetPbsVehicleUnitSetPbsApprovalVehicleMappingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPbsVehicleUnitSetPbsApprovalVehicleMappingsQuery, GetPbsVehicleUnitSetPbsApprovalVehicleMappingsQueryVariables>(GetPbsVehicleUnitSetPbsApprovalVehicleMappingsDocument, options);
        }
export function useGetPbsVehicleUnitSetPbsApprovalVehicleMappingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPbsVehicleUnitSetPbsApprovalVehicleMappingsQuery, GetPbsVehicleUnitSetPbsApprovalVehicleMappingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPbsVehicleUnitSetPbsApprovalVehicleMappingsQuery, GetPbsVehicleUnitSetPbsApprovalVehicleMappingsQueryVariables>(GetPbsVehicleUnitSetPbsApprovalVehicleMappingsDocument, options);
        }
export type GetPbsVehicleUnitSetPbsApprovalVehicleMappingsQueryHookResult = ReturnType<typeof useGetPbsVehicleUnitSetPbsApprovalVehicleMappingsQuery>;
export type GetPbsVehicleUnitSetPbsApprovalVehicleMappingsLazyQueryHookResult = ReturnType<typeof useGetPbsVehicleUnitSetPbsApprovalVehicleMappingsLazyQuery>;
export type GetPbsVehicleUnitSetPbsApprovalVehicleMappingsSuspenseQueryHookResult = ReturnType<typeof useGetPbsVehicleUnitSetPbsApprovalVehicleMappingsSuspenseQuery>;
export type GetPbsVehicleUnitSetPbsApprovalVehicleMappingsQueryResult = Apollo.QueryResult<GetPbsVehicleUnitSetPbsApprovalVehicleMappingsQuery, GetPbsVehicleUnitSetPbsApprovalVehicleMappingsQueryVariables>;