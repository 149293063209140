import React from 'react';

import {
  SelectInput,
  SelectInputProps,
  GridItem,
  UseGridItemStyles,
} from 'components/DesignSystem/Library';

import {DSP_FieldProps} from '../types';

import {useSingleSelectField} from './useSingleSelectField';

export interface DSP_SingleSelectFieldProps
  extends DSP_FieldProps,
    UseGridItemStyles,
    Omit<SelectInputProps, 'value' | 'multiple'> {
  recordsLoaderRef?: string;
}

export const DSP_SingleSelectField = ({
  span,
  ...props
}: DSP_SingleSelectFieldProps) => {
  const fieldProps = useSingleSelectField(props);
  return (
    <GridItem span={span}>
      <SelectInput {...fieldProps} />
    </GridItem>
  );
};

DSP_SingleSelectField.displayName = 'DSP_Template';

export {
  //
  /** @deprecated use DSP_SingleSelectFieldProps instead*/
  DSP_SingleSelectFieldProps as SingleSelectFieldProps, //
  /** @deprecated use DSP_SingleSelectField instead*/
  DSP_SingleSelectField as SingleSelectField,
};
