import React, {Fragment} from 'react';

import {
  List as VirtualizedList,
  AutoSizer,
  ListRowProps,
} from 'react-virtualized';

import {DSL_Divider} from 'components/DesignSystem/Library';

import {DSP_SelectedSegmentsRoadCard} from '../SelectedSegmentsRoadCard';

import {useDspSelectedSegmentsRoadList} from './useSelectedSegmentsRoadList';

interface DSP_SelectedSegmentsRoadListProps {
  onRemoveSegments?: (segmentIds: number[]) => void;
}

export const DSP_SelectedSegmentsRoadList = ({
  onRemoveSegments,
}: DSP_SelectedSegmentsRoadListProps) => {
  const {roads, ...props} = useDspSelectedSegmentsRoadList();

  const renderRow = ({index, key, style}: ListRowProps): React.ReactNode => {
    return (
      <div key={key} style={style}>
        <Fragment>
          <DSP_SelectedSegmentsRoadCard
            onRemoveSegments={onRemoveSegments}
            road={roads[index]}
            {...props}
          />
          <DSL_Divider />
        </Fragment>
      </div>
    );
  };

  return (
    <AutoSizer>
      {({width, height}) => (
        <VirtualizedList
          width={width}
          height={height}
          rowHeight={77}
          rowRenderer={renderRow}
          rowCount={roads.length}
          overscanRowCount={3}
        />
      )}
    </AutoSizer>
  );
};

DSP_SelectedSegmentsRoadList.displayName = 'DSP_SelectedSegmentsRoadList';
