import React from 'react';

import {
  Collapse as MuiCollapse,
  CollapseProps as MuiCollapseProps,
} from '@mui/material';

import {DSL_RestrictedVisualProps} from '../../types';

export type DSL_CollapseProps = Omit<
  MuiCollapseProps,
  DSL_RestrictedVisualProps
>;

export const DSL_Collapse = (props: DSL_CollapseProps) => {
  return <MuiCollapse {...props} />;
};

DSL_Collapse.displayName = 'DSL_Collapse';

export {
  //
  /** @deprecated use DSL_CollapseProps instead*/
  DSL_CollapseProps as CollapseProps, //
  /** @deprecated use DSL_Collapse instead*/
  DSL_Collapse as Collapse,
};
