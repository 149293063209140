import React from 'react';

import {DSL_SwapVertIcon} from '../../../Icons';
import {DSL_BaseIconButton, DSL_BaseIconButtonProps} from '../BaseIconButton';

export type DSL_SwapVertIconButtonProps = Omit<
  DSL_BaseIconButtonProps,
  'color' | 'children'
>;

export const DSL_SwapVertIconButton = (
  props: DSL_SwapVertIconButtonProps,
): JSX.Element => {
  return (
    <DSL_BaseIconButton aria-label="more" {...props}>
      <DSL_SwapVertIcon />
    </DSL_BaseIconButton>
  );
};

DSL_SwapVertIconButton.displayName = 'DSL_SwapVertIconButton';

export {
  //
  /** @deprecated use DSL_SwapVertIconButtonProps instead*/
  DSL_SwapVertIconButtonProps as SwapVertIconButtonProps, //
  /** @deprecated use DSL_SwapVertIconButton instead*/
  DSL_SwapVertIconButton as SwapVertIconButton,
};
