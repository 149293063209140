import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/approval-library/:approvalId/trailerSet/:id',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "VehicleUnitSetLayoutDetails" */ './VehicleUnitSetTrailerDetails'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Trailer set',
  menuIcon: LocalShippingOutlinedIcon,
  authorisation: {
    apiKey: 'PbsVehicleUnitSetTrailerWithRelations.findById',
    policies: [
      {
        role: 'providerPBSRead',
      },
      {
        role: 'operationsPBSRead',
      },
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'PBS Approval Library',
      to: '../../..',
    },
    {
      content: 'Approval',
      to: '../..',
      state: {'Approval detail': 'Trailer sets'},
    },
  ],
};
