import React from 'react';

import {accountTypes, UserProfile} from '@regulatory-platform/common-utils';
import getUserName from 'app/router/utils/getUserName';
import {UserModelSearchPublic} from 'typeDefs/types';

import {
  DSP_SearchRelationField,
  DSP_SearchRelationFieldProps,
} from '../SearchRelationField';

import {UsersSearchDocument} from './api/query/UserSearch.generated';
import {DSP_UserSearchRelationOption} from './UserSearchRelationOption';

export interface DSP_UserSearchRelationFieldProps
  extends Omit<DSP_SearchRelationFieldProps, 'query' | 'queryVariables'> {
  searchAll?: boolean;
  accountType?: (typeof accountTypes)[number];
  accountId?: number;
  hideUserAvatar?: boolean;
}

export const DSP_UserSearchRelationField = ({
  accountType,
  accountId,
  searchAll,
  hideUserAvatar = false,
  ...props
}: DSP_UserSearchRelationFieldProps) => {
  return (
    <DSP_SearchRelationField
      id="user-relation-search"
      query={UsersSearchDocument}
      queryVariables={searchAll ? {} : {accountType, accountId}}
      getOptionLabel={searchResult =>
        searchResult ? getUserName(searchResult as UserProfile) : ''
      }
      renderOption={(params, searchResult: UserModelSearchPublic) => {
        const workPhone = searchResult?.phoneNumbers?.find(
          ph => ph?.phoneType === 'Work',
        );
        return (
          searchResult.id && (
            <li {...params}>
              <DSP_UserSearchRelationOption
                name={getUserName(searchResult as UserProfile)}
                imageSrc={searchResult?.fileList?.userImageUrl}
                hideUserAvatar={hideUserAvatar}
                phone={workPhone?.phoneNumber}
                email={searchResult?.email}
              />
            </li>
          )
        );
      }}
      emptyRecord={{
        id: null,
        firstName: '',
        name: '',
        email: '',
        phoneNumbers: [],
      }}
      {...props}
    />
  );
};

export {
  //
  /** @deprecated use DSP_UserSearchRelationFieldProps instead*/
  DSP_UserSearchRelationFieldProps as UserSearchRelationFieldProps, //
  /** @deprecated use DSP_UserSearchRelationField instead*/
  DSP_UserSearchRelationField as UserSearchRelationField,
};
