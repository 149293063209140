import React from 'react';

import {Button as MuiButton} from '@mui/material';
import {DSL_CopyAllIcon} from 'components/DesignSystem/Library/Icons';
import {DSL_FlexRow} from 'components/DesignSystem/Library/Layout/FlexRow';

import {DSL_BaseInput} from '../BaseInput';

export type DSL_CopyInputProps = {
  link: string;
};

export const DSL_CopyInput = ({link}: DSL_CopyInputProps) => {
  const onClick = async () => {
    try {
      await navigator.clipboard.writeText(link);
      alert('The link has been copied to your clipboard');
    } catch (err) {
      throw new Error('Failed to copy: ');
    }
  };
  return (
    <DSL_FlexRow width={'100%'} justifyContent={'space-between'} gap={0}>
      <DSL_FlexRow flexGrow={1}>
        <DSL_BaseInput
          type={'text'}
          value={link}
          label={'Link'}
          internal_sx={{
            '& .MuiOutlinedInput-notchedOutline': {
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              borderRight: 'none',
            },
          }}
          disabled
        />
      </DSL_FlexRow>
      <DSL_FlexRow>
        <MuiButton
          variant={'contained'}
          onClick={onClick}
          sx={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0, boxShadow: 0}}
        >
          <DSL_CopyAllIcon />
        </MuiButton>
      </DSL_FlexRow>
    </DSL_FlexRow>
  );
};

DSL_CopyInput.displayName = 'DSL_CopyInput';
