import React from 'react';

import {DSL_BaseAlert, DSL_BaseAlertProps} from '../BaseAlert';

export type DSL_InlineErrorAlertProps = Omit<
  DSL_BaseAlertProps,
  'variant' | 'severity'
>;

export const DSL_InlineErrorAlert = (props: DSL_InlineErrorAlertProps) => {
  return <DSL_BaseAlert variant={'outlined'} severity="error" {...props} />;
};

DSL_InlineErrorAlert.displayName = 'DSL_InlineErrorAlert';

export {
  //
  /** @deprecated use DSL_InlineErrorAlertProps instead*/
  DSL_InlineErrorAlertProps as InlineErrorAlertProps, //
  /** @deprecated use DSL_InlineErrorAlert instead*/
  DSL_InlineErrorAlert as InlineErrorAlert,
};
