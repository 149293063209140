import React from 'react';

import {DSL_IconWrapper} from '../IconWrapper';
import {DSL_IconProps} from '../types';

const DSL_Application = (props: DSL_IconProps) => {
  return (
    <DSL_IconWrapper
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          fill="none"
          viewBox="0 0 24 24"
          {...props}
        >
          <path
            fill="#000"
            fillOpacity={0.54}
            d="M9 9a1 1 0 1 0-2 0 1 1 0 0 0 2 0M10 9a1 1 0 0 0 1 1h5a1 1 0 1 0 0-2h-5a1 1 0 0 0-1 1M11 13a1 1 0 1 1 0-2h5a1 1 0 1 1 0 2zM16 16h-5a1 1 0 1 1 0-2h5a1 1 0 1 1 0 2M8 11a1 1 0 1 1 0 2 1 1 0 0 1 0-2M8 14a1 1 0 1 1 0 2 1 1 0 0 1 0-2"
          />
          <path
            fill="#000"
            fillOpacity={0.54}
            fillRule="evenodd"
            d="M4 20c0 1.1.9 2 2 2h14V4a2 2 0 0 0-2-2H6c-1.1 0-2 .9-2 2zm14 0V4H6v16z"
            clipRule="evenodd"
          />
        </svg>
      }
      {...props}
    />
  );
};

export default React.memo(DSL_Application);
