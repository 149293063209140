import React, {ReactNode} from 'react';

import {
  DSL_FlexRow,
  DSL_FlexColumn,
  DSL_Subtitle1,
  DSL_Body2,
  DSL_DeleteIconButton,
  DSL_CardActionAreaExclusion,
} from 'components/DesignSystem/Library';

import {DSP_BaseVehicleCard} from '../BaseVehicleCard/BaseVehicleCard';

export type DSP_SavedVehicleNetworkCardProps = {
  title?: ReactNode;
  subheader?: ReactNode;
  vehicleComponentIds: number[];
  imageUrl?: string | null;
  onClick: () => void;
  onDeleteClick?: () => void;
};

export const DSP_SavedVehicleNetworkCard = ({
  vehicleComponentIds,
  imageUrl,
  title,
  subheader,
  onClick,
  onDeleteClick,
}: DSP_SavedVehicleNetworkCardProps) => {
  return (
    <DSP_BaseVehicleCard
      onClick={onClick}
      vehicleComponentIds={vehicleComponentIds}
      imageUrl={imageUrl}
    >
      <DSL_FlexRow
        justifyContent={'space-between'}
        alignItems={'flex-start'}
        flexWrap={'nowrap'}
      >
        <DSL_FlexColumn gap={0} flex={1}>
          <DSL_Subtitle1 color={'text.primary'} bold>
            {title}
          </DSL_Subtitle1>
          <DSL_Body2 color={'text.secondary'}>{subheader}</DSL_Body2>
        </DSL_FlexColumn>
        <DSL_FlexRow>
          <DSL_CardActionAreaExclusion>
            <DSL_DeleteIconButton onClick={onDeleteClick} />
          </DSL_CardActionAreaExclusion>
        </DSL_FlexRow>
      </DSL_FlexRow>
    </DSP_BaseVehicleCard>
  );
};

DSP_SavedVehicleNetworkCard.displayName = 'DSP_SavedVehicleNetworkCard';

export {
  //
  /** @deprecated use DSP_SavedVehicleNetworkCardProps instead*/
  DSP_SavedVehicleNetworkCardProps as SavedVehicleNetworkCardProps, //
  /** @deprecated use DSP_SavedVehicleNetworkCard instead*/
  DSP_SavedVehicleNetworkCard as SavedVehicleNetworkCard,
};
