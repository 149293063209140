import {useMemo} from 'react';

import {SegmentAccessType} from 'components/DesignSystem/Portal/Layout/Segments';
import {segmentAccessTitleMap} from 'components/DesignSystem/Portal/Layout/Segments/constants';

import {useDspSelectedSegmentsDrawerContext} from '../SelectedSegmentsDrawer.Provider';

export const useDspAccessTypeSelectOptions = () => {
  const {roads, selectedRoadId, hasSelectedRoadId, getSegmentsByIds} =
    useDspSelectedSegmentsDrawerContext();

  const validAccessTypesByRoads = useMemo(() => {
    if (!roads?.length) {
      return [];
    }
    const nextValidAccessTypesByRoads = new Set<SegmentAccessType>([]);

    const roadSegmentIds = roads.flatMap(road => road?.segmentIds ?? []);

    const segments = getSegmentsByIds(roadSegmentIds);

    for (const segment of segments) {
      nextValidAccessTypesByRoads.add(segment.accessType);
    }

    return Array.from(nextValidAccessTypesByRoads);
  }, [roads, getSegmentsByIds]);

  const validAccessTypesBySelectedRoadId = useMemo(() => {
    if (!hasSelectedRoadId) {
      return [];
    }
    const nextValidAccessTypesBySelectedRoadId = new Set<SegmentAccessType>([]);

    const roadSegmentIds =
      roads.find(road => road.id === selectedRoadId)?.segmentIds ?? [];

    const segments = getSegmentsByIds(roadSegmentIds);

    for (const segment of segments) {
      nextValidAccessTypesBySelectedRoadId.add(segment.accessType);
    }

    return Array.from(nextValidAccessTypesBySelectedRoadId);
  }, [hasSelectedRoadId, roads, getSegmentsByIds, selectedRoadId]);

  const options = useMemo(() => {
    const accessTypes = hasSelectedRoadId
      ? validAccessTypesBySelectedRoadId
      : validAccessTypesByRoads;

    const accessTypeOptions = accessTypes.map(accessType => ({
      label: segmentAccessTitleMap.CURRENT[accessType],
      value: accessType,
    }));
    return [{label: 'All', value: 'All'}, ...accessTypeOptions];
  }, [
    hasSelectedRoadId,
    validAccessTypesByRoads,
    validAccessTypesBySelectedRoadId,
  ]);

  return options;
};
