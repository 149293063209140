import {DSL_VerticalTabListProps} from './VerticalTabList';

export function useVerticalTabList(
  anchor: DSL_VerticalTabListProps['indicatorAnchor'],
) {
  if (anchor === 'right') {
    return {};
  }

  return {
    TabIndicatorProps: {
      sx: {
        left: 0,
      },
    },
  };
}
