import React from 'react';

import {DSP_UserEntityNameView} from '../UserEntityName.View';
import {useUserEntityName} from '../useUserEntityName';

export const DSP_UserEntityFullName = () => {
  const viewProps = useUserEntityName();

  return <DSP_UserEntityNameView {...viewProps} />;
};

DSP_UserEntityFullName.displayName = 'DSP_UserEntityFullName';

export {
  //
  /** @deprecated use DSP_UserEntityFullName instead*/
  DSP_UserEntityFullName as UserEntityFullName,
};
