import React from 'react';

import {DSL_TextButton, DSL_TextButtonProps} from '../TextButton';

export type DSL_CancelButtonProps = Omit<
  DSL_TextButtonProps,
  'variant' | 'children'
>;

export const DSL_CancelButton = (props: DSL_CancelButtonProps) => {
  return <DSL_TextButton {...props}>{props.title ?? 'Cancel'}</DSL_TextButton>;
};

DSL_CancelButton.displayName = 'DSL_CancelButton';
