import React from 'react';

import {useSelector} from '@xstate/react';
import {LocationStateTab} from 'components/navigation/Tabs/useTabs';
import {TabStep} from 'components/navigation/types';
import isValidStep from 'components/navigation/utils/isValidStep';
import * as R from 'ramda';
import {useLocation, useNavigate} from 'react-router';
import {FormMachineContext} from 'utils/stores/types';

import {useFormContext} from '../FormProvider';

import {DSP_ITabStep} from './FormTabs.View';

export const useFormTabs = (
  label: string,
  _tabSteps: TabStep[] | ((context: FormMachineContext) => TabStep[]),
  parentTabLabel: string,
): {
  tabSteps: DSP_ITabStep[];
  onChange: (event: React.ChangeEvent<{}>, newValue: string) => void;
  onStepChange: (label: string) => void;
  activeHasErrors: boolean;
  value: string;
} => {
  const location = useLocation();
  const navigate = useNavigate();
  const {service} = useFormContext();

  return useSelector(
    service,
    state => {
      let tabSteps = _tabSteps as TabStep[];
      if (R.is(Function, _tabSteps)) {
        tabSteps = (_tabSteps as (context: FormMachineContext) => TabStep[])(
          state.context,
        );
      }
      const selectedTab = R.defaultTo(
        tabSteps[0]?.label,
        (location.state as LocationStateTab)?.[label],
      );
      const activeStep = R.defaultTo(
        tabSteps[0],
        R.find(R.propEq('label', selectedTab), tabSteps),
      );

      const onStepChange = (nextTabLabel: string): void => {
        const nextStep = R.find(R.propEq('label', nextTabLabel), tabSteps);
        if (R.isNil(nextStep)) {
          return;
        }
        if (!R.isNil(service) && !R.isNil(activeStep.validateFields)) {
          const validateFields = R.defaultTo([], activeStep.validateFields);
          service.send({
            type: 'TAB_TOUCHED',
            fieldRef: activeStep?.label,
            fieldRefs: validateFields,
          });
        }
        navigate(location.pathname + R.defaultTo('', location.search), {
          state: R.assoc(label, nextTabLabel, location.state),
        });
        if (!R.isNil(service) && nextStep.onClickBlurField) {
          service.send({
            type: 'BLUR',
            fieldRef: nextStep.onClickBlurField as string,
          });
        }
        if (!R.isNil(service) && nextStep.onClickValidateField) {
          service.send({
            type: 'VALIDATE',
            fieldRef: nextStep.onClickValidateField as string,
          });
        }
      };

      const overrideTouched = state.context.props.overrideTouched;
      const tabTouched = R.defaultTo({}, state.context.props.tabTouched) as {
        [key: string]: boolean;
      };

      const isParentTabTouchedOrOverrideSet =
        tabTouched[parentTabLabel] || overrideTouched;

      const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        event.preventDefault();
        onStepChange(newValue);
      };

      return {
        onChange: handleChange,
        onStepChange,
        tabSteps: tabSteps.map(step => ({
          ...step,
          error:
            (isParentTabTouchedOrOverrideSet || tabTouched[step?.label]) &&
            !isValidStep(state.context, R.defaultTo([], step?.validateFields)),
        })),
        value: activeStep?.label,
        activeHasErrors:
          !!(
            isParentTabTouchedOrOverrideSet || tabTouched[activeStep?.label]
          ) &&
          !isValidStep(
            state.context,
            R.defaultTo([], activeStep?.validateFields),
          ),
      };
    },
    (prevFieldProps, nextFieldProps) => {
      const ignoreProps = R.omit(['onChange', 'onStepChange']);
      return R.equals(ignoreProps(prevFieldProps), ignoreProps(nextFieldProps));
    },
  );
};
