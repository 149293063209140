import {schemaJsonPointerResolver} from '@regulatory-platform/common-utils';
import {useSelector} from '@xstate/react';
import * as R from 'ramda';
import {ActorRef, Subscribable} from 'xstate';

const DSP_MISSING_FIELD = Symbol('MISSING_FIELD');
export function useFormFieldPropsSelector<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  DSP_TActor extends ActorRef<any, any>,
  DSP_TSelectorReturn,
  DSP_TEmitted = DSP_TActor extends Subscribable<infer DSP_Emitted>
    ? DSP_Emitted
    : never,
>(
  fieldRef: string,
  actor: DSP_TActor,
  selector: (emitted: DSP_TEmitted) => DSP_TSelectorReturn,
  omit: string[] = ['onChange', 'onBlur', 'onOpen'],
  compareCustom?: (
    oldValue: DSP_TSelectorReturn,
    newValue: DSP_TSelectorReturn,
  ) => boolean,
) {
  return useSelector(
    actor,
    state => {
      const {schema} = state.context;
      const fieldSchema = schemaJsonPointerResolver(fieldRef)(schema);
      if (R.isNil(fieldSchema)) {
        return DSP_MISSING_FIELD;
      }
      return selector(state);
    },
    (oldValue, newValue) => {
      // we do this because useSelector execution order is non-deterministic,
      // so you can have cases where the schema has been updated to remove a field, and that field's useSelector callback
      // will be called BEFORE the component is removed from the react tree.  In that case we want to return the old schema
      // so the component continues to render without blowing up
      if (newValue === DSP_MISSING_FIELD) {
        return true;
      }
      const ignoreProps = R.omit(omit);
      const isEqual = R.equals(ignoreProps(oldValue), ignoreProps(newValue));
      return compareCustom
        ? compareCustom(oldValue as DSP_TSelectorReturn, newValue) && isEqual
        : isEqual;
    },
  ) as DSP_TSelectorReturn;
}
