import {AccessVehicleExemptionSetPublic} from '../../typeDefs/types';

export enum VehicleNetworkMapSearchParams {
  NETWORK_IDS = 'networkIds',
  ROUTE_ID = 'routeId',
  NON_OSM_LAYER_IDS = 'nonOsmLayerIds',
  EXEMPTION_SET_ID = 'exemptionSetId',
  CATEGORY_CODE = 'category',
  PARENT_CATEGORY_CODE = 'parentCategory',
  TAB_ID = 'view',
  MAP_VIEW = 'mapView',
  IS_MINIMIZED = 'isMinimised',
  VIEW_BY = 'viewBy',
  SELECTED_SEGMENTS_DRAWER = 'selectedSegmentsDrawer',
  ACTIVE_SEGMENT = 'activeSegment',
  NETWORK_LAYER_CONTEXT = 'networkLayerContext',
  SUBSCRIBE = 'subscribe',
  APPLY_NETWORK_PERMIT = 'applyNetworkPermit',
  IS_SELECTED_SEGMENTS_DRAWER_MINIMIZED = 'isSelectedSegmentsDrawerMinimized',
}

export enum NetworkUpdateSubscriptionVerificationParams {
  IV = 'iv',
  SECRET = 'secret',
}
export const VehicleFiltersToPersist = [
  'states',
  VehicleNetworkMapSearchParams.VIEW_BY,
] as (keyof AccessVehicleExemptionSetPublic)[];

export const SubscribeToNetworkUrl =
  'https://www.vision6.com.au/em/forms/subscribe.php?db=382978&s=326808&a=40111&k=ycnH53mupzOdDH45pgbLqIw7s3n5jdFbuYtXg92KNG4';
