import {useEffect, useState} from 'react';

import {useDspSelectedSegmentsDrawerContext} from '../SelectedSegmentsDrawer.Provider';

export const useSelectedSegmentsSegmentCard = (segmentId: number) => {
  const [isHovering, setIsHovering] = useState(false);
  const {onSegmentHover, onSegmentHoverOut} =
    useDspSelectedSegmentsDrawerContext();

  useEffect(() => {
    if (isHovering) {
      onSegmentHover([segmentId]);
    } else {
      onSegmentHoverOut();
    }
  }, [isHovering, segmentId, onSegmentHover, onSegmentHoverOut]);

  return {
    isHovering,
    setIsHovering,
  };
};
