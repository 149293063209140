import React from 'react';

import {DSP_RegulatorAccountEntityNameView} from './RegulatorAccountEntityName.View';
import {useRegulatorAccountEntityName} from './useRegulatorAccountEntityName';

export const DSP_RegulatorAccountEntityName = () => {
  const viewProps = useRegulatorAccountEntityName();

  return <DSP_RegulatorAccountEntityNameView {...viewProps} />;
};

DSP_RegulatorAccountEntityName.displayName = 'DSP_RegulatorAccountEntityName';
