import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';
import React, {useState} from 'react';
import {useIdleTimer} from 'react-idle-timer';
import {getIdleLogout, IDLE_AUTO_LOGOUT_DELAY} from 'utils/global';
import {AuthStoreService} from 'utils/machines/authMachine';
import {useInterval} from 'usehooks-ts';

type AutoLogoutProps = {
  authService: AuthStoreService;
};

const AutoLogout: React.FC<AutoLogoutProps> = ({
  authService,
}: AutoLogoutProps) => {
  const timeout = 1000 * getIdleLogout();
  const promptBeforeIdle = 1000 * IDLE_AUTO_LOGOUT_DELAY;

  // Modal open state
  const [open, setOpen] = useState(false);

  // Time before idle
  const [remaining, setRemaining] = useState(0);

  const onPrompt = () => {
    // onPrompt will be called `promptBeforeIdle` milliseconds before `timeout`.
    // In this case 29 minutes and 30 seconds or 30 seconds before idle.
    // Here you can open your prompt.
    // All events are disabled while the prompt is active.
    // If the user wishes to stay active, call the `activate()` method.
    // You can get the remaining prompt time with the `getRemainingTime()` method,
    setOpen(true);
    setRemaining(IDLE_AUTO_LOGOUT_DELAY);
  };

  const onIdle = () => {
    // onIdle will be called after the timeout is reached.
    // In this case 30 minutes. Here you can close your prompt and
    // perform whatever idle action you want such as logging out your user.
    // Events will be rebound as long as `stopOnMount` is not set.
    setOpen(false);
    setRemaining(0);
    authService.send('LOGOUT');
  };

  const onActive = () => {
    // onActive will only be called if `activate()` is called while `isPrompted()`
    // is true. Here you will also want to close your modal and perform
    // any active actions.
    setOpen(false);
    setRemaining(0);
  };

  const {getRemainingTime, isPrompted, activate} = useIdleTimer({
    timeout,
    promptBeforeIdle,
    eventsThrottle: 1000,
    debounce: 1000,
    crossTab: true,
    syncTimers: 1000,
    onPrompt,
    onIdle,
    onActive,
  });

  const handleStillHere = () => {
    setOpen(false);
    activate();
  };

  useInterval(() => {
    if (isPrompted()) {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }
  }, 1000);

  return (
    <Snackbar
      open={open}
      onClose={(): void => {
        handleStillHere();
      }}
    >
      <MuiAlert
        severity="warning"
        elevation={0}
        variant="filled"
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={(): void => {
                handleStillHere();
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      >
        Logging out due to inactivity in {remaining} seconds ...
        <LinearProgress
          variant="determinate"
          value={(remaining * 100) / IDLE_AUTO_LOGOUT_DELAY}
        />
      </MuiAlert>
    </Snackbar>
  );
};

export default AutoLogout;
