import React, {ReactNode} from 'react';

import {
  InputLabel,
  FormControlProps,
  CircularProgress,
  FormHelperText,
} from '@mui/material';
import {DSL_UseGridItemStyles} from 'components/DesignSystem/Library/Layout/hooks';

import {DSL_FormControl} from '../FormControl';
import {DSL_Select, DSL_SelectProps} from '../Select';

export type DSL_BaseSelectInputProps<T> = Omit<DSL_SelectProps<T>, 'size'> &
  Pick<FormControlProps, 'error'> &
  DSL_UseGridItemStyles & {
    helperText?: ReactNode;
    loading?: boolean;
    /** @deprecated only added for legacy support */
    size?: DSL_SelectProps<T>['size'];
  };

const fieldSizeToLabelSize = {
  small: 'small',
  medium: 'normal',
} as const;

export const DSL_BaseSelectInput = function <T = string>({
  helperText,
  error,
  children,
  disabled,
  span,
  xs,
  sm,
  md,
  lg,
  xl,
  loading,
  endAdornment,
  size = 'medium',
  ...props
}: DSL_BaseSelectInputProps<T>) {
  return (
    <DSL_FormControl
      fullWidth
      error={error}
      disabled={disabled}
      span={span}
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
    >
      {props?.label && (
        <InputLabel size={fieldSizeToLabelSize[size || 'medium']}>
          {props.label}
        </InputLabel>
      )}
      <DSL_Select<T>
        size={size}
        {...props}
        endAdornment={
          loading ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            endAdornment
          )
        }
      >
        {children}
      </DSL_Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </DSL_FormControl>
  );
};

DSL_BaseSelectInput.displayName = 'DSL_BaseSelectInput';

export {
  //
  /** @deprecated use DSL_BaseSelectInputProps instead*/
  DSL_BaseSelectInputProps as BaseSelectInputProps, //
  /** @deprecated use DSL_BaseSelectInput instead*/
  DSL_BaseSelectInput as BaseSelectInput,
};
