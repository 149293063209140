import React from 'react';

import {DSL_ArrowForwardIcon} from '../../Icons';
import {
  DSL_ContainedButton,
  DSL_ContainedButtonProps,
} from '../ContainedButton';

export type DSL_NextButtonProps = Omit<
  DSL_ContainedButtonProps,
  'variant' | 'children'
>;

export const DSL_NextButton = (props: DSL_NextButtonProps) => {
  return (
    <DSL_ContainedButton endIcon={<DSL_ArrowForwardIcon />} {...props}>
      {props.title ?? 'Next'}
    </DSL_ContainedButton>
  );
};

DSL_NextButton.displayName = 'DSL_NextButton';

export {
  //
  /** @deprecated use DSL_NextButtonProps instead*/
  DSL_NextButtonProps as NextButtonProps, //
  /** @deprecated use DSL_NextButton instead*/
  DSL_NextButton as NextButton,
};
