import React from 'react';

import {accessProductChangeSetAccountTypeNotificationRendererMap} from './constants';
import {DSP_AccessProductChangeSetNotificationItem} from './types';

export interface DSP_AccessProductChangeSetNotificationCardProps {
  notification: DSP_AccessProductChangeSetNotificationItem;
}

export const DSP_AccessProductChangeSetNotificationCard = ({
  notification,
}: DSP_AccessProductChangeSetNotificationCardProps) => {
  const DSP_NotificationComponent =
    accessProductChangeSetAccountTypeNotificationRendererMap[
      notification.accountType
    ];

  if (!DSP_NotificationComponent) {
    return null;
  }

  return <DSP_NotificationComponent notification={notification} />;
};

DSP_AccessProductChangeSetNotificationCard.displayName =
  'DSP_AccessProductChangeSetNotificationCard';

export {
  //
  /** @deprecated use DSP_AccessProductChangeSetNotificationCardProps instead*/
  DSP_AccessProductChangeSetNotificationCardProps as AccessProductChangeSetNotificationCardProps, //
  /** @deprecated use DSP_AccessProductChangeSetNotificationCard instead*/
  DSP_AccessProductChangeSetNotificationCard as AccessProductChangeSetNotificationCard,
};
