import {ChangeEvent} from 'react';

import {
  JSONSchemaRecord,
  schemaJsonPointerResolver,
} from '@regulatory-platform/common-utils';

import {MultilineTextInputProps} from 'components/DesignSystem/Library';

import {useFormContext} from '../FormProvider';
import {useFormFieldPropsSelector, DSP_UseInputFieldProps} from '../hooks';
import {getGenericFieldProps} from '../utils/getGenericFieldProps';

export type DSP_UseMultiLineTextFieldProps = DSP_UseInputFieldProps &
  MultilineTextInputProps;

export function useMultiLineTextField({
  fieldRef,
  onBlurEvent,
  onChangeEvent,
  hideLabel,
  label,
  rows,
  includeOptionalSuffix = true,
  useLabelForValidationErrors = false,
  ...props
}: DSP_UseMultiLineTextFieldProps): MultilineTextInputProps {
  const {service} = useFormContext();
  const send = service.send;

  return useFormFieldPropsSelector(fieldRef, service, state => {
    const {value, ...genericFieldProps} = getGenericFieldProps({
      fieldRef,
      state,
      label,
      hideLabel,
      includeOptionalSuffix,
      useLabelForValidationErrors,
    });
    const fieldConfig = schemaJsonPointerResolver(fieldRef)(
      state.context.schema,
    ) as JSONSchemaRecord;

    return {
      rows,
      ...genericFieldProps,
      value: value ?? '',
      maxLength: fieldConfig?.maxLength,
      onChange: (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      ): void => {
        send({type: 'CHANGE', fieldRef, value: event.currentTarget.value});
        onChangeEvent?.();
      },
      onBlur: (): void => {
        send({type: 'BLUR', fieldRef});
        onBlurEvent?.();
      },
      ...props,
    };
  });
}
