import {UserProfile} from '@regulatory-platform/common-utils';
import userProfileAuthorisedForRoute from 'app/router/utils/userProfileAuthorisedForRoute';
import Unauthorised from 'components/templates/errors/Unauthorised';
import React, {FC} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import {ContainerConfig} from '../types';
import {RouteElement} from './RouteElement';
import {useRegisterContent} from 'containers-content';

type ContainerRouterProps = {
  userProfile?: UserProfile;
  containerConfig: ContainerConfig;
};

const ContainerRouter: FC<ContainerRouterProps> = ({
  userProfile,
  containerConfig,
}: ContainerRouterProps) => {
  useRegisterContent(containerConfig.content);

  return (
    <Routes>
      {containerConfig.routes.map((route, i) => {
        return (
          <Route
            key={i}
            path={!route.nestedRoutes?.length ? route.path : `${route.path}/*`}
            element={
              userProfileAuthorisedForRoute(route, userProfile) ? (
                <RouteElement {...route} />
              ) : (
                <Unauthorised />
              )
            }
          >
            {/* Nested routing; requires React Router <Outlet /> to be placed in container route */}
            {route.nestedRoutes?.length &&
              route.nestedRoutes.map((nestedRoute, nrIdx) => {
                return (
                  <Route
                    key={nrIdx}
                    path={nestedRoute.path}
                    element={
                      <RouteElement
                        shouldSetPageTitle={false}
                        {...nestedRoute}
                      />
                    }
                    index={nestedRoute.index ?? false}
                  />
                );
              })}
          </Route>
        );
      })}
      <Route path="/*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default ContainerRouter;
