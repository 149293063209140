import {ApolloLink, fromPromise} from '@apollo/client';
import {authClient} from '../clients';
import {UserModelControllerRefreshDocument} from 'app/api/mutations/Refresh.generated';
import authService from 'app/services/authService';
import {
  JwtTokenDecoded,
  toNumber,
  UserProfile,
} from '@regulatory-platform/common-utils';
import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode';
import * as R from 'ramda';
import {JwtToken, RefreshInput} from 'typeDefs/types';
import {EXPIRY_OFFSET} from 'utils/global';

/**
 * Refreshing token flag to prevent concurrent refreshing
 */
let _isRefreshing = false;
const isRefreshing = (): boolean => {
  return _isRefreshing;
};
const setIsRefreshing = (value: boolean): void => {
  _isRefreshing = value;
};

/**
 * Queue for requests while a token refresh is in progress
 */
let pendingRequests: Function[] = [];
const addPendingRequest = (pendingRequest: Function): void => {
  pendingRequests.push(pendingRequest);
};
const resolvePendingRequests = (): void => {
  pendingRequests.map(callback => callback());
  pendingRequests = [];
};

/**
 * In memory store of current token and expiry
 */
let _token: string | undefined;
let _expiry: number | undefined;
const clearToken = (): void => {
  _expiry = undefined;
  _token = undefined;
};
function isExpired(): boolean {
  const unixNow = new Date().getTime() / 1000;
  //subtract EXPIRY_OFFSET from expiry as buffer for next request
  return _expiry ? _expiry - EXPIRY_OFFSET <= unixNow : true;
}
const setToken = (token: string, expiry: number | undefined): void => {
  _token = token;
  _expiry = expiry;
};
export const getNonExpiredToken = (): string | undefined => {
  if (isExpired()) {
    clearToken();
  }
  return _token;
};

function setTokenInternallyAndGetUserProfile(jwtToken: JwtToken): UserProfile {
  const token = jwtToken.token as string;
  const decodedToken = jwtDecode(token) as JwtTokenDecoded;
  setToken(token, decodedToken.exp);
  return {
    id: decodedToken.id,
    firstName: decodedToken.firstName,
    name: decodedToken.name,
    email: decodedToken.email,
    ip: decodedToken.ip,
    accountName: decodedToken.accountName,
    accountType: decodedToken.accountType,
    idVerified: decodedToken.idVerified,
    accountSubTypes: decodedToken.accountSubTypes,
    featureTokens: decodedToken.featureTokens,
    accountId: decodedToken.accountId,
    rMID: decodedToken.rMID,
    accountImageUrl: R.defaultTo('', decodedToken.accountImageUrl),
    userImageUrl: R.defaultTo('', decodedToken.userImageUrl),
    accountCount: decodedToken.accountCount,
    impersonationAccountType: decodedToken.impersonationAccountType,
    impersonationAccountId: decodedToken.impersonationAccountId,
    roles: decodedToken.roles,
    thirdPartyTokens: decodedToken.thirdPartyTokens,
    domain: decodedToken.domain,
  };
}

export function getRefreshInputFromCookies(): RefreshInput {
  const accountType = Cookies.get('accountType');
  return {
    refreshToken: Cookies.get('refreshToken'),
    accountId: toNumber(R.defaultTo('', Cookies.get('accountId'))),
    domain: Cookies.get('domain'),
    ...(accountType ? {accountType} : {}),
  };
}

const getNewToken = async (): Promise<void> => {
  const {data} = await authClient.mutate({
    mutation: UserModelControllerRefreshDocument,
    variables: {refreshInput: getRefreshInputFromCookies()},
  });

  //save JWT internally to enable processing queued or new requests
  const userProfile = setTokenInternallyAndGetUserProfile(
    data.userModelControllerRefresh,
  );

  //send to auth service and trigger save of new refresh token to cookies
  authService.service.send('REFRESH', {
    data: {
      data: {
        userModelControllerRefresh: {
          refreshToken: data.userModelControllerRefresh.refreshToken,
          userProfile: userProfile,
        },
      },
    },
  });
};

export const authGetTokenLink = new ApolloLink((operation, forward) => {
  //handle logout request
  if (operation.operationName === 'userModelControllerLogout') {
    operation.variables.refreshInput = getRefreshInputFromCookies();
    clearToken();
    return forward(operation).map(data => {
      authClient.clearStore();
      return data;
    });
  }
  // handle login and refresh operation
  if (
    R.includes(operation.operationName, [
      'userModelControllerVerifyTokenAccess',
      'userModelControllerVerifyB2CTokenAccess',
      'userModelControllerLogin',
      'userModelControllerRefresh',
    ])
  ) {
    setIsRefreshing(true);
    //handle refresh request
    if (operation.operationName === 'userModelControllerRefresh') {
      operation.variables.refreshInput = getRefreshInputFromCookies();
    }
    return forward(operation).map(data => {
      //handle login response
      if (
        R.includes(operation.operationName, [
          'userModelControllerVerifyTokenAccess',
          'userModelControllerVerifyB2CTokenAccess',
          'userModelControllerLogin',
        ]) &&
        !R.isNil(data.data) &&
        (!R.isNil(data.data.userModelControllerLogin) ||
          !R.isNil(data.data.userModelControllerVerifyTokenAccess) ||
          !R.isNil(data.data.userModelControllerVerifyB2CTokenAccess)) &&
        R.isNil(data.errors)
      ) {
        if (!R.isNil(data.data.userModelControllerVerifyTokenAccess)) {
          data.data.userModelControllerVerifyTokenAccess.userProfile =
            setTokenInternallyAndGetUserProfile(
              data.data.userModelControllerVerifyTokenAccess,
            );
        } else if (
          !R.isNil(data.data.userModelControllerVerifyB2CTokenAccess)
        ) {
          data.data.userModelControllerVerifyB2CTokenAccess.userProfile =
            setTokenInternallyAndGetUserProfile(
              data.data.userModelControllerVerifyB2CTokenAccess,
            );
        } else {
          data.data.userModelControllerLogin.userProfile =
            setTokenInternallyAndGetUserProfile(
              data.data.userModelControllerLogin,
            );
        }
      }
      //handle refresh response
      if (
        operation.operationName === 'userModelControllerRefresh' &&
        !R.isNil(data.data) &&
        !R.isNil(data.data.userModelControllerRefresh) &&
        R.isNil(data.errors)
      ) {
        data.data.userModelControllerRefresh.userProfile =
          setTokenInternallyAndGetUserProfile(
            data.data.userModelControllerRefresh,
          );
      }
      resolvePendingRequests();
      setIsRefreshing(false);
      return data;
    });
  }

  // if we have a non-expired token, return it immediately
  const token = getNonExpiredToken();
  if (!R.isNil(token)) {
    return forward(operation);
  }

  // check for refresh token
  const refreshToken = Cookies.get('refreshToken');

  // if we don't have refresh token then return
  if (R.isNil(refreshToken)) {
    return forward(operation);
  }

  if (!isRefreshing()) {
    setIsRefreshing(true);
    return fromPromise(
      getNewToken().catch(() => {
        resolvePendingRequests();
        setIsRefreshing(false);

        return forward(operation);
      }),
    ).flatMap(() => {
      resolvePendingRequests();
      setIsRefreshing(false);
      return forward(operation);
    });
  }

  //refreshing token so queue request
  return fromPromise(
    new Promise<void>(resolve => {
      addPendingRequest(() => resolve());
    }),
  ).flatMap(() => {
    return forward(operation);
  });
});
