import React, {ReactNode} from 'react';

import {Chip, HorizontalStack} from 'components/DesignSystem/Library';

import {DSP_NotificationCard} from '../../NotificationCard';
import {DSP_NotificationCardHeader} from '../../NotificationCardHeader';
import {DSP_NotificationCardTitle} from '../../NotificationCardTitle';
import {DSP_OutcomeColorType} from '../constants';

export interface DSP_BaseWorkflowActivityNotificationCardViewProps {
  avatar: ReactNode | undefined;
  href?: string;
  created?: Date;
  actionDescription?: string;
  title?: string;
  description?: string;
  status?: string;
  isRead?: boolean;
  statusColor?: DSP_OutcomeColorType;
}

export const DSP_BaseWorkflowActivityNotificationCardView = ({
  avatar,
  href,
  created,
  actionDescription,
  title,
  status,
  isRead,
  statusColor,
}: DSP_BaseWorkflowActivityNotificationCardViewProps) => {
  return (
    <DSP_NotificationCard href={href} isRead={isRead}>
      <DSP_NotificationCardHeader
        created={created}
        actionDescription={actionDescription}
        avatar={avatar}
      />
      {title && <DSP_NotificationCardTitle>{title}</DSP_NotificationCardTitle>}
      <HorizontalStack>
        <Chip
          variant={'outlined'}
          size={'small'}
          label={status}
          color={statusColor}
        />
      </HorizontalStack>
    </DSP_NotificationCard>
  );
};

DSP_BaseWorkflowActivityNotificationCardView.displayName =
  'DSP_BaseWorkflowActivityNotificationCardView';

export {
  //
  /** @deprecated use DSP_BaseWorkflowActivityNotificationCardViewProps instead*/
  DSP_BaseWorkflowActivityNotificationCardViewProps as BaseWorkflowActivityNotificationCardViewProps, //
  /** @deprecated use DSP_BaseWorkflowActivityNotificationCardView instead*/
  DSP_BaseWorkflowActivityNotificationCardView as BaseWorkflowActivityNotificationCardView,
};
