import React from 'react';

import {Link} from '@mui/material';

import {
  DSL_Body1,
  DSL_SelectFileDropTarget,
  DSL_SelectFileDropTargetProps,
} from 'components/DesignSystem/Library';

import {pluralizeLabel} from '../../../../utils/pluralizeLabel';

export interface ImportFromCsvImportStepProps
  extends Omit<
    DSL_SelectFileDropTargetProps,
    'acceptedMimeTypes' | 'maxFiles' | 'maxSize'
  > {
  maxRows: number;
  nameOfData: string;
  onDownloadFormat: () => void;
}

export const ImportFromCsvSelectFileStep = ({
  maxRows,
  nameOfData,
  onDownloadFormat,
  ...props
}: ImportFromCsvImportStepProps) => {
  return (
    <>
      <DSL_Body1>
        You can import {nameOfData}s using a CSV file. The data in the file must
        follow a specific format to be imported successfully.{' '}
        <Link onClick={onDownloadFormat}>Download the format</Link> which is
        required to populate the CSV file.
      </DSL_Body1>
      <DSL_SelectFileDropTarget
        maxFiles={1}
        acceptedMimeTypes={['text/csv']}
        {...props}
      >
        (max. {maxRows} {pluralizeLabel('row', maxRows)})
      </DSL_SelectFileDropTarget>
    </>
  );
};

ImportFromCsvSelectFileStep.displayName = 'ImportFromCsvImportStep';
