import {Observable} from 'rxjs';
import {FormEventObject} from 'utils/stores/types';
import {createMachine, InterpreterFrom, MachineConfig, State} from 'xstate';
import machineDefinition from './snackbarMachine.json';

export type MessageVariant = 'success' | 'error' | 'info' | 'warning';

export interface SnackbarMessage {
  key?: string;
  message: string;
  variant: MessageVariant;
}

export type SnackbarMachineContext = {
  messageQueue: SnackbarMessage[];
};
export interface SnackbarMachineStateSchema {
  states: {
    inactive: {};
    active: {};
  };
}
export type SnackbarState = State<
  SnackbarMachineContext,
  FormEventObject,
  SnackbarMachineStateSchema
>;
export type SnackbarObservable = Observable<SnackbarState>;

export type SnackbarMachineConfig = MachineConfig<
  SnackbarMachineContext,
  SnackbarMachineStateSchema,
  FormEventObject
>;

const machine = createMachine<SnackbarMachineContext, FormEventObject>(
  machineDefinition as SnackbarMachineConfig,
);

export type SnackbarService = InterpreterFrom<typeof machine>;

export default machine;
