import React, {ForwardedRef, forwardRef} from 'react';

import {DSL_FlexColumn, DSL_FlexColumnProps} from '../FlexColumn';

export type DSL_ScrollableLayoutProps = Omit<
  DSL_FlexColumnProps,
  'flexWrap' | 'gap'
>;

export const DSL_ScrollableLayout = forwardRef(
  (
    {children, ...props}: DSL_ScrollableLayoutProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    return (
      <DSL_FlexColumn ref={ref} gap={0} flexWrap={'nowrap'} {...props}>
        {children}
      </DSL_FlexColumn>
    );
  },
);

DSL_ScrollableLayout.displayName = 'DSL_ScrollableLayout';
