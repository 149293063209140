import React from 'react';

import {DSL_ActionsMenuIconButton} from 'components/DesignSystem/Library/Buttons/IconButtons/ActionsMenuIconButton';
import {
  DSL_TooltipProps,
  DSL_Tooltip,
} from 'components/DesignSystem/Library/Forms/Tooltip';

import {DSL_FlexRow} from '../../FlexRow';

export interface ActionsMenuToolTipProps
  extends Omit<DSL_TooltipProps, 'title' | 'onClick' | 'children'> {
  open?: boolean;
}

export const ActionsMenuToolTip = ({
  open,
  ...tooltipProps
}: ActionsMenuToolTipProps) => {
  return (
    <DSL_Tooltip
      placement={'bottom'}
      title={open ? '' : 'Actions menu'}
      {...tooltipProps}
    >
      <DSL_FlexRow alignItems={'center'} cursor={'pointer'}>
        <DSL_ActionsMenuIconButton
          id="actions-menu-button"
          aria-controls={open ? 'actions-menu-list' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          aria-label="Toggle actions menu"
          size={'large'}
        />
      </DSL_FlexRow>
    </DSL_Tooltip>
  );
};
