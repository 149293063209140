import {JSONSchemaRecord} from '@regulatory-platform/common-utils';
import * as R from 'ramda';

export function getPureLabel(fieldSchema: JSONSchemaRecord): string {
  if (!R.isNil((fieldSchema?.['x-items'] as JSONSchemaRecord)?.['x-title'])) {
    return (fieldSchema['x-items'] as JSONSchemaRecord)['x-title'] as string;
  }
  if (!R.isNil((fieldSchema?.['x-items'] as JSONSchemaRecord)?.title)) {
    return (fieldSchema['x-items'] as JSONSchemaRecord).title as string;
  }
  return R.defaultTo(
    '',
    R.defaultTo(fieldSchema?.title, fieldSchema?.['x-title']),
  );
}

export default function getLabel(fieldSchema: JSONSchemaRecord): string {
  if (R.defaultTo('', fieldSchema['x-ptr']).indexOf('#/fileList') === 0) {
    return 'Attachments';
  }
  if (
    R.reverse(R.defaultTo('', fieldSchema['x-ptr'])).indexOf(
      R.reverse('contactRecord/address'),
    ) === 0
  ) {
    return 'Contact person address';
  }
  return getPureLabel(fieldSchema);
}
