import {useMemo} from 'react';

import {Theme, useTheme} from '@mui/material';
import {fromPairs, map} from 'ramda';

import {DSL_DrawerContext} from './DrawerProvider';

export function useDrawerTransitions(
  drawerDimensions: DSL_DrawerContext['drawerDimensions'],
) {
  const theme = useTheme();

  /*
   Creates transitions based on MUI theme values to match the Drawer
   transitions for seamless animations when opening/closing.
  */
  const transitionStates = useMemo(
    () => createTransitionStates(theme.transitions),
    [theme.transitions],
  );

  return {
    transition: createTransition(transitionStates, drawerDimensions),
  };
}

function createTransitionStates(themeTransitions: Theme['transitions']) {
  const createTransitionsFor = (cssProps: string[]) =>
    fromPairs(
      map(cssProp => {
        return [
          // Convert CSS prop name to JS prop name
          cssProp.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase()),
          // Create transition config
          create(cssProp),
        ];
      }, cssProps),
    );

  const create = (prop: string) => {
    const {create: muiCreate, easing, duration} = themeTransitions;

    return {
      onOpen: muiCreate(prop, {
        easing: easing.easeIn,
        duration: duration.leavingScreen,
      }),
      onClose: muiCreate(prop, {
        easing: easing.easeOut,
        duration: duration.enteringScreen,
      }),
    };
  };

  return createTransitionsFor(['left', 'right', 'margin-left', 'margin-right']);
}

function createTransition(
  transitionStates: ReturnType<typeof createTransitionStates>,
  drawerDimensions: DSL_DrawerContext['drawerDimensions'],
) {
  const widestLeftDrawer = drawerDimensions.fixed.left.width;
  const widestRightDrawer = drawerDimensions.fixed.right.width;

  const mapTransitionStatesToTransitionStyles = ([jsProp, transition]: [
    string,
    (typeof transitionStates)['key'],
  ]) => {
    if (jsProp.toLowerCase().includes('left')) {
      return widestLeftDrawer > 0 ? transition.onClose : transition.onOpen;
    } else if (jsProp.toLowerCase().includes('right')) {
      return widestRightDrawer > 0 ? transition.onClose : transition.onOpen;
    }
  };

  const stylesList = Object.entries(transitionStates).map(
    mapTransitionStatesToTransitionStyles,
  );

  return stylesList.join(',');
}
