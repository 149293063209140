import React from 'react';

import {
  DSP_CustomerAccountEntityAvatarWithName,
  DSP_CustomerAccountEntityLoaderProvider,
} from 'components/DesignSystem/Portal/Entity/AccountEntity/CustomerEntity';

import {
  DSP_BaseAccreditationActionNotificationCard,
  DSP_BaseAccreditationActionNotificationCardProps,
} from '../Base/BaseAccreditationActionNotificationCard.Connector';

export interface DSP_AccreditationActionOperationsNotificationCardViewProps
  extends Omit<DSP_BaseAccreditationActionNotificationCardProps, 'avatar'> {
  customerAccountId: number | undefined | null;
}

export const DSP_AccreditationActionOperationsNotificationCardView = ({
  notification,
  customerAccountId,
}: DSP_AccreditationActionOperationsNotificationCardViewProps) => {
  return (
    <DSP_BaseAccreditationActionNotificationCard
      notification={notification}
      avatar={
        customerAccountId && (
          <DSP_CustomerAccountEntityLoaderProvider entityId={customerAccountId}>
            <DSP_CustomerAccountEntityAvatarWithName />
          </DSP_CustomerAccountEntityLoaderProvider>
        )
      }
    />
  );
};

DSP_AccreditationActionOperationsNotificationCardView.displayName =
  'DSP_AccreditationActionOperationsNotificationCardView';
