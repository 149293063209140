import {lazy} from 'react';
import WebAsset from '@mui/icons-material/WebAsset';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/applications/application/:permitApplicationId/vcbTestVehicles/vcbTestVehicle/create',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "NewAssetDetailAratTestVehicleGateway" */ './NewAssetDetailAratTestVehicleGateway'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Add new asset test vehicle',
  menuIcon: WebAsset,
  authorisation: {
    apiKey: 'AssetDetailAratTestVehicleWithRelations.create',
    policies: [
      {
        role: 'operationsSpatialUser',
      },
      {
        role: 'partnerSpatialUser',
      },
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Test Vehicles',
      to: '../../vcbTestVehicles',
      state: {'Manage asset': 'Test Vehicles'},
    },
  ],
};
