import {ConsentWorkflowState} from 'containers-access/AccessProductApplication/types';
import {i18n} from 'containers-content';

import {applicationWorkflowStateDescriptionMap} from '../constants';
import {DSP_AccreditationApplicationNotificationItem} from '../types';

export interface DSP_UseBaseAccreditationApplicationNotificationCardProps {
  notification: DSP_AccreditationApplicationNotificationItem;
}

export function useBaseAccreditationApplicationNotificationCard({
  notification,
}: DSP_UseBaseAccreditationApplicationNotificationCardProps) {
  const {id, applicationState, applicationType, status} = notification.data;

  const actionDescription =
    applicationWorkflowStateDescriptionMap[
      applicationState?.workflowState as ConsentWorkflowState
    ];

  return {
    actionDescription,
    isRead: notification.readByLoggedInUser,
    href: `/accreditation/applications/${id}`,
    created: notification.created ?? undefined,
    name: applicationType ?? undefined,
    applicationId: `${i18n.fromMapWithDefault({
      default: 'NHVAS',
      wa: 'WAHVA',
    })}${id}`,
    status: status ?? undefined,
  };
}
