import React, {Fragment} from 'react';

import {DSL_Divider} from 'components/DesignSystem/Library';

import {DSP_RoadsByAccessTypeCard} from '../RoadsByAccessTypeCard';

import {useDspRoadsByAccessTypeList} from './useRoadsByAccessTypeList';

interface DSP_RoadsByAccessTypeListProps {
  onRemoveSegments?: (segmentIds: number[]) => void;
}

export const DSP_RoadsByAccessTypeList = ({
  onRemoveSegments,
}: DSP_RoadsByAccessTypeListProps) => {
  const {roadsByAccessType, ...props} = useDspRoadsByAccessTypeList({
    onRemoveSegments,
  });
  return (
    <>
      {roadsByAccessType.map((roadByAccessType, index) => (
        <Fragment key={index}>
          <DSP_RoadsByAccessTypeCard
            key={index}
            {...props}
            {...roadByAccessType}
          />
          {<DSL_Divider />}
        </Fragment>
      ))}
    </>
  );
};
