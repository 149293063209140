import React, {createContext, useContext} from 'react';

import {DSP_EntityLoaderContext} from '../../EntityLoaderProvider';
import {DSP_EntityLoaderProviderProps} from '../../types';

import {DSP_RegulatorAccountEveryonePublic} from './types';

const DSP_RegulatorAccountEntityLoaderContext = createContext<
  DSP_EntityLoaderContext<DSP_RegulatorAccountEveryonePublic>
>({
  entity: undefined,
  isError: false,
  isLoading: false,
});

const value = {
  isError: false,
  isLoading: false,
  entity: {
    id: 1,
    name: 'National Heavy Vehicle Regulator',
    website: 'https://www.nhvr.gov.au/',
    fileList: {
      accountImageUrl: '',
    },
  },
};

export const DSP_RegulatorAccountEntityLoaderProvider = ({
  children,
}: Omit<DSP_EntityLoaderProviderProps, 'entityId'>) => {
  return (
    <DSP_RegulatorAccountEntityLoaderContext.Provider value={value}>
      {children}
    </DSP_RegulatorAccountEntityLoaderContext.Provider>
  );
};

export const useRegulatorAccountEntityLoaderContext = () =>
  useContext(DSP_RegulatorAccountEntityLoaderContext);

DSP_RegulatorAccountEntityLoaderProvider.displayName =
  'DSP_RegulatorAccountEntityLoaderProvider';
