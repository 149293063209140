import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/approval-library/:approvalId/application/:id',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "RegistrationDetails" */ 'containers-pbs/Applications/NewApplication/NewApplication'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'PBS application',
  menuIcon: LocalShippingOutlinedIcon,
  authorisation: {
    apiKey: 'PbsApplicationWithRelations.findById',
    policies: [
      {role: 'operationsPBSRead'},
      {role: 'providerPBSRead'},
      {role: 'customerPBSEnabled'},
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'PBS Approval Library',
      to: '../../..',
    },
    {
      content: 'Approval',
      to: '../..',
      state: {'Approval detail': 'Applications'},
    },
  ],
};
