import React, {useMemo} from 'react';

import {Theme} from '@mui/material';
import {Box as MuiBox, BoxProps as MuiBoxProps} from '@mui/material';
import {useTheme} from '@mui/styles';
import {Breakpoint} from '@mui/system';
import {SystemCssProperties} from '@mui/system';
import {Properties} from 'csstype';

import {DSL_DeprecatedBoxProps, DSL_RestrictedBoxProps} from '../../types';

/**
 * Internal use only.  Components outside of the DS Library should consume Box or other BaseBox derivatives
 */
export interface DSL_BaseBoxProps
  extends DSL_DeprecatedBoxProps,
    Omit<MuiBoxProps, DSL_RestrictedBoxProps | keyof DSL_DeprecatedBoxProps> {
  /** NOTE: if you're using this outside of the design system it's a sign you need to build a separate design system component */
  internal_sx?: MuiBoxProps['sx'];
  /** @deprecated use internal_sx instead */
  sx?: MuiBoxProps['sx'];
  overflowX?: SystemCssProperties['overflowX'];
  overflowY?: SystemCssProperties['overflowY'];
  cursor?: Properties['cursor'];
  hiddenUpTo?: Breakpoint;
  hiddenDownTo?: Breakpoint;
  flexRow?: boolean;
  flexColumn?: boolean;
  borderRadius?: SystemCssProperties['borderRadius'];
  pointerEvents?: SystemCssProperties['pointerEvents'];
  wordBreak?: SystemCssProperties['wordBreak'];
  boxShadow?: SystemCssProperties['boxShadow'];
  transform?: SystemCssProperties['transform'];
  bgcolor?: MuiBoxProps['bgcolor'];
  showOnTouchEnabledDevices?: boolean;
}

export const DSL_BaseBox = React.forwardRef(
  (
    {
      overflowY,
      overflowX,
      cursor,
      pointerEvents,
      borderRadius,
      internal_sx = [],
      sx = [],
      hiddenUpTo,
      hiddenDownTo,
      flexRow,
      flexColumn,
      wordBreak,
      boxShadow,
      transform,
      bgcolor,
      showOnTouchEnabledDevices = true,
      ...props
    }: DSL_BaseBoxProps,
    ref,
  ) => {
    const theme: Theme = useTheme();
    const styles = useMemo(
      () => ({
        ...(hiddenUpTo && {
          [theme.breakpoints.up(hiddenUpTo)]: {display: 'none'},
        }),
        ...(hiddenDownTo && {
          [theme.breakpoints.down(hiddenDownTo)]: {display: 'none'},
        }),
        ...(flexRow && {
          display: 'flex',
          flexDirection: 'row',
        }),
        ...(flexColumn && {
          display: 'flex',
          flexDirection: 'column',
        }),
        ...(!showOnTouchEnabledDevices && {
          ['@media (pointer: coarse)']: {display: 'none'},
        }),
        overflowX,
        overflowY,
        cursor,
        borderRadius,
        pointerEvents,
        wordBreak,
        boxShadow,
        transform,
        bgcolor,
      }),
      [
        hiddenUpTo,
        theme.breakpoints,
        hiddenDownTo,
        flexRow,
        flexColumn,
        overflowX,
        overflowY,
        cursor,
        borderRadius,
        pointerEvents,
        wordBreak,
        boxShadow,
        bgcolor,
        showOnTouchEnabledDevices,
        transform,
      ],
    );

    const enforceArray = (maybeArray: MuiBoxProps['sx']) =>
      Array.isArray(maybeArray) ? maybeArray : [maybeArray];

    return (
      <MuiBox
        sx={[styles, ...enforceArray(internal_sx), ...enforceArray(sx)]}
        {...props}
        ref={ref}
      />
    );
  },
) as React.FC<DSL_BaseBoxProps>;

DSL_BaseBox.displayName = 'DSL_BaseBox';

export {
  //
  /** @deprecated use DSL_BaseBoxProps instead*/
  DSL_BaseBoxProps as BaseBoxProps, //
  /** @deprecated use DSL_BaseBox instead*/
  DSL_BaseBox as BaseBox,
};
