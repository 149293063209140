import React from 'react';

import {DSL_BaseTypography, DSL_BaseTypographyProps} from '../BaseTypography';

export type DSL_Body1Props = Omit<DSL_BaseTypographyProps, 'variant'>;
export const DSL_Body1 = (props: DSL_Body1Props) => (
  <DSL_BaseTypography color={'text.primary'} {...props} variant={'body1'} />
);

export {
  //
  /** @deprecated use DSL_Body1Props instead*/
  DSL_Body1Props as Body1Props, //
  /** @deprecated use DSL_Body1 instead*/
  DSL_Body1 as Body1,
};
