import {
  schemaJsonPointerSettor,
  jsonPointerSettor,
} from '@regulatory-platform/common-utils';
import * as R from 'ramda';
import {FormEventObject, FormMachineContext} from './types';
import {ObjectType} from '@regulatory-platform/common-utils/dist/types';

/**
 * Set field value if any and set x-dirty flag which is temporary as this will be overiden by onLocalValidatorExec
 */
export default function onBeforeValidate<T extends ObjectType>(
  context: FormMachineContext<T>,
  event: FormEventObject,
): FormMachineContext<T> {
  const {fieldRef, value, fieldRefRelation, valueRelation} = event;
  const {record: origRecord, schema: origSchema} = context;
  const isNewRecord = fieldRef.indexOf('/-1/') > -1;
  let record =
    value === undefined
      ? origRecord
      : jsonPointerSettor(fieldRef, value)(origRecord);
  if (!isNewRecord && !R.isNil(fieldRefRelation)) {
    record = jsonPointerSettor(fieldRefRelation, valueRelation)(record);
  }
  const nextContext = context;
  nextContext.record = record as T;
  nextContext.schema = schemaJsonPointerSettor(
    fieldRef,
    'x-dirty',
    true,
  )(origSchema);
  return nextContext;
}
