import React from 'react';

import {DSP_WizardProvider} from '../WizardProvider';

import {useWizardLayout, DSP_UseWizardLayoutArgs} from './useWizardLayout';
import {DSP_WizardLayoutView} from './WizardLayout.View';

export interface DSP_WizardLayoutProps extends DSP_UseWizardLayoutArgs {
  isCreate?: boolean;
}

export const DSP_WizardLayout = ({
  isCreate,
  ...props
}: DSP_WizardLayoutProps) => {
  const {onStepChange, ...viewProps} = useWizardLayout(props);

  return (
    <DSP_WizardProvider onStepChange={onStepChange}>
      <DSP_WizardLayoutView
        isCreate={isCreate}
        onStepChange={onStepChange}
        {...viewProps}
      />
    </DSP_WizardProvider>
  );
};

DSP_WizardLayout.displayName = 'DSP_WizardLayout';
