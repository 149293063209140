import React from 'react';

import {DSL_BaseAlert, DSL_BaseAlertProps} from '../BaseAlert';

export type DSL_InlineInfoAlertProps = Omit<
  DSL_BaseAlertProps,
  'variant' | 'severity'
>;

export const DSL_InlineInfoAlert = (props: DSL_InlineInfoAlertProps) => {
  return <DSL_BaseAlert variant={'outlined'} severity="info" {...props} />;
};

DSL_InlineInfoAlert.displayName = 'DSL_InlineInfoAlert';

export {
  //
  /** @deprecated use DSL_InlineInfoAlertProps instead*/
  DSL_InlineInfoAlertProps as InlineInfoAlertProps, //
  /** @deprecated use DSL_InlineInfoAlert instead*/
  DSL_InlineInfoAlert as InlineInfoAlert,
};
