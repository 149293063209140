/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {useApolloClient} from '@apollo/client';
import {useMachine} from '@xstate/react';
import getQueryParams from 'app/router/utils/getQueryParams';
import AppServicesContext from 'app/services/AppServicesContext';
import moment from 'moment';
import * as R from 'ramda';
import {useContext} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {map} from 'rxjs/operators';
import {assign} from 'xstate';
import {UserModelControllerVerifyEmailDocument} from './mutations/VerifyEmail.generated';
import verifyMachine from './verifyMachine';
import {FormMachine} from 'utils/machines/formMachine';

export type SharedContext = {
  type: string;
  email: string;
  secret: string;
  userId: string;
  iv: string;
  accountType: string;
  accountId: string;
  clientId: string;
  pathName: string;
  recaptcha: string;
};

export default function useVerifyEmailMachine() {
  const client = useApolloClient();
  const {app, auth, snackbar} = useContext(AppServicesContext);
  const navigate = useNavigate();
  const {search} = useLocation();
  const {type, email, secret, userId, iv, accountType, accountId, clientId} =
    getQueryParams(search);

  const pathName = window.location.pathname;

  const shared = {
    type,
    email,
    secret,
    userId,
    iv,
    accountType,
    accountId,
    clientId,
    pathName,
  };

  const container = useMachine<FormMachine>(verifyMachine, {
    context: {
      shared,
    },
    guards: {
      invalidUrl: (context): boolean => {
        const sharedContext = context.shared as SharedContext;
        return (
          sharedContext.type === 'email' &&
          R.any(
            param => R.isNil(param) || R.isEmpty(param),
            [
              sharedContext.email,
              sharedContext.userId,
              sharedContext.secret,
              sharedContext.iv,
            ],
          )
        );
      },
      verifyEmail: (context): boolean => {
        const sharedContext = context.shared as SharedContext;
        return (
          sharedContext.type === 'email' &&
          R.all(param => !R.isNil(param), [email, userId, secret, iv])
        );
      },
      verifyToken: (context): boolean => {
        const sharedContext = context.shared as SharedContext;
        return (
          sharedContext.type === 'loginWithToken' &&
          R.all(
            param => !R.isNil(param),
            [
              sharedContext.email,
              sharedContext.userId,
              sharedContext.secret,
              sharedContext.iv,
              sharedContext.accountType,
              sharedContext.accountId,
              sharedContext.clientId,
            ],
          )
        );
      },
    },
    actions: {
      onRecaptcha: assign({
        shared: (context, event) =>
          R.assoc('recaptcha', event.value, context.shared),
      }),
      onVerifyEmailSuccess: (): void => {
        snackbar.service.send('TRIGGER', {
          record: {
            variant: 'success',
            message: 'Your email has been successfully verified',
          },
        });
        app.service.send('NORMAL');
        navigate('../');
      },
      onVerifyToken: (context): void => {
        const sharedContext = context.shared as SharedContext;
        const referrer = document.referrer;
        let url;
        let paramObject = {
          type: sharedContext.type,
          accountType: sharedContext.accountType,
          email: sharedContext.email,
          userId: parseInt(sharedContext.userId),
          clientId: parseInt(sharedContext.clientId),
          accountId: parseInt(sharedContext.accountId),
          verificationToken: sharedContext.secret,
          iv: sharedContext.iv,
          validTo: moment(moment.utc()).add(6, 'minutes').toDate(),
        };
        if (referrer) {
          url = R.split('?', referrer);
          if (R.is(Array, url) && !R.isEmpty(url)) {
            url = R.head(url);
            paramObject = R.mergeLeft(paramObject, {requestHostname: url});
          }
        } else {
          paramObject = R.mergeLeft(paramObject, {
            requestHostname: window.origin,
          });
        }
        auth.service.send('TOKEN', {record: paramObject});
      },
      onError: assign({
        props: (context, event) =>
          R.assoc('apiError', event.data, context.props),
      }),
    },
    services: {
      apiVerifyEmail: context => {
        const sharedContext = context.shared as SharedContext;
        return client.mutate({
          mutation: UserModelControllerVerifyEmailDocument,
          variables: {
            verifyEmailInput: {
              email: sharedContext.email,
              verificationToken: sharedContext.secret,
              iv: sharedContext.iv,
              recaptcha: sharedContext.recaptcha,
              userId: parseInt(sharedContext.userId),
            },
          },
          errorPolicy: 'none',
        });
      },
      authStoreStream: () =>
        auth.state$.pipe(
          map(state => {
            if (state.matches('authenticated')) {
              return {
                type: 'SUCCESS',
              };
            }
            if (state.event.type === 'error.platform.apiLogin') {
              return {
                type: 'ERROR',
                data: state.event.data,
              };
            }
            return {
              type: '',
            };
          }),
        ),
    },
  });
  return container;
}
