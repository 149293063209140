import {createTheme, Theme} from '@mui/material/styles';
import {getDesignTokens} from './defaultTheme';
import lightTheme from './themes/generated/lightTheme.json';
import darkTheme from './themes/generated/darkTheme.json';
import waTheme from './themes/generated/waTheme.json';
import {AuthorisationDomains} from '@regulatory-platform/common-utils/dist';
import {DomainMapWithDefault} from 'containers-content';

const rawThemes = {
  light: lightTheme as unknown as Theme,
  dark: darkTheme as unknown as Theme,
  wa: waTheme as unknown as Theme,
};

const generateTheme = (themeName: string) => {
  return createTheme(
    getDesignTokens(rawThemes[themeName as keyof typeof rawThemes] as Theme),
  );
};

export const themes = {
  light: generateTheme('light'),
  dark: generateTheme('dark'),
  wa: generateTheme('wa'),
} as const;

export type ThemeKey = keyof typeof themes;

export const domainThemeMap: DomainMapWithDefault<
  AuthorisationDomains,
  ThemeKey
> = {
  wa: 'wa',
  adf: 'wa',
  default: 'light',
};
