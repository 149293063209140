import React, {FormEventHandler, ReactNode} from 'react';

import {
  jsonPointerResolver,
  ObjectType,
  schemaJsonPointerResolver,
} from '@regulatory-platform/common-utils';
import {useSelector} from '@xstate/react';
import * as R from 'ramda';
import isChanged from 'utils/stores/isChanged';
import isValid from 'utils/stores/isValid';
import {FormMachineService} from 'utils/stores/types';

import {
  DSP_LegacyFormProvider,
  DSP_LegacyFormProviderMock,
} from './LegacyFormProvider';
import {
  useFormContext,
  DSP_FormContextProps,
  DSP_FormContext,
} from './useFormContext';

export function useFormService(): DSP_FormContextProps['service'] {
  const {service} = useFormContext();
  return service;
}

export function useFormMachineSend() {
  const {service} = useFormContext();
  return service.send;
}

export function useFieldValue(fieldRef: string) {
  const {service} = useFormContext();
  return useSelector(
    service,
    state => {
      const {schema, record, shared} = state.context;
      const fieldSchema = schemaJsonPointerResolver(fieldRef)(schema);
      const isNewRecord = fieldRef.includes('/-1/');

      if (isNewRecord) {
        return fieldSchema?.['x-origValue'];
      }
      if (!fieldSchema && shared?.origRecord) {
        const origRecord = (shared as {origRecord: ObjectType}).origRecord;
        const origValue = jsonPointerResolver(fieldRef)(origRecord);
        return origValue;
      }
      return jsonPointerResolver(fieldRef)(record);
    },
    R.equals,
  );
}

export type DSP_FormProviderProps<
  T extends FormMachineService = FormMachineService,
> = {
  service: T;
  canSave?: boolean;
  isCreate?: boolean;
  submitConfirmMessage?: string;
  children?: ReactNode;
  otherProps?: {[key: string]: unknown};
  onSubmit?: FormEventHandler<HTMLFormElement>;
  /**
   * Enable the use of legacy form fields within this form. Defaults to `false`
   */
  legacySupport?: Boolean;
};

export const DSP_FormProvider = <
  T extends FormMachineService = FormMachineService,
>({
  service,
  children,
  otherProps,
  legacySupport,
  canSave = true,
  isCreate = false,
  submitConfirmMessage = 'Are you sure you want to submit this form?',
}: DSP_FormProviderProps<T>) => {
  const contextValues = useSelector(
    service,
    state => {
      const isAuthorisedUpdateById =
        state.context.authorisations?.updateById === true;

      const isCreating = state.matches('creating');

      const isSubmitting =
        state.matches('submitting') || state.matches('submitted');

      const isFinished = state.matches('finished');

      const readOnly = !isAuthorisedUpdateById || !canSave;

      const hasChangesToSave =
        !isCreating &&
        !isSubmitting &&
        !isFinished &&
        !readOnly &&
        isChanged(state.context);

      const isValidForm = isValid(state.context);

      const recordId = (state.context.record as {id?: number})?.id;

      return {
        isValidForm,
        readOnly,
        hasChangesToSave,
        service,
        otherProps,
        recordId,
        canSave,
        isCreate,
        submitConfirmMessage,
      };
    },
    R.equals,
  );

  const DSP_LegacyWrapper = legacySupport
    ? DSP_LegacyFormProvider
    : DSP_LegacyFormProviderMock;

  return (
    <DSP_FormContext.Provider
      value={contextValues}
      key={contextValues.recordId}
    >
      <DSP_LegacyWrapper
        key={contextValues.recordId}
        service={service}
        otherProps={otherProps}
        canSave={canSave}
      >
        {children}
      </DSP_LegacyWrapper>
    </DSP_FormContext.Provider>
  );
};

export {
  //

  /** @deprecated use DSP_FormProviderProps instead*/
  DSP_FormProviderProps as FormProviderProps, //
  /** @deprecated use DSP_FormProvider instead*/
  DSP_FormProvider as FormProvider,
};
