import {RefObject, useCallback, useState} from 'react';

import * as R from 'ramda';

import {useScrollObserver} from './useScrollObserver';
import {useSizeObserver} from './useSizeObserver';

export interface UseScrollableLayoutProps {
  ref: RefObject<HTMLElement>;
  enableListeners?: boolean;
  onScrollableLayoutChange?: (
    scrollAttributes: ScrollableLayoutAttributes,
  ) => void;
}

export interface ScrollableLayoutAttributes {
  isAtTopOfDiv: boolean;
  isAtBottomOfDiv: boolean;
  hasOverflowY: boolean;
  scrollHeight: number | undefined;
  clientHeight: number | undefined;
}

const recalculateScrollAttributes = (ref?: HTMLElement) => {
  const isAtTopOfDiv = ref ? ref?.scrollTop === 0 : true;
  const isAtBottomOfDiv = ref
    ? Math.round(ref?.scrollTop) >= ref.scrollHeight - ref.clientHeight
    : false;

  const hasOverflowY = ref ? ref?.scrollHeight > ref?.clientHeight : false;

  return {
    isAtTopOfDiv,
    isAtBottomOfDiv,
    hasOverflowY,
    scrollHeight: ref?.scrollHeight,
    clientHeight: ref?.clientHeight,
  };
};

export function useScrollableLayout({
  ref,
  enableListeners = true,
  onScrollableLayoutChange,
}: UseScrollableLayoutProps): ScrollableLayoutAttributes {
  const [scrollAttributes, setScrollAttributes] = useState(
    recalculateScrollAttributes(ref.current ?? undefined),
  );

  const onScroll = useCallback(
    (_ref: HTMLElement) => {
      const newScrollAttributes = recalculateScrollAttributes(_ref);

      if (!R.equals(scrollAttributes, newScrollAttributes)) {
        setScrollAttributes(newScrollAttributes);
        onScrollableLayoutChange?.(newScrollAttributes);
      }
    },
    [scrollAttributes, setScrollAttributes],
  );

  const onResize = useCallback(
    (_, _ref: HTMLElement) => {
      const newScrollAttributes = recalculateScrollAttributes(_ref);

      if (!R.equals(scrollAttributes, newScrollAttributes)) {
        setScrollAttributes(newScrollAttributes);
        onScrollableLayoutChange?.(newScrollAttributes);
      }
    },
    [scrollAttributes, setScrollAttributes],
  );

  useScrollObserver(ref, enableListeners ? onScroll : undefined);

  useSizeObserver({ref, onResize: enableListeners ? onResize : undefined});

  return scrollAttributes;
}
