import React from 'react';

import {DSL_List, DSL_ListProps} from '../../List';

export type DSL_PopperListProps = DSL_ListProps;

export const DSL_PopperList = React.forwardRef<
  HTMLUListElement,
  DSL_PopperListProps
  // eslint-disable-next-line react/prop-types
>(({internal_sx = [], disablePadding, ...props}: DSL_PopperListProps, ref) => {
  const enforceArray = (maybeArray: DSL_ListProps['internal_sx']) =>
    Array.isArray(maybeArray) ? maybeArray : [maybeArray];

  return (
    <DSL_List
      ref={ref}
      disablePadding
      internal_sx={[
        {pl: 3, pr: 3, pt: 2, pb: 2, ...(disablePadding && {p: 0})},
        ...enforceArray(internal_sx),
      ]}
      {...props}
    />
  );
});

DSL_PopperList.displayName = 'DSL_PopperList';

export {
  //
  /** @deprecated use DSL_PopperListProps instead*/
  DSL_PopperListProps as PopperListProps, //
  /** @deprecated use DSL_PopperList instead*/
  DSL_PopperList as PopperList,
};
