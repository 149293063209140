import React, {ReactNode} from 'react';

import {FormControl, FormHelperText, FormLabel} from '@mui/material';
import * as R from 'ramda';

import {Option} from '../../../types';
import {optionToLabel} from '../../utils/optionToLabel';
import {optionToValue} from '../../utils/optionToValue';
import {CheckboxInput, CheckboxInputProps} from '../CheckboxInput';

export interface CheckboxGroupInputProps extends CheckboxInputProps {
  options: readonly Option[];
  value: (string | number) | (string | number)[];
  helperText?: ReactNode;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CheckboxGroupInput = ({
  options,
  label,
  error,
  disabled,
  helperText,
  value,
  onChange,
  ...props
}: CheckboxGroupInputProps) => {
  const valueArray = (R.is(Array, value) ? value : [value]) as (
    | string
    | number
  )[];
  return (
    <FormControl fullWidth error={error} disabled={disabled}>
      {label && <FormLabel>{label}</FormLabel>}
      {options.map(option => (
        <CheckboxInput
          label={optionToLabel(option)}
          value={optionToValue(option)}
          key={optionToValue(option)}
          error={error}
          checked={valueArray.includes(optionToValue(option))}
          onChange={() => {
            const prevValue = optionToValue(option);
            const nextValue = valueArray.includes(optionToValue(option))
              ? R.reject(R.equals(prevValue), valueArray)
              : [...valueArray, prevValue];

            const event = {
              currentTarget: {
                value: nextValue,
              },
            } as unknown as React.ChangeEvent<HTMLInputElement>;

            onChange?.(event);
          }}
          disabled={disabled}
          {...props}
        />
      ))}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
