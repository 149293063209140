import React, {ForwardedRef, forwardRef, ReactNode} from 'react';

import {DSL_ButtonCardActionAreaProps} from '../../../Layout/Card/CardActionArea';
import {DSL_Body1} from '../../../Typography/Body1';
import {DSL_Heading4} from '../../../Typography/Heading4';
import {DSL_BaseCardInput, DSL_BaseCardInputProps} from '../BaseCardInput';
import {DSL_CheckboxInput, DSL_CheckboxInputProps} from '../CheckboxInput';

export interface DSL_CheckboxCardInputViewProps
  extends Omit<DSL_BaseCardInputProps, 'input' | 'children'>,
    DSL_CheckboxInputProps {
  label: ReactNode;
  onClickCard: DSL_ButtonCardActionAreaProps['onClick'];
  icon?: ReactNode;
  description?: ReactNode;
  subLabel?: ReactNode;
  describedBy?: string;
}

export const DSL_CheckboxCardInputView = forwardRef(
  (
    {
      checked,
      label,
      icon,
      description,
      subLabel,
      minHeight,
      height,
      maxHeight,
      error,
      disabled,
      onClickCard,
      describedBy,
      ...checkboxProps
    }: DSL_CheckboxCardInputViewProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    return (
      <DSL_BaseCardInput
        isHighlighted={checked}
        disabled={disabled}
        maxHeight={maxHeight}
        minHeight={minHeight}
        height={height}
        error={error}
        onClickCard={onClickCard}
      >
        {icon}
        <div>
          <DSL_CheckboxInput
            ref={ref}
            aria-describedby={describedBy}
            disabled={disabled}
            error={error}
            checked={checked}
            {...checkboxProps}
          />
        </div>
        <DSL_Heading4>{label}</DSL_Heading4>
        {subLabel}
        {description && <DSL_Body1>{description}</DSL_Body1>}
      </DSL_BaseCardInput>
    );
  },
);

DSL_CheckboxCardInputView.displayName = 'DSL_CheckboxCardInputView';
