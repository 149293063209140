import React from 'react';

import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  TextButton,
  CriticalActionButton,
} from 'components/DesignSystem/Library';

import {DSP_UseDeleteFileDialogResults} from './useDeleteFileDialog';

export type DSP_DeleteFileDialogProps = Omit<
  DSP_UseDeleteFileDialogResults,
  'handleDeleteDialogOpen'
>;

export const DSP_DeleteFileDialog = ({
  deleteDialogOpen,
  deleteDialogState,
  handleDeleteDialogClose,
  onDeleteDialogSubmit,
}: DSP_DeleteFileDialogProps) => {
  return (
    <Dialog
      open={deleteDialogOpen}
      onClose={handleDeleteDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Confirm'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {`Do you want to remove attachment '${deleteDialogState.file?.name}'?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <CriticalActionButton onClick={onDeleteDialogSubmit}>
          Remove
        </CriticalActionButton>
        <TextButton onClick={handleDeleteDialogClose} autoFocus>
          Cancel
        </TextButton>
      </DialogActions>
    </Dialog>
  );
};

export {
  //
  /** @deprecated use DSP_DeleteFileDialogProps instead*/
  DSP_DeleteFileDialogProps as DeleteFileDialogProps, //
  /** @deprecated use DSP_DeleteFileDialog instead*/
  DSP_DeleteFileDialog as DeleteFileDialog,
};
