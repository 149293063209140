import React from 'react';

import {AccreditationApplicationWithRelations} from 'typeDefs/types';
import {NotificationItem} from 'utils/machines/notificationsMachine';

import {DSP_AccreditationApplicationCustomerNotificationCardView} from './AccreditationApplicationCustomerNotificationCard.View';
import {useAccreditationApplicationCustomerNotificationCard} from './useAccreditationApplicationCustomerNotificationCard';

export interface DSP_AccreditationApplicationCustomerNotificationCardProps {
  notification: NotificationItem<AccreditationApplicationWithRelations>;
}

export const DSP_AccreditationApplicationCustomerNotificationCard = (
  props: DSP_AccreditationApplicationCustomerNotificationCardProps,
) => {
  const viewProps = useAccreditationApplicationCustomerNotificationCard(props);

  return (
    <DSP_AccreditationApplicationCustomerNotificationCardView {...viewProps} />
  );
};

DSP_AccreditationApplicationCustomerNotificationCard.displayName =
  'DSP_AccreditationApplicationCustomerNotificationCard';
