import React, {ReactNode, useEffect, useRef} from 'react';

import {Theme, useTheme} from '@mui/material';
import {DSL_MoreVertIconButton} from 'components/DesignSystem/Library/Buttons/IconButtons/MoreVertIconButton';
import {useBreakpoint} from 'components/DesignSystem/Library/hooks/useBreakpoint';
import {DSL_Menu, DSL_MenuItem} from 'components/DesignSystem/Library/Menus';

import {DSL_Box} from '../../Box';
import {DSL_WidthObservingBox} from '../../Box/WidthObservingBox';
import {DSL_Divider} from '../../Divider';
import {DSL_FlexRow} from '../../FlexRow';
import {DSL_Tab} from '../Tab';
import {DSL_TabContext} from '../TabContext';
import {DSL_TabList} from '../TabList';
import {DSL_TabPanel} from '../TabPanel';

export interface DSL_HorizontalTabsWithOverflowMenuProps {
  anchor?: 'top' | 'bottom';
  tabs: {label: string; component: ReactNode}[];
  value: string | number;
  onChange: (event: React.SyntheticEvent, value: string | number) => void;
  showTabComponents?: boolean;
  showTabs?: boolean;
}

export const DSL_HorizontalTabsWithOverflowMenu = ({
  anchor = 'bottom',
  tabs,
  value,
  onChange,
  showTabComponents = true,
  showTabs = true,
}: DSL_HorizontalTabsWithOverflowMenuProps) => {
  const theme: Theme = useTheme();

  const {isSm} = useBreakpoint();
  const [containerWidth, setContainerWidth] = React.useState<number>(0);
  const [tabWidths, setTabWidths] = React.useState<number[]>(
    new Array(tabs.length).fill(0),
  );

  const tabRefs = useRef<(HTMLDivElement | null)[]>(
    new Array(tabs.length).fill(null),
  );

  useEffect(() => {
    const newTabWidths = tabRefs.current.map(ref => ref?.offsetWidth || 0);
    setTabWidths(newTabWidths);
  }, [tabs.length, containerWidth]);

  const tabsToDisplay = tabWidths.reduce(
    (acc, width, index) => {
      if (!containerWidth || !width) {
        return acc;
      }
      const tabsWidth = acc.tabsWidth + width;
      const isThisTabFittingInsideTheContainer = tabsWidth < containerWidth;
      const menuIconWidth = parseInt(theme.spacing(3));
      const hasEnoughRoomForMenuIcon =
        tabsWidth + menuIconWidth < containerWidth;

      if (isThisTabFittingInsideTheContainer) {
        const nextCount = hasEnoughRoomForMenuIcon ? index + 1 : index;

        return {count: nextCount, tabsWidth};
      }
      return acc;
    },
    {count: 0, tabsWidth: 0},
  ).count;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <DSL_Box width={'100%'}>
      <DSL_TabContext value={value}>
        {showTabs && (
          <>
            <DSL_WidthObservingBox
              display={'flex'}
              onWidthChange={setContainerWidth}
              width={'100%'}
              gap={0}
            >
              <DSL_TabList indicatorAnchor={anchor} onChange={onChange}>
                <DSL_FlexRow
                  visibility={'hidden'}
                  position={'absolute'}
                  zIndex={-1}
                  gap={0}
                >
                  {tabs.map((tab, index) => (
                    <DSL_Tab
                      textTransform="none"
                      key={index}
                      data-name={`horizontal-tabs-with-overflow-menu-tab-${tab.label}`}
                      label={tab.label}
                      value={tab.label}
                      ref={el => (tabRefs.current[index] = el)}
                    />
                  ))}
                </DSL_FlexRow>
                {!!containerWidth &&
                  tabs.map(
                    (tab, index) =>
                      index < tabsToDisplay && (
                        <DSL_Tab
                          textTransform="none"
                          key={index}
                          label={tab.label}
                          value={tab.label}
                        />
                      ),
                  )}
              </DSL_TabList>
              {!!containerWidth && tabs.length > tabsToDisplay && (
                <>
                  <DSL_FlexRow alignItems={'center'}>
                    <DSL_Box width={6}>
                      <DSL_MoreVertIconButton
                        aria-label="more"
                        id="tabs-overflow-button"
                        aria-controls={open ? 'tabs-overflow-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                      />
                    </DSL_Box>
                  </DSL_FlexRow>
                  <DSL_Menu
                    id="tabs-overflow-menu"
                    MenuListProps={{
                      'aria-labelledby': 'tabs-overflow-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    {tabs.map(
                      (tab, index) =>
                        index >= tabsToDisplay && (
                          <DSL_MenuItem
                            key={tab.label}
                            selected={tab.label === value}
                            onClick={() => {
                              handleClose();
                              onChange({} as React.SyntheticEvent, tab.label);
                            }}
                          >
                            {tab.label}
                          </DSL_MenuItem>
                        ),
                    )}
                  </DSL_Menu>
                </>
              )}
            </DSL_WidthObservingBox>
            {!isSm && <DSL_Divider />}
          </>
        )}

        {showTabComponents &&
          tabs.map((tab, index) => (
            <DSL_TabPanel key={index} value={tab.label} removeMargin>
              {tab.component}
            </DSL_TabPanel>
          ))}
      </DSL_TabContext>
    </DSL_Box>
  );
};
