import React from 'react';

import {
  DSL_PageHeaderProps,
  DSL_PageHeader,
  DSL_Breadcrumb,
  DSL_BreadcrumbProps,
} from 'components/DesignSystem/Library';

import {useAuthenticatedPageHeader} from './useAuthenticatedPageHeader';

export interface DSP_AuthenticatedPageHeadingProps
  extends Omit<DSL_PageHeaderProps, 'breadcrumbs'> {
  breadcrumbs?: DSL_BreadcrumbProps[];
  activeBreadcrumbLabel?: string;
  hideBreadcrumbs?: boolean;
}

export const DSP_AuthenticatedPageHeading = ({
  activeBreadcrumbLabel,
  hideBreadcrumbs,
  breadcrumbs: overrideBreadcrumbs,
  ...props
}: DSP_AuthenticatedPageHeadingProps) => {
  const {breadcrumbs} = useAuthenticatedPageHeader(
    overrideBreadcrumbs,
    activeBreadcrumbLabel,
  );

  return (
    <DSL_PageHeader
      breadcrumbs={
        hideBreadcrumbs
          ? []
          : breadcrumbs.map((breadcrumb, index) => (
              <DSL_Breadcrumb {...breadcrumb} key={index} />
            ))
      }
      {...props}
    />
  );
};

DSP_AuthenticatedPageHeading.displayName = 'DSP_AuthenticatedPageHeading';

export {
  //
  /** @deprecated use DSP_AuthenticatedPageHeadingProps instead*/
  DSP_AuthenticatedPageHeadingProps as AuthenticatedPageHeadingProps, //
  /** @deprecated use DSP_AuthenticatedPageHeading instead*/
  DSP_AuthenticatedPageHeading as AuthenticatedPageHeading,
};
