import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import GroupIcon from '@mui/icons-material/Group';
export default {
  path: '/customers',
  component: lazy(
    () => import(/* webpackChunkName: "CustomerAccount" */ './CustomerAccount'),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'Customer accounts',
  menuIcon: GroupIcon,
  authorisation: {
    apiKey: null,
    policies: [{role: 'operationsAccountRead'}] as AuthorisationPolicy[],
  },
  sections: [],
};
