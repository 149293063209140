import React from 'react';

import {DSL_CloseIconButton} from '../../Buttons/IconButtons/CloseIconButton';
import {DSL_BaseAlert, DSL_BaseAlertProps} from '../BaseAlert';
import {DSL_GlobalErrorAlertProps} from '../GlobalErrorAlert';

export interface DSL_DismissibleGlobalAlertViewProps
  extends Omit<DSL_GlobalErrorAlertProps, 'action'> {
  isOpen: boolean;
  onClose: () => void;
  severity: DSL_BaseAlertProps['severity'];
}

export const DSL_DismissibleGlobalAlertView = ({
  isOpen,
  onClose,
  ...props
}: DSL_DismissibleGlobalAlertViewProps) => {
  return (
    <>
      {isOpen && (
        <DSL_BaseAlert
          action={
            <DSL_CloseIconButton
              size={'small'}
              onClick={onClose}
              aria-label={'Dismiss alert'}
            />
          }
          {...props}
        />
      )}
    </>
  );
};

DSL_DismissibleGlobalAlertView.displayName = 'DSL_DismissibleGlobalAlertView';
