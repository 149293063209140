import {lazy} from 'react';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/specifications/vehicleConfirmation/:id',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "CertificationConfirmation" */ './CertificationConfirmation'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Vehicle certified',
  menuIcon: LocalShippingOutlinedIcon,
  authorisation: {
    apiKey: 'VehicleRegistrationExtendedWithRelations.findById',
    policies: [
      {
        role: 'operationsRegistrationEnabled',
      },
      {
        role: 'partnerRegistrationEnabled',
      },
      {
        role: 'providerRegistrationEnabled',
        accountSubType: 'Manufacturer',
      },
      {
        role: 'providerPBSRead',
        accountSubType: 'PBS Certifier',
      },
      {
        role: 'customerRegistrationEnabled',
      },
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Vehicle Specifications',
      to: '../..',
    },
  ],
};
