import React, {ReactNode} from 'react';

import {DSL_Box} from '../../Layout/Box';
import {DSL_FlexColumn} from '../../Layout/FlexColumn';
import {DSL_FlexRow} from '../../Layout/FlexRow';
import {DSL_BaseTypography, DSL_BaseTypographyProps} from '../BaseTypography';
import {usePreviewTypography} from '../Preview/usePreviewTypography';

export interface DSL_TruncateTypographyProps extends DSL_BaseTypographyProps {
  showMoreLabel?: ReactNode;
}

export const DSL_TruncateTypography = ({
  ellipsisLines = 4,
  showMoreLabel = 'MORE',
  ...props
}: DSL_TruncateTypographyProps) => {
  const {previewRef, overflowRef, previewHeight, hasOverflowContents} =
    usePreviewTypography();

  return (
    <DSL_FlexColumn position={'relative'}>
      <DSL_Box
        overflow={'hidden'}
        maxHeight={previewHeight}
        position={'relative'}
      >
        <DSL_Box
          ref={overflowRef}
          visibility={'hidden'}
          position={'absolute'}
          aria-hidden={true}
          tabIndex={-1}
          internal_sx={{
            userSelect: 'none',
            pointerEvents: 'none',
          }}
        >
          <DSL_BaseTypography {...props} />
        </DSL_Box>
        <DSL_Box ref={previewRef}>
          <DSL_BaseTypography ellipsisLines={ellipsisLines} {...props} />
        </DSL_Box>
      </DSL_Box>
      {hasOverflowContents && (
        <DSL_FlexRow width={'100%'} justifyContent={'flex-end'}>
          <DSL_BaseTypography variant={props.variant} color={'primary'}>
            {showMoreLabel}
          </DSL_BaseTypography>
        </DSL_FlexRow>
      )}
    </DSL_FlexColumn>
  );
};

DSL_TruncateTypography.displayName = 'DSL_TruncateTypography';
