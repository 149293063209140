import React from 'react';
import {GetContentOptions} from '../types';
import {AuthorisationDomains} from '@regulatory-platform/common-utils/dist';
import {i18n, ContentKeys} from 'containers-content';

export interface ContentProps
  extends Omit<GetContentOptions<AuthorisationDomains, ContentKeys>, 'key'> {
  cKey: ContentKeys;
}

export const Content = (props: ContentProps) => (
  <>{i18n.get({...props, key: props.cKey})}</>
);

Content.displayName = 'Content';
