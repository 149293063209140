import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';

export default {
  path: '/afmDocumentTemplates/create',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "AfmCreateDocumentTemplate" */ './AfmCreateDocumentTemplate'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Create AFM Document Template',
  menuIcon: LocalShippingOutlinedIcon,
  authorisation: {
    apiKey: null,
    policies: [{role: 'operationsAccreditationAdmin'}] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'AFM Document Templates',
      to: '..',
    },
  ],
};
