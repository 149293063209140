import {DSP_AccreditationActionNotificationItem} from '../types';

import {DSP_AccreditationActionCustomerNotificationCardViewProps} from './AccreditationActionCustomerNotificationCard.View';

export interface DSP_UseAccreditationActionNotificationCardProps {
  notification: DSP_AccreditationActionNotificationItem;
}

export function useAccreditationActionCustomerNotificationCard({
  notification,
}: DSP_UseAccreditationActionNotificationCardProps): DSP_AccreditationActionCustomerNotificationCardViewProps {
  return {
    notification,
    userId: notification.fromUserId ?? undefined,
  };
}
