import {
  CommonValidator,
  CommonValidatorResult,
  insertNewArrayItemIntoSchema,
  jsonPointerResolver,
  JSONSchemaRecord,
  ValueType,
  removeArrayItemFromSchema,
  schemaJsonPointerResolver,
  updateFieldPropOnSubSchema,
  validatorExec,
  ObjectType,
} from '@regulatory-platform/common-utils';
import * as R from 'ramda';
import {FormEventObject, FormMachineContext, FormRecord} from './types';

export default function onInsertNewArrayItemsIntoSchema<T extends FormRecord>(
  context: FormMachineContext<T>,
  event: FormEventObject,
  validator: CommonValidator,
): FormMachineContext<T> {
  const {fieldRef, records: _fieldRecords} = event;
  const fieldRecords = R.defaultTo([], _fieldRecords) as ObjectType[];

  const {record: origRecord, shared} = context;

  let {schema: origSchema} = context;

  origSchema = updateFieldPropOnSubSchema(
    origSchema,
    fieldRef + '/-1',
    'x-valid/touched',
    false,
    true,
  )(origSchema);
  origSchema = updateFieldPropOnSubSchema(
    origSchema,
    fieldRef + '/-1',
    'x-origValue',
    undefined,
    true,
  )(origSchema);

  let nextModel = {
    processedRecord: origRecord,
    processedSchema: origSchema,
  } as CommonValidatorResult;

  if (fieldRecords.length > 0) {
    //check for empty record and whether to remove
    const origFieldRecords =
      (jsonPointerResolver(fieldRef)(origRecord) as {
        [key: string]: ValueType;
      }[]) ?? [];

    const emptyRecordIndexes = origFieldRecords
      .reduce((acc, record, index) => {
        if (
          R.isEmpty(
            R.keys(record).filter(fieldName => {
              if (fieldName) {
                return (
                  record?.[fieldName] &&
                  !R.isNil(record?.[fieldName]) &&
                  !R.isEmpty(record?.[fieldName])
                );
              }
            }),
          )
        ) {
          acc.push(index);
        }
        return acc;
      }, [] as number[])
      .reverse();
    emptyRecordIndexes.forEach(index => {
      const [record, schema] = removeArrayItemFromSchema(fieldRef, index)(
        nextModel.processedRecord,
        nextModel.processedSchema,
      );
      nextModel = {
        processedRecord: record,
        processedSchema: schema,
      };
    });
  }

  nextModel = R.reduce(
    (accNextModel, fieldRecord) => {
      const [record, schema] = insertNewArrayItemIntoSchema(
        fieldRef,
        fieldRecord,
      )(accNextModel.processedRecord, accNextModel.processedSchema);
      return {
        processedRecord: record,
        processedSchema: schema,
      };
    },
    nextModel,
    fieldRecords,
  );

  const result = validatorExec(
    {
      record: nextModel.processedRecord,
      origRecord,
      schema: nextModel.processedSchema,
      isClient: true,
      methodName: context.methodName,
      userProfile: context.authorisations?.userProfile,
      shared: R.mergeRight(R.defaultTo({}, shared), {
        fieldRef,
        //flag for record imports to run validation on all rows
        arrayUpdate: true,
      }),
    },
    validator,
  );

  let validatedSchema = result?.validatedSchema as JSONSchemaRecord;
  //set validation flags to true for new record if original array was valid
  if (
    schemaJsonPointerResolver<boolean | undefined>(
      fieldRef,
      'x-valid/touched',
    )(nextModel.processedSchema) === true
  ) {
    validatedSchema = updateFieldPropOnSubSchema(
      validatedSchema,
      fieldRef,
      'x-valid/touched',
      true,
      false,
    )(validatedSchema);
  }

  const nextContext = context;
  if (context.record !== result?.processedRecord) {
    nextContext.record = result?.processedRecord as T;
  }
  if (!R.isNil(result) && context.schema !== validatedSchema) {
    nextContext.schema = validatedSchema;
  }
  if (!R.isNil(context.props) && !R.isNil(context.props.apiError)) {
    nextContext.props.apiError = undefined;
  }
  return nextContext;
}
