import React from 'react';

import {
  IconButtonProps as MuiIconButtonProps,
  IconButton as MuiIconButton,
} from '@mui/material';
import {DSL_Tooltip} from 'components/DesignSystem/Library/Forms/Tooltip';
import {DSL_Box} from 'components/DesignSystem/Library/Layout/Box';

import {DSL_RestrictedVisualProps} from '../../../types';

export interface DSL_BaseIconButtonProps
  extends Omit<MuiIconButtonProps, DSL_RestrictedVisualProps> {
  disableRipple?: boolean;
}

export const DSL_BaseIconButton = ({
  title,
  ...props
}: DSL_BaseIconButtonProps): JSX.Element => {
  if (title) {
    return (
      <DSL_Tooltip isClickable title={title}>
        <DSL_Box>
          <MuiIconButton {...props} />
        </DSL_Box>
      </DSL_Tooltip>
    );
  }
  return <MuiIconButton {...props} />;
};

DSL_BaseIconButton.displayName = 'DSL_BaseIconButton';

export {
  //
  /** @deprecated use DSL_BaseIconButtonProps instead*/
  DSL_BaseIconButtonProps as BaseIconButtonProps, //
  /** @deprecated use DSL_BaseIconButton instead*/
  DSL_BaseIconButton as BaseIconButton,
};
