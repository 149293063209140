import * as Types from '../../../typeDefs/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PubSubTokenControllerGeneratePubSubTokenMutationVariables = Types.Exact<{
  pubSubTokenRequestInput: Types.PubSubTokenRequestInput;
}>;


export type PubSubTokenControllerGeneratePubSubTokenMutation = { __typename?: 'Mutation', pubSubTokenControllerGeneratePubSubToken?: { __typename?: 'PubSubToken', token: string, baseUrl: string, url: string } | null };


export const PubSubTokenControllerGeneratePubSubTokenDocument = gql`
    mutation pubSubTokenControllerGeneratePubSubToken($pubSubTokenRequestInput: PubSubTokenRequestInput!) {
  pubSubTokenControllerGeneratePubSubToken(
    pubSubTokenRequestInput: $pubSubTokenRequestInput
  ) {
    token
    baseUrl
    url
  }
}
    `;
export type PubSubTokenControllerGeneratePubSubTokenMutationFn = Apollo.MutationFunction<PubSubTokenControllerGeneratePubSubTokenMutation, PubSubTokenControllerGeneratePubSubTokenMutationVariables>;

/**
 * __usePubSubTokenControllerGeneratePubSubTokenMutation__
 *
 * To run a mutation, you first call `usePubSubTokenControllerGeneratePubSubTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePubSubTokenControllerGeneratePubSubTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pubSubTokenControllerGeneratePubSubTokenMutation, { data, loading, error }] = usePubSubTokenControllerGeneratePubSubTokenMutation({
 *   variables: {
 *      pubSubTokenRequestInput: // value for 'pubSubTokenRequestInput'
 *   },
 * });
 */
export function usePubSubTokenControllerGeneratePubSubTokenMutation(baseOptions?: Apollo.MutationHookOptions<PubSubTokenControllerGeneratePubSubTokenMutation, PubSubTokenControllerGeneratePubSubTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PubSubTokenControllerGeneratePubSubTokenMutation, PubSubTokenControllerGeneratePubSubTokenMutationVariables>(PubSubTokenControllerGeneratePubSubTokenDocument, options);
      }
export type PubSubTokenControllerGeneratePubSubTokenMutationHookResult = ReturnType<typeof usePubSubTokenControllerGeneratePubSubTokenMutation>;
export type PubSubTokenControllerGeneratePubSubTokenMutationResult = Apollo.MutationResult<PubSubTokenControllerGeneratePubSubTokenMutation>;
export type PubSubTokenControllerGeneratePubSubTokenMutationOptions = Apollo.BaseMutationOptions<PubSubTokenControllerGeneratePubSubTokenMutation, PubSubTokenControllerGeneratePubSubTokenMutationVariables>;