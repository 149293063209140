import React, {ReactElement} from 'react';

import {Stack, StackProps} from '@mui/material';

import {DSL_RestrictedVisualProps} from '../../types';

export interface DSL_BaseStackProps
  extends Omit<StackProps, DSL_RestrictedVisualProps> {
  // The MUI stack component incorrectly accepts a string value (via ReactNode type) for divider which causes an error
  // at runtime, this property narrows the type to only accept ReactElements.
  divider?: ReactElement;
}

export const DSL_BaseStack = (props: DSL_BaseStackProps) => {
  return <Stack {...props} />;
};

DSL_BaseStack.displayName = 'DSL_BaseStack';

export {
  //
  /** @deprecated use DSL_BaseStackProps instead*/
  DSL_BaseStackProps as BaseStackProps, //
  /** @deprecated use DSL_BaseStack instead*/
  DSL_BaseStack as BaseStack,
};
