import {useRef} from 'react';

import {useKeyLineBorder} from '../../../hooks/useKeyLineBorder';
import {
  useScrollableLayout,
  UseScrollableLayoutProps,
} from '../../../hooks/useScrollableLayout';
import {
  DSL_ScrollableLayoutSize,
  useScrollableLayoutPadding,
} from '../useScrollableLayoutPadding';

export interface DSL_UserScrollableLayoutBodyProps
  extends Omit<UseScrollableLayoutProps, 'ref'> {
  padding?: DSL_ScrollableLayoutSize;
  alwaysShowBorder?: boolean;
}

export function useScrollableLayoutBody({
  padding = 'SM',
  alwaysShowBorder: alwaysShow = false,
  onScrollableLayoutChange,
}: DSL_UserScrollableLayoutBodyProps) {
  const ref = useRef<HTMLDivElement>(null);
  const {isAtTopOfDiv, hasOverflowY, isAtBottomOfDiv} = useScrollableLayout({
    ref,
    enableListeners: !alwaysShow,
    onScrollableLayoutChange,
  });
  const borderStyle = useKeyLineBorder({
    isAtTopOfDiv,
    isAtBottomOfDiv,
    hasOverflowY,
    alwaysShow,
  });
  const paddingStyle = useScrollableLayoutPadding(padding);

  return {
    paddingStyle,
    borderStyle,
    refContentDiv: ref,
    isAtTopOfDiv,
    hasOverflowY,
  };
}
