import {UserModelInAccountPublic} from 'typeDefs/types';

export interface DSP_UseUserEntityMapperProps {
  user: UserModelInAccountPublic | null | undefined;
  nameVariant?: 'FIRST' | 'FULL';
}

export function useUserEntityMapper({
  nameVariant = 'FULL',
  user,
}: DSP_UseUserEntityMapperProps) {
  const orgTitle: string[] = [];

  if (user?.position) {
    orgTitle.push(user.position);
  }

  if (user?.division) {
    orgTitle.push(user.division);
  }

  return {
    title:
      nameVariant === 'FIRST'
        ? user?.firstName ?? ''
        : `${user?.firstName ?? ''} ${user?.name ?? ''}`,
    subTitle: orgTitle.join(', '),
    email: user?.email,
    phone: user?.phoneNumbers?.[0]?.phoneNumber,
    src: user?.fileList?.userImageUrl,
  };
}
