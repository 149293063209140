import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';

export default {
  path: '/assessors/create',
  component: lazy(
    () => import(/* webpackChunkName: "CreateAssessor" */ './CreateAssessor'),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Add PBS Assessor',
  menuIcon: LocalShippingOutlinedIcon,
  authorisation: {
    apiKey: 'PbsAssessorWithRelations.create',
    policies: [{role: 'operationsPBSUser'}] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'PBS Assessors',
      to: '..',
    },
  ],
};
