import React from 'react';
import clsx from 'clsx';
import BaseButton from '../BaseButton';
import {useStyles} from '../../form.styles';
import {ButtonProps} from '../types';

/**
 * Link Button
 */
const TextButton: React.FC<ButtonProps> = (props: ButtonProps) => {
  const classes = useStyles();
  const {children, formMargin, rightAlign, ...otherProps} = props;

  return (
    <BaseButton
      color="primary"
      disableElevation={true}
      className={clsx(
        classes.button,
        formMargin ? classes.formMargin : '',
        rightAlign ? classes.rightAlign : '',
      )}
      {...otherProps}
    >
      {children}
    </BaseButton>
  );
};

export default TextButton;
