import React, {ReactNode} from 'react';

import {useMediaQuery} from '@mui/material';
import {useWindowSize} from 'usehooks-ts';

import {DSL_FlexColumn} from '../FlexColumn';

export interface DSL_FullHeightColumnPageLayoutProps {
  printMinWidth?: string;
  printHeight?: string;
  children?: ReactNode;
}

export const DSL_FullHeightColumnPageLayout = ({
  printHeight,
  printMinWidth,
  children,
}: DSL_FullHeightColumnPageLayoutProps) => {
  const isPrint = useMediaQuery('print');
  // ⚠️ NOTE: We use useWindowSize rather than a simpler value of '100vh' because 100vh does not work well with mobile safari.
  // DO NOT change this without testing national maps thoroughly on mobile.
  const {height} = useWindowSize();
  return (
    <DSL_FlexColumn
      gap={0}
      flexWrap={'nowrap'}
      minWidth={isPrint && printMinWidth ? printMinWidth : undefined}
      height={isPrint && printHeight ? printHeight : height}
      color={'text.primary'}
      data-name={'FullHeightColumnPageLayout'}
    >
      {children}
    </DSL_FlexColumn>
  );
};

export {
  //
  /** @deprecated use DSL_FullHeightColumnPageLayoutProps instead*/
  DSL_FullHeightColumnPageLayoutProps as FullHeightColumnPageLayoutProps, //
  /** @deprecated use DSL_FullHeightColumnPageLayout instead*/
  DSL_FullHeightColumnPageLayout as FullHeightColumnPageLayout,
};
