import React from 'react';

import {
  DSP_PartnerAccountEntityAvatarWithName,
  DSP_PartnerAccountEntityLoaderProvider,
} from 'components/DesignSystem/Portal/Entity/AccountEntity/PartnerEntity';

import {
  DSP_BaseAccessProductChangeSetNotificationCard,
  DSP_BaseAccessProductChangeSetNotificationCardProps,
} from '../Base/BaseAccessProductChangeSetNotificationCard.Connector';

export interface DSP_AccessProductChangeSetOperationsNotificationCardViewProps
  extends Omit<DSP_BaseAccessProductChangeSetNotificationCardProps, 'avatar'> {
  partnerAccountId: number | undefined | null;
}

export const DSP_AccessProductChangeSetOperationsNotificationCardView = ({
  notification,
  partnerAccountId,
}: DSP_AccessProductChangeSetOperationsNotificationCardViewProps) => {
  return (
    <DSP_BaseAccessProductChangeSetNotificationCard
      notification={notification}
      avatar={
        partnerAccountId && (
          <DSP_PartnerAccountEntityLoaderProvider entityId={partnerAccountId}>
            <DSP_PartnerAccountEntityAvatarWithName />
          </DSP_PartnerAccountEntityLoaderProvider>
        )
      }
    />
  );
};

DSP_AccessProductChangeSetOperationsNotificationCardView.displayName =
  'DSP_AccessProductChangeSetOperationsNotificationCardView';

export {
  //
  /** @deprecated use DSP_AccessProductChangeSetOperationsNotificationCardViewProps instead*/
  DSP_AccessProductChangeSetOperationsNotificationCardViewProps as AccessProductChangeSetOperationsNotificationCardViewProps, //
  /** @deprecated use DSP_AccessProductChangeSetOperationsNotificationCardView instead*/
  DSP_AccessProductChangeSetOperationsNotificationCardView as AccessProductChangeSetOperationsNotificationCardView,
};
