import React from 'react';

import {
  MenuItem as MuiMenuItem,
  MenuItemProps as MuiMenuItemProps,
} from '@mui/material';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

export type DSL_MenuItemProps = Omit<
  MuiMenuItemProps,
  DSL_RestrictedVisualProps
>;

export const DSL_MenuItem = (props: DSL_MenuItemProps) => {
  return <MuiMenuItem {...props} />;
};

DSL_MenuItem.displayName = 'DSL_MenuItem';

export {
  //
  /** @deprecated use DSL_MenuItemProps instead*/
  DSL_MenuItemProps as MenuItemProps, //
  /** @deprecated use DSL_MenuItem instead*/
  DSL_MenuItem as MenuItem,
};
