import {useCallback, useEffect} from 'react';

import {FetchResult} from '@apollo/client';
import {SelectChangeEvent} from '@mui/material';
import {
  ObjectType,
  jsonPointerResolver,
} from '@regulatory-platform/common-utils';
import * as R from 'ramda';

import {RelatedRecord} from 'components/DesignSystem/Library';

import {useFormContext} from '../FormProvider';
import {useFormFieldPropsSelector} from '../hooks';
import {getGenericFieldProps} from '../utils/getGenericFieldProps';

import {DSP_SelectRelationBoxViewProps} from './SelectRelationBox.View';

export interface DSP_UseSelectRelationBoxProps
  extends DSP_SelectRelationBoxViewProps {
  fieldRef: string;
  fieldRelationRef?: string;
  recordsLoaderRef: string;
  emptyRecord?: RelatedRecord;
  items?: RelatedRecord[];
  disabled?: boolean;
  onChangeBlurEventType?: string;
  queryVariables?: {[key: string]: string | number | ObjectType};
  hideLabel?: boolean;
  isUserInput?: boolean;
  includeOptionalSuffix?: boolean;
  clientSideFilter?: (d: FetchResult) => FetchResult;
  hideOnNoItems?: boolean;
}

export const useSelectRelationBox = ({
  fieldRef,
  fieldRelationRef,
  recordsLoaderRef,
  emptyRecord,
  items,
  disabled,
  onChangeBlurEventType,
  queryVariables,
  label,
  hideLabel,
  isUserInput = false,
  includeOptionalSuffix,
  clientSideFilter,
  hideOnNoItems,
  ...restProps
}: DSP_UseSelectRelationBoxProps) => {
  const {service} = useFormContext();

  // if there's no fieldRelationRef, then trigger the loader ref immediately in order to populate the value
  useEffect(() => {
    if (recordsLoaderRef && !fieldRelationRef) {
      send('TRIGGER_ACTOR_LOAD', {
        fieldRef: recordsLoaderRef,
        queryVariables,
        clientSideFilter,
      });
    }
  }, []);

  const stateDerivedProps = useFormFieldPropsSelector(
    fieldRef,
    service,
    state => {
      const genericProps = getGenericFieldProps({
        fieldRef,
        state,
        hideLabel,
        label,
        includeOptionalSuffix,
      });

      let _items = R.defaultTo(
        R.defaultTo([], state.context?.shared?.[recordsLoaderRef]),
        items,
      ) as RelatedRecord[];

      let fieldRelationValue = undefined as RelatedRecord | undefined;
      const isLoading =
        state.context.actors[recordsLoaderRef]?.state.matches('loading');
      const origRecord = state.context.shared?.origRecord as RelatedRecord;
      const origValue = jsonPointerResolver(fieldRef)(origRecord) as
        | RelatedRecord
        | undefined;
      const hideComponent =
        hideOnNoItems && (isLoading || _items?.length === 0) && !origValue;
      const _isUserInput = state.matches('userInput') || isUserInput;

      if (fieldRelationRef) {
        //always use orignRecord to avoid protected fields filtering
        fieldRelationValue = jsonPointerResolver(fieldRelationRef)(
          origRecord || state.context.record,
        ) as RelatedRecord | undefined;
        if (R.isEmpty(_items) && !R.isNil(fieldRelationValue)) {
          _items = [fieldRelationValue];
        }
        //push the value to the items list if it's not there
        if (
          !R.isNil(fieldRelationValue) &&
          !_items.find(item => item.id === fieldRelationValue?.id)
        ) {
          _items = R.prepend(fieldRelationValue, _items);
        }
      }
      if (R.isNil(fieldRelationValue)) {
        // if there's no fieldRelationRef, let's try to find the item from the loaded list
        fieldRelationValue = R.find(
          item => item.id === genericProps.value,
          _items,
        );
      }
      const value = fieldRelationValue
        ? R.defaultTo('', genericProps.value as RelatedRecord | '' | undefined)
        : fieldRelationValue || '';

      const hasEmptyRecordConflict = !!_items.find(
        item => item.id === emptyRecord?.id,
      );
      const itemsWithOptionalEmptyRecord =
        R.isNil(emptyRecord) || hasEmptyRecordConflict
          ? _items
          : R.prepend(emptyRecord, _items);

      return {
        ...genericProps,
        value,
        loading: isLoading,
        hideComponent,
        itemsWithOptionalEmptyRecord,
        disabled: disabled || isLoading || !_isUserInput,
        items:
          R.length(itemsWithOptionalEmptyRecord) === 0 &&
          !R.isNil(fieldRelationValue)
            ? ([fieldRelationValue] as RelatedRecord[])
            : itemsWithOptionalEmptyRecord,
        ...restProps,
      };
    },
  );
  const {itemsWithOptionalEmptyRecord, ...restStateDerivedProps} =
    stateDerivedProps;

  const send = service?.send;

  const handleChange = useCallback(
    (event: SelectChangeEvent<unknown>): void => {
      if (R.length(itemsWithOptionalEmptyRecord) === 0) {
        return;
      }
      const _value = event.target.value === '' ? null : event.target.value;
      if (R.isNil(_value)) {
        send({
          type: 'CHANGE',
          fieldRef,
          value: null,
          fieldRefRelation: fieldRelationRef,
          valueRelation: emptyRecord || null,
        });
        return;
      }
      const relatedRecord = R.find((rr: RelatedRecord) => {
        return rr.id === _value;
      }, itemsWithOptionalEmptyRecord);
      if (R.isNil(relatedRecord)) {
        return;
      }
      send({
        type: onChangeBlurEventType ? onChangeBlurEventType : 'CHANGEBLUR',
        fieldRef,
        value: relatedRecord.id,
        fieldRefRelation: fieldRelationRef,
        valueRelation: relatedRecord,
      });
    },
    [
      itemsWithOptionalEmptyRecord,
      fieldRef,
      fieldRelationRef,
      onChangeBlurEventType,
      send,
    ],
  );

  const handleOpen = useCallback(() => {
    if (!R.isNil(recordsLoaderRef) && !hideOnNoItems) {
      send('TRIGGER_ACTOR_LOAD', {
        fieldRef: recordsLoaderRef,
        queryVariables,
        clientSideFilter,
      });
    }
  }, [send, recordsLoaderRef, queryVariables]);

  const handleBlur = useCallback(() => {
    send({type: 'BLUR', fieldRef});
  }, [send, fieldRef]);

  return {
    ...restStateDerivedProps,
    id: R.replace(/\//g, '-', R.replace(/#\//g, '', fieldRef)),
    onChange: handleChange,
    onBlur: handleBlur,
    onOpen: handleOpen,
  };
};
