import React from 'react';

import {
  DSL_ScrollableLayoutHeader,
  DSL_ScrollableLayoutHeaderProps,
} from '../../ScrollableLayout';

export type DSL_BasePopperHeaderProps = DSL_ScrollableLayoutHeaderProps;

export const DSL_BasePopperHeader = (props: DSL_BasePopperHeaderProps) => {
  return <DSL_ScrollableLayoutHeader {...props} />;
};

DSL_BasePopperHeader.displayName = 'DSL_BasePopperHeader';
