import React from 'react';

import {DSP_FormField} from '../FormField';
import {DSP_UseFormFieldProps} from '../useFormField';

import {useTableFormField} from './useTableFormField';

export type DSP_TableFormFieldProps = DSP_UseFormFieldProps;

export const DSP_TableFormField = (props: DSP_TableFormFieldProps) => {
  const viewProps = useTableFormField(props);

  return <DSP_FormField {...viewProps} />;
};

DSP_TableFormField.displayName = 'DSP_TableFormField';
