import React, {ReactNode} from 'react';

import {DSL_Tooltip} from '../Forms/Tooltip';
import {DSL_IconProps} from '../Icons/types';
import {DSL_Box} from '../Layout/Box';

export type DSL_ProgressBarSegmentProps = {
  flex: number;
  label: ReactNode;
  toolTip?: string;
  color: DSL_IconProps['htmlColor'];
};
export const DSL_ProgressBarSegment = ({
  flex,
  color,
  toolTip,
}: DSL_ProgressBarSegmentProps) => {
  if (!toolTip)
    return <DSL_Box flex={flex} bgcolor={color} height="100%" minWidth="8px" />;
  return (
    <DSL_Tooltip title={toolTip} arrow>
      <DSL_Box flex={flex} bgcolor={color} height="100%" minWidth="8px" />
    </DSL_Tooltip>
  );
};

export {
  //
  /** @deprecated use DSL_ProgressBarSegmentProps instead*/
  DSL_ProgressBarSegmentProps as ProgressBarSegmentProps, //
  /** @deprecated use DSL_ProgressBarSegment instead*/
  DSL_ProgressBarSegment as ProgressBarSegment,
};
