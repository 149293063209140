import {useRef} from 'react';

import {useKeyLineBorder} from 'components/DesignSystem/Library/hooks/useKeyLineBorder';
import {useScrollableLayout} from 'components/DesignSystem/Library/hooks/useScrollableLayout';

export function useDialogContent() {
  const ref = useRef<HTMLDivElement>(null);
  const {isAtTopOfDiv, hasOverflowY, isAtBottomOfDiv} = useScrollableLayout({
    ref,
  });

  const borderStyle = useKeyLineBorder({
    isAtTopOfDiv,
    isAtBottomOfDiv,
    hasOverflowY,
    alwaysShow: false,
  });

  return {
    ref,
    borderStyle,
  };
}
