import {ReactNode} from 'react';

import {AutocompleteProps} from '@mui/material';
import {schemaJsonPointerResolver} from '@regulatory-platform/common-utils';
import * as R from 'ramda';
import {FormMachineState} from 'utils/machines/formMachine';

import {Option} from 'components/DesignSystem/Library';

import {
  DSP_GenericFieldProps,
  getGenericFieldProps,
} from './getGenericFieldProps';
import {getItemsFromSchema} from './getItemsFromSchema';

interface DSP_ArrayFieldProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> extends DSP_GenericFieldProps {
  options: AutocompleteProps<
    Option,
    Multiple,
    DisableClearable,
    FreeSolo
  >['options'];
}

interface DSP_GetArrayFieldProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> {
  fieldRef: string;
  state: FormMachineState;
  items?: DSP_ArrayFieldProps<Multiple, DisableClearable, FreeSolo>['options'];
  label?: ReactNode;
  hideLabel?: boolean;
  includeOptionalSuffix?: boolean;
  multiple?: boolean;
  useLabelForValidationErrors?: boolean;
}

export function getArrayFieldProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  fieldRef,
  state,
  items,
  label,
  hideLabel,
  includeOptionalSuffix,
  multiple,
  useLabelForValidationErrors,
}: DSP_GetArrayFieldProps<
  Multiple,
  DisableClearable,
  FreeSolo
>): DSP_ArrayFieldProps<Multiple, DisableClearable, FreeSolo> {
  const {value, ...genericFieldProps} = getGenericFieldProps({
    fieldRef,
    state,
    label,
    hideLabel,
    includeOptionalSuffix,
    useLabelForValidationErrors,
  });
  const {schema} = state.context;
  const fieldSchema = schemaJsonPointerResolver(fieldRef)(schema);
  let options = R.defaultTo(getItemsFromSchema(fieldSchema), items);
  if (options.length === 0 && value) {
    options = R.is(Array, value) ? value : [value as string];
  }

  return {
    ...genericFieldProps,
    value: multiple ? value || [] : value,
    options,
  };
}
