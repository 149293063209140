import React from 'react';

import {
  TimelineOppositeContentProps as MuiTimelineOppositeContentProps,
  TimelineOppositeContent as MuiTimelineOppositeContent,
} from '@mui/lab';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

export type DSL_TimelineOppositeContentProps = Omit<
  MuiTimelineOppositeContentProps,
  DSL_RestrictedVisualProps
>;

export const DSL_TimelineOppositeContent = (
  props: DSL_TimelineOppositeContentProps,
) => {
  return (
    <MuiTimelineOppositeContent sx={{px: 2, py: 2}} align="right" {...props} />
  );
};

DSL_TimelineOppositeContent.displayName = 'DSL_TimelineOppositeContent';

export {
  //
  /** @deprecated use DSL_TimelineOppositeContentProps instead*/
  DSL_TimelineOppositeContentProps as TimelineOppositeContentProps, //
  /** @deprecated use DSL_TimelineOppositeContent instead*/
  DSL_TimelineOppositeContent as TimelineOppositeContent,
};
