import React from 'react';

import {DSL_BaseTypography, DSL_BaseTypographyProps} from '../BaseTypography';

export type DSL_Heading1Props = Omit<DSL_BaseTypographyProps, 'variant'>;
export const DSL_Heading1 = (props: DSL_Heading1Props) => (
  <DSL_BaseTypography {...props} variant={'h1'} />
);

export {
  //
  /** @deprecated use DSL_Heading1Props instead*/
  DSL_Heading1Props as Heading1Props, //
  /** @deprecated use DSL_Heading1 instead*/
  DSL_Heading1 as Heading1,
};
