import React from 'react';

import {UserProfile} from '@regulatory-platform/common-utils/dist';
import {capitalize} from 'components/DesignSystem/utils/capitalize';

import {
  Body2,
  FlexColumn,
  Heading5,
  OpenerProps,
  Popper,
  PopperList,
  PopperListItem,
  PopperListSubheader,
  PopperProps,
} from 'components/DesignSystem/Library';

import {DSP_AccountHeader} from '../AccountHeader';
import {DSP_AccountToolTip} from '../AccountToolTip';

export type DSP_AccountHeaderMenuViewProps = {
  openerProps: OpenerProps;
  popperProps: PopperProps;
  userProfile: UserProfile;
};

export const DSP_AccountHeaderMenuView = ({
  openerProps,
  popperProps,
  userProfile,
}: DSP_AccountHeaderMenuViewProps) => {
  return (
    <>
      <DSP_AccountToolTip
        ref={openerProps.ref}
        open={popperProps.open}
        onClick={openerProps.onClick}
      />
      <Popper id={'account-header-menu-list'} disablePortal {...popperProps}>
        <PopperList
          aria-labelledby="account-header-menu-subheader"
          subheader={
            <>
              <PopperListSubheader
                disableSticky
                id="account-header-menu-subheader"
              >
                <FlexColumn>
                  <Heading5>Switch to organisation</Heading5>
                  {userProfile?.accountType && (
                    <Body2 color={'text.secondary'}>
                      {capitalize(userProfile.accountType)}
                    </Body2>
                  )}
                </FlexColumn>
              </PopperListSubheader>
            </>
          }
        >
          <PopperListItem>
            <DSP_AccountHeader userProfile={userProfile} />
          </PopperListItem>
        </PopperList>
      </Popper>
    </>
  );
};

DSP_AccountHeaderMenuView.displayName = 'DSP_AccountHeaderMenuView';

export {
  //
  /** @deprecated use DSP_AccountHeaderMenuViewProps instead*/
  DSP_AccountHeaderMenuViewProps as AccountHeaderMenuViewProps, //
  /** @deprecated use DSP_AccountHeaderMenuView instead*/
  DSP_AccountHeaderMenuView as AccountHeaderMenuView,
};
