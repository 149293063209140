import React from 'react';
import {useLocation} from 'react-router-dom';
import {trackPageView} from 'utils/tracking';
import {RouteConfig, RouteSection} from '../types';
import * as R from 'ramda';
import {i18n} from 'containers-content';

type SetPageTitleProps = {
  route: RouteConfig;
};

const SetPageTitle: React.FC<SetPageTitleProps> = ({
  route,
}: SetPageTitleProps) => {
  const location = useLocation();

  //track page
  React.useEffect(() => {
    let pageBreadcrumb = route.menuTitle;
    const routePath = R.defaultTo('', route.path);
    const sections = R.takeLast(
      1,
      R.defaultTo([], route.sections) as RouteSection[],
    );
    const sectionsLength = sections.length;
    if (sections.length > 0) {
      pageBreadcrumb = R.join(
        ' > ',
        sections.map(
          (section, idx) =>
            idx === sectionsLength - 1
              ? R.defaultTo('', route.menuTitle)
              : R.defaultTo('', section.content),
          route.sections,
        ),
      );
    }
    if (routePath.indexOf(':id') > -1) {
      const pathArr = R.split('/', location.pathname);
      pageBreadcrumb = pageBreadcrumb + ' ' + pathArr[pathArr.length - 1];
    }
    const pageTitle = `${pageBreadcrumb} | ${i18n.get(
      'GLOBAL_BRAND_NAME_SHORT',
    )}`;
    window.document.title = pageTitle;
    trackPageView(pageTitle, location.pathname + location.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route]);

  return null;
};

export default SetPageTitle;
