import React from 'react';
import {TypographyProps} from '@mui/material';
import {
  DSL_Heading2,
  DSL_Heading3,
  DSL_Heading4,
  DSL_Heading5,
  DSL_Subtitle1,
  DSL_Subtitle2,
  DSL_Caption,
  DSL_Body2,
  DSL_Body1,
  DSL_BaseTypographyProps,
} from 'components/DesignSystem/Library';

type DSL_LegacyTypographyProps = TypographyProps & DSL_BaseTypographyProps;

/**
 * Paragraph
 */
const LegacyTypography: React.FC<DSL_LegacyTypographyProps> = ({
  variant,
  color,
  ...props
}) => {
  switch (variant) {
    case 'h1': // old headings map to new headings one level down
      return <DSL_Heading2 {...props} />;
    case 'h2':
      return <DSL_Heading3 {...props} />;
    case 'h3':
      return <DSL_Heading4 {...props} />;
    case 'h4':
      return <DSL_Heading5 {...props} />;
    case 'subtitle1':
      return <DSL_Subtitle1 {...props} />;
    case 'subtitle2':
      return <DSL_Subtitle2 {...props} />;
    case 'caption':
      return <DSL_Caption {...props} />;
    case 'body2':
      return <DSL_Body2 {...props} />;
    case 'body1':
    default:
      return <DSL_Body1 {...props} />;
  }
};

export default LegacyTypography;
