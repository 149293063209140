import React, {forwardRef} from 'react';

import {
  ListItem as MuiListItem,
  ListItemProps as MuiListItemProps,
} from '@mui/material';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

export interface DSL_BaseListItemProps
  extends Omit<MuiListItemProps, DSL_RestrictedVisualProps | 'component'> {
  display?: string;
  internal_sx?: MuiListItemProps['sx'];
}

export const DSL_BaseListItem = forwardRef<
  HTMLLIElement,
  DSL_BaseListItemProps
>(({internal_sx, ...props}, ref) => {
  return <MuiListItem ref={ref} component="li" sx={internal_sx} {...props} />;
});

DSL_BaseListItem.displayName = 'DSL_BaseListItem';
