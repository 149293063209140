import React from 'react';

import {DSL_CircularProgress} from '../../CircularProgress';
import {DSL_FlexColumn} from '../../Layout/FlexColumn';

export const DSL_DrawerLoadingState = (): JSX.Element => {
  return (
    <DSL_FlexColumn
      flex={1}
      alignItems={'center'}
      p={5}
      justifyContent={'center'}
    >
      <DSL_CircularProgress size={32} color={'primary'} />
    </DSL_FlexColumn>
  );
};

DSL_DrawerLoadingState.displayName = 'DSL_DrawerLoadingState';

export {
  //
  /** @deprecated use DSL_DrawerLoadingState instead*/
  DSL_DrawerLoadingState as DrawerLoadingState,
};
