// TODO: Look at how decouple the design system from React Router

import React, {ForwardedRef, forwardRef} from 'react';

import {Link as MuiLink, LinkProps as MuiLinkProps} from '@mui/material';
import {Link as DSL_RRLink} from 'react-router-dom';

/*
  This component is specific to react-router-dom
  If you use another routing library such as nextjs
  please feel free to create another component specific for that library
  in order to having import and pass the component everywhere you
  need to use an internal link
*/

export type DSL_RouterLinkProps = Omit<MuiLinkProps, 'component' | 'to'>;

export const DSL_RouterLink = forwardRef(
  (
    {href = '/', ...props}: DSL_RouterLinkProps,
    ref: ForwardedRef<HTMLAnchorElement>,
  ) => {
    return <MuiLink ref={ref} component={DSL_RRLink} to={href} {...props} />;
  },
);

DSL_RouterLink.displayName = 'DSL_RouterLink';

export {
  //
  /** @deprecated use DSL_RouterLinkProps instead*/
  DSL_RouterLinkProps as RouterLinkProps, //
  /** @deprecated use DSL_RouterLink instead*/
  DSL_RouterLink as RouterLink,
};
