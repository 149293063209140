import {styled} from '@mui/material/styles';
import {spacing} from '@mui/system';
import MuiButton from '@mui/material/Button';

/**
 * Applies the spacing system to the material UI Button
 */
const BaseButton = styled(MuiButton)(spacing);

export default BaseButton;
