import {
  jsonPointerResolver,
  JSONSchemaRecord,
} from '@regulatory-platform/common-utils';
import {useSelector} from '@xstate/react';
import * as R from 'ramda';

import {FormMachineState} from '../../../../../utils/machines/formMachine';
import {useFormContext} from '../../Forms/FormProvider';
import {createReadableFieldName} from '../../Forms/utils/createReadableFieldName';
import {getFieldSchema} from '../../Forms/utils/getFieldSchema';

import {DSP_StatusChipViewProps} from './StatusChip.View';

export function useStatusChip(
  fieldRef: string,
  explicitStyle: string | undefined,
): DSP_StatusChipViewProps {
  const {service} = useFormContext();

  return useSelector(
    service,
    state => {
      const {id, name, style, value} = getStatusChipFieldProps(fieldRef, state);
      const effectiveStatus = style ?? explicitStyle ?? value;

      return {
        id,
        name,
        effectiveStatus,
        label: value,
      };
    },
    R.equals,
  );
}

function getStatusChipFieldProps(fieldRef: string, state: FormMachineState) {
  const {schema, record} = state.context;

  const name = createReadableFieldName(fieldRef);
  const value = jsonPointerResolver<string | undefined>(fieldRef)(record);
  const style = getStyleFromFieldSchema(value, fieldRef, schema);

  return {
    name,
    value,
    style,
    id: name,
  };
}

function getStyleFromFieldSchema(
  fieldValue: string | undefined,
  fieldRef: string,
  schema: JSONSchemaRecord,
): string | undefined {
  if (!fieldValue) {
    return;
  }

  const fieldSchema = getFieldSchema(fieldRef, schema);

  type DSP_OneOfArray =
    | {enum: string[]; 'x-style': string; title: string}[]
    | undefined;

  const oneOfArray = fieldSchema.oneOf as DSP_OneOfArray;

  return oneOfArray?.find(({title}) => title === fieldValue)?.['x-style'];
}
