import React from 'react';

import {DSL_BaseButton, DSL_BaseButtonProps} from '../BaseButton';

export type DSL_SubmitButtonProps = DSL_BaseButtonProps;

export const DSL_SubmitButton = (props: DSL_SubmitButtonProps) => {
  return <DSL_BaseButton variant={'contained'} color={'primary'} {...props} />;
};

DSL_SubmitButton.displayName = 'DSL_SubmitButton';

export {
  //
  /** @deprecated use DSL_SubmitButtonProps instead*/
  DSL_SubmitButtonProps as SubmitButtonProps, //
  /** @deprecated use DSL_SubmitButton instead*/
  DSL_SubmitButton as SubmitButton,
};
