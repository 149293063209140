import {ReactNode, useEffect, useMemo} from 'react';

import {Breakpoint} from '@mui/material';

import {useCurrentBreakpoint} from '../../hooks/useCurrentBreakpoint';

interface DSL_UseOverflowManagerProps {
  children: ReactNode[] | undefined;
  maxItems: number | {[BreakpointName in Breakpoint]?: number};
  onOverflowChange?: () => void;
}

export function useOverflowManager({
  children,
  maxItems,
  onOverflowChange,
}: DSL_UseOverflowManagerProps) {
  const currentBreakpoint = useCurrentBreakpoint();

  const effectiveMaxItems = useMemo(() => {
    if (typeof maxItems === 'number') {
      return maxItems;
    }

    const maxItemsForBreakpoint = maxItems[currentBreakpoint];

    if (maxItemsForBreakpoint === undefined) {
      return children?.length ?? 0;
    }

    return maxItemsForBreakpoint - 1;
  }, [children?.length, currentBreakpoint, maxItems]);

  useEffect(() => {
    onOverflowChange?.();
  }, [effectiveMaxItems, onOverflowChange]);

  return {
    effectiveMaxItems,
  };
}
