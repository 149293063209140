import app from 'app/services/appService';
import AppServicesContext from 'app/services/AppServicesContext';
import auth from 'app/services/authService';
import browserHistory from 'app/services/browserHistoryService';
import AppSnackbar from 'components/AppSnackbar';
import snackbar from 'components/AppSnackbar/snackbarService';
import React, {useMemo} from 'react';
import websocket from 'app/services/websocketService';
import {getDebugDomain} from 'utils/global';
import {i18n} from 'containers-content';

type AppServicesProviderProps = {
  children?: React.ReactChildren | React.ReactElement | React.ReactNode;
};

/**
 * Load and start base application services into the React context.
 */
const AppServicesProvider: React.FC<AppServicesProviderProps> = ({
  children,
}: AppServicesProviderProps) => {
  app.service.start();
  auth.service.start();
  snackbar.service.start();
  websocket.machineInterpreter.start();

  useMemo(
    () =>
      auth.state$.subscribe(({context: {userProfile}}) => {
        const newDomain = getDebugDomain() ?? userProfile?.domain;
        if (newDomain) {
          i18n.setDomain(newDomain);
        }
      }),
    [],
  );

  return (
    <AppServicesContext.Provider
      value={{
        browserHistory,
        app,
        auth,
        snackbar,
        webSocketService: websocket.service,
      }}
    >
      <AppSnackbar service={snackbar.service} />
      {children}
    </AppServicesContext.Provider>
  );
};

export default AppServicesProvider;
