import React, {ForwardedRef, forwardRef, ReactNode} from 'react';

import {DSL_ButtonCardActionAreaProps} from '../../../../Layout/Card/CardActionArea';
import {DSL_Body1} from '../../../../Typography/Body1';
import {DSL_BaseCardInput, DSL_BaseCardInputProps} from '../../BaseCardInput';
import {DSL_RadioOption, DSL_RadioOptionProps} from '../../RadioGroupInput';

export interface DSL_RadioCardOptionViewProps
  extends Omit<DSL_BaseCardInputProps, 'input' | 'children'>,
    Omit<DSL_RadioOptionProps, 'ref'> {
  label: ReactNode;
  onClickCard: DSL_ButtonCardActionAreaProps['onClick'];
  description?: ReactNode;
  describedBy?: string;
}

export const DSL_RadioCardOptionView = forwardRef(
  (
    {
      isHighlighted,
      value,
      label,
      disabled,
      maxHeight,
      minHeight,
      error,
      onClickCard,
      height,
      description,
      describedBy,
    }: DSL_RadioCardOptionViewProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => (
    <DSL_BaseCardInput
      error={error}
      height={height}
      disabled={disabled}
      maxHeight={maxHeight}
      minHeight={minHeight}
      onClickCard={onClickCard}
      isHighlighted={isHighlighted}
    >
      <DSL_RadioOption
        ref={ref}
        value={value}
        label={label}
        disabled={disabled}
        aria-describedby={describedBy}
      />
      {description && (
        <DSL_Body1 component={'div'} color={'text.secondary'}>
          {description}
        </DSL_Body1>
      )}
    </DSL_BaseCardInput>
  ),
);

DSL_RadioCardOptionView.displayName = 'DSL_RadioCardOptionView';
