import React from 'react';

import {
  DSL_EntityPopover,
  DSL_EntityPopoverProps,
  DSL_EntityContactDetails,
  DSL_EntityContactDetailsProps,
} from 'components/DesignSystem/Library';

export interface DSP_UserEntityPopoverProps
  extends DSL_EntityPopoverProps,
    Omit<DSL_EntityContactDetailsProps, 'isLoading'> {
  title: string;
  subTitle: string;
}

export const DSP_UserEntityPopover = ({
  isLoading,
  title,
  subTitle,
  email,
  phone,
  ...popoverProps
}: DSP_UserEntityPopoverProps) => {
  return (
    <DSL_EntityPopover
      isLoading={isLoading}
      title={title}
      subTitle={subTitle}
      {...popoverProps}
    >
      <DSL_EntityContactDetails
        isLoading={isLoading}
        email={email}
        phone={phone}
      />
    </DSL_EntityPopover>
  );
};

DSP_UserEntityPopover.displayName = 'DSP_UserEntityPopover';
