import React from 'react';

import {
  DSL_EntityCard,
  DSL_EntityContactDetails,
} from 'components/DesignSystem/Library';

import {useAuditorEntityCard} from './useAuditorEntityCard';

export const DSP_AuditorEntityCard = () => {
  const {phone, email, location, ...cardProps} = useAuditorEntityCard();

  return (
    <DSL_EntityCard {...cardProps}>
      <DSL_EntityContactDetails
        phone={phone}
        email={email}
        location={location}
      />
    </DSL_EntityCard>
  );
};

DSP_AuditorEntityCard.displayName = 'DSP_AuditorEntityCard';
