import React from 'react';

import {
  RadioGroup as MuiRadioGroup,
  RadioGroupProps as MuiRadioGroupProps,
} from '@mui/material';

import {
  DSL_BreakpointValues,
  useCascadingBreakpointValue,
} from '../../../Layout/hooks/useCascadingBreakpointValue';
import {DSL_RestrictedVisualProps} from '../../../types';

export interface DSL_RadioCardGroupProps
  extends Omit<MuiRadioGroupProps, DSL_RestrictedVisualProps> {
  columns: DSL_BreakpointValues<number>;
}

export const DSL_RadioCardGroup = ({
  columns,
  ...props
}: DSL_RadioCardGroupProps) => (
  <MuiRadioGroup
    sx={{
      display: 'grid',
      gridTemplateColumns: `repeat(${useCascadingBreakpointValue(
        columns,
      )}, 1fr)`,
      columnGap: 3,
      rowGap: 3,
    }}
    {...props}
  />
);

DSL_RadioCardGroup.displayName = 'DSL_RadioCardGroup';
