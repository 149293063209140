import React from 'react';

import {useSelector} from '@xstate/react';
import * as R from 'ramda';

import {
  ContainedButton,
  ContainedButtonProps,
} from 'components/DesignSystem/Library';

import {useFormService} from '../FormProvider';

type DSP_SubmitButtonProps = {
  /**
   * which state machine states should display the loading spinner.
   * These are usually consistent but can very
   */
  loadingStates?: string[];
} & Omit<ContainedButtonProps, 'type' | 'loading'>;

const defaultLoadingStates = [
  'saving',
  'submitting',
  'archiving',
  'creating',
  'checkingUniqueness',
];

export const DSP_SubmitButton = ({
  loadingStates = defaultLoadingStates,
  ...props
}: DSP_SubmitButtonProps) => {
  const service = useFormService();
  const loading = useSelector(service, state =>
    R.any(loadingState => state.matches(loadingState), loadingStates),
  );

  if (loading) {
    props.disabled = true;
  }

  return <ContainedButton type={'submit'} loading={loading} {...props} />;
};

export {
  //
  /** @deprecated use DSP_SubmitButton instead*/
  DSP_SubmitButton as SubmitButton,
};
