import React from 'react';

import {DSL_BaseTypography, DSL_BaseTypographyProps} from '../BaseTypography';

export type DSL_Heading5Props = Omit<DSL_BaseTypographyProps, 'variant'>;
export const DSL_Heading5 = (props: DSL_Heading5Props) => (
  <DSL_BaseTypography color={'text.primary'} variant={'h5'} {...props} />
);

export {
  //
  /** @deprecated use DSL_Heading5Props instead*/
  DSL_Heading5Props as Heading5Props, //
  /** @deprecated use DSL_Heading5 instead*/
  DSL_Heading5 as Heading5,
};
