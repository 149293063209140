import {from} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {onError} from '@apollo/client/link/error';
import {authGetTokenLink, getNonExpiredToken} from './authGetTokenLink';

const authSetHeaderLink = setContext((_, {headers}) => {
  const authToken = getNonExpiredToken();
  if (authToken) {
    return {
      headers: {
        ...headers,
        Authorization: 'Bearer ' + authToken,
      },
    };
  } else {
    return {
      headers: {
        ...headers,
      },
    };
  }
});

const authErrorLink = onError(() => {
  //{graphQLErrors, networkError}
  // if (graphQLErrors)
  // graphQLErrors.map(({ message, locations, path }) =>
  //   console.log(
  //     `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
  //   )
  // );
  // if (
  //   networkError &&
  //   networkError.name === 'ServerError' &&
  //   (networkError as ServerError).statusCode === 401
  // ) {
  // }
  // send('LOCKED')
});

export const authLink = from([
  authErrorLink,
  authGetTokenLink,
  authSetHeaderLink,
]);
