import React from 'react';

import {DSL_Option} from '../../types';

export function optionToLabel(option: DSL_Option): React.ReactNode {
  return option === '' ? (
    <em>None</em>
  ) : typeof option === 'object' ? (
    option?.label
  ) : (
    option
  );
}
