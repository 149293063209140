import React from 'react';

import {
  TabPanel as MuiTabPanel,
  TabPanelProps as MuiTabPanelProps,
} from '@mui/lab';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

export type DSL_TabPanelProps = Omit<
  MuiTabPanelProps,
  DSL_RestrictedVisualProps
> & {
  removeMargin?: boolean;
  label?: string;
};

export const DSL_TabPanel = ({
  removeMargin = false,
  ...props
}: DSL_TabPanelProps) => {
  return (
    <MuiTabPanel
      data-name={'tab-panel'}
      sx={{marginTop: removeMargin ? 0 : 2, padding: 0, width: '100%'}}
      {...props}
    />
  );
};

DSL_TabPanel.displayName = 'DSL_TabPanel';

export {
  //
  /** @deprecated use DSL_TabPanelProps instead*/
  DSL_TabPanelProps as TabPanelProps, //
  /** @deprecated use DSL_TabPanel instead*/
  DSL_TabPanel as TabPanel,
};
