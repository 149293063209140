import Grid from '@mui/material/Grid';
import {DSP_PageContainer} from 'components/DesignSystem/Portal';
import LegacyTypography from 'components/typography/LegacyTypography';

import React from 'react';
import {Link} from 'react-router-dom';

/**
 * Error page for 401 Unauthorised
 */
const Unauthorised: React.FC = () => {
  return (
    <DSP_PageContainer
      title="Sorry. That page doesn't exist or is private"
      hideBreadcrumbs
      legacyChildren
    >
      <Grid container>
        <Grid item xs={12}>
          <LegacyTypography variant="h5">
            To get you moving again, return to the{' '}
            <Link color={'primary'} to={'/'}>
              Home page
            </Link>
            ,{' '}
          </LegacyTypography>
          <LegacyTypography variant="h5">
            or try looking for it using{' '}
            <Link color={'primary'} to={'/'}>
              search
            </Link>
          </LegacyTypography>
        </Grid>
      </Grid>
    </DSP_PageContainer>
  );
};

export default Unauthorised;
