import React from 'react';

import {DSL_IconWrapper} from '../IconWrapper';
import {DSL_IconProps} from '../types';

const DSL_Roundabout3 = (props: DSL_IconProps) => {
  return (
    <DSL_IconWrapper
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          fill="none"
          viewBox="0 0 60 60"
          {...props}
        >
          <g clipPath="url(#a)">
            <path fill="#fff" d="M0 0h60v60H0z" />
            <path
              fill="#000"
              fillOpacity={0.3}
              fillRule="evenodd"
              d="M30 21.5a8.5 8.5 0 1 0 0 17 8.5 8.5 0 0 0 0-17M16.5 30c0-7.456 6.044-13.5 13.5-13.5S43.5 22.544 43.5 30 37.456 43.5 30 43.5 16.5 37.456 16.5 30"
              clipRule="evenodd"
            />
            <path
              fill="#000"
              fillRule="evenodd"
              d="m24.268 15.268-3.536-3.535L30 2.465l9.268 9.268-3.536 3.535-3.232-3.232V17c0 2.575-2.045 4.274-4.04 4.639a8.503 8.503 0 0 0 0 16.722c1.995.365 4.04 2.064 4.04 4.639v8h-5v-7.731c-6.262-1.174-11-6.666-11-13.269s4.738-12.095 11-13.268v-4.696z"
              clipRule="evenodd"
            />
          </g>
          <defs>
            <clipPath id="a">
              <path fill="#fff" d="M0 0h60v60H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      {...props}
    />
  );
};

export default React.memo(DSL_Roundabout3);
