import React from 'react';

import {
  DSL_Body2,
  DSL_Box,
  DSL_FlexRow,
  DSL_Table,
  DSL_TableBody,
  DSL_TableCell,
  DSL_TableContainer,
  DSL_TableHead,
  DSL_TableRow,
} from 'components/DesignSystem/Library';

import {pluralizeLabel} from '../../../../utils/pluralizeLabel';
import {ImportFromCsvRowMachine} from '../machines/types';
import {ImportFromCsvRow, ImportFromCsvRowProps} from '../Row/ImportFromCsvRow';

export interface ImportFromCsvTableProps
  extends Omit<ImportFromCsvRowProps, 'rowMachine' | 'rowIndex'> {
  tableTitle: string;
  invalidRowCount: number;
  selectedRowCount: number;
  rowMachines: ImportFromCsvRowMachine[];
}

export const ImportFromCsvTable = ({
  tableTitle,
  columns,
  rowMachines,
  invalidRowCount,
  selectedRowCount,
  csvService,
}: ImportFromCsvTableProps) => {
  return (
    <DSL_Box>
      <DSL_TableContainer maxHeight={400}>
        <DSL_Table
          stickyHeader
          display={'block'}
          whiteSpace={'nowrap'}
          aria-label={tableTitle}
        >
          <DSL_TableHead>
            <DSL_TableRow>
              <DSL_TableCell width={50} padding={'none'} />
              {columns?.map(({title, width, description}, index) => (
                <DSL_TableCell
                  key={title}
                  title={description}
                  width={index + 1 === columns.length ? '100%' : width}
                >
                  {title}
                </DSL_TableCell>
              ))}
            </DSL_TableRow>
          </DSL_TableHead>
          <DSL_TableBody>
            {rowMachines.map((machine, index) => (
              <ImportFromCsvRow
                key={index}
                csvService={csvService}
                rowMachine={machine}
                columns={columns}
              />
            ))}
          </DSL_TableBody>
        </DSL_Table>
      </DSL_TableContainer>
      <DSL_FlexRow
        p={2}
        bottom={0}
        boxShadow={2}
        width={'100%'}
        position={'sticky'}
        alignItems={'center'}
        bgcolor={'background.paper'}
        zIndex={theme => theme.zIndex.tooltip}
      >
        <DSL_Body2 bold>
          {selectedRowCount} of {rowMachines.length} rows selected
        </DSL_Body2>
        {invalidRowCount > 0 && (
          <DSL_Body2 color={'error'}>
            {invalidRowCount} invalid {pluralizeLabel('row', invalidRowCount)}
          </DSL_Body2>
        )}
      </DSL_FlexRow>
    </DSL_Box>
  );
};

ImportFromCsvTable.displayName = 'ImportFromCsvTable';
