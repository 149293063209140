import React from 'react';

import {
  AuthorizationDecision,
  UserProfile,
  UserRoles,
  anyRolesVoter,
} from '@regulatory-platform/common-utils/dist';
import {useNavigate} from 'react-router';

import {
  LogoutIcon,
  BusinessCenterIcon,
  ProfileIcon,
  usePopper,
} from 'components/DesignSystem/Library';

import {DSP_ProfileMenuViewProps} from './ProfileMenu.View';

export const useProfileMenu = (
  userProfile: UserProfile,
  logoutUrl = '/logout',
): DSP_ProfileMenuViewProps => {
  const navigate = useNavigate();
  const {popperProps, openerProps} = usePopper();

  const accountType = userProfile?.accountType ?? '';
  const roles = [(accountType + 'AccountAdmin') as UserRoles];

  const isAdmin =
    !userProfile?.accountType ||
    anyRolesVoter(userProfile, roles) === AuthorizationDecision.DENY
      ? false
      : true;

  const adminListItems =
    userProfile && isAdmin
      ? [
          {
            id: 'manage-account',
            navigateTo: '/accounts/manage',
            menuTitle: 'Manage account',
            menuSubtitle: `Manage ${userProfile.accountName} account`,
            icon: <BusinessCenterIcon color={'action'} />,
          },
        ]
      : [];

  return {
    userProfile,
    popperProps,
    openerProps,
    onListItemClick: (to: string) => {
      popperProps.closePopper();
      navigate(to);
    },
    listItems: [
      {
        id: '/user-profile',
        menuTitle: 'Profile',
        menuSubtitle: 'Manage user profile',
        navigateTo: '/accounts/user-profile/' + userProfile?.id,
        icon: <ProfileIcon color={'action'} />,
      },
      ...adminListItems,
      {
        id: 'logout',
        menuTitle: 'Logout',
        navigateTo: logoutUrl,
        menuSubtitle: '',
        icon: <LogoutIcon color={'action'} />,
      },
    ],
  };
};
