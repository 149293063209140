import React from 'react';

import * as R from 'ramda';

import {
  DSL_Box,
  DSL_FlexColumn,
  DSL_PageHeader,
  DSL_LinearProgress,
} from 'components/DesignSystem/Library';

import {
  DSP_AuthenticatedPageHeading,
  DSP_AuthenticatedPageHeadingProps,
} from '../AuthenticatedPageHeader';

export interface DSP_PageContainerProps
  extends Pick<
    DSP_AuthenticatedPageHeadingProps,
    | 'subTitle'
    | 'actionItems'
    | 'pageInfo'
    | 'hideBreadcrumbs'
    | 'activeBreadcrumbLabel'
    | 'breadcrumbs'
    | 'showDivider'
  > {
  title?: DSP_AuthenticatedPageHeadingProps['title'];
  children?: React.ReactChildren | React.ReactElement | React.ReactNode;
  loading?: boolean;
  unauthenticated?: boolean;
  formContainer?: boolean;
  noPageMargin?: boolean;
}

/**
 * Standard container for all pages
 */
export const DSP_PageContainer: React.FC<DSP_PageContainerProps> = ({
  title,
  subTitle,
  loading,
  children,
  actionItems,
  pageInfo,
  activeBreadcrumbLabel,
  breadcrumbs,
  hideBreadcrumbs,
  unauthenticated,
  formContainer,
  noPageMargin,
  showDivider,
}: DSP_PageContainerProps) => {
  return (
    <DSL_Box
      my={!formContainer ? (noPageMargin ? 0 : [1, 1, 3, 3, 3]) : undefined}
      mx={!formContainer ? (noPageMargin ? 0 : [1, 2, 3, 3, 8]) : undefined}
      data-name={'page-container'}
    >
      <DSL_FlexColumn
        data-name="page-container-flex-column"
        gap={3}
        sx={theme => ({
          maxWidth: {
            xs: '100%',
            xl: theme.breakpoints.values.xl,
          },
        })}
      >
        {loading ? (
          <>
            <DSL_PageHeader
              title={R.defaultTo('Loading. Please wait.', title)}
            />
            <DSL_LinearProgress />
          </>
        ) : unauthenticated ? (
          <DSL_PageHeader title={title} showDivider={showDivider} />
        ) : (
          <DSP_AuthenticatedPageHeading
            activeBreadcrumbLabel={activeBreadcrumbLabel}
            title={formContainer ? null : title}
            subTitle={subTitle}
            actionItems={actionItems}
            pageInfo={pageInfo}
            breadcrumbs={breadcrumbs}
            hideBreadcrumbs={hideBreadcrumbs}
            showDivider={showDivider}
          />
        )}
        {!loading && (
          <DSL_Box data-id={'page-container-inner-wrapper'} maxWidth={'100%'}>
            {children}
          </DSL_Box>
        )}
      </DSL_FlexColumn>
    </DSL_Box>
  );
};

DSP_PageContainer.displayName = 'DSP_PageContainer';

export {
  //
  /** @deprecated use DSP_PageContainerProps instead*/
  DSP_PageContainerProps as PageContainerProps, //
  /** @deprecated use DSP_PageContainer instead*/
  DSP_PageContainer as PageContainer,
};
