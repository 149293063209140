import {useFieldSchema, useFieldValue} from '../../FormProvider';
import {DSP_FormFieldProps} from '../FormField';
import {DSP_UseFormFieldProps} from '../useFormField';

export function useTableFormField({
  fieldRef,
  fieldTypeOverride,
  ...props
}: DSP_UseFormFieldProps): DSP_FormFieldProps {
  const value = useFieldValue(fieldRef);
  const schema = useFieldSchema({fieldRef});

  if (!schema || (!fieldTypeOverride && !value && schema.readOnly)) {
    fieldTypeOverride = 'notApplicable';
  }

  return {
    ...props,
    fieldRef,
    fieldTypeOverride,
  };
}
