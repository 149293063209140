import {useCallback, useRef, useState} from 'react';

export function usePopover<ElementType = HTMLElement>() {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const anchorRef = useRef<ElementType>(null);

  const openPopover = useCallback(() => setIsPopoverOpen(true), []);
  const closePopover = useCallback(() => setIsPopoverOpen(false), []);

  return {
    anchorRef,
    closePopover,
    isPopoverOpen,
    openPopover,
  };
}
