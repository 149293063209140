import React from 'react';

import {EmailInput, EmailInputProps} from 'components/DesignSystem/Library';

import {useInputFieldProps} from '../hooks/useInputFieldProps';
import {DSP_FieldProps} from '../types';

export type DSP_EmailFieldProps = EmailInputProps & DSP_FieldProps;
export const DSP_EmailField = (props: DSP_EmailFieldProps) => {
  const fieldProps = useInputFieldProps(props);
  return <EmailInput {...fieldProps} />;
};

export {
  //
  /** @deprecated use DSP_EmailFieldProps instead*/
  DSP_EmailFieldProps as EmailFieldProps, //
  /** @deprecated use DSP_EmailField instead*/
  DSP_EmailField as EmailField,
};
