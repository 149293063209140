import * as Types from '../../../../../../typeDefs/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAccreditationAuditorPublicFindByIdEveryoneQueryVariables = Types.Exact<{
  id: Types.Scalars['Float']['input'];
}>;


export type GetAccreditationAuditorPublicFindByIdEveryoneQuery = { __typename?: 'Query', accreditationAuditorPublic?: { __typename?: 'AccreditationAuditorPublic', addressLocality?: string | null, addressPostcode?: string | null, addressState?: string | null, auditingInStates?: Array<string | null> | null, email?: string | null, fatigueScope?: boolean | null, massScope?: boolean | null, id?: number | null, name?: string | null, phone?: string | null } | null };


export const GetAccreditationAuditorPublicFindByIdEveryoneDocument = gql`
    query getAccreditationAuditorPublicFindByIdEveryone($id: Float!) {
  accreditationAuditorPublic(id: $id) {
    addressLocality
    addressPostcode
    addressState
    auditingInStates
    email
    fatigueScope
    massScope
    id
    name
    phone
  }
}
    `;

/**
 * __useGetAccreditationAuditorPublicFindByIdEveryoneQuery__
 *
 * To run a query within a React component, call `useGetAccreditationAuditorPublicFindByIdEveryoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccreditationAuditorPublicFindByIdEveryoneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccreditationAuditorPublicFindByIdEveryoneQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAccreditationAuditorPublicFindByIdEveryoneQuery(baseOptions: Apollo.QueryHookOptions<GetAccreditationAuditorPublicFindByIdEveryoneQuery, GetAccreditationAuditorPublicFindByIdEveryoneQueryVariables> & ({ variables: GetAccreditationAuditorPublicFindByIdEveryoneQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccreditationAuditorPublicFindByIdEveryoneQuery, GetAccreditationAuditorPublicFindByIdEveryoneQueryVariables>(GetAccreditationAuditorPublicFindByIdEveryoneDocument, options);
      }
export function useGetAccreditationAuditorPublicFindByIdEveryoneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccreditationAuditorPublicFindByIdEveryoneQuery, GetAccreditationAuditorPublicFindByIdEveryoneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccreditationAuditorPublicFindByIdEveryoneQuery, GetAccreditationAuditorPublicFindByIdEveryoneQueryVariables>(GetAccreditationAuditorPublicFindByIdEveryoneDocument, options);
        }
export function useGetAccreditationAuditorPublicFindByIdEveryoneSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAccreditationAuditorPublicFindByIdEveryoneQuery, GetAccreditationAuditorPublicFindByIdEveryoneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAccreditationAuditorPublicFindByIdEveryoneQuery, GetAccreditationAuditorPublicFindByIdEveryoneQueryVariables>(GetAccreditationAuditorPublicFindByIdEveryoneDocument, options);
        }
export type GetAccreditationAuditorPublicFindByIdEveryoneQueryHookResult = ReturnType<typeof useGetAccreditationAuditorPublicFindByIdEveryoneQuery>;
export type GetAccreditationAuditorPublicFindByIdEveryoneLazyQueryHookResult = ReturnType<typeof useGetAccreditationAuditorPublicFindByIdEveryoneLazyQuery>;
export type GetAccreditationAuditorPublicFindByIdEveryoneSuspenseQueryHookResult = ReturnType<typeof useGetAccreditationAuditorPublicFindByIdEveryoneSuspenseQuery>;
export type GetAccreditationAuditorPublicFindByIdEveryoneQueryResult = Apollo.QueryResult<GetAccreditationAuditorPublicFindByIdEveryoneQuery, GetAccreditationAuditorPublicFindByIdEveryoneQueryVariables>;