import React, {forwardRef} from 'react';

import {DSL_Flex, DSL_FlexProps} from '../Flex';

export type DSL_FlexRowProps = Omit<
  DSL_FlexProps,
  'flexDirection' | 'flexRow' | 'flexColumn'
>;

export const DSL_FlexRow = forwardRef(
  ({flexWrap = 'wrap', gap = 2, ...DSL_BoxProps}: DSL_FlexRowProps, ref) => {
    return (
      <DSL_Flex
        data-name={'flex-row'}
        ref={ref}
        flexRow
        flexWrap={flexWrap}
        gap={gap}
        {...DSL_BoxProps}
      />
    );
  },
);

DSL_FlexRow.displayName = 'DSL_FlexRow';

export {
  //
  /** @deprecated use DSL_FlexRowProps instead*/
  DSL_FlexRowProps as FlexRowProps, //
  /** @deprecated use DSL_FlexRow instead*/
  DSL_FlexRow as FlexRow,
};
