import Today from '@mui/icons-material/Today';
import {
  AuthorisationPolicy,
  BuiltInRoles,
} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/manage-subscriptions',
  component: lazy(
    () => import(/* webpackChunkName: "Roadmap" */ './ManageSubscriptions'),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Manage subscriptions',
  menuIcon: Today,
  authorisation: {
    apiKey: null,
    policies: [BuiltInRoles.EVERYONE] as AuthorisationPolicy[],
  },
  sections: [],
};
