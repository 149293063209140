import React from 'react';

import {
  DSL_ScrollableLayoutHeader,
  DSL_ScrollableLayoutHeaderProps,
} from '../../../Layout/ScrollableLayout';

export type DSL_BaseDrawerHeaderProps = DSL_ScrollableLayoutHeaderProps;

export const DSL_BaseDrawerHeader = React.forwardRef(
  (props: DSL_BaseDrawerHeaderProps, ref) => {
    return <DSL_ScrollableLayoutHeader ref={ref} {...props} />;
  },
);

DSL_BaseDrawerHeader.displayName = 'DSL_BaseDrawerHeader';

export {
  //
  /** @deprecated use DSL_BaseDrawerHeaderProps instead*/
  DSL_BaseDrawerHeaderProps as BaseDrawerHeaderProps, //
  /** @deprecated use DSL_BaseDrawerHeader instead*/
  DSL_BaseDrawerHeader as BaseDrawerHeader,
};
