import React from 'react';

import mime from 'mime';
import prettyBytes from 'pretty-bytes';

import {
  ExternalLink,
  Caption,
  TableCaption,
} from 'components/DesignSystem/Library';

import {
  DSP_SummaryTableRenderer,
  DSP_SummaryTableRendererProps,
} from '../SummaryTableRenderer';

import {DSP_SummaryAttachmentsRendererProps} from './SummaryAttachmentsRenderer.Connector';

interface DSP_SummaryAttachmentsRendererViewProps
  extends DSP_SummaryAttachmentsRendererProps {
  blobUrl: (name: string, filePrefix: string, fileGroupId?: string) => void;
}

export const DSP_SummaryAttachmentsRendererView = ({
  fieldRef,
  emptyState,
  blobUrl,
}: DSP_SummaryAttachmentsRendererViewProps) => {
  const columns: DSP_SummaryTableRendererProps['columns'] = [
    {
      fieldRef: '#/name',
      label: 'Filename',
      minWidth: 274,
      valueRenderer: value => ({
        value: (
          <ExternalLink onClick={() => blobUrl?.(value as string, 'documents')}>
            {value}
          </ExternalLink>
        ),
      }),
    },
    {
      fieldRef: '#/type',
      label: 'Type',
      isSecondary: true,
      valueRenderer: value => ({
        value: mime.getExtension(value as string)?.toUpperCase(),
      }),
      width: 160,
    },
    {
      fieldRef: '#/size',
      label: 'Size',
      valueRenderer: value => ({
        value: prettyBytes(value as number),
      }),
      width: 160,
    },
  ];

  const caption: DSP_SummaryTableRendererProps['caption'] = value => (
    <TableCaption textAlign={'right'}>
      <Caption>Uploaded files: </Caption>
      <Caption color={'text.primary'}>{value.length}</Caption>
    </TableCaption>
  );

  return (
    <DSP_SummaryTableRenderer
      label={'Attachments'}
      columns={columns}
      caption={caption}
      fieldRef={fieldRef}
      emptyState={emptyState}
    />
  );
};

DSP_SummaryAttachmentsRendererView.displayName =
  'DSP_SummaryAttachmentsRendererView';
