import React from 'react';

import {DSL_IconWrapper} from '../IconWrapper';
import {DSL_IconProps} from '../types';

const DSL_Fatigue = (props: DSL_IconProps) => {
  return (
    <DSL_IconWrapper
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          fill="none"
          viewBox="0 0 24 24"
          {...props}
        >
          <path
            fill="#000"
            fillOpacity={0.54}
            d="M14 4.3c0-.166.13-.3.29-.3h2.993a.29.29 0 0 1 .262.173.31.31 0 0 1-.04.32L14.91 7.7h2.373c.16 0 .29.134.29.3s-.13.3-.29.3H14.29a.29.29 0 0 1-.263-.173.31.31 0 0 1 .04-.32L16.663 4.6H14.29a.295.295 0 0 1-.29-.3M18.2 6.8c0-.166.13-.3.29-.3h2.22a.29.29 0 0 1 .263.173.31.31 0 0 1-.04.32L19.11 9.25h1.6c.16 0 .29.134.29.3s-.13.3-.29.3h-2.22a.29.29 0 0 1-.263-.173.31.31 0 0 1 .04-.32L20.09 7.1h-1.6a.295.295 0 0 1-.29-.3M15.159 9.55c0-.166.13-.3.29-.3h1.737a.29.29 0 0 1 .263.173.31.31 0 0 1-.04.32l-1.34 1.657h1.117c.16 0 .29.134.29.3s-.13.3-.29.3h-1.738a.29.29 0 0 1-.262-.173.31.31 0 0 1 .04-.32l1.34-1.657h-1.118a.295.295 0 0 1-.29-.3"
          />
          <path
            fill="#000"
            fillOpacity={0.54}
            fillRule="evenodd"
            d="M10.5 13.5a3.25 3.25 0 1 0 0-6.5 3.25 3.25 0 1 0 0 6.5m0-4.875a1.63 1.63 0 0 1 1.625 1.625 1.63 1.63 0 0 1-1.625 1.625 1.63 1.63 0 0 1-1.625-1.625A1.63 1.63 0 0 1 10.5 8.625M4 17.563c0-2.162 4.33-3.25 6.5-3.25S17 15.4 17 17.563V20H4zm1.625.008v.804h9.75v-.812c-.162-.577-2.681-1.625-4.875-1.625s-4.712 1.048-4.875 1.633"
            clipRule="evenodd"
          />
        </svg>
      }
      {...props}
    />
  );
};

export default React.memo(DSL_Fatigue);
