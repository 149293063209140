import React from 'react';
import {useSidebar} from './useSidebar';
import {SidebarView} from './Sidebar.View';

export interface SidebarProps {
  isOpen: boolean;
  toggleIsOpen: () => void;
}

export const Sidebar: React.FC<SidebarProps> = ({isOpen, toggleIsOpen}) => {
  const sidebarProps = useSidebar();

  return (
    <SidebarView
      isOpen={isOpen}
      toggleIsOpen={toggleIsOpen}
      {...sidebarProps}
    />
  );
};
