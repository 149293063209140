import React from 'react';

import {DSL_BaseTypography, DSL_BaseTypographyProps} from '../BaseTypography';

export type DSL_OverlineProps = Omit<DSL_BaseTypographyProps, 'variant'>;

export const DSL_Overline = (props: DSL_OverlineProps) => (
  <DSL_BaseTypography {...props} variant={'overline'} />
);

export {
  //
  /** @deprecated use DSL_OverlineProps instead*/
  DSL_OverlineProps as OverlineProps, //
  /** @deprecated use DSL_Overline instead*/
  DSL_Overline as Overline,
};
