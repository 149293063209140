import React from 'react';

import {DSP_CustomerAccountEntityAvatarWithNameView} from './CustomerAccountEntityAvatarWithName.View';
import {useCustomerAccountEntityAvatarWithName} from './useCustomerAccountEntityAvatarWithName';

export const DSP_CustomerAccountEntityAvatarWithName = () => {
  const viewProps = useCustomerAccountEntityAvatarWithName();

  return <DSP_CustomerAccountEntityAvatarWithNameView {...viewProps} />;
};

DSP_CustomerAccountEntityAvatarWithName.displayName =
  'DSP_CustomerAccountEntityAvatarWithName';
