import {useCallback, useRef, useState} from 'react';

import {ReCAPTCHA} from 'react-google-recaptcha';
// TODO: move this util into DS
import {getRecaptchaSiteKey} from 'utils/global';

const recaptchaSiteKey = getRecaptchaSiteKey();
const recaptchaEnabled = recaptchaSiteKey !== '';

export function useDspRecaptcha() {
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [token, setToken] = useState<string | null>();

  const fetchToken = useCallback(async () => {
    let newToken: string | null = null;

    if (recaptchaEnabled) {
      try {
        newToken = (await recaptchaRef?.current?.executeAsync()) ?? null;
        setToken(newToken);
      } catch (err) {
        // handle error
      }
    }
    return newToken;
  }, [recaptchaRef]);

  return {
    recaptchaEnabled,
    recaptchaRef,
    fetchToken,
    recaptchaSiteKey,
    token,
  };
}

export type DSP_UseRecaptcha = ReturnType<typeof useDspRecaptcha>;
