import React from 'react';

import {styled} from '@mui/material';
import ReactGoogleReCAPTCHA, {
  ReCAPTCHAProps as ReactGoogleReCAPTCHAProps,
} from 'react-google-recaptcha';

import {DSL_ExternalLink, DSL_Body2} from '../..';

export type DSL_RecaptchaProps = Omit<
  ReactGoogleReCAPTCHAProps,
  'ref' | 'theme'
> & {
  reCaptchaRef?: React.Ref<ReactGoogleReCAPTCHA>;
};

const DSL_StyledCaptcha = styled(
  ({reCaptchaRef, ...props}: DSL_RecaptchaProps) => (
    <ReactGoogleReCAPTCHA ref={reCaptchaRef} {...props} />
  ),
)({
  '& .grecaptcha-badge': {visibility: 'hidden'},
});

export const DSL_Recaptcha = ({reCaptchaRef, ...props}: DSL_RecaptchaProps) => {
  return (
    <DSL_Body2 variantMapping={{body2: 'div'}}>
      {'This site is protected by reCAPTCHA and the Google '}
      <DSL_ExternalLink href="https://policies.google.com/privacy">
        Privacy Policy
      </DSL_ExternalLink>
      {' and '}
      <DSL_ExternalLink href="https://policies.google.com/terms">
        Terms of Service
      </DSL_ExternalLink>
      {' apply.'}
      <DSL_StyledCaptcha
        reCaptchaRef={reCaptchaRef}
        badge="bottomright"
        size={'invisible'}
        {...props}
      />
    </DSL_Body2>
  );
};

DSL_Recaptcha.displayName = 'DSL_Recaptcha';

export {
  //
  /** @deprecated use DSL_RecaptchaProps instead*/
  DSL_RecaptchaProps as RecaptchaProps, //
  /** @deprecated use DSL_Recaptcha instead*/
  DSL_Recaptcha as Recaptcha,
};
