import React from 'react';

import {
  Body1,
  ButtonBarGroup,
  ContainedButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FlexColumn,
  FlexRow,
  OutlinedButton,
} from 'components/DesignSystem/Library';

import {DSP_TextField} from '../../TextField';
import {DSP_FormSaveDialogProps} from '../FormSaveDialog.Connector';

export interface DSP_FormSaveDialogSavingToArchiveViewProps
  extends DSP_FormSaveDialogProps {
  isConfirmButtonDisabled?: boolean;
  onConfirm: () => void;
  submitConfirmMessage?: string;
}

export const DSP_FormSaveDialogSavingToArchiveView = ({
  submitConfirmMessage,
  isConfirmButtonDisabled,
  onConfirm,
  onClose,
  ...props
}: DSP_FormSaveDialogSavingToArchiveViewProps) => (
  <Dialog
    aria-labelledby={'form-save-dialog-title'}
    onClose={onClose}
    {...props}
  >
    <DialogTitle
      id={'form-save-dialog-title'}
      title={'Confirm'}
      onClose={onClose}
    />
    <DialogContent>
      <FlexColumn gap={2}>
        <Body1>
          The record will be archived and hidden from the default view. Please
          provide a justification to continue.
        </Body1>
        <FlexRow>
          <DSP_TextField fieldRef="#/reasonForArchive" />
        </FlexRow>
      </FlexColumn>
    </DialogContent>
    <DialogActions>
      <ButtonBarGroup>
        <ContainedButton
          onClick={onConfirm}
          disabled={isConfirmButtonDisabled}
          autoFocus
        >
          Confirm
        </ContainedButton>
        <OutlinedButton onClick={onClose}>Cancel</OutlinedButton>
      </ButtonBarGroup>
    </DialogActions>
  </Dialog>
);

DSP_FormSaveDialogSavingToArchiveView.displayName =
  'DSP_FormSaveDialogSavingToArchiveView';

export {
  //
  /** @deprecated use DSP_FormSaveDialogSavingToArchiveViewProps instead*/
  DSP_FormSaveDialogSavingToArchiveViewProps as FormSaveDialogSavingToArchiveViewProps, //
  /** @deprecated use DSP_FormSaveDialogSavingToArchiveView instead*/
  DSP_FormSaveDialogSavingToArchiveView as FormSaveDialogSavingToArchiveView,
};
