import React from 'react';

import {UserProfile} from '@regulatory-platform/common-utils/dist';

import {DSP_ProfileMenuView} from './ProfileMenu.View';
import {useProfileMenu} from './useProfileMenu';

export interface DSP_ProfileMenuProps {
  userProfile: UserProfile;
  logoutUrl?: string;
}

export const DSP_ProfileMenu = ({
  userProfile,
  logoutUrl,
}: DSP_ProfileMenuProps) => {
  const props = useProfileMenu(userProfile, logoutUrl);
  return <DSP_ProfileMenuView {...props} />;
};

DSP_ProfileMenu.displayName = 'DSP_ProfileMenu';

export {
  //
  /** @deprecated use DSP_ProfileMenuProps instead*/
  DSP_ProfileMenuProps as ProfileMenuProps, //
  /** @deprecated use DSP_ProfileMenu instead*/
  DSP_ProfileMenu as ProfileMenu,
};
