import React from 'react';

import {DSP_CloseButtonStackView} from './CloseButtonStack.View';
import {useCloseButtonStack} from './useCloseButtonStack';
export const DSP_CloseButtonStack = () => {
  const viewProps = useCloseButtonStack();
  return <DSP_CloseButtonStackView {...viewProps} />;
};

DSP_CloseButtonStack.displayName = 'DSP_CloseButtonStack';

export {
  //
  /** @deprecated use DSP_CloseButtonStack instead*/
  DSP_CloseButtonStack as CloseButtonStack,
};
