import React from 'react';

import {DSL_BaseInput, DSL_BaseInputProps} from '../BaseInput';

export type DSL_TextInputProps = Omit<
  DSL_BaseInputProps,
  'multiline' | 'rows' | 'maxRows' | 'minRows' | 'type'
>;

export const DSL_TextInput = (props: DSL_TextInputProps) => {
  return <DSL_BaseInput type={'text'} {...props} />;
};

DSL_TextInput.displayName = 'DSL_TextInput';

export {
  //
  /** @deprecated use DSL_TextInputProps instead*/
  DSL_TextInputProps as TextInputProps, //
  /** @deprecated use DSL_TextInput instead*/
  DSL_TextInput as TextInput,
};
