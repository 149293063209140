import React from 'react';

import * as R from 'ramda';

import {DSL_BaseBox, DSL_BaseBoxProps} from './BaseBox';

const internalBoxProps = ['flexRow', 'flexColumn', 'cursor'] as const;

export type DSL_BoxProps = Omit<
  DSL_BaseBoxProps,
  'flexRow' | 'flexColumn' | 'cursor'
>;

export const DSL_Box = React.forwardRef((props: DSL_BoxProps, ref) => {
  const cleanedProps = R.omit(internalBoxProps, props);

  return <DSL_BaseBox {...cleanedProps} ref={ref} />;
}) as React.FC<DSL_BoxProps>;

DSL_Box.displayName = 'DSL_Box';

export {
  //
  /** @deprecated use DSL_BoxProps instead*/
  DSL_BoxProps as BoxProps, //
  /** @deprecated use DSL_Box instead*/
  DSL_Box as Box,
};
