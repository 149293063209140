import React from 'react';

import {AuthorisationAccountTypes} from '@regulatory-platform/common-utils/dist';

import {accountTypeEntityComponentMap} from '../constants';

export interface DSP_AccountEntityNameProps {
  entityId: number | undefined;
  accountType: AuthorisationAccountTypes | undefined;
}

export const DSP_AccountEntityName = ({
  entityId,
  accountType,
}: DSP_AccountEntityNameProps) => {
  if (!entityId || !accountType) {
    return null;
  }

  const Components = accountTypeEntityComponentMap[accountType];

  if (!Components) {
    return null;
  }

  return (
    <Components.LoaderProvider entityId={entityId}>
      <Components.Name />
    </Components.LoaderProvider>
  );
};

DSP_AccountEntityName.displayName = 'DSP_AccountEntityName';
