import React from 'react';

import {
  DSL_RelatedRecord,
  DSL_RestrictedVisualProps,
} from 'components/DesignSystem/Library/types';

import {
  DSL_MultiSelectAutocompleteInput,
  DSL_MultiSelectAutocompleteInputProps,
} from '../MultiSelectAutocompleteInput';

export type DSL_SearchMultiSelectAutocompleteInputProps = Omit<
  DSL_MultiSelectAutocompleteInputProps<DSL_RelatedRecord>,
  DSL_RestrictedVisualProps
>;

export const DSL_SearchMultiSelectAutocompleteInput = ({
  options = [],
  ...props
}: DSL_SearchMultiSelectAutocompleteInputProps) => {
  return (
    <DSL_MultiSelectAutocompleteInput<DSL_RelatedRecord>
      clearOnBlur={false}
      includeInputInList
      selectOnFocus
      options={options}
      {...props}
    />
  );
};

DSL_SearchMultiSelectAutocompleteInput.displayName =
  'DSL_SearchMultiSelectAutocompleteInput';
