import React from 'react';

import {Theme, useTheme} from '@mui/material';

import {DSL_PaddingProps} from '../../types';
import {DSL_Box, DSL_BoxProps} from '../Box';

import {
  DSL_ScrollableLayoutSize,
  useScrollableLayoutPadding,
} from './useScrollableLayoutPadding';

export interface DSL_ScrollableLayoutFooterProps
  extends Omit<
    DSL_BoxProps,
    DSL_PaddingProps | 'width' | 'flexShrink' | 'boxSizing'
  > {
  padding?: DSL_ScrollableLayoutSize;
  withKeylineBorder?: boolean;
}

export const DSL_ScrollableLayoutFooter = ({
  padding = 'SM',
  withKeylineBorder = false,
  internal_sx,
  ...props
}: DSL_ScrollableLayoutFooterProps) => {
  const paddingStyle = useScrollableLayoutPadding(padding);
  const theme: Theme = useTheme();
  return (
    <DSL_Box
      flexShrink={0}
      width={'100%'}
      boxSizing={'border-box'}
      internal_sx={{
        ...internal_sx,
        ...(withKeylineBorder && {
          borderTop: `1px solid ${theme.palette.divider}`,
        }),
      }}
      {...props}
      {...paddingStyle}
    />
  );
};

DSL_ScrollableLayoutFooter.displayName = 'DSL_ScrollableLayoutFooterProps';
