import React from 'react';

import {DSL_BaseEmptyState, DSL_BaseEmptyStateProps} from '../BaseEmptyState';

import {DSL_FavouritesEmptyStateIcon} from './FavouritesEmptyStateIcon';

export type DSL_FavouritesEmptyStateProps = Omit<
  DSL_BaseEmptyStateProps,
  'icon'
>;

export const DSL_FavouritesEmptyState = (
  props: DSL_FavouritesEmptyStateProps,
) => {
  return (
    <DSL_BaseEmptyState icon={<DSL_FavouritesEmptyStateIcon />} {...props} />
  );
};

DSL_FavouritesEmptyState.displayName = 'DSL_FavouritesEmptyState';

export {
  //
  /** @deprecated use DSL_FavouritesEmptyStateProps instead*/
  DSL_FavouritesEmptyStateProps as FavouritesEmptyStateProps, //
  /** @deprecated use DSL_FavouritesEmptyState instead*/
  DSL_FavouritesEmptyState as FavouritesEmptyState,
};
