import React from 'react';

import {
  ButtonGroup as MuiButtonGroup,
  ButtonGroupProps as MuiButtonGroupProps,
} from '@mui/material';

import {DSL_RestrictedVisualProps} from '../../types';

export type DSL_ButtonGroupProps = Omit<
  MuiButtonGroupProps,
  DSL_RestrictedVisualProps
>;

export const DSL_ButtonGroup = React.forwardRef(
  (props: DSL_ButtonGroupProps, ref): JSX.Element => (
    <MuiButtonGroup {...props} ref={ref} />
  ),
) as React.FC<DSL_ButtonGroupProps>;

DSL_ButtonGroup.displayName = 'DSL_ButtonGroup';

export {
  //
  /** @deprecated use DSL_ButtonGroupProps instead*/
  DSL_ButtonGroupProps as ButtonGroupProps, //
  /** @deprecated use DSL_ButtonGroup instead*/
  DSL_ButtonGroup as ButtonGroup,
};
