import React, {ReactNode} from 'react';

import {Breadcrumbs as MuiBreadcrumbs} from '@mui/material';

export interface DSL_BreadcrumbsProps {
  children?: ReactNode[];
}

export const DSL_Breadcrumbs = ({children = []}: DSL_BreadcrumbsProps) => {
  return (
    <MuiBreadcrumbs aria-label="breadcrumbs" maxItems={3}>
      {children}
    </MuiBreadcrumbs>
  );
};

DSL_Breadcrumbs.displayName = 'DSL_Breadcrumbs';

export {
  //
  /** @deprecated use DSL_BreadcrumbsProps instead*/
  DSL_BreadcrumbsProps as BreadcrumbsProps, //
  /** @deprecated use DSL_Breadcrumbs instead*/
  DSL_Breadcrumbs as Breadcrumbs,
};
