import React from 'react';

import {DSP_CustomerAccountEntityNameView} from './CustomerAccountEntityName.View';
import {useCustomerAccountEntityName} from './useCustomerAccountEntityName';

export const DSP_CustomerAccountEntityName = () => {
  const viewProps = useCustomerAccountEntityName();

  return <DSP_CustomerAccountEntityNameView {...viewProps} />;
};

DSP_CustomerAccountEntityName.displayName = 'DSP_CustomerAccountEntityName';
