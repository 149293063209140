import DataUsage from '@mui/icons-material/Dashboard';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/pbsDocumentTemplates',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "PbsDocumentTemplates" */ './PbsDocumentTemplates'
      ),
  ),
  visibleInMenu: true,
  hideInProduction: false,
  menuTitle: 'PBS Document Templates',
  menuIcon: DataUsage,
  authorisation: {
    apiKey: null,
    policies: [{role: 'operationsPBSAdmin'}] as AuthorisationPolicy[],
  },
  category: 'Admin',
  sections: [],
};
