import {RefObject, useEffect} from 'react';

import {noOp} from 'utils/noOp';

export function useScrollObserver(
  ref: RefObject<HTMLElement>,
  onScroll: (ref: HTMLElement, event: Event) => void = noOp,
) {
  useEffect(() => {
    const current = ref.current;

    if (!current) {
      return;
    }

    const listener: EventListener = event => onScroll(current, event);

    current.addEventListener('scroll', listener);

    return () => {
      current.removeEventListener('scroll', listener);
    };
  }, [onScroll, ref]);
}
