import {ReactNode} from 'react';

import {useSelector} from '@xstate/react';
import * as R from 'ramda';
import {FormMachineContext} from 'utils/stores/types';

import {useFormContext} from '../FormProvider';

import {
  DSP_FormContainerMode,
  DSP_FormPageContainerViewProps,
} from './FormPageContainer.View';

export interface DSP_UseFormPageContainerArgs
  extends Omit<DSP_FormPageContainerViewProps, 'mode'>,
    UseFormPageContainerProps {
  renderButtonStack?: (mode: DSP_FormContainerMode) => ReactNode;
}

export interface UseFormPageContainerProps {
  title?: ReactNode;
  mode?: DSP_FormContainerMode;
}

export const useFormPageContainer = ({
  title,
  mode: overrideMode,
}: UseFormPageContainerProps) => {
  const {service, canSave, isCreate} = useFormContext();

  return useSelector(
    service,
    state => {
      const isLoading = R.isNil(state) || state?.matches('loading');

      const isAuthorisedUpdateById =
        state?.context?.authorisations?.updateById === true;

      const readOnly = !isAuthorisedUpdateById || !canSave;

      const unauthorised =
        state?.matches('notFound') || state?.matches('unauthorised');

      return {
        mode:
          overrideMode ??
          ((isLoading
            ? 'loading'
            : unauthorised
            ? 'unauthorised'
            : readOnly
            ? 'readonly'
            : isCreate
            ? 'create'
            : 'update') as DSP_FormContainerMode),
        title: R.is(Function, title) // TODO: need to look at deprecating the title as a function use case and possibly passing via form context
          ? (title as (context: FormMachineContext) => ReactNode)(
              state?.context,
            )
          : title,
      };
    },
    R.equals,
  );
};
