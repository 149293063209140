import React, {ReactNode} from 'react';

import {DSL_DrawerLoadingState} from './DrawerLoadingState';

export interface DSL_DrawerLoaderProps {
  isLoading: boolean;
  children?: ReactNode;
}

export const DSL_DrawerLoader = ({
  isLoading,
  children,
}: DSL_DrawerLoaderProps) => {
  return isLoading ? <DSL_DrawerLoadingState /> : <>{children}</>;
};

DSL_DrawerLoader.displayName = 'DSL_DrawerLoader';

export {
  //
  /** @deprecated use DSL_DrawerLoaderProps instead*/
  DSL_DrawerLoaderProps as DrawerLoaderProps, //
  /** @deprecated use DSL_DrawerLoader instead*/
  DSL_DrawerLoader as DrawerLoader,
};
