import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/accessInstruments/:accessInstrumentId/createAccessProduct',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "CreateAccessProduct" */ 'containers-access/AccessProduct/CreateAccessProduct/CreateAccessProduct'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Add reference vehicle',
  menuIcon: LocalShippingOutlinedIcon,
  authorisation: {
    apiKey: 'AccessProductWithRelations.create',
    policies: [
      {
        role: 'operationsAccessSupervisor',
      },
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Access instruments',
      to: '../..',
    },
    {
      content: 'Manage access instrument',
      to: '..',
      state: {'Access instrument tabs': 'Reference vehicles'},
    },
  ],
};
