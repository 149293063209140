import React from 'react';

import {TableHead} from '@mui/material';
import {DSL_DeleteIconButton} from 'components/DesignSystem/Library/Buttons/IconButtons/DeleteIconButton';
import {DSL_DownloadIconButton} from 'components/DesignSystem/Library/Buttons/IconButtons/DownloadIconButton';
import {DSL_EditIconButton} from 'components/DesignSystem/Library/Buttons/IconButtons/EditIconButton';
import {DSL_FlexRow} from 'components/DesignSystem/Library/Layout/FlexRow';
import {DSL_Table} from 'components/DesignSystem/Library/Table';
import {DSL_TableBody} from 'components/DesignSystem/Library/Table/TableBody';
import {DSL_TableCell} from 'components/DesignSystem/Library/Table/TableCell';
import {DSL_TableContainer} from 'components/DesignSystem/Library/Table/TableContainer';
import {DSL_TableRow} from 'components/DesignSystem/Library/Table/TableRow';
import {DateTime} from 'luxon';
import prettyBytes from 'pretty-bytes';
import * as R from 'ramda';
// TODO: need to decouple this type dependency
import {File} from 'typeDefs/types';

export interface DSL_UploadedFileType extends File {
  progress?: number;
  error?: boolean;
}

export interface DSL_UploadedFilesProps<T> {
  files?: T[];
  onFileEdit?: (file: T) => void;
  onFileRemove?: (file: T) => void;
  blobUrl: (name: string, filePrefix: string, progress?: number) => void;
}

const columns = [
  {label: 'Filename', width: 80},
  {label: 'Description', width: 80},
  {label: 'Type', width: 80},
  {label: 'Size', width: 80},
  {label: 'Attached', width: 80},
  {label: '', width: 160},
];

const columnsNoEdit = [
  {label: 'Filename', width: 80},
  {label: 'Type', width: 80},
  {label: 'Size', width: 80},
  {label: 'Attached', width: 80},
  {label: '', width: 160},
];

export const DSL_UploadedFiles = <T extends DSL_UploadedFileType>({
  files,
  onFileEdit,
  onFileRemove,
  blobUrl,
}: DSL_UploadedFilesProps<T>) => {
  if (!files || files.length === 0) {
    return null;
  }

  return (
    <DSL_TableContainer>
      <DSL_Table aria-label="Uploaded files table">
        <TableHead>
          <DSL_TableRow>
            {(!R.isNil(onFileEdit) ? columns : columnsNoEdit).map(
              (column, index) => (
                <DSL_TableCell key={index} minWidth={column.width}>
                  {column.label}
                </DSL_TableCell>
              ),
            )}
          </DSL_TableRow>
        </TableHead>
        <DSL_TableBody>
          {files?.map((file, rowIndex) => (
            <DSL_TableRow key={rowIndex}>
              <DSL_TableCell verticalAlign={'center'}>
                {file?.name}
              </DSL_TableCell>
              {onFileEdit && (
                <DSL_TableCell verticalAlign={'center'}>
                  {file?.note}
                </DSL_TableCell>
              )}
              <DSL_TableCell verticalAlign={'center'}>
                {file?.originalFileName?.split('.').pop()?.toUpperCase()}
              </DSL_TableCell>
              <DSL_TableCell verticalAlign={'center'}>
                {prettyBytes(file?.size)}
              </DSL_TableCell>
              <DSL_TableCell verticalAlign={'center'}>
                {DateTime.fromISO(file?.uploaded).toFormat('dd/MM/yyyy h:mm a')}
              </DSL_TableCell>
              <DSL_TableCell>
                <DSL_FlexRow
                  gap={0}
                  alignItems={'center'}
                  justifyContent={'flex-end'}
                >
                  <DSL_DownloadIconButton
                    onClick={() =>
                      blobUrl?.(file.name, file.prefix, file.progress)
                    }
                  />
                  {onFileEdit && (
                    <DSL_EditIconButton
                      onClick={() => {
                        onFileEdit?.(file);
                      }}
                    />
                  )}
                  <DSL_DeleteIconButton onClick={() => onFileRemove?.(file)} />
                </DSL_FlexRow>
              </DSL_TableCell>
            </DSL_TableRow>
          ))}
        </DSL_TableBody>
      </DSL_Table>
    </DSL_TableContainer>
  );
};

export {
  //
  /** @deprecated use DSL_UploadedFilesProps instead*/
  DSL_UploadedFilesProps as UploadedFilesProps, //
  /** @deprecated use DSL_UploadedFiles instead*/
  DSL_UploadedFiles as UploadedFiles,
};
