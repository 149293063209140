import * as R from 'ramda';

export default function getQueryParams(qryString: string): {
  [key: string]: string;
} {
  return qryString
    .replace('?', '')
    .split('&')
    .reduce((qryObj, param) => {
      const [key, value] = param.split('=') as [string, string];
      return R.assoc(key, decodeURIComponent(value), qryObj);
    }, {});
}
