import {FormEvent, FormEventHandler} from 'react';

import * as R from 'ramda';

import {useFormContext} from '../FormProvider';

export interface DSP_UseSimpleFormContainerArgs {
  onSubmit?: FormEventHandler<HTMLFormElement>;
  machineSubmitEvent?: string;
}

export function useSimpleFormContainer({
  onSubmit: onSubmitInput,
  machineSubmitEvent = 'SUBMIT',
}: DSP_UseSimpleFormContainerArgs) {
  const {service} = useFormContext();

  const onSubmit = R.defaultTo((e: FormEvent<HTMLFormElement>) => {
    service.send(machineSubmitEvent);
    e.preventDefault();
  }, onSubmitInput);

  return {
    onSubmit,
    service,
  };
}
