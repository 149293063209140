import React, {CSSProperties} from 'react';

import {Paper as MuiPaper, PaperProps as MuiPaperProps} from '@mui/material';

export interface DSL_PaperProps extends MuiPaperProps {
  borderRadius?: CSSProperties['borderRadius'];
}

export const DSL_Paper = ({borderRadius, ...props}: DSL_PaperProps) => {
  return (
    <MuiPaper
      variant={'outlined'}
      sx={{borderRadius: borderRadius}}
      {...props}
    />
  );
};

DSL_Paper.displayName = 'DSL_Paper';

export {
  //
  /** @deprecated use DSL_PaperProps instead*/
  DSL_PaperProps as PaperProps, //
  /** @deprecated use DSL_Paper instead*/
  DSL_Paper as Paper,
};
