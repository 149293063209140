import React, {useMemo} from 'react';

import {RoadSegmentsLengthLoaderProvider} from 'containers-spatial/NetworkManagement/drawers/SelectedSegmentsDrawer/RoadSegmentsLength/RoadSegmentsLengthLoaderProvider';

import {DSL_FlexRow, DSL_Subtitle1} from 'components/DesignSystem/Library';

import {DSP_SegmentAccessTypeIcon} from '../SegmentAccessTypeIcon';
import {useDspSelectedSegmentsDrawerContext} from '../SelectedSegmentsDrawer.Provider';

import {useDspSelectedSegmentsSummary} from './useSelectedSegmentsSummary';

export const DSP_SelectedSegmentsSummary = () => {
  const {summary, segmentIds, showSegmentAccessTypeIcon} =
    useDspSelectedSegmentsSummary();
  const {RenderRoadSegmentsLengthComponent} =
    useDspSelectedSegmentsDrawerContext();

  const MemorizedRoadSegmentsLengthComponent = useMemo(
    () =>
      RenderRoadSegmentsLengthComponent &&
      !!segmentIds?.length && (
        <RoadSegmentsLengthLoaderProvider segmentIds={segmentIds}>
          <RenderRoadSegmentsLengthComponent
            prefix={' '}
            showSkeletonLoader={false}
          />
        </RoadSegmentsLengthLoaderProvider>
      ),
    [RenderRoadSegmentsLengthComponent, segmentIds],
  );

  return (
    <DSL_FlexRow alignItems={'center'} gap={0}>
      <DSL_Subtitle1 color={'text.primary'} bold>
        {summary}
      </DSL_Subtitle1>
      {MemorizedRoadSegmentsLengthComponent}
      {showSegmentAccessTypeIcon && <DSP_SegmentAccessTypeIcon />}
    </DSL_FlexRow>
  );
};

DSP_SelectedSegmentsSummary.displayName = 'DSP_SelectedSegmentsSummary';
