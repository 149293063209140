import {useDspSelectedSegmentsDrawerContext} from '../SelectedSegmentsDrawer.Provider';

export const useDspSelectedSegmentsDrawerContents = () => {
  const {hasSelectedRoadId, hasFilterByAccessType, showSingleListView} =
    useDspSelectedSegmentsDrawerContext();

  return {
    showSingleListView,
    showViewByRoadsOrAccessTypeSelect:
      !showSingleListView && !hasSelectedRoadId && !hasFilterByAccessType,
    showAccessTypeSelect: showSingleListView || hasSelectedRoadId,
  };
};
