import React, {FC, ReactNode, useMemo} from 'react';

import {DSL_FlexRow} from '../../Layout/FlexRow';

export interface DSL_ButtonBarProps {
  children?: ReactNode;
}

export const DSL_ButtonBar: FC<DSL_ButtonBarProps> = ({
  children,
}: DSL_ButtonBarProps): JSX.Element => {
  const hasMultipleChildren = useMemo(
    () => Array.isArray(children) && children.filter(c => !!c).length > 1,
    [children],
  );

  return (
    <DSL_FlexRow
      gap={0}
      width={'100%'}
      justifyContent={hasMultipleChildren ? 'space-between' : 'flex-end'}
    >
      {children}
    </DSL_FlexRow>
  );
};

export {
  //
  /** @deprecated use DSL_ButtonBarProps instead*/
  DSL_ButtonBarProps as ButtonBarProps, //
  /** @deprecated use DSL_ButtonBar instead*/
  DSL_ButtonBar as ButtonBar,
};
