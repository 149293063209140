import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/afmApplications/:id',
  component: lazy(
    () =>
      import(/* webpackChunkName: "NewAfmApplication" */ './NewAfmApplication'),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'AFM application',
  menuIcon: LocalShippingOutlinedIcon,
  authorisation: {
    apiKey: 'AfmApplicationWithRelations.findById',
    policies: [
      {role: 'operationsAccreditationRead'},
      {role: 'customerAccreditationRead'},
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'AFM Applications',
      to: '..',
    },
  ],
};
