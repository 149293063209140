import React, {useRef} from 'react';

import {
  Timeline as MuiTimeline,
  TimelineProps as MuiTimelineProps,
} from '@mui/lab';
import {timelineOppositeContentClasses} from '@mui/lab/TimelineOppositeContent';

import {DSL_RestrictedVisualProps} from '../types';

export type DSL_TimelineProps = Omit<
  MuiTimelineProps,
  DSL_RestrictedVisualProps
> & {
  oppositeContentFlex?: number;
};

export const DSL_Timeline = ({
  oppositeContentFlex,
  ...props
}: DSL_TimelineProps) => {
  //required otherwise causes type errors
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const node = useRef<HTMLUListElement>(null) as any;
  return (
    <MuiTimeline
      ref={node}
      sx={{
        p: 0,
        m: 0,
        ...(oppositeContentFlex && {
          [`& .${timelineOppositeContentClasses.root}`]: {
            flex: oppositeContentFlex,
          },
        }),
      }}
      {...props}
    />
  );
};

DSL_Timeline.displayName = 'DSL_Timeline';

export {
  //
  /** @deprecated use DSL_TimelineProps instead*/
  DSL_TimelineProps as TimelineProps, //
  /** @deprecated use DSL_Timeline instead*/
  DSL_Timeline as Timeline,
};
