import React from 'react';

import {Badge, BadgeProps} from '@mui/material';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

export interface DSL_AccordionBadgeCountProps
  extends Omit<BadgeProps, DSL_RestrictedVisualProps> {
  badgeCount: number;
}

// TODO: This needs styling but we are awaiting Jit's design

export const DSL_AccordionBadgeCount = ({
  badgeCount,
  ...badgeProps
}: DSL_AccordionBadgeCountProps) => {
  return (
    <Badge
      overlap="circular"
      badgeContent={<>{badgeCount}</>}
      {...badgeProps}
    />
  );
};

DSL_AccordionBadgeCount.displayName = 'DSL_AccordionBadgeCount';

export {
  //
  /** @deprecated use DSL_AccordionBadgeCountProps instead*/
  DSL_AccordionBadgeCountProps as AccordionBadgeCountProps, //
  /** @deprecated use DSL_AccordionBadgeCount instead*/
  DSL_AccordionBadgeCount as AccordionBadgeCount,
};
