import React, {useState} from 'react';

import {
  Avatar as MuiAvatar,
  AvatarProps as MuiAvatarProps,
} from '@mui/material';

import {
  DSL_RestrictedVisualProps,
  DSL_ThemeSizeKey,
  DSL_ThemeSizeMap,
} from 'components/DesignSystem/Library/types';

const sizeMap: DSL_ThemeSizeMap<string> = {
  XS: '1rem',
  SM: '1.5rem',
  MD: '2.5rem',
  LG: '3rem',
  XL: '4rem',
} as const;

export interface DSL_AvatarProps
  extends Omit<MuiAvatarProps, DSL_RestrictedVisualProps> {
  size?: DSL_ThemeSizeKey;
  bgcolor?: string;
}

export const DSL_Avatar = ({
  size = 'MD',
  bgcolor,
  alt,
  children,
  imgProps,
  src,
  ...props
}: DSL_AvatarProps) => {
  const [hasImageLoaded, setHasImageLoaded] = useState<boolean>(false);
  const avatarSize = sizeMap[size];
  const finalBGcolor = hasImageLoaded && src ? 'white' : bgcolor;

  return (
    <MuiAvatar
      alt={alt}
      sx={{
        bgcolor: finalBGcolor,
        width: avatarSize,
        height: avatarSize,
      }}
      src={src}
      imgProps={{
        ...imgProps,
        onLoad: () => {
          setHasImageLoaded(true);
        },
      }}
      {...props}
    >
      {children ?? alt?.[0]?.toUpperCase()}
    </MuiAvatar>
  );
};

DSL_Avatar.displayName = 'DSL_Avatar';

export {
  //
  /** @deprecated use DSL_AvatarProps instead*/
  DSL_AvatarProps as AvatarProps, //
  /** @deprecated use DSL_Avatar instead*/
  DSL_Avatar as Avatar,
};
