import React from 'react';

import {accreditationApplicationAccountTypeNotificationRendererMap} from './constants';
import {DSP_AccreditationApplicationNotificationItem} from './types';

export interface DSP_AccreditationApplicationNotificationCardProps {
  notification: DSP_AccreditationApplicationNotificationItem;
}

export const DSP_AccreditationApplicationNotificationCard = ({
  notification,
}: DSP_AccreditationApplicationNotificationCardProps) => {
  const DSP_NotificationComponent =
    accreditationApplicationAccountTypeNotificationRendererMap[
      notification.accountType
    ];

  if (!DSP_NotificationComponent) {
    return null;
  }

  return <DSP_NotificationComponent notification={notification} />;
};

DSP_AccreditationApplicationNotificationCard.displayName =
  'DSP_AccreditationApplicationNotificationCard';
