/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {DocumentNode, useApolloClient} from '@apollo/client';
import {ObjectType} from '@regulatory-platform/common-utils';
import {useMachine} from '@xstate/react';
import AppServicesContext from 'app/services/AppServicesContext';
import {
  actionVoter,
  baseSchema,
  validator,
} from 'common/global/entities/search/search';
import * as R from 'ramda';
import {useContext} from 'react';
import searchMachineActions, {MachineActions} from './searchMachineActions';
import searchMachineGuards from './searchMachineGuards';
import getFormStore from '../stores/getFormStore';
import {FormRecord} from '../stores/types';
import {RelatedRecord} from 'components/forms/SearchRelationBox/types';
import baseSearchMachine, {BaseSearchMachine} from './baseSearchMachine';

export default function useBaseSearchMachine(
  currentRecord: RelatedRecord,
  onSelect: (record: RelatedRecord) => void,
  query: DocumentNode,
  queryVariables = {},
  emptyRecord: RelatedRecord,
  searchResultToString: (searchResult?: RelatedRecord) => string,
  fixedRecords = [] as RelatedRecord[],
) {
  const appServices = useContext(AppServicesContext);
  const client = useApolloClient();

  const container = useMachine<BaseSearchMachine>(baseSearchMachine, {
    context: R.mergeRight(getFormStore<FormRecord>(baseSchema, validator), {
      props: {},
      data: fixedRecords,
      fixedRecords,
      storeFieldValue: currentRecord,
      fieldRef: '',
      searchResultToString: searchResultToString as (
        searchResult?: ObjectType,
      ) => string,
    }),
    guards: searchMachineGuards(),
    actions: R.mergeRight(
      searchMachineActions(baseSchema, validator, actionVoter, appServices),
      {
        onSelect: (_context, event) => {
          let relatedRecord = event.value as RelatedRecord;
          if (R.isNil(relatedRecord)) {
            relatedRecord = R.defaultTo({id: null}, emptyRecord);
          }
          onSelect(relatedRecord);
        },
      } as MachineActions,
    ),
    services: {
      apiSearch: context => {
        return client.query({
          query,
          variables: R.mergeRight({qs: context.record.search}, queryVariables),
          fetchPolicy: 'no-cache',
          errorPolicy: 'none',
        });
      },
      formStoreStream: () => () => {
        //
      },
    },
    delays: {
      DEBOUNCE_INPUT_DELAY: () => 1000,
    },
  });
  return container;
}
