import {useSelector} from '@xstate/react';
import FormContext from 'app/stores/FormContext';
import useTabs, {useTabsResults} from 'components/navigation/Tabs/useTabs';
import SaveOrCancelButtonStack from 'components/templates/form/SaveOrCancelButtonStack';
import * as R from 'ramda';
import React, {useContext} from 'react';
import {FormContextProps, FormMachineContext} from 'utils/stores/types';
import {FormTabContainerProps, TabStep} from '../types';
import isValidStep from '../utils/isValidStep';
import Grid from '@mui/material/Grid';
import {
  DSL_TabContext,
  DSL_TabList,
  DSL_Tab,
  DSL_TabPanel,
} from 'components/DesignSystem/Library';

export interface TabStepComponentProps {
  onStepChange: useTabsResults[1];
  label: string;
  tabIndex: number;
  validateFields?: string[];
  disabled?: boolean;
}

/**
 * FormTabs component
 * @TODO: See if we can replace this with DSP_FormTabs
 */
const FormTabs: React.FC<FormTabContainerProps> = ({
  label,
  tabSteps: _tabSteps,
  disabled,
  submitConfirmMessage: _submitConfirmMessage,
  centered,
  orientation,
  scrollButtons,
  variant,
  onClick,
  saveOrCancelButtons = true,
  parentTabLabel = '',
  overrideTouched = false,
}: FormTabContainerProps) => {
  const context: FormContextProps = useContext(FormContext);
  const {service} = context;
  const state = useSelector(service, R.identity);

  let tabSteps = _tabSteps as TabStep[];
  if (R.is(Function, _tabSteps)) {
    tabSteps = (_tabSteps as (context: FormMachineContext) => TabStep[])(
      state.context,
    );
  }
  let submitConfirmMessage = _submitConfirmMessage as string;
  if (R.is(Function, _submitConfirmMessage)) {
    submitConfirmMessage = (
      _submitConfirmMessage as (context: FormMachineContext) => string
    )(state.context);
  }

  const [activeStep, onStepChange] = useTabs(label, tabSteps, service);

  const overrideTouchedProp =
    state.context.props.overrideTouched || overrideTouched;
  const tabTouched = R.defaultTo({}, state.context.props.tabTouched) as {
    [key: string]: boolean;
  };

  const isParentTabTouchedOrOverrideSet =
    tabTouched[parentTabLabel] || overrideTouchedProp;
  const activeHasErrors =
    (isParentTabTouchedOrOverrideSet || tabTouched[activeStep.label]) &&
    !isValidStep(state.context, R.defaultTo([], activeStep?.validateFields));

  /**
   * orientation = vertical
   */
  if (orientation === 'vertical') {
    return (
      <>
        <DSL_TabContext value={activeStep.label}>
          <Grid container spacing={2}>
            <Grid item xs={3} mt={3}>
              <DSL_TabList
                onChange={(
                  e: React.ChangeEvent<{}>,
                  tabLabel: string,
                ): void => {
                  onStepChange(tabLabel);
                  if (onClick) {
                    onClick(tabLabel);
                  }
                }}
                aria-label={label}
                centered={centered}
                orientation={orientation}
                scrollButtons={scrollButtons}
                variant={variant}
                error={activeHasErrors}
              >
                {tabSteps.map((step, idx) => {
                  const hasErrors =
                    (isParentTabTouchedOrOverrideSet ||
                      tabTouched[tabSteps[idx].label]) &&
                    !isValidStep(
                      state.context,
                      R.defaultTo([], tabSteps[idx].validateFields),
                    );

                  return (
                    <DSL_Tab
                      aria-invalid={hasErrors}
                      key={idx}
                      error={hasErrors}
                      label={step?.label}
                      tabIndex={idx}
                      value={step.label}
                      wrapped
                    />
                  );
                })}
              </DSL_TabList>
            </Grid>
            <Grid item xs={9}>
              {tabSteps.map((step, idx) => {
                const ActiveStepComponent = tabSteps[idx]
                  .component as new () => React.Component<
                  TabStepComponentProps,
                  unknown
                >;
                return (
                  <DSL_TabPanel key={idx} value={step.label}>
                    <ActiveStepComponent
                      onStepChange={onStepChange}
                      label={step.label}
                      tabIndex={idx}
                      validateFields={step.validateFields}
                      disabled={disabled}
                    />
                  </DSL_TabPanel>
                );
              })}
            </Grid>
          </Grid>
        </DSL_TabContext>
        {saveOrCancelButtons ? (
          <SaveOrCancelButtonStack
            disabled={disabled}
            service={service}
            submitConfirmMessage={submitConfirmMessage}
          />
        ) : null}
      </>
    );
  }

  return (
    <>
      <DSL_TabContext value={activeStep.label}>
        <DSL_TabList
          onChange={(e: React.ChangeEvent<{}>, tabLabel: string): void => {
            onStepChange(tabLabel);
            if (onClick) {
              onClick(tabLabel);
            }
          }}
          aria-label={label}
          centered={centered}
          orientation={orientation}
          scrollButtons={scrollButtons}
          variant={variant}
          error={activeHasErrors}
        >
          {tabSteps.map((step, idx) => {
            const hasErrors =
              (isParentTabTouchedOrOverrideSet ||
                tabTouched[tabSteps[idx].label]) &&
              !isValidStep(
                state.context,
                R.defaultTo([], tabSteps[idx].validateFields),
              );

            return (
              <DSL_Tab
                aria-invalid={hasErrors}
                key={idx}
                error={hasErrors}
                label={step?.label}
                tabIndex={idx}
                value={step.label}
              />
            );
          })}
        </DSL_TabList>
        {tabSteps.map((step, idx) => {
          const ActiveStepComponent = tabSteps[idx]
            .component as new () => React.Component<
            TabStepComponentProps,
            unknown
          >;
          return (
            <DSL_TabPanel key={idx} value={step.label}>
              <ActiveStepComponent
                onStepChange={onStepChange}
                label={step.label}
                tabIndex={idx}
                validateFields={step.validateFields}
                disabled={disabled}
              />
            </DSL_TabPanel>
          );
        })}
      </DSL_TabContext>
      {saveOrCancelButtons ? (
        <SaveOrCancelButtonStack
          disabled={disabled}
          service={service}
          submitConfirmMessage={submitConfirmMessage}
        />
      ) : null}
    </>
  );
};

export default FormTabs;
