import React from 'react';

import {DSL_BaseTypography, DSL_BaseTypographyProps} from '../BaseTypography';

export type DSL_Heading6Props = Omit<DSL_BaseTypographyProps, 'variant'>;
export const DSL_Heading6 = (props: DSL_Heading6Props) => (
  <DSL_BaseTypography {...props} variant={'h6'} />
);

export {
  //
  /** @deprecated use DSL_Heading6Props instead*/
  DSL_Heading6Props as Heading6Props, //
  /** @deprecated use DSL_Heading6 instead*/
  DSL_Heading6 as Heading6,
};
