import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import {lazy} from 'react';

export default {
  path: '/accessProducts',
  component: lazy(
    () => import(/* webpackChunkName: "AccessProducts" */ './AccessProducts'),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Reference vehicles',
  menuIcon: LibraryAddCheckIcon,
  authorisation: {
    apiKey: 'AccessProductWithRelations.findById',
    policies: [{role: 'operationsSpatialRead'}] as AuthorisationPolicy[],
  },
  sections: [],
};
