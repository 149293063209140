import React, {ReactNode} from 'react';

import {DSL_FlexRow} from '../Layout/FlexRow';
import {DSL_Body2} from '../Typography/Body2';

export interface DSL_BaseIconInfoProps {
  icon: ReactNode;
  label: ReactNode;
}

export const DSL_BaseIconInfo = ({icon, label}: DSL_BaseIconInfoProps) => {
  return (
    <DSL_FlexRow
      alignItems={'center'}
      color={'text.secondary'}
      width={'fit-content'}
      flexWrap={'nowrap'}
      gap={1}
    >
      {icon}
      <DSL_Body2 color={'text.secondary'}>{label}</DSL_Body2>
    </DSL_FlexRow>
  );
};

DSL_BaseIconInfo.displayName = 'DSL_BaseIconInfo';

export {
  //
  /** @deprecated use DSL_BaseIconInfoProps instead*/
  DSL_BaseIconInfoProps as BaseIconInfoProps, //
  /** @deprecated use DSL_BaseIconInfo instead*/
  DSL_BaseIconInfo as BaseIconInfo,
};
