import CircularProgress from '@mui/material/CircularProgress';
import SaveIcon from '@mui/icons-material/Save';
import {useSelector} from '@xstate/react';
import ButtonStack from 'components/forms/buttons/ButtonStack';
import PrimaryButton from 'components/forms/buttons/PrimaryButton';
import TertiaryButton from 'components/forms/buttons/TertiaryButton';
import FormSaveDialog from 'components/navigation/dialogs/FormSaveDialog';
import * as R from 'ramda';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import getStateMetaHead from 'utils/machines/utils/getStateMetaHead';
import isChanged from 'utils/stores/isChanged';
import {FormContextProps} from 'utils/stores/types';
import {schemaJsonPointerResolver} from '@regulatory-platform/common-utils/dist';

export type SaveOrCancelButtonStackProps = {
  service: FormContextProps['service'];
  onCancelLabel?: string;
  onUpdateLabel?: string;
  submitConfirmMessage?: string;
  disabled?: boolean;
};

/**
 * Form Button Stack
 */
const SaveOrCancelButtonStack: React.FC<SaveOrCancelButtonStackProps> = ({
  onUpdateLabel = 'Save',
  onCancelLabel = 'Cancel',
  submitConfirmMessage,
  service,
  disabled,
}: SaveOrCancelButtonStackProps) => {
  const navigate = useNavigate();
  // Submit confirm dialog
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const handleDialogClose = (): void => {
    setDialogOpen(false);
  };

  const {
    navigateTo,
    navigateState,
    loading,
    changed,
    checkingUniqueness,
    showArchiveDialog,
  } = useSelector(
    service,
    state => {
      const metaHead = getStateMetaHead(state);
      const _hasArchiveReasonField = !!schemaJsonPointerResolver(
        '#/reasonForArchive',
      )(state.context.schema);
      const _isSavingToArchive =
        state.matches('userInput.archived') || state.matches('archiving');

      const _showArchiveDialog = _isSavingToArchive && _hasArchiveReasonField;

      return {
        navigateTo: metaHead.navigateTo,
        navigateState: metaHead.navigateState,
        loading:
          state.matches('saving') ||
          state.matches('submitting') ||
          state.matches('archiving'),
        changed: isChanged(state.context),
        checkingUniqueness: state.matches('checkingUniqueness'),
        showArchiveDialog: _showArchiveDialog,
      };
    },
    R.equals,
  );
  const send = service.send;

  return (
    <>
      <ButtonStack>
        <PrimaryButton
          onClick={(): void => {
            if (showArchiveDialog) {
              setDialogOpen(true);
              return;
            }

            send('SAVE');
          }}
          disabled={
            disabled === true || loading || !changed || checkingUniqueness
          }
          startIcon={
            loading ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              <SaveIcon />
            )
          }
        >
          {onUpdateLabel}
        </PrimaryButton>
        <TertiaryButton
          ml={1}
          onClick={(): void => {
            send('CANCEL');

            if (!R.isNil(navigateTo) && navigateTo !== '') {
              navigate(
                navigateTo,
                navigateState ? {state: navigateState} : undefined,
              );
            }
          }}
        >
          {onCancelLabel}
        </TertiaryButton>
      </ButtonStack>

      <FormSaveDialog
        service={service}
        dialogOpen={dialogOpen}
        handleDialogClose={handleDialogClose}
        submitConfirmMessage={submitConfirmMessage}
        isSavingToArchive={showArchiveDialog}
      />
    </>
  );
};

export default SaveOrCancelButtonStack;
