import {usePopover} from 'components/DesignSystem/Library';

import {useCustomerAccountEntityLoaderContext} from '../CustomerAccountEntityLoaderProvider';
import {useCustomerAccountEntityMapper} from '../useCustomerAccountEntityMapper';

import {DSP_CustomerAccountEntityAvatarWithNameViewProps} from './CustomerAccountEntityAvatarWithName.View';

export function useCustomerAccountEntityAvatarWithName(): DSP_CustomerAccountEntityAvatarWithNameViewProps {
  const popoverProps = usePopover<HTMLDivElement>();
  const {entity: customerAccount, isLoading} =
    useCustomerAccountEntityLoaderContext();
  const customerAccountProps = useCustomerAccountEntityMapper({
    customerAccount,
  });

  return {
    ...popoverProps,
    ...customerAccountProps,
    isLoading,
  };
}
