import {useMemo} from 'react';

import {useDspSelectedSegmentsDrawerContext} from '../SelectedSegmentsDrawer.Provider';

export const useDspSelectedSegmentsSingleList = () => {
  const {
    roads,
    currentBreakpoint,
    activeSegmentId,
    selectSegment,
    getSegmentsByIds,
    filterByAccessTypeValue,
  } = useDspSelectedSegmentsDrawerContext();

  const handleSegmentClick = (id: number) => {
    selectSegment(id);
  };

  const filteredSegments = useMemo(
    () =>
      getSegmentsByIds().filter(
        segment =>
          segment.accessType === filterByAccessTypeValue ||
          filterByAccessTypeValue === 'All' ||
          !filterByAccessTypeValue,
      ),
    [getSegmentsByIds, filterByAccessTypeValue],
  );

  const sortedRoads = useMemo(
    () =>
      roads.map(road => {
        return {
          name: road.name,
          suburb: road.suburb,
          segments: filteredSegments.filter(segment =>
            road.segmentIds.includes(segment.id),
          ),
        };
      }),
    [roads, filteredSegments],
  );

  return {
    sortedRoads,
    onClick: handleSegmentClick,
    currentBreakpoint,
    activeSegmentId,
  };
};
