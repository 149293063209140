import React from 'react';

import {DSL_BaseBox, DSL_BaseBoxProps} from './BaseBox';

export const DSL_ClickableBox = React.forwardRef(
  (props: DSL_BaseBoxProps, ref) => {
    return (
      <DSL_BaseBox
        ref={ref}
        p={1}
        internal_sx={{
          '&:hover': {
            backgroundColor: 'action.hover',
          },
        }}
        cursor={'pointer'}
        {...props}
      />
    );
  },
);

DSL_ClickableBox.displayName = 'DSL_ClickableBox';

export {
  //
  /** @deprecated use DSL_ClickableBox instead*/
  DSL_ClickableBox as ClickableBox,
};
