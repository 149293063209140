import React from 'react';

import {DSL_SelectFileDropTargetView} from './SelectFileDropTarget.View';
import {
  useSelectFileDropTarget,
  UseSelectFileDropTargetProps,
} from './useSelectFileDropTarget';

export type DSL_SelectFileDropTargetProps = UseSelectFileDropTargetProps;

export const DSL_SelectFileDropTarget = (
  props: DSL_SelectFileDropTargetProps,
) => {
  const viewProps = useSelectFileDropTarget(props);

  return <DSL_SelectFileDropTargetView {...viewProps} />;
};

DSL_SelectFileDropTarget.displayName = 'DSL_SelectFileDropTarget';
