import {BlobServiceClient, BlockBlobClient} from '@azure/storage-blob';
import {
  toInt,
  RecordWithFileList,
  files as FilesLib,
} from '@regulatory-platform/common-utils';
import {UserProfile} from '@regulatory-platform/common-utils/dist';
import * as R from 'ramda';
import {SasToken} from 'typeDefs/types';
import {onGenerateSASTokenFunc} from 'utils/stores/types';

import {FileRejection, FilesConfig, FileListFile} from './Attachments.types';

const {
  getAccountId,
  getAccountType,
  getRecordId,
  convertBytesToMbsOrKbs,
  filterFileList,
} = FilesLib;

export const getBlockBlobClient = (request: SasToken): BlockBlobClient => {
  const blobClient = BlobServiceClient.fromConnectionString(
    `BlobEndpoint=${request.storageUri};` +
      `SharedAccessSignature=${request.token}`,
  );
  const containerClient = blobClient.getContainerClient('');
  return containerClient.getBlockBlobClient(request.blobName as string);
};

export const downloadBlobItem = (request: SasToken): string => {
  const blockBlobClient = getBlockBlobClient(request);
  return blockBlobClient.url;
};

export const downloadFile = (request: SasToken): string => {
  return downloadBlobItem({...request});
};

export const getDownloadURL = async (
  blobName: string,
  prefix: string,
  fileGroupId: string | undefined,
  attachmentConfig: FilesConfig,
  activeRecord: RecordWithFileList,
  onGenerateSASToken: onGenerateSASTokenFunc,
): Promise<string> => {
  const sasToken = await onGenerateSASToken({
    blobName,
    prefix,
    fileGroupId,
    accountType: getAccountType(prefix, attachmentConfig),
    accountId: getAccountId(prefix, attachmentConfig, activeRecord),
    recordId: getRecordIdIfExists(prefix, attachmentConfig, activeRecord),
    action: 'download',
  });
  return downloadFile(sasToken as SasToken);
};

export const readFile = (file: File): Promise<FileReader['result']> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>): void => {
      resolve(event?.target?.result as string | ArrayBuffer | null);
    };
    reader.onerror = (event: Event): void => {
      reader.abort();
      reject(event);
    };
    reader.readAsDataURL(file);
  });
};

// success
export const getFileAddedMessage = (fileName: string): string =>
  `File ${fileName} attached.`;

export const getFilesAddedMessage = (fileList: FileListFile[]): string => {
  if (fileList.length === 1) {
    return getFileAddedMessage(fileList[0].name);
  }
  return `${fileList.length} files attached.`;
};

export const getFileLimitExceedMessage = (
  filesLimit: number,
  numberOfFiles: number,
): string =>
  `${numberOfFiles} files not attached. Maximum limit of ${filesLimit} allowed only.`;

export const getAbortMessage = (isCancelled: boolean, fileName: string) =>
  isCancelled
    ? `File upload cancelled. ${fileName} was not attached`
    : `File ${fileName} not attached. Unknown error. Try another file`;

export const getDropRejectMessage = (
  fileRejections: FileRejection[],
  allowedFileTypes: string[] | undefined,
  maxFileSize: number,
): string[] => {
  let messages: string[] = fileRejections.map(rejectedFile => {
    let message = `File ${rejectedFile.file.name} not attached. `;

    if (rejectedFile?.file?.size > maxFileSize) {
      message +=
        'File large than limit of ' +
        convertBytesToMbsOrKbs(maxFileSize) +
        '. ';
    } else if (
      allowedFileTypes &&
      !allowedFileTypes.includes(rejectedFile.file.type)
    ) {
      message += 'File type not supported. ';
    } else {
      message += 'Unknown error. Try another file';
    }
    return message;
  });
  if (messages.length > 1) {
    messages = R.prepend(`${messages.length} files not attached.`, messages);
  }
  return messages;
};

export const getFileListKey = (fileListItem: FileListFile): string => {
  return fileListItem.prefix + ':' + fileListItem.name;
};

type FileListWithKeyAndIndex = (FileListFile & {_key: string; _idx: number})[];

export const getFileListWithIndexByPrefix = (
  prefix: string,
  fileGroupId: string | undefined,
  fileList: FileListFile[],
): FileListWithKeyAndIndex => {
  if (!fileList) {
    return [];
  }

  const fileListWithIndex = fileList.map((fileListItem, idx) =>
    R.mergeRight({_key: getFileListKey(fileListItem), _idx: idx}, fileListItem),
  );

  return filterFileList({
    prefix,
    fileGroupId,
    fileList: fileListWithIndex,
  }) as FileListWithKeyAndIndex;
};

const getFileNameParts = (
  fileName: string,
): {
  fileNameNoExt: string;
  fileNameNoVersion: string;
  version: number;
  extension: string;
} => {
  const fileNameParts = R.split('.', fileName);
  const fileNameNoExt = R.join('.', R.init(fileNameParts));
  const extension = '.' + R.last(fileNameParts);
  const versionRegex = /\(V(\d{1,3})\)$/i;
  const versionArr = fileNameNoExt.match(versionRegex);
  const versionStr = versionArr && versionArr.length > 1 ? versionArr[1] : '0';
  const version = toInt(versionStr) as number;
  let fileNameNoVersion = fileNameNoExt;
  if (version !== 0) {
    fileNameNoVersion = R.slice(
      0,
      fileNameNoExt.length - ('(V' + version + ')').length,
      fileNameNoExt,
    );
  }
  return {
    fileNameNoExt,
    fileNameNoVersion,
    version,
    extension,
  };
};

export const getVersionedFileIfRequired = (
  fileName: string,
  files: FileListFile[],
): {name: string; version: string} => {
  const nextFileNameParts = getFileNameParts(fileName);
  const nextFileNameNoVersion = nextFileNameParts.fileNameNoVersion;
  const fileExtension = nextFileNameParts.extension;
  let nextFileName = nextFileNameParts.fileNameNoExt;
  let nextVersion = nextFileNameParts.version;
  R.forEach(file => {
    const fileNameParts = getFileNameParts(file.name);
    if (
      nextFileNameNoVersion + fileExtension ===
        fileNameParts.fileNameNoVersion + fileNameParts.extension &&
      fileNameParts.version + 1 > nextVersion
    ) {
      nextVersion = fileNameParts.version + 1;
      nextFileName = nextFileNameNoVersion + '(V' + nextVersion + ')';
    }
  }, files);
  return {
    name: nextFileName + fileExtension,
    version: nextVersion + '',
  };
};

/**
 * getRecordIdIfExists obtains the id field (variable, as defined in the attachment config) if it exists
 *
 * @param prefix
 * @param attachmentConfig
 * @param activeRecord
 */
export const getRecordIdIfExists = (
  prefix: string,
  attachmentConfig: FilesConfig,
  activeRecord: RecordWithFileList,
): number | undefined => {
  let recordId: number | undefined = getRecordId(
    prefix,
    attachmentConfig,
    activeRecord,
  );

  // @ts-expect-error the common-utils getRecordId declares that it returns a number but can actually return a string representation of null
  if (!recordId || recordId === 'null' || recordId < 0) {
    recordId = undefined;
  }

  return recordId;
};

/**
 * getAccountId obtains the account id (variable, as defined in the attachment schema config) if it exists on the record
 * if no value exists (which is common if the record does not exist yet), it will obtain the users accountId, so long
 * as the user's accountType matches the schema's accountType
 *
 * @param prefix
 * @param attachmentConfig
 * @param activeRecord
 * @param accountTypeFromSchema
 * @param userProfile
 */
export const getAccountIdFromRecordWithUserProfileFallback = (
  prefix: string,
  attachmentConfig: FilesConfig,
  activeRecord: RecordWithFileList,
  accountTypeFromSchema: string,
  userProfile: UserProfile,
): number | undefined => {
  const accountIdFromRecord = getAccountId(
    prefix,
    attachmentConfig,
    activeRecord,
  );

  if (accountIdFromRecord) {
    return accountIdFromRecord;
  }

  // if we're here, there was no accountId on the record (common if the record does not exist yet)
  // fall back to the user's account id - but only if the user's account type matches the schema's account type
  if (userProfile.accountType == accountTypeFromSchema) {
    return userProfile.accountId;
  }

  return undefined;
};
