import isChangingField from 'utils/stores/isChangingField';
import isFieldTouched from 'utils/stores/isFieldTouched';
import isValid from 'utils/stores/isValid';
import onBlurEventHandler from 'utils/stores/onBlurEventHandler';
import onChangeBlurEventHandler from 'utils/stores/onChangeBlurEventHandler';
import onChangeEventHandler from 'utils/stores/onChangeEventHandler';
import {FormEventObject, FormMachineContext} from 'utils/stores/types';
import {assign, createMachine} from 'xstate';

import {ImportFromCsvRowMachineContext} from './types';

export const createImportFromCsvRowMachine = (
  initialContext: ImportFromCsvRowMachineContext,
) => {
  const userInputEvents = {
    CHANGE: {
      cond: 'isChangingField',
      actions: ['onChange', 'onRowUpdated'],
    },
    BLUR: {
      actions: ['onBlur'],
    },
    CHANGEBLUR: {
      cond: 'isChangingFieldOrNotTouched',
      actions: ['onChangeBlur', 'onRowUpdated'],
    },
    TOGGLE_SELECTED: {
      actions: ['toggleSelected', 'onRowUpdated'],
    },
  };

  return createMachine<ImportFromCsvRowMachineContext, FormEventObject>(
    {
      context: initialContext,
      id: `importFromCsvRow[${initialContext.lineIndex}]`,
      initial: 'userInput',
      states: {
        userInput: {
          initial: 'entry',
          states: {
            entry: {
              always: [
                {
                  cond: 'isValidStore',
                  target: 'valid',
                },
                {
                  cond: 'isInvalidStore',
                  target: 'invalid',
                },
              ],
            },
            valid: {
              always: [
                {
                  cond: 'isInvalidStore',
                  target: 'invalid',
                  actions: ['onRowUpdated'],
                },
              ],
              on: userInputEvents,
            },
            invalid: {
              always: [
                {
                  cond: 'isValidStore',
                  target: 'valid',
                  actions: ['onRowUpdated'],
                },
              ],
              on: userInputEvents,
            },
          },
        },
      },
    },
    {
      actions: {
        onChange: assign((context, event) =>
          createContext(
            onChangeEventHandler(context, event, context.validator),
          ),
        ),
        onBlur: assign((context, event) =>
          createContext(onBlurEventHandler(context, event, context.validator)),
        ),
        onChangeBlur: assign((context, event) =>
          createContext(
            onChangeBlurEventHandler(context, event, context.validator),
          ),
        ),
        toggleSelected: assign({
          isSelected: ({isSelected}) => !isSelected,
        }),
      },
      guards: {
        isChangingField,
        isChangingFieldOrNotTouched: (context, event) =>
          isChangingField(context, event) || !isFieldTouched(context, event),
        isValidStore: context => isValid(context),
        isInvalidStore: context => !isValid(context),
      },
    },
  );
};

function createContext(
  _context: FormMachineContext,
): Partial<ImportFromCsvRowMachineContext> {
  type CombinedContext = ImportFromCsvRowMachineContext & FormMachineContext;
  const context = _context as CombinedContext;

  if (context.isSelected && !isValid(context)) {
    context.isSelected = false;
  }

  return context;
}
