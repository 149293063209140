import React from 'react';
import {AppBarWithDrawerOffsetObserver} from 'components/navigation';
import {useLocation} from 'react-router';
import {
  DSL_DrawerProvider,
  DSL_FullHeightColumnPageLayout,
  DSL_FullHeightColumnPageLayoutHeader,
  DSL_FullHeightColumnPageLayoutMain,
} from 'components/DesignSystem/Library';

type UnauthenticatedLayoutProps = {
  /** Content to render within layout */
  children: React.ReactChildren | React.ReactElement | React.ReactNode;
  iframeMode: boolean;
};

/**
 * Layout component when unauthenticated
 */
const UnauthenticatedLayout: React.FC<UnauthenticatedLayoutProps> = ({
  children,
  iframeMode,
}: UnauthenticatedLayoutProps) => {
  const location = useLocation();
  const pathsWithoutAppBar = ['/', '/reset-password'];

  const shouldShowAppBar =
    iframeMode || !pathsWithoutAppBar.includes(location.pathname);

  return (
    <DSL_DrawerProvider>
      <DSL_FullHeightColumnPageLayout>
        {shouldShowAppBar && (
          <DSL_FullHeightColumnPageLayoutHeader>
            <AppBarWithDrawerOffsetObserver />
          </DSL_FullHeightColumnPageLayoutHeader>
        )}
        <DSL_FullHeightColumnPageLayoutMain>
          {children}
        </DSL_FullHeightColumnPageLayoutMain>
      </DSL_FullHeightColumnPageLayout>
    </DSL_DrawerProvider>
  );
};

export default UnauthenticatedLayout;
