import React from 'react';

import {useWizardFooter} from './useWizardFooter';
import {DSP_WizardFooterView} from './WizardFooter.View';

interface DSP_WizardFooterProps {
  isSubmitButtonVisible?: boolean;
  isPrintButtonVisible?: boolean;
  isPreviousButtonDisabled?: boolean;
  openDialog?: () => void;
  onNextClick?: () => void;
  onPreviousClick?: () => void;
  isCancelButtonVisible?: boolean;
  isCreate?: boolean;
}

export const DSP_WizardFooter = ({
  isCreate,
  isPreviousButtonDisabled,
  isPrintButtonVisible,
  isSubmitButtonVisible,
  openDialog,
  onNextClick,
  onPreviousClick,
  isCancelButtonVisible,
}: DSP_WizardFooterProps) => {
  const props = useWizardFooter(openDialog, isCreate);
  return (
    <DSP_WizardFooterView
      {...props}
      isCreate={isCreate}
      isPreviousButtonDisabled={isPreviousButtonDisabled}
      isPrintButtonVisible={isPrintButtonVisible}
      isSubmitButtonVisible={isSubmitButtonVisible}
      onNextClick={onNextClick}
      onPreviousClick={onPreviousClick}
      isCancelButtonVisible={isCancelButtonVisible}
    />
  );
};

DSP_WizardFooter.displayName = 'DSP_WizardFooter';
