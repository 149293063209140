import {lazy} from 'react';
import WebAsset from '@mui/icons-material/WebAsset';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';

export default {
  path: '/approval-library/vehicleApproval',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "NewApprovalGateway" */ './NewVehicleApprovalGateway'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Add Vehicle Approval',
  menuIcon: WebAsset,
  authorisation: {
    apiKey: 'PbsApprovalWithRelations.create',
    policies: [{role: 'providerPBSUser'}] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'PBS Approval Library',
      to: '..',
    },
  ],
};
