import React from 'react';

import {DSL_BaseTypography, DSL_BaseTypographyProps} from '../BaseTypography';

export type DSL_Subtitle2Props = Omit<DSL_BaseTypographyProps, 'variant'>;

export const DSL_Subtitle2 = (props: DSL_Subtitle2Props) => (
  <DSL_BaseTypography {...props} variant={'subtitle2'} />
);

export {
  //
  /** @deprecated use DSL_Subtitle2Props instead*/
  DSL_Subtitle2Props as Subtitle2Props, //
  /** @deprecated use DSL_Subtitle2 instead*/
  DSL_Subtitle2 as Subtitle2,
};
