import React from 'react';

import {AccessProductChangeSetWithRelations} from 'typeDefs/types';
import {NotificationItem} from 'utils/machines/notificationsMachine';

import {DSP_AccessProductChangeSetOperationsNotificationCardView} from './AccessProductChangeSetOperationsNotificationCard.View';
import {useAccessProductChangeSetOperationsNotificationCard} from './useAccessProductChangeSetOperationsNotificationCard';

export interface DSP_AccessProductChangeSetOperationsNotificationCardProps {
  notification: NotificationItem<AccessProductChangeSetWithRelations>;
}

export const DSP_AccessProductChangeSetOperationsNotificationCard = (
  props: DSP_AccessProductChangeSetOperationsNotificationCardProps,
) => {
  const viewProps = useAccessProductChangeSetOperationsNotificationCard(props);

  return (
    <DSP_AccessProductChangeSetOperationsNotificationCardView {...viewProps} />
  );
};

DSP_AccessProductChangeSetOperationsNotificationCard.displayName =
  'DSP_AccessProductChangeSetOperationsNotificationCard';

export {
  //
  /** @deprecated use DSP_AccessProductChangeSetOperationsNotificationCardProps instead*/
  DSP_AccessProductChangeSetOperationsNotificationCardProps as AccessProductChangeSetOperationsNotificationCardProps, //
  /** @deprecated use DSP_AccessProductChangeSetOperationsNotificationCard instead*/
  DSP_AccessProductChangeSetOperationsNotificationCard as AccessProductChangeSetOperationsNotificationCard,
};
