import {pluralizeLabel} from 'components/DesignSystem/utils/pluralizeLabel';

import {useDspSelectedSegmentsDrawerContext} from '../SelectedSegmentsDrawer.Provider';

interface UseDspCalculateSummaryProps {
  summary: string;
  segmentIds?: number[];
  isRootList?: boolean;
}

export const useDspCalculateSummary = (): UseDspCalculateSummaryProps => {
  const {
    filteredRoads,
    hasSelectedRoadId,
    isViewByRoads,
    hasFilterByAccessType,
    currentBreakpoint,
    selectedRoadId,
  } = useDspSelectedSegmentsDrawerContext();

  if (hasSelectedRoadId) {
    const selectedRoad = filteredRoads?.find(
      road => road.id === selectedRoadId,
    );

    const segmentsNum = selectedRoad?.segmentIds?.length ?? 0;
    const summary = `${segmentsNum} ${pluralizeLabel('segment', segmentsNum)}`;

    return {
      summary,
      segmentIds: selectedRoad?.segmentIds ?? [],
    };
  }

  const totalSegmentIds = filteredRoads.flatMap(road => road.segmentIds);

  if (isViewByRoads || (hasFilterByAccessType && currentBreakpoint !== 'xs')) {
    const summary = `${filteredRoads.length ?? 0} ${pluralizeLabel(
      'road',
      filteredRoads.length,
    )}, ${totalSegmentIds.length ?? 0} ${pluralizeLabel(
      'segment',
      totalSegmentIds.length,
    )}`;
    return {
      summary,
      segmentIds: totalSegmentIds,
      isRootList: true,
    };
  }

  if (isViewByRoads && currentBreakpoint === 'xs') {
    return {
      summary: `${filteredRoads.length ?? 0} ${pluralizeLabel(
        'road',
        filteredRoads.length,
      )}`,
    };
  }

  const summary = `${filteredRoads.length ?? 0} ${pluralizeLabel(
    'road',
    filteredRoads.length,
  )}, ${totalSegmentIds.length ?? 0}  ${pluralizeLabel(
    'segment',
    totalSegmentIds.length,
  )}`;
  return {
    summary,
    segmentIds: totalSegmentIds,
    isRootList: true,
  };
};
