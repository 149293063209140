import * as Types from '../../../typeDefs/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserModelControllerRefreshMutationVariables = Types.Exact<{
  refreshInput: Types.RefreshInput;
}>;


export type UserModelControllerRefreshMutation = { __typename?: 'Mutation', userModelControllerRefresh?: { __typename?: 'JWTToken', refreshToken?: string | null, token?: string | null } | null };


export const UserModelControllerRefreshDocument = gql`
    mutation userModelControllerRefresh($refreshInput: RefreshInput!) {
  userModelControllerRefresh(refreshInput: $refreshInput) {
    refreshToken
    token
  }
}
    `;
export type UserModelControllerRefreshMutationFn = Apollo.MutationFunction<UserModelControllerRefreshMutation, UserModelControllerRefreshMutationVariables>;

/**
 * __useUserModelControllerRefreshMutation__
 *
 * To run a mutation, you first call `useUserModelControllerRefreshMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserModelControllerRefreshMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userModelControllerRefreshMutation, { data, loading, error }] = useUserModelControllerRefreshMutation({
 *   variables: {
 *      refreshInput: // value for 'refreshInput'
 *   },
 * });
 */
export function useUserModelControllerRefreshMutation(baseOptions?: Apollo.MutationHookOptions<UserModelControllerRefreshMutation, UserModelControllerRefreshMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserModelControllerRefreshMutation, UserModelControllerRefreshMutationVariables>(UserModelControllerRefreshDocument, options);
      }
export type UserModelControllerRefreshMutationHookResult = ReturnType<typeof useUserModelControllerRefreshMutation>;
export type UserModelControllerRefreshMutationResult = Apollo.MutationResult<UserModelControllerRefreshMutation>;
export type UserModelControllerRefreshMutationOptions = Apollo.BaseMutationOptions<UserModelControllerRefreshMutation, UserModelControllerRefreshMutationVariables>;