/* eslint-disable @typescript-eslint/no-explicit-any */
import * as R from 'ramda';
import {ResolveTypegenMeta, State} from 'xstate';
import {EventObject, StateSchema} from 'xstate/lib/types';

/**
 * Get the first state meta data object from the state machine state-context
 * @param state
 */
export default function getStateMetaHead<
  TContext,
  TEvent extends EventObject = EventObject,
  TStateSchema extends StateSchema<TContext> = any,
>(
  state: State<
    TContext,
    TEvent,
    TStateSchema,
    {value: any; context: TContext},
    ResolveTypegenMeta<any, any, any, any>
  >,
): any {
  if (R.isNil(state.meta)) {
    return {};
  }
  const metaKeys = R.keys(state.meta);
  if (metaKeys.length === 0) {
    return {};
  }
  return state.meta[R.keys(state.meta)[0]];
}
