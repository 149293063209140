import {lazy} from 'react';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';

export default {
  path: '/customers/:id',
  component: lazy(
    () =>
      import(
        /* webpackChunkName: "ManageCustomerAccount" */ './ManageCustomerAccount'
      ),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'Manage Customer account',
  menuIcon: LocalShippingOutlinedIcon,
  authorisation: {
    apiKey: null,
    policies: [
      {role: 'operationsAccountRead'},
      {role: 'customerAccountRead'},
    ] as AuthorisationPolicy[],
  },
  sections: [
    {
      content: 'Customer accounts',
      to: '..',
    },
  ],
};
