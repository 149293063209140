import WebAsset from '@mui/icons-material/WebAsset';
import {AuthorisationPolicy} from '@regulatory-platform/common-utils/dist';
import {lazy} from 'react';

export default {
  path: '/vcbRefVehicles/:id',
  component: lazy(
    () =>
      import(/* webpackChunkName: "ManageAsset" */ './ManageAratRefVehicle'),
  ),
  visibleInMenu: false,
  hideInProduction: false,
  menuTitle: 'VCB reference vehicle',
  authorisation: {
    apiKey: 'AratRefVehicleWithRelations.findById',
    policies: [
      {
        role: 'operationsSpatialRead',
      },
      {
        role: 'partnerSpatialRead',
      },
    ] as AuthorisationPolicy[],
  },
  menuIcon: WebAsset,
  sections: [
    {
      content: 'VCB reference vehicles',
      to: '..',
    },
  ],
};
