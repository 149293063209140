import * as Types from '../../../../../../../typeDefs/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCustomerAccountFindByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['Float']['input'];
}>;


export type GetCustomerAccountFindByIdQuery = { __typename?: 'Query', customerAccountPublic?: { __typename?: 'CustomerAccountPublic', id?: number | null, fileList?: any | null, name?: string | null, website?: string | null } | null };


export const GetCustomerAccountFindByIdDocument = gql`
    query getCustomerAccountFindById($id: Float!) {
  customerAccountPublic(id: $id) {
    id
    fileList
    name
    website
  }
}
    `;

/**
 * __useGetCustomerAccountFindByIdQuery__
 *
 * To run a query within a React component, call `useGetCustomerAccountFindByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerAccountFindByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerAccountFindByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCustomerAccountFindByIdQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerAccountFindByIdQuery, GetCustomerAccountFindByIdQueryVariables> & ({ variables: GetCustomerAccountFindByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerAccountFindByIdQuery, GetCustomerAccountFindByIdQueryVariables>(GetCustomerAccountFindByIdDocument, options);
      }
export function useGetCustomerAccountFindByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerAccountFindByIdQuery, GetCustomerAccountFindByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerAccountFindByIdQuery, GetCustomerAccountFindByIdQueryVariables>(GetCustomerAccountFindByIdDocument, options);
        }
export function useGetCustomerAccountFindByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCustomerAccountFindByIdQuery, GetCustomerAccountFindByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCustomerAccountFindByIdQuery, GetCustomerAccountFindByIdQueryVariables>(GetCustomerAccountFindByIdDocument, options);
        }
export type GetCustomerAccountFindByIdQueryHookResult = ReturnType<typeof useGetCustomerAccountFindByIdQuery>;
export type GetCustomerAccountFindByIdLazyQueryHookResult = ReturnType<typeof useGetCustomerAccountFindByIdLazyQuery>;
export type GetCustomerAccountFindByIdSuspenseQueryHookResult = ReturnType<typeof useGetCustomerAccountFindByIdSuspenseQuery>;
export type GetCustomerAccountFindByIdQueryResult = Apollo.QueryResult<GetCustomerAccountFindByIdQuery, GetCustomerAccountFindByIdQueryVariables>;