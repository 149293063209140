import React, {Ref, useMemo} from 'react';

import {
  Typography as MuiTypography,
  TypographyProps as MuiTypographyProps,
} from '@mui/material';
import DOMPurify from 'dompurify';

import {
  DSL_UseGridItemStyles,
  useGridItemStyles,
} from '../../Layout/hooks/useGridItemStyles';
import {DSL_DeprecatedBoxProps, DSL_RestrictedVisualProps} from '../../types';

export type DSL_BaseTypographyProps<
  DSL_C extends React.ElementType = 'div' | 'span',
> = DSL_DeprecatedBoxProps &
  DSL_UseGridItemStyles &
  Omit<
    MuiTypographyProps<DSL_C, {component?: DSL_C}>,
    DSL_RestrictedVisualProps | 'typography'
  > & {
    width?: MuiTypographyProps['width'];
    maxWidth?: MuiTypographyProps['maxWidth'];
    bold?: boolean;
    ellipsisLines?: number;
    html?: string;
    cursor?: 'pointer';
  };

function DSL_BaseTypographyInternal<
  DSL_C extends React.ElementType = 'div' | 'span',
>(
  {
    bold,
    ellipsisLines,
    span,
    xs,
    sm,
    md,
    lg,
    xl,
    html,
    cursor,
    ...props
  }: DSL_BaseTypographyProps<DSL_C>,
  ref: Ref<HTMLInputElement>,
) {
  const ellipsisStyles = ellipsisLines
    ? {
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: ellipsisLines,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
      }
    : {};

  const htmlProps = useMemo(() => {
    if (!html) return {};
    const sanitised = DOMPurify.sanitize(html || '', {ADD_ATTR: ['target']});
    return {
      dangerouslySetInnerHTML: {__html: sanitised},
    };
  }, [html]);

  const gridItemStyles: MuiTypographyProps['sx'] = useGridItemStyles({
    span,
    xs,
    sm,
    md,
    lg,
    xl,
  });
  return (
    <MuiTypography
      sx={[
        {cursor},
        ellipsisStyles,
        gridItemStyles,
        {
          ...(Object.keys(htmlProps).length > 0 && {
            '& p': {
              marginBlockStart: 0,
              marginBlockEnd: 0,
              marginInlineStart: 0,
              marginInlineEnd: 0,
            },
          }),
        },
      ]}
      {...props}
      {...htmlProps}
      // fontWeight={bold ? 'bold' : 'regular'}  // TODO: Reintroduce this and remove the line below when the bug that the header6 fontWeight is overriding the subtitle2 fontWeight is fixed
      fontWeight={bold ? 500 : undefined}
      ref={ref}
    />
  );
}

DSL_BaseTypographyInternal.displayName = 'DSL_BaseTypography';

export const DSL_BaseTypography = React.forwardRef<
  HTMLInputElement,
  DSL_BaseTypographyProps
>(DSL_BaseTypographyInternal) as (
  props: DSL_BaseTypographyProps,
) => JSX.Element;

export {
  //
  /** @deprecated use DSL_BaseTypographyProps instead*/
  DSL_BaseTypographyProps as BaseTypographyProps, //
  /** @deprecated use DSL_BaseTypography instead*/
  DSL_BaseTypography as BaseTypography,
};
