import React from 'react';

import * as R from 'ramda';

import {
  GridItem,
  UseGridItemStyles,
  MultiSelectAutocompleteInput,
  MultiSelectAutocompleteInputProps,
} from 'components/DesignSystem/Library';

import {DSP_FieldProps} from '../types';

import {useMultiSelectAutocomplete} from './useMultiSelectAutocomplete';

export interface DSP_MultiSelectAutoCompleteFieldProps
  extends DSP_FieldProps,
    UseGridItemStyles,
    Omit<MultiSelectAutocompleteInputProps<string>, 'value' | 'options'>,
    Partial<Pick<MultiSelectAutocompleteInputProps<string>, 'options'>> {}

export const DSP_MultiSelectAutocompleteField = ({
  fieldRef,
  span,
  ...propsOverrides
}: DSP_MultiSelectAutoCompleteFieldProps) => {
  const fieldProps = useMultiSelectAutocomplete(fieldRef);
  return (
    <GridItem span={span}>
      <MultiSelectAutocompleteInput<string>
        isOptionEqualToValue={R.equals}
        {...fieldProps}
        {...propsOverrides}
      />
    </GridItem>
  );
};

export {
  //
  /** @deprecated use DSP_MultiSelectAutoCompleteFieldProps instead*/
  DSP_MultiSelectAutoCompleteFieldProps as MultiSelectAutoCompleteFieldProps, //
  /** @deprecated use DSP_MultiSelectAutocompleteField instead*/
  DSP_MultiSelectAutocompleteField as MultiSelectAutocompleteField,
};
