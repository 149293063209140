import React, {ForwardedRef, forwardRef} from 'react';

import {DSL_Box, DSL_BoxProps} from './Box';

export type DSL_InlineBlockBoxProps = Omit<
  DSL_BoxProps,
  'display' | 'width' | 'height'
>;

export const DSL_InlineBlockBox = forwardRef(
  (props: DSL_InlineBlockBoxProps, ref: ForwardedRef<HTMLDivElement>) => (
    <DSL_Box ref={ref} display={'inline-block'} {...props} />
  ),
);

DSL_InlineBlockBox.displayName = 'DSL_InlineBlockBox';

export {
  //
  /** @deprecated use DSL_InlineBlockBoxProps instead*/
  DSL_InlineBlockBoxProps as InlineBlockBoxProps, //
  /** @deprecated use DSL_InlineBlockBox instead*/
  DSL_InlineBlockBox as InlineBlockBox,
};
