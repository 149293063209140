import React from 'react';

import {
  FormLabel,
  Slider as MuiSlider,
  SliderProps as MuiSliderProps,
} from '@mui/material';

import {DSL_Box} from '../../../Layout/Box';
import {DSL_RestrictedVisualProps} from '../../../types';
import {DSL_FormControl} from '../FormControl';

export type DSL_SliderInputProps = Omit<
  MuiSliderProps,
  DSL_RestrictedVisualProps
> & {
  label?: string;
};

export const DSL_SliderInput = (props: DSL_SliderInputProps) => {
  return (
    <DSL_FormControl fullWidth>
      {props?.label && <FormLabel id={props?.id}>{props?.label}</FormLabel>}
      <DSL_Box mx={1.25}>
        <MuiSlider {...props} />
      </DSL_Box>
    </DSL_FormControl>
  );
};

DSL_SliderInput.displayName = 'DSL_SliderInput';

export {
  //
  /** @deprecated use DSL_SliderInputProps instead*/
  DSL_SliderInputProps as SliderInputProps, //
  /** @deprecated use DSL_SliderInput instead*/
  DSL_SliderInput as SliderInput,
};
