import React, {ReactNode} from 'react';

import {Theme} from '@mui/material';

import {DSL_Box} from '../Box';
import {DSL_Divider} from '../Divider';
import {DSL_FlexRow} from '../FlexRow';

type DSL_Sizes = Record<keyof Theme['sizes'] | 'NONE', number>;

export const spacing: DSL_Sizes = {
  NONE: 0,
  XS: 1,
  SM: 2,
  MD: 3,
  LG: 4,
  XL: 5,
} as const;

export interface DSL_ThreadItemProps {
  children: ReactNode;
  spacing?: keyof typeof spacing;
}

export const DSL_ThreadItem = ({
  children,
  spacing: spacingKey = 'SM',
}: DSL_ThreadItemProps) => {
  return (
    <DSL_FlexRow pl={3} gap={3} flexWrap={'nowrap'}>
      <DSL_Divider flexItem orientation={'vertical'} />
      <DSL_Box py={spacing[spacingKey]}>{children}</DSL_Box>
    </DSL_FlexRow>
  );
};

DSL_ThreadItem.displayName = 'DSL_ThreadItem';

export {
  //
  /** @deprecated use DSL_ThreadItemProps instead*/
  DSL_ThreadItemProps as ThreadItemProps, //
  /** @deprecated use DSL_ThreadItem instead*/
  DSL_ThreadItem as ThreadItem,
};
