import React from 'react';

import {DSL_EmailIcon} from '../../Icons';
import {DSL_BaseIconInfo, DSL_BaseIconInfoProps} from '../BaseIconInfo';

export type DSL_EmailIconInfoProps = Omit<DSL_BaseIconInfoProps, 'icon'>;

export const DSL_EmailIconInfo = (props: DSL_EmailIconInfoProps) => {
  return <DSL_BaseIconInfo icon={<DSL_EmailIcon />} {...props} />;
};

DSL_EmailIconInfo.displayName = 'DSL_EmailIconInfo';

export {
  //
  /** @deprecated use DSL_EmailIconInfoProps instead*/
  DSL_EmailIconInfoProps as EmailIconInfoProps, //
  /** @deprecated use DSL_EmailIconInfo instead*/
  DSL_EmailIconInfo as EmailIconInfo,
};
