import React from 'react';

import {DSL_SelectInput} from 'components/DesignSystem/Library';

import {useDspViewByRoadsOrAccessTypeSelect} from './useViewByRoadsOrAccessTypeSelect';

export const DSP_ViewByRoadsOrAccessTypeSelect = () => {
  const props = useDspViewByRoadsOrAccessTypeSelect();
  return (
    <DSL_SelectInput
      label={'View by'}
      size={'small'}
      options={[
        {label: 'Roads', value: 'roads'},
        {label: 'Access', value: 'accessType'},
      ]}
      {...props}
    />
  );
};
DSP_ViewByRoadsOrAccessTypeSelect.displayName =
  'DSP_ViewByRoadsOrAccessTypeSelect';
