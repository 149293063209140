import React, {useEffect} from 'react';

import {NotificationItem} from 'utils/machines/notificationsMachine';

import {
  DSL_ListWithInfiniteScroll,
  DSL_PopperList,
  DSL_PopperListSubheader,
} from 'components/DesignSystem/Library';

import {
  DSP_NotificationListItem,
  DSP_NotificationListItemProps,
} from './NotificationListItem';

export interface DSP_NotificationListProps
  extends Pick<DSP_NotificationListItemProps, 'onNotificationClicked'> {
  todayNotifications: NotificationItem[];
  olderNotifications: NotificationItem[];
  hasMore: boolean;
  loadMore: (page: number) => void;
}

const DSP_NotificationListWrapper = React.forwardRef<HTMLUListElement>(
  (props, ref) => <DSL_PopperList ref={ref} internal_sx={{pt: 0}} {...props} />,
);
DSP_NotificationListWrapper.displayName = 'ListComponent';

export const DSP_NotificationList = ({
  todayNotifications,
  olderNotifications,
  onNotificationClicked,
  hasMore,
  loadMore,
}: DSP_NotificationListProps) => {
  useEffect(() => {
    // on close of the notification list popout reset the list to the first page
    return () => loadMore(0);
  }, [loadMore]);

  return (
    <DSL_ListWithInfiniteScroll
      ListComponent={DSP_NotificationListWrapper}
      loadMore={loadMore}
      hasMore={hasMore}
    >
      {todayNotifications.length > 0 && (
        <>
          <DSL_PopperListSubheader key={'today'}>Today</DSL_PopperListSubheader>
          {todayNotifications.map((notification, index) => {
            return (
              <DSP_NotificationListItem
                key={notification.notificationId + '-' + index}
                notification={notification}
                onNotificationClicked={onNotificationClicked}
              />
            );
          })}
        </>
      )}
      {olderNotifications.length > 0 && (
        <>
          <DSL_PopperListSubheader key={'older'}>Older</DSL_PopperListSubheader>
          {olderNotifications.map((notification, index) => {
            return (
              <DSP_NotificationListItem
                key={notification.notificationId + '-' + index}
                notification={notification}
                onNotificationClicked={onNotificationClicked}
              />
            );
          })}
        </>
      )}
    </DSL_ListWithInfiniteScroll>
  );
};

DSP_NotificationList.displayName = 'DSP_NotificationList';
