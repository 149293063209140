import React from 'react';

import {useTheme} from '@mui/material';

import {useHighlightedText} from './useHighlightedText';

export interface DSL_HighlightedTextProps {
  value?: string;
  highlightText?: string;
  DSL_WrapperComponent?: React.ComponentType<{children: React.ReactNode}>;
  color?: React.CSSProperties['color'];
}

export const DSL_HighlightedText: React.FC<DSL_HighlightedTextProps> = ({
  value = '',
  highlightText = '',
  color,
}) => {
  const theme = useTheme();
  const styleArgs = {
    color: color ?? theme.palette.secondary.main,
  };

  const {parts, regex} = useHighlightedText({value, highlightText});

  if (!highlightText) return <>{value}</>;

  return (
    <>
      {parts.map((part, index) =>
        regex.test(part) ? (
          <span style={styleArgs} key={index}>
            {part}
          </span>
        ) : (
          part
        ),
      )}
    </>
  );
};

export {
  //
  /** @deprecated use DSL_HighlightedTextProps instead*/
  DSL_HighlightedTextProps as HighlightedTextProps, //
  /** @deprecated use DSL_HighlightedText instead*/
  DSL_HighlightedText as HighlightedText,
};
