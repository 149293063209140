import React, {ForwardedRef} from 'react';

import {Tab as MuiTab, TabProps as MuiTabProps} from '@mui/material';

import {DSL_RestrictedVisualProps} from 'components/DesignSystem/Library/types';

export interface DSL_BaseTabProps
  extends Omit<MuiTabProps, Exclude<DSL_RestrictedVisualProps, 'sx'>> {
  textTransform?: React.CSSProperties['textTransform'];
}

export const DSL_BaseTab = React.forwardRef(
  (
    {textTransform, ...props}: DSL_BaseTabProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    return (
      <MuiTab
        sx={{...(textTransform && {textTransform})}}
        {...props}
        ref={ref}
      />
    );
  },
);

DSL_BaseTab.displayName = 'DSL_BaseTab';

export {
  //
  /** @deprecated use DSL_BaseTabProps instead*/
  DSL_BaseTabProps as BaseTabProps, //
  /** @deprecated use DSL_BaseTab instead*/
  DSL_BaseTab as BaseTab,
};
